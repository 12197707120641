import React from "react";
import { Wallet } from "./WalletInfo";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { es, enUS } from "date-fns/locale";

interface WalletInfoCardProps {
  wallet: Wallet;
}
const WalletInfoCard = ({ wallet }: WalletInfoCardProps) => {
  const { t, i18n } = useTranslation();

  const transactionDate = wallet.date_created.slice(0, 11).split("-");

  const resultDate = format(
    new Date(
      Number(transactionDate[0]),
      Number(transactionDate[1]) - 1,
      Number(transactionDate[2])
    ),
    "dd MMMM yyyy",
    {
      locale: i18n.language === "es" ? es : enUS,
    }
  );

  return (
    <div key={wallet.id}>
      <div className="flex justify-between py-2 border-t border-b px-8">
        {/* <div className="text-xs font-medium hidden lg:block dark:text-whiteish w-1/4 ">
          <p className="text-start"> {wallet.id}</p>
        </div> */}
        <div className=" w-1/4">
          {/* <p className="text-center">{wallet.date_created.slice(0, 11)}</p> */}

          <p className="text-xs text-gray-400 text-left">{resultDate}</p>
        </div>
        <div className="bg-background  w-1/3">
          <p className="uppercase text-start text-[#333] dark:text-whiteish text-sm font-semibold">
            {/* {t("employer.leftbar.menu.item7.Credits.Body.left.card_post.job")} */}
            <p className="text-xs">{wallet.action_name}</p>
          </p>
        </div>
        <div className="w-1/3 text-xs flex items-center justify-end  dark:bg-input text-[#555] dark:text-whiteish  ">
          {/* <p>{t("employer.leftbar.menu.item7.Credits.title")}:</p> */}
          <p className="w-20 font-medium text-start items-center justify-center flex px-8 py-3 rounded">
            {wallet.credits}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WalletInfoCard;
