import React from "react";
import { ReactComponent as Ratings } from "../../assets/img/ratings.svg";

import { ReviewTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import ReviewsCard from "./ReviewsCard.";
import { useAtom } from "jotai";
import { loadingFeedback } from "../../Atoms/jotaiAtoms";
import CardSkeleton from "../ui/skeleton/CardSkeleton";

interface ReviewsGivenProps {
  filteredReviews: ReviewTypes[];
  view: string;
}

function ReviewsGiven({ filteredReviews, view }: ReviewsGivenProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useAtom(loadingFeedback);

  return (
    <div className="flex flex-col  mx-2 w-full">
      {filteredReviews.length === 0 && (
        <div className="mx-8 mt-4 mb-2 flex flex-col items-center">
          <Ratings className="h-[104px]" />
          <p className="text-lg font-bold tracking-wide mt-4">
            {t("employer.myhires.feedback.nogivenreviews.Text")}
          </p>
        </div>
      )}
      <div className="flex flex-col w-full">
        {filteredReviews.length > 0 &&
          filteredReviews
            .sort((a, b) => b.date_start?.localeCompare(a?.date_start))
            ?.map((review: ReviewTypes) => {
              if (loading) {
                return <CardSkeleton key={review.title} />;
              } else {
                return (
                  <ReviewsCard key={review.title} review={review} view={view} />
                );
              }
            })}
      </div>
    </div>
  );
}

export default ReviewsGiven;
