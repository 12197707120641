import React, { Suspense, lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProgressRegistration = lazy(
  () => import("src/Components/Worker/Registration/ProgressRegistration")
);
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

function AddBio() {
  const { t, i18n } = useTranslation();
  const [bioWorker, setBioWorker] = useState("");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("overview", bioWorker);

  const saveBio = () => {
    fetch(`${BASE_URL}worker.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const getBio = async () => {
    const data = await fetch(`${BASE_URL}worker.profile?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      // .then((data) => setBioWorker(data.result.workerProfile.overview))
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerInfo } = useQuery(["workerInfo"], getBio);

  useEffect(() => {
    setBioWorker(workerInfo?.workerProfile?.overview);
  }, [workerInfo]);

  // useEffect(() => {
  //   getBio();
  // }, []);

  const navigate = useNavigate();
  const onNextClick = () => {
    saveBio();
    navigate("/create-profile/budget");
  };

  return (
    <div className=" h-screen w-full !bg-white ">
      <RegistrationHeader />
      <div className="lg:max-w-xl pt-12 px-10 lg:px-0 mx-auto min-h-[75vh]">
        <div className="text-xl lg:text-4xl font-bold">
          {t("createprofile.bio.title")}
        </div>
        <p className="pt-8 text-sm text-[#6e7081]">
          {t("createprofile.bio.subtitle")}
        </p>{" "}
        <form className="relative">
          <textarea
            value={bioWorker}
            className="w-full mt-6 border h-48 p-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
            placeholder={`${t(
              "worker.profile.modal_edit_description.placeholder"
            )}`}
            onChange={(e) => setBioWorker(e.target.value)}
          />
          {100 - bioWorker?.length > 0 && (
            <p className="text-xs absolute right-0 -bottom-5">
              {i18n.language === "en" ? (
                <>At least {100 - bioWorker?.length} characters</>
              ) : (
                <>Faltan {100 - bioWorker?.length} caracteres</>
              )}
            </p>
          )}
        </form>
      </div>
      <Suspense fallback={null}>
        <ProgressRegistration
          backButton={"create-profile/languages"}
          disabled={bioWorker?.length < 100 || !bioWorker ? true : false}
          nextButtonText={t("createprofile.language.rightBtn_salary")}
          onNextClick={onNextClick}
          progress="84"
        />
      </Suspense>
    </div>
  );
}

export default AddBio;
