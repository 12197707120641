import React from "react";
import { useNavigate } from "react-router-dom";

import ProgressBarRegister from "../../ProgressBarRegistration/ProgressBarRegister";
import { useTranslation } from "react-i18next";
import { Button } from "../../ui/Button";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { userProfileStore } from "src/Routing/useProfileStore";

interface ProgressRegistrationProps {
  nextButtonText: string;
  backButton?: string;
  progress: string;
  disabled?: boolean;
  onNextClick: () => void;
}

function ProgressRegistration({
  nextButtonText,
  backButton,
  progress,
  disabled,
  onNextClick,
}: ProgressRegistrationProps) {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);

  const handleNavigateBack = () => {
    if (window.location.pathname === "/create-profile/title") {
      navigate("/");
      setLoggedInUserUid("");
      setUserId("");
      setProfileTypesLocal(null);
      // localStorage.clear();
    } else {
      navigate(`/${backButton}`);
    }
  };
  const navigate = useNavigate();
  return (
    <div className="w-full  h-40 relative">
      <div className="w-full absolute bottom-20">
        {/* <div className="absolute bottom-28 w-full"> */}
        <ProgressBarRegister
          height={6}
          bgcolor="#2ABEEB"
          progress={progress}
          color1="#2ABEEB"
          color2="#2ABEEB"
          color3="#2ABEEB"
          color4="#2ABEEB"
          margintop="2rem"
        />
      </div>
      <div className="flex items-center justify-between w-full lg:px-20 absolute bottom-6">
        <Button
          className="px-8 py-2 text-azulBonico font-semibold hover:bg-azulBonico border-2 border-azulBonico bg-white hover:text-white transition"
          onClick={() => handleNavigateBack()}
        >
          {window.location.pathname === "/create-profile/title"
            ? t("createprofile.title.leftBtn")
            : t("createprofile.employment.leftBtn")}
        </Button>
        <Button
          // className={`px-6 font-bold h-10 fixed bottom-6 right-32 text-white rounded-full  ${
          className={`px-6 font-semibold h-10 truncate  text-white rounded-full  ${
            disabled === true
              ? "bg-blancoGris cursor-not-allowed color-grey"
              : "bg-azulBonico hover:bg-azulOscuro"
          } `}
          onClick={() => {
            onNextClick();
          }}
          disabled={disabled}
        >
          {nextButtonText}
        </Button>
      </div>
    </div>
  );
}

export default ProgressRegistration;
