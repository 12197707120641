import React, { useState } from "react";
import { useEffect } from "react";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../Config/api.config";

import {
  HandleMonthName,
  HandleMonthNameES,
} from "../../Utils/HandleMonthName";
import { ReactComponent as InPerson } from "../../assets/img/in-person.svg";
import { ReactComponent as Tag } from "../../assets/img/price-tag.svg";
import { ReactComponent as Expertise } from "../../assets/img/expertise.svg";
import { ReactComponent as Flag } from "../../assets/img/flag.svg";
import { ReactComponent as Check } from "../../assets/img/check-solid.svg";

import AvailableDays from "../Time/AvailableDays";
import RatingStars from "../Rating/RatingStars";
import { JobInfoTypes, JobTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import HandleDayEnding from "src/Utils/HandleDayEnding";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../ui/skeleton";
import TimeSkeleton from "../ui/skeleton/TimeSkeleton";
import { useQuery } from "react-query";

interface JobOfferDetailsProps {
  job: JobTypes;
  setJob: any;
  saveItOrNot: (job: any) => void;
  // handleSaveJob: (job: JobTypes, e: any) => void;
  setShowModal: any;
  setIsApplied: any;
  isApplied: boolean | string;
  jobInfo: JobInfoTypes;
}

export const JobOfferDetails = ({
  job,
  setJob,
  saveItOrNot,
  // handleSaveJob,
  setShowModal,
  setIsApplied,
  isApplied,
  jobInfo,
}: JobOfferDetailsProps) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [isSaved, setIsSaved] = useState<boolean | string>(false);
  const [activeStar, setActiveStar] = useState();
  const [posted, setPosted] = useState("");
  const [allChosenTimes, setAllChosenTimes] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (job?.updated) {
      const separatedPosted = job?.updated?.split(" ");
      setPosted(separatedPosted[0] + `${" "}` + separatedPosted[1]);
    }
  }, []);
  const getTimes = async () => {
    const data = await fetch(
      `${BASE_URL}job.time.profile?job_id=${job?.job_id}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: timeData } = useQuery(["time", job?.job_id], getTimes, {
    enabled: job?.job_id !== undefined,
  });

  useEffect(() => {
    setAllChosenTimes(timeData);
  }, [timeData]);

  useEffect(() => {
    setIsSaved(job?.saved);
    if (setIsApplied) {
      setIsApplied(job?.applied);
    }
  }, [job]);

  const copyOfferUrl = async () => {
    await navigator.clipboard.writeText(
      `localhost:3000/job-offer/${job?.job_id}`
    );
  };

  if (job?.job_id === undefined)
    return (
      <div className="mt-10 ml-6">
        <Skeleton className="w-20 h-4 mb-4" />
        <Skeleton className="w-40 h-4 mb-4" />
        <Skeleton className="w-60 h-4 mb-4" />
        <Skeleton className="w-40 h-4 " />
      </div>
    );

  return (
    <div>
      <div className="text-xl mt-6 mx-6 shadow overflow-y-auto   rounded h-full  bg-background  dark:bg-background  flex lg:flex-row flex-col justify-between">
        <div className="lg:flex-[0_0_70%] lg:max-w-[70%] border-r ">
          <div className="border-b border-blue-100 dark:border-border">
            <p className="font-semibold text-2xl tracking-wide my-6 mx-6">
              {job?.title}
            </p>
          </div>
          <div className="border-b border-blue-100 dark:border-border flex flex-col py-6">
            <p className="text-base text-azulBonico font-medium hover:text-azulOscuro hover:underline cursor-pointer w-fit mx-6">
              {job.category_name}
            </p>
            <p className="text-sm mx-6 mt-2">
              {t("worker.offer_details.created_on")}{" "}
              <span className=" text-slate-800">
                {i18n.language === "en" ? (
                  <>
                    {HandleMonthName(job.date_created?.slice(5, 7))}{" "}
                    {job.date_created?.slice(8, 10)}
                    {HandleDayEnding(job.date_created?.slice(8, 10))}
                  </>
                ) : (
                  <>
                    {job.date_created?.slice(8, 10)} de{" "}
                    {HandleMonthNameES(job.date_created?.slice(5, 7))}
                  </>
                )}
              </span>
            </p>
            <div className="flex flex-col lg:flex-row items-start justify-between  mt-4">
              <div>
                <div className="flex items-center gap-3 mx-6 ">
                  <InPerson className="h-3 " />
                  <p className="text-sm font-medium">{job.type_name}</p>
                </div>
                {job.type_name === "In-person" && (
                  <p className="mx-6 text-xs mt-1">
                    {job.location}, {job.country_code}
                  </p>
                )}
                {job.type_name === "Hybrid" && (
                  <p className="text-sm mx-6 font-medium">{job.country_code}</p>
                )}
              </div>
              <div className="border-l h-14 border-gray-200" />
              <div className="flex flex-col items-start mx-6 ">
                <div className="flex items-start gap-3 ">
                  <Tag className="h-4 " />
                  <div className="flex flex-col">
                    <p className="text-sm font-medium">
                      {" "}
                      {job.budget ?? "0"} €
                    </p>
                  </div>
                </div>
                <p className="text-xs mt-1">
                  {jobInfo?.data[0]?.netpay === "0"
                    ? t("offer_details.hourly_gross_rate")
                    : t("offer_details.hourly_net_rate")}
                </p>
              </div>
              <div className="border-l h-14 border-gray-200" />
              <div className="flex flex-col items-start mx-6">
                <div className="flex  items-start gap-3">
                  <Expertise className="h-4 mt-1 w-fit" />
                  <div className="flex flex-col">
                    <p className="text-sm font-medium">{job.category_name}</p>
                  </div>
                </div>
                <p className="text-xs mt-1">
                  {t("worker.offer_details.category")}
                </p>
              </div>{" "}
            </div>
          </div>
          {job.details && (
            <div className="border-b border-blue-100 flex flex-col py-6">
              <p className="mx-6 text-base font-medium">
                {t("worker.offer_details.job_details")}
              </p>
              <p className="text-sm mx-6 mt-3 max-w-[600px] overflow-hidden">
                {job.details}
              </p>
            </div>
          )}
          {job.cover_letter && job.cover_letter !== "undefined" && (
            <div className="border-b border-blue-100 flex flex-col py-6">
              <p className="mx-6 text-base font-medium">
                {t("worker.offer_details.cover_letter")}
              </p>
              <h3 className="text-sm mx-6 mt-3 w-[700px] max-w-[700px] ">
                {job.cover_letter}
              </h3>
            </div>
          )}
          {typeof job.skill_list === "string" ? (
            <div className="border-b border-border py-6">
              <p className="mx-6 font-medium">
                {t("worker.offer_details.skills")}{" "}
              </p>
              <div className="flex flex-wrap text-xs overflow-auto mt-4 mx-6 gap-2 ">
                <button className="px-2 text-xs py-0.5 mx-0.5 text-black bg-blancoGrisClaro my-0.5 flex border rounded-xl hover:bg-[#bdbdbd]">
                  {job.skill_list}
                </button>
              </div>
            </div>
          ) : (
            <div className="border-b border-blue-100 flex flex-col py-6">
              <p className="mx-6 text-base font-medium">
                {t("worker.offer_details.skills")}{" "}
              </p>
              <div className="flex flex-wrap text-xs overflow-auto mt-4 mx-6 gap-2 ">
                {job?.skill_list?.length > 0 && (
                  <>
                    {job?.skill_list?.map((skill: { skill_name: string }) => {
                      return (
                        <div key={Math.random()}>
                          <button className="px-2 text-xs py-0.5 mx-0.5 text-black bg-blancoGrisClaro my-0.5 flex border rounded-xl hover:bg-[#bdbdbd]">
                            {skill.skill_name}
                          </button>
                        </div>
                      );
                    })}
                  </>
                )}
                {job.skill_list?.length === 0 && (
                  <p>{t("skills.no_skills.empty_container")}</p>
                )}
              </div>
            </div>
          )}

          <div className="border-b border-blue-100 flex flex-col py-6">
            <p className="mx-6 text-base font-medium">
              {t("worker.offer_details.schedule")}
            </p>
            <div className=" mt-4 mx-6">
              {!loading && allChosenTimes?.length > 0 && (
                <AvailableDays allChosenTimes={allChosenTimes!} />
              )}
              {loading && (
                <div>
                  <TimeSkeleton />
                </div>
              )}
              {!loading && !allChosenTimes?.length && (
                <div>
                  <p className="text-xs text-gray-400">
                    {t("time.available_hours.empty")}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="border-b border-blue-100 flex flex-col py-6">
            <p className="mx-6 text-base font-medium mb-4">
              {t("worker.offer_details.about_job")}{" "}
            </p>

            <p className="text-sm mx-6 ">
              {t("worker.offer_details.workers_needed")}:{" "}
              <span className="font-medium">
                {jobInfo?.data[0]?.workers_needed}
              </span>
            </p>
          </div>
        </div>
        <div className="lg:flex-[0_0_30%] lg:max-w-[30%] ">
          <div className="border-b border-blue-100 font-medium py-6 ">
            <div className="flex flex-col gap-4 items-center mx-6 text-sm">
              {/* {isApplied === false ||
              isApplied === "false" ||
              job.applied === "false" ? ( */}
              {job.applied !== "true" ? (
                <button
                  className="w-full py-2.5 text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                  onClick={() => {
                    setShowModal((prev: any) => ({ ...prev, cover: true }));
                  }}
                >
                  {t("worker.offer_details.apply")}
                </button>
              ) : (
                <div className="relative w-full cursor-not-allowed bg-azulBonico text-white rounded-full">
                  <Check
                    className="h-4 absolute left-[33%] top-[13px] "
                    fill={"#fff"}
                  />
                  <p className="text-white text-sm font-medium border text-center w-full mb-1 pl-[20px] py-2.5 rounded-full px-2 border-azulBonico ">
                    {t("worker.offer_details.applied")}
                  </p>
                </div>
              )}
              {job.active && (
                <button
                  className="w-full py-2.5 text-azulBonico hover:bg-[#f7f4f4] border-2 border-azulBonico hover:border-black hover:text-black bg-white  rounded-full transition duration-300"
                  onClick={() => {
                    // setMessagesJobId(JOB?.job_id);
                    setTimeout(() => {
                      navigate("/messages");
                    }, 50);
                  }}
                >
                  {t("worker.offer_details.messages")}
                </button>
              )}
              <div className="relative w-full">
                {isSaved === false || isSaved === "false" ? (
                  <button
                    className="w-full py-2.5 text-azulBonico hover:bg-[#f7f4f4] border-2 border-azulBonico hover:border-black hover:text-black bg-white  rounded-full transition duration-300"
                    onClick={(e) => {
                      setJob((prev: any) => ({ ...prev, saved: true }));
                      saveItOrNot(job);
                      // handleSaveJob(job, e);
                      setIsSaved(true);
                    }}
                  >
                    {t("worker.offer_details.save_job")}
                  </button>
                ) : (
                  <button
                    className="w-full py-2.5 text-[#EC4F9D] hover:bg-[#f7f4f4] border-2 border-[#EC4F9D] font-medium bg-white  rounded-full transition duration-300"
                    onClick={(e) => {
                      setJob((prev: any) => ({ ...prev, saved: false }));
                      saveItOrNot(job);
                      // handleSaveJob(job, e);
                      setIsSaved(false);
                    }}
                  >
                    {t("worker.offer_details.saved_job")}
                  </button>
                )}
              </div>
            </div>
            {job.applied === "false" && (
              <>
                {job.inapropiate_job === "true" ? (
                  <div className="mx-6 mt-7 flex items-center gap-3 cursor-not-allowed">
                    <Flag className="h-3" fill={"rgb(148, 168, 184)"} />
                    <p className="text-sm text-slate-400">
                      Flagged as inaproppriate
                    </p>
                  </div>
                ) : (
                  <div
                    className="mx-6 mt-7 flex items-center gap-3 cursor-pointer text-azulBonico hover:underline"
                    onClick={() =>
                      setShowModal((prev: any) => ({ ...prev, report: true }))
                    }
                  >
                    <Flag className="h-3" fill={"#2abeeb"} />
                    <p className=" text-sm ">
                      {t("worker.offer_details.inappropiate")}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="border-b border-blue-100 py-6">
            <p className="mx-6 text-base font-medium">
              {t("worker.offer_details.about_employer")}
            </p>
            <p className="text-sm font-medium mx-6 mt-2">
              {jobInfo?.employer_score?.employer_first_name}{" "}
              {jobInfo?.employer_score?.employer_last_name}
            </p>
            <p className="mt-1 mx-6 text-xs text-slate-400">
              {t("worker.dashboard.member_since")}{" "}
              {i18n.language === "en"
                ? HandleMonthName(
                    jobInfo?.employer_score?.date_joined?.slice(5, 7)
                  )
                : HandleMonthNameES(
                    jobInfo?.employer_score?.date_joined?.slice(5, 7)
                  )}{" "}
              {jobInfo?.employer_score?.date_joined?.slice(0, 4)}
            </p>
            <div className="mx-6 mt-4">
              <RatingStars
                activeStar={jobInfo?.employer_score?.avgScore}
                score={jobInfo?.employer_score?.avgScore}
                setActiveStar={setActiveStar}
                evaluation={false}
                showHalf={true}
                info={jobInfo?.employer_score}
                reviews={true}
                showModal={false}
              />
            </div>
            {/* {job.type_name !== "Online" && job.country_code === "AU" ? (
              <div className="flex flex-col">
                <p className="mx-6 text-sm mt-4 font-medium">Australia</p>
                <p className="mx-6 text-sm">{job.city_name} </p>
              </div>
            ) : job.type_name !== "Online" && job.country_code === "AU" ? (
              <div className="flex flex-col">
                <p className="mx-6 text-sm mt-4 font-medium">Spain</p>
                <p className="mx-6 text-sm">{job.city_name} </p>
              </div>
            ) : null} */}
            <ul className="flex flex-col gap-2 font-medium">
              <li className="mx-6 text-sm flex items-center">
                <p className="w-9 ">
                  {" "}
                  {typeof jobInfo?.employer_score?.avgComplianceDate ===
                    "number" && (
                    <>
                      {Math.round(jobInfo?.employer_score.avgComplianceDate)}%{" "}
                    </>
                  )}
                </p>
                <span className="text-xs text-gray-500 ml-2">
                  {t("worker.offer_details.job_success")}
                </span>
              </li>
              <li className="mx-6 text-sm flex items-center">
                <div className="flex items-center w-9 ">
                  {jobInfo?.employer_score?.totalConnections}
                  <p className=" ">
                    <span className="text-xs">
                      / {jobInfo?.data[0]?.workers_needed}{" "}
                    </span>
                  </p>
                </div>
                <span className="text-xs text-gray-500 ml-2">
                  {t("worker.offer_details.hired_workers")}
                </span>
              </li>
              <li className="mx-6  text-sm flex items-center ">
                <div className="flex items-center w-9">
                  {jobInfo?.employer_score?.totalConnectionsTerminated}
                  <p className=" ">
                    <span className="text-xs">
                      / {jobInfo?.data[0]?.workers_needed}{" "}
                    </span>
                  </p>
                </div>
                <span className="text-xs text-gray-500 ml-2">
                  {t("worker.offer_details.early_end_contracts")}
                </span>
              </li>
            </ul>

            {/* <div className="flex flex-col">
              <p className="mx-6 text-sm mt-4 font-medium">X jobs posted</p>
              <p className="mx-6 text-sm">X open jobs</p>
            </div> */}
          </div>
          {/* <div className="py-6  mx-6 overflow-hidden">
            <p className=" text-base font-medium">Job link</p>
            <input
              type="text"
              placeholder="https://www.flexihours.com/erxfcz"
              className="w-max text-sm  border border-slate-200 rounded px-4 py-2 mt-6 cursor-not-allowed"
              disabled={true}
            />
            <p
              className="text-azulBonico cursor-pointer  hover:underline hover:text-azulOscuro text-sm font-medium mt-2"
              onClick={() => copyOfferUrl()}
            >
              Copy link
            </p>
          </div> */}
        </div>
      </div>
      {/* {showApplyModal && (
        <ModalAddCoverLetter
          showModal={showApplyModal}
          setShowModal={setShowApplyModal}
          getNumberOfAppliedJobs={getNumberOfAppliedJobs}
          setJob={setJob}
          setIsApplied={setIsApplied}
          toApplyOrNotToApply={toApplyOrNotToApply}
          job={job}
          handleApplyJob={handleApplyJob}
          loading={loading}
          setLoading={setLoading}
        />
      )} */}
    </div>
  );
};
