import React from "react";
import { ReactComponent as ArrowLeft } from "../../assets/img/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/arrow-right.svg";
import Carousel from "react-multi-carousel";

interface CustomSkillCarouselProps {
  children: React.ReactNode;
}

export const CustomRightArrow = ({ onClick, ...rest }: { onClick: any }) => {
  return (
    <button
      onClick={() => onClick()}
      className="absolute -right-1 overflow-visible z-100 rounded-full  opacity-100 p-1 "
    >
      <ArrowRight className="h-5" fill={"#7b7b7b"} />
    </button>
  );
};
export const CustomLeftArrow = ({ onClick, ...rest }: { onClick: any }) => {
  return (
    <button
      onClick={() => onClick()}
      className="absolute overflow-visible z-100 rounded-full -ml-[3px] opacity-100 p-1 "
    >
      <ArrowLeft className="h-5" fill={"#7b7b7b"} />
    </button>
  );
};
function CustomSkillCarousel({ children }: CustomSkillCarouselProps) {
  //Skills carousel arrows
  return (
    <div
      className="whitespace-pre text-black overflow-visible z-10 justify-center w-[95%]"
      onClick={(e) => e.stopPropagation()}
    >
      {/* {job?.skill_list && ( */}
      <Carousel
        customRightArrow={<CustomRightArrow onClick={undefined} />}
        customLeftArrow={<CustomLeftArrow onClick={undefined} />}
        className="w-full flex flex-flow mt-3 items-center justify-start -ml-1  "
        containerClass="w-full flex"
        // itemClass="bg-blancoGrisClaro text-xs py-0.5 flex whitespace-nowrap !mx-1 hover:bg-[#bdbdbd] my-1 border text-center !px-2 !w-auto justify-center rounded-xl cursor-pointer text-[#343a47]"
        draggable={true}
        responsive={{
          desktop: {
            breakpoint: { max: 5000, min: 1201 },
            items: 3,
            slidesToSlide: 3,
          },
          laptop: {
            breakpoint: { max: 1200, min: 801 },
            items: 2.5,
            slidesToSlide: 2,
          },
          tablet: {
            breakpoint: { max: 800, min: 651 },
            items: 2,
            slidesToSlide: 2,
          },
          mobile: {
            breakpoint: { max: 650, min: 451 },
            items: 1.5,
            slidesToSlide: 1,
          },
          xs: {
            breakpoint: { max: 450, min: 0 },
            items: 2,
            slidesToSlide: 1,
          },
        }}
        arrows={true}
      >
        {children}
      </Carousel>
    </div>
  );
}

export default CustomSkillCarousel;
