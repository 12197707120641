import React from "react";
import { ReactComponent as NoData } from "../../../assets/img/work.svg";
import { HandleMonthName } from "../../../Utils/HandleMonthName.js";
import { HandleMonthNameES } from "../../../Utils/HandleMonthName.js";
import { useTranslation } from "react-i18next";
import { JobTypes } from "../../../types/types";

interface DisplayJobsProps {
  section: string;
  acceptedJobsList: any;
  setShowModal: any;
  setClickedConnection: (job: JobTypes) => void;
}
const DisplayJobs = ({
  acceptedJobsList,
  setClickedConnection,
  setShowModal,
  section,
}: DisplayJobsProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      {acceptedJobsList?.length === 0 ? (
        <div className="pb-2 flex flex-col items-center">
          <NoData className="h-40 lg:h-48 lg:mt-12 mb-4 lg:mb-8 lg:mx-auto " />
          {/* <NoData className="h-48  mt-12 mb-8 mx-auto" /> */}
          <p className="flex justify-center font-bold text-lg tracking-wider mb-12">
            {section === "active" && t("worker.myJobs.activeJob.text")}
            {section === "pending" && t("worker.myJobs.pendingJob.text")}
            {section === "finished" && t("worker.myJobs.finishedJob.text")}
          </p>
        </div>
      ) : (
        <div>
          {acceptedJobsList
            ?.slice(0)
            ?.filter((job: { title: string | null }) => job.title !== null)
            .reverse()
            ?.map((job: JobTypes) => {
              let acceptedDate = job.offer_accepted_date?.slice(9, 10);
              if (acceptedDate === "1") {
                acceptedDate = "st";
              } else if (acceptedDate === "2") {
                acceptedDate = "nd";
              } else if (acceptedDate === "3") {
                acceptedDate = "rd";
              } else {
                acceptedDate = "th";
              }
              let endingEnd = job.date_end?.slice(9);
              let endingStart = job.date_start?.slice(9);
              let endingEarlyEnd;
              if (job.date_early_end) {
                endingEarlyEnd = job.date_early_end?.slice(9);
                if (endingEarlyEnd === "1") {
                  endingEarlyEnd = "st";
                } else if (endingEarlyEnd === "2") {
                  endingEarlyEnd = "nd";
                } else if (endingEarlyEnd === "3") {
                  endingEarlyEnd = "rd";
                } else {
                  endingEarlyEnd = "th";
                }
              }
              if (endingEnd === "1") {
                endingEnd = "st";
              } else if (endingEnd === "2") {
                endingEnd = "nd";
              } else if (endingEnd === "3") {
                endingEnd = "rd";
              } else {
                endingEnd = "th";
              }
              if (endingStart === "1") {
                endingStart = "st";
              } else if (endingStart === "2") {
                endingStart = "nd";
              } else if (endingStart === "3") {
                endingStart = "rd";
              } else {
                endingStart = "th";
              }
              return (
                <div key={job.job_id} className="border-t border-border p-6 ">
                  <div className="flex flex-col lg:flex-row justify-between">
                    <p className="font-[700] tracking-wide truncate w-60">
                      {job.title.charAt(0).toUpperCase() + job.title.slice(1)}
                    </p>
                    <span className="text-sm text-[#333] font-medium tracking-normal lg:ml-2">
                      {/* <span className="hidden lg:block">
                        {t("worker.diplay_jobs.employer")}:&nbsp;
                      </span> */}
                      {job.employer_full_name}
                    </span>
                  </div>
                  <div className="flex justify-between mt-4 relative">
                    <div className="flex flex-col">
                      <div className="text-[#3a3a3ab5] dark:text-whiteish text-sm w-full font-[600]">
                        <span className="hidden lg:block">
                          {/* {t("worker.search_jobs_filter.salary")}: */}
                        </span>{" "}
                        {job.budget}€ /h
                      </div>
                      {section !== "finished" && (
                        <p className="text-black text-xs lg:text-sm mt-3 w-full">
                          <span className="hidden">
                            {t("worker.diplay_jobs.employer.accepted")}{" "}
                          </span>
                          {i18n.language === "en" ? (
                            <>
                              {HandleMonthName(
                                job.offer_accepted_date?.slice(5, 7)
                              )}{" "}
                              {job.offer_accepted_date?.slice(8, 10)}
                              <span className="text-xs">{acceptedDate}</span>
                            </>
                          ) : (
                            <>
                              {job.offer_accepted_date?.slice(8, 10)} de{" "}
                              {HandleMonthNameES(
                                job.offer_accepted_date?.slice(5, 7)
                              )}
                            </>
                          )}
                        </p>
                      )}

                      {!job.date_early_end &&
                        job.date_start > job.server_date && (
                          <p className="text-xs lg:text-sm text-gray-500">
                            <span className="hidden">
                              {t("worker.diplay_jobs.employer.started_working")}
                            </span>
                            {/* {t("worker.diplay_jobs.employer.start_working")}{" "} */}
                            {i18n.language === "en" ? (
                              <>
                                {HandleMonthName(job.date_start?.slice(5, 7))}{" "}
                                {job.date_start?.slice(8)}
                                <span className="text-xs">
                                  {endingStart}
                                </span>{" "}
                                {t("worker.diplay_jobs.employer.until")}{" "}
                                {HandleMonthName(job.date_end?.slice(5, 7))}{" "}
                                {job.date_end?.slice(8)}
                                <span className="text-xs">{endingEnd}</span>
                              </>
                            ) : (
                              <>
                                {job.date_start?.slice(8)} de{" "}
                                {HandleMonthNameES(job.date_start?.slice(5, 7))}{" "}
                                {t("worker.diplay_jobs.employer.until")} el{" "}
                                {job.date_end?.slice(8)} de{" "}
                                {HandleMonthNameES(job.date_end?.slice(5, 7))}
                              </>
                            )}
                          </p>
                        )}
                      {!job.date_early_end &&
                        job.date_start <= job.server_date &&
                        job.server_date <= job.date_end && (
                          <p className="text-sm text-gray-500">
                            <span className="hidden">
                              {t("worker.diplay_jobs.employer.started_working")}{" "}
                            </span>
                            {i18n.language === "en" ? (
                              <>
                                {HandleMonthName(job.date_start?.slice(5, 7))}{" "}
                                {job.date_start?.slice(8)}
                                <span className="text-xs">
                                  {endingStart}
                                </span>{" "}
                                {t("worker.diplay_jobs.employer.until")}{" "}
                                {HandleMonthName(job.date_end?.slice(5, 7))}{" "}
                                {job.date_end?.slice(8)}
                                <span className="text-xs">{endingEnd}</span>
                              </>
                            ) : (
                              <>
                                {job.date_start?.slice(8)} de{" "}
                                {HandleMonthNameES(job.date_start?.slice(5, 7))}{" "}
                                {t("worker.diplay_jobs.employer.until")} el{" "}
                                {job.date_end?.slice(8)} de{" "}
                                {HandleMonthNameES(job.date_end?.slice(5, 7))}
                              </>
                            )}
                          </p>
                        )}
                      {!job.date_early_end &&
                        job.server_date > job.date_end && (
                          <p className="text-xs lg:text-sm text-gray-500">
                            <span className="hidden ">
                              {t("worker.diplay_jobs.employer.worked_from")}{" "}
                            </span>{" "}
                            {i18n.language === "en" ? (
                              <>
                                {HandleMonthName(job.date_start?.slice(5, 7))}{" "}
                                {job.date_start?.slice(8)}
                                <span className="text-xs">
                                  {endingStart}
                                </span>{" "}
                                {t("worker.diplay_jobs.employer.until")}{" "}
                                {HandleMonthName(job.date_end?.slice(5, 7))}{" "}
                                {job.date_end?.slice(8)}
                                <span className="text-xs">{endingEnd}</span>
                              </>
                            ) : (
                              <>
                                {job.date_start?.slice(8)} de{" "}
                                {HandleMonthNameES(job.date_start?.slice(5, 7))}{" "}
                                {t("worker.diplay_jobs.employer.until")} el{" "}
                                {job.date_end?.slice(8)} de{" "}
                                {HandleMonthNameES(job.date_end?.slice(5, 7))}
                              </>
                            )}
                          </p>
                        )}
                      {job.date_early_end < job.server_date && (
                        <p className="text-xs lg:text-sm text-gray-500">
                          <span className="hidden ">
                            {t("worker.diplay_jobs.employer.worked_from")}{" "}
                          </span>
                          {i18n.language === "en" ? (
                            <>
                              {HandleMonthName(job.date_start?.slice(5, 7))}{" "}
                              {job.date_start?.slice(8)}
                              <span className="text-xs">
                                {endingStart}
                              </span>{" "}
                              {t("worker.diplay_jobs.employer.until")}{" "}
                              {HandleMonthName(job.date_early_end?.slice(5, 7))}{" "}
                              {job.date_early_end?.slice(8)}
                              <span className="text-xs">{endingEarlyEnd}</span>
                            </>
                          ) : (
                            <>
                              {job.date_start?.slice(8)} de{" "}
                              {HandleMonthNameES(job.date_start?.slice(5, 7))}{" "}
                              {t("worker.diplay_jobs.employer.until")} el{" "}
                              {job.date_early_end?.slice(8)} de{" "}
                              {HandleMonthNameES(
                                job.date_early_end?.slice(5, 7)
                              )}
                            </>
                          )}
                        </p>
                      )}
                      {job.date_early_end > job.server_date && (
                        <p className="text-xs lg:text-sm text-gray-500">
                          {t("worker.diplay_jobs.employer.started_working")}{" "}
                          {i18n.language === "en" ? (
                            <>
                              {HandleMonthName(job.date_start?.slice(5, 7))}{" "}
                              {job.date_start?.slice(8)}
                              <span className="text-xs">
                                {endingStart}
                              </span>{" "}
                              {t("worker.diplay_jobs.employer.until")}{" "}
                              {HandleMonthName(job.date_early_end?.slice(5, 7))}{" "}
                              {job.date_early_end?.slice(8)}
                              <span className="text-xs">{endingEnd}</span>
                            </>
                          ) : (
                            <>
                              {job.date_start?.slice(8)} de{" "}
                              {HandleMonthNameES(job.date_start?.slice(5, 7))}{" "}
                              {t("worker.diplay_jobs.employer.until")} el{" "}
                              {job.date_early_end?.slice(8)} de{" "}
                              {HandleMonthNameES(
                                job.date_early_end?.slice(5, 7)
                              )}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                    {!job.date_early_end &&
                      job.date_start > job.server_date && (
                        <div className="text-xs lg:text-sm mr-8 text-center">
                          <p className="font-semibold text-slate-500">
                            {t("worker.diplay_jobs.employer.no_working_yet")}
                          </p>
                        </div>
                      )}
                    {!job.date_early_end &&
                      job.date_start <= job.server_date &&
                      job.server_date <= job.date_end && (
                        <div className="text-xs lg:text-sm mr-8 text-center flex flex-col gap-2 items-end w-fit h-fit">
                          <p className="text-green-500 font-semibold">
                            {t("worker.diplay_jobs.employer.currently_working")}
                            .
                          </p>
                          {/* <p
                            className="text-slate-500 cursor-pointer hover:underline w-fit h-fit text-xs  mb-[2px]"
                            onClick={() => {
                              setClickedConnection(job);
                              setShowModal((prev) => ({
                                ...prev,
                                end: true,
                              }));
                            }}
                          >
                            End Contract
                          </p> */}
                          <button
                            className="button-rate !w-full"
                            onClick={() => {
                              setClickedConnection(job);
                              setShowModal((prev: any) => ({
                                ...prev,
                                end: true,
                              }));
                            }}
                          >
                            {t("worker.diplay_jobs.employer.end_contract")}
                          </button>
                        </div>
                      )}
                    {job.server_date < job.date_early_end && (
                      <div className="text-sm flex flex-col gap-2 mr-8 text-center">
                        <p className="text-green-500 font-semibold">
                          {t("worker.diplay_jobs.employer.currently_working")}
                        </p>
                      </div>
                    )}
                    {(job.server_date > job.date_end ||
                      job.server_date >= job.date_early_end) && (
                      <div className="text-xs lg:text-sm flex flex-col   gap-2  text-center">
                        {/* <p className="font-semibold text-slate-500">
                          {t("worker.diplay_jobs.employer.finished_working")}
                        </p> */}
                        {(job.score_from_worker === "0.00" ||
                          job.score_from_worker === null) && (
                          <button
                            className="bg-azulBonico text-white font-medium hover:bg-azulOscuro px-2 lg:px-8 py-2 rounded-full transition duration-200 ease-in-out text-xs lg:text-sm"
                            // className="button-rate"
                            onClick={() => {
                              setClickedConnection(job);
                              setShowModal((prev: any) => ({
                                ...prev,
                                rating: true,
                              }));
                            }}
                          >
                            {t("worker.diplay_jobs.employer.give_feedback")}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default DisplayJobs;
