import { useQuery } from "react-query";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";
import { useEmployerStore } from "src/Routing/useProfileStore";

export const useGetCredits = () => {
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const getClientCredits = async () => {
    const data = await fetch(
      `${BASE_URL}clients.wallet?client_id=${userID}&mode=balance`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: creditsData, isLoading: loadingCredits } = useQuery(
    ["credits_balance"],
    getClientCredits
  );

  return { creditsData, loadingCredits };
};
