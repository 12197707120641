import React from "react";
import { useAtom } from "jotai";
import { useEffect } from "react";

import {
  numberOfAllApplicants,
  numberOfArchivedApplicants,
  numberOfDeclinedApplicants,
  numberOfMessagedApplicants,
  numberOfShortlistedApplicants,
  selectedApplicantsSection,
} from "../../Atoms/jotaiAtoms";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

interface ReviewApplicantsNavBarProps {
  selectedSection?: any;
  setSelectedSection?: any;
  jobID?: string;
}

const ReviewApplicantsNavBar = ({
  selectedSection,
  setSelectedSection,
  jobID,
}: ReviewApplicantsNavBarProps) => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [counterShortlisted, setCounterShortlisted] = useAtom(
    numberOfShortlistedApplicants
  );
  const [counterAllApplicants, setCounterAllApplicants] = useAtom(
    numberOfAllApplicants
  );
  const [counterArchived, setCounterArchived] = useAtom(
    numberOfArchivedApplicants
  );
  const [counterDeclined, setCounterDeclined] = useAtom(
    numberOfDeclinedApplicants
  );
  const [counterMessaged, setCounterMessaged] = useAtom(
    numberOfMessagedApplicants
  );

  const { t } = useTranslation();

  const getShortlisted = async () => {
    const data = await fetch(
      `${BASE_URL}employer.saved.applicants?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: shortlistedApplicantsCounter,
    isLoading: isLoadingShortlisted,
  } = useQuery(["shortlistedApplicantsEmployer", jobID], getShortlisted);

  useEffect(() => {
    if (!isLoadingShortlisted) {
      setCounterShortlisted(
        parseInt(shortlistedApplicantsCounter?.numberOfSavedApplicants)
      );
    }
  }, [shortlistedApplicantsCounter]);

  const getDeclined = async () => {
    const data = await fetch(
      `${BASE_URL}employer.declined.applicants?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };
  const { data: declinedApplicantsCounter, isLoading: isLoadingDeclined } =
    useQuery(["declinedApplicantsEmployer", jobID], getDeclined);

  useEffect(() => {
    if (!isLoadingDeclined) {
      setCounterDeclined(parseInt(declinedApplicantsCounter?.total));
    }
  }, [declinedApplicantsCounter]);

  const getArchived = async () => {
    const data = await fetch(
      `${BASE_URL}employer.archived.applicants?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: archivedApplicantsCounter } = useQuery(
    ["archivedApplicantsEmployer", jobID],
    getArchived
  );

  useEffect(() => {
    setCounterArchived(archivedApplicantsCounter?.numberOfArchivedApplicants);
  }, [archivedApplicantsCounter]);

  const getAllApplicants = () => {
    fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=not archived`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setCounterAllApplicants(data.result.length))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setSelectedSection("all");
    // getArchived();
    // getDeclined();
    // getShortlisted();
    getAllApplicants();
  }, []);

  return (
    <div>
      <div className="flex pt-8 px-8 pb-3 border-b gap-10 text-sm font-medium border-blue-200 dark:text-whiteish dark:border-border">
        <p
          className={` transition ${
            selectedSection === "all"
              ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB] "
              : "hover:text-azulOscuro"
          } cursor-pointer `}
          onClick={() => setSelectedSection("all")}
        >
          {t("employer.review_applicants.all_applicants")}{" "}
          {counterAllApplicants > 0 && `(${counterAllApplicants})`}
        </p>
        <p
          className={` transition ${
            selectedSection === "shortlisted"
              ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
              : "hover:text-azulOscuro"
          } cursor-pointer `}
          onClick={() => setSelectedSection("shortlisted")}
        >
          {t("employer.review_applicants.shortlisted")}{" "}
          {counterShortlisted > 0 && `(${counterShortlisted})`}
        </p>
        <p
          className={` transition ${
            selectedSection === "messaged"
              ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
              : "hover:text-azulOscuro"
          } cursor-pointer `}
          onClick={() => setSelectedSection("messaged")}
        >
          {t("employer.review_applicants.messaged")}{" "}
          {counterMessaged > 0 && `(${counterMessaged})`}
        </p>

        <p
          className={`transition ${
            selectedSection === "archived"
              ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
              : "hover:text-azulOscuro"
          } cursor-pointer `}
          onClick={() => setSelectedSection("archived")}
        >
          {t("employer.review_applicants.archived")}{" "}
          {counterArchived > 0 && `(${counterArchived})`}
        </p>
        <p
          className={` ${
            selectedSection === "declined"
              ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
              : "hover:text-azulOscuro"
          } cursor-pointer `}
          onClick={() => setSelectedSection("declined")}
        >
          {t("employer.review_applicants.declined")}{" "}
          {counterDeclined > 0 && `(${counterDeclined})`}
        </p>
      </div>
    </div>
  );
};

export default ReviewApplicantsNavBar;
