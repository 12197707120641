import { useQuery } from "react-query";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";

export const useInappropiateReasons = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const getReportReasons = async () => {
    const data = await fetch(`${BASE_URL}inapropiate.job.reasons`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      // .then((data) => setReclamationOptions(data.result))
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: inappropiateReasonsData, isLoading } = useQuery(
    ["inappropiate_reasons"],
    getReportReasons
  );

  return { inappropiateReasonsData, isLoading };
};

export const useWorkerInappropiateReasons = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const getReportReasons = async () => {
    const data = await fetch(`${BASE_URL}inapropiate.worker.profile.reasons`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      //   .then((data) => setReclamationOptions(data.result))
      .catch((err) => console.log(err));
    return data.result;
  };
  const { data: inappropiateReasonsData, isLoading } = useQuery(
    ["inappropiate_reasons_worker"],
    getReportReasons
  );

  return { inappropiateReasonsData, isLoading };
};
