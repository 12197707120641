import { useState } from "react";
import { useFormatActions } from "src/Routing/useCreditsActions";

export const convertCreditsToEUR = (credits: string) => {
  const paramForm: any = localStorage.getItem("paramType");

  const coefficient = JSON.parse(paramForm)?.find(
    (param: { name: string; id: string; value: string }) =>
      param.name === "COEFFICIENT"
  )?.value;

  return `${Number(credits) / coefficient}€`;
};
