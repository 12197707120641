import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import Select from "react-select";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";
import Modal from "react-modal";
import { ReactComponent as Cross } from "../../../assets/img/cross.svg";
import plus from "../../../assets/img/plus.png";
import { StylesModal } from "../../../Utils/stylesModal";
import { useTranslation } from "react-i18next";

interface EditSkillSelectorProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showEditSkillsModal: boolean;
  workerCategories: any;
  refetchData?: () => void;
}

export default function EditSkillSelector({
  setShowModal,
  showEditSkillsModal,
  workerCategories,
  refetchData,
}: EditSkillSelectorProps) {
  const { t } = useTranslation();
  const [skillsSearch, setSkillsSearch] = useState<any>();
  const [searchInput, setSearchInput] = useState("");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [clientSkills, setClientSkills] = useLocalStorage("clientSkills", []);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [categoryID, setCategoryID] = useLocalStorage("jobCategoryID", "");
  const [stateSkillIn, setStateSkillIn] = useState<string>();
  const [stateSkillOut, setStateSkillOut] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [suggestedSkills, setSuggestedSkills] = useState<any>([]);
  const [suggestedSkillsIntact, setSuggestedSkillsIntact] = useState<any>([]);
  const [allWorkerSkills, setAllWorkerSkills] = useState<
    { skill_id: string; name: string }[]
  >([]);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const externalRef = useRef<HTMLDivElement>(null);
  const getWorkerSkills = () => {
    fetch(`${BASE_URL}worker.skill?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllWorkerSkills(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (skillsSearch && ref.current && !ref.current.contains(e.target)) {
        setSkillsSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [skillsSearch]);

  const getAllSkills = () => {
    fetch(
      `${BASE_URL}job.skills?${
        !searchInput ? "category_id" : `name=${searchInput}`
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) =>
        setSkillsSearch(
          data.result
            .sort(function (a: any, b: any) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
            })
            ?.map(function (row: any, i: any, arr: any) {
              if (arr[i].name !== arr[i + 1]?.name) {
                return {
                  value: row.name,
                  label: row.name,
                  skill_id: row.skill_id,
                  name: row.name,
                };
              } else return null;
            })
            .filter(function (el: any) {
              return el != null;
            })
        )
      )
      .catch((err) => console.log(err));
  };

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue);
    setSkillsSearch("");
  };
  useEffect(() => {
    setClientSkills(allWorkerSkills);
  }, [suggestedSkills]);

  const clientSuggestedSkills = () => {
    fetch(
      `${BASE_URL}job.skills?category_id=${workerCategories[0].category_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setSuggestedSkills(data.result);
        setSuggestedSkillsIntact(data.result);
      })
      .catch((err) => console.log(err));
  };

  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append(
    "skill_list",
    JSON.stringify(allWorkerSkills?.map((skill) => skill.skill_id))
  );

  const postNewSkills = () => {
    fetch(`${BASE_URL}worker.skill`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowModal(false);
          if (refetchData) {
            refetchData();
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    clientSuggestedSkills();
    getWorkerSkills();
  }, []);

  const addSkills = (skill: any, skillName: any) => {
    // setSkillsSearch();
    let counter = 0;
    // ELIMINATE SUGGESTIONS ONCLICK

    // let y = e?.target.getAttribute("removesuggestion");
    setSuggestedSkills(
      suggestedSkills?.filter(
        (items: { name: string }) => items.name !== skillName
      )
    );
    for (let i = 0; i <= allWorkerSkills?.length; i++) {
      if (skill.skill_id === allWorkerSkills[i]?.skill_id) {
        counter++;
      }
    }
    if (counter === 0) {
      return setAllWorkerSkills((current: any) => [...current, skill]);
    }
  };
  // const addSkills = (skill, e) => {
  //   // setSkillsSearch();
  //   let counter = 0;
  //   // ELIMINATE SUGGESTIONS ONCLICK
  //   for (let i = 0; i <= allWorkerSkills?.length; i++) {
  //     if (skill.skill_id === allWorkerSkills[i]?.skill_id) {
  //       counter++;
  //     }
  //   }
  //   if (counter === 0) {
  //     return setAllWorkerSkills((current) => [...current, skill]);
  //   }
  // };
  const removeHandler = (skill: any) => {
    // let x = e.target.getAttribute("removeskill");
    setAllWorkerSkills(
      allWorkerSkills.filter((item) => item.skill_id !== skill.skill_id)
    );
    for (let i = 0; i < suggestedSkillsIntact.length; i++) {
      if (
        skill.name === suggestedSkillsIntact[i]?.name &&
        skill.name !== suggestedSkills[i]?.name
      ) {
        setSuggestedSkills((current: any) => [...current, skill]);
      }
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditSkillsModal &&
        externalRef.current &&
        !externalRef.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditSkillsModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };

  useEffect(() => {
    getAllSkills();
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  return (
    <Modal isOpen={showEditSkillsModal} style={StylesModal} ariaHideApp={false}>
      {/* <div> */}{" "}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[700px] left-[50%] translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={externalRef}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edityourskills.title.card")}
        </div>{" "}
        <div>
          <div className="px-8 py-4">
            <Select
              className="text-sm mb-4"
              options={skillsSearch}
              styles={colourStyles}
              placeholder={t(
                "createprofile.details.edityourskills.sortbyDropdown.textPlaceholder"
              )}
              onChange={(e) => addSkills(e, "")}
            />
            <div className="flex flex-wrap max-h-48 text-xs overflow-visible overflow-y-auto jobpost">
              {allWorkerSkills &&
                allWorkerSkills?.map((skill) => {
                  return (
                    <div
                      key={skill.name}
                      className={`flex items-center cursor-pointer gap-2 px-2 py-0.5 mx-0.5 text-white bg-[#4d5669] my-0.5 border rounded-xl hover:bg-[#292e38] ${
                        skill.name === stateSkillOut
                          ? `opacity-0 transition duration-300`
                          : ""
                      }`}
                      // removeskill={skill.name}
                      onClick={(e) => {
                        setStateSkillOut(skill.name);
                        removeHandler(skill);
                        // setTimeout(() => {
                        //   removeHandler(e, skill);
                        //   setStateSkillOut("");
                        // }, 500);
                      }}
                    >
                      <div
                        // removeskill={skill.name}
                        // onClick={(e) => {
                        //   setStateSkillOut(skill.name);
                        //   setTimeout(() => {
                        //     removeHandler(e, skill);
                        //     setStateSkillOut();
                        //   }, 200);
                        // }}
                        className=" text-xs flex items-center"
                      >
                        {skill.name}
                      </div>
                      {/* <img
                            className="h-2.5"
                            src={cross}
                            alt="plus"
                            removeskill={skill.name}
                          /> */}
                      <Cross
                        // removeskill={skill.name}
                        fill={"white"}
                        className="h-[13px] "
                      />
                    </div>
                  );
                })}
            </div>
            <h5 className="mt-6 text-sm mb-2">
              {/* Popular Skills based in{" "} */}
              {t("createprofile.submit.edityourskills.subtitle")}
              <span className="font-bold">
                {workerCategories[0]?.name}
              </span>{" "}
              {/* category */}
            </h5>
            <div className="flex text-sm flex-wrap">
              <div className=" flex flex-wrap   w-max">
                {suggestedSkills
                  ?.slice(0, 20)
                  ?.map((skill: { name: string }) => {
                    return (
                      <div
                        key={skill.name}
                        className={`flex gap-2 bg-blancoGrisClaro border  mx-1  my-1 px-2  py-0.5 rounded-xl active:bg-azulBonico hover:bg-[#bdbdbd]  items-center cursor-pointer ${
                          skill.name === stateSkillIn
                            ? `opacity-[0] transition duration-300`
                            : ""
                        }  `}
                        onClick={(e) => {
                          setStateSkillIn(skill.name);
                          setTimeout(() => {
                            // addSkills(skill, e, skill.name);
                            addSkills(skill, e);

                            setStateSkillIn("");
                          }, 500);
                        }}
                      >
                        <div
                          className=" text-xs  flex items-center  cursor-pointer text-[#343a47]"
                          // removesuggestion={skill.name}
                        >
                          {skill.name}
                        </div>
                        <img
                          // className="h-2.5 absolute right-2.5 top-2.5"
                          className="h-2.5 "
                          src={plus}
                          alt="plus"
                          // removesuggestion={skill.name}
                        ></img>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end border-t border-blue-100">
          <button
            className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro cursor-pointer mt-1 transition duration-300"
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourskills.leftBtn")}
          </button>
          <button
            disabled={allWorkerSkills?.length === 0}
            className={` border-none w-[135px] rounded-[20px] text-[0.9rem] px-[0.6rem] py-[0] transition duration-300 h-10 ${
              allWorkerSkills?.length === 0
                ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
            } `}
            onClick={() => {
              postNewSkills();
              // setTimeout(() => {
              //   setShowModal(false);
              //   window.location.reload(false);
              // }, 200);
            }}
          >
            {t("createprofile.submit.edityourskills.rightBtn")}
          </button>
        </div>
      </div>
      {/* </div>
      </div> */}
    </Modal>
  );
}
