import React, { Suspense, useState, useRef } from "react";
import useLocalStorage from "../../../Hooks/UseLocalStorage";

import { BASE_URL } from "../../../Config/api.config";
import { ReactComponent as Results } from "../../../assets/img/results2.svg";
import "../../../Components/ClientDashboard/ScrollBar.css";
import { useEffect } from "react";
import ProgressBarOffer from "../../../Components/ViewJobPost/ProgressBarOffer";
import ReviewApplicantsNavBar from "../../../Components/ViewJobPost/ReviewApplicantsNavBar";
import ApplicantsFilters from "../../../Components/ViewJobPost/ApplicantsFilters";
import AllApplicants from "../../../Components/ViewJobPost/AllApplicants";
import ShortlistedApplicants from "../../../Components/ViewJobPost/ShortlistedApplicants";
import MessagedApplicants from "../../../Components/ViewJobPost/MessagedApplicants";
import ArchivedApplicants from "../../../Components/ViewJobPost/ArchivedApplicants";
import { useAtom } from "jotai";
import {
  numberOfMessagedApplicants,
  numberOfShortlistedApplicants,
  selectedApplicantsSection,
} from "../../../Atoms/jotaiAtoms";

import DeclinedApplicants from "../../../Components/ViewJobPost/DeclinedApplicants";
import { useTranslation } from "react-i18next";
import autoAnimate from "@formkit/auto-animate";

import { useQuery } from "react-query";
import { getJobInfo } from "src/Hooks/useApplicationProcess";

export default function ReviewCandidates() {
  const [applicantsData, setApplicantsData] = useState<any>([]);
  const [shortlistedApplicants, setShortlistedApplicants] = useState<any>();
  const [messagedApplicants, setMessagedApplicants] = useState<any>();
  const [archivedApplicants, setArchivedApplicants] = useState<any>();
  const [declinedApplicants, setDeclinedApplicants] = useState<any>();
  const [declinedOffers, setDeclinedOffers] = useState<any>();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [selectedSection, setSelectedSection] = useAtom(
    selectedApplicantsSection
  );
  const [sortOrder, setSortOrder] = useState("newest_applicants");
  const [searchedApplicant, setSearchedApplicant] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [counterShortlisted, setCounterShortlisted] = useAtom(
    numberOfShortlistedApplicants
  );
  const { isJobLoading, jobInfoData } = getJobInfo(jobID);

  const applicantsRef = useRef(null);
  const shortlistedRef = useRef(null);
  const messagedRef = useRef(null);
  const archivedRef = useRef(null);
  const declinedRef = useRef(null);

  const [counterMessaged, setCounterMessaged] = useAtom(
    numberOfMessagedApplicants
  );

  useEffect(() => {
    applicantsRef.current && autoAnimate(applicantsRef.current);
  }, [applicantsRef]);
  useEffect(() => {
    shortlistedRef.current && autoAnimate(shortlistedRef.current);
  }, [shortlistedRef]);
  useEffect(() => {
    messagedRef.current && autoAnimate(messagedRef.current);
  }, [messagedRef]);
  useEffect(() => {
    archivedRef.current && autoAnimate(archivedRef.current);
  }, [archivedRef]);
  useEffect(() => {
    declinedRef.current && autoAnimate(declinedRef.current);
  }, [declinedRef]);

  const [showLoader, setShowLoader] = useState(false);

  const { t } = useTranslation();

  const sortFilter = [
    {
      value: "Newest applicants",
      label: t("employer.review_candidate.sort_filter.newest"),
      param: "newest_applicants",
    },
    {
      value: "Oldest applicants",
      label: t("employer.review_candidate.sort_filter.oldest"),
      param: "oldest_applicants",
    },
    {
      value: "Highest rate",
      label: t("employer.review_candidate.sort_filter.highest_rate"),
      param: "highest_rate",
    },
    {
      value: "Lowest rate",
      label: t("employer.review_candidate.sort_filter.oldest_rate"),
      param: "lowest_rate",
    },
  ];

  useEffect(() => {
    if (!isJobLoading) {
      setJobData(jobInfoData);
    }
  }, [jobInfoData, isJobLoading]);

  // APPLICANTS DATA

  const getApplicants = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=not archived&order=${sortOrder}${
        searchedApplicant ? `&find=${searchedApplicant}` : ""
      }&page=${currentPage}&limit=10`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data;
  };

  const {
    data: applicants,
    isLoading: isApplicantsLoading,
    refetch: refetchApplicants,
  } = useQuery(
    ["applicants", sortOrder, searchedApplicant, currentPage],
    getApplicants
  );

  useEffect(() => {
    if (currentPage === 1) {
      setMaxPages(applicants?.paginate.pages);
      setApplicantsData(applicants?.result);
    } else {
      setApplicantsData([...applicantsData, applicants?.result].flat());
    }
  }, [applicants, currentPage]);

  useEffect(() => {
    if (selectedSection === "all") {
      refetchApplicants();
    } else if (selectedSection === "shortlisted") {
      refetchShortlistedApplicants();
    } else if (selectedSection === "archived") {
      refetchArchived();
    }
  }, [selectedSection]);

  //ARCHIVED APPLICANTS

  const getArchivedApplicants = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=archived&order=${sortOrder}&find=${
        searchedApplicant ? searchedApplicant : ""
      }&page=${currentPage}&limit=10`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const {
    data: archivedApplicantsData,
    isLoading: isArchivedLoading,
    refetch: refetchArchived,
  } = useQuery(
    ["archived_applicants", searchedApplicant, currentPage, refreshData],
    getArchivedApplicants,
    {
      enabled: selectedSection === "archived",
    }
  );

  useEffect(() => {
    setArchivedApplicants(archivedApplicantsData);
  }, [archivedApplicantsData]);

  //DECLINED APPLICANTS

  const getDeclinedApplicants = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=declined&order=${sortOrder}&find=${
        searchedApplicant ? searchedApplicant : ""
      }&page=${currentPage}&limit=10`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };
  const {
    data: declinedApplicantsData,
    isLoading: isDeclinedLoading,
    refetch: refetchDeclined,
  } = useQuery(["declined_applicants", refreshData], getDeclinedApplicants, {
    enabled: selectedSection === "declined",
  });

  useEffect(() => {
    setDeclinedApplicants(declinedApplicantsData);
  }, [declinedApplicantsData]);

  //DECLINED OFFERS

  const getDeclinedOffers = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=offer declined&order=${sortOrder}&find=${
        searchedApplicant ? searchedApplicant : ""
      }&page=${currentPage}&limit=10`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: declinedOffersData, isLoading: isDeclinedOffersLoading } =
    useQuery(["declined_offers"], getDeclinedOffers);

  useEffect(() => {
    setDeclinedOffers(declinedOffersData);
  }, [declinedOffersData]);

  //SHORTLISTED APPLICANTS DATA

  const getShortlistedApplicants = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=saved&order=${sortOrder}&find=${
        searchedApplicant ? searchedApplicant : ""
      }&page=${currentPage}&limit=10`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data;
  };

  const {
    data: shortlistedApplicantsData,
    isLoading: isShortlistedApplicantsLoading,
    refetch: refetchShortlistedApplicants,
  } = useQuery(
    ["shortlisted_applicants", sortOrder, searchedApplicant, currentPage],
    getShortlistedApplicants
  );

  useEffect(() => {
    setMaxPages(shortlistedApplicantsData?.paginate.pages);
    setShortlistedApplicants(shortlistedApplicantsData?.result);
  }, [shortlistedApplicantsData]);

  //MESSAGED APPLICANTS DATA

  const getMessagedApplicants = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=messages&order=messages`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data;
  };

  const { data: messagedApplicantsData, refetch: refetchMessagedApplicants } =
    useQuery(
      ["messaged_applicants"],
      getMessagedApplicants
      // {
      //   enabled: selectedSection === "messaged",
      // }
    );

  useEffect(() => {
    setCounterMessaged(messagedApplicantsData?.result?.length);
  }, [messagedApplicantsData]);

  useEffect(() => {
    setMaxPages(messagedApplicantsData?.paginate.pages);
    setMessagedApplicants(messagedApplicantsData?.result);
  }, [messagedApplicantsData]);

  return (
    <>
      <ProgressBarOffer
        props={"review"}
        jobData={jobData && jobData.data?.[0].job_status}
      />
      <div className="flex flex-col w-full bg-white dark:bg-background dark:border dark:border-border mt-8  rounded shadow">
        <ReviewApplicantsNavBar
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          jobID={jobID}
        />

        {selectedSection === "all" && (
          <Suspense fallback={null}>
            <div ref={applicantsRef} className={`${showLoader && "hidden"}`}>
              {applicantsData?.length > 0 ? (
                <>
                  <ApplicantsFilters
                    getApplicants={getApplicants}
                    setSearchedApplicant={setSearchedApplicant}
                    sortFilter={sortFilter}
                    setSortOrder={setSortOrder}
                    setCurrentPage={setCurrentPage}
                  />
                  <AllApplicants
                    refetch={refetchApplicants}
                    applicantsData={applicantsData}
                    setApplicantsData={setApplicantsData}
                    setRefreshData={setRefreshData}
                    maxPages={maxPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </>
              ) : (
                <div>
                  <div className="flex flex-col w-full items-center justify-center my-12 dark:text-whiteish">
                    <Results className="h-32 mb-6" />
                    <p className="text-lg font-bold tracking-wide">
                      {t("employer.review_candidates.no_offers")}
                    </p>
                    <p className="text-sm mt-4">
                      {t("employer.review_candidates.no_offers_desc")}
                      <span className="text-azulBonico font-bold hover:text-azulOscuro hover:underline cursor-pointer">
                        {" "}
                        {t("employer.review_candidates.no_offers_desc_here")}
                      </span>
                      .
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Suspense>
        )}
        {selectedSection === "shortlisted" && (
          <Suspense fallback={null}>
            <div ref={shortlistedRef}>
              {/* <div className={`${showLoader && "hidden"}`}> */}
              <div
                className={`${shortlistedApplicants?.length === 0 && "hidden"}`}
              >
                <ApplicantsFilters
                  getApplicants={getShortlistedApplicants}
                  setSearchedApplicant={setSearchedApplicant}
                  sortFilter={sortFilter}
                  setSortOrder={setSortOrder}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              <ShortlistedApplicants
                refetch={refetchShortlistedApplicants}
                applicantsData={shortlistedApplicants}
                setApplicantsData={setShortlistedApplicants}
                setRefreshData={setRefreshData}
                maxPages={maxPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </Suspense>
        )}
        {selectedSection === "messaged" && (
          <Suspense fallback={null}>
            <div ref={applicantsRef}>
              <div className={`${showLoader && "hidden"}`}>
                <MessagedApplicants
                  refetch={refetchMessagedApplicants}
                  applicantsData={messagedApplicants}
                  setApplicantsData={setMessagedApplicants}
                  setRefreshData={setRefreshData}
                  maxPages={maxPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />{" "}
              </div>
            </div>
          </Suspense>
        )}
        {selectedSection === "archived" && (
          <Suspense fallback={null}>
            <div ref={applicantsRef}>
              <div className={`${showLoader && "hidden"}`}>
                <div
                  className={`${archivedApplicants?.length === 0 && "hidden"}`}
                >
                  <ApplicantsFilters
                    getApplicants={getArchivedApplicants}
                    setSearchedApplicant={setSearchedApplicant}
                    sortFilter={sortFilter}
                    setSortOrder={setSortOrder}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                <ArchivedApplicants
                  refetch={refetchArchived}
                  applicantsData={archivedApplicants}
                  setApplicantsData={setArchivedApplicants}
                  setRefreshData={setRefreshData}
                />{" "}
              </div>
            </div>
          </Suspense>
        )}
        {selectedSection === "declined" && (
          <div ref={declinedRef}>
            <div className={`${showLoader && "hidden"}`}>
              <div
                className={`${declinedApplicants?.length === 0 && "hidden"}`}
              >
                <ApplicantsFilters
                  getApplicants={getArchivedApplicants}
                  setSearchedApplicant={setSearchedApplicant}
                  sortFilter={sortFilter}
                  setSortOrder={setSortOrder}
                  setCurrentPage={setCurrentPage}
                />
              </div>
              <DeclinedApplicants
                refetch={refetchDeclined}
                setRefreshData={setRefreshData}
                declinedApplicants={declinedApplicants}
                declinedOffers={declinedOffers}
              />{" "}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
