import React from "react";
import { useTranslation } from "react-i18next";
import { HoursJobTypes } from "src/types/types";

interface AvailableSlotTime {
  time: HoursJobTypes;
}

const AvailableSlotTime = ({ time }: AvailableSlotTime) => {
  const { t } = useTranslation();
  return (
    <div key={time.job_time_profile_id}>
      {time.dayname === "MON" && (
        <div className="flex items-center w-full flex-wrap">
          <p className="font-bold text-sm  my-1  w-40 truncate">
            {t("welcome-client.rightcard.dayweek.monday")}:
          </p>
          <div>
            <p
              className={`bg-blancoGrisClaro px-2 text-xs mt-2 flex-[0_0_87%] w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl  text-[#343a47]`}
            >
              {time.hour_from?.slice(0, 5)} - {time.hour_to?.slice(0, 5)}
            </p>
          </div>
        </div>
      )}
      {time.dayname === "TUE" && (
        <div className="flex items-center w-full flex-wrap">
          <p className="font-bold text-sm my-1 w-40 truncate">
            {t("welcome-client.rightcard.dayweek.tuesday")}:
          </p>
          <div>
            <p
              className={`bg-blancoGrisClaro px-2 text-xs mt-2 flex-[0_0_87%] w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl  text-[#343a47]`}
            >
              {time.hour_from.slice(0, 5)} - {time.hour_to.slice(0, 5)}
            </p>
          </div>
        </div>
      )}
      {time.dayname === "WED" && (
        <div className="flex items-center w-full flex-wrap">
          <p className="font-bold text-sm my-1 w-40 truncate">
            {t("welcome-client.rightcard.dayweek.wednesday")}:
          </p>
          <div>
            <p
              className={`bg-blancoGrisClaro px-2 flex-[0_0_87%] text-xs mt-2 w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl  text-[#343a47]`}
            >
              {time.hour_from?.slice(0, 5)} - {time.hour_to?.slice(0, 5)}
            </p>
          </div>
        </div>
      )}
      {time.dayname === "THU" && (
        <div className="flex items-center w-full flex-wrap">
          <p className="font-bold text-sm my-1 w-40 truncate">
            {t("welcome-client.rightcard.dayweek.thursday")}:
          </p>
          <div>
            <p
              className={`bg-blancoGrisClaro px-2 flex-[0_0_87%] text-xs mt-2 w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl  text-[#343a47]`}
            >
              {time.hour_from.slice(0, 5)} - {time.hour_to.slice(0, 5)}
            </p>
          </div>
        </div>
      )}
      {time.dayname === "FRI" && (
        <div className="flex items-center w-full flex-wrap">
          <p className="font-bold text-sm my-1 w-40 truncate">
            {t("welcome-client.rightcard.dayweek.friday")}
          </p>
          <div>
            <p
              className={`bg-blancoGrisClaro px-2 flex-[0_0_87%] text-xs mt-2 w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl  text-[#343a47]`}
            >
              {" "}
              {time.hour_from?.slice(0, 5)} - {time.hour_to?.slice(0, 5)}
            </p>
          </div>
        </div>
      )}

      {time.dayname === "SAT" && (
        <div className="flex items-center w-full flex-wrap">
          <p className="font-bold text-sm my-1 w-40 truncate">
            {t("welcome-client.rightcard.dayweek.saturday")}
          </p>
          <div>
            <p
              className={`bg-blancoGrisClaro px-2 flex-[0_0_87%] text-xs mt-2 w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl  text-[#343a47]`}
            >
              {time.hour_from?.slice(0, 5)} - {time.hour_to?.slice(0, 5)}
            </p>
          </div>
        </div>
      )}
      {time.dayname === "SUN" && (
        <div className="flex items-center w-full flex-wrap">
          <p className="font-bold text-sm my-1 w-40 truncate">
            {t("welcome-client.rightcard.dayweek.sunday")}
          </p>
          <div>
            <p
              className={`bg-blancoGrisClaro px-2 flex-[0_0_87%] text-xs mt-2 w-fit py-0.5 mx-1 my-1 flex items-center border rounded-xl  text-[#343a47]`}
            >
              {time.hour_from?.slice(0, 5)} - {time.hour_to?.slice(0, 5)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableSlotTime;
