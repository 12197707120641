import React, { useEffect, useState } from "react";
import useLocalStorage from "./UseLocalStorage";
import { BASE_URL, LIMIT } from "src/Config/api.config";
import { FindWorker, WeekDaysHour } from "src/types/types";
import {
  budgetEnd,
  budgetStart,
  categoriesArrayId,
  citiesArrayId,
  jobTypeArrayId,
  mapHoursDayParams,
  paginationFindWorkers,
  postcodesArrayId,
  saveTypeId,
  savedWorkers,
  sortType,
  workerInput,
} from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";

const INITIAL_STRING_WEEK = {
  mon: "",
  tue: "",
  wed: "",
  thu: "",
  fri: "",
  sat: "",
  sun: "",
};

const INITIAL_WEEK_DAYS = {
  mon: { dayname: "MON", hours: [""] },
  tue: { dayname: "TUE", hours: [""] },
  wed: { dayname: "WED", hours: [""] },
  thu: { dayname: "THU", hours: [""] },
  fri: { dayname: "FRI", hours: [""] },
  sat: { dayname: "SAT", hours: [""] },
  sun: { dayname: "SUN", hours: [""] },
};

const useFiltersWorkers = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");

  const [stringParams, setStringParams] = useState("");
  const [stringWeekParams, setStringWeekParams] = useState(INITIAL_STRING_WEEK);
  const [weekDaysHours, setWeekDaysHours] =
    useState<WeekDaysHour>(INITIAL_WEEK_DAYS);
  const [workers, setWorkers] = useState<FindWorker[]>([]);
  const [loading, setLoading] = useState(false);

  const [citiesId, setCitiesId] = useAtom(citiesArrayId);
  const [categoriesId, setCategoriesId] = useAtom(categoriesArrayId);
  const [postcodesId, setPostcodesId] = useAtom(postcodesArrayId);
  const [jobTypeIds, setJobTypeIds] = useAtom(jobTypeArrayId);
  const [budgetStartFrom, setBudgetStartFrom] = useAtom(budgetStart);
  const [budgetEndTo, setBudgetEndTo] = useAtom(budgetEnd);
  const [mappedHoursOfDay, setMappedHoursDay] = useAtom(mapHoursDayParams);
  const [sortOptionSelected, setSortOptionSelected] = useAtom(sortType);
  const [pagination, setPagination] = useAtom(paginationFindWorkers);
  const [findWorkerInput, setFindWorkerInput] = useAtom(workerInput);
  const [saveWorkers, setSavedWorkers] = useAtom(savedWorkers);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
    }));
  }, [saveWorkers]);

  const getDiscoverWorkers = async () => {
    setLoading(true);
    const data = await fetch(
      `${BASE_URL}discover.workers?client_id_employer=${userID}${stringParams}&order=${
        sortOptionSelected.name
      }&order_sense=${sortOptionSelected.order.toUpperCase()}&limit=${LIMIT}&page=${
        pagination.currentPage
      }&title=${findWorkerInput}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setWorkers(data.result);
        if (data.status === "ok") {
          setPagination((prev) => ({
            ...prev,
            totalPosts: data.paginate.rows,
            totalPages: data.paginate.pages,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  useEffect(() => {
    const stringCities = citiesId.length > 0 ? `&city_id_list=${citiesId}` : "";
    const stringCategories =
      categoriesId.length > 0 ? `&category_id_list=${categoriesId}` : "";
    const stringPostcodes =
      postcodesId.length > 0 ? `&post_code_id_list=${postcodesId}` : "";
    const stringJobType =
      jobTypeIds.length > 0 ? `&job_type_list=${jobTypeIds}` : "";
    const stringBudgetStart =
      budgetStartFrom !== "" ? `&budget_start=${budgetStartFrom}` : "";
    const stringBudgetEnd =
      budgetEndTo !== "" ? `&budget_end=${budgetEndTo}` : "";
    const stringSaved = saveWorkers !== "0" ? `&saved=${saveWorkers}` : "";

    setStringParams(
      stringCities +
        stringCategories +
        stringPostcodes +
        stringJobType +
        stringBudgetStart +
        stringBudgetEnd +
        stringSaved +
        stringWeekParams.mon +
        stringWeekParams.tue +
        stringWeekParams.wed +
        stringWeekParams.thu +
        stringWeekParams.fri +
        stringWeekParams.sat +
        stringWeekParams.sun
    );
  }, [
    citiesId,
    categoriesId,
    postcodesId,
    jobTypeIds,
    budgetStartFrom,
    budgetEndTo,
    saveWorkers,
    stringWeekParams,
  ]);

  useEffect(() => {
    const monMappedHours = mappedHoursOfDay?.find(
      (day) => day.dayname.toUpperCase() === "MON"
    );
    const tueMappedHours = mappedHoursOfDay?.find(
      (day) => day.dayname.toUpperCase() === "TUE"
    );

    const wedMappedHours = mappedHoursOfDay?.find(
      (day) => day.dayname.toUpperCase() === "WED"
    );
    const thuMappedHours = mappedHoursOfDay?.find(
      (day) => day.dayname.toUpperCase() === "THU"
    );
    const friMappedHours = mappedHoursOfDay?.find(
      (day) => day.dayname.toUpperCase() === "FRI"
    );
    const satMappedHours = mappedHoursOfDay?.find(
      (day) => day.dayname.toUpperCase() === "SAT"
    );
    const sunMappedHours = mappedHoursOfDay?.find(
      (day) => day.dayname.toUpperCase() === "SUN"
    );

    setWeekDaysHours({
      mon: monMappedHours ?? { dayname: "", hours: [""] },
      tue: tueMappedHours ?? { dayname: "", hours: [""] },
      wed: wedMappedHours ?? { dayname: "", hours: [""] },
      thu: thuMappedHours ?? { dayname: "", hours: [""] },
      fri: friMappedHours ?? { dayname: "", hours: [""] },
      sat: satMappedHours ?? { dayname: "", hours: [""] },
      sun: sunMappedHours ?? { dayname: "", hours: [""] },
    });
  }, [mappedHoursOfDay]);

  useEffect(() => {
    const stringParamsMon =
      weekDaysHours.mon.dayname !== ""
        ? `&MON=${weekDaysHours.mon.hours?.toString()}`
        : "";
    const stringParamsTue =
      weekDaysHours.tue.dayname !== ""
        ? `&TUE=${weekDaysHours.tue.hours?.toString()}`
        : "";
    const stringParamsWed =
      weekDaysHours.wed.dayname !== ""
        ? `&WED=${weekDaysHours.wed.hours?.toString()}`
        : "";
    const stringParamsThu =
      weekDaysHours.thu.dayname !== ""
        ? `&THU=${weekDaysHours.thu.hours?.toString()}`
        : "";
    const stringParamsFri =
      weekDaysHours.fri.dayname !== ""
        ? `&FRI=${weekDaysHours.fri.hours?.toString()}`
        : "";
    const stringParamsSat =
      weekDaysHours.sat.dayname !== ""
        ? `&SAT=${weekDaysHours.sat.hours?.toString()}`
        : "";
    const stringParamsSun =
      weekDaysHours.sun.dayname !== ""
        ? `&SUN=${weekDaysHours.sun.hours?.toString()}`
        : "";

    setStringWeekParams({
      mon: stringParamsMon,
      tue: stringParamsTue,
      wed: stringParamsWed,
      thu: stringParamsThu,
      fri: stringParamsFri,
      sat: stringParamsSat,
      sun: stringParamsSun,
    });
  }, [weekDaysHours]);

  useEffect(() => {
    getDiscoverWorkers();
  }, [stringParams, sortOptionSelected, findWorkerInput, saveWorkers]);
  return { savedWorkers, workers, getDiscoverWorkers, loading };
};

export default useFiltersWorkers;
