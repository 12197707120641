import React, { Dispatch, SetStateAction, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import Select from "react-select";

import { BASE_URL, DECLINE_OFFER_REASONS } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";
interface ModalDeclineOfferProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  declineReason: string;
  setDeclineReason: Dispatch<SetStateAction<string>>;
  declineOffer: (string: string) => void;
  setDeclineExplanation: Dispatch<SetStateAction<string>>;
}

const ModalDeclineOffer = ({
  showModal,
  setShowModal,
  declineReason,
  setDeclineReason,
  declineOffer,
  setDeclineExplanation,
}: ModalDeclineOfferProps) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [optionsFetched, setOptionsFetched] = useState<
    { name: string; id: string }[]
  >([]);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const getDeclinedOptions = () => {
    fetch(`${BASE_URL}${DECLINE_OFFER_REASONS}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setOptionsFetched(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDeclinedOptions();
  }, []);

  const options = optionsFetched?.map(function (row) {
    return {
      value: row.name,
      label: row.name,
      id: row.id,
    };
  });
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  const closeModal = () => {
    setDeclineReason("");
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-[90%] lg:w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-2 border-azulBonico `}
          ref={ref}
        >
          <div className="text-xl font-bold px-8 py-6">
            {t("employer.modal.decline_applicant.title")}
          </div>
          <div className="text-sm px-8 py-3">
            <p>{t("employer.modal.decline_applicant.desc")}</p>
            <div className="flex flex-col my-6 gap-2">
              <p className="font-bold">Reason</p>
              <Select
                styles={colourStyles}
                className="w-full cursor-pointer text-sm"
                options={options}
                onChange={(e: any) => setDeclineReason(e.id)}
                placeholder={`${t("employer.select.please_select")}...`}
              />
            </div>
            <div className="flex flex-col gap-2 mb-6">
              <p className="font-bold">
                {t("employer.modal.decline_applicant.message")}
              </p>
              <textarea
                onChange={(e) => setDeclineExplanation(e.target.value)}
                className="w-full h-32 resize-none border border-blue-200 rounded hover:border-azulBonico outline-none px-4 py-2 transition duration-300"
              ></textarea>
            </div>
            <div className="flex gap-4 justify-end">
              <button
                className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-300"
                onClick={() => closeModal()}
              >
                {t("employer.modal.decline_applicant.cancel")}
              </button>
              <button
                className={`px-8 py-2 ${
                  declineReason
                    ? "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
                    : "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                } rounded-full transition duration-300`}
                disabled={!declineReason}
                onClick={() => {
                  setShowModal(false);
                  declineOffer("reject");
                }}
              >
                {t("employer.modal.decline_applicant.decline")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeclineOffer;
