import React, { Dispatch, SetStateAction, useState } from "react";
import { ActiveChats, ChatConversation } from "src/types/types";
import { ReactComponent as Circle } from "../../assets/img/circle.svg";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { ReactComponent as Warning } from "../../assets/img/warning.svg";
import exclamation from "../../assets/img/exclamation-yellow.png";

import Highlighter from "react-highlight-words";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { BASE_URL_IMG } from "src/Config/api.config";
import { useTranslation } from "react-i18next";

interface ConversionChatProps {
  msg: ChatConversation;
  i: any;
  arr: any;
  showContent: boolean;
  searchWord: any;
  selectedWorkerChat: ActiveChats;
  setSelectedMessage: any;
  setShowModalDeleteMsg: Dispatch<SetStateAction<boolean>>;
  setShowModalReportMsg: Dispatch<SetStateAction<boolean>>;
  handleCancelReport: (msg: ChatConversation) => void;
  setData: Dispatch<SetStateAction<any>>;
}

const ConversationChat = ({
  arr,
  i,
  msg,
  showContent,
  searchWord,
  selectedWorkerChat,
  handleCancelReport,
  setSelectedMessage,
  setShowModalDeleteMsg,
  setShowModalReportMsg,
  setData,
}: ConversionChatProps) => {
  const { t } = useTranslation();
  const [userID, setUserId] = useLocalStorage("IDUser", "");

  const [showIcon, setShowIcon] = useState(-1);
  const [showCancelReportPopover, setShowCancelReportPopover] = useState(-1);
  return (
    <>
      <div key={i} className={`${showContent === false && "opacity-0"} `}>
        {arr[i]?.msg_date !== arr[i - 1]?.msg_date && (
          <div className="relative">
            <div className="w-full flex mt-4 justify-center  border-b border-[#b2b3bd] dark:border-border dark:border"></div>
            <p className="absolute left-[50%] text-xs font-medium text-[#b2b3bd] -translate-x-[50%] bg-white dark:bg-background dark:border dark:rounded-full -top-[7px] px-3">
              {msg.msg_date}
            </p>
          </div>
        )}
        {arr[i]?.client_name !== arr[i - 1]?.client_name ||
        (arr[i]?.client_name === arr[i - 1]?.client_name &&
          arr[i]?.msg_date !== arr[i - 1]?.msg_date) ? (
          <div className="flex gap-3 text-sm pt-6 dark:text-whiteish">
            {arr[i]?.img_profile === arr[i - 1]?.img_profile &&
            arr[i]?.msg_date === arr[i - 1]?.msg_date ? null : msg.img_profile
                .length < 5 ? (
              <div>
                <p className="border font-bold rounded-full border-azulBonico text-azulBonico h-[48px] w-[48px] p-1 flex justify-center items-center">
                  {msg.img_profile}
                </p>
                <Circle
                  fill={`${
                    msg.status === "OFFLINE" || msg.status === "INVISIBLE"
                      ? "#9c9d9d"
                      : "#14A800"
                  }`}
                  className="h-3 relative bottom-3 left-8 border-[2px] border-white rounded-full"
                />
              </div>
            ) : (
              <div>
                <img
                  src={`${BASE_URL_IMG}${msg.img_profile}`}
                  alt="worker"
                  className="h-[51px] w-[51px] object-cover rounded-full"
                />
                {selectedWorkerChat?.client_name === msg.client_name ? (
                  <Circle
                    fill={`${
                      msg.status === "OFFLINE" || msg.status === "INVISIBLE"
                        ? "#9c9d9d"
                        : "#14A800"
                    }`}
                    className="h-3 relative bottom-3 left-8 border-[2px] border-white rounded-full"
                  />
                ) : (
                  <Circle
                    fill={`${
                      msg.status === "OFFLINE" || msg.status === "INVISIBLE"
                        ? "#9c9d9d"
                        : "#14A800"
                    }`}
                    className="h-3 relative bottom-3 left-8 border-[2px] border-white rounded-full"
                  />
                )}
              </div>
            )}
            <div className="flex flex-col gap-2 items-start w-full">
              <p>
                <span className="font-bold dark:text-whiteish">
                  {arr[i]?.client_name !== arr[i - 1]?.client_name ||
                  (arr[i]?.client_name === arr[i - 1]?.client_name &&
                    arr[i]?.msg_date !== arr[i - 1]?.msg_date)
                    ? msg.client_name
                    : null}
                </span>
                &nbsp;&nbsp;
                {arr[i]?.client_name !== arr[i - 1]?.client_name ||
                (arr[i]?.client_name === arr[i - 1]?.client_name &&
                  arr[i]?.msg_date !== arr[i - 1]?.msg_date) ? (
                  <span className="text-xs dark:text-whiteish">
                    {msg.msg_time.split(":")[0]}:{msg.msg_time.split(":")[1]}
                  </span>
                ) : null}
              </p>
              {arr[i]?.client_name !== arr[i - 1]?.client_name ||
              (arr[i]?.client_name === arr[i - 1]?.client_name &&
                arr[i]?.msg_date !== arr[i - 1]?.msg_date) ? (
                <>
                  {msg.reported === "false" && msg.deleted === "false" && (
                    <div
                      className="hover:bg-[#effaff] dark:text-whiteish dark:hover:bg-neutral-400/30 transition rounded w-full pl-[3px] py-1.5 relative"
                      onMouseEnter={() => {
                        setShowIcon(i);
                      }}
                      onMouseLeave={() => {
                        setShowIcon(-1);
                      }}
                    >
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={searchWord}
                        autoEscape={true}
                        textToHighlight={`${msg.msg}`}
                      />
                      {showIcon === i && (
                        <>
                          {msg.client_id_send === userID ? (
                            <Trash
                              className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                              onClick={() => {
                                setSelectedMessage(msg);
                                setShowModalDeleteMsg(true);
                              }}
                            />
                          ) : (
                            <Warning
                              className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                              onClick={() => {
                                setSelectedMessage(msg);
                                setShowModalReportMsg(true);
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {msg.deleted !== "false" && (
                    <div className="hover:bg-[#effaff] dark:text-whiteish dark:hover:bg-neutral-400/30 w-full pl-[3px] py-1.5 relative">
                      <div className="flex flex-col border-l-2 border-red-500 pl-[15px] italic">
                        <p>{t("employer.messages.message_removed")}.</p>
                      </div>
                    </div>
                  )}
                  {msg.reported !== "false" &&
                    msg.client_id_send !== userID &&
                    msg.deleted === "false" && (
                      <div
                        className="hover:bg-[#effaff] dark:text-whiteish dark:hover:bg-neutral-400/30  w-full pl-[3px] py-1.5 relative"
                        onMouseEnter={() => {
                          setShowIcon(i);
                        }}
                        onMouseLeave={() => {
                          setShowIcon(-1);
                        }}
                      >
                        <>
                          {msg.reported === "Show Anyway" ? (
                            <>
                              <img
                                className="exclamation mb-3"
                                src={exclamation}
                                alt="hey"
                              />
                              <Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={searchWord}
                                autoEscape={true}
                                textToHighlight={`${msg.msg}`}
                              />
                            </>
                          ) : (
                            <div className="flex flex-col border-l-2 border-red-500 pl-[15px]">
                              <p>
                                {`This message was reported by you. You told us "${msg.reported}"`}
                              </p>
                              <p
                                className="text-azulOscuro cursor-pointer hover:underline w-fit"
                                onClick={() => {
                                  setData((current: any) =>
                                    current?.map(
                                      (obj: { chat_message_id: string }) => {
                                        if (
                                          obj.chat_message_id ===
                                          msg.chat_message_id
                                        ) {
                                          return {
                                            ...obj,
                                            reported: "Show Anyway",
                                          };
                                        }
                                        return obj;
                                      }
                                    )
                                  );
                                  // setRefreshConversation(true);
                                }}
                              >
                                {t("employer.messages.show_anyway")}
                              </p>
                            </div>
                          )}
                        </>
                        {showIcon === i && (
                          <>
                            <div className="relative">
                              <div
                                className={` ${
                                  showCancelReportPopover === i &&
                                  " transition scale-x-100"
                                } ${
                                  showCancelReportPopover !== i &&
                                  "scale-x-0 -translate-x-7"
                                }  duration-300  absolute right-10 -top-14 w-fit
                          `}
                              >
                                <div
                                  className={`absolute right-0 -bottom-11 font-light text-xs rounded-lg border border-gray-200 shadow-[0px_0px_5px_1px_rgba(0,0,0,0.2)] bg-white`}
                                >
                                  <div className="flex flex-col px-6 py-2 items-center whitespace-nowrap">
                                    <p>
                                      {t("employer.messages.cancel_report")}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`bg-white  border-r-2 border-t-2 border-gray-200 z-20 w-3 h-3 top-5 rotate-45 absolute -right-[7px] `}
                                ></div>
                              </div>
                            </div>
                            <Warning
                              className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                              onMouseEnter={() => {
                                setShowCancelReportPopover(i);
                              }}
                              onMouseLeave={() =>
                                setShowCancelReportPopover(-1)
                              }
                              onClick={() => {
                                handleCancelReport(msg);
                              }}
                            />
                          </>
                        )}
                      </div>
                    )}
                  {msg.reported !== "false" &&
                    msg.client_id_send === userID &&
                    msg.deleted === "false" && (
                      <div
                        className="hover:bg-[#effaff] dark:text-whiteish w-full pl-[3px] py-1.5 relative"
                        onMouseEnter={() => {
                          setShowIcon(i);
                        }}
                        onMouseLeave={() => {
                          setShowIcon(-1);
                        }}
                      >
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchWord}
                          autoEscape={true}
                          textToHighlight={`${msg.msg}`}
                        />
                        {showIcon === i && (
                          <>
                            {msg.client_id_send === userID ? (
                              <Trash
                                className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                                onClick={() => {
                                  setSelectedMessage(msg);
                                  setShowModalDeleteMsg(true);
                                }}
                              />
                            ) : (
                              <Warning
                                className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                                onClick={() => {
                                  setSelectedMessage(msg);
                                  setShowModalReportMsg(true);
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            {msg.reported === "false" && msg.deleted === "false" && (
              <div
                className="relative text-sm ml-[60px] pl-[3px] py-1.5 h-fit hover:bg-[#effaff] dark:text-whiteish dark:hover:bg-neutral-400/30 transition rounded"
                onMouseEnter={() => setShowIcon(i)}
                onMouseLeave={() => setShowIcon(-1)}
              >
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={searchWord}
                  autoEscape={true}
                  textToHighlight={`${msg.msg}`}
                />
                {showIcon === i && (
                  <>
                    {msg.client_id_send === userID ? (
                      <Trash
                        className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                        onClick={() => {
                          setSelectedMessage(msg);
                          setShowModalDeleteMsg(true);
                        }}
                      />
                    ) : (
                      <Warning
                        className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                        onClick={() => {
                          setSelectedMessage(msg);
                          setShowModalReportMsg(true);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            )}
            {msg.deleted !== "false" && (
              <div className="hover:bg-[#effaff] w-full  py-1.5 relative text-sm pl-[63px] dark:hover:bg-neutral-400/30 transition">
                <div className="flex flex-col border-l-2 border-red-500 pl-[15px] italic">
                  <p>{t("employer.messages.message_removed")}.</p>
                </div>
              </div>
            )}
            {msg.reported !== "false" &&
              msg.client_id_send !== userID &&
              msg.deleted === "false" && (
                <div
                  className="relative text-sm ml-[60px] pl-[3px]  py-1.5 h-fit hover:bg-[#effaff] dark:hover:bg-neutral-400/30 transition"
                  onMouseEnter={() => setShowIcon(i)}
                  onMouseLeave={() => setShowIcon(-1)}
                >
                  <>
                    {msg.reported === "Show Anyway" ? (
                      <>
                        <img
                          className="exclamation mb-3"
                          src={exclamation}
                          alt="hey"
                        />
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchWord}
                          autoEscape={true}
                          textToHighlight={`${msg.msg}`}
                        />
                      </>
                    ) : (
                      <div className="flex flex-col border-l-2 border-red-500 pl-[15px]">
                        <p>
                          {`This message was reported by you. You told us "${msg.reported}"`}
                        </p>
                        <p
                          className="text-azulOscuro cursor-pointer hover:underline w-fit"
                          onClick={() => {
                            setData((current: any) =>
                              current?.map(
                                (obj: { chat_message_id: string }) => {
                                  if (
                                    obj.chat_message_id === msg.chat_message_id
                                  ) {
                                    return {
                                      ...obj,
                                      reported: "Show Anyway",
                                    };
                                  }
                                  return obj;
                                }
                              )
                            );
                          }}
                        >
                          {t("employer.messages.show_anyway")}
                        </p>
                      </div>
                    )}
                  </>
                  {showIcon === i && (
                    <>
                      <div className="relative">
                        <div
                          className={` ${
                            showCancelReportPopover === i &&
                            "transition scale-x-100"
                          } ${
                            showCancelReportPopover !== i &&
                            "scale-x-0 -translate-x-7"
                          } duration-300 absolute right-10 -top-14 w-fit`}
                        >
                          <div
                            className={`absolute right-0 -bottom-11 font-light text-xs rounded-lg border border-gray-200 shadow-[0px_0px_5px_1px_rgba(0,0,0,0.2)] bg-white`}
                          >
                            <div className="flex flex-col px-6 py-2 items-center whitespace-nowrap">
                              <p>{t("employer.messages.cancel_report")}</p>
                            </div>
                          </div>
                          <div
                            className={`bg-white border-r-2 border-t-2 border-gray-200 z-20 w-3 h-3 top-5 rotate-45 absolute -right-[7px]`}
                          ></div>
                        </div>
                      </div>
                      <Warning
                        className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                        onMouseEnter={() => setShowCancelReportPopover(i)}
                        onMouseLeave={() => setShowCancelReportPopover(-1)}
                        onClick={() => handleCancelReport(msg)}
                      />
                    </>
                  )}
                </div>
              )}
            {msg.reported !== "false" &&
              msg.client_id_send === userID &&
              msg.deleted === "false" && (
                <div
                  className="relative text-sm ml-[60px] pl-[3px]  py-1.5 h-fit hover:bg-[#effaff] dark:hover:bg-neutral-400/30 transition"
                  onMouseEnter={() => setShowIcon(i)}
                  onMouseLeave={() => setShowIcon(-1)}
                >
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={searchWord}
                    autoEscape={true}
                    textToHighlight={`${msg.msg}`}
                  />
                  {showIcon === i && (
                    <>
                      {msg.client_id_send === userID ? (
                        <Trash
                          className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                          onClick={() => {
                            setSelectedMessage(msg);
                            setShowModalDeleteMsg(true);
                          }}
                        />
                      ) : (
                        <Warning
                          className="absolute h-5 right-0 top-1.5 cursor-pointer mr-2 fill-slate-500 p-1 overflow-visible rounded-full"
                          onClick={() => {
                            setSelectedMessage(msg);
                            setShowModalReportMsg(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default ConversationChat;
