import React, {
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  ElementRef,
} from "react";
import { useEffect } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import "react-circular-progressbar/dist/styles.css";
import { allWorkerExployments, whichModal } from "../../Atoms/jotaiAtoms";
import {
  BASE_URL,
  BASE_URL_IMG,
  WORKER_PROFILE_PROGRESS,
} from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";

import { ReactComponent as Completed } from "../../assets/img/circle-check.svg";
import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";

import { ReactComponent as ArrowUp } from "../../assets/img/arrow-up-solid.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/arrow-right.svg";
import { ReactComponent as Circle } from "../../assets/img/circle-border.svg";
import { useQuery } from "react-query";
import { CompleteProfile, UserInfoTypes } from "src/types/types";
import { useTranslation } from "react-i18next";

interface ModalProfileCompletenessProps {
  showCompletenessModal: boolean;
  setShowCompletenessModal: Dispatch<SetStateAction<boolean>>;
  percentage: number;
  userInfo: UserInfoTypes;
}

function ModalProfileCompleteness({
  showCompletenessModal,
  setShowCompletenessModal,
  userInfo,
  percentage,
}: ModalProfileCompletenessProps) {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [openModal, setOpenModal] = useAtom(whichModal);
  const [allEmployments, setAllEmployments] = useAtom(allWorkerExployments);
  const [showCompleted, setShowCompleted] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [completenessData, setCompletenessData] = useState<CompleteProfile>();
  const [transition, setTransition] = useState(false);
  const { t } = useTranslation();
  let counter = -8;

  const getCompletenessData = async () => {
    const data = await fetch(
      `${BASE_URL}${WORKER_PROFILE_PROGRESS}?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      // .then((data) => setCompletenessData(data.result))
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: completionProfile } = useQuery(
    ["profile_progress", userID],
    getCompletenessData
  );

  useEffect(() => {
    setCompletenessData(completionProfile);
  }, [completionProfile]);

  const handleInputChange = (option: string) => {
    setSelectedOption(option);
  };
  const navigate = useNavigate();
  const ref = useRef<ElementRef<"div">>(null);
  const handleClick = (option: string) => {
    switch (option) {
      case "Employment History":
        navigate("/profile");
        setOpenModal("Employment History");
        return;
      case "Education":
        navigate("/profile");
        setOpenModal("Education");
        return;

      case "Video introduction":
        navigate("/profile");
        setOpenModal("Video introduction");
        return;

      case "Profile Photo":
        navigate("/profile");
        setOpenModal("Profile Photo");
        return;
      case "Overview":
        navigate("/profile");
        setOpenModal("Overview");
        return;
      case "Skills":
        navigate("/profile");
        setOpenModal("Skills");
        return;
    }
  };

  useEffect(() => {
    if (completenessData) {
      Object.values(completenessData)?.map((value) => {
        if (value !== 0) {
          counter = counter + 1;
        }
      });
    }
  }, [completenessData]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showCompletenessModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showCompletenessModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowCompletenessModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-[38%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          {" "}
          <div className="flex">
            <div className="flex-[0_0_40%] max-w-[40%]  mt-4 border-r">
              <div className="flex flex-col items-center">
                <div className="p-2 mt-24 w-fit relative mb-2">
                  <img
                    src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
                    alt="profile"
                    className="h-32  w-32 object-cover rounded-full m-auto"
                  ></img>
                  <CircularProgressbar
                    value={percentage}
                    // strokeWidth="6"
                    styles={buildStyles({ pathColor: "#2abeed" })}
                    className="h-[142px] w-[133px] absolute top-[2px] right-[2.5px]  bg-transparent"
                  />
                </div>
                <p className="text-sm mb-4 text-slate-700">
                  {percentage}% complete
                </p>
                <p className="text-lg font-bold mb-12">
                  {percentage === 100 ? "Well done!" : "You're on your way"}
                </p>
              </div>
            </div>
            <div className="flex-[0_0_60%] max-w-[60%] mt-4">
              <div className="flex flex-col p-6">
                <h3 className="text-lg font-bold tracking-wide">
                  {t("modal.profile_completeness.title")}
                </h3>
                <p className="text-sm mt-2">
                  {t("modal.profile_completeness.description")}
                </p>
                <form className="mt-6">
                  {completenessData?.education === 0 && (
                    <div
                      className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                      onClick={() => handleClick("Education")}
                      key={"education"}
                    >
                      <div className="relative cursor-pointer">
                        <label
                          className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Education" && ""
                          }`}
                        >
                          <input
                            type="radio"
                            value={"Education"}
                            checked={selectedOption === "Education"}
                            onChange={() => handleInputChange("Education")}
                          />{" "}
                          <Circle
                            className="h-5 absolute overflow-visible -left-0 bg-white rounded-full w-fit"
                            fill={"#2abeed"}
                          />
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm font-bold tracking-wide">
                              {"Education"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t("modal.profile_completeness.include_degrees")}{" "}
                              ({completenessData?.educationValue}%)
                            </p>
                          </div>
                        </label>
                      </div>
                      <ArrowRight
                        className="h-4 pr-2 cursor-pointer"
                        fill={"#b2b2b2"}
                      />
                    </div>
                  )}
                  {completenessData?.experience === 0 && (
                    <div
                      className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                      onClick={() => handleClick("Employment History")}
                      key={"Employment History"}
                    >
                      <div className="relative cursor-pointer">
                        <label
                          className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Employment History" && ""
                          }`}
                        >
                          <input
                            type="radio"
                            value={"Employment History"}
                            checked={selectedOption === "Employment History"}
                            onChange={() =>
                              handleInputChange("Employment History")
                            }
                          />
                          <Circle
                            className="h-5 absolute overflow-visible -left-0 bg-white rounded-full w-fit"
                            fill={"#2abeed"}
                          />
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm  font-bold tracking-wide">
                              {"Employment History"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t("modal.profile_completeness.past_jobs")} (
                              {completenessData?.experienceValue}%)
                            </p>
                          </div>
                        </label>
                      </div>
                      <ArrowRight
                        className="h-4 pr-2 cursor-pointer"
                        fill={"#b2b2b2"}
                      />
                    </div>
                  )}
                  {completenessData?.languages === 0 && (
                    <div
                      className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                      onClick={() => handleClick("Languages")}
                      key={"Languages"}
                    >
                      <div className="relative cursor-pointer">
                        <label
                          className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Languages" && ""
                          }`}
                        >
                          <input
                            type="radio"
                            value={"Languages"}
                            checked={selectedOption === "Languages"}
                            onChange={() => handleInputChange("Languages")}
                          />{" "}
                          <Circle
                            className="h-5 absolute overflow-visible -left-0 bg-white rounded-full w-fit"
                            fill={"#2abeed"}
                          />
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm font-bold tracking-wide">
                              {"Languages"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t("modal.profile_completeness.known_languages")}{" "}
                              ({completenessData?.languagesValue}%)
                            </p>
                          </div>
                        </label>
                      </div>
                      <ArrowRight
                        className="h-4 pr-2 cursor-pointer"
                        fill={"#b2b2b2"}
                      />
                    </div>
                  )}
                  {completenessData?.overview === 0 && (
                    <div
                      className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                      onClick={() => handleClick("Overview")}
                      key={"Overview"}
                    >
                      <div className="relative cursor-pointer">
                        <label
                          className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Overview" && ""
                          }`}
                        >
                          <input
                            type="radio"
                            value={"Overview"}
                            checked={selectedOption === "Overview"}
                            onChange={() => handleInputChange("Overview")}
                          />{" "}
                          <Circle
                            className="h-5 absolute overflow-visible -left-0 bg-white rounded-full w-fit"
                            fill={"#2abeed"}
                          />
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm font-bold tracking-wide">
                              {"Overview"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t(
                                "modal.profile_completeness.bio_hightlighting"
                              )}{" "}
                              ({completenessData?.overviewValue}%)
                            </p>
                          </div>
                        </label>
                      </div>
                      <ArrowRight
                        className="h-4 pr-2 cursor-pointer"
                        fill={"#b2b2b2"}
                      />
                    </div>
                  )}
                  {completenessData?.profileImage === 0 && (
                    <div
                      className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                      onClick={() => handleClick("Profile Photo")}
                      key={"Profile Photo"}
                    >
                      <div className="relative cursor-pointer">
                        <label
                          className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Profile Photo" && ""
                          }`}
                        >
                          <input
                            type="radio"
                            value={"Profile Photo"}
                            checked={selectedOption === "Profile Photo"}
                            onChange={() => handleInputChange("Profile Photo")}
                          />{" "}
                          <Circle
                            className="h-5 absolute overflow-visible -left-0 bg-white rounded-full w-fit"
                            fill={"#2abeed"}
                          />
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm font-bold tracking-wide">
                              {"Profile Photo"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t(
                                "modal.profile_completeness.take_professional"
                              )}{" "}
                              ({completenessData?.profileImageValue}%)
                            </p>
                          </div>
                        </label>
                      </div>
                      <ArrowRight
                        className="h-4 pr-2 cursor-pointer"
                        fill={"#b2b2b2"}
                      />
                    </div>
                  )}
                  {completenessData?.profileVideo === 0 && (
                    <div
                      className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                      onClick={() => handleClick("Video introduction")}
                      key={"Video introduction"}
                    >
                      <div className="relative cursor-pointer">
                        <label
                          className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Video introduction" && ""
                          }`}
                        >
                          <input
                            type="radio"
                            value={"Video introduction"}
                            checked={selectedOption === "Video introduction"}
                            onChange={() =>
                              handleInputChange("Video introduction")
                            }
                          />{" "}
                          <Circle
                            className="h-5 absolute overflow-visible -left-0 bg-white rounded-full w-fit"
                            fill={"#2abeed"}
                          />
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm font-bold tracking-wide">
                              {"Video introduction"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t("modal.profile_completeness.short_intro")} (
                              {completenessData?.profileVideoValue}%)
                            </p>
                          </div>
                        </label>
                      </div>
                      <ArrowRight
                        className="h-4 pr-2 cursor-pointer"
                        fill={"#b2b2b2"}
                      />
                    </div>
                  )}
                  {completenessData?.skills === 0 && (
                    <div
                      className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                      onClick={() => handleClick("Skills")}
                      key={"Skills"}
                    >
                      <div className="relative cursor-pointer">
                        <label
                          className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Skills" && ""
                          }`}
                        >
                          <input
                            type="radio"
                            value={"Skills"}
                            checked={selectedOption === "Skills"}
                            onChange={() => handleInputChange("Skills")}
                          />{" "}
                          <Circle
                            className="h-5 absolute overflow-visible -left-0 bg-white rounded-full w-fit"
                            fill={"#2abeed"}
                          />
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm font-bold tracking-wide">
                              {"Skills"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t(
                                "modal.profile_completeness.showcase_expertise"
                              )}
                              ({completenessData?.skillsValue}%)
                            </p>
                          </div>
                        </label>
                      </div>
                      <ArrowRight
                        className="h-4 pr-2 cursor-pointer"
                        fill={"#b2b2b2"}
                      />
                    </div>
                  )}
                </form>
                <div
                  className="flex items-start px-14 py-3 mt-6 border-t border-b cursor-pointer"
                  onClick={() => setShowCompleted(!showCompleted)}
                >
                  <div className="flex flex-col">
                    <p className="text-sm mr-4 text-[#2abeed] font-bold hover:underline hover:text-azulOscuro">
                      {t("modal.profile_completeness.show_completed")} (
                      {counter})
                    </p>
                    <p className="text-slate-700 text-xs">
                      {t("modal.profile_completeness.items_list")}
                    </p>
                  </div>
                  {showCompleted === true ? (
                    <ArrowUp className="h-4 mt-1" fill={"#2abeed"} />
                  ) : (
                    <ArrowDown className="h-4 mt-1" fill={"#2abeed"} />
                  )}
                </div>
                {showCompleted === true && (
                  <>
                    {completenessData?.education !== 0 && (
                      <div
                        className="mx-6 relative"
                        onClick={() => handleClick("Education")}
                        key={"Education"}
                      >
                        <label
                          className={`flex cursor-pointer hover:bg-blancoGrisClaro items-center gap-4 text-[15px] p-1.5 my-2 ${
                            selectedOption === "Education" &&
                            "bg-blancoGrisClaro"
                          }`}
                        >
                          <input
                            type="radio"
                            readOnly
                            value={"Education"}
                            checked={true}
                          />{" "}
                          <div className="flex flex-col hover:text-azulBonico">
                            <p className="text-sm font-bold tracking-wide">
                              {"Education"}
                            </p>
                            <p className="text-xs text-slate-700">
                              {t("modal.profile_completeness.include_degrees")}(
                              {completenessData?.educationValue}%)
                            </p>
                          </div>
                          <Completed
                            fill={"#2abeed"}
                            className="h-5 z-10 absolute -left-0 rounded-full bg-white w-fit "
                          />
                        </label>
                      </div>
                    )}
                    {completenessData?.experience !== 0 && (
                      <div
                        className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                        onClick={() => handleClick("Employment History")}
                        key={"Employment History"}
                      >
                        <div className="relative cursor-pointer">
                          <label
                            className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                              selectedOption === "Employment History" && ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={"Employment History"}
                              checked={selectedOption === "Employment History"}
                              onChange={() =>
                                handleInputChange("Employment History")
                              }
                            />{" "}
                            <Completed
                              fill={"#2abeed"}
                              className="h-5 z-10 absolute -left-0 rounded-full bg-white w-fit "
                            />
                            <div className="flex flex-col hover:text-azulBonico">
                              <p className="text-sm font-bold tracking-wide">
                                {"Employment History"}
                              </p>
                              <p className="text-xs text-slate-700">
                                {t("modal.profile_completeness.past_jobs")} (
                                {completenessData?.experienceValue}%)
                              </p>
                            </div>
                          </label>
                        </div>
                        <ArrowRight
                          className="h-4 pr-2 cursor-pointer"
                          fill={"#b2b2b2"}
                        />
                      </div>
                    )}
                    {completenessData?.languages !== 0 && (
                      <div
                        className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                        onClick={() => handleClick("Languages")}
                        key={"Languages"}
                      >
                        <div className="relative cursor-pointer">
                          <label
                            className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                              selectedOption === "Languages" && ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={"Languages"}
                              checked={selectedOption === "Languages"}
                              onChange={() => handleInputChange("Languages")}
                            />{" "}
                            <Completed
                              fill={"#2abeed"}
                              className="h-5 z-10 absolute -left-0 rounded-full bg-white w-fit "
                            />
                            <div className="flex flex-col hover:text-azulBonico">
                              <p className="text-sm font-bold tracking-wide">
                                {"Languages"}
                              </p>
                              <p className="text-xs text-slate-700">
                                {t(
                                  "modal.profile_completeness.known_languages"
                                )}{" "}
                                ({completenessData?.languagesValue}%)
                              </p>
                            </div>
                          </label>
                        </div>
                        <ArrowRight
                          className="h-4 pr-2 cursor-pointer"
                          fill={"#b2b2b2"}
                        />
                      </div>
                    )}
                    {completenessData?.overview !== 0 && (
                      <div
                        className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                        onClick={() => handleClick("Overview")}
                        key={"Overview"}
                      >
                        <div className="relative cursor-pointer">
                          <label
                            className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                              selectedOption === "Overview" && ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={"Overview"}
                              checked={selectedOption === "Overview"}
                              onChange={() => handleInputChange("Overview")}
                            />{" "}
                            <Completed
                              fill={"#2abeed"}
                              className="h-5 z-10 absolute -left-0 rounded-full bg-white w-fit "
                            />
                            <div className="flex flex-col hover:text-azulBonico">
                              <p className="text-sm font-bold tracking-wide">
                                {"Overview"}
                              </p>
                              <p className="text-xs text-slate-700">
                                {t(
                                  "modal.profile_completeness.bio_hightlighting"
                                )}{" "}
                                ({completenessData?.overviewValue}%)
                              </p>
                            </div>
                          </label>
                        </div>
                        <ArrowRight
                          className="h-4 pr-2 cursor-pointer"
                          fill={"#b2b2b2"}
                        />
                      </div>
                    )}
                    {completenessData?.profileImage !== 0 && (
                      <div
                        className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                        onClick={() => handleClick("Profile Photo")}
                        key={"Profile Photo"}
                      >
                        <div className="relative cursor-pointer">
                          <label
                            className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                              selectedOption === "Profile Photo" && ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={"Profile Photo"}
                              checked={selectedOption === "Profile Photo"}
                              onChange={() =>
                                handleInputChange("Profile Photo")
                              }
                            />{" "}
                            <Completed
                              fill={"#2abeed"}
                              className="h-5 z-10 absolute -left-0 rounded-full bg-white w-fit "
                            />
                            <div className="flex flex-col hover:text-azulBonico">
                              <p className="text-sm font-bold tracking-wide">
                                {"Profile Photo"}
                              </p>
                              <p className="text-xs text-slate-700">
                                {t(
                                  "modal.profile_completeness.take_professional"
                                )}{" "}
                                ({completenessData?.profileImageValue}%)
                              </p>
                            </div>
                          </label>
                        </div>
                        <ArrowRight
                          className="h-4 pr-2 cursor-pointer"
                          fill={"#b2b2b2"}
                        />
                      </div>
                    )}
                    {completenessData?.profileVideo !== 0 && (
                      <div
                        className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                        onClick={() => handleClick("Video introduction")}
                        key={"Video introduction"}
                      >
                        <div className="relative cursor-pointer">
                          <label
                            className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                              selectedOption === "Video introduction" && ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={"Video introduction"}
                              checked={selectedOption === "Video introduction"}
                              onChange={() =>
                                handleInputChange("Video introduction")
                              }
                            />{" "}
                            <Completed
                              fill={"#2abeed"}
                              className="h-5 z-10 absolute -left-0 rounded-full bg-white w-fit "
                            />
                            <div className="flex flex-col hover:text-azulBonico">
                              <p className="text-sm font-bold tracking-wide">
                                {"Video introduction"}
                              </p>
                              <p className="text-xs text-slate-700">
                                {t("modal.profile_completeness.short_intro")} (
                                {completenessData?.profileVideoValue}%)
                              </p>
                            </div>
                          </label>
                        </div>
                        <ArrowRight
                          className="h-4 pr-2 cursor-pointer"
                          fill={"#b2b2b2"}
                        />
                      </div>
                    )}
                    {completenessData?.skills !== 0 && (
                      <div
                        className="mx-6 flex items-center justify-between hover:bg-blancoGrisClaro cursor-pointer"
                        onClick={() => handleClick("Skills")}
                        key={"Skills"}
                      >
                        <div className="relative cursor-pointer">
                          <label
                            className={`flex cursor-pointer items-center w-full gap-4 text-[15px] p-1.5 my-2 ${
                              selectedOption === "Skills" && ""
                            }`}
                          >
                            <input
                              type="radio"
                              value={"Skills"}
                              checked={selectedOption === "Skills"}
                              onChange={() => handleInputChange("Skills")}
                            />{" "}
                            <Completed
                              fill={"#2abeed"}
                              className="h-5 z-10 absolute -left-0 rounded-full bg-white w-fit "
                            />
                            <div className="flex flex-col hover:text-azulBonico">
                              <p className="text-sm font-bold tracking-wide">
                                {"Skills"}
                              </p>
                              <p className="text-xs text-slate-600">
                                Showcase your expertise (
                                {completenessData?.skillsValue}%)
                              </p>
                            </div>
                          </label>
                        </div>
                        <ArrowRight
                          className="h-4 pr-2 cursor-pointer"
                          fill={"#b2b2b2"}
                        />
                      </div>
                    )}
                  </>
                )}

                <div className="w-full flex justify-end mt-8">
                  <button
                    className="bg-azulBonico border-none items-end font-semibold hover:bg-azulOscuro  text-white w-[145px] rounded-[20px] text-[0.9rem] px-[1rem] py-0 cursor-pointer transition duration-300 h-10"
                    onClick={() => closeModal()}
                  >
                    {t("modal.profile_completeness.close")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalProfileCompleteness;
