import React, { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "src/Components/ui/Button";
import { useModalStore } from "src/Hooks/useModalStore";
import { cn } from "src/lib/utils";
import { FireIcon } from "@heroicons/react/24/solid";
import FlexiCoin from "src/assets/img/flexicoin.png";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { getCreditsTypes } from "src/Routing/useCreditsActions";

interface SendOfferProps {
  sendOffer: () => void;
}

const ModalSendOffer = ({ sendOffer }: SendOfferProps) => {
  const [showModalPayOffer, setShowModalPayOffer] = useModalStore((state) => [
    state.showPayForOffer,
    state.setShowPayForOffer,
  ]);
  const ref = useRef<HTMLDivElement>(null);
  const { featureJob } = getCreditsTypes();
  const { creditsDataInfo, updateCredits } = useUpdateCredits(
    featureJob?.credits,
    featureJob?.id
  );
  const { t } = useTranslation();

  const handleClose = () => setShowModalPayOffer(showModalPayOffer);
  const handleCancel = () => setShowModalPayOffer(showModalPayOffer);

  const sendWorker = () => {
    updateCredits();
  };

  return (
    <Modal isOpen={showModalPayOffer} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-lg font-medium">Send Offer</h1>
          <Button
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
            onClick={() => handleClose()}
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-6">
          Take the next step in building your ideal team by sending a
          personalized offer to standout candidates. Reaching out directly with
          an offer shows your commitment and genuine interest, making a lasting
          impression on top talent.
        </h3>
        <div className="flex justify-end w-full py-6 px-8 ">
          <Button
            onClick={() => sendOffer()}
            className="bg-azulBonico px-6 py-2 flex items-center gap-1 text-white peer"
          >
            {t("wallet.credits_send")}
          </Button>

          <div className="absolute bottom-16  scale-0 bg-gray-100 rounded px-2 peer-hover:scale-100 transition">
            <div className="flex items-center gap-2">
              <div>2€</div>
              <img src={FlexiCoin} className="h-4 w-4 " />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSendOffer;
