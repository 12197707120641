import React, { useRef, useEffect, Dispatch, SetStateAction } from "react";
import deal from "../../assets/img/flexiExp4.png";
import jobPost from "../../assets/img/flexiExp1.png";
import chatting from "../../assets/img/flexiExp3.png";
import talent from "../../assets/img/flexiExp2.png";
import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import autoAnimate from "@formkit/auto-animate";
import { useNavigate } from "react-router-dom";

interface StepsRightContainerProps {
  showInstructionsCollapsed: boolean;
  setShowInstructionsCollapsed: Dispatch<SetStateAction<boolean>>;
}

export default function StepsRightContainer({
  showInstructionsCollapsed,
  setShowInstructionsCollapsed,
}: StepsRightContainerProps) {
  const { t } = useTranslation();
  const parent = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const knowFlexiStep1 =
    typeof t("employer.home.rightbar.knowflexi.step1.link")?.split("<b>")[0] ===
      "string" &&
    `${parse(
      t("employer.home.rightbar.knowflexi.step1.link").split("<b>")[0]
    )}`;
  const knowFlexiStep2 =
    typeof t("employer.home.rightbar.knowflexi.step1.link")?.split("<b>")[1] ===
      "string" &&
    `${parse(
      t("employer.home.rightbar.knowflexi.step1.link").split("<b>")[1]
    )}`;

  const knowFlexiFooter =
    typeof t("employer.home.rightbar.knowflexi.footer.link")?.split(
      "<b>"
    )[0] === "string" &&
    t("employer.home.rightbar.knowflexi.footer.link").split("<b>")[0];

  const knowFlexiFooter2 =
    typeof t("employer.home.rightbar.knowflexi.footer.link")
      ?.split("<b>")[1]
      ?.split("</b>")[0] === "string" &&
    t("employer.home.rightbar.knowflexi.footer.link")
      .split("<b>")[1]
      .split("</b>")[0];

  return (
    <div
      className={`bg-white dark:bg-background w-[450px] text-black dark:text-whiteish rounded-lg shadow dark:border-border dark:border `}
      ref={parent}
    >
      <div
        className="flex flex-col justify-between p-10 "
        onClick={() => setShowInstructionsCollapsed(!showInstructionsCollapsed)}
      >
        <div className="flex justify-between items-center cursor-pointer">
          <h1 className="tablet:text-xl text-lg font-semibold">
            {parse(t("employer.home.rightbar.knowflexi.title"))}
          </h1>
          <ArrowDown
            className={`h-3 fill-black dark:fill-white ${
              showInstructionsCollapsed
                ? "animate-toArrowUp  rotate-180"
                : "animate-toArrowDown "
            }`}
          />
        </div>
        <p className="mt-2 mb-0 tablet:text-sm text-xs">
          {t("employer.home.rightbar.knowflexi.subtitle")}
        </p>
      </div>
      {showInstructionsCollapsed && (
        <>
          <div className="border-y flex flex-col lg:flex-row items-center  p-8 dark:border-border">
            <></>
            <img
              src={jobPost}
              alt="jobpost"
              className="w-auto h-20 mr-8 mb-5 lg:mb-0"
            ></img>
            <div>
              <h3 className=" tablet:text-base text-sm">
                {parse(t("employer.home.rightbar.knowflexi.step1.title"))}
              </h3>
              <p className="tablet:text-sm text-xs mt-2">
                {parse(t("employer.home.rightbar.knowflexi.step1.text"))}
              </p>
              <p className="text-azulBonico text-sm lg:text-[15px]">
                <>{knowFlexiStep1}</>
                <button
                  className="hover:underline"
                  onClick={() => navigate("/how-to-employer-guide")}
                >
                  {knowFlexiStep2}
                </button>
                {/* <a
                  // href={`${HOST}/how-to-employer-guide`}
                  href={`${HOST}/how-to-employer-guide`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold hover:underline cursor-pointer "
                  // onClick={() => navigate("/how-to-worker-guide")}
                >
                  {knowFlexiStep2}
                </a> */}
                .
              </p>
            </div>
          </div>
          <div className="border-b flex flex-col lg:flex-row items-center dark:border-border p-8">
            <></>
            <img
              src={talent}
              alt="talent"
              className="w-auto h-20 mr-8 mb-5 lg:mb-0"
            ></img>
            <div>
              <h3 className=" tablet:text-base text-sm">
                {parse(t("employer.home.rightbar.knowflexi.step2.title"))}
              </h3>
              <p className="tablet:text-sm text-xs mt-2">
                {parse(t("employer.home.rightbar.knowflexi.step2.text"))}
              </p>
            </div>
          </div>
          <div className="border-b flex flex-col lg:flex-row items-center dark:border-border p-8">
            <></>
            <img
              src={chatting}
              alt="chatting"
              className="w-auto h-20 mr-8 mb-5 lg:mb-0"
            ></img>
            <div>
              <h3 className="tablet:text-base text-sm">
                {parse(t("employer.home.rightbar.knowflexi.step3.title"))}
              </h3>
              <p className="tablet:text-sm text-xs  mt-2">
                {t("employer.home.rightbar.knowflexi.step3.text")}
              </p>
            </div>
          </div>
          <div className="border-b flex flex-col lg:flex-row items-center dark:border-border p-8">
            <></>
            <img src={deal} alt="deal" className="w-auto h-20 mr-8"></img>
            <div>
              <h3 className=" tablet:text-base text-sm">
                {parse(t("employer.home.rightbar.knowflexi.step4.title"))}
              </h3>
              <p className="tablet:text-sm text-xs mt-2">
                {parse(t("employer.home.rightbar.knowflexi.step4.text"))}
              </p>
            </div>
          </div>
          <div className=" p-10">
            <></>
            <h3 className=" tablet:text-base text-sm">
              {parse(t("employer.home.rightbar.knowflexi.footer.title"))}
            </h3>
            <p className="tablet:text-sm text-xs">
              {knowFlexiFooter}
              <span className="text-azulBonico hover:text-azulOscuro hover:underline cursor-pointer">
                {knowFlexiFooter2}
              </span>
              {
                t("employer.home.rightbar.knowflexi.footer.link").split(
                  "</b>"
                )[1]
              }
            </p>
          </div>
        </>
      )}
    </div>
  );
}
