import React, { useRef } from "react";
import { useModalStore } from "src/Hooks/useModalStore";
import { Button } from "../ui/Button";
import { AiOutlineClose } from "react-icons/ai";
import { useAtom } from "jotai";
import { jobFeatured } from "src/Atoms/jotaiAtoms";
import { useTranslation } from "react-i18next";

const ModalEditFeaturedJob = () => {
  const { t } = useTranslation();
  const [showModalEditFeatured, setShowModalEditFeatured] = useModalStore(
    (state) => [state.showModalFeatured, state.setShowModalFeatured]
  );
  const [editFeatureJob, setEditFeatureJob] = useAtom(jobFeatured);

  const modalRef = useRef<HTMLDivElement>(null);
  const handleClose = () => setShowModalEditFeatured(showModalEditFeatured);

  //   const handleChangeFeatured = () => {};

  return (
    <div
      className="fixed top-0 right-0  w-full h-full bg-black/50 text-black z-50"
      ref={modalRef}
    >
      <div className="fixed  z-[50] w-[90%] lg:w-[28%] left-[50%] transition shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-lg font-medium">
            {t("employer.applicants.job_post.featured_modal.title")}
          </h1>
          <Button
            onClick={() => handleClose()}
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-6">
          {t("employer.applicants.job_post.featured_modal.subtitle")}
        </h3>

        <div className="flex flex-col items-start px-8 py-4">
          <div
            onClick={() => setEditFeatureJob(true)}
            className="flex items-center"
          >
            <input checked={editFeatureJob} type="checkbox" className="mr-3" />
            <p className="text-sm">
              {t("employer.applicants.job_post.featured_modal.featured")}
            </p>
          </div>
          <div
            onClick={() => setEditFeatureJob(false)}
            className="flex items-center"
          >
            <input checked={!editFeatureJob} type="checkbox" className="mr-3" />
            <p className="text-sm">
              {t("employer.applicants.job_post.featured_modal.not_featured")}
            </p>
          </div>
        </div>
        <div className="flex items-center w-full justify-end px-8 py-4">
          <Button
            onClick={() => handleClose()}
            className="bg-none text-[0.9rem] w-24 h-10 border  text-azulBonico    mt-1 mr-4 rounded-full "
          >
            {t("employer.applicants.job_post.featured_modal.cancel")}
          </Button>
          <Button
            onClick={() => handleClose()}
            className="bg-none text-[0.9rem] w-24 h-10 border hover:bg-azulOscuro bg-azulBonico text-white rounded-full   mt-1 "
          >
            {t("employer.applicants.job_post.featured_modal.save")}
          </Button>
        </div>
        {/* {sendWithNote && (
          <div className="px-8 py-6 ">
            <textarea
              className="border border-border w-full h-36 p-3"
              placeholder=""
            />
          </div>
        )}
        {!sendWithNote ? (
          <div className="border-t border-border flex items-center justify-end w-full gap-4 px-8 py-2">
            <Button
              onClick={() => setSendWithNote(!sendWithNote)}
              className="bg-none text-[0.9rem] w-24 h-10 border  text-azulBonico rounded   mt-1 "
            >
              Add a note
            </Button>
            <Button className="bg-none text-[0.9rem] w-24 h-10 border bg-azulBonico text-white rounded   mt-1 ">
              Send now
            </Button>
          </div>
        ) : (
          <div className="border-t border-border flex items-center justify-end w-full gap-4 px-8 py-2">
            <Button
              onClick={() => setSendWithNote(false)}
              className="bg-none text-[0.9rem] w-24 h-10 border text-azulBonico rounded mt-1"
            >
              Cancel
            </Button>
            <Button className="bg-none text-[0.9rem] w-24 h-10 border bg-azulBonico text-white rounded   mt-1 ">
              Send
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ModalEditFeaturedJob;
