import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { HireTypes } from "src/types/types";
import DisplayHiresNewCard from "./DisplayHiresNewCard";
import { ModalProps } from "../Modals/ModalWorkerCard";
import { ReactComponent as Search } from "../../assets/img/search2.svg";
import { useTranslation } from "react-i18next";
import { BsSliders } from "react-icons/bs";
import Select from "react-select";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { HiOutlineDownload } from "react-icons/hi";
import { debounce } from "src/Utils/debounce";
import PaginationContracts from "./PaginationContracts";
import CSVDownloader from "react-csv-downloader";

interface DisplayHiresNewProps {
  allContracts: HireTypes[];
  getWorkerInfo: (arg: string) => void;
  setClickedWorker: any;
  setShowModal: Dispatch<SetStateAction<ModalProps>>;
  getHiredWorkers: (arg: string) => void;
  handleSearch: (args: string) => void;
  limit: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  rows: number;
  setSortParams: Dispatch<SetStateAction<string>>;
  setOrderSort: Dispatch<SetStateAction<{ value: string; label: string }>>;
  orderSort: { value: string; label: string };
  orderSortOptions: { value: string; label: string }[];
}

const DisplayHiresNew = ({
  allContracts,
  setClickedWorker,
  getWorkerInfo,
  setShowModal,
  handleSearch,
  getHiredWorkers,
  limit,
  page,
  setPage,
  rows,
  totalPages,
  setSortParams,
  orderSort,
  setOrderSort,
  orderSortOptions,
}: DisplayHiresNewProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [pageNumbers, setPageNumbers] = useState<any>();
  const [columns, setColumns] = useState<any>();
  const [datas, setDatas] = useState<any>();

  const optimisedSearch = useCallback(debounce(handleSearch), []);

  const goToPreviousPage = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (totalPages) {
      setPageNumbers([...Array(totalPages + 1).keys()]?.slice(1));
    }
  }, [totalPages]);

  const changePage = (page: any) => {
    setPage(page);
  };

  const goToNextPage = () => {
    setPage(page + 1);
  };

  const goToLastPage = () => {
    setPage(totalPages);
  };

  const sortOptions = [
    {
      value: "date_end",
      label: t("worker.my_jobs.all_contracts.sort1"),
    },
    {
      value: "date_created",
      label: t("worker.my_jobs.all_contracts.sort2"),
    },
    {
      value: "connection_id",
      label: t("worker.my_jobs.all_contracts.sort3"),
    },
    {
      value: "budget",
      label: t("worker.job_search.sort.budget"),
    },
  ];

  const selectStyles = {
    control: () => ({
      width: "100%",
      cursor: "pointer",
    }),
  };
  useEffect(() => {
    if (allContracts?.length > 0) {
      setColumns(
        Object.keys(allContracts[0])?.map((entry) => {
          return {
            id: entry,
            displayName: entry,
          };
        })
      );
    }
  }, [allContracts]);

  useEffect(() => {
    setDatas(Object.values(allContracts));
  }, [allContracts]);

  return (
    <div>
      <div
        className="relative my-8 ml-6  flex items-center"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") getHiredWorkers();
        // }}
      >
        <div className="w-[95%] relative">
          <input
            type="text"
            ref={inputRef}
            className=" border-2 w-full  border-blue-100 h-10 pl-4 outline-none text-sm rounded hover:border-blue-200 focus:border-blue-200 transition duration-300 dark:bg-input dark:border-border"
            placeholder={`${t("employer.messages.leftbar.search.placeholder")}`}
            onChange={(e) => {
              optimisedSearch(e.target.value);
            }}
            // onKeyDown={() => getHiredWorkers()}
          />
          <div
            className="h-10 w-9 bg-azulBonico hover:bg-azulOscuro transition duration-300 absolute right-0 top-0 rounded-r cursor-pointer"
            onClick={() => getHiredWorkers("")}
          >
            <Search
              className="h-4 cursor-pointer absolute top-[12px] left-[10px]"
              fill={"white"}
            />
          </div>
        </div>
        <div className="flex items-center mx-6">
          <BsSliders size={17} color={"#2abeeb"} />
          <p className="text-azulBonico font-medium ml-2 text-sm">
            {t("employer.applicant_filters.title")}
          </p>
        </div>
      </div>
      <div className="ml-6 py-4 flex items-center justify-between lg:pr-6 w-full ">
        <div className="flex flex-col lg:flex-row w-full lg:items-center">
          <p className="text-sm font-medium">
            {t("employer.alljobs.sortbyDropdown.sortby")}
          </p>
          <div className="border-2 border-gray-200   text-sm lg:ml-4 mt-4 lg:mt-0 rounded-lg flex items-center justify-between w-60">
            {/* {t("employer.received_offer.start_date")}
            <ChevronDownIcon className="h-4 w-4" /> */}
            <Select
              className=" py-0.5 text-sm ml-4 rounded-lg flex items-center justify-between w-60"
              styles={selectStyles}
              options={sortOptions}
              // className="text-sm dark:text-black "
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              placeholder={`${t(
                "employer.alljobs.sortbyDropdown.placeholder"
              )}`}
              onChange={(e: any) => {
                setPage(1);

                setSortParams(e.value);
              }}
            />
            <ChevronDownIcon className="h-4 w-4 mr-2" />
          </div>
          <div className="border-2 border-gray-200   text-sm lg:ml-4 mt-4 lg:mt-0 rounded-lg flex items-center justify-between w-60">
            {/* {t("employer.hires.sort.descending")} */}
            <Select
              className=" py-0.5 text-sm ml-4 rounded-lg flex items-center justify-between w-60"
              styles={selectStyles}
              options={orderSortOptions}
              // className="text-sm dark:text-black "
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              placeholder={`${t(
                "employer.alljobs.sortbyDropdown.placeholder"
              )}`}
              onChange={(e: any) => {
                setPage(1);

                setOrderSort(e);
              }}
            />
            <ChevronDownIcon className="h-4 w-4 mr-2" />
          </div>
          <div className="flex items-center mx-2 mt-4 lg:mt-0 text-xs text-gray-500">
            <p>
              {" "}
              {rows}{" "}
              <span className="lowercase"> {t("admin.analytics.total")}</span>{" "}
            </p>
          </div>
          {columns && (
            <div className="text-sm text-azulBonico flex items-center justify-end ml-auto pr-6   font-medium lg:mr-0">
              <HiOutlineDownload size={20} className="mr-1" />
              <CSVDownloader
                datas={datas}
                columns={columns}
                filename="hires"
                extension=".csv"
                wrapColumnChar=""
                text={`${t("privacy_policy.download")} CSV`}
              />
              {/* <p>{t("privacy_policy.download")} CSV</p> */}
            </div>
          )}
        </div>
      </div>
      {allContracts?.map((contract: HireTypes) => (
        <DisplayHiresNewCard
          key={contract.connection_id}
          contract={contract}
          setClickedWorker={setClickedWorker}
          getWorkerInfo={getWorkerInfo}
          setShowModal={setShowModal}
        />
      ))}
      <PaginationContracts
        changePage={changePage}
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
        limit={limit}
        page={page}
        pageNumbers={pageNumbers}
        rows={rows}
        totalPages={totalPages}
      />

      {/* </div> */}
    </div>
  );
};

export default DisplayHiresNew;
