import React, { useState, SetStateAction, useRef, useEffect } from "react";
import parse from "html-react-parser";
import { UserInfoTypes } from "../../../types/types";
import ProfileCompleteness from "../../../Components/WorkerDashboard/ProfileCompleteness";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import edit from "../../../assets/img/edit.png";
import ModalEditAvailability from "../../../Components/Modals/ModalEditAvailability";
import ModalEditCategory from "../../../Components/Modals/ModalEditCategory";
import ModalHowToUseWorker from "../../../Components/Modals/ModalHowToUseWorker";
import { useTranslation } from "react-i18next";
import { Category } from "../../../Utils/services/apiCalls";
import { Skeleton } from "../../../Components/ui/skeleton";
import {
  BASE_URL,
  BASE_URL_IMG,
  INFO_WORKER,
  PROFILE_WORKER,
} from "src/Config/api.config";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import { useModalStore } from "src/Hooks/useModalStore";

import { cn } from "src/lib/utils";
import { useEmployerStore } from "src/Routing/useProfileStore";
import Flexicoin from "src/assets/img/flexicoin-nobg.png";
import { convertCreditsToEUR } from "src/Utils/conversionEur";
import { useWalletSore } from "src/Hooks/useWalletStore";
import TextureProfile from "src/assets/texture/texture-profile.jpg";
import { useQuery } from "react-query";

interface DashboardRightContainerProps {
  workerCategories: Category[];
  setWorkerCategories: React.Dispatch<SetStateAction<any>>;
  appliedCounter: { applied: number; active: number; offered: number };
  setAppliedCounter: React.Dispatch<
    SetStateAction<
      { applied: number; active: number; offered: number } | undefined
    >
  >;
  loadingWorkerCategories: boolean;
  loadingAppliedJobsNumber: boolean;
  refetchCategories: () => void;
}

function DashboardRightContainer({
  setWorkerCategories,
  workerCategories,
  appliedCounter,
  loadingWorkerCategories,
  loadingAppliedJobsNumber,
  refetchCategories,
}: DashboardRightContainerProps) {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const [numberOfSavedJobs, setNumberOfSavedJobs] = useState();
  const [showModalAvailability, setShowModalAvailability] = useState(false);
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [availability, setAvailability] = useLocalStorage(
    "Availability",
    "Available now"
  );
  const [showEditJobType, setShowEditJobType] = useState(false);
  const [showCreditsMenu, setShowCreditsMenu] = useModalStore((state) => [
    state.showCreditsMenu,
    state.setShowCreditsMenu,
  ]);
  const modalRef = useRef<HTMLDivElement>(null);
  const [loadingCredits, setLoadingCredits] = useState(false);
  const modal = useWalletSore();
  const [userID, setUserId] = useLocalStorage("IDUser", "");

  useOnClickOutside(modalRef, () => {
    if (showCreditsMenu) {
      setShowCreditsMenu(showCreditsMenu);
    }
  });

  const getWorkerData = async () => {
    const data = await fetch(
      `${BASE_URL}${PROFILE_WORKER}?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setUserInfo((prev: any) => ({
          ...prev,
          profile_image: data.result.workerProfile.profile_image,
        }));
      })

      .catch((err) => console.log(err));

    return data;
  };

  useEffect(() => {
    getWorkerData();
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getWorkerInformation = async () => {
    const data = await fetch(`${BASE_URL}${INFO_WORKER}?client_id=${userID}`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: profileData,
    isLoading,
    refetch: refetchWorkerData,
  } = useQuery(["worker_info"], getWorkerInformation);
  return (
    <div className="hidden laptop:!block flex-[0_0_25%] max-w-[25%] p-2 mt-4  ">
      <div className="rounded relative dark:bg-background mr-2  dark:text-whiteish">
        <div className=" flex flex-col items-center m-auto p-4 bg-white rounded shadow mb-4">
          <img
            src={TextureProfile}
            className="h-10 absolute top-0 w-full object-cover z-10 rounded-t"
          />
          <img
            src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
            alt="profile"
            className="h-24  w-24 object-cover rounded-full z-20"
          />
          <p
            className="font-semibold underline mt-2 cursor-pointer tracking-wider"
            onClick={() => navigate("/profile")}
          >
            {profileData?.data[0]?.first_name?.charAt(0).toUpperCase() +
              profileData?.data[0]?.first_name?.slice(1)}{" "}
            {profileData?.data[0]?.last_name}
          </p>
          <p className="text-sm mt-1">{profileData?.data[0]?.title}</p>
        </div>
        {/* <div className="flex items-center justify-center relative rounded  bg-white dark:bg-background shadow py-2 mb-4">
          <div
            className={cn(
              "flex items-center gap-2 cursor-pointer   dark:bg-input py-2 px-2 rounded"
            )}
          >
            <p className="text-sm flex items-center gap-1">
              {!loadingCredits ? (
                <>
                  <span className="font-bold dark:text-whiteish">
                    {creditsNumber}
                  </span>
                  <img src={Flexicoin} className="h-5 w-6 object-contain " />
                </>
              ) : (
                <Skeleton className="w-20 h-4" />
              )}
              <span>|</span>

              <p className="font-semibold dark:text-whiteish text-gray-400 text-[12px]">
                {convertCreditsToEUR(creditsNumber)}
              </p>
            </p>
          </div>
        </div> */}
        <ProfileCompleteness userInfo={userInfo} />
        <div className="flex flex-col p-6 text-sm bg-white rounded shadow mt-4">
          <div>
            <div className="flex items-center justify-between">
              <p className="font-semibold">
                {t("worker.right_container_dashboard.availability")}
              </p>
              <img
                src={edit}
                alt="edit"
                className="h-3 cursor-pointer"
                onClick={() => setShowModalAvailability(true)}
              />
            </div>
            <p
              className={`mt-1 cursor-pointer font-bold hover:bg-[#f7f4f4] border rounded-xl px-3 border-slate-200 w-fit ${
                availability === "Available now"
                  ? "text-[#14A800]"
                  : availability === "Listening offers..."
                  ? "text-[#FF7D01]"
                  : "text-[#94b8a3]"
              }`}
              onClick={() => setShowModalAvailability(true)}
            >
              {availability}
            </p>
          </div>
          {showModalAvailability === true && (
            <ModalEditAvailability
              setShowModalAvailability={setShowModalAvailability}
              showModalAvailability={showModalAvailability}
              setAvailability={setAvailability}
              availability={availability}
            />
          )}
          {/* <div className="mt-4">
                  <div className="flex items-center justify-between">
                    <p className="font-bold">Hours per week</p>
                    <img src={edit} alt="edit" className="h-3" />
                  </div>
                  <p>More than 30 hrs/week</p>
                </div> */}
          {/* <div className="mt-4">
            <div className="flex items-center justify-between">
              <p className="font-bold">
                {t("worker.right_container_dashboard.job_type")}
              </p>
              <img
                src={edit}
                alt="edit"
                className="h-3 cursor-pointer"
                onClick={() => setShowEditJobType(true)}
              />
              {showEditJobType === true && (
                <ModalEditJobType
                  setShowModal={setShowEditJobType}
                  showEditJobType={showEditJobType}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  refresh={"true"}
                />
              )}
            </div>
            <p>{userInfo?.job_type_name}</p>
          </div> */}
        </div>
        <div className="flex flex-col p-6 text-sm font-bold border-border border shadow mt-4 bg-white">
          <div className="flex items-center justify-between ">
            <p>{t("worker.find_work.dashboard.my_categories")}</p>
            <img
              src={edit}
              alt="edit"
              className="h-3 cursor-pointer"
              onClick={() => setShowModalCategory(true)}
            />
            {showModalCategory === true && (
              <ModalEditCategory
                workerCategories={workerCategories}
                setWorkerCategories={setWorkerCategories}
                setShowModal={setShowModalCategory}
                showEditCategoriesModal={showModalCategory}
                refetchCategories={refetchCategories}
              />
            )}
          </div>
          <div className=" gap-1 flex flex-col mt-4">
            {workerCategories?.map(
              (category: { category_id: string; name: string }) => {
                if (!loadingWorkerCategories) {
                  return (
                    <p
                      className="text-azulOscuro text-sm font-medium cursor-pointer"
                      key={category.category_id}
                    >
                      {category.name}
                    </p>
                  );
                } else {
                  return (
                    <Skeleton
                      key={category.category_id}
                      className="h-4 w-[60%]"
                    />
                  );
                }
              }
            )}
          </div>
        </div>
      </div>

      <div className="rounded shadow  bg-white  mt-6 dark:bg-background dark:text-whiteish border-border border mr-2">
        <div className="flex flex-col p-6 border-b border-blue-100">
          <p className=" font-bold text-xl tracking-wider">
            {" "}
            {t("worker.right_container_dashboard.applied_jobs.title")}{" "}
          </p>
        </div>
        {appliedCounter?.applied === 0 &&
        appliedCounter?.active === 0 &&
        appliedCounter?.offered === 0 ? (
          <p className="text-sm p-6">
            {t("worker.right_container_dashboard.applied_jobs.desc")}
          </p>
        ) : (
          <>
            <div className="pt-6 px-6 pb-3 cursor-pointer ">
              {loadingAppliedJobsNumber ? (
                <Skeleton className="h-3 w-1/2" />
              ) : (
                <p
                  className="text-sm text-azulBonico font-medium hover:underline  hover:text-azulOscuro w-fit"
                  onClick={() => navigate("/applied-jobs")}
                >
                  {appliedCounter?.offered}{" "}
                  {t("worker.right_container_dashboard.applied_jobs.offers")}
                </p>
              )}
            </div>
            <div className=" px-6 pb-3 cursor-pointer">
              {loadingAppliedJobsNumber ? (
                <Skeleton className="h-3 w-1/2" />
              ) : (
                <p
                  className="text-sm text-azulBonico  font-medium  hover:underline  hover:text-azulOscuro w-fit"
                  onClick={() => navigate("/applied-jobs")}
                >
                  {appliedCounter?.active}{" "}
                  {t("worker.right_container_dashboard.applied_jobs.active")}
                </p>
              )}
            </div>
            <div className="px-6 pb-6 cursor-pointer">
              {loadingAppliedJobsNumber ? (
                <Skeleton className="h-3 w-1/2" />
              ) : (
                <p
                  className="text-sm text-azulBonico  font-medium hover:underline  hover:text-azulOscuro w-fit"
                  onClick={() => navigate("/applied-jobs")}
                >
                  {appliedCounter?.applied}{" "}
                  {t("worker.right_container_dashboard.applied_jobs.applied")}
                </p>
              )}
            </div>
          </>
        )}
      </div>
      <div className="rounded shadow mt-4 bg-white  mr-2 dark:bg-background dark:border-border dark:border dark:text-whiteish">
        <div className="flex flex-col p-6">
          <p className="font-medium">
            {parse(t("worker.home.rightbar.knowflexi.title"))}
          </p>
          <p className="text-azulBonico text-sm">
            {typeof t("worker.home.rightbar.knowflexi.subtitle.link").split(
              "<b>"
            )[0] === "string" && (
              <>
                {
                  t("worker.home.rightbar.knowflexi.subtitle.link").split(
                    "<b>"
                  )[0]
                }
              </>
            )}
            <span
              className="font-medium hover:underline cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              {typeof t("worker.home.rightbar.knowflexi.subtitle.link").split(
                "<b>"
              )[1] === "string" && (
                <>
                  {parse(
                    t("worker.home.rightbar.knowflexi.subtitle.link")
                      .split("<b>")[1]
                      ?.toString()
                  )}
                </>
              )}
            </span>
          </p>
        </div>
        {showModal && (
          <ModalHowToUseWorker
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </div>
  );
}

export default DashboardRightContainer;
