import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, PROFILE_WORKER } from "../../Config/api.config";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";

interface ModalEditTitleProps {
  setShowEditTitleModal: React.Dispatch<React.SetStateAction<boolean>>;
  showEditTitleModal: boolean;
  workerDataTitle: string | undefined;
  refetchData: () => void;
}

function ModalEditTitle({
  setShowEditTitleModal,
  workerDataTitle,
  showEditTitleModal,
  refetchData,
}: ModalEditTitleProps) {
  const { t } = useTranslation();
  const [title, setTitle] = useState<any>("");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTitle(workerDataTitle);
  }, []);

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("title", title);
  const handleTitle = (e: string) => {
    setTitle(e);
  };

  useEffect(() => {
    setTitle(workerDataTitle);
  }, []);

  const updateTitle = () => {
    fetch(`${BASE_URL}${PROFILE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowEditTitleModal(false);
          refetchData();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditTitleModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditTitleModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowEditTitleModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  return (
    <Modal
      isOpen={showEditTitleModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.35)",
        },
        content: {
          position: "absolute",
          top: "-20px",
          left: "40px",
          right: "40px",
          bottom: "0px",
          border: "none",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          backgroundColor: "transparent",
        },
      }}
      ariaHideApp={false}
    >
      {" "}
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50"> */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[90%] lg:w-[700px] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edityourTitle.title")}
        </div>
        <div className="p-8">
          <p className="font-bold tracking-wide">
            {t("createprofile.submit.edityourTitle.subtitle")}
          </p>
          <p className="text-sm">
            {t("createprofile.submit.edityourTitle.description")}
          </p>
          <input
            type="text"
            value={title}
            className="w-full mt-6 border h-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
            placeholder={`${t(
              "createprofile.submit.edityourworkexperience.subtitle1.card.placeholder"
            )}`}
            onChange={(e) => handleTitle(e.target.value)}
          />
        </div>

        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico hover:bg-blancoGrisClaro  mt-1 "
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourTitle.leftBtn")}
          </Button>
          <Button
            className={`border-none  w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]  h-10 ${
              !title
                ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
            } `}
            disabled={!title}
            onClick={(e) => {
              updateTitle();
              // setTimeout(() => {
              //   setShowEditTitleModal(false);
              //   window.location.reload(false);
              // }, 200);
            }}
          >
            {t("createprofile.submit.edityourTitle.rightBtn")}
          </Button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ModalEditTitle;
