import { useEffect, useState } from "react";
import { create } from "zustand";

export const actionTypeKey = "actionType";

interface CreditsType {
  id: string;
  name: string;
  credits: string;
}

interface ActionsState {
  actionTypes: CreditsType[] | any;
  setActionTypes: (actions: CreditsType[]) => void;
}

export const useCreditsActions = create<ActionsState>((set) => ({
  actionTypes: localStorage.getItem(actionTypeKey) as any,
  setActionTypes: async (actiontypes) => {
    try {
      await localStorage.setItem(actionTypeKey, JSON.stringify(actiontypes));
    } catch (error) {}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    set({ actiontypes });
  },
}));
// export const useCreditsActions = create(
//   persist(
//     (set, get) => ({
//       actionTypes: [],
//       setActionTypes: () => set({ actionTypes: get().actionTypes }),
//     }),
//     {
//       name: actionTypeKey,
//       storage: createJSONStorage(() => sessionStorage),
//     }
//   )
// );

interface ParamsTypes {
  id: string;
  name: string;
  value: string;
}

interface FormatState {
  formatTypes: ParamsTypes[];
  setFormatTypes: (formats: ParamsTypes[]) => void;
}

export const paramsTypeKey = "paramType";

export const useFormatActions = create<FormatState>((set) => ({
  formatTypes: localStorage.getItem(paramsTypeKey) as any,
  setFormatTypes: async (formatTypes) => {
    try {
      await localStorage.setItem(paramsTypeKey, JSON.stringify(formatTypes));
    } catch (error) {
      set({ formatTypes });
    }
  },
}));

const INITIAL_STATE = {
  id: "",
  credits: "",
  name: "",
};

export const getCreditsTypes = () => {
  const [typesArray, setTypesArray] = useState<CreditsType[]>([]);

  const [creditActions, setCreditActions] = useCreditsActions((state) => [
    state.actionTypes,
    state.setActionTypes,
  ]);
  const [postJob, setPostJob] = useState<CreditsType>(INITIAL_STATE);
  const [cloneJob, setCloneJob] = useState<CreditsType>(INITIAL_STATE);
  const [inviteJob, setInviteJob] = useState<CreditsType>(INITIAL_STATE);
  const [viewProfileJob, setViewProfileJob] =
    useState<CreditsType>(INITIAL_STATE);
  const [chatJob, setChatJob] = useState<CreditsType>(INITIAL_STATE);
  const [featureJob, setFeatureJob] = useState<CreditsType>(INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTypesArray(JSON.parse(creditActions));
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [creditActions]);

  useEffect(() => {
    if (typesArray?.length > 0) {
      const POST_JOB = typesArray?.filter((action) => action.id === "1")[0];
      const CLONE_JOB = typesArray?.filter((action) => action.id === "2")[0];

      const INVITE_JOB = typesArray?.filter((action) => action.id === "3")[0];

      const VIEW_PROFILE_JOB = typesArray?.filter(
        (action) => action.id === "4"
      )[0];

      const CHAT_JOB = typesArray?.filter((action) => action.id === "5")[0];
      const FEATURE_USER = typesArray?.filter((action) => action.id === "6")[0];

      setPostJob(POST_JOB);
      setCloneJob(CLONE_JOB);
      setInviteJob(INVITE_JOB);
      setViewProfileJob(VIEW_PROFILE_JOB);
      setChatJob(CHAT_JOB);
      setFeatureJob(FEATURE_USER);
    }
  }, [typesArray]);

  return {
    postJob,
    cloneJob,
    inviteJob,
    viewProfileJob,
    chatJob,
    featureJob,
    loading,
  };
};
