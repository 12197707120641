import React, { useState, useEffect } from "react";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";
import plus from "../../../assets/img/plus.png";
import { ReactComponent as Cross } from "../../../assets/img/cross.svg";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { useSkills } from "src/Hooks/useSkills";

function AddSkills() {
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [skillsSearch, setSkillsSearch] = useState();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [suggestedSkillsIntact, setSuggestedSkillsIntact] = useState([]);
  const [selectedCategoryWorker, setSelectedCategoryWorker] = useLocalStorage(
    "selectedCategoryWorker",
    []
  );
  const [allSkills, setAllSkills] = useState();

  const { refetchSkills, skillsData } = useSkills();

  useEffect(() => {
    setAllSkills(skillsData);
  }, [skillsData]);

  let availableSkills;
  if (allSkills) {
    availableSkills = allSkills
      ?.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
      ?.map(function (row, i, arr) {
        if (arr[i].name !== arr[i + 1]?.name) {
          return {
            value: row.name,
            label: row.name,
            skill_id: row.skill_id,
            name: row.name,
          };
        } else return null;
      })
      .filter(function (el) {
        return el != null;
      });
  }

  const colourStyles = {
    control: (styles) => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2ABEEB",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  //Request settings
  let data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append(
    "skill_list",
    skills?.map((skill) => skill.skill_id)
  );

  const workerSkills = () => {
    fetch(`${BASE_URL}worker.skill`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };
  const getWorkerCategories = () => {
    fetch(`${BASE_URL}worker.category?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setSelectedCategoryWorker(data.result))
      .catch((err) => console.log(err));
  };

  const getWorkerSkills = () => {
    fetch(`${BASE_URL}worker.skill?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setSkills(data.result))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getWorkerSkills();
  }, []);

  //Suggested skills in relation with chosen categories
  const workerSuggestedSkills = () => {
    const response = selectedCategoryWorker?.map(
      (category) => category.category_id
    );
    fetch(`${BASE_URL}job.skills?category_id_list=${response}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSuggestedSkills(data.result);
        setSuggestedSkillsIntact(data.result);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (selectedCategoryWorker.length > 0) {
      workerSuggestedSkills();
    }
  }, [selectedCategoryWorker]);
  useEffect(() => {
    getWorkerCategories();
  }, []);

  const addSkills = (skill, e, skillName) => {
    setSkillsSearch();
    let counter = 0;
    // ELIMINATE SUGGESTIONS ONCLICK
    // let y = e?.target.getAttribute("removesuggestion");
    setSuggestedSkills(
      suggestedSkills?.filter((items) => items.name !== skillName)
    );
    for (let i = 0; i <= skills?.length; i++) {
      if (skill.skill_id === skills[i]?.skill_id) {
        counter++;
      }
    }
    if (counter === 0) {
      return setSkills((current) => [...current, skill]);
    }
  };
  const removeHandler = (skill) => {
    // let x = e.target.getAttribute("removeskill");
    setSkills(skills.filter((items) => items.name !== skill.name));
    for (let i = 0; i < suggestedSkillsIntact.length; i++) {
      if (
        skill.name === suggestedSkillsIntact[i]?.name &&
        skill.name !== suggestedSkills[i]?.name
      ) {
        setSuggestedSkills((current) => [...current, skill]);
      }
    }
  };
  let navigate = useNavigate();
  const onNextClick = () => {
    workerSkills();
    navigate("/create-profile/languages");
  };

  return (
    <div className=" h-screen w-full !bg-white ">
      <RegistrationHeader />
      <div className="lg:max-w-2xl pt-12 mx-auto px-10 lg:px-0 min-h-[75vh]">
        <div className="text-xl lg:text-4xl font-bold">
          {t("createprofile.skills.title")}
        </div>
        <p className="pt-8 text-sm text-[#6e7081]">
          {t("createprofile.skills.subtitle")}
        </p>{" "}
        <Select
          options={availableSkills}
          styles={colourStyles}
          className="text-sm mt-4"
          // placeholder="Search skills..."
          placeholder={`${t("createprofile.skills.textPlaceholder")}`}
          onChange={(e) => addSkills(e)}
        />
        <div className="flex flex-wrap max-h-48 text-xs overflow-auto mt-6 mb-2 jobpost">
          {skills &&
            skills?.map((skill) => {
              return (
                <div
                  key={skill.skill_id}
                  className="flex items-center gap-2 cursor-pointer px-2 py-0.5 mx-0.5 text-white bg-azulBonico my-0.5 border rounded-xl hover:bg-[#292e38]"
                  onClick={() => removeHandler(skill)}
                >
                  <div
                    // removeskill={skill.name}
                    // onClick={(e) => removeHandler(e, skill)}
                    className="text-xs"
                  >
                    {skill.name}
                  </div>
                  <Cross className="h-[13px] cursor-pointer w-fit fill-white" />
                </div>
              );
            })}
        </div>
        <div className=" mt-12 font-bold">
          <p>{t("createprofile.skills.subTitle2")}</p>
        </div>
        <div className="flex flex-wrap mt-4">
          {suggestedSkills?.slice(0, 20)?.map((skill) => {
            return (
              <div
                key={skill.skill_id}
                className="flex items-center gap-2 cursor-pointer px-2 py-0.5 mx-0.5 text-[#343a47] bg-blancoGrisClaro hover:bg-[#bdbdbd] my-0.5 border rounded-xl"
                onClick={(e) => addSkills(skill, e, skill.name)}
              >
                <div className="text-xs">{skill.name}</div>
                <img
                  // removesuggestion={skill.name}
                  className="h-[10px] cursor-pointer w-fit fill-white"
                  src={plus}
                  alt="plus"
                ></img>
              </div>
            );
          })}
        </div>
      </div>
      <ProgressRegistration
        backButton={"create-profile/categories"}
        onNextClick={onNextClick}
        disabled={skills.length === 0 ? true : false}
        // nextButtonText={"Next, Languages"}
        nextButtonText={t("createprofile.skills.rightBtn")}
        progress="60"
      />
    </div>
  );
}

export default AddSkills;
