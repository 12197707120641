import React from "react";
import { useTranslation } from "react-i18next";

interface ProgressBarProps {
  bgcolor: string;
  height: number;
  progress: string;
  color1: string;
  color2: string;
  color3: string;
  color4?: string;
  color5?: string;
}

const Progress_bar = ({
  bgcolor,
  progress,
  height,
  color1,
  color2,
  color3,
  color4,
  color5,
}: ProgressBarProps) => {
  const { t } = useTranslation();
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "#2B5468",
    borderRadius: 40,
    marginTop: "2rem",
  };

  const Childdiv: any = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.7rem",
  };
  const headline = {
    color: color1,
  };
  const skills = {
    color: color2,
  };
  const scope = {
    color: color3,
  };
  const budget = {
    color: color4,
  };
  const times = {
    color: color5,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span></span>
      </div>
      <div style={progresstext}>
        <p style={headline}>{t("welcome-client.leftcard.timeline.headline")}</p>
        <p style={skills}>{t("welcome-client.leftcard.timeline.skills")}</p>
        <p style={scope}>{t("welcome-client.leftcard.timeline.jobtype")}</p>
        <p style={budget}>{t("welcome-client.leftcard.timeline.budget")}</p>
        <p style={times}>{t("welcome-client.leftcard.timeline.schedule")}</p>
      </div>
    </div>
  );
};

export default Progress_bar;
