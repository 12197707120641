import React, { useState, useRef, useEffect } from "react";

import useLocalStorage from "../../Hooks/UseLocalStorage";

import { ReactComponent as Circle } from "../../assets/img/circle.svg";
import { BASE_URL_IMG } from "src/Config/api.config";

export default function NavItem({
  visibilityStatus,
  children,
}: {
  visibilityStatus: { value: string };
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);
  return (
    <li className="w-[calc(60*0.8)]  flex items-center justify-end" ref={ref}>
      {" "}
      {userInfo?.profile_image ? (
        <a
          className="flex items-center justify-center p-1 m-1 rounded-full w-24 h-24 "
          onClick={() => setOpen(!open)}
        >
          <div className="absolute">
            <img
              src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
              alt="profile"
              className="h-12 w-12 object-cover border rounded-full mt-1"
            ></img>
            {(window.location.pathname === "/messages" ||
              window.location.pathname === "/messages-em") && (
              <Circle
                fill={`${
                  visibilityStatus?.value === "Online" ? "#14A800" : "#9c9d9d"
                }`}
                className="h-[14px] absolute bottom-[1px] right-0 border-[2px] border-white rounded-full"
              />
            )}
          </div>
        </a>
      ) : (
        <div className="relative">
          <p
            className="border-2 font-bold rounded-full border-black  text-black h-[44px] w-[44px] p-1 flex mr-2 justify-center items-center"
            onClick={() => setOpen(!open)}
          >
            {userInfo?.first_name?.slice(0, 1)}
            {userInfo?.last_name?.slice(0, 1)}
          </p>
          <div>
            {(window.location.pathname === "/messages" ||
              window.location.pathname === "/messages-em") && (
              <Circle
                fill={`${
                  visibilityStatus?.value === "Online" ? "#14A800" : "#9c9d9d"
                }`}
                className="h-[14px] absolute bottom-[0px] right-2 border-[2px] border-white rounded-full"
              />
            )}
          </div>
        </div>
      )}
      {open && children}
    </li>
  );
}
