import React, { useState, useEffect, lazy } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { BASE_URL, DRAFTS } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import Pagination from "../EmployerJobsPagination/Pagination";
import postings from "../../assets/img/postings.svg";

const Loader = lazy(() => import("src/Components/Loader/Loader"));
import { ReactComponent as Search } from "../../assets/img/search.svg";

import { useTranslation } from "react-i18next";
import { DraftTypes } from "../../types/types";
import DraftsSearchCard from "./DraftsSearchCard";
import GoBackButton from "../ui/GoBack";

const DraftsSearch = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loading, setLoading] = useState(false);
  const [draftsData, setDraftsData] = useState<DraftTypes[]>();
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const [clientSkills, setClientSkills] = useLocalStorage("clientSkills", []);
  const [selected, setSelected] = useLocalStorage("selected", "");
  const [pagination, setPagination] = useState({
    limit: 20,
    totalPages: "",
    totalPosts: "",
    currentPage: 1,
    pageNumbers: [],
    order: "date_updated",
    title: "",
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const sortOptions = [
    {
      value: "Updated",
      label: t("employer.alldrafts.sortbyDropdown.updated"),
      param: "date_updated",
    },
    {
      value: "Created",
      label: t("employer.alldrafts.sortbyDropdown.created"),
      param: "date_created",
    },
  ];
  const getDraftsFiltered = (param: string) => {
    setLoading(true);
    fetch(
      `${BASE_URL}${DRAFTS}?client_id=${userID}&limit=${
        pagination.limit
      }&page=${pagination.currentPage}${
        param ? `&order=${param}` : "&order=updated"
      }${pagination.title.length > 0 ? `&title=${pagination.title}` : ""}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setPagination((prev) => ({
            ...prev,
            totalPosts: data.paginate.rows,
            totalPages: data.paginate.pages,
          }));
          setDraftsData(data.result);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDraftsFiltered("");
  }, []);
  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "lightblue",
      },
      display: "flex",
      width: "120px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
    }),
  };

  const getDraftData = (id: string) => {
    fetch(`${BASE_URL}${DRAFTS}?draft_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          if (data.result.data[0].job_type == 1) {
            setSelected("presential");
          } else if (data.result.data[0].job_type == 2) {
            setSelected("remote");
          } else if (data.result.data[0].job_type == 3) {
            setSelected("hybrid");
          }
          setJobInfo(data.result.data[0]);
          setClientSkills(data.result.skills);
          navigate(`/job-draft-edit/${id}`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className=" flex justify-between mt-8 items-center px-6">
        <div
          className="flex items-center mt-6 gap-2 fill-azulBonico cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <GoBackButton />
        </div>
      </div>
      <div className=" bg-white rounded shadow  mt-12 mb-8 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex w-full  px-8 py-8 justify-between border-b border-blue-100 ">
          <div className="relative w-1/2">
            <input
              className="px-2 border-slate-200 dark:bg-input dark:border-border dark:border hover:border-blue-100 border text-sm w-full rounded h-10 outline-none focus:border-blue-100  transition duration-300"
              placeholder={`${t("employer.all_drafts.search_job_postings")}...`}
              onChange={(e) =>
                setPagination((prev) => ({
                  ...prev,
                  currentPage: 1,
                  title: e.target.value,
                }))
              }
            ></input>
            <Search className="h-5 absolute fill-slate-500 right-[0.5rem] top-[10px] cursor-pointer " />
          </div>
          <div className="flex items-center gap-3">
            <p className="text-sm ">{t("employer.all_drafts.sort_by")}:</p>
            <Select
              styles={colourStyles}
              options={sortOptions}
              className="text-sm dark:text-black"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              defaultValue={{
                label: sortOptions[0].label,
                value: sortOptions[0].value,
              }}
              onChange={(e: any) => {
                setPagination((prev) => ({ ...prev, currentPage: 1 }));
                getDraftsFiltered(e.param);
              }}
            />
          </div>
        </div>

        <div className="flex flex-col border-t border-blue-100  dark:border-border dark:border">
          <>
            {draftsData?.map((job) => {
              return (
                <DraftsSearchCard
                  job={job}
                  getDraftData={getDraftData}
                  key={job.job_id}
                />
              );
            })}
            {draftsData?.length === 0 && (
              <div className="flex h-52 flex-col items-center justify-center text-center bg-white  ">
                <img src={postings} alt="drafts" className="w-28 mb-4" />
                <h1 className="text-base font-semibold mb-2">
                  {t("employer.drafts.no_searches")}
                </h1>
              </div>
            )}
          </>
        </div>
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          getJobPostsFiltered={getDraftsFiltered}
        />
      </div>
    </>
  );
};

export default DraftsSearch;
