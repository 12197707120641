import React, { Dispatch, SetStateAction } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

type Pagination = {
  totalPages: number;
  limit: number;
  currentPage: number;
  pageNumbers: any;
};

interface PaginationCreditsProps {
  refetch: () => void;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<any>>;
}

const PaginationCredits = ({
  refetch,
  pagination,
  setPagination,
}: PaginationCreditsProps) => {
  function goToNextPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
    }));
    refetch();
  }
  function goToPreviousPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.currentPage - 1,
    }));
    refetch();
  }

  function goToFirstPage() {
    setPagination((prev: any) => ({ ...prev, currentPage: 1 }));
    refetch();
  }

  function changePage(page: any) {
    setPagination((prev: any) => ({ ...prev, currentPage: page }));
    refetch();
  }

  return (
    <>
      <div className="flex items-center w-full gap-4 justify-end px-8 bg-white">
        <button
          className={`hidden lg:block text-sm font-medium cursor-pointer  ${
            pagination.currentPage == 1
              ? "text-slate-400 cursor-not-allowed"
              : "text-azulBonico hover:text-azulOscuro"
          } `}
          onClick={() => goToFirstPage()}
          disabled={pagination.currentPage == 1}
        >
          1{/* {t("employer.alljobs.first.right.Text")} */}
        </button>
        <button
          className={`flex items-center gap-3 ${
            pagination.currentPage == 1
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }  `}
          onClick={() => goToPreviousPage()}
          disabled={pagination.currentPage == 1}
        >
          <GoChevronLeft
            className="h-5 w-5 "
            fill={`${
              pagination.currentPage == 1 ? "rgb(148 163 184)" : "#2abeeb"
            } `}
          />
        </button>
        <div className=" font-medium flex gap-3">
          {pagination.currentPage < 4 ? (
            <>
              {pagination.pageNumbers?.slice(0, 4)?.map((page: number) => {
                return (
                  <div key={page}>
                    {page === pagination.currentPage ? (
                      <div
                        className="text-azulBonico decoration-[2px]  underline underline-offset-[6px] cursor-pointer"
                        onClick={() => changePage(page)}
                      >
                        &nbsp;&nbsp;{page}&nbsp;&nbsp;
                      </div>
                    ) : (
                      <div
                        className="hover:text-azulOscuro hover:underline cursor-pointer"
                        onClick={() => changePage(page)}
                      >
                        &nbsp;{page}&nbsp;
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {pagination.currentPage >= 4 &&
              pagination.totalPages - 3 >= pagination.currentPage ? (
                <>
                  {pagination.pageNumbers
                    ?.slice(
                      pagination.currentPage - 2,
                      pagination.currentPage + 1
                    )
                    ?.map((page: number) => {
                      return (
                        <div key={page}>
                          {page === pagination.currentPage ? (
                            <div
                              className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                              onClick={() => changePage(page)}
                            >
                              &nbsp;&nbsp;{page}&nbsp;&nbsp;
                            </div>
                          ) : (
                            <div
                              className="hover:text-azulOscuro hover:underline cursor-pointer"
                              onClick={() => changePage(page)}
                            >
                              &nbsp;{page}&nbsp;
                            </div>
                          )}
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {pagination.totalPages - 3 < pagination.currentPage && (
                    <>
                      {pagination.pageNumbers
                        ?.slice(
                          pagination.totalPages - 4,
                          pagination.totalPages
                        )
                        ?.map((page: number) => {
                          return (
                            <div key={page}>
                              {page === pagination.currentPage ? (
                                <div
                                  className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                  onClick={() => changePage(page)}
                                >
                                  &nbsp;&nbsp;{page}
                                  &nbsp;&nbsp;
                                </div>
                              ) : (
                                <div
                                  className="hover:text-azulOscuro hover:underline cursor-pointer"
                                  onClick={() => changePage(page)}
                                >
                                  &nbsp;{page}&nbsp;
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <button
          className={`flex items-center gap-3 ${
            pagination.currentPage == pagination.totalPages
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }  `}
          onClick={() => goToNextPage()}
          disabled={pagination.currentPage == pagination.totalPages}
        >
          <GoChevronRight
            className="h-5 "
            fill={`${
              pagination.currentPage == pagination.totalPages
                ? "rgb(148 163 184)"
                : "#2abeeb"
            } `}
          />
        </button>
      </div>
    </>
  );
};

export default PaginationCredits;
