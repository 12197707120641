import React from "react";

import { VerticalLayout } from "../Worker/Layout/VerticalLayout";
import GoBackButton from "src/Components/ui/GoBack";
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { FaRegCalendarDays } from "react-icons/fa6";

const Transactions = () => {
  const navigate = useNavigate();

  const today = new Date();
  const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
  return (
    <VerticalLayout>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline mt-8 mx-4"
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>
      <div className="mt-10">
        <h1 className="text-4xl font-semibold">Transaction history</h1>
        <p className="text-2xl mt-4">
          Balance: <span className="text-azulOscuro">$0.00</span>
        </p>
        <div className="flex items-end justify-between mt-6">
          <div>
            <h3 className="text-sm">Statement period</h3>
            <div className="border-2 border-gray-200  px-2 rounded-xl mt-4 flex items-center space-x-3 py-2">
              <p className="text-sm">
                {today.toDateString()} - {nextMonth.toDateString()}
              </p>
              <FaRegCalendarDays className="h-4 w-4" />
            </div>
          </div>
          <div>
            <h3 className="text-sm">Transaction category</h3>
            <div className="border-2 border-gray-200  px-2 rounded-xl mt-4 flex items-center space-x-3 py-2">
              <p className="text-sm">All transactions</p>{" "}
              <BsChevronDown className="h-4 w-4" />
            </div>
          </div>
          <div>
            <h3 className="text-sm">Client</h3>
            <div className="border-2 border-gray-200  px-2 rounded-xl mt-4 flex items-center space-x-3 py-2">
              <p className="text-sm">All Clients</p>
              <BsChevronDown className="h-4 w-4" />
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <div className="bg-azulBonico text-white px-5 py-2 rounded-xl">
              <h3>Download CSV</h3>
            </div>
            <div className="bg-azulBonico text-white px-5 py-2 rounded-xl">
              <h3>Download invoices</h3>
            </div>
          </div>
        </div>
        <div className="bg-white mt-10 w-full h-60 flex items-center justify-center rounded-lg">
          <div className="flex flex-col items-center ">
            <img
              src={require("../../assets/img/folder.png")}
              className="h-32 w-32"
            />
            <p className="text-lg">
              No transactions meet your selected criteria
            </p>
          </div>
        </div>
      </div>
    </VerticalLayout>
  );
};

export default Transactions;
