import React, { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "src/Components/ui/Button";
import { useModalStore } from "src/Hooks/useModalStore";
import { cn } from "src/lib/utils";
import { FireIcon } from "@heroicons/react/24/solid";
import FlexiCoin from "src/assets/img/flexicoin.png";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { getCreditsTypes } from "src/Routing/useCreditsActions";

interface FeatureWorkerProps {
  onClick: () => void;
  featureStatus: boolean;
}

const ModalFeaturedProfile = ({
  onClick,
  featureStatus,
}: FeatureWorkerProps) => {
  const [showModalFeaturedProfile, setShowModalFeaturedProfile] = useModalStore(
    (state) => [
      state.showModalFeaturedProfile,
      state.setShowModalFeaturedProfile,
    ]
  );
  const ref = useRef<HTMLDivElement>(null);
  const { featureJob } = getCreditsTypes();
  const { creditsDataInfo, updateCredits } = useUpdateCredits(
    featureJob?.credits,
    featureJob?.id
  );
  const { t } = useTranslation();

  const handleClose = () =>
    setShowModalFeaturedProfile(showModalFeaturedProfile);
  const handleCancel = () =>
    setShowModalFeaturedProfile(showModalFeaturedProfile);

  const featureWorker = () => {
    onClick();
    updateCredits();
  };

  return (
    <Modal isOpen={showModalFeaturedProfile} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-lg font-medium">
            {t(
              "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.title"
            )}
          </h1>
          <Button
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
            onClick={() => handleClose()}
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-6">
          {t(
            "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.subtitle"
          )}
          .
        </h3>
        <div className="flex justify-end w-full py-6 px-8 ">
          {!featureStatus ? (
            <Button
              onClick={() => featureWorker()}
              className="bg-azulBonico px-4 py-1 flex items-center gap-1 text-white peer"
            >
              {t(
                "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.turn_on.btn"
              )}
              <FireIcon className="h-4 w-4" />
            </Button>
          ) : (
            <Button
              disabled
              className="bg-gray-100 px-4 py-1 flex items-center gap-1 text-[#333] disabled:cursor-not-allowed"
              // onClick={() => unfeatureWorker()}
            >
              {t("worker.leftbar.menu.item_my.profile.Featured_fireBtn")}
              <FireIcon className="h-4 w-4" />
            </Button>
          )}
          <div className="absolute bottom-24  scale-0 bg-gray-100 rounded px-2 peer-hover:scale-100 transition">
            <div className="flex items-center gap-2">
              <div>1</div>
              <img src={FlexiCoin} className="h-4 w-4 " />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFeaturedProfile;
