import React from "react";

import { ReactComponent as Invite } from "../../assets/img/messages.svg";
import { useTranslation } from "react-i18next";
import WorkerCards from "./WorkerCards";
import { ApplicantTypes } from "../../types/types";

interface InvitedWorkersProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData: any;
  setRefreshData: any;
  setSelectedSection: any;
  refetch: () => void;
}

const InvitedWorkers = ({
  setSelectedSection,
  applicantsData,
  setApplicantsData,
  setRefreshData,
  refetch,
}: InvitedWorkersProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {applicantsData?.length > 0 ? (
        <div>
          <WorkerCards
            refetch={refetch}
            applicantsData={applicantsData}
            setApplicantsData={setApplicantsData}
            setRefreshData={setRefreshData}
          />{" "}
        </div>
      ) : (
        <div className="flex flex-col w-full items-center justify-center my-12">
          <Invite className="h-32 mb-6" />
          <p className="text-lg font-bold tracking-wide">
            {t("employer.invite_workers.no_invite_workers")}
          </p>
          <p className="text-sm mt-4  ">
            <span
              className="text-azulBonico font-medium"
              onClick={() => setSelectedSection("search")}
            >
              {t("employer.invite_workers.invite")}
            </span>{" "}
            {t("employer.invite_workers.suitable_candidates")}
          </p>
        </div>
      )}
    </div>
  );
};

export default InvitedWorkers;
