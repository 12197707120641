import React, { Dispatch, SetStateAction } from "react";
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Modal from "react-modal";
import { BASE_URL, PROFILE_WORKER } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { UserInfoTypes } from "src/types/types";
import { Button } from "../ui/Button";

interface ModalEditJobTypeProps {
  userInfo: UserInfoTypes;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setUserInfo: Dispatch<SetStateAction<UserInfoTypes>>;
  showEditJobType: boolean;
}

function ModalEditJobType({
  userInfo,
  setShowModal,
  setUserInfo,
  // refresh,
  showEditJobType,
}: ModalEditJobTypeProps) {
  const [previousJobType, setPreviousJobType] = useState<{
    job_type_name: string;
    job_type: string;
  }>({ job_type: "", job_type_name: "" });

  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [cancelData, setCancelData] = useState(false);
  const [transition, setTransition] = useState(false);
  const [labelJobType, setLabelJobType] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  const { t } = useTranslation();

  const workType = [
    {
      value: "In-Person",
      label: t("worker.edit_job_type.modal.work_type.inperson"),
      id: 1,
    },
    {
      value: "Remote",
      label: t("worker.edit_job_type.modal.work_type.remote"),
      id: 2,
    },
    {
      value: "Hybrid",
      label: t("worker.edit_job_type.modal.work_type.hybrid"),
      id: 3,
    },
    {
      value: "It does not matter",
      label: t("worker.edit_job_type.modal.work_type.nomatter"),
      id: 4,
    },
  ];

  useEffect(() => {
    if (userInfo.job_type == 1) {
      setLabelJobType(`${t("worker.edit_job_type.modal.work_type.inperson")}`);
    } else if (userInfo.job_type === 2) {
      setLabelJobType(`${t("worker.edit_job_type.modal.work_type.remote")}`);
    } else if (userInfo.job_type === 3) {
      setLabelJobType(`${t("worker.edit_job_type.modal.work_type.hybrid")}`);
    } else if (userInfo.job_type === 4) {
      setLabelJobType(`${t("worker.edit_job_type.modal.work_type.nomatter")}`);
    }
  }, [userInfo.job_type]);

  const updateJobType = () => {
    data.append("job_type", userInfo.job_type?.toString());
    fetch(`${BASE_URL}${PROFILE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },

      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    setPreviousJobType({
      job_type_name: userInfo.job_type_name,
      job_type: userInfo.job_type?.toString(),
    });
  }, []);

  const handleCancelation = () => {
    setUserInfo((prev) => ({
      ...prev,
      job_type_name: previousJobType.job_type_name,
      job_type: previousJobType.job_type,
    }));
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showEditJobType && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditJobType]);

  useEffect(() => {
    if (cancelData === true) {
      handleCancelation();
    }
  }, [cancelData]);

  const closeModal = () => {
    setCancelData(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showEditJobType} style={StylesModal} ariaHideApp={false}>
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50"> */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[700px] lg:max-w-[40%] max-h-[50%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("worker.edit_job_type.modal")}
        </div>
        <div className="p-6">
          <p className="text-sm mb-6">{t("worker.edit_job_type.modal_desc")}</p>
          <Select
            styles={colourStyles2}
            className="mt-2 w-full"
            options={workType}
            value={{
              label: labelJobType,
              value: userInfo?.job_type_name,
            }}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            onChange={(e: any) =>
              setUserInfo((info) => ({
                ...info,
                job_type_name: e.value,
                job_type: e.id,
              }))
            }
          />
        </div>
        <div className="flex w-full h-full items-end mt-12 p-4 gap-2 pr-4 justify-end border-t border-blue-100">
          <Button
            className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro mt-1"
            onClick={() => {
              setCancelData(true);
            }}
          >
            {t("worker.edit_job_type.modal.cancel")}
          </Button>
          <Button
            onClick={() => {
              // setShowModal(false);
              updateJobType();
              // setTimeout(() => {
              //   if (refresh === "true" || refresh === true) {
              //     window.location.reload(false);
              //   }
              // }, 150);
            }}
            className=" border-none  text-white w-[135px] rounded-[20px] text-[0.9rem] px-[0.6rem] py-[0] h-10 bg-azulBonico hover:bg-azulOscuro"
          >
            {t("worker.edit_job_type.modal.save")}
          </Button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ModalEditJobType;
