import React from "react";
import { cn } from "src/lib/utils";

export const Button = ({
  children,
  className,
  // type,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      // type={type}
      className={cn("font-medium rounded-full transition", className)}
      {...props}
    >
      {children}
    </button>
  );
};
