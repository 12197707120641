import React, { useEffect, useState } from "react";
import { ImLocation } from "react-icons/im";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import Lottie from "lottie-react";
import { HeartIcon } from "@heroicons/react/24/outline";
import {
  FireIcon,
  HeartIcon as HeartIconFilled,
} from "@heroicons/react/24/solid";
import { BsThreeDots } from "react-icons/bs";
import { FindWorker } from "src/types/types";
import { BASE_URL } from "src/Config/api.config";
import { selectedWorkerId, workerProfile } from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { userProfileStore } from "src/Routing/useProfileStore";

import Hein from "../../assets/img/hien.jpg";

interface WorkerCardProps {
  worker: FindWorker;
  refetch: () => void;
}

const WorkerCard = ({ worker, refetch }: WorkerCardProps) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const { profileTypeToken } = userProfileStore((s) => s);
  const [animationData, setAnimationData] = useState<any>();
  useEffect(() => {
    import("src/Utils/lotties/Fire.json").then(setAnimationData);
  }, []);
  console.log(profileTypeToken);

  const [showModalInvite, setShowModalInvite] = useModalStore((state) => [
    state.showModalInvite,
    state.setShowModalInvite,
  ]);
  const [workerId, setWorkerId] = useAtom(selectedWorkerId);

  const [workerProf, setWorkerProf] = useAtom(workerProfile);

  const workerData = new FormData();
  workerData.append("_method", "POST");
  workerData.append("client_id_employer", userID);
  workerData.append("client_id_worker", worker.data.client_id_worker);

  const saveWorker = async () => {
    const data = await fetch(`${BASE_URL}discover.workers`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: workerData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 1) {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  const unsaveWorker = async (workerId: string) => {
    const data = await fetch(
      `${BASE_URL}discover.workers?client_id_employer=${userID}&client_id_worker=${workerId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 1) {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      onClick={() => {
        setShowModalInvite(showModalInvite);
        setWorkerProf(worker);
        setWorkerId(worker.data.client_id_worker);
      }}
      key={worker.data.client_id_worker}
      className="w-full relative dark:bg-background dark:text-whiteish dark:border-border  shadow-lg overflow-hidden border-b border-border bg-white flex  space-x-4 px-4 hover:shadow-xl transition cursor-pointer justify-between items-start py-6 rounded-lg mb-4"
    >
      <div className="flex items-start space-x-4">
        {/* <div className="h-28  relative w-32">
          <img src={Hein} className="h-28 w-28 rounded-full object-cover " />
          <div className="rounded-full p-1 absolute bottom-0 right-2 bg-white h-10 w-10 opacity-90 shadow flex items-center justify-center">
            {worker.data.saved !== "0" ? (
              <HeartIconFilled
                onClick={() => unsaveWorker(worker.data.client_id_worker)}
                className="h-7 w-7 text-red-500 cursor-pointer"
              />
            ) : (
              <HeartIcon
                onClick={() => saveWorker()}
                className="h-7 w-7 cursor-pointer"
              />
            )}
          </div>
          <div className="absolute top-2 right-5 h-10 w-10 opacity-70 flex items-center justify-center">
            {worker.data.date_featured !== null && (
              <div className="relative ">
                <Lottie
                  aria-aria-labelledby="lottie loader"
                  animationData={animationData}
                  loop
                  className="w-20 h-20"
                />
                <div className="absolute peer-hover:scale-100 scale-0 top-9 p-1 -right-12 bg-gray-700 text-white items-center justify-center flex rounded shadow">
                  <div
                    className={`bg-gray-700 border-l border-t z-20 w-3 h-3 rotate-[45deg] absolute -top-[6px] right-[50px] `}
                  />
                  <p className="text-[10px] w-20 text-center">
                    {worker.data.date_featured}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div> */}

        <div className="px-8 py-2 flex flex-col items-start w-[70%]">
          {/* <h1 className="text-xl font-semibold">{worker.name}</h1> */}
          <h1 className="flex items-center text-lg capitalize font-medium">
            {/* {worker.featured && (
                      <div className="bg-white absolute left-2">
                        <BiCrown className="fill-yellow-500 " />
                      </div>
                    )} */}
            {worker.data.title}
            {worker.data.date_featured !== null && (
              <div className="relative ">
                <Lottie
                  aria-aria-labelledby="lottie loader"
                  animationData={animationData}
                  loop
                  className="w-8 h-8"
                />
                {/* <div className="absolute peer-hover:scale-100 scale-0 top-9 p-1 -right-12 bg-gray-700 text-white items-center justify-center flex rounded shadow">
                  <div
                    className={`bg-gray-700 border-l border-t z-20 w-3 h-3 rotate-[45deg] absolute -top-[6px] right-[50px] `}
                  />
                  <p className="text-[10px] w-20 text-center">
                    {worker.data.date_featured}
                  </p>
                </div> */}
              </div>
            )}
          </h1>
          <div className="flex items-center  text-gray-600 gap-1 my-2">
            <ImLocation className="h-5 w-5" />
            <p className="">{worker.data.city_name ?? "Worldwide"}</p>
          </div>
          {/* <div className="flex items-center gap-10 my-2">
            <div>
              <p className="text-sm font-medium">{worker.data.job_type_name}</p>
            </div>
          </div> */}
          <p className="pt-2 mb-4">
            Confiable y ayudante de limpieza con mas de 5 años de experiencia
            trabajando para diferentes casas y familias , logrando mantener las
            áreas de trabajo impecable{" "}
          </p>
          <div className="flex items-center gap-2">
            {worker.ProfileTime.length > 0 &&
              worker.ProfileTime?.map((day, i) => (
                <div
                  key={day.worker_time_profile_id}
                  className="flex flex-col    shadow bg-azulBonico dark:bg-background rounded w-12"
                >
                  <p className="text-sm font-medium text-white border-b px-2 py-1">
                    {day.dayname}
                  </p>

                  <div className="px-2 py-1 text-center">{day.hours}h</div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <h1 className="text-lg  ">
        <span className="font-semibold text-xl">{worker.data.budget}</span>€/h
      </h1>
    </div>
    // </div>
  );
};

export default WorkerCard;
