import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import Select from "react-select";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, JOB_SKILLS } from "../../Config/api.config";

import plus from "../../assets/img/plus.png";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { skillsInvite } from "src/Atoms/jotaiAtoms";
import { useSkills } from "src/Hooks/useSkills";

interface SkillsSelectorProps {
  clientSkills: Array<{ skill_id: string; skill_name: string }>;
  setClientSkills: Dispatch<
    SetStateAction<{ skill_id: string; skill_name: string }[]>
  >;
  setError: Dispatch<SetStateAction<boolean>>;
}

export default function SkillsSelector({
  clientSkills,
  setClientSkills,
  setError,
}: SkillsSelectorProps) {
  const [skillsSearch, setSkillsSearch] = useState("");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [categoryID, setCategoryID] = useLocalStorage("jobCategoryID", "");
  const [allSkills, setAllSkills] = useLocalStorage("allSkills", "");
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [stateSkillIn, setStateSkillIn] = useState<string>("");
  const [stateSkillOut, setStateSkillOut] = useState<string>("");
  const [suggestedSkills, setSuggestedSkills] = useState<any>([]);
  const [skillsInvit, setSkillsInvite] = useAtom(skillsInvite);
  const [skills, setSkills] = useState<
    { skill_id: string; skill_name: string }[]
  >([]);
  const [suggestedSkillsIntact, setSuggestedSkillsIntact] = useState<
    { skill_id: string; name: string }[]
  >([]);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { refetchSkills, skillsData } = useSkills();

  useEffect(() => {
    setAllSkills(skillsData);
  }, [skillsData]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (skillsSearch && ref.current && !ref.current.contains(e.target)) {
        setSkillsSearch("");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [skillsSearch]);

  //ESTO LO HE PUESTO PARA QUE SE MUESTREN LAS SKILLS CUANDO CREAS UN JOB Y TE DESLOGUEAS, EL DRAFT NO LAS MOSTRABA; CON ESTO PARECE QUE SE ARREGLA, SINO SERIA EL DESCOMENTARR EL GET SKILLS Y PROBAR OTRA MANERA
  useEffect(() => {
    if (clientSkills) {
      setSkills(clientSkills);
    }
  }, []);

  useEffect(() => {
    if (skills?.length > 0) {
      setClientSkills(skills);
    }
  }, [skills]);
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  let availableSkills;
  if (allSkills) {
    availableSkills = allSkills
      ?.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
      ?.map(function (row: any, i: any, arr: any) {
        if (arr[i].name !== arr[i + 1]?.name) {
          return {
            value: row.name,
            label: row.name,
            skill_id: row.skill_id,
            name: row.name,
          };
        } else return null;
      })
      .filter(function (el: any) {
        return el != null;
      });
  }
  useEffect(() => {
    setTimeout(() => {
      if (skills.length > 0) {
        setError(false);
      } else {
        setError(true);
      }
    }, 150);
    // }
  }, [skills]);

  const clientSuggestedSkills = () => {
    fetch(`${BASE_URL}${JOB_SKILLS}?category_id=${categoryID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSuggestedSkills(data.result);
        setSuggestedSkillsIntact(data.result);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    clientSuggestedSkills();
  }, []);
  const addSkills = (
    skill: { skill_id: string; name: string },
    e: any,
    skillName?: string
  ) => {
    setSkillsSearch("");
    let counter = 0;
    // ELIMINATE SUGGESTIONS ONCLICK
    // let y = e?.target.getAttribute("removesuggestion");
    setSuggestedSkills(
      suggestedSkills?.filter((items: any) => items.name !== skillName)
    );
    for (let i = 0; i <= skills?.length; i++) {
      if (skill.skill_id === skills[i]?.skill_id) {
        counter++;
      }
    }
    if (counter === 0) {
      if (skills?.length > 0) {
        return setSkills((current: any) => [
          ...current,
          { skill_id: skill.skill_id, skill_name: skill.name },
        ]);
      } else {
        return setSkills([
          { skill_id: skill.skill_id, skill_name: skill.name },
        ]);
      }
    }
  };
  const removeHandler = (skill: { skill_id: string; skill_name: string }) => {
    // let x = e.target.getAttribute("removeskill");
    setSkills(skills.filter((items) => items.skill_name !== skill.skill_name));
    for (let i = 0; i < suggestedSkillsIntact.length; i++) {
      if (
        skill.skill_name === suggestedSkillsIntact[i]?.name &&
        skill.skill_name !== suggestedSkills[i]?.skill_name
      ) {
        setSuggestedSkills((current: any) => [
          ...current,
          { skill_id: skill.skill_id, name: skill.skill_name },
        ]);
      }
    }
  };
  return (
    <div>
      {" "}
      <div>
        <div className="px-8 py-4">
          <h3 className="mb-2 text-sm text-md">
            {t(
              "welcome-client.rightcard.skills.subtitle1.dropdown.skillsPlaceholder"
            )}
          </h3>
          <Select
            options={availableSkills}
            styles={colourStyles}
            className="text-sm mb-4 dark:text-black text-black"
            placeholder={`${t(
              "welcome-client.rightcard.skills.subtitle1.dropdown.skillsPlaceholder_2"
            )}...`}
            onChange={(e: any) => addSkills(e, "")}
          />
          <div className="flex flex-wrap max-h-24 text-xs overflow-auto">
            {skills &&
              skills?.map((skill) => {
                return (
                  <div
                    key={skill.skill_id}
                    className={`flex items-center gap-2 cursor-pointer px-2 py-0.5 mx-0.5 text-white dark:border-transparent bg-[#4d5669] my-0.5 border rounded-xl hover:bg-[#292e38] ${
                      skill.skill_name === stateSkillOut
                        ? `opacity-0 transition duration-300`
                        : ""
                    }`}
                    onClick={(e) => {
                      setStateSkillOut(skill.skill_name);
                      setTimeout(() => {
                        setStateSkillOut("");
                        removeHandler(skill);
                      }, 200);
                    }}
                  >
                    <div className="text-xs">{skill.skill_name}</div>
                    <Cross className="h-[13px] cursor-pointer w-fit fill-white" />
                  </div>
                );
              })}
          </div>
          <h5 className="mt-6 text-sm mb-2">
            {t("welcome-client.rightcard.skills.subtitle2.popularskills")}
            <span className="font-bold"> {selectedCategory}</span>
          </h5>
          <div className="flex text-sm flex-wrap">
            <div className="flex flex-wrap w-max">
              {suggestedSkills
                ?.slice(0, 20)
                ?.map((skill: { skill_id: string; name: string }) => {
                  return (
                    <div
                      key={skill.skill_id}
                      className={`flex items-center gap-2 cursor-pointer px-2 py-0.5 mx-0.5 text-[#343a47] bg-blancoGrisClaro active:bg-azulBonico hover:bg-[#bdbdbd] my-0.5 border rounded-xl ${
                        skill.name === stateSkillIn
                          ? `opacity-[0] transition duration-150`
                          : ""
                      }  `}
                      onClick={(e) => {
                        setStateSkillIn(skill.name);
                        setTimeout(() => {
                          setStateSkillIn("");
                          addSkills(skill, e, skill.name);
                        }, 500);
                      }}
                    >
                      <div
                        className="text-xs"
                        //  removesuggestion={skill.name}
                      >
                        {skill.name}
                      </div>
                      <img
                        // removesuggestion={skill.name}
                        className="h-2.5 "
                        src={plus}
                        alt="plus"
                      ></img>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
