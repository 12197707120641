import { ChevronDownIcon, PencilIcon } from "@heroicons/react/24/solid";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlinePaperClip } from "react-icons/ai";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  budgetInvite,
  categoryIdInvite,
  categoryInvite,
  expireDateInvite,
  skillsInvite,
  workersNeededInvite,
} from "src/Atoms/jotaiAtoms";
import { ArrowDownIcon as ArrowDown } from "@heroicons/react/24/outline";
import JobEditAdvancedPreferences from "src/Components/InitialClientForm/JobEditAdvancedPreferences";
import ModalEdit from "src/Components/InitialClientForm/ModalEdit";
import { Button } from "src/Components/ui/Button";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import { BASE_URL, BASE_URL_IMG } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { ReactComponent as Banner } from "src/assets/img/allJobsEmployerBanner.svg";
import { WorkerInfo, WorkerProfile } from "src/types/types";
import { cn } from "src/lib/utils";
import exclamation from "src/assets/img/exclamation.png";

const JobPostInvite = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserID] = useLocalStorage("IDUser", "");
  // const [workerInfo, setWorkerInfo] = useLocalStorage("workerInfo", "");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [privateMessageInput, setPrivateMessageInput] = useState("");
  const params = useParams();
  const [workerProfile, setWorkerProfile] = useState<WorkerProfile>();
  const navigate = useNavigate();
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModalSkills, setShowModalSkills] = useState(false);
  const [showModalBudget, setShowModalBudget] = useState(false);
  const [showAdvancedPreferences, setShowAdvancedPreferences] = useState(false);
  const { t } = useTranslation();
  const [jobDate, setJobDate] = useState({ start: null, end: null });
  const curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);

  const [title, setTitle] = useState("");
  const [detailsDescription, setDetailsDescription] = useState("");
  const [category, setCategory] = useAtom(categoryInvite);
  const [categoryId, setCategoryId] = useAtom(categoryIdInvite);
  const [skills, setSkills] = useAtom(skillsInvite);
  const [budget, setBudget] = useAtom(budgetInvite);
  const [expireDate, setExpireDate] = useAtom(expireDateInvite);
  const [workersNeeded, setWorkersNeeded] = useAtom(workersNeededInvite);
  const [warning, setWarning] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(false);

  const getWorkerData = async () => {
    const data = await fetch(
      `${BASE_URL}worker.profile?client_id=${params.id}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerInfo, isLoading } = useQuery<WorkerInfo>(
    ["workerInfo", params.id],
    getWorkerData
  );

  const getWorkerInformation = async () => {
    const data = await fetch(`${BASE_URL}workers?client_id=${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: workerInfoData, isLoading: isWorkerInfoLoading } = useQuery(
    ["workerInfo", params.id],
    getWorkerInformation
  );

  useEffect(() => {
    if (!isWorkerInfoLoading) {
      setWorkerProfile(workerInfoData);
    }
  }, [workerInfoData]);

  const defaultValue = `Hello! \n\nI'd like to personally invite you to apply to my job. Please review the job post and apply if you're available. \n\n${
    userInfo.first_name
  } ${userInfo.last_name?.slice(0, 1)}.`;

  useEffect(() => {
    setPrivateMessageInput(defaultValue);
  }, []);

  useEffect(() => {
    if (jobDate.end && jobDate.start) {
      if (jobDate.end < jobDate.start) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    }
  }, [jobDate]);

  useEffect(() => {
    console.log(workerInfo);
  }, [workerInfo]);

  const data = new FormData();

  data.append("_method", "POST");
  data.append("job_type", workerInfo?.workerProfile.job_type as string);
  data.append("city_id", workerInfo?.workerProfile.city_id as string);
  data.append("post_code_id", workerInfo?.workerProfile.post_code_id as string);
  data.append("country_code", workerInfo?.workerProfile.country_code as string);
  data.append("title", title);
  data.append("details", detailsDescription);
  data.append("skills_list", skills?.map((skill) => skill.skill_id).toString());
  data.append("category_id", categoryId);
  data.append("currency_code", "ES");
  data.append("expire_offer_days", expireDate);
  data.append("workers_needed", workersNeeded === "01" ? "1" : workersNeeded);
  data.append("_method", "PUT");
  data.append("client_id", userID);

  const postJobInvite = async () => {
    fetch(`${BASE_URL}postings`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  return (
    <>
      <HeaderBanner
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
      >
        <div className="flex flex-col h-full ">
          <p className="text-[30px] font-bold h-full items-center">
            Post Job & Invite
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div className="container mx-auto border border-border  bg-white mb-10">
        <div className="flex items-center justify-between my-4 px-8 mt-10">
          <h1 className="text-3xl font-semibold">Post a job and invite</h1>
          <Button className="text-sm bg-gray-100 text-[#333]  px-6 py-2 opacity-70">
            Post Job & Invite
          </Button>
        </div>
        <div className="flex items-center mt-10 px-8">
          <img
            src={BASE_URL_IMG + workerInfo?.workerProfile.profile_image}
            className="h-20 w-20 rounded-full object-cover"
          />
          <div className="flex flex-col items-start ml-4">
            <p className="font-medium text-lg">
              {workerProfile?.first_name} {workerProfile?.last_name}{" "}
            </p>
            <p className="text-sm">{workerInfo?.workerProfile.title} </p>
            <div
              className="mt-2 cursor-pointer"
              onClick={() => navigate(`/find-worker/${params.id}`)}
            >
              <p className="underline text-sm text-azulOscuro">
                {t(
                  "employer.findworkers.body.body.rightcard.viewprofile.dot_modal.rightBtn"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="my-3 p-8">
          <p className="text-[16px] font-semibold text-[#333]">
            Private message to {workerProfile?.first_name}{" "}
            {workerProfile?.last_name} (optional)
          </p>
          <div className="my-2">
            <textarea
              value={privateMessageInput}
              className="w-1/2 ring-2 h-40 p-3 rounded ring-gray-200 hover:ring-gray-300 text-sm"
            />
          </div>
        </div>
        <div className="border px-8 py-4">
          <p className="text-[16px] font-medium mb-2 ">Title</p>
          <input
            type="text"
            className="w-1/2 ring-2 ring-gray-200 rounded h-10 px-2 py-1 hover:ring-gray-300 transition"
          />
        </div>
        <div className="border px-8 py-4">
          <p className="text-[16px] font-medium mb-2 ">
            Describe what you need
          </p>
          <textarea
            className="w-1/2 ring-2 ring-gray-200  h-40 p-3 rounded font-normal text-sm hover:ring-gray-300"
            placeholder="Already have a description? Paste it here!"
          />
          <div className="mt-4">
            <div className="border-2 border-azulBonico w-32 flex rounded-full justify-center items-center">
              <AiOutlinePaperClip className="text-azulBonico" />
              <h1 className="text-azulBonico">Attach file</h1>
            </div>
            <p className="text-sm mt-2">Max file size: 100MB</p>
          </div>
        </div>
        <div className="border px-8 py-4">
          <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h1 className="text-[16px] font-medium mb-2">Category</h1>
              <p>{category}</p>
            </div>
            <div
              onClick={() => setShowModalCategory(!showModalCategory)}
              className="border-2 p-1 rounded-full"
            >
              <PencilIcon className="h-5 w-5 text-azulOscuro" />
            </div>
          </div>
          <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h1 className="text-[16px] font-medium mb-2">Skills</h1>
              <div className="flex flex-wrap items-center gap-2">
                {skills?.slice(1)?.map((skill) => (
                  <div
                    key={skill.skill_id}
                    className="bg-azulBonico px-2 py-1 rounded-full"
                  >
                    <p className="text-white text-sm">{skill.skill_name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div
              onClick={() => setShowModalSkills(!showModalSkills)}
              className="border-2 p-1 rounded-full"
            >
              <PencilIcon className="h-5 w-5 text-azulOscuro" />
            </div>
          </div>
          {/* <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h1 className="text-[16px] font-medium mb-2">Scope</h1>

              <p className="text-sm text-[#333]">
                Small, Less than 1 month, Intermediate level
              </p>
            </div>
            <div className="border-2 p-1 rounded-full">
              <PencilIcon className="h-5 w-5 text-azulOscuro" />
            </div>
          </div> */}
          <div className="flex items-center justify-between py-4">
            <div className="flex flex-col">
              <h1 className="text-[16px] font-medium mb-2">Budget</h1>
              {budget !== "" ? (
                <p>{budget}€ /h</p>
              ) : (
                <p className="text-sm text-[#333]">
                  I'm not ready to set a budget
                </p>
              )}
            </div>
            <div
              onClick={() => setShowModalBudget(!showModalBudget)}
              className="border-2 p-1 rounded-full"
            >
              <PencilIcon className="h-5 w-5 text-azulOscuro" />
            </div>
          </div>
          <div>
            <div
              className=" cursor-pointer flex justify-between items-center  py-6  "
              onClick={() =>
                setShowAdvancedPreferences(!showAdvancedPreferences)
              }
            >
              <p className="text-base font-medium">
                {t("employer.job_post_edit.advanced_preferences")}
              </p>

              <ArrowDown
                className={cn(
                  `h-3 fill-black dark:fill-whiteish`,
                  showAdvancedPreferences && "rotate-180 transition"
                )}
              />
            </div>

            {showAdvancedPreferences && (
              <>
                <div className=" flex items-center gap-8 px-8 py-4 border-b mb-4">
                  <div className="flex flex-col gap-1">
                    <p className="font-medium">
                      {t("review.jobpostreview.body.card4.left.subtitle9")}
                    </p>
                    <input
                      type="date"
                      min={date}
                      className="border-2 border-slate-200 w-fit px-4 py-1 rounded-lg text-sm text-slate-400 cursor-pointer outline-none"
                      onChange={(e) => {
                        setJobDate((prev: any) => ({
                          ...prev,
                          start: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className=" flex flex-col gap-1">
                    <p className="font-medium">
                      {t("review.jobpostreview.body.card4.right.subtitle9")}
                    </p>
                    <input
                      type="date"
                      min={jobDate.start!}
                      className="border-2 border-slate-200 w-fit px-4 py-1 rounded-lg text-sm text-slate-400 cursor-pointer outline-none"
                      onChange={(e) =>
                        setJobDate((prev: any) => ({
                          ...prev,
                          end: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className={`mt-4 ${!warning && "hidden"}`}>
                    <div className="flex items-center">
                      <img
                        className="exclamation"
                        src={exclamation}
                        alt="hey"
                      />
                      <p className="text-sm text-[#9b211b] font-bold">
                        {t("employer.job_posting.end_date_higher")}
                      </p>
                    </div>
                  </div>
                </div>
                <JobEditAdvancedPreferences
                  expireDate={expireDate}
                  setExpireDate={setExpireDate}
                  workersNeeded={workersNeeded}
                  setWorkersNeeded={setWorkersNeeded}
                />
              </>
            )}
          </div>
        </div>

        <div className="border-t px-8 py-4">
          <div className="flex items-center justify-between ">
            <div className="flex flex-col">
              <h1 className="text-[16px] font-medium mb-2">
                Job post preferences (optional)
              </h1>
              <p className="text-sm text-[#333]">
                Adjust visibility and add coworkers
              </p>
            </div>
            <ChevronDownIcon className="h-5 w-5" />
          </div>
        </div>
        <div className="flex items-center justify-between py-4 px-8">
          <Button
            onClick={() => navigate(-1)}
            className="border-2 rounded-full px-6 py-2 text-sm text-azulBonico "
          >
            Back
          </Button>

          <div className="flex items-center gap-10">
            <Button className="text-sm px-6 py-2 opacity-70">
              Save as a draft{" "}
            </Button>
            <Button
              onClick={() => postJobInvite()}
              className="text-sm bg-gray-100 text-[#333]  px-6 py-2 opacity-70"
            >
              Post Job & Invite
            </Button>
          </div>
        </div>
      </div>

      {showModalCategory && (
        <ModalEdit
          setShowModal={setShowModalCategory}
          title={`${t("employer.job_post_edit.category")}`}
        />
      )}
      {showModalSkills && (
        <ModalEdit
          setShowModal={setShowModalSkills}
          title={t("employer.job_post_edit.skills")}
        />
      )}
      {showModalBudget && (
        <ModalEdit
          setShowModal={setShowModalBudget}
          title={`${t("employer.job_post_edit.budget")}`}
        />
      )}
    </>
  );
};

export default JobPostInvite;
