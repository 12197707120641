import React, { Dispatch, SetStateAction, lazy } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";

interface ModalChatProps {
  clientName: string;
  modal: string;
  showModal: {
    delete: boolean;
    report: boolean;
    block: boolean;
    chat: boolean;
  };
  setShowModal: Dispatch<
    SetStateAction<{
      delete: boolean;
      report: boolean;
      block: boolean;
      chat: boolean;
    }>
  >;
}

const ModalChat = ({
  modal,
  showModal,
  setShowModal,
  clientName,
}: ModalChatProps) => {
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({
        ...prev,
        chat: false,
        block: false,
        report: false,
      }));
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-y-0" : "scale-y-100"
          } transition w-[28%] left-[50%] shadow translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="text-xl font-bold  px-8 py-6">{modal}</div>
          <div className="mx-8 my-4 text-sm">
            {t("modal_chat.want_to")}{" "}
            {modal === t("employer.messages.modal_chat.delete") && (
              <>{t("messages.conversation.delete_chat.desc")}</>
            )}
            {modal === t("employer.messages.modal_chat.report_user") && (
              <>
                {t("messages.conversation.report_user.desc")}{" "}
                <span className="font-medium">{clientName}</span>
              </>
            )}
            {modal === t("employer.messages.modal_chat.block") && (
              <>
                {t("messages.conversation.block_user.desc")}{" "}
                <span className="font-medium">{clientName}</span>
              </>
            )}
            ?
          </div>
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
            <Button
              className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico hover:bg-blancoGrisClaro  mt-1"
              onClick={() => {
                // handleNoMoreLocal();
                closeModal();
              }}
            >
              {t("modal_chat.want_to.cancel")}
            </Button>
            <Button
              className="bg-azulBonico border-none  hover:bg-azulOscuro text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]   h-10"
              // onClick={(e) => handleAddition(e)}
            >
              {t("modal_chat.want_to.continue")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalChat;
