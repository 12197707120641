import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import Modal from "react-modal";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import YoutubeEmbed from "../WorkerSettingsBoard/YoutubeEmbed";

import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { useTranslation } from "react-i18next";
import { StylesModal } from "../../Utils/stylesModal";
import { ModalProps } from "./ModalWorkerCard";

interface ModalVideoIntroduction {
  videoUrl: string;
  setShowModal: any;
  showModal: any;
  // showModal: {
  //   invite: boolean;
  //   chat: boolean;
  //   rating: boolean;
  //   decline: boolean;
  //   worker: boolean;
  //   end: boolean;
  //   video: boolean;
  //   report: boolean;
  // };
}

export default function ModalVideoIntroduction({
  videoUrl,
  setShowModal,
  showModal,
}: ModalVideoIntroduction) {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [transition, setTransition] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, video: false }));
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showModal} style={StylesModal} ariaHideApp={false}>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-[170]">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-auto left-[50%] translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="text-xl font-bold border-b border-blue-100 flex justify-between items-center px-8 py-6">
            <p>{t("worker.profile.video_introduction")}</p>
            <Cross
              className="h-5 cursor-pointer"
              onClick={() => closeModal()}
            />
          </div>
          <div className="p-8  font-normal">
            <YoutubeEmbed videoUrl={videoUrl} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
