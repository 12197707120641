import React, { useState, useRef } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import exclamation from "../../assets/img/exclamation.png";
import { BASE_URL, INAPROPIATE_JOB } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { StylesModal } from "../../Utils/stylesModal";
import { OffersTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useInappropiateReasons } from "src/Hooks/useInappropiateReasons";

interface ModalInappropriateJobProps {
  setShowModal: any;
  showModal: any;
  job?: OffersTypes;
  setJob: any;
  offers?: OffersTypes[];
  setOffers?: any;
}

export const ModalInappropriateJob = ({
  setShowModal,
  showModal,
  job,
  setJob,
  offers,
  setOffers,
}: ModalInappropriateJobProps) => {
  const { t } = useTranslation();
  const [transition, setTransition] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [reclamationOptions, setReclamationOptions] = useState<any>();
  const [selectedReport, setSelectedReport] = useState<{
    note: string;
    name: string;
    id: string;
  }>({
    note: "",
    name: "",
    id: "",
  });
  const ref = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState(false);
  const { inappropiateReasonsData, isLoading } = useInappropiateReasons();

  useEffect(() => {
    if (!isLoading) {
      setReclamationOptions(inappropiateReasonsData);
    }
  }, [inappropiateReasonsData]);

  const postData = new FormData();
  postData.append("_method", "POST");
  postData.append("client_id_worker", userID);
  postData.append("job_id", job?.job_id!);
  postData.append("inapropiate_job_reason_id", selectedReport?.id);
  const postReportReason = () => {
    if (selectedReport?.note) {
      postData.append("note", selectedReport.note);
    }
    fetch(`${BASE_URL}${INAPROPIATE_JOB}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: postData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok")
          if (offers) {
            setOffers((current: any) =>
              current?.map((obj: any) => {
                if (obj.job_id === job?.job_id) {
                  return {
                    ...obj,
                    inapropiate_job: "true",
                    inapropiate_desc: selectedReport.name,
                  };
                }
                return obj;
              })
            );
          }
        setJob((prev: any) => ({
          ...prev,
          inapropiate_job: "true",
        }));
      })
      .catch((err) => console.log(err));
  };
  const handleInputChange = (option: string) => {
    setSelectedOption(option);
    setError(false);
  };
  const handleReclamation = () => {
    if (!selectedOption) {
      setError(true);
    } else {
      setShowModal((prev: any) => ({ ...prev, report: false }));
      postReportReason();
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.report && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.report]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, report: false }));
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showModal.report} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[80%] lg:w-[500px]  shadow-xl translate-x-[-50%] left-[50%] rounded top-[50%] translate-y-[-50%] bg-white `}
        ref={ref}
      >
        <div className="text-lg lg:text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("employer.modal_inappropiate_job.title")}
        </div>
        <form className="mt-10">
          {reclamationOptions?.map((option: { name: string; id: string }) => {
            return (
              <div key={option.id}>
                <div className="mx-6">
                  <label
                    className={`flex cursor-pointer hover:bg-blancoGrisClaro items-center gap-4 text-sm lg:text-[15px] p-0.5 lg:p-1.5 mt-2 ${
                      selectedOption === option.name && "bg-blancoGrisClaro"
                    }`}
                  >
                    <input
                      type="radio"
                      value={option.name}
                      checked={selectedOption === option.name}
                      onChange={() => {
                        setSelectedReport((prev) => ({
                          ...prev,
                          id: option.id,
                          name: option.name,
                        }));
                        handleInputChange(option.name);
                      }}
                    />
                    {option.name}
                  </label>
                </div>
              </div>
            );
          })}
          {error === true && (
            <div className="wrong-container">
              <img className="exclamation ml-3.5" src={exclamation} alt="hey" />{" "}
              {t("employer.modal_inappropiate_job.field_required")}
            </div>
          )}
          <p className="text-sm font-bold mx-6 mt-10">
            {t("employer.modal_inappropiate_job.tell_us_more")}
          </p>
          <div className="mx-6 mt-4">
            <textarea
              placeholder={`${t(
                "worker.modal_report_message.additional_details"
              )}`}
              onChange={(e) =>
                setSelectedReport((prev) => ({ ...prev, note: e.target.value }))
              }
              className="w-full rounded h-[100px] lg:h-[150px] border outline-none text-sm p-3"
            ></textarea>
          </div>
        </form>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <button
            className="bg-none text-[0.9rem] font-semibold w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro cursor-pointer mt-1 transition duration-300"
            onClick={() => closeModal()}
          >
            {t("employer.modal_inappropiate_job.cancel")}
          </button>
          <button
            className={`border-none font-semibold w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] transition duration-300 h-10 bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer `}
            onClick={() => handleReclamation()}
          >
            {t("employer.modal_inappropiate_job.save")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
