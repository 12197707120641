export const options = [
    {
        value: "January",
        label: "January",
        id: "01",
    },
    {
        value: "February",
        label: "February",
        id: "02",
    },
    {
        value: "March",
        label: "March",
        id: "03",
    },
    {
        value: "April",
        label: "April",
        id: "04",
    },
    {
        value: "May",
        label: "May",
        id: "05",
    },
    {
        value: "June",
        label: "June",
        id: "06",
    },
    {
        value: "July",
        label: "July",
        id: "07",
    },
    {
        value: "August",
        label: "August",
        id: "08",
    },
    {
        value: "September",
        label: "September",
        id: "09",
    },
    {
        value: "October",
        label: "October",
        id: "10",
    },
    {
        value: "November",
        label: "November",
        id: "11",
    },
    {
        value: "December",
        label: "December",
        id: "12",
    },
];