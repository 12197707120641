import React from "react";

//LOCALS
import AuthNavigation from "./AuthNavigation";

const AuthenticationSwitch = () => {
  return <AuthNavigation />;
};

export default AuthenticationSwitch;
