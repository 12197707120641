import { useAtom } from "jotai"
import { filtersFeedback } from "../Atoms/jotaiAtoms"
import { ReviewTypes } from "../types/types";

export const useFiltersFeedback = (reviews: ReviewTypes[]) => {
    const [filtersScore, setFilters] = useAtom(filtersFeedback)


    const filterByScore = (reviews: ReviewTypes[]) => {
        return reviews?.filter((review) => {
            return (
                Number(review.score_from_worker) >= filtersScore.min &&
                Number(review.score_from_worker) <= filtersScore.max
            );
        });
    };

    const filteredReviews = filterByScore(reviews);

    return { filteredReviews }
}