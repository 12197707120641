import { atom } from "jotai";
import { LIMIT } from "src/Config/api.config";
import { ActiveChats, ChatConversation, Cities, DayHour, FindWorker, OffersTypes } from "src/types/types";
import { HourDaysTypes } from "src/types/types";

export const ALL_FILTERS_INITIAL: AppliedFilters = { budget: "", category_id_list: "", find: "", job_type_id_list: "", country_code: "", city_id_list: "", postcode_id_list: "", skill_id_list: "" }

interface AppliedFilters {
  category_id_list: string; find: string; skill_id_list: string; budget: string; job_type_id_list: string; country_code: string; city_id_list: string; postcode_id_list: string
}

export const CONVERSATION_CHAT_MESSAGE: ActiveChats = {
  chat_message_id: "",
  client_id: "",
  client_id_send: "",
  client_name: "",
  deleted: "",
  img_profile: "",
  job_id: "",
  job_title: "",
  msg: "",
  msg_date: "",
  msg_time: "",
  new_messages: "",
  reported: "",
  seen: "",
  sender: "",
  status: "",
  title: ""
}


//AMBOS
export const selectedFeedbackSection = atom("recibidas");
// export const isAllowedToEnter = atom<boolean>(false);
export const applicationView = atom<string>("view");
export const isAccountValidated = atom<boolean>(false);
export const jobOfferAtom = atom<OffersTypes[]>([]);
export const savedJobsAtom = atom<OffersTypes[]>([])
export const checkPasswordStart = atom<boolean>(false);
export const widthScreen = atom("w-full");
export const jobType = atom("");
export const language = atom<string>("spanish");
export const startingHourNextDay = atom({});
export const daysReplicatedTime = atom<HourDaysTypes[]>([]);
export const wantsToReplicate = atom(false);
export const filtersFeedback = atom({
  min: 0,
  max: 5,
});
export const loadingFeedback = atom(false);
export const loadingJob = atom(false);
export const loadingCandidates = atom(false);
//WORKER
export const showOpenMenuWorker = atom({
  findWork: false,
  jobs: false,
  profile: false,
});
export const whichModal = atom<string>("");
export const allWorkerExployments = atom([]);
export const counterSaved = atom(0);
export const counterApplied = atom(0);
export const jobFeedbackOptions = atom([]);
export const isWorkerOnline = atom(false);
export const onlineStatusMode = atom<{ value: string; label: any }>({ value: "", label: null });
export const selectedTabHeaderWorker = atom("dashboard");
export const navBarSettingsWorker = atom("info");
//notifications
export const allNotifications = atom([]);
export const notificationsState = atom<any>({});
//job search
export const categoryFilterOptions = atom<{ name: string; category_id: string }[]>([{ name: "", category_id: "" }]);
export const skillFilterOptions = atom<{ name: string; skill_id: string }[]>([{ name: "", skill_id: "" }]);
export const cityFilterOptions = atom([]);
export const postcodeFilterOptions = atom([]);
export const showSavedJobsWorker = atom(false);
export const savedSearchesData = atom<any>([]);
export const allFiltersAppliedToJobSearch = atom<AppliedFilters>(ALL_FILTERS_INITIAL);
//offer
//pagination
export const myCurrentPage = atom(1);
export const numberOffersPerPage = atom(10);

//EMPLOYER
export const showOpenMenuEmployer = atom({
  jobs: false,
  // hires: false,
  profile: false,
});
export const selectedTabHeaderEmployer = atom("dashboard");
export const isEmployerOnline = atom(false);
export const numberOfShortlistedApplicants = atom<number>(0);
export const numberOfArchivedApplicants = atom<number>(0);
export const numberOfAllApplicants = atom<number>(0);
export const numberOfDeclinedApplicants = atom<number>(0);
export const numberOfMessagedApplicants = atom<number>(0)
// export const numberOfDeclinedOffers = atom();
export const selectedApplicantsSection = atom("all");
export const dataOfferedApplicant = atom({});
// export const dataMessagesProcessOne = atom();
export const messages4JobId = atom<string>("");
export const process1WithParamNew = atom<string>("");
// export const clickedEmployerChat = atom();
export const showEditableCompany = atom<boolean>(false);
//contracts, hired, applicants = 0 => yes
export const jobStatus = atom({});

export const jobFeatured = atom<boolean>(false)
export const selectedWorkerChat = atom<ActiveChats>(CONVERSATION_CHAT_MESSAGE)

// CHAT

export const selectedChatMessages = atom<ActiveChats>(CONVERSATION_CHAT_MESSAGE)
export const selectedChatAtom = atom<ChatConversation[]>([])


export const citiesArrayId = atom<string[]>([])
export const categoriesArrayId = atom<string[]>([])
export const postcodesArrayId = atom<string[]>([])
export const jobTypeArrayId = atom<string[]>([])
export const budgetStart = atom<string>("")
export const budgetEnd = atom<string>("")


export const saveTypeId = atom<string>("")
export const mapHoursDayParams = atom<DayHour[]>([])
export const cityNameInput = atom<string>("")
export const showWorkerSaved = atom<boolean>(true)
export const showEnterpriseSaved = atom<boolean>(true)
export const selectedWorkerId = atom<string>("")
export const featuredWorkers = atom<string>("0")
export const savedWorkers = atom<string>("0")
export const sortType = atom<{ enabled: boolean; order: string; name: string }>({
  enabled: false,
  order: "desc", name: "date_featured"
})

export const paginationFindWorkers = atom<{ limit: number; totalPages: string; totalWorkers: string; currentPage: number; pageNumbers: [] }>({ limit: LIMIT, totalPages: "", totalWorkers: "", currentPage: 1, pageNumbers: [] })
export const workerInput = atom<string>("")
export const categoryInvite = atom<string>("")
export const categoryIdInvite = atom<string>("")
export const skillsInvite = atom<{ skill_id: string; skill_name: string }[]>([{ skill_id: "", skill_name: "" }])
export const jobtypeInvite = atom<string>("")
export const budgetInvite = atom<string>("")
export const expireDateInvite = atom<string>("")
export const workersNeededInvite = atom<string>("1")

export const workerProfile = atom<any>({})

export const registerButton = atom<"staffEnterprise" | "staff" | "work" | "">("staffEnterprise")
export const workerData = atom<any>(null)
export const clientDataState = atom<any>(null);
export const isAllowedToEnter = atom(false)

export const filterActionType = atom<{ value: string; label: string }>({ value: "", label: "" })

export const showCongratulationsGift = atom<boolean>(false)

export const isCompany = atom<boolean>(false)

export const showFilterWorkers = atom<boolean>(false)

export const selectedCategory = atom<{ category_id: string; name: string }>({ category_id: "", name: "" })

export const showFilterWorkersTime = atom<boolean>(false)