import React, { useEffect, useState } from "react";
import { HoursJobTypes } from "../../types/types";
import TimeSkeleton from "src/Components/ui/skeleton/TimeSkeleton";
import AvailableSlotTime from "./AvailableSlotTime";

interface AvailableDaysProps {
  allChosenTimes: HoursJobTypes[];
}

const AvailableDays = ({ allChosenTimes }: AvailableDaysProps) => {
  const [groupedTimes, setGroupedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (allChosenTimes?.length > 0) {
      setGroupedItems(
        Object.values(
          allChosenTimes?.reduce((acc: any, current) => {
            acc[current.dayname] = acc[current.dayname] ?? [];
            acc[current.dayname].push(current);
            return acc;
          }, {})
        )
      );
    }
    setLoading(false);
  }, [allChosenTimes]);

  return (
    <div className="text-black dark:text-whiteish">
      {groupedTimes?.length > 0 &&
        !loading &&
        groupedTimes?.map((weekday: any, i) => (
          <div key={i}>
            {weekday?.map((day: HoursJobTypes) => (
              <AvailableSlotTime key={day.job_time_profile_id} time={day} />
            ))}
          </div>
        ))}
      {loading && <TimeSkeleton />}
    </div>
  );
};

export default AvailableDays;
