import React, { ReactNode, lazy } from "react";
import {
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  useRouteError,
} from "react-router-dom";
import ClosedJobsSearch from "src/Components/AllKindOfJobs/ClosedJobsSearch";
import DraftsSearch from "src/Components/AllKindOfJobs/DraftsSearch";
import JobPostEdit from "src/Components/InitialClientForm/JobPostEdit";
import JobPostReview from "src/Components/InitialClientForm/JobPostReview";
import TitleJob from "src/Components/InitialClientForm/TitleJob";
import JobSearch from "src/Components/JobSearch/JobSearch";
import OfferDetails from "src/Components/ViewJobOffer/OfferDetails";
import ReceivedOffer from "src/Components/ViewJobOffer/ReceivedOffer";
import MessagesEmployer from "src/Pages/Employer/Chat/MessagesEmployer";
import Credits from "src/Pages/Employer/Credits/Credits";
import Dashboard from "src/Pages/Employer/Dashboard/Dashboard";
import FindWorkers from "src/Pages/Employer/FindWorkers/FindWorkers";
import WorkerProfileScreen from "src/Pages/Employer/FindWorkers/WorkerProfile";
import HiredWorkers from "src/Pages/Employer/HiredWorkers/HiredWorkers";
import JobPostInvite from "src/Pages/Employer/JobPostInvite/JobPostInvite";
import Calendar from "src/Pages/General/Home/Calendar";
import Settings from "src/Pages/General/Settings/Settings";
import AcceptedJobs from "src/Pages/Worker/AcceptedJobs/AcceptedJobs";
import MessagesWorker from "src/Pages/Worker/Chat/MessagesWorker";
import DashboardWorker from "src/Pages/Worker/Dashboard/DashboardWorker";
import FindWork from "src/Pages/Worker/FindWork/FindWork";
import { VerticalLayout } from "src/Pages/Worker/Layout/VerticalLayout";
import WorkerProposals from "src/Pages/Worker/Offers/WorkerProposals";
import EnterpriseProfile from "src/Pages/Worker/Profile/EntrepriseProfile";
import Profile from "src/Pages/Worker/Profile/Profile";
import ProfileReviews from "src/Pages/Worker/Profile/ProfileReviews";
import AddBio from "src/Pages/Worker/Registration/AddBio";
import AddBudget from "src/Pages/Worker/Registration/AddBudget";
import AddCategories from "src/Pages/Worker/Registration/AddCategories";
import AddDetails from "src/Pages/Worker/Registration/AddDetails";
import AddEducation from "src/Pages/Worker/Registration/AddEducation";
import AddEmployment from "src/Pages/Worker/Registration/AddEmployment";
import AddLanguages from "src/Pages/Worker/Registration/AddLanguages";
import AddScheduleGood from "src/Pages/Worker/Registration/AddScheduleGood";
import AddSkills from "src/Pages/Worker/Registration/AddSkills";
import AddTitle from "src/Pages/Worker/Registration/AddTitle";
import ProfileReady from "src/Pages/Worker/Registration/ProfileReady";
import SubmitProfile from "src/Pages/Worker/Registration/SubmitProfile";
import Reports from "src/Pages/Worker/Reports/Reports";
import Subscriptions from "src/Pages/Worker/Subscriptions/Subscriptions";
import JobDraftEdit from "src/Components/InitialClientForm/JobDraftEdit";
import HiredApplicants from "src/Pages/Employer/ApplicationProcess/HiredApplicants";
import OffersApplicants from "src/Pages/Employer/ApplicationProcess/OffersApplicants";
import InviteWorkers from "src/Pages/Employer/ApplicationProcess/InviteWorkers";
import ViewJobPost from "src/Pages/Employer/ApplicationProcess/ViewJobPost";
import ClosedViewJobPost from "src/Pages/Employer/ApplicationProcess/ClosedViewJobPost";
import ReviewCandidates from "src/Pages/Employer/ApplicationProcess/ReviewCandidates";
import SendAnOffer from "src/Pages/Employer/ApplicationProcess/SendAnOffer";
import JobPostingsSearch from "src/Components/AllKindOfJobs/JobPostingsSearch";
import ProtectedRoute from "./Employer/ProtectedRoute";
import ComingSoon from "src/Pages/General/ComingSoon/ComingSoon";
import Transactions from "src/Pages/finances/Transactions";
const Legal = lazy(() => import("src/Pages/General/Legal/Legal"));
const VerifyEmail = lazy(
  () => import("src/Pages/General/Register/VerifyEmail")
);

const ValidateEmail = lazy(
  () => import("src/Pages/General/ValidateEmail/ValidateEmail")
);

const NewPassword = lazy(
  () => import("src/Components/UpdatePassword/NewPassword")
);

const UpdatePassword = lazy(
  () => import("src/Components/UpdatePassword/UpdatePassword")
);

const Notifications = lazy(
  () => import("src/Pages/General/Notifications/Notifications")
);

const PasswordAndSecurity = lazy(
  () => import("src/Pages/Employer/Settings/PasswordAndSecurity")
);

const WhereTo = lazy(() => import("src/Pages/Worker/Registration/WhereTo"));
const Home = lazy(() => import("src/Pages/General/Home/Home"));
const Login = lazy(() => import("src/Pages/General/Login/Login"));
const Step1 = lazy(() => import("src/Pages/General/Register/Step1"));
const Step2Client = lazy(
  () => import("src/Pages/General/Register/Step2Client")
);
const Step2Enterprise = lazy(
  () => import("src/Pages/General/Register/Step2Enterprise")
);
const StepTwoWorker = lazy(
  () => import("src/Pages/General/Register/StepTwoWorker")
);
const Articles = lazy(() => import("src/Pages/General/Articles/Articles"));
const EmployerGuide = lazy(
  () => import("src/Components/Articles/EmployerGuide")
);
const WorkerGuide = lazy(() => import("src/Components/Articles/WorkerGuide"));
const AboutUs = lazy(() => import("src/Pages/General/AboutUs/AboutUs"));
const Pricing = lazy(() => import("src/Pages/Pricing/Pricing"));
const NotFound = lazy(() => import("src/Pages/General/NotFound/NotFound"));
const Contact = lazy(() => import("src/Pages/contact/Contact"));

function ErrorBoundary() {
  const error = useRouteError();
  console.log(error);
  return <div>Dang!</div>;
}
interface LayoutProps {
  children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => (
  <>
    <Outlet />
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route
        path="/login"
        element={<Login />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/transactions"
        errorElement={<ErrorBoundary />}
        element={<Transactions />}
      />
      <Route
        path="/coming-soon"
        element={<ComingSoon />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="/aboutus"
        element={<AboutUs />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/please-verify"
        element={<VerifyEmail />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/legal"
        element={<Legal />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/articles"
        element={<Articles />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/how-to-worker-guide"
        element={<WorkerGuide />}
        errorElement={<ErrorBoundary />}
      />

      <Route path="*" element={<NotFound />} />

      <Route
        path="/verify_email/:token/:id"
        element={<ValidateEmail />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/subscriptions"
        element={<Subscriptions />}
      />
      <Route
        path="/password/:token/:id"
        element={<NewPassword />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/forgot-password"
        element={<UpdatePassword />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/settings/password-and-security"
        element={<PasswordAndSecurity />}
        errorElement={<ErrorBoundary />}
      />

      <Route path="/" element={<Home />} errorElement={<ErrorBoundary />} />

      <Route
        path="/register"
        element={<Step1 />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/register-client"
        element={<Step2Client />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/register-enterprise"
        element={<Step2Enterprise />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/register-worker"
        element={<StepTwoWorker />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/pricing"
        element={<Pricing />}
        errorElement={<ErrorBoundary />}
      />

      <Route
        path="/contact"
        element={<Contact />}
        errorElement={<ErrorBoundary />}
      />

      <Route path="/how-to-employer-guide" element={<EmployerGuide />} />

      <Route
        errorElement={<ErrorBoundary />}
        path="/settings/password-and-security"
        element={
          <VerticalLayout>
            <PasswordAndSecurity />
          </VerticalLayout>
        }
      />

      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/title"
        element={<AddTitle />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/employment"
        element={<AddEmployment />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/education"
        element={<AddEducation />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/languages"
        element={<AddLanguages />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/skills"
        element={<AddSkills />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/bio"
        element={<AddBio />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/categories"
        element={<AddCategories />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/submit"
        element={<SubmitProfile />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/details"
        element={<AddDetails />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/schedule"
        element={<AddScheduleGood />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/budget"
        element={<AddBudget />}
      />
      <Route
        errorElement={<ErrorBoundary />}
        path="/create-profile/finish"
        element={<ProfileReady />}
      />
      <Route element={<ProtectedRoute />}>
        <Route
          errorElement={<ErrorBoundary />}
          path="/dashboard-worker"
          element={
            <VerticalLayout>
              <DashboardWorker />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/profile"
          element={
            <VerticalLayout>
              <Profile />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/messages"
          element={
            <VerticalLayout>
              <MessagesWorker />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/settings"
          element={
            <VerticalLayout>
              <Settings />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/jobs-search"
          element={
            <VerticalLayout>
              <JobSearch />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/job-offer/:offerId"
          element={
            <VerticalLayout>
              <OfferDetails />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/applied-jobs"
          element={
            <VerticalLayout>
              <WorkerProposals />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/view-offer"
          element={
            <VerticalLayout>
              <ReceivedOffer />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/my-jobs"
          element={
            <VerticalLayout>
              <AcceptedJobs />
            </VerticalLayout>
          }
        />

        <Route
          errorElement={<ErrorBoundary />}
          path="/find-work"
          element={
            <VerticalLayout>
              <FindWork />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/calendar"
          element={
            <VerticalLayout>
              <Calendar />
            </VerticalLayout>
          }
        />
        <Route
          errorElement={<ErrorBoundary />}
          path="/reports"
          element={
            <VerticalLayout>
              <Reports />
            </VerticalLayout>
          }
        />

        <Route
          path="/notifications"
          element={
            <VerticalLayout>
              <Notifications />
            </VerticalLayout>
          }
        />
        <Route
          path="/entrepise-profile"
          element={
            <VerticalLayout>
              <EnterpriseProfile />
            </VerticalLayout>
          }
        />
        <Route
          path="/entrepise-profile/reviews"
          element={
            <VerticalLayout>
              <ProfileReviews />
            </VerticalLayout>
          }
        />

        <Route
          path="/settings"
          element={
            <VerticalLayout>
              <Settings />
            </VerticalLayout>
          }
        />
        <Route
          path="/welcome-client"
          element={
            <VerticalLayout>
              <TitleJob />
            </VerticalLayout>
          }
        />

        <Route
          path="/job-post-review"
          element={
            <VerticalLayout>
              <JobPostReview />
            </VerticalLayout>
          }
        />
        <Route
          path="/job-post-edit/:id"
          element={
            <VerticalLayout>
              <JobPostEdit />
            </VerticalLayout>
          }
        />
        <Route
          path="/job-draft-edit/:id"
          element={
            <VerticalLayout>
              <JobDraftEdit />
            </VerticalLayout>
          }
        />

        <Route
          path="/applicants/hired"
          element={
            <VerticalLayout>
              <HiredApplicants />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/applicants/offers"
          element={
            <VerticalLayout>
              <OffersApplicants />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/applicants/invite"
          element={
            <VerticalLayout>
              <InviteWorkers />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/applicants/view-job-post"
          element={
            <VerticalLayout>
              <ViewJobPost />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/applicants/view-closed-job-post"
          element={
            <VerticalLayout>
              <ClosedViewJobPost />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/applicants/review"
          element={
            <VerticalLayout>
              <ReviewCandidates />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/send-offer"
          element={
            <VerticalLayout>
              <SendAnOffer />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/all-jobs"
          element={
            <VerticalLayout>
              <JobPostingsSearch />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/all-closed-jobs"
          element={
            <VerticalLayout>
              <ClosedJobsSearch />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/all-drafts"
          element={
            <VerticalLayout>
              <DraftsSearch />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/dashboard"
          element={
            <VerticalLayout>
              <Dashboard />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/messages-em"
          element={
            <VerticalLayout>
              <MessagesEmployer />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/my-hires"
          element={
            <VerticalLayout>
              <HiredWorkers />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />

        <Route
          path="/find-workers"
          element={
            <VerticalLayout>
              <FindWorkers />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />

        <Route
          path="/find-worker/:id"
          element={
            <VerticalLayout>
              <WorkerProfileScreen />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />

        <Route
          path="/credits"
          element={
            <VerticalLayout>
              <Credits />
            </VerticalLayout>
          }
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="/job-post/invite/:id"
          element={<JobPostInvite />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
    </Route>
  )
);

export default function AuthNavigation() {
  return <RouterProvider router={router} />;
}
