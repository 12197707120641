import React, { useEffect, useState } from "react";

import { ReactComponent as NoData } from "../../assets/img/work.svg";

import { useTranslation } from "react-i18next";
import { HireTypes } from "../../types/types";
import DiplayHiresJobShared from "./DiplayHiresJobShared";
import DisplayHiredJobsNotShared from "./DisplayHiredJobsNotShared";

interface DisplayHiresProps {
  hiredWorkers: HireTypes[];
  setClickedWorker: any;
  setShowModal: any;
  section: string;
  getWorkerInfo: (arg: string) => void;
}

const DisplayHires = ({
  hiredWorkers,
  setClickedWorker,
  setShowModal,
  section,
  getWorkerInfo,
}: DisplayHiresProps) => {
  const [showHires, setShowHires] = useState<any>([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    {
      hiredWorkers?.map((hire, i, arr) => {
        if (arr[i - 1]?.job_id !== arr[i]?.job_id) {
          setShowHires((prev: any) => [
            ...prev,
            {
              state: true,
              job_id: hire.job_id,
            },
          ]);
        }
      });
    }
  }, [hiredWorkers]);

  return (
    <div className="dark:border-border dark:border dark:text-whiteish dark:bg-background">
      <>
        {hiredWorkers.length === 0 ? (
          <div className="flex flex-col items-center  pb-2">
            <NoData className="h-40 lg:h-48 lg:mt-12 mb-4 lg:mb-8 lg:mx-auto" />
            <p className="flex justify-center font-bold text-lg tracking-wider mb-12">
              {section === "active" &&
                t("employer.myhires.activecontract.Text")}
              {section === "pending" &&
                t("employer.myhires.pendingcontract.Text")}
              {section === "finished" &&
                t("employer.myhires.finishedcontract.Text")}
            </p>
          </div>
        ) : (
          <>
            {hiredWorkers
              .sort((a, b) => a.date_start?.localeCompare(b.date_start))
              .reverse()
              ?.map((hire, i, arr) => {
                let starsScore = "";
                if (hire.score !== "0") starsScore = hire.score;
                let endingEnd = hire.date_end?.slice(9);
                let endingStart = hire.date_start?.slice(9);
                let endingEarlyEnd = "";
                if (hire.date_early_end) {
                  endingEarlyEnd = hire.date_early_end?.slice(9);
                  if (endingEarlyEnd === "1") {
                    endingEarlyEnd = "st";
                  } else if (endingEarlyEnd === "2") {
                    endingEarlyEnd = "nd";
                  } else if (endingEarlyEnd === "3") {
                    endingEarlyEnd = "rd";
                  } else {
                    endingEarlyEnd = "th";
                  }
                }
                if (endingEnd === "1") {
                  endingEnd = "st";
                } else if (endingEnd === "2") {
                  endingEnd = "nd";
                } else if (endingEnd === "3") {
                  endingEnd = "rd";
                } else {
                  endingEnd = "th";
                }
                if (endingStart === "1") {
                  endingStart = "st";
                } else if (endingStart === "2") {
                  endingStart = "nd";
                } else if (endingStart === "3") {
                  endingStart = "rd";
                } else {
                  endingStart = "th";
                }
                if (arr[i - 1]?.job_id === arr[i]?.job_id) {
                  return (
                    <DiplayHiresJobShared
                      key={hire.connection_id}
                      hire={hire}
                      showHires={showHires}
                      setShowHires={setShowHires}
                      getWorkerInfo={getWorkerInfo}
                      setClickedWorker={setClickedWorker}
                      endingStart={endingStart}
                      endingEnd={endingEnd}
                      endingEarlyEnd={endingEarlyEnd}
                      setShowModal={setShowModal}
                    />
                  );
                } else {
                  return (
                    <DisplayHiredJobsNotShared
                      key={hire.connection_id}
                      arr={arr}
                      i={i}
                      hire={hire}
                      showHires={showHires}
                      setShowHires={setShowHires}
                      getWorkerInfo={getWorkerInfo}
                      setClickedWorker={setClickedWorker}
                      endingStart={endingStart}
                      endingEnd={endingEnd}
                      endingEarlyEnd={endingEarlyEnd}
                      setShowModal={setShowModal}
                    />
                  );
                }
              })}
          </>
        )}
      </>
    </div>
  );
};

export default DisplayHires;
