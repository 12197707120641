import React from "react";

import { ThreeDots } from "react-loader-spinner";

const DotsLoader = (props) => {
  return (
    <div className="flex items-center">
      <ThreeDots
        height={props.height}
        width={props.width}
        radius={props.radius}
        color={props.color}
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
      {props.show === "all" && (
        <>
          <p className="text-xs">Loading</p>
          <ThreeDots
            height={props.height}
            width={props.width}
            radius={props.radius}
            color={props.color}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </>
      )}
    </div>
  );
};

export default DotsLoader;
