import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { messages4JobId } from "../../Atoms/jotaiAtoms";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { ModalInappropriateJob } from "../Modals/ModalInappropriateJob";
import ModalDeclineOffer from "../Modals/ModalDeclineOffer";
import { ReactComponent as Tag } from "../../assets/img/price-tag.svg";
import { ReactComponent as Expertise } from "../../assets/img/expertise.svg";
import { ReactComponent as Flag } from "../../assets/img/flag.svg";
import { ReactComponent as InPerson } from "../../assets/img/in-person.svg";
import { ReactComponent as Check } from "../../assets/img/check-solid.svg";
import {
  HandleMonthName,
  HandleMonthNameES,
} from "../../Utils/HandleMonthName";

import RatingStars from "../Rating/RatingStars";
import RequestErrorModal from "../Modals/RequestErrorModal";
import { VerticalLayout } from "../../Pages/Worker/Layout/VerticalLayout";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import { EmployerTypes, OffersTypes } from "../../types/types";
import GoBackButton from "../ui/GoBack";
import { formatJobTitle } from "src/Utils/formatters";
import { useQuery } from "react-query";
import { userProfileStore } from "src/Routing/useProfileStore";

function ReceivedOffer() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [activateConfetti, setActivateConfetti] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [offerData, setOfferData] = useState<OffersTypes>();
  const [employerData, setEmployerData] = useState<EmployerTypes>();
  const [showModal, setShowModal] = useState({ report: false });
  const [showModalDeclineOffer, setShowModalDeclineOffer] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [declineExplanation, setDeclineExplanation] = useState("");
  const [dataForFetch, setDataForFetch] = useLocalStorage(
    "dataForFetchingOffer"
  );
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const [loading, setLoading] = useState(true);
  const [activeStar, setActiveStar] = useState();
  const [messagesJobId, setMessagesJobId] = useState(messages4JobId);
  const [warning, setWarning] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const getOfferData = async () => {
    const data = await fetch(
      `${BASE_URL}worker.offers.jobs?client_id=${dataForFetch?.client_id}&job_id=${dataForFetch?.job_id}&worker_id=${userID}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());
    return data.result;
  };

  const { data: offersData, isLoading: isOffersLoading } = useQuery(
    ["offers", dataForFetch?.job_id],
    getOfferData
  );

  useEffect(() => {
    if (!isOffersLoading) {
      setOfferData(offersData.JobOffer[0]);
    }
  }, [offersData, isOffersLoading]);

  const getOfferDataFromJobs = async () => {
    const data = await fetch(
      `${BASE_URL}jobs?client_id=${userID}&job_id=${dataForFetch?.job_id}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: offersFromJob, isLoading: isOffersFromJobLoading } = useQuery(
    ["offersData", dataForFetch?.job_id],
    getOfferDataFromJobs
  );

  useEffect(() => {
    if (!isOffersFromJobLoading) {
      setEmployerData(offersFromJob.employer_score);
      setOfferData((prev: any) => ({
        ...prev,
        ...offersFromJob.data[0],
      }));
    }
  }, [offersFromJob]);

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", dataForFetch?.client_id);
  data.append("job_id", dataForFetch?.job_id);
  data.append("worker_id", userID);
  const answerOffer = (answer: string) => {
    if (answer === "accept") {
      data.append("hire_status_id", "3");
    } else {
      data.append("decline_offer_reason_id", declineReason);
      data.append("hire_status_id", "2");
    }
    fetch(`${BASE_URL}worker.offers.jobs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          if (answer === "accept") {
            setOfferData((prev: any) => ({
              ...prev,
              hired_status: "Accepted",
            }));
            setActivateConfetti(true);
            setTimeout(() => {
              if (profileType === "worker") {
                navigate("/my-jobs");
              }
            }, 5000);
          } else {
            setOfferData((prev: any) => ({
              ...prev,
              hired_status: "Declined",
            }));
          }
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {activateConfetti && (
        <>
          <Confetti
            numberOfPieces={300}
            gravity={0.3}
            initialVelocityX={10}
            initialVelocityY={150}
          />
          <div
            className={`flex justify-center transition-opacity duration-700 ${
              !activateConfetti ? "opacity-0" : "opacity-100"
            }`}
          >
            <div className="bg-green-600 text-white font-medium text-xl rounded-lg py-3.5 tracking-wide px-6 mt-12 fixed items-start shadow-lg">
              <div className="flex justify-between gap-[78px] items-center">
                <div className="flex gap-4 items-center">
                  <Check className="h-8 fill-white" />
                  <p>{t("employer.received_offer.accepted_offer")}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="max-w-6xl mx-auto  h-full  overflow-y-auto pb-16">
        <RequestErrorModal warning={warning} />
        <div
          className="flex items-center mx-12 mt-6 gap-2 fill-azulBonico cursor-pointer text-azulBonico hover:underline"
          onClick={() => navigate(-1)}
        >
          <GoBackButton />
        </div>
        {/* <p className="text-2xl mx-12 mt-6 font-medium">
            {t("employer.received_offer.view_offer")}
          </p> */}
        <div>
          {/* {loading ? (
              <Suspense fallback={null}>
                <Loader />
              </Suspense>
            ) : ( */}
          <div className="text-xl mt-6 mx-6  rounded h-fit shadow bg-white  flex justify-between dark:bg-background dark:border-border dark:border dark:text-whiteish">
            <div className="flex-[0_0_70%] max-w-[70%] border-r ">
              <div className="border-b ">
                <p className="font-semibold text-2xl tracking-wide my-6 mx-6">
                  {formatJobTitle(offerData?.title ?? "")}
                </p>
              </div>
              <div className="flex border-b  py-6 text-sm mx-6  ">
                <div className="flex-[0_0_20%] max-w-[20%] space-y-2">
                  <p className="font-medium">
                    {t("employer.received_offer.status")}
                  </p>
                  <p className="font-medium">
                    {t("employer.received_offer.start_date")}
                  </p>
                  <p className="font-medium">
                    {t("employer.received_offer.end_date")}
                  </p>
                  <p className="font-medium">
                    {t("employer.received_offer.offer_date")}
                  </p>
                  {/* <p className="font-medium">
                        {t("employer.received_offer.due_date")}
                      </p> */}
                </div>
                <div className="flex-[0_0_80%] max-w-[80%] justify-start ml-4 text-[#333] space-y-2">
                  {offerData?.hired_status === "Pending" && (
                    <p>
                      {t("employer.received_offer.pending")}{" "}
                      {i18n.language === "en" ? (
                        <>
                          {offerData.expire_offer_date !== "" && (
                            <>
                              {offerData?.expire_offer_date?.slice(8)}
                              {offerData?.expire_offer_date?.slice(9) ===
                                "1" && <span className="text-xs">st</span>}
                              {offerData?.expire_offer_date?.slice(9) ===
                                "2" && <span className="text-xs">nd</span>}
                              {offerData?.expire_offer_date?.slice(9) ===
                                "3" && <span className="text-xs">rd</span>}
                            </>
                          )}
                          {offerData.date_start !== "" && (
                            <>
                              {offerData?.date_start?.slice(9) !== "1" &&
                                offerData?.date_start?.slice(9) !== "2" &&
                                offerData?.date_start?.slice(9) !== "3" && (
                                  <span className="text-xs">th</span>
                                )}{" "}
                            </>
                          )}
                          {HandleMonthName(
                            offerData?.expire_offer_date?.slice(5, 7)
                          )}{" "}
                        </>
                      ) : (
                        <>
                          {offerData?.expire_offer_date?.slice(8)} de{" "}
                          {HandleMonthNameES(
                            offerData?.expire_offer_date?.slice(5, 7)
                          )}{" "}
                        </>
                      )}
                      ({offerData?.expire_offer_days}{" "}
                      {t("employer.received_offer.days_left")})
                    </p>
                  )}
                  {offerData?.hired_status === "Accepted" && (
                    <p>{t("employer.received_offer.hired")}</p>
                  )}
                  {offerData?.hired_status === "Declined" && (
                    <p>{t("employer.received_offer.declined")}</p>
                  )}
                  <p>
                    {t("employer.received_offer.job_starts_on")}{" "}
                    {i18n.language === "en" ? (
                      <>
                        {HandleMonthName(offerData?.date_start?.slice(5, 7))}{" "}
                        {offerData?.date_start?.slice(8)}
                        {offerData?.date_start?.slice(9) === "1" && (
                          <span className="text-xs">st</span>
                        )}
                        {offerData?.date_start?.slice(9) === "2" && (
                          <span className="text-xs">nd</span>
                        )}
                        {offerData?.date_start?.slice(9) === "3" && (
                          <span className="text-xs">rd</span>
                        )}
                        {offerData?.date_start?.slice(9) !== "1" &&
                          offerData?.date_start?.slice(9) !== "2" &&
                          offerData?.date_start?.slice(9) !== "3" && (
                            <span className="text-xs">th</span>
                          )}{" "}
                      </>
                    ) : (
                      <>
                        {offerData?.date_start?.slice(8)} de{" "}
                        {HandleMonthNameES(offerData?.date_start?.slice(5, 7))}
                      </>
                    )}
                  </p>
                  <p>
                    {t("employer.received_offer.job_ends_on")}{" "}
                    {i18n.language === "en" ? (
                      <>
                        {HandleMonthName(offerData?.date_end?.slice(5, 7))}{" "}
                        {offerData?.date_end?.slice(8)}
                        {offerData?.date_end?.slice(9) === "1" && (
                          <span className="text-xs">st</span>
                        )}
                        {offerData?.date_end?.slice(9) === "2" && (
                          <span className="text-xs">nd</span>
                        )}
                        {offerData?.date_end?.slice(9) === "3" && (
                          <span className="text-xs">rd</span>
                        )}
                        {offerData?.date_end?.slice(9) !== "1" &&
                          offerData?.date_end?.slice(9) !== "2" &&
                          offerData?.date_end?.slice(9) !== "3" && (
                            <span className="text-xs">th</span>
                          )}
                      </>
                    ) : (
                      <>
                        {offerData?.date_end?.slice(8)} de{" "}
                        {HandleMonthNameES(offerData?.date_end?.slice(5, 7))}
                      </>
                    )}
                  </p>
                  {offerData?.offer_date_created !== null && (
                    <p>
                      {i18n.language === "en" ? (
                        <>
                          {HandleMonthName(
                            offerData?.offer_date_created?.slice(5, 7)
                          )}{" "}
                          {offerData?.offer_date_created?.slice(8, 10)}
                          {offerData?.offer_date_created?.slice(9) === "1" && (
                            <span className="text-xs">st</span>
                          )}
                          {offerData?.offer_date_created?.slice(9) === "2" && (
                            <span className="text-xs">nd</span>
                          )}
                          {offerData?.offer_date_created?.slice(9) === "3" && (
                            <span className="text-xs">rd</span>
                          )}
                          {offerData?.offer_date_created?.slice(9) !== "1" &&
                            offerData?.offer_date_created?.slice(9) !== "2" &&
                            offerData?.offer_date_created?.slice(9) !== "3" && (
                              <span className="text-xs">th</span>
                            )}
                        </>
                      ) : (
                        <>
                          <>
                            {offerData?.offer_date_created?.slice(8, 10)} de{" "}
                            {HandleMonthNameES(
                              offerData?.offer_date_created?.slice(5, 7)
                            )}
                          </>
                        </>
                      )}
                      , {offerData?.offer_date_created?.slice(0, 4)}
                    </p>
                  )}

                  {/* {i18n.language === "en" ? (
                        <p>In {offerData?.due_days} days</p>
                      ) : (
                        <p>En {offerData?.due_days} días</p>
                      )} */}
                </div>
              </div>
              <div className="border-b  flex flex-col py-6">
                <div className="flex items-start justify-between mt-2">
                  <div>
                    <div>
                      <div className="flex items-center gap-3 mx-6 ">
                        <InPerson className="h-3 " />
                        <p className="text-sm font-medium">
                          {offerData?.type_name}
                        </p>
                      </div>
                      {offerData?.type_name === "In-person" && (
                        <p className="mx-6 text-xs mt-1">
                          {offerData?.location}, {offerData?.country_code}
                        </p>
                      )}
                      {offerData?.type_name === "Hybrid" && (
                        <p className="text-sm mx-6 font-medium">
                          {offerData?.country_code}
                        </p>
                      )}
                    </div>
                    {/* <div className="flex items-center gap-3 mx-6">
                          <InPerson className="h-3" />
                          <p className="text-sm">{offerData?.type_name}</p>
                        </div> */}
                    {/* <p className="text-sm mx-6 font-medium">
                          {offerData?.city_name}
                        </p> */}
                    {/* {offerData?.type_name === "In-person" && (
                          <p className="text-sm mx-6 font-medium">
                            {offerData?.location}, {offerData?.country_code}
                          </p>
                        )}
                        {offerData?.type_name === "Hybrid" && (
                          <p className="text-sm mx-6 font-medium">
                            {offerData?.country_code}
                          </p>
                        )} */}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex mx-6 items-start gap-3">
                      <Tag className="h-4 mt-[6px]" />
                      <div className="flex flex-col">
                        <p className="text-sm font-medium">
                          {offerData?.budget}€
                        </p>
                        <p className="text-xs mt-1">
                          {t("employer.received_offer.hourly_rate")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex mx-6 items-start gap-1">
                      <Expertise className="h-4 mt-1 w-fit" />
                      <div className="flex flex-col">
                        <p className="text-sm font-medium">
                          {offerData?.category_name}
                        </p>
                        <p className="text-xs mt-1">
                          {t("employer.received_offer.category")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {offerData?.details && (
                <div className="border-b  flex flex-col py-6">
                  <p className="mx-6 text-base font-medium">
                    {t("employer.received_offer.job_details")}
                  </p>
                  <p className="text-sm mx-6 mt-3">{offerData?.details}</p>
                </div>
              )}

              {/* <div className="border-b  flex flex-col py-6">
                    <p className="mx-6 text-base font-medium">
                      {t("employer.received_offer.skills")}{" "}
                    </p>
                    <div className="flex flex-wrap text-xs overflow-auto mt-4 mx-6 gap-2 ">
                      {offerData?.skill_list?.length > 0 && (
                        <>
                          {offerData?.skill_list
                            .split(",")
                            ?.map((skill: string) => {
                              return (
                                <div key={Math.random()}>
                                  <button className="px-2 text-xs py-0.5 mx-0.5 text-black bg-blancoGrisClaro my-0.5 flex border rounded-xl hover:bg-[#bdbdbd]">
                                    {skill}
                                  </button>
                                </div>
                              );
                            })}
                        </>
                      )}
                      {offerData?.skill_list?.length === 0 && (
                        <div>{t("skills.no_skills.empty_container")}</div>
                      )}
                    </div>
                  </div> */}
              <div className="border-b  flex flex-col py-6">
                <p className="mx-6 text-base font-medium mb-4">
                  {t("employer.received_offer.activity")}{" "}
                </p>
                <p className="text-sm mx-6 ">
                  {t("employer.received_offer.applicants")}:{" "}
                  <span className="font-medium">{offerData?.applicants}</span>
                </p>
              </div>
            </div>
            <div className="flex-[0_0_30%] max-w-[30%] ">
              <div className="border-b  font-medium py-6 ">
                {offerData?.hired_status === "Pending" && (
                  <div className="flex flex-col gap-4 items-center mx-6 text-sm">
                    <button
                      className="w-full py-2.5  border  text-white bg-azulBonico hover:bg-azulOscuro   rounded-full transition duration-300"
                      onClick={() => answerOffer("accept")}
                    >
                      {t("employer.received_offer.accept_offer")}
                    </button>
                    <button
                      className="w-full py-2.5 text-azulBonico hover:bg-[#f7f4f4] border  hover:border-black hover:text-black bg-white  rounded-full transition duration-300"
                      onClick={() => {
                        setMessagesJobId(dataForFetch?.job_id);
                        setTimeout(() => {
                          navigate("/messages");
                        }, 50);
                      }}
                    >
                      {t("employer.received_offer.messages")}
                    </button>
                    <button
                      className="w-full py-2.5 text-azulBonico hover:bg-[#f7f4f4] border  hover:border-black hover:text-black bg-white  rounded-full transition duration-300"
                      onClick={() => setShowModalDeclineOffer(true)}
                    >
                      {t("employer.received_offer.decline_offer")}
                    </button>
                  </div>
                )}
                {offerData?.hired_status === "Declined" && (
                  <div className="border-b   pb-6 text-sm">
                    <div className="mx-6">
                      <p className="text-black bg-blancoGris text-sm  border text-center w-full mb-1 pl-[20px] py-2.5 rounded-full px-2  ">
                        {t("employer.received_offer.declined")}
                      </p>
                    </div>
                  </div>
                )}
                {offerData?.hired_status === "Accepted" && (
                  <div className=" mx-6">
                    <div className="relative w-full cursor-not-allowed">
                      <Check
                        className="h-4 absolute left-[33%] top-[13px] "
                        fill={"#2abeeb"}
                      />
                      <p className="text-azulBonico text-sm font-medium border text-center w-full mb-1 pl-[20px] py-2.5 rounded-full px-2 border-azulBonico ">
                        {t("employer.received_offer.accepted")}
                      </p>
                    </div>
                  </div>
                )}
                {window.location.pathname !== "/view-offer" && (
                  <>
                    {offerData?.inapropiate_job === "true" ? (
                      <div className="mx-6 mt-7 flex items-center gap-3 cursor-not-allowed">
                        <Flag className="h-3" fill={"rgb(148, 168, 184)"} />
                        <p className="text-sm text-slate-400">
                          {t("worker.offer_details.inappropiate_flagged")}
                        </p>
                      </div>
                    ) : (
                      <div
                        className="mx-6 mt-7 flex items-center gap-3 cursor-pointer text-azulBonico hover:underline"
                        onClick={() =>
                          setShowModal((prev) => ({
                            ...prev,
                            report: true,
                          }))
                        }
                      >
                        <Flag className="h-3" fill={"#2abeeb"} />
                        <p className=" text-sm ">
                          {" "}
                          {t("employer.received_offer.flag")}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="border-b  py-6 ">
                <p className="mx-6 text-base font-medium">
                  {t("employer.received_offer.about_employer")}
                </p>
                <p className="text-sm font-medium mx-6 mt-2">
                  {employerData?.employer_first_name}{" "}
                  {employerData?.employer_last_name}
                </p>
                <p className="mt-1 mx-6 text-xs text-slate-400">
                  {t("worker.dashboard.member_since")}{" "}
                  {i18n.language === "en"
                    ? HandleMonthName(employerData?.date_joined?.slice(5, 7))
                    : HandleMonthNameES(
                        employerData?.date_joined?.slice(5, 7)
                      )}{" "}
                  {employerData?.date_joined?.slice(0, 4)}
                </p>
                <div className="mx-6 mt-4">
                  <RatingStars
                    activeStar={employerData?.avgScore}
                    score={employerData?.avgScore}
                    setActiveStar={setActiveStar}
                    evaluation={false}
                    showHalf={true}
                    info={employerData?.employer_score}
                  />
                  <div className="relative mt-1 mb-4 whitespace-nowrap">
                    <p className="text-[13px] text-slate-500">
                      {employerData?.totalScores}{" "}
                      {t("worker.modal_job_card.rating_reviews")}
                    </p>
                  </div>
                </div>
                <ul className="flex flex-col gap-2 font-medium mt-4">
                  <li className="mx-6 text-sm flex items-center">
                    <p className="w-9 ">
                      {" "}
                      {Math.round(employerData?.avgComplianceDate!)}%{" "}
                    </p>
                    <span className="text-xs text-gray-500 ml-2">
                      {t("worker.offer_details.job_success")}
                    </span>
                  </li>
                  <li className="mx-6 text-sm flex items-center">
                    <div className="flex items-center w-9 ">
                      {employerData?.totalConnections}
                      {/* <p className=" ">
                            <span className="text-xs">
                              / {jobInfo.data[0].workers_needed}{" "}
                            </span>
                          </p> */}
                    </div>
                    <span className="text-xs text-gray-500 ml-2">
                      {t("worker.offer_details.hired_workers")}
                    </span>
                  </li>
                  <li className="mx-6  text-sm flex items-center ">
                    <div className="flex items-center w-9">
                      {employerData?.totalConnectionsTerminated}
                      {/* <p className=" ">
                    <span className="text-xs">
                      / {jobInfo.data[0].workers_needed}{" "}
                    </span>
                  </p> */}
                    </div>
                    <span className="text-xs text-gray-500 ml-2">
                      {t("worker.offer_details.early_end_contracts")}
                    </span>
                  </li>
                  {/* <p className="mx-6 text-sm">
                        {Math.round(employerData?.avgComplianceDate!)}%{" "}
                        {t("employer.received_offer.job_success")}
                      </p> */}
                  {/* <p className="mx-6 text-sm">
                        {employerData?.totalConnections}{" "}
                        {t("employer.received_offer.hired_workers")}
                      </p> */}
                  {/* <p className="mx-6 text-sm">
                        {employerData?.totalConnectionsTerminated}{" "}
                        {t("employer.received_offer.early_end_contracts")}
                      </p> */}
                </ul>
              </div>
              {/* <div className="py-6">
                  <p className="mx-6 text-base font-medium">Job link</p>
                  <input
                    type="text"
                    placeholder="https://www.flexihours.com/erxfcz"
                    className="w-max text-sm mx-6 border border-slate-200 rounded px-4 py-2 mt-6 cursor-not-allowed"
                    disabled={true}
                  />
                  <p
                    className="text-azulBonico cursor-pointer mx-6 hover:underline hover:text-azulOscuro text-sm font-medium mt-2"
                    onClick={() => copyOfferUrl()}
                  >
                    Copy link
                  </p>
                </div> */}
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      {showModal.report && (
        <ModalInappropriateJob
          showModal={showModal}
          setShowModal={setShowModal}
          job={offerData!}
          offers={offersFromJob!}
          setJob={setOfferData}
        />
      )}
      {showModalDeclineOffer && (
        <ModalDeclineOffer
          showModal={showModalDeclineOffer}
          setShowModal={setShowModalDeclineOffer}
          declineReason={declineReason}
          setDeclineReason={setDeclineReason}
          setDeclineExplanation={setDeclineExplanation}
          declineOffer={answerOffer}
        />
      )}
    </div>
  );
}

export default ReceivedOffer;
