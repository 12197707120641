import { useAtom } from "jotai";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiMoreLine } from "react-icons/ri";
import { counterSaved } from "src/Atoms/jotaiAtoms";

interface DashboardWorkerTabsProps {
  savedSearchedJobsData: any;
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  invitedJobs: [];
}

const DashboardWorkerTabs = ({
  savedSearchedJobsData,
  selected,
  setSelected,
  invitedJobs,
}: DashboardWorkerTabsProps) => {
  const [savedJobsCounter, setSavedJobsCounter] = useAtom(counterSaved);
  const [showMobileModal, setShowMobileModal] = useState(false);

  const { t } = useTranslation();
  return (
    <div className="relative">
      <div className="flex items-center justify-between p-6">
        <p className="font-bold text-xl tracking-wide">
          {t("worker.findwork.body.title")}
        </p>
        <div
          onClick={() => setShowMobileModal(!showMobileModal)}
          className="block lg:hidden border rounded-full p-1 bg-white hover:bg-blancoGris transition duration-150 cursor-pointer"
        >
          <RiMoreLine size={20} color="black" />
        </div>
      </div>
      <div className="hidden lg:block border-border border-b">
        <div className="flex px-3 mobile:px-5 tablet:px-8 pb-3 justify-between tablet:justify-start tablet:gap-10 tablet:text-sm text-xs font-bold ">
          {savedSearchedJobsData?.length > 0 && (
            <p
              className={`cursor-pointer ${
                selected === "mySearches"
                  ? "underline decoration-[3px] underline-offset-[15px] text-[#2dbeeb] "
                  : "hover:text-azulOscuro"
              } `}
              onClick={() => setSelected("mySearches")}
            >
              {t("worker.findwork.card.layer1.title")}
            </p>
          )}
          <p
            className={` cursor-pointer  ${
              selected === "bestMatches"
                ? "underline decoration-[3px] underline-offset-[15px] text-[#2dbeeb]"
                : "hover:text-azulOscuro"
            } `}
            onClick={() => setSelected("bestMatches")}
          >
            {t("worker.findwork.card.layer2.title")}
          </p>
          <p
            className={`cursor-pointer ${
              selected === "invitations"
                ? "underline decoration-[3px] underline-offset-[15px] text-[#2dbeeb]"
                : "hover:text-azulOscuro"
            } `}
            onClick={() => setSelected("invitations")}
          >
            {t("worker.findwork.card.layer3.title")}
            {invitedJobs?.length > 0 ? ` (${invitedJobs?.length})` : ""}
          </p>

          <p
            className={`cursor-pointer ${
              selected === "savedJobs"
                ? "underline decoration-[3px] underline-offset-[15px] text-[#2dbeeb]"
                : "hover:text-azulOscuro"
            }`}
            onClick={() => setSelected("savedJobs")}
          >
            {t("worker.findwork.card.layer4.title")} ({savedJobsCounter ?? 0})
          </p>
        </div>
      </div>
      {showMobileModal && (
        <div className="absolute top-20 right-2 border bg-white rounded shadow py-2 z-40">
          <div
            className={`bg-white border-l border-t z-20 w-4 h-4 rotate-45 absolute -top-[7px] right-6`}
          ></div>
          <div className="flex flex-col space-y-2  px-3 mobile:px-5 tablet:px-8 pb-3 justify-between tablet:justify-start tablet:gap-10 tablet:text-sm text-xs font-bold ">
            {savedSearchedJobsData?.length > 0 && (
              <p
                className={`cursor-pointer ${
                  selected === "mySearches"
                    ? " underline-offset-[15px] text-[#2dbeeb] "
                    : "hover:text-azulOscuro"
                } `}
                onClick={() => {
                  setSelected("mySearches");
                  setShowMobileModal(false);
                }}
              >
                {t("worker.findwork.card.layer1.title")}
              </p>
            )}
            <p
              className={` cursor-pointer  ${
                selected === "bestMatches"
                  ? " underline-offset-[15px] text-[#2dbeeb]"
                  : "hover:text-azulOscuro"
              } `}
              onClick={() => {
                setSelected("bestMatches");
                setShowMobileModal(false);
              }}
            >
              {t("worker.findwork.card.layer2.title")}
            </p>
            <p
              className={`cursor-pointer ${
                selected === "invitations"
                  ? " underline-offset-[15px] text-[#2dbeeb]"
                  : "hover:text-azulOscuro"
              } `}
              onClick={() => {
                setSelected("invitations");
                setShowMobileModal(false);
              }}
            >
              {t("worker.findwork.card.layer3.title")}{" "}
              {invitedJobs?.length > 0 ? `(${invitedJobs?.length})` : ""}
            </p>

            <p
              className={`cursor-pointer ${
                selected === "savedJobs"
                  ? " underline-offset-[15px] text-[#2dbeeb]"
                  : "hover:text-azulOscuro"
              }`}
              onClick={() => {
                setSelected("savedJobs");
                setShowMobileModal(false);
              }}
            >
              {t("worker.findwork.card.layer4.title")} ({savedJobsCounter ?? 0})
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardWorkerTabs;
