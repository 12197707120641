import React, {
  useRef,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  ElementRef,
} from "react";
import Select from "react-select";
import { v4 as uniqueID } from "uuid";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, LANGUAGES, LANGUAGE_WORKER } from "../../Config/api.config";

import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { useQuery } from "react-query";
import { allLevels } from "src/data/levels";

type Language = {
  language_code: string;
  languale_level_name: string;
  worker_language_id: string;
  language_name: string;
};

interface ModalEditLanguagesProps {
  setShowEditLanguagesModal: Dispatch<SetStateAction<boolean>>;
  showEditLanguagesModal: boolean;
}

function ModalEditLanguages({
  setShowEditLanguagesModal,
  showEditLanguagesModal,
}: ModalEditLanguagesProps) {
  const { t } = useTranslation();
  const [allLanguages, setAllLanguages] = useState([]);
  // const [allLevels, setAllLevels] = useState([]);
  const [receivedLanguages, setReceivedLanguages] = useState();
  const [copyReceivedLanguages, setCopyReceivedLanguages] = useState<
    Language[]
  >([]);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [transition, setTransition] = useState(false);
  const [languageLevelSpanish, setLanguageLevelSpanish] = useState("");
  const ref = useRef<ElementRef<"div">>(null);
  const unique_id = uniqueID();
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);

  const getAllLanguages = async () => {
    const data = await fetch(`${BASE_URL}${LANGUAGES}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      // .then((data) => setAllLanguages(data.result))

      .catch((err) => console.log(err));
    return data.result;
  };
  const { data: languages } = useQuery(["languages"], getAllLanguages);

  const getWorkerLanguages = async () => {
    const data = await fetch(
      `${BASE_URL}${LANGUAGE_WORKER}?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerLanguages } = useQuery(
    ["worker_languages"],
    getWorkerLanguages
  );

  useEffect(() => {
    setReceivedLanguages(workerLanguages);
    setCopyReceivedLanguages(workerLanguages);
    setAllLanguages(languages);
  }, [workerLanguages, languages]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      width: "250px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const deleteLanguage = (language: {
    language_code: string;
    languale_level_name: string;
    worker_language_id: string;
  }) => {
    fetch(
      `${BASE_URL}${LANGUAGE_WORKER}?client_id=${userID}&language_code=${language.language_code}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const updateLevel = (languale_level_name: string, index: string) => {
    setCopyReceivedLanguages((state) =>
      state?.map((el, i) =>
        el.worker_language_id === index ? { ...el, languale_level_name } : el
      )
    );
  };

  const level = (language: string) => {
    switch (language) {
      case "Basic":
        return "1";
      case "Conversational":
        return "2";
      case "Fluent":
        return "3";
      case "Native or Bilingual":
        return "4";
    }
  };
  const editWorkerLanguages = () => {
    data.append(
      "language_code_list",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      copyReceivedLanguages?.map((language) => language.language_code)
    );

    data.append(
      "language_level_id_list",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      copyReceivedLanguages?.map((language) =>
        level(language.languale_level_name)
      )
    );
    fetch(`${BASE_URL}${LANGUAGE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const handleDeletion = (language: Language) => {
    deleteLanguage(language);
    setCopyReceivedLanguages(
      copyReceivedLanguages.filter(
        (item) => item.worker_language_id !== language.worker_language_id
      )
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditLanguagesModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditLanguagesModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowEditLanguagesModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const translateLanguageLevel = (level: string) => {
    if (level === "Basic") {
      return "Básico";
    } else if (level === "Conversational") {
      return "Conversación";
    } else if (level === "Fluent") {
      return "Avanzado";
    } else if (level === "Native or Bilingual") {
      return "Nativo o Bilingüe";
    }
  };

  return (
    <Modal
      isOpen={showEditLanguagesModal}
      style={StylesModal}
      ariaHideApp={false}
    >
      {" "}
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50"> */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[80%] lg:w-[800px] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edityourLanguages.title.card")}
        </div>
        <div className="flex flex-col w-full text-sm mt-8 px-8">
          <div className="flex py-2 bg-[#2abeeb3d] font-bold px-4">
            <p>{t("createprofile.submit.edityourLanguages.subtitle1.card")}</p>
            <p className="ml-[38%]">
              {t("createprofile.submit.edityourLanguages.subtitle2.card")}
            </p>
          </div>

          <div className="flex flex-col">
            {copyReceivedLanguages?.map((language: Language) => {
              return (
                <div key={language.language_name}>
                  {language.language_name === "English" && (
                    <div
                      className="flex items-center gap-8 justify-between py-4 border-t border-blue-100"
                      key={unique_id}
                    >
                      <input
                        type="text"
                        value={language.language_name}
                        className="cursor-not-allowed h-10 flex-[0_0_40%] max-w-[40%] rounded pl-4"
                        disabled={true}
                      />
                      <Select
                        styles={colourStyles}
                        options={allLevels}
                        placeholder={language.languale_level_name}
                        isSearchable={false}
                        onChange={(e: any) =>
                          updateLevel(e.value, language.worker_language_id)
                        }
                        className="flex-[0_0_40%] max-w-[40%]"
                      />
                      <Trash className="h-4 mr-5" fill={"white"} />
                    </div>
                  )}
                </div>
              );
            })}
            {copyReceivedLanguages?.map((language, idx) => {
              return (
                <>
                  {language.language_name !== "English" && (
                    <div
                      className="flex items-center gap-8 justify-between py-4 border-t border-blue-100"
                      key={idx}
                    >
                      <input
                        type="text"
                        value={language.language_name}
                        className="cursor-not-allowed h-10 flex-[0_0_40%] max-w-[40%] rounded pl-4"
                        disabled={true}
                      />
                      <Select
                        styles={colourStyles}
                        options={allLevels}
                        placeholder={translateLanguageLevel(
                          language.languale_level_name
                        )}
                        isSearchable={false}
                        onChange={(e: any) =>
                          updateLevel(e.value, language.worker_language_id)
                        }
                        className="flex-[0_0_40%] max-w-[40%]"
                      />
                      <Trash
                        className="h-4 mr-5 cursor-pointer"
                        fill={"#cccccc"}
                        onClick={() => handleDeletion(language)}
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>

        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 mt-2 justify-end border-t border-border">
          <Button
            className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1"
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourLanguages.leftBtn")}
          </Button>
          <Button
            className="bg-azulBonico border-none hover:bg-azulOscuro text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]   h-10"
            onClick={(e) => {
              editWorkerLanguages();
              setTimeout(() => {
                setShowEditLanguagesModal(false);
                window.location.reload();
              }, 300);
            }}
          >
            {t("createprofile.submit.edityourLanguages.rightBtn")}
          </Button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ModalEditLanguages;
