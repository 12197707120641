import React, {
  useRef,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
  lazy,
  Suspense,
} from "react";
import { v4 as uniqueID } from "uuid";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import MonthPicker from "../Time/MonthPicker";
import YearPicker from "../Time/YearPicker";
import { BASE_URL, EXPERIENCE_WORKER } from "../../Config/api.config";
import CountryPicker from "../Worker/Registration/CountryPicker";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

import exclamation from "../../assets/img/exclamation.png";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { options } from "src/Utils/content/Years";

interface ModalAddEmploymentProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showAddJobModal: boolean;
}

export default function ModalAddEmployment({
  setShowModal,
  showAddJobModal,
}: ModalAddEmploymentProps) {
  const { t } = useTranslation();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [startingMonth, setStartingMonth] = useState("");
  const [startingYear, setStartingYear] = useState("");
  const [endingMonth, setEndingMonth] = useState("");
  const [endingYear, setEndingYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState({
    company: "",
    city: "",
    country: "",
    title: "",
    country_code: "ES",
    // date_start: "",
    // date_end: "",
    description: "",
    id: "",
    startingMonth: "",
    endingMonth: "",
  });
  const [checking, setChecking] = useState(false);
  const [checked, setChecked] = useState(false);
  const [transition, setTransition] = useState(false);
  const unique_id = uniqueID();
  const ref = useRef<HTMLDivElement>(null);
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append("company_name", job.company);
  data.append("city", job.city);
  data.append("job_title", job.title);
  data.append("start_date", `${startingYear}-${startingMonth}-01`);
  data.append("country_code", "ES");
  data.append("description", job.description);

  const saveExperience = () => {
    fetch(`${BASE_URL}${EXPERIENCE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const handleAddition = () => {
    if (
      checked === true &&
      job.title &&
      job.company &&
      job.city &&
      job.country_code &&
      startingMonth &&
      startingYear
    ) {
      data.append("currently", "1");
      setLoading(true);

      // handleDates();
      // handleCountryCode(job.country);
      // addItem(e, job);
      // setShowModal(false);
      saveExperience();
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else if (
      checked === false &&
      job.title &&
      job.company &&
      job.city &&
      job.country_code &&
      startingMonth &&
      startingYear &&
      endingMonth &&
      endingYear
    ) {
      setLoading(true);
      data.append("end_date", `${endingYear}-${endingMonth}-01`);
      // handleDates();
      // handleCountryCode(job.country);
      // addItem(e, job);
      // setShowModal(false);
      saveExperience();
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } else {
      setChecking(true);
    }
  };
  // const handleCountryCode = (country) => {
  //   countryCode = lookup.byCountry(`${country}`).iso2;
  //   data.append("country_code", countryCode);
  // };

  const handleChecked = () => {
    setChecked(!checked);
  };
  const handleJobDescription = (e: any) => {
    setJob((job) => ({ ...job, description: e.target.value }));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showAddJobModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showAddJobModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const arr = [];
  const secondArr: { value: string; label: string }[] = [];

  for (const item of options) {
    arr.push(item);
    if (item.value === startingYear) {
      break;
    }
  }

  for (const item of options) {
    secondArr.push(item);
    if (item.value === endingYear) {
      break;
    }
  }

  const intersection = options.filter((x) => !secondArr.includes(x));

  return (
    <Modal isOpen={showAddJobModal} style={StylesModal}>
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[700px] max-h-[90vh] overflow-y-auto left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <>
          <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
            {t("worker.modal_add_employment")}
          </div>
          <form>
            <div className="flex flex-col">
              <div className="text-sm px-8 pt-4">
                <p className="font-bold">
                  {t(
                    "createprofile.submit.edityourworkexperience.subtitle1.card"
                  )}{" "}
                  *
                </p>
                <input
                  className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded  transition duration-300 ${
                    checking === true && job.title.length === 0
                      ? "border-[#A4211B]"
                      : "hover:border-azulBonico focus:border-azulBonico"
                  }`}
                  placeholder={`${t(
                    "createprofile.employment.placeholder.subtitle1.card.placeholder"
                  )}`}
                  onChange={(e) => {
                    setJob((job) => ({
                      ...job,
                      title: e.target.value,
                    }));
                    setJob((job) => ({
                      ...job,
                      id: unique_id,
                    }));
                  }}
                ></input>
                {job.title.length === 0 && checking === true && (
                  <div className="wrong-container text-sm">
                    <img className="exclamation" src={exclamation} alt="hey" />{" "}
                    {t("worker.modal_add_employment.add_job_title")}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-sm px-8 pt-4">
                <p className="font-bold">
                  {t("worker.modal_add_employment.company")} *
                </p>
                <input
                  className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded  transition duration-300 ${
                    checking === true && job.company.length === 0
                      ? "border-[#A4211B]"
                      : "hover:border-azulBonico focus:border-azulBonico"
                  }`}
                  placeholder={`${t(
                    "createprofile.employment.placeholder.subtitle2.card.placeholder"
                  )}`}
                  onChange={(e) =>
                    setJob((job) => ({
                      ...job,
                      company: e.target.value,
                    }))
                  }
                ></input>
                {job.company.length === 0 && checking === true && (
                  <div className="wrong-container">
                    <img className="exclamation" src={exclamation} alt="hey" />{" "}
                    {t("worker.modal_add_employment.who_you_work_for")}
                  </div>
                )}
              </label>
            </div>
            <div className="flex flex-col">
              <div className="text-sm px-8 pt-4">
                <p className="font-bold">
                  {t("worker.modal_add_employment.location")} *
                </p>
                <div className="flex flex-1 gap-4 items-center">
                  <div className="flex flex-col flex-1">
                    <input
                      className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded transition duration-300 ${
                        checking === true && job.city.length === 0
                          ? "border-[#A4211B]"
                          : "hover:border-azulBonico focus:border-azulBonico"
                      }`}
                      placeholder={`${t(
                        "createprofile.employment.placeholder.subtitle3.card.placeholder"
                      )}`}
                      onChange={(e) =>
                        setJob((job) => ({
                          ...job,
                          city: e.target.value,
                        }))
                      }
                    ></input>
                    {job.city.length === 0 && checking === true && (
                      <div className="wrong-container">
                        <img
                          className="exclamation"
                          src={exclamation}
                          alt="hey"
                        />{" "}
                        {t("worker.modal_add_employment.add_location")}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col flex-1">
                    <CountryPicker
                      job={job}
                      setJob={setJob}
                      checking={checking}
                    />
                    {!job?.country_code && checking === true && (
                      <div className="wrong-container">
                        <img
                          className="exclamation"
                          src={exclamation}
                          alt="hey"
                        />{" "}
                        {t("worker.modal_add_employment.add_country")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-sm px-8 pt-4">
                <p className="font-bold">
                  {t("worker.modal_add_employment.period")} *
                </p>
                <div className="flex flex-col lg:flex-row gap-4 mt-2">
                  <div className="flex flex-col flex-1">
                    <MonthPicker
                      setMonth={setStartingMonth}
                      month={startingMonth}
                      checking={checking}
                      // timespan={"From"}
                    />
                    {(!startingMonth || !startingYear) && checking === true && (
                      <div className="wrong-container text-sm">
                        <img
                          className="exclamation"
                          src={exclamation}
                          alt="hey"
                        />{" "}
                        {t("worker.modal_add_employment.add_working_period")}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col flex-1">
                    {/* <input
                      type="date"
                      className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded  transition duration-300 ${
                        checking === true && !job.date_start
                          ? "border-[#A4211B]"
                          : "hover:border-azulBonico focus:border-azulBonico"
                      }`}
                      onChange={(e) =>
                        setJob((job) => ({
                          ...job,
                          date_start: e.target.value,
                        }))
                      }
                    ></input> */}
                    <YearPicker
                      setYear={setStartingYear}
                      year={startingYear}
                      checking={checking}
                      timespan={"From"}
                      startingYearOptions={intersection}
                    />
                  </div>
                </div>
                {checked === false ? (
                  <>
                    <p className="text-[#6e7081] mt-3">
                      {t("worker.modal_add_employment.through")}
                    </p>
                    <div className="flex flex-col lg:flex-row gap-4 mt-2">
                      <div className="flex flex-col flex-1">
                        <MonthPicker
                          setMonth={setEndingMonth}
                          month={endingMonth}
                          checking={checking}
                          // timespan={"Through"}
                        />
                        {(!endingMonth || !endingYear) && checking === true && (
                          <div className="wrong-container text-sm">
                            <img
                              className="exclamation"
                              src={exclamation}
                              alt="hey"
                            />{" "}
                            {t(
                              "worker.modal_add_employment.add_working_period"
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col flex-1">
                        <YearPicker
                          setYear={setEndingYear}
                          year={endingYear}
                          checking={checking}
                          timespan={"Through"}
                          endingYearOptions={arr}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                <label className="flex items-center cursor-pointer mt-4 w-fit">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChecked}
                    className="mr-3"
                  />
                  {t("worker.modal_add_employment.currently_working")}
                </label>
              </div>
            </div>
            <div className="px-8">
              <p className="text-sm mb-3 pt-4 font-bold">
                {t("worker.modal_add_employment.description")}
              </p>
              <textarea
                className="w-full h-40 p-2 px-4 text-sm border resize-none outline-none border-blue-100 rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
                onChange={(e) => handleJobDescription(e)}
              ></textarea>
            </div>
          </form>
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
            <Button
              className="bg-none text-[0.9rem] font-semibold w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1"
              onClick={() => {
                // handleNoMoreLocal();
                closeModal();
              }}
            >
              {t("worker.modal_add_employment.cancel")}
            </Button>
            <Button
              className="bg-azulBonico border-none font-semibold hover:bg-azulOscuro text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] h-10"
              onClick={(e: any) => handleAddition()}
            >
              {t("worker.modal_add_employment.save")}
            </Button>
          </div>
        </>
      </div>
    </Modal>
  );
}
