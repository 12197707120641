import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface SearchSavedNavbarProps {
  showSavedJobs: boolean;
  setShowSavedJobs: Dispatch<SetStateAction<boolean>>;
  savedJobsCounter: string;
}

const SearchSavedNavbar = ({
  showSavedJobs,
  setShowSavedJobs,
  savedJobsCounter,
}: SearchSavedNavbarProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="border-blue-100">
        <div className="flex px-8 py-6 pb-3 border-b gap-10 text-sm font-bold">
          <p
            className={` ${
              showSavedJobs === false
                ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
                : "hover:text-azulOscuro"
            }  cursor-pointer `}
            onClick={() => setShowSavedJobs(false)}
          >
            {t("worker.findwork.body.search.placeholder")}
          </p>
          <p
            className={` ${
              showSavedJobs === true
                ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
                : "hover:text-azulOscuro"
            } cursor-pointer`}
            onClick={() => setShowSavedJobs(true)}
          >
            {t("worker.leftbar.menu.item1.subitem2.title")} (
            {savedJobsCounter ?? 0})
          </p>
        </div>
      </div>
    </>
  );
};

export default SearchSavedNavbar;
