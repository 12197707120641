import React from "react";

const HandleDayEnding = (numberOfDay) => {
  let ending = "";
  switch (numberOfDay) {
    case "01":
      ending = "st";
      break;
    case "21":
      ending = "st";
      break;
    case "31":
      ending = "st";
      break;
    case "02":
      ending = "nd";
      break;
    case "22":
      ending = "nd";
      break;
    case "03":
      ending = "rd";
      break;
    case "23":
      ending = "rd";
      break;
    default:
      ending = "th";
  }
  return ending;
};

export default HandleDayEnding;
