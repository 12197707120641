import React, { Dispatch, SetStateAction } from "react";

import { ReactComponent as Bell } from "../../assets/img/bell.svg";
import { ReactComponent as Lock } from "../../assets/img/lock-solid.svg";
import { CreditCardIcon, UserCircleIcon } from "@heroicons/react/24/outline";

import { useTranslation } from "react-i18next";
import { WalletIcon } from "@heroicons/react/24/solid";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { GiStairsGoal } from "react-icons/gi";
import { RiBillLine } from "react-icons/ri";
import { MdPermIdentity } from "react-icons/md";
import { userProfileStore } from "src/Routing/useProfileStore";

interface WorkerSettingsNavBarProps {
  decorationBar?: string;
  setDecorationBar: Dispatch<SetStateAction<string>>;
}

export default function WorkerSettingsNavBar({
  setDecorationBar,
  decorationBar,
}: WorkerSettingsNavBarProps) {
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const { t } = useTranslation();
  return (
    <div className="bg-white shadow dark:bg-background dark:border-border px-4 py-2">
      <ul>
        <li
          className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline"
          onClick={() => {
            setDecorationBar("info");
          }}
        >
          <div
            className={` ${
              decorationBar === "info"
                ? "border-l-4 border-azulOscuro"
                : "border-l-4 border-gray-200"
            } flex items-center gap-3 py-2 transition`}
          >
            <UserCircleIcon className="h-4 w-4 ml-4" />

            <p>{t("settings.contact_info.title")}</p>
          </div>
        </li>
        {profileType !== "worker" && (
          <li
            onClick={() => setDecorationBar("wallet")}
            className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline"
          >
            <div
              className={` ${
                decorationBar === "wallet"
                  ? "border-l-4 border-azulOscuro"
                  : "border-l-4 border-gray-200"
              } flex items-center gap-3 py-2 transition pl-4`}
            >
              <WalletIcon className="h-4 w-4 " />
              {/* <img /> */}
              <p>{t("settings.wallet.title")}</p>
            </div>
          </li>
        )}

        <li
          className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline"
          onClick={() => {
            setDecorationBar("password");
          }}
        >
          <div
            className={` ${
              decorationBar === "password"
                ? "border-l-4 border-azulOscuro"
                : "border-l-4 border-gray-200"
            } flex items-center gap-3 py-2 transition`}
          >
            {" "}
            <Lock className="h-4 pl-4 fill-black dark:fill-white" />
            {/* <img src={lock} alt="lock" className="h-3 pl-5"></img> */}
            <p>{t("settings.password_security.title")}</p>
          </div>{" "}
        </li>

        {profileType !== "worker" && (
          <li
            className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline"
            onClick={() => {
              setDecorationBar("subscriptions");
              // navigate("/settings/password");
            }}
          >
            <div
              className={` ${
                decorationBar === "subscriptions"
                  ? "border-l-4 border-azulOscuro"
                  : "border-l-4 border-gray-200"
              } flex items-center gap-3 py-2 transition`}
            >
              {" "}
              <CreditCardIcon className="h-4 pl-4 fill-black dark:fill-white" />
              {/* <img src={lock} alt="lock" className="h-3 pl-5"></img> */}
              {/* <p>{t("settings.subscriptions.title")}</p> */}
              <p>{t("settings.credits.title")}</p>
            </div>{" "}
          </li>
        )}

        <li
          className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline"
          onClick={() => {
            setDecorationBar("billing");
            // navigate("/settings/password");
          }}
        >
          <div
            className={`${
              decorationBar === "billing"
                ? "border-l-4 border-azulOscuro"
                : "border-l-4 border-gray-200"
            } flex items-center gap-3 py-2 transition`}
          >
            <RiBillLine className="h-4 w-4 ml-4 fill-black dark:fill-white" />
            <p className="">{t("employer.client.settings.billings")}</p>
          </div>
        </li>
        <li
          className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline"
          onClick={() => {
            setDecorationBar("plan");
            // navigate("/settings/password");
          }}
        >
          <div
            className={`${
              decorationBar === "plan"
                ? "border-l-4 border-azulOscuro"
                : "border-l-4 border-gray-200"
            } flex items-center gap-3 py-2 transition`}
          >
            <GiStairsGoal className="h-5 ml-4 fill-black dark:fill-white" />
            <p className="">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan"
              )}
            </p>
          </div>
        </li>

        <li
          className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline"
          onClick={() => {
            setDecorationBar("notifications");
          }}
        >
          <div
            className={`${
              decorationBar === "notifications"
                ? "border-l-4 border-azulOscuro "
                : "border-l-4 border-gray-200"
            } flex items-center gap-3 py-2 transition`}
          >
            <Bell className="h-3 pl-5 fill-black dark:fill-white" />
            <p>{t("settings.notification.title")}</p>
          </div>{" "}
        </li>
        <li
          className="client-settings-item hover:bg-[#f8f8f8] dark:hover:bg-neutral-400/30 transition font-medium cursor-pointer hover:underline "
          onClick={() => {
            setDecorationBar("identity");
          }}
        >
          <div
            className={`${
              decorationBar === "identity"
                ? "border-l-4 border-azulOscuro "
                : "border-l-4 border-gray-200"
            } flex items-center gap-3 py-2 transition`}
          >
            <MdPermIdentity className="ml-4 h-5 fill-black dark:fill-white" />

            <p>
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.identityverification"
              )}
            </p>
          </div>{" "}
        </li>
      </ul>
    </div>
  );
}
