import React from "react";
import { Skeleton } from "../skeleton";

const WalletSkeleton = () => {
  return (
    <div className="flex justify-between px-4 py-2 items-center border-b">
      <div className="flex items-center  gap-4 mb-5">
        <Skeleton className="h-4 w-4 rounded-full" />{" "}
        <div className="flex flex-col items-start">
          <Skeleton className="h-4 w-12 rounded-full" />
          <Skeleton className="h-4 w-16 rounded-full mt-2" />
        </div>
      </div>
      <Skeleton className="h-4 w-4 rounded-full" />
    </div>
  );
};

export default WalletSkeleton;
