import React, {
  useRef,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";
import { ModalProps } from "src/Components/Modals/ModalWorkerCard";
import OnlyStars from "src/Components/Rating/OnlyStars";
import { cn } from "src/lib/utils";
import { HireTypes } from "src/types/types";

interface DisplayJobsNewCardProps {
  contract: HireTypes;
  setShowModal: Dispatch<SetStateAction<ModalProps>>;
}

const DisplayJobsNewCard = ({
  contract,
  setShowModal,
}: DisplayJobsNewCardProps) => {
  const [contractStartDate, setContractStartDate] = useState<any>([]);
  const [contractEndDate, setContractEndDate] = useState<any>([]);
  const [formattedStartDate, setFormattedStartDate] = useState<any>("");
  const [formattedEndDate, setFormattedEndDate] = useState<any>("");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [contractEnded, setContractEnded] = useState(false);
  const [hasFeedback, setHasFeedback] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (contract.score_from_employer !== null) {
      setHasFeedback(true);
    }

    if (contract.date_early_end === null) {
      if (contract.date_end < contract.server_date) {
        setContractEnded(true);
      }
    } else if (contract.date_early_end !== null) {
      if (contract.date_early_end < contract.server_date) {
        setContractEnded(true);
      }
    }
  }, [contract]);

  useEffect(() => {
    setContractStartDate(contract.date_start.split("-"));

    if (contract.date_early_end !== null) {
      setContractEndDate(contract.date_early_end.split("-"));
    } else {
      setContractEndDate(contract.date_end.split("-"));
    }
  }, [contract]);

  useEffect(() => {
    setFormattedStartDate(
      `${new Date(
        contractStartDate[0],
        Number(contractStartDate[1]?.replace("0", "") - 1),
        contractStartDate[2]
      )}`
    );
  }, [contractStartDate]);

  useEffect(() => {
    setFormattedEndDate(
      `${new Date(
        contractEndDate[0],
        Number(contractEndDate[1]?.replace("0", "") - 1),
        contractEndDate[2]
      )}`
    );
  }, [contractEndDate]);

  return (
    <div
      className="flex items-center justify-between
      border-y px-8 py-4 dark:border-border relative"
    >
      <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 w-full lg:gap-10 lg:w-[60%] justify-between">
        <div className="flex items-center lg:w-[40%]">
          {/* <div className="mr-10 w-1/3">
            <img
              src={`${BASE_URL_IMG}${contract?.profile_image}`}
              className="h-14 w-14 object-cover rounded-full"
            />
          </div> */}
          <div
          //   className="w-2/3"
          >
            <h3 className="text-sm text-[#333]">
              {t("employer.hires.diplay_hires.title")}
            </h3>
            <h2 className="font-semibold text-sm ">{contract.title}</h2>
            <p className="text-[13px] mt-3 text-slate-500">
              {formattedStartDate?.slice(4, 10)} -{" "}
              {formattedEndDate?.slice(4, 10)}
            </p>
          </div>
        </div>
        <div className="">
          <p className="text-sm text-slate-500">
            {t("employer.hires.diplay_hires.company")}
          </p>
          <p className="text-sm">
            {contract.employer_full_name}
            {/* {contract.first_name} {contract.last_name?.slice(0, 1)}. */}
          </p>
        </div>
        <div>
          <p>
            <span className="font-medium text-sm">
              {contract?.budget?.substring(0, 2) ?? "0"},00€
            </span>{" "}
            /h
          </p>
          <p className="text-sm text-[#333] mb-2">
            {t("employer.hires.diplay_hires.completed")}{" "}
            {formattedEndDate?.slice(4, 10)}
          </p>
          <OnlyStars showHalf={true} activeStar={contract.score_from_worker} />
        </div>
      </div>
      <div className="">
        <div className="border absolute top-4 lg:relative right-4 rounded-full p-1 cursor-pointer">
          <BsThreeDots
            onClick={() => setShowMenu(!showMenu)}
            className="text-slate-500"
          />
          <div ref={ref}>
            <div
              className={`bg-white border-l dark:border-gray-600 transition  -bottom-[25px] border-t z-20 w-4 h-4 rotate-45 absolute dark:bg-input  left-1 ${
                !showMenu ? "scale-0 " : " scale-100 "
              } `}
            ></div>
            <div
              className={` absolute top-[42px] w-[180px] -right-[20px] z-10 dark:bg-input  text-sm rounded border border-gray-200 shadow  dark:border-gray-600 dark:bg-gray-800 bg-white ${
                !showMenu ? "scale-0 transition" : " scale-100  transition"
              }`}
            >
              <div className="py-2.5 text-start text-sm  font-normal">
                <div className="border-b ">
                  <p className="py-1 hover:bg-blancoGrisClaro dark:hover:bg-background px-4 transition">
                    {t("employer.hires.diplay_hires.send_message")}
                  </p>
                  <p className="py-1 hover:bg-blancoGrisClaro dark:hover:bg-background px-4 transition">
                    {t("employer.hires.diplay_hires.view_terms")}
                  </p>
                </div>
                <div className="border-b ">
                  <button
                    // disabled={hasFeedback}
                    // onClick={() => {
                    //   if (!hasFeedback) {
                    //     setClickedWorker(contract);

                    //     setShowModal((prev: any) => ({
                    //       ...prev,
                    //       rating: true,
                    //     }));
                    //   }
                    // }}
                    className={cn(
                      `py-1 hover:bg-blancoGrisClaro dark:hover:bg-background px-4 transition w-full text-left`,
                      contract.score_from_worker
                    )}
                  >
                    {t("employer.hires.diplay_hires.give_feedback")}
                  </button>
                  <p
                    className={cn(
                      `py-1 hover:bg-blancoGrisClaro dark:hover:bg-background px-4 transition`,
                      contractEnded &&
                        "bg-blancoGrisClaro bg-background px-4 py-1 cursor-not-allowed text-gray-400"
                    )}
                  >
                    {t("employer.hires.diplay_hires.end_contract")}
                  </p>
                </div>
                <div className=""></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayJobsNewCard;
