import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";

import {
  BASE_URL,
  CHAT,
  DELETE_CHAT_MESSAGES,
  REPORT_CHAT_MESSAGES,
} from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import DotsLoader from "../Loader/DotsLoader";
import ModalBasic from "../Modals/ModalBasic";
import ModalReportMessage from "../Modals/ModalReportMessage";

import { ReactComponent as Info } from "../../assets/img/ellipsis.svg";

import { ReactComponent as Search } from "../../assets/img/search.svg";
import { ReactComponent as Emoji } from "../../assets/img/emoji.svg";
import { ReactComponent as Clip } from "../../assets/img/clip.svg";
import { AiOutlineArrowDown as GoDown } from "react-icons/ai";

import ModalChat from "../Modals/ModalChat";
import { useTranslation } from "react-i18next";
import { ActiveChats, ChatConversation } from "../../types/types";
import ConversationChat from "./ConversationChat";
import { cn } from "src/lib/utils";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormField, FormItem, Form } from "../ui/Form";
import { IoSend } from "react-icons/io5";
import { messages4JobId, process1WithParamNew } from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";

interface Conversation {
  data: any;
  setData: any;
  messages: ChatConversation[];
  selectedWorkerChat: ActiveChats;
  setRefreshConversation: Dispatch<SetStateAction<boolean>>;
  refreshConversation: boolean;
  showSearchBar: boolean;
  setShowSearchBar: Dispatch<SetStateAction<boolean>>;
  setShowDotsLoader: Dispatch<SetStateAction<boolean>>;
  showDotsLoader: boolean;
  // setReceiverID: Dispatch<SetStateAction<string>>;
  setUseParamsForStartConversation?: any;
}

const Conversation = ({
  data,
  setData,
  selectedWorkerChat,
  setRefreshConversation,
  refreshConversation,
  showSearchBar,
  setShowSearchBar,
  setShowDotsLoader,
  showDotsLoader,
  messages,
}: Conversation) => {
  const [useParamsForStartConversation, setUseParamsForStartConversation] =
    useAtom(process1WithParamNew);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [messageToSend, setMessageToSend] = useState("");
  const [showContent, setShowContent] = useState(true);
  const [searchWord, setSearchWord] = useState<any>([]);
  const [goToBottom, setGoToBottom] = useState(false);
  const [showNewMessageButton, setShowNewMessageButton] = useState(false);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [checkScrolling, setCheckScrolling] = useState(true);
  const [showModal, setShowModal] = useState({
    delete: false,
    report: false,
    block: false,
    chat: false,
  });
  const [showModalDeleteMsg, setShowModalDeleteMsg] = useState(false);
  const [showModalReportMsg, setShowModalReportMsg] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<any>({
    chat_message_id: "",
  });
  const [messagesJobId, setMessagesJobId] = useAtom(messages4JobId);

  const [openEmojis, setOpenEmojis] = useState(false);
  useEffect(() => {
    goToBottomOfConversation();
  }, []);

  const [showPopover, setShowPopover] = useState({ menu: false });
  const [showReportedMsg, setShowReportedMsg] = useState(-1);
  const bottomRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLInputElement>(null);
  const checkinRef = useRef();
  const { t } = useTranslation();
  const dataMsg = new FormData();
  dataMsg.append("_method", "POST");
  dataMsg.append("client_id_in", userID);
  dataMsg.append(
    "client_id_out",
    selectedWorkerChat?.client_id !== ""
      ? selectedWorkerChat?.client_id
      : useParamsForStartConversation
  );
  dataMsg.append(
    "job_id",
    selectedWorkerChat?.job_id !== ""
      ? selectedWorkerChat?.job_id
      : messagesJobId
  );

  const sendMessage = (value: string) => {
    dataMsg.append("msg", value);

    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: dataMsg,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshConversation(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const reportData = new FormData();
  reportData.append("_method", "PUT");
  reportData.append("client_id", userID);
  reportData.append("chat_message_report_id", "0");
  const handleCancelReport = (msg: { chat_message_id: string }) => {
    reportData.append("chat_message_id", msg.chat_message_id);
    fetch(`${BASE_URL}${REPORT_CHAT_MESSAGES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: reportData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") setRefreshConversation(true);
      })
      .catch((err) => console.log(err));
  };
  const deleteData = new FormData();
  deleteData.append("_method", "PUT");
  deleteData.append("client_id", userID);

  const handleDeleteMsg = () => {
    deleteData.append("chat_message_id", selectedMessage?.chat_message_id);
    fetch(`${BASE_URL}${DELETE_CHAT_MESSAGES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: deleteData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          // setReceiverID(selectedWorkerChat.client_id);
          setRefreshConversation(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setRefreshConversation(true);
  }, []);
  // useEffect(() => {
  //   if (refreshConversation) {
  //     bottomRef.current?.scrollIntoView({ behavior: "auto" });
  //   }
  // }, [refreshConversation]);

  useEffect(() => {
    setSearchWord([]);
    searchRef.current!.value = "";
    setShowContent(false);
    // setTimeout(() => {
    //   bottomRef.current?.scrollIntoView({ behavior: "auto" });
    // }, 100);
    setTimeout(() => {
      setShowContent(true);
    }, 150);
  }, [selectedWorkerChat]);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = (e: any) => {
    if (
      e.target.clientHeight + e.target.scrollTop <
      e.target.scrollHeight - 1000
    ) {
      setGoToBottom(true);
    } else {
      setGoToBottom(false);
    }
  };
  const goToBottomOfConversation = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    if (showNewMessageButton) setShowNewMessageButton(false);
  };

  useEffect(() => {
    if (scrollRef.current !== null)
      if (
        scrollRef.current.clientHeight + scrollRef.current.scrollTop >
        scrollRef.current.scrollHeight - 100
      ) {
        setShowNewMessageButton(false);
      }
  }, [checkScrolling]);

  const formSchema = z.object({
    content: z.string().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      content: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      sendMessage(values.content);

      form.reset();
      goToBottomOfConversation();
      setOpenEmojis(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="h-full  lg:h-fit">
        <div className="flex h-[60px]  border border-border  dark:bg-background px-6 py-1 dark:border-border dark:border">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-24">
              <div className="flex flex-col align-center">
                <p className="font-semibold dark:text-whiteish">
                  {selectedWorkerChat?.client_name}
                </p>
                <p className="text-sm font-medium text-[#b2b3bd]">
                  {selectedWorkerChat?.title === "Employer" && (
                    <>{t("worker.messages.employer")}</>
                  )}
                  {selectedWorkerChat?.title !== "Employer" && (
                    <>{t("employer.messages.worker")}</>
                  )}
                </p>
              </div>
              <div
                className={`relative ${showSearchBar === false && "hidden"}`}
              >
                <input
                  className="px-4 pl-10 rounded-full dark:text-whiteish border-azulOscuro hover:border-azulBonico border text-sm w-full h-10 outline-none focus:border-azulOscuro transition duration-300 dark:bg-input"
                  placeholder={`${t(
                    "employer.messages.chat.actionbar.placeholder"
                  )}...`}
                  onChange={(e) => setSearchWord([e.target.value])}
                  ref={searchRef}
                ></input>
                <div className="cursor-pointer w-full">
                  <Search className="h-4 absolute left-[1rem] top-[13px] cursor-pointer dark:fill-whiteish" />
                </div>
              </div>
            </div>
            <div className="relative">
              <div
                onClick={() =>
                  setShowPopover((prev) => ({
                    ...prev,
                    menu: !showPopover.menu,
                  }))
                }
                className={cn(
                  `hover:bg-gray-100 rounded-full p-2`,
                  showPopover.menu && "bg-gray-100"
                )}
              >
                <Info className="h-5 cursor-pointer w-fit " />
              </div>
              <div
                className={`inline-block py-2.5 w-max absolute top-[46px] -right-[36px] z-10 font-light text-sm rounded transition border border-gray-200 shadow bg-white ${
                  showPopover.menu ? "visible scale-100" : "hidden scale-0"
                }`}
              >
                <div
                  className="py-2 px-3 hover:bg-blancoGris text-sm w-full cursor-pointer font-normal transition text-[#333]"
                  onClick={() =>
                    setShowModal((prev) => ({ ...prev, chat: true }))
                  }
                >
                  {t("employer.messages.modal_chat.delete")}
                </div>
                <div
                  className="py-2 px-3 hover:bg-blancoGris text-sm w-full cursor-pointer font-normal transition text-[#333]"
                  onClick={() =>
                    setShowModal((prev) => ({ ...prev, report: true }))
                  }
                >
                  {t("employer.messages.modal_chat.report_user")}
                </div>
                <div
                  className="py-2 px-3 hover:bg-blancoGris text-sm w-full cursor-pointer font-normal transition text-[#333]"
                  onClick={() =>
                    setShowModal((prev) => ({ ...prev, block: true }))
                  }
                >
                  {t("employer.messages.modal_chat.block")}
                </div>
              </div>
              <div
                className={`bg-white border-l-2 border-t-2 z-20 w-4 h-4 rotate-45 absolute -bottom-[18px] left-[9px] ${
                  showPopover.menu ? "visible" : "hidden"
                }`}
              ></div>
            </div>
          </div>
        </div>
        <div className="flex gap-5 px-6 text-sm font-semibold text-azulBonico border-b border-azulBonico py-2 dark:border-border dark:border">
          <p
            className={`${
              showSearchBar === true
                ? "text-azulOscuro underline"
                : " hover:text-azulOscuro hover:underline"
            } cursor-pointer`}
            onClick={() => setShowSearchBar(!showSearchBar)}
          >
            {t("employer.messages.chat.actionbar.placeholder")}
          </p>
        </div>
      </div>

      <div
        className="flex flex-col  mb-auto py-10  lg:px-6 overflow-y-auto jobpost "
        onScroll={(e) => {
          handleScroll(e);
          setCheckScrolling(!checkScrolling);
        }}
        ref={scrollRef}
      >
        {messages?.length > 0 &&
          messages?.map((msg: ChatConversation, i: any, arr: any[]) => {
            return (
              <ConversationChat
                key={msg.chat_message_id}
                msg={msg}
                arr={arr}
                i={i}
                showContent={showContent}
                searchWord={searchWord}
                selectedWorkerChat={selectedWorkerChat}
                setSelectedMessage={setSelectedMessage}
                setShowModalDeleteMsg={setShowModalDeleteMsg}
                setShowModalReportMsg={setShowModalReportMsg}
                handleCancelReport={handleCancelReport}
                setData={setData}
              />
            );
          })}
        <div ref={bottomRef}></div>
        {showDotsLoader === true && (
          <DotsLoader
            height="10"
            width="80"
            radius="8"
            color="#2abeeb"
            show="all"
          />
        )}
      </div>
      {/* <div className="pb-5 w-full  flex justify-between lg:px-6 px-2"> */}
      <Form {...form}>
        <form
          className=" relative py-4 border-t border-border"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormField
            control={form.control}
            name="content"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className=" w-full rounded-full relative  h-12 lg:px-4">
                    <input
                      placeholder={`${t(
                        "worker.messages.conversation_chat.inputPlaceholder"
                      )} ..`}
                      {...field}
                      className="w-full h-full bg-gray-50 rounded-full border shadow dark:text-whiteish ring-azulBonico ring-1 dark:bg-input dark:border-border dark:border focus:ring-2 transition duration-150 text-sm overflow-y-auto pl-5 font-medium pr-14 outline-none"
                    />
                    <div className="absolute right-[40px] top-3">
                      {field.value.length > 0 ? (
                        <div
                          onClick={() => {
                            sendMessage(field.value);
                            form.reset();
                          }}
                        >
                          <IoSend className="h-6 w-6 font-semibold fill-slate-400  top-3 cursor-pointer" />
                        </div>
                      ) : (
                        <>
                          <IoSend
                            onClick={() => setOpenEmojis(true)}
                            className="top-3 h-6 w-6 fill-slate-400 opacity-20  cursor-pointer"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="relative">
            {showNewMessageButton === true && (
              <div className="absolute -top-10 right-96">
                <div
                  className=" flex items-center bg-[#b2b3bd] rounded-full py-1.5 hover:transform hover:scale-[1.05] cursor-pointer transition duration-100 gap-2 px-4"
                  onClick={() => goToBottomOfConversation()}
                >
                  <GoDown className="h-4 fill-white " />
                  <p className="items-center whitespace-nowrap text-xs text-white font-semibold">
                    {t("worker.messages.conversation_chat.newMessage")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </form>
      </Form>

      <div className="relative">
        {goToBottom === true && (
          <div className="absolute -top-20 right-0">
            <GoDown
              className="h-8 w-8 fill-white bg-azulOscuro rounded-full p-2 hover:transform hover:scale-110 cursor-pointer transition "
              onClick={() => goToBottomOfConversation()}
            />
            {/* <button className="px-8 py-1 whitespace-nowrap bg-azulOscuro text-white font-bold rounded-full text-xs">
                Go to bottom
              </button> */}
          </div>
        )}
      </div>
      {showModalDeleteMsg && (
        <ModalBasic
          title={t("employer.messages.modal_chat.message.delete_message")}
          showModal={showModalDeleteMsg}
          setShowModal={setShowModalDeleteMsg}
          content={t(
            "employer.messages.modal_chat.message.delete_message_desc"
          )}
          handleContinue={handleDeleteMsg}
        />
      )}
      {showModalReportMsg && (
        <ModalReportMessage
          showModal={showModalReportMsg}
          setShowModal={setShowModalReportMsg}
          selectedMessage={selectedMessage}
          setRefreshConversation={setRefreshConversation}
        />
      )}
      {showModal.chat && (
        <ModalChat
          clientName={data[0].client_name}
          modal={t("employer.messages.modal_chat.delete")}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showModal.report && (
        <ModalChat
          clientName={data[0].client_name}
          modal={t("employer.messages.modal_chat.report_user")}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showModal.block && (
        <ModalChat
          clientName={data[0].client_name}
          modal={t("employer.messages.modal_chat.block")}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {/* </div> */}
    </>
  );
};

export default Conversation;
