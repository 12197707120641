import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as LogoTwo } from "../../assets/img/logo_2.svg";
import { ReactComponent as Home } from "../../assets/img/house.svg";
import { ReactComponent as Hires } from "../../assets/img/sitemap.svg";
import { ReactComponent as Envelope } from "../../assets/img/envelope.svg";
import { ReactComponent as Bell } from "../../assets/img/bell.svg";
import { ReactComponent as Settings } from "../../assets/img/settings.svg";
import { BiLogOut, BiMoneyWithdraw, BiWallet } from "react-icons/bi";
import { ReactComponent as Circle } from "../../assets/img/circle.svg";
import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useAtom } from "jotai";
import Flexicoin from "src/assets/img/flexicoin-nobg.png";
import FlexihoursClock from "src/assets/img/flexihours.png";
import {
  allNotifications,
  isEmployerOnline,
  jobOfferAtom,
  myCurrentPage,
  notificationsState,
  numberOffersPerPage,
  savedSearchesData,
  selectedTabHeaderEmployer,
  selectedTabHeaderWorker,
  showOpenMenuWorker,
  showSavedJobsWorker,
  widthScreen,
} from "../../Atoms/jotaiAtoms";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  BASE_URL_IMG,
  CHAT,
  LOGOUT,
  NOTIFICATIONS,
  PROFILE_WORKER,
  SETTINGS_USER,
} from "../../Config/api.config";
import { FaCaretDown } from "react-icons/fa";
import { BiHelpCircle } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useThemeStore } from "src/Routing/useThemeStore";
import Select from "react-select";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import {
  useEmployerStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import { cn } from "src/lib/utils";

import { useGetNotifications } from "src/Hooks/useGetNotifications";
import { useWalletSore } from "src/Hooks/useWalletStore";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { useClientCredits } from "src/Hooks/useClientCredits";
import { TbReportSearch } from "react-icons/tb";

const themeOptions = [
  {
    value: "light",
    label: (
      <div className="flex items-center gap-4">
        <SunIcon className="h-4 w-4" />
        Light
      </div>
    ),
  },
  {
    value: "dark",
    label: (
      <div className="flex items-center gap-4">
        <MoonIcon className="h-4 w-4" />
        Dark
      </div>
    ),
  },
];

const VerticalHeaderWorker = () => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const [selectedTab, setSelectedTab] = useAtom(selectedTabHeaderEmployer);

  const [showSavedJobs, setShowSavedJobs] = useAtom(showSavedJobsWorker);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  // const [profileType, setProfileType] = useLocalStorage("profileType", "");
  const [headerWorker, setHeaderWorker] = useAtom(selectedTabHeaderWorker);
  const [savedSearches, setSavedSearches] = useAtom(savedSearchesData);
  const [jobOffers, setJobOffers] = useAtom(jobOfferAtom);
  const [notifications, setNotifications] = useAtom(allNotifications);
  const [unreadMessages, setUnreadMessages] = useState("0");

  const [decorationBar, setDecorationBar] = useLocalStorage(
    "barWorker",
    "info"
  );
  const [headerEmployer, setHeaderEmployer] = useAtom(
    selectedTabHeaderEmployer
  );
  const [offersPerPage, setOffersPerPage] = useAtom(numberOffersPerPage);
  const [currentPageJobsOffer, setCurrentPageJobsOffer] =
    useAtom(myCurrentPage);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loggedStatus, setLoggedStatus] = useAtom(isEmployerOnline);
  const [isOpen, setOpen] = useState(false);
  const [notificationsData, setNotificationsData] = useAtom(notificationsState);

  const [showOptions, setShowOptions] = useAtom(showOpenMenuWorker);
  const [showAccountConfig, setShowAccountConfig] = useState(false);
  const [width, setWidth] = useAtom(widthScreen);
  const navigate = useNavigate();
  const settingsRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useThemeStore((state) => [
    state.themeMode,
    state.setThemeMode,
  ]);
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);

  const [transitionAnimation, setTransitionAnimation] = useLocalStorage(
    "transition_vertical_worker",
    false
  );

  const [showVerticalEntire, setShowVerticalEntire] =
    useLocalStorage("vertical_on");
  const { isNotificationsLoading, notificationData, refetch } =
    useGetNotifications();
  const modal = useWalletSore();

  //Notifications

  const INTERVAL_NOTIFICATIONS = 10000;

  const getUserInfo = () => {
    fetch(`${BASE_URL}workers?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setUserInfo(data.result.data[0]))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (userID) {
      getUserInfo();
    }
  }, [userID]);

  useEffect(() => {
    if (window.location.pathname === "/dashboard") {
      setSelectedTab("dashboard");
    } else if (window.location.pathname === "/applied-jobs") {
      setSelectedTab("applied-jobs");
    } else if (window.location.pathname === "/my-jobs") {
      setSelectedTab("my-jobs");
    } else if (window.location.pathname === "/messages") {
      setSelectedTab("messages");
    } else if (window.location.pathname === "/notifications") {
      setSelectedTab("notifications");
    }
  }, [window]);

  const resizeVerticalHeader = () => {
    setShowVerticalEntire(!showVerticalEntire);
    setTimeout(() => {
      if (showVerticalEntire) {
        setTransitionAnimation(false);
      } else {
        setTransitionAnimation(true);
      }
    }, 50);
  };

  useEffect(() => {
    setNotifications(notificationData?.notifications);
    setNotificationsData(notificationData?.paginate?.[0]);
    setUnreadMessages(notificationData?.messages);
  }, [notificationData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, INTERVAL_NOTIFICATIONS);
    return () => clearInterval(interval);
  }, []);

  //SET ONLINE STATUS FROM MESSAGES TAB OFF
  const data = new FormData();
  const setOnlineStatusOff = () => {
    data.append("_method", "PUT");
    data.append("client_id", userID);
    data.append("status", "LOGOUT");
    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // checkNotifications();
    if (
      window.location.pathname !== "/messages" &&
      loggedStatus === true &&
      userID
    ) {
      setLoggedStatus(false);
      setOnlineStatusOff();
    }
    if (window.location.pathname !== "/jobs-search" && userID) {
      setShowSavedJobs(false);
      setOffersPerPage(10);
      setCurrentPageJobsOffer(1);
    }
  }, []);

  const readNotification = new FormData();
  readNotification.append("_method", "PUT");
  readNotification.append("client_id", userID);

  const checkNotificationAsRead = (id?: string) => {
    if (id) {
      readNotification.append("notification_id_list", id);
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    } else {
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    }
  };

  //CHANGE STATUS CHAT VISIBILITY
  const statusData = new FormData();
  const changeStatus = (props: string) => {
    statusData.append("_method", "PUT");
    statusData.append("client_id", userID);
    statusData.append("status", "ONLINE");
    statusData.append("online_mode", props === "Online" ? "1" : "2");
    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: statusData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const logoutData = new FormData();
  logoutData.append("id", userID);
  const logoutUser = async () => {
    const data = await fetch(`${BASE_URL}${LOGOUT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: logoutData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.token === "disabled") {
          setProfileTypesLocal(null);
          resetAtoms();
          navigate("/login");
          // setTimeout(() => {
          //   setWidth("w-full");
          //   navigate("/login");
          // }, 100);
        }
      })
      .catch((err) => console.log(err));
  };
  const resetAtoms = () => {
    if (profileType === "worker") {
      setHeaderWorker("dashboard");
      setSavedSearches([]);
      setJobOffers([]);
    } else if (profileType === "employer") {
      setJobOffers([]);
      setHeaderEmployer("dashboard");
    }
  };

  const handleLogout = () => {
    // localStorage.clear();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showOptions.profile &&
        settingsRef.current &&
        !settingsRef.current.contains(e.target)
      ) {
        setShowOptions((prev) => ({ ...prev, profile: false }));
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showOptions.profile]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showAccountConfig && ref.current && !ref.current.contains(e.target)) {
        setShowAccountConfig(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showAccountConfig]);

  //To get the inner width of the window, and close the nav bar mobile if it happens to be open

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth > 989) setOpen(false);
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const { clientCreditsData, refetchClientCredits } = useClientCredits();

  useEffect(() => {
    setCreditsNumber(clientCreditsData);
  }, [clientCreditsData]);

  const themeData = new FormData();
  themeData.append("_method", "PUT");
  themeData.append("client_id", userID);

  const updateTheme = (theme: string) => {
    themeData.append("dark_mode", theme === "dark" ? "1" : "0");
    fetch(`${BASE_URL}${SETTINGS_USER}`, {
      method: "POST",
      body: themeData,
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));
  };

  return (
    <div
      ref={ref}
      className={cn(
        "h-screen  bg-white dark:bg-background border-r border-border   pt-[20px] shadow transition cursor-pointer "
      )}
    >
      <div
        className={cn(
          "flex flex-col justify-between h-full w-64  pb-12 transition-width duration-200 ease-in-out",
          showVerticalEntire && "w-14 "
        )}
      >
        <div className="flex flex-col text-base font-semibold tracking-wide px-2 relative">
          {!transitionAnimation ? (
            <LogoTwo
              className="h-10 cursor-pointer"
              onClick={() => {
                if (window.location.pathname !== "/dashboard") {
                  setSelectedTab("dashboard");
                  setShowOptions((prev) => ({
                    ...prev,
                    findWork: true,
                    jobs: false,
                    profile: false,
                  }));
                  if (profileType === "employer") {
                    navigate("/dashboard");
                  } else {
                    navigate("/dashboard-worker");
                  }
                } else {
                  if (!loggedInUserUid) {
                    navigate("/");
                  }
                }
              }}
            />
          ) : (
            <div
              onClick={() => {
                if (window.location.pathname !== "/dashboard") {
                  setSelectedTab("dashboard");
                  setShowOptions((prev) => ({
                    ...prev,
                    findWork: true,
                    jobs: false,
                    profile: false,
                  }));
                  if (profileType === "employer") {
                    navigate("/dashboard");
                  } else {
                    navigate("/dashboard-worker");
                  }
                } else {
                  if (!loggedInUserUid) {
                    navigate("/");
                  }
                }
              }}
              className={cn("h-10 justify-center items-center flex")}
            >
              <img src={FlexihoursClock} className="h-6 w-6 cursor-pointer" />
            </div>
          )}

          <div
            onClick={() => resizeVerticalHeader()}
            className="h-7 w-7 rounded hover:bg-gray-200 items-center flex justify-center absolute right-2 top-16 transition"
          >
            {!transitionAnimation ? (
              <BsChevronDoubleLeft className="h-4 w-4 text-gray-500" />
            ) : (
              <BsChevronDoubleRight className="h-4 w-4 text-gray-500" />
            )}
          </div>
          <div className="flex flex-col mt-14  text-slate-600 dark:text-whiteish">
            <div
              className={cn(
                `flex items-center gap-5 justify-between transition h-10 py-2 px-2 cursor-pointer mb-[1px] dark:text-whiteish  ${
                  (selectedTab === "dashboard" &&
                    window.location.pathname === "/dashboard") ||
                  (selectedTab === "jobs-search" &&
                    window.location.pathname === "/jobs-search") ||
                  (selectedTab === "profile" &&
                    window.location.pathname === "/profile")
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200 rounded dark:hover:bg-neutral-400/30 "
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                if (!transitionAnimation) {
                  setShowOptions((prev) => ({
                    ...prev,
                    findWork: !showOptions.findWork,
                  }));
                } else {
                  if (profileType === "employer") {
                    navigate("/dashboard");
                  } else {
                    navigate("/dashboard-worker");
                  }
                }
              }}
            >
              <div className="flex items-center gap-5 ">
                <Home
                  className={`h-4 w-4 dark:fill-whiteish ${
                    selectedTab === "dashboard" ||
                    selectedTab === "jobs-search" ||
                    selectedTab === "profile"
                      ? "fill-azulOscuro"
                      : "fill-slate-500"
                  } `}
                />
                {!transitionAnimation && (
                  <p className="text-[14px]">
                    {t("worker.leftbar.menu.item1.subitem1.title")}
                  </p>
                )}
              </div>
              {!transitionAnimation && (
                <ArrowDown
                  className={`h-3  ${
                    showOptions.findWork
                      ? "animate-toArrowUp rotate-180 fill-azulOscuro"
                      : "animate-toArrowDown fill-slate-500"
                  }`}
                />
              )}
            </div>
            {!transitionAnimation && showOptions.findWork && (
              <div className="  text-sm">
                <p
                  className={`mb-0.5 ${
                    selectedTab === "dashboard" &&
                    "bg-slate-200 dark:bg-neutral-400/30 rounded "
                  } pl-11 py-1 cursor-pointer  hover:bg-slate-200 dark:hover:bg-neutral-400/30 transition rounded`}
                  onClick={() => {
                    setSelectedTab("dashboard");
                    setShowOptions((prev) => ({
                      ...prev,
                      jobs: false,
                      profile: false,
                    }));
                    if (profileType === "employer") {
                      navigate("/dashboard");
                    } else {
                      navigate("/dashboard-worker");
                    }
                  }}
                >
                  {t("worker.leftbar.menu.item1.subitem1.title")}
                </p>
                <p
                  className={`mb-0.5 ${
                    selectedTab === "jobs-search" &&
                    "bg-slate-200 dark:bg-neutral-400/30 rounded"
                  } pl-11 py-1 cursor-pointer hover:bg-slate-200 transition hover:rounded dark:hover:bg-neutral-400/30`}
                  onClick={() => {
                    setShowSavedJobs(true);
                    setSelectedTab("jobs-search");
                    setShowOptions((prev) => ({
                      ...prev,
                      jobs: false,
                      profile: false,
                    }));
                    navigate("/jobs-search");
                  }}
                >
                  {t("worker.leftbar.menu.item1.subitem2.title")}
                </p>
                <p
                  className={`mb-0.5 ${
                    selectedTab === "profile" &&
                    "bg-slate-200 rounded dark:bg-neutral-400/30"
                  } pl-11 py-1 cursor-pointer hover:bg-slate-200 transition hover:rounded dark:hover:bg-neutral-400/30`}
                  onClick={() => {
                    setSelectedTab("profile");
                    setShowOptions((prev) => ({
                      ...prev,
                      jobs: false,
                      profile: false,
                    }));
                    navigate("/profile");
                  }}
                >
                  {t("worker.leftbar.menu.item1.subitem3.title")}
                </p>
              </div>
            )}
            <div
              className={cn(
                `flex items-center gap-5 justify-between cursor-pointer h-10 py-2 px-2 mb-[1px] dark:text-whiteish ${
                  selectedTab === "my-jobs" || selectedTab === "applied-jobs"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200 transition hover:rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                if (!transitionAnimation) {
                  setShowOptions((prev) => ({
                    ...prev,
                    jobs: !showOptions.jobs,
                  }));
                  setSelectedTab("my-jobs");
                  navigate("/my-jobs");
                } else {
                  setSelectedTab("my-jobs");
                  navigate("/my-jobs");
                }
              }}
            >
              <div className="flex items-center gap-5 hover:bg-slate-200 transition dark:hover:bg-neutral-400/30 ">
                <Hires
                  className={`h-4 dark:fill-whiteish ${
                    selectedTab === "my-jobs" || selectedTab === "applied-jobs"
                      ? "fill-azulOscuro"
                      : "fill-slate-500"
                  } `}
                />
                {!transitionAnimation && (
                  <p className="text-[14px]">
                    {t("worker.leftbar.menu.item2.subitem1.title")}
                  </p>
                )}
              </div>
              {!transitionAnimation && (
                <ArrowDown
                  className={`h-3  ${
                    showOptions.jobs
                      ? "animate-toArrowUp rotate-180 fill-azulOscuro"
                      : "animate-toArrowDown fill-slate-500"
                  } `}
                />
              )}
            </div>
            {!transitionAnimation && showOptions.jobs && (
              <div className=" text-sm">
                <p
                  className={`mb-0.5 ${
                    selectedTab === "my-jobs" &&
                    "bg-slate-200 rounded dark:bg-neutral-400/30"
                  } pl-11 py-1 cursor-pointer hover:bg-slate-200 transition hover:rounded dark:hover:bg-neutral-400/30`}
                  onClick={() => {
                    setSelectedTab("my-jobs");
                    navigate("/my-jobs");
                    setShowOptions((prev) => ({
                      ...prev,
                      findWork: false,
                      profile: false,
                    }));
                  }}
                >
                  {t("worker.leftbar.menu.item2.subitem1.title")}
                </p>
                <p
                  className={`mb-0.5 ${
                    selectedTab === "applied-jobs" &&
                    "bg-slate-200 rounded dark:bg-neutral-400/30"
                  } pl-11 py-1 cursor-pointer hover:bg-slate-200 transition hover:rounded dark:hover:bg-neutral-400/30`}
                  onClick={() => {
                    setSelectedTab("applied-jobs");
                    setShowOptions((prev) => ({
                      ...prev,
                      findWork: false,
                      profile: false,
                    }));
                    navigate("/applied-jobs");
                  }}
                >
                  {t("worker.leftbar.menu.item2.title")}
                </p>
              </div>
            )}
            <div
              className={cn(
                `flex items-center gap-5 cursor-pointer h-10 py-2 px-2 mb-[1px] dark:text-whiteish ${
                  selectedTab === "messages"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200  hover:rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                setSelectedTab("messages");
                setShowOptions((prev) => ({
                  ...prev,
                  findWork: false,
                  jobs: false,
                  profile: false,
                }));
                navigate("/messages");
              }}
            >
              <Envelope
                className={`h-4 dark:fill-whiteish ${
                  selectedTab === "messages"
                    ? "fill-azulOscuro"
                    : "fill-slate-500"
                } `}
              />
              {!transitionAnimation && (
                <p className="text-[14px]">
                  {t("employer.leftbar.menu.item3.title")}
                </p>
              )}
              {unreadMessages !== "0" && (
                <p className="flex mt-[1px] justify-center text-sm text-white font-bold bg-[#b91919] w-[22px] h-fit rounded-full border border-[#b91919]">
                  {unreadMessages}
                </p>
              )}
            </div>

            <div
              className={cn(
                `flex items-center gap-5  cursor-pointer py-2 h-10 px-2 mb-[1px] dark:text-whiteish ${
                  window.location.pathname === "/notifications"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200 hover:rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                setSelectedTab("notifications");
                checkNotificationAsRead();
                if (
                  showOptions.findWork ||
                  showOptions.jobs ||
                  showOptions.profile
                ) {
                  setShowOptions((prev) => ({
                    ...prev,
                    findWork: false,
                    jobs: false,
                    profile: false,
                  }));
                }
                navigate("/notifications");
              }}
            >
              <Bell
                className={`h-4 dark:fill-whiteish ${
                  notificationsData?.unread_notifications !== "0" &&
                  "animate-bellAanimation"
                } ${
                  selectedTab === "notifications"
                    ? "fill-azulOscuro"
                    : "fill-slate-500"
                }`}
              />
              {!transitionAnimation && (
                <div className="relative">
                  <p className="text-[14px]">
                    {t("worker.leftbar.menu.item5.title")}
                  </p>
                  {notificationsData?.unread_notifications !== "0" && (
                    <Circle
                      className="h-2 absolute top-1 -left-6"
                      fill={"#b91919"}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              onClick={() => navigate("/transactions")}
              className={cn(
                `flex items-center gap-5  cursor-pointer py-2 h-10 px-2 mb-[1px] dark:text-whiteish ${
                  window.location.pathname === "/transactions"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200 hover:rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
            >
              <BiMoneyWithdraw className="h-4 w-4" />
              {!transitionAnimation && (
                <div className="relative">
                  <p className="text-[14px] -ml-[2px]">Transactions</p>
                </div>
              )}{" "}
            </div>
          </div>
        </div>
        <div>
          <div className="pb-8 relative px-2" ref={settingsRef}>
            {showAccountConfig && (
              <div className="mx-2">
                <div className="absolute bottom-1 left-0 mx-2 w-60 bg-white shadow dark:bg-input dark:text-whiteish shadow-gray-400 rounded py-2 px-2">
                  <div className="flex flex-col">
                    <div className="w-full flex flex-col items-center justify-center pb-6 pt-2">
                      {userInfo.profile_image && (
                        <img
                          onClick={() => navigate("/profile")}
                          src={`${BASE_URL_IMG}${userInfo.profile_image}`}
                          alt="profile"
                          className="h-[105px]  w-[105px] object-cover rounded-full cursor-pointer hover:border transition duration-300 ease-in-out"
                        />
                      )}
                      {!userInfo.profile_image && (
                        <p className="border-2 font-bold rounded-full border-black text-black dark:text-whiteish h-[55px] w-[55px] p-1 flex mr-2 justify-center items-center">
                          {userInfo?.first_name?.slice(0, 1)}
                          {userInfo?.last_name?.slice(0, 1)}
                        </p>
                      )}
                      <p className="text-base font-bold py-2 cursor-default">
                        {userInfo?.first_name} {userInfo?.last_name}
                      </p>
                    </div>
                    <div className="flex flex-col space-y-2">
                      {/* {userInfo?.company_id !== null && (
                        <div
                          onClick={() => {
                            if (modal.isOpen) {
                              modal.onClose();
                            } else {
                              modal.onOpen();
                            }
                          }}
                          className="px-2 py-2 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-neutral-400/30 flex items-center gap-3 transition duration-200 ease-out hover:rounded-lg"
                        >
                          <BiWallet className="h-4 fill-black dark:fill-whiteish" />

                          <p className="text-sm ">
                            {t("settings.wallet.title")}
                          </p>
                        </div>
                      )} */}
                      <div
                        onClick={() => navigate("/contact")}
                        className="px-2 py-2 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-background flex items-center gap-3 transition-transform duration-200 ease-out hover:rounded-lg"
                      >
                        <BiHelpCircle className="h-4 fill-black dark:fill-whiteish" />
                        <p className="text-sm">
                          {t("worker.leftbar.usermenu.link1")}
                        </p>
                      </div>
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-background flex items-center gap-3 transition-transform duration-200 ease-out hover:rounded-lg"
                        onClick={() => {
                          setDecorationBar("info");
                          navigate("/settings");
                        }}
                      >
                        <Settings className="h-4 fill-black dark:fill-whiteish" />
                        <p className="text-sm">
                          {t("worker.leftbar.usermenu.link2")}
                        </p>
                      </div>
                      {/* <div className="flex items-center justify-between">
                        <div className="flex items-center px-2 py-1 gap-3">
                          {theme === "light" ? (
                            <SunIcon className="h-4 fill-black dark:fill-whiteish" />
                          ) : (
                            <MoonIcon className="h-4 fill-black dark:fill-whiteish" />
                          )}
                          <p className="text-sm ">{t("theme.title")}</p>
                        </div>
                        <div className="grow ml-10">
                          <Select
                            className="text-xs dark:text-[#333]"
                            isSearchable={false}
                            options={themeOptions}
                            controlShouldRenderValue={true}
                            hideSelectedOptions={true}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            value={{
                              value: theme ?? "",
                              label:
                                theme !== "light"
                                  ? themeOptions[1].label
                                  : themeOptions[0].label,
                            }}
                            onChange={(e) => {
                              setTheme(e!.value);
                              updateTheme(e!.value);
                            }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="border-t my-2" />
                    <div
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-background flex items-center gap-3  transition-transform duration-200 ease-out hover:rounded-lg"
                      onClick={() => {
                        setOnlineStatusOff();
                        logoutUser();
                      }}
                    >
                      <BiLogOut className="h-4  dark:fill-whiteish" />
                      <p className="text-[15px]">
                        {t("worker.leftbar.usermenu.link3")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={cn(
              `flex items-center justify-between dark:hover:bg-input rounded-lg py-2 transition mt-2 mx-2 px-2 cursor-pointer hover:bg-slate-50 ${
                showAccountConfig ? "bg-slate-50 dark:bg-input" : ""
              }`,
              transitionAnimation && "mx-0 px-0"
            )}
            onClick={() => {
              setShowAccountConfig(!showAccountConfig);
            }}
          >
            <div className="flex  items-center justify-center   cursor-pointer">
              {!transitionAnimation && !userInfo.profile_image && (
                <p className="border-2 font-bold rounded-full border-black dark:text-neutral-400  dark:border-neutral-400 text-black h-[44px] w-[44px] p-1 flex mr-2 justify-center items-center">
                  {userInfo?.first_name?.slice(0, 1)}
                  {userInfo?.last_name?.slice(0, 1)}
                </p>
              )}
              <div className="flex flex-col items-start ml-2">
                {!transitionAnimation && (
                  <p className="text-base font-bold py-2   cursor-pointer dark:text-whiteish">
                    {userInfo?.first_name
                      ?.split(" ")[0]
                      .charAt(0)
                      .toUpperCase() +
                      userInfo?.first_name?.split(" ")[0].slice(1)}{" "}
                    {userInfo?.last_name?.slice(0, 1)}.
                  </p>
                )}
                {/* {userInfo?.company_id !== null && (
                  <div
                    className={cn(
                      "flex items-center gap-2 cursor-pointer hover:bg-gray-100  dark:hover:bg-transparent relative",
                      transitionAnimation && "hover:bg-transparent"
                    )}
                  >
                    <p className="text-sm flex items-center gap-1 ">
                      <span
                        className={cn(
                          "font-bold  dark:text-whiteish",
                          transitionAnimation && "text-xs"
                        )}
                      >
                        {creditsNumber}
                      </span>
                      <div
                        className={cn(
                          "bg-transparent h-4 w-5 flex items-center justify-center ml-1 rounded-full",
                          transitionAnimation &&
                            "justify-center absolute left-7"
                        )}
                      >
                        <img
                          src={Flexicoin}
                          className={cn(
                            "h-5 w-5 object-contain ",
                            transitionAnimation && "h-4 w-4"
                          )}
                        />
                      </div>
                    </p>
                  </div>
                )} */}
              </div>
            </div>
            {!transitionAnimation && (
              <div>
                <FaCaretDown
                  className={`h-4 fill-black dark:fill-whiteish transition-transform duration-150 ease-in-out ${
                    showAccountConfig ? "rotate-180 " : "rotate-0"
                  }`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalHeaderWorker;
