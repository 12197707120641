import React, { useState, useRef, useEffect } from "react";
import { BASE_URL, SCORE_CONNECTION } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import RatingStars from "../Rating/RatingStars";
import RequestErrorModal from "./RequestErrorModal";
import Modal from "react-modal";
import { HireTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { selectedFeedbackSection } from "src/Atoms/jotaiAtoms";
import { Button } from "../ui/Button";
import { AiOutlineClose } from "react-icons/ai";
import { cn } from "src/lib/utils";
import { StylesModal } from "src/Utils/stylesModal";
import { userProfileStore } from "src/Routing/useProfileStore";

interface ModalSendRatingProps {
  showModal: { rating: boolean };
  setShowModal: any;
  hire?: HireTypes;
  setRefreshData: any;
  employer?: any;
  setSelectedSection?: any;
}

const ModalSendRating = ({
  showModal,
  setShowModal,
  hire,
  setRefreshData,
  employer,
  setSelectedSection,
}: ModalSendRatingProps) => {
  const [transition, setTransition] = useState(false);
  const [activeStar, setActiveStar] = useState<any>({
    cooperation: 0,
    skills: 0,
    availability: 0,
    requirements: 0,
    rate: 0,
    communication: 0,
  });
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [review, setReview] = useState("");
  const [totalScore, setTotalScore] = useState<any>(0);
  const [dividend, setDividend] = useState<any>(0);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const [warning, setWarning] = useState(false);
  const [selectedFeedSection, setSelectedFeedSection] = useAtom(
    selectedFeedbackSection
  );
  const ref = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const activeStarCooperation: any = activeStar.cooperation !== -1 && 1;
    const activeStarSkills = activeStar.skills !== -1 && 1;
    setTotalScore(
      (activeStar.cooperation !== -1 && activeStar.cooperation) +
        (activeStar.skills !== -1 && activeStar.skills) +
        (activeStar.availability !== -1 && activeStar.availability) +
        (activeStar.requirements !== -1 && activeStar.requirements) +
        (activeStar.rate !== -1 && activeStar.rate) +
        (activeStar.communication !== -1 && activeStar.communication)
    );
    setDividend(
      activeStarCooperation +
        activeStarSkills +
        (activeStar.availability !== -1 && 1) +
        (activeStar.requirements !== -1 && 1) +
        (activeStar.rate !== -1 && 1) +
        (activeStar.communication !== -1 && 1)
    );
  }, [activeStar]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.rating && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.rating]);
  const sendData = new FormData();
  sendData.append("_method", "PUT");
  sendData.append("client_id", userID);
  const sendRating = () => {
    sendData.append(
      "connection_id",
      hire ? hire.connection_id : employer?.connection_id
    );
    sendData.append(
      "score",
      (Math.round((totalScore / dividend) * 100) / 100).toString()
    );
    sendData.append("review", review);
    fetch(`${BASE_URL}${SCORE_CONNECTION}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: sendData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          closeModal();
          if (
            window.location.pathname === "/my-hires" ||
            window.location.pathname === "/my-jobs"
          ) {
            setSelectedFeedSection("realizadas");
            setSelectedSection("feedback");
          }
        } else {
          setWarning(data.result.error_msg);
          setTimeout(() => {
            setWarning(false);
          }, 3800);
        }
      })
      .catch((err) => console.log(err));
  };
  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, rating: false }));
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);

  return (
    <Modal
      closeTimeoutMS={200}
      contentLabel="modal"
      isOpen={showModal.rating}
      onRequestClose={() => closeModal()}
      style={StylesModal}
    >
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <RequestErrorModal warning={warning} />

        <div className="mx-8 mt-6 flex flex-col">
          <div className="flex items-start justify-between border-b py-2 mb-6">
            <h1 className="font-medium text-xl tracking-wide ">
              {t("modal.send_rating.title")}
            </h1>
            <Button
              className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
              onClick={() => closeModal()}
            >
              <AiOutlineClose className="h-4 w-4" />
            </Button>
          </div>
          {!employer ? (
            <>
              {i18n.language === "en" ? (
                <p className="font-medium">
                  How do you qualify {hire?.first_name}?
                </p>
              ) : (
                <p>¿Cómo calificas a {hire?.first_name}?</p>
              )}
            </>
          ) : (
            <>
              {i18n.language === "en" ? (
                <p className="font-medium">
                  How do you qualify {employer.employer_full_name}
                </p>
              ) : (
                <p className="font-medium">
                  ¿Cómo calificas a {employer.employer_full_name}?
                </p>
              )}
            </>
          )}

          <div className="my-4 mb-6 flex flex-col gap-2">
            <div className="flex gap-2 items-start mb-1">
              <RatingStars
                score={
                  hire ? hire.score_from_employer : employer.score_from_worker
                }
                activeStar={activeStar.cooperation}
                setActiveStar={setActiveStar}
                area={"cooperation"}
                evaluation={false}
                showModal={showModal}
                // score={"dont"}
              />
              <p className="text-gray-500 text-sm font-medium">
                {profileType === "employer"
                  ? "Adherence to Schedule"
                  : "Cooperation"}
              </p>
            </div>
            <div className="flex gap-2 items-start mb-1">
              <RatingStars
                showModal={showModal}
                score={
                  hire ? hire.score_from_employer : employer.score_from_worker
                }
                activeStar={activeStar.skills}
                setActiveStar={setActiveStar}
                area={"skills"}
                evaluation={false}
              />
              <p className="text-gray-500 text-sm font-medium">
                {t("modal.send_rating.skills")}
              </p>
            </div>
            <div className="flex gap-2 items-start mb-1">
              <RatingStars
                showModal={showModal}
                score={
                  hire ? hire.score_from_employer : employer.score_from_worker
                }
                activeStar={activeStar.availability}
                setActiveStar={setActiveStar}
                area={"availability"}
                evaluation={false}
              />
              <p className="text-gray-500 text-sm font-medium">
                {t("modal.send_rating.availability")}
              </p>
            </div>
            <div className="flex gap-2 items-start mb-1">
              <RatingStars
                showModal={showModal}
                score={
                  hire ? hire.score_from_employer : employer.score_from_worker
                }
                activeStar={activeStar.requirements}
                setActiveStar={setActiveStar}
                area={"requirements"}
                evaluation={false}
              />
              <p className="text-gray-500 text-sm font-medium">
                {profileType === "employer"
                  ? t("modal.send_rating.quality_work")
                  : t("modal.send_rating.quality_requirements")}
              </p>
            </div>
            <div className="flex gap-2 items-start mb-1">
              <RatingStars
                showModal={showModal}
                score={
                  hire ? hire.score_from_employer : employer.score_from_worker
                }
                activeStar={activeStar.rate}
                setActiveStar={setActiveStar}
                area={"rate"}
                evaluation={false}
              />
              <p className="text-gray-500 text-sm font-medium">
                {t("modal.send_rating.reasonable_ratings")}
              </p>
            </div>
            <div className="flex gap-2 items-start mb-1">
              <RatingStars
                showModal={showModal}
                score={
                  hire ? hire.score_from_employer : employer.score_from_worker
                }
                activeStar={activeStar.communication}
                setActiveStar={setActiveStar}
                area={"communication"}
                evaluation={false}
              />
              <p className="text-gray-500 text-sm font-medium">
                {t("modal.send_rating.communication")}
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <p className=" font-medium">
                {t("modal.send_rating.total_score")}:{" "}
                {dividend !== 0
                  ? Math.round((totalScore / dividend) * 100) / 100
                  : 0}
              </p>
              <p className=" font-medium">-</p>
              <div className="  whitespace-nowrap">
                {1 <= totalScore / dividend && totalScore / dividend < 2 && (
                  <p className="text-[#E22024]  font-medium ">
                    {t("modal.send_rating.poorly_done")}
                  </p>
                )}
                {2 <= totalScore / dividend && totalScore / dividend < 3 && (
                  <p className="text-[#F25B2A]  font-medium ">
                    {t("modal.send_rating.not_good_enough")}
                  </p>
                )}
                {3 <= totalScore / dividend && totalScore / dividend < 4 && (
                  <p className="text-[#FDB041]  font-medium ">
                    {t("modal.send_rating.good_enough")}
                  </p>
                )}
                {4 <= totalScore / dividend && totalScore / dividend < 4.5 && (
                  <p className="text-[#91CB63]  font-medium ">
                    {t("modal.send_rating.very_good")}
                  </p>
                )}
                {totalScore / dividend >= 4.5 && (
                  <p className="text-[#019545]  font-medium ">
                    {t("modal.send_rating.excellent_done")}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className=" font-medium mb-2 text-sm">
              {t("modal.send_rating.tell_thoughts")}{" "}
              {hire ? hire.first_name : employer.employer_full_name}
            </p>
            <textarea
              className="border pl-2 pt-1  border-border rounded outline-none focus:ring-2 h-[70px] max-h-[70px] w-full  px-2 py-1 text-sm"
              onChange={(e) => setReview(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="flex w-full h-full items-end p-3 gap-2 px-8 mt-2 justify-center">
          <button
            type="button"
            disabled={
              activeStar.cooperation !== -1 ||
              activeStar.skills !== -1 ||
              activeStar.availability !== -1 ||
              activeStar.requirements !== -1 ||
              activeStar.rate !== -1 ||
              activeStar.communication !== -1
                ? false
                : true
            }
            className={` ${
              activeStar.cooperation !== -1 ||
              activeStar.skills !== -1 ||
              activeStar.availability !== -1 ||
              activeStar.requirements !== -1 ||
              activeStar.rate !== -1 ||
              activeStar.communication !== -1
                ? "bg-azulBonico hover:bg-azulOscuro"
                : "cursor-not-allowed bg-blancoGris text-white"
            } border-none font-semifont-medium flex-1 rounded-[20px] text-sm  transition duration-300 h-8 text-white cursor-pointer`}
            onClick={(e) => {
              sendRating();
            }}
          >
            {t("modal.send_rating.send")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSendRating;
