import React, { useEffect, useRef, useState } from "react";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import { ReactComponent as Banner } from "src/assets/img/allJobsEmployerBanner.svg";
import { ReactComponent as Search } from "src/assets/img/search.svg";
import { useNavigate } from "react-router-dom";
import GoBackButton from "src/Components/ui/GoBack";
import { useAtom } from "jotai";
import {
  featuredWorkers,
  paginationFindWorkers,
  showEnterpriseSaved,
  showWorkerSaved,
  sortType,
} from "src/Atoms/jotaiAtoms";
import Switch from "react-switch";
import {
  ArrowUpIcon,
  ArrowsUpDownIcon,
  CheckBadgeIcon,
  FireIcon,
} from "@heroicons/react/24/solid";
import { cn } from "src/lib/utils";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import FilterWork from "./FilterWork";
import { companies } from "src/Utils/content/Companies";
import { ImLocation } from "react-icons/im";
import { useTranslation } from "react-i18next";
type Order = "asc" | "desc";
type SortOption = { enabled: boolean; order: Order };

type SortBy = {
  featured: SortOption;
  updated: SortOption;
  budget: SortOption;
};

const FindWork = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedFeatured, setCheckedFeatured] = useState(false);
  const [workersFeatured, setWorkersFeatured] = useAtom(featuredWorkers);
  const modalRef = useRef<HTMLDivElement>(null);
  const [openSort, setOpenSort] = useState(false);
  const [sortOptionSelected, setSortOptionSelected] = useAtom(sortType);
  const [showAllEnterprises, setshowAllEnterprises] =
    useAtom(showEnterpriseSaved);

  const [headerVisible, setHeaderVisible] = useState(true);

  const [sortBy, setSortBy] = useState<SortBy>({
    featured: {
      enabled: true,
      order: "desc",
    },
    updated: {
      enabled: false,
      order: "desc",
    },
    budget: {
      enabled: false,
      order: "desc",
    },
  });

  useOnClickOutside(modalRef, () => {
    setOpenSort(false);
  });

  useEffect(() => {
    if (sortBy.featured.enabled) {
      setSortOptionSelected(() => ({
        enabled: sortBy.featured.enabled,
        order: sortBy.featured.order,
        name: "date_featured",
      }));
    } else if (sortBy.updated.enabled) {
      setSortOptionSelected(() => ({
        enabled: sortBy.updated.enabled,
        order: sortBy.updated.order,
        name: "date_updated",
      }));
    } else if (sortBy.budget.enabled) {
      setSortOptionSelected(() => ({
        enabled: sortBy.budget.enabled,
        order: sortBy.budget.order,
        name: "budget",
      }));
    }
  }, [sortBy]);

  const handleToggleSortUpdated = () => {
    if (sortBy.budget.enabled) {
      setSortBy((prev) => ({
        ...prev,
        budget: { enabled: false, order: sortBy.budget.order },
      }));
    }
    if (sortBy.featured.enabled) {
      setSortBy((prev) => ({
        ...prev,
        featured: { enabled: false, order: sortBy.featured.order },
      }));
    }
    setSortBy((prev) => ({
      ...prev,
      updated: {
        enabled: true,
        order: sortBy.updated.order === "asc" ? "desc" : "asc",
      },
    }));
  };

  const handleToggleSortFeatured = () => {
    if (sortBy.budget.enabled) {
      setSortBy((prev) => ({
        ...prev,
        budget: { enabled: false, order: sortBy.budget.order },
      }));
    }
    if (sortBy.updated.enabled) {
      setSortBy((prev) => ({
        ...prev,
        updated: { enabled: false, order: sortBy.updated.order },
      }));
    }
    setSortBy((prev) => ({
      ...prev,
      featured: {
        enabled: true,
        order: sortBy.featured.order === "asc" ? "desc" : "asc",
      },
    }));
  };
  const handleToggleSortBudget = () => {
    if (sortBy.featured.enabled) {
      setSortBy((prev) => ({
        ...prev,
        featured: { enabled: false, order: sortBy.featured.order },
      }));
    }
    if (sortBy.updated.enabled) {
      setSortBy((prev) => ({
        ...prev,
        updated: { enabled: false, order: sortBy.updated.order },
      }));
    }
    setSortBy((prev) => ({
      ...prev,
      budget: {
        enabled: true,
        order: sortBy.budget.order === "asc" ? "desc" : "asc",
      },
    }));
  };

  return (
    <>
      <HeaderBanner
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            Busca tu trabajo ideal
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline my-8 mx-4"
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>
      <div className="bg-white rounded shadow dark:bg-background dark:text-whiteish ">
        <div className="flex flex-col lg:flex-row w-full px-8 py-8 justify-between border-b border-border">
          <div className="relative w-full lg:w-2/3">
            <input
              className="px-2 border-slate-200 dark:bg-input dark:text-whiteish dark:border-border hover:border-blue-100 border text-sm w-full rounded h-10 outline-none focus:border-blue-100  transition duration-300"
              placeholder="Buscar empresas"
              onChange={(e) => console.log(e)}
            />
            <Search className="h-5 absolute fill-slate-500 right-[0.5rem] top-[10px] cursor-pointer dark:fill-whiteish " />
          </div>
          <div className="flex items-center gap-4 mt-4 lg:mt-0">
            <Switch
              checked={checkedFeatured}
              onChange={(checked) => {
                setCheckedFeatured(!checkedFeatured);
                if (checked) {
                  setWorkersFeatured("1");
                } else {
                  setWorkersFeatured("0");
                }
              }}
            />
            {/* <HiLightningBolt
              className={cn(
                "h-6 w-6",
                checkedFeatured ? "text-azulOscuro" : "text-gray-500"
              )}
            /> */}
            <FireIcon
              className={cn(
                "h-6 w-6 ",
                checkedFeatured ? "text-orange-500" : "text-gray-500"
              )}
            />
          </div>
          <div className="relative" ref={modalRef}>
            <div
              onClick={() => setOpenSort(!openSort)}
              className="flex items-center justify-end gap-2 cursor-pointer"
            >
              <ArrowsUpDownIcon className="text-gray-500 h-4 w-4" />
              {t("worker.findwork.body.right.sortlink")}
            </div>
            <div
              className={cn(
                "absolute top-10 right-0  transition w-60 p-2 bg-white  rounded shadow border flex flex-col gap-1",
                openSort ? "scale-100 translate-y-0" : "scale-0 -translate-y-2"
              )}
            >
              <div
                className={`bg-white border-l border-t z-20 w-4 h-4 rotate-[45deg] absolute -top-2 right-6 `}
              />
              <div
                onClick={() => handleToggleSortFeatured()}
                className={cn(
                  "text-sm flex items-center justify-between cursor-pointer p-2 rounded",
                  sortBy.featured.enabled && "bg-gray-50"
                )}
              >
                {t("worker.findwork.body.right.sortlink.option1")}
                <div className="bg-gray-100 rounded-full p-1">
                  <ArrowUpIcon
                    className={cn(
                      "h-3 w-3 transition",
                      sortBy.featured.order === "asc"
                        ? "rotate-180 transition"
                        : "rotate-0"
                    )}
                  />
                </div>
              </div>
              <div
                onClick={() => handleToggleSortUpdated()}
                className={cn(
                  "text-sm flex items-center justify-between cursor-pointer p-2 rounded",
                  sortBy.updated.enabled && "bg-gray-50"
                )}
              >
                {t("worker.findwork.body.right.sortlink.option2")}
                <div className="bg-gray-100 rounded-full p-1">
                  <ArrowUpIcon
                    className={cn(
                      "h-3 w-3 transition",
                      sortBy.updated.order === "asc"
                        ? "rotate-180 transition"
                        : "rotate-0"
                    )}
                  />
                </div>
              </div>
              <div
                onClick={() => handleToggleSortBudget()}
                className={cn(
                  "text-sm flex items-center justify-between cursor-pointer p-2 rounded",
                  sortBy.budget.enabled && "bg-gray-50"
                )}
              >
                {t("worker.findwork.body.right.sortlink.option3")}
                <div className="bg-gray-100 rounded-full p-1">
                  <ArrowUpIcon
                    className={cn(
                      "h-3 w-3 transition",
                      sortBy.budget.order === "asc"
                        ? "rotate-180 transition"
                        : "rotate-0"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-end flex items-center">
        <p
          className={cn(
            "text-sm font-medium px-3 py-1 bg-white dark:bg-background dark:text-whiteish rounded-b shadow cursor-pointer",
            showAllEnterprises && "text-azulOscuro dark:bg-input"
          )}
          onClick={() => setshowAllEnterprises(true)}
        >
          {t("worker.findwork.body.right.all_enterprisesBtn")}
        </p>
        <p
          onClick={() => setshowAllEnterprises(false)}
          className={cn(
            "text-sm font-medium px-3 py-1 bg-white dark:bg-background dark:text-whiteish rounded-b shadow cursor-pointer",
            !showAllEnterprises && "text-azulOscuro dark:bg-input"
          )}
        >
          {t("worker.findwork.body.right.saved_enterprisesBtn")}
        </p>
      </div>
      <div className="flex flex-col lg:grid grid-cols-5 items-start gap-4 w-full">
        <div className="col-span-2 w-full  ">
          <FilterWork />
        </div>
        <div className="my-4 col-span-3 w-full ">
          <div className="flex items-center justify-between">
            {showAllEnterprises ? (
              <h1 className="text-xl font-medium my-4">
                {t("worker.find_work.enterprises")}
              </h1>
            ) : (
              <h1 className="text-xl font-medium my-4">
                {t("worker.findwork.body.saved_enterprises.title")}
              </h1>
            )}
          </div>
          <div className="">
            <div className="flex flex-col gap-2">
              {companies?.map((company) => (
                <div
                  key={company.id}
                  className="w-full relative dark:bg-background dark:text-whiteish dark:border-border hover:bg-[#fafafa] rounded shadow overflow-hidden border-b border-border bg-white"
                >
                  <div className="absolute top-2 right-4">
                    <div className="flex items-center gap-2">
                      {company.validated === "1" && (
                        <div className="relative">
                          <CheckBadgeIcon className="h-4 w-4 text-green-500 peer" />
                          <div className="absolute peer-hover:scale-100 scale-0 top-9 p-1 -right-4 bg-gray-700 text-white items-center justify-center flex rounded shadow">
                            <div
                              className={`bg-gray-700 border-l border-t z-20 w-3 h-3 rotate-[45deg] absolute -top-[6px] right-[20px] `}
                            />
                            <p className="text-[10px] w-20 text-center">
                              {company.date_created}
                              {/* {company.data.date_featured} */}
                            </p>
                          </div>
                        </div>
                      )}
                      {company.validated === "0" && (
                        <div className="relative">
                          <CheckBadgeIcon className="h-4 w-4 text-gray-500 peer" />
                          <div className="absolute peer-hover:scale-100 scale-0 top-9 p-1 -right-4 bg-gray-700 text-white items-center justify-center flex rounded shadow">
                            <div
                              className={`bg-gray-700 border-l border-t z-20 w-3 h-3 rotate-[45deg] absolute -top-[6px] right-[20px] `}
                            />
                            <p className="text-[10px] w-20 text-center">
                              {company.date_created}
                              {/* {company.data.date_featured} */}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="px-8 py-2">
                    <div className="flex items-center text-[16px] font-medium">
                      {company.name}
                    </div>
                    <div className="flex items-center text-xs text-gray-600 gap-1 my-2">
                      <ImLocation className="h-3 w-3" />
                      <p className="">
                        {company.city_id},{" "}
                        <span className="font-semibold">
                          {" "}
                          {company.country_code}
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center gap-10 my-2">
                      <div className="text-sm">
                        <span className=" text-gray-500">
                          {company.description}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindWork;
