import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";

import SkillsSelector from "./SkillsSelector";
import CategorySearch from "./CategorySearch";
import JobTypeSelector from "./JobTypeSelector";

import exclamation from "../../assets/img/exclamation.png";
import { JobTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { useAtom } from "jotai";
import { budgetInvite } from "src/Atoms/jotaiAtoms";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { InitialStateJobType } from "./JobPostEdit";

const currencyCode = [{ value: "EUR", label: "EUR" }];
const salaryType = [
  { value: "Bruto", label: "Bruto", logic: "0" },
  { value: "Neto", label: "Neto", logic: "1" },
];

interface ModalEditProps {
  showModal?: boolean;
  setShowModal: any;
  title: string;
  clientSkills?: any;
  setClientSkills?: any;
  setJobLocation?: any;
  jobLocation?: string;
  setCountry?: any;
  country?: string;
  postcode?: string;
  setPostcode?: any;
  setJobType?: any;
  jobType?: string;
  jobInfo?: InitialStateJobType;
  setJobInfo?: any;
  budget?: string;
  setBudget?: any;
  selected?: string;
  setSelected?: any;
  selectedCategory?: any;
  setSelectedCategory?: any;
  categoryID?: string;
  setCategoryID?: any;
  setSelectedCurrencyCode?: any;
  netPay?: string;
  setNetPay?: any;
  setPostcodeID?: any;
  setJobLocationID?: any;
}
export default function ModalEdit({
  showModal,
  setShowModal,
  title,
  clientSkills,
  setClientSkills,
  setJobType,
  jobType,
  setJobLocation,
  jobLocation,
  country,
  setCountry,
  postcode,
  setPostcode,
  jobInfo,
  setJobInfo,
  budget,
  setBudget,
  selected,
  setSelected,
  selectedCategory,
  setSelectedCategory,
  categoryID,
  setCategoryID,
  setSelectedCurrencyCode,
  netPay,
  setNetPay,
  setPostcodeID,
  setJobLocationID,
}: ModalEditProps) {
  const [error, setError] = useState(false);
  const [decimalError, setDecimalError] = useState(false);
  const [transition, setTransition] = useState(false);
  const [budgetInvit, setBudgetInvite] = useAtom(budgetInvite);

  const { t } = useTranslation();

  const [originalData, setOriginalData] = useState<any>();
  const [back, setBack] = useState<any>();
  const ref = useRef<HTMLDivElement>(null);
  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  //Copy of original data in case user presses cancel button

  useEffect(() => {
    if (jobInfo) {
      setOriginalData((prev: any) => ({
        ...prev,
        budget: jobInfo?.budget,
        jobType: jobType,
        jobLocation: jobLocation,
        postcode: postcode,
        country: country,
        selected: selected,
        skills: clientSkills,
        netpay: jobInfo?.netpay,
      }));
    } else {
      setOriginalData((prev: any) => ({
        ...prev,
        budget: budget,
        jobType: jobType,
        jobLocation: jobLocation,
        postcode: postcode,
        country: country,
        selected: selected,
        skills: clientSkills,
      }));
    }
  }, []);

  //Budget validation
  const handleValidation = (e: any) => {
    setDecimalError(false);
    if (jobInfo) {
      setJobInfo((prev: any) => ({ ...prev, budget: e.target.value }));
      setError(false);
    } else {
      if (setBudget) {
        setBudget(e.target.value);
      } else {
        setBudgetInvite(e.target.value);
      }
      setError(false);
    }
    if (!isNaN(e.target.value) === false) {
      setError(true);
    }
    if (e.target.value.includes(".")) {
      setDecimalError(true);
    }
    if (e.target.value.length == 0) setError(true);
  };
  const handleCancelation = () => {
    if (title === t("employer.job_post_edit.budget")) {
      setNetPay(originalData?.netpay);
      if (jobInfo) {
        setJobInfo((prev: any) => ({ ...prev, budget: originalData.budget }));
      } else {
        setBudget(originalData.budget);
        setBudgetInvite(originalData.budget);
      }
    }
    if (title === t("employer.job_post_edit.type_job")) {
      setJobType(originalData.jobType);
      setJobLocation(originalData.jobLocation);
      setPostcode(originalData.postcode);
      setCountry(originalData.country);
      setSelected(originalData.selected);
    }
    if (title === t("employer.job_post_edit.skills")) {
      setClientSkills(originalData.skills);
    }
  };

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      width: "60px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
    }),
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  return (
    <div>
      {" "}
      <div className="absolute top-0 left-0 w-full h-full bg-black/50 z-40 dark">
        <div className="fixed z-10 w-[80%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white ">
          <div className="flex w-full items-center border-b justify-between px-8">
            <div className="text-lg lg:text-xl font-bold   py-6 dark:text-black">
              {t("modal_edit.edit")} {title}
            </div>
            <div
              onClick={() => closeModal()}
              className="hover:bg-gray-200 p-1 rounded-full"
            >
              <XMarkIcon className="h-4 w-4" />
            </div>
          </div>
          {(title === t("employer.job_post_edit.category").toLowerCase() ||
            title === t("employer.job_post_edit.category")) && (
            <CategorySearch
              back={back}
              title={t("employer.job_post_edit.category")}
              jobInfo={jobInfo!}
              setJobInfo={setJobInfo}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              categoryID={categoryID!}
              setCategoryID={setCategoryID}
            />
          )}
          {(title === t("employer.job_post_edit.skills") ||
            title === t("employer.job_post_edit.skills").toLowerCase()) && (
            <SkillsSelector
              setClientSkills={setClientSkills}
              clientSkills={clientSkills}
              setError={setError}
            />
          )}
          {/* {title === "editSkills" && <EditSkillSelector sending={sending} />} */}
          <div className="p-4 px-8">
            {title.toLowerCase() ===
              t("employer.job_post_edit.type_job").toLowerCase() && (
              <JobTypeSelector
                setJobType={setJobType}
                jobType={jobType!}
                setJobLocation={setJobLocation}
                jobLocation={jobLocation!}
                country={country!}
                setJobLocationID={setJobLocationID}
                setPostcodeID={setPostcodeID}
                setCountry={setCountry}
                postcode={postcode}
                setPostcode={setPostcode}
                setShowBudget={false}
                setShowScope={false}
                setShowSkills={false}
                // jobInfo={jobInfo}
                // setJobInfo={setJobInfo}
                selected={selected}
                setSelected={setSelected}
                setError={setError}
                // error={error}
              />
            )}
          </div>
          {(title === t("employer.job_post_edit.budget").toLowerCase() ||
            title === t("employer.job_post_edit.budget")) && (
            <>
              <h3 className="font-bold pt-4 px-8 text-base">
                {t("modal_edit.set_hourly")}
              </h3>
              <p className="text-sm px-8">{t("modal_edit.negotiate_desc")}</p>
              <div className="flex items-end relative  px-8 mb-6">
                <input
                  // type="number"
                  className="border text-right px-4 h-10 mt-4 outline-none rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 w-1/3"
                  defaultValue={
                    !budget
                      ? jobInfo?.budget.split(".")[0]
                      : budget.split(".")[0] ?? budgetInvit
                  }
                  onChange={(e) => handleValidation(e)}
                ></input>
                <Select
                  options={currencyCode}
                  isDisabled={true}
                  styles={colourStyles}
                  defaultValue={{
                    label: "EUR",
                    value: "EUR",
                  }}
                  onChange={(e) => setSelectedCurrencyCode(e!.value)}
                  className="text-sm cursor-pointer"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
                <Select
                  options={salaryType}
                  styles={colourStyles}
                  defaultValue={
                    netPay === "0"
                      ? { value: "Bruto", label: "Bruto", logic: "0" }
                      : { value: "Neto", label: "Neto", logic: "1" }
                  }
                  onChange={(e) => setNetPay(e!.logic)}
                  className="text-sm cursor-pointer"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              {error === true && (
                <div className="flex px-8 pb-8">
                  <img
                    src={exclamation}
                    alt="exclamation"
                    className="h-4 mt-1 mr-4"
                  />
                  <p className=" text-sm text-[#A7211B]">
                    {t("modal_edit.enter_valid")}
                  </p>
                </div>
              )}
              {decimalError === true && (
                <div className="flex px-8 pb-8">
                  <img
                    src={exclamation}
                    alt="exclamation"
                    className="h-4 mt-1 mr-4"
                  />
                  <p className=" text-sm text-[#A7211B]">
                    {t("modal_edit.enter_whole_number")}
                  </p>
                </div>
              )}
            </>
          )}
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end border-t border-blue-100 ">
            <Button
              className="bg-none text-[0.9rem] w-24 h-10 border border-[#71B9E6] text-[#71B9E6] rounded-3xl hover:bg-[#F1EFEF]  mt-1 "
              onClick={() => {
                setBack(false);
                handleCancelation();
                setTimeout(() => {
                  setShowModal(false);
                }, 50);
              }}
            >
              {t("modal_edit.cancel")}
            </Button>
            <Button
              className={` border-none  text-white w-[135px]  text-[0.9rem] px-[0.6rem] py-[0]  h-10 ${
                error === true || (title === "budget" && decimalError === true)
                  ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                  : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
              }`}
              disabled={
                error === true || (title === "budget" && decimalError === true)
              }
              onClick={() => {
                setBack(true);
                setShowModal(false);
                // window.location.reload(false);
              }}
            >
              {t("modal_edit.save")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
