import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { selectedCategory, showFilterWorkers } from "src/Atoms/jotaiAtoms";
import { StylesModal } from "src/Utils/stylesModal";
import MultiRangeSlider from "../Slider/MultiRangeSlider";
import { BASE_URL, JOB_SKILLS } from "src/Config/api.config";
import { use } from "i18next";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import Switch from "react-switch";

const professionalExperience = [
  {
    value: "0-2",
    checked: false,
  },
  {
    value: "2-5",
    checked: false,
  },
  {
    value: "6-10",
    checked: false,
  },
  {
    value: "11-20",
    checked: false,
  },
  {
    value: "+20",
    checked: false,
  },
];

const ModalFilterWorkersTime = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [filterWorkers, setFilterWorkers] = useAtom(showFilterWorkers);
  const [transition, setTransition] = useState(false);
  const [suggestedSkills, setSuggestedSkills] = useState<any>([]);
  const [selectCategory, setSelectCategory] = useAtom(selectedCategory);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [selectedSkills, setSelectedSkills] = useState<
    { skill_id: string; name: string; checked: boolean }[]
  >([]);
  const [businessProfile, setBusinessProfile] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  const [checkedExperience, setCheckedExperience] = useState<
    {
      value: string;
      checked: boolean;
    }[]
  >([]);

  useEffect(() => {
    setCheckedExperience(professionalExperience);
  }, []);

  const [minMaxBudget, setMinMaxBudget] = useState({ min: 9, max: 20 });

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (filterWorkers && ref.current && !ref.current.contains(e.target)) {
        setFilterWorkers(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [filterWorkers]);

  const toggleCheckbox = (
    items: { value: string; checked: boolean }[],
    name: string
  ) => {
    setCheckedExperience(
      items.map((item) => {
        if (item.value === name) {
          return { ...item, checked: !item.checked };
        }
        return item;
      })
    );
  };
  const toggleCheckboxSkills = (
    items: { skill_id: string; name: string; checked: boolean }[],
    name: string
  ) => {
    setSelectedSkills(
      items.map((item) => {
        if (item.name === name) {
          return { ...item, checked: !item.checked };
        }
        return item;
      })
    );
  };

  const clientSuggestedSkills = () => {
    fetch(
      `${BASE_URL}${JOB_SKILLS}?category_id=${selectCategory.category_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setSuggestedSkills(
          data.result.map((item: { skill_id: string; name: string }) => {
            return {
              ...item,
              checked: false,
            };
          })
        );
        // setSuggestedSkillsIntact(data.result);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (selectCategory.name !== "") {
      clientSuggestedSkills();
    }
  }, [selectCategory]);

  useEffect(() => {
    setSelectedSkills(suggestedSkills);
  }, [suggestedSkills]);

  return (
    <Modal style={StylesModal} isOpen={filterWorkers} ariaHideApp={false}>
      <div
        className={`fixed z-50 ${
          transition === false ? "opacity-40 scale-0" : "opacity-100 scale-100"
        } transition duration-150 w-[90%] lg:w-[700px] h-[650px] max-w-auto max-h-auto left-[50%] shadow-xl translate-x-[-50%] rounded-xl top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="flex items-center justify-between p-10">
          <h1 className="text-2xl font-semibold">Filters</h1>
          <div
            onClick={() => setFilterWorkers(false)}
            className="h-8 w-8 rounded-full bg-[#f8f8f8] flex items-center justify-center cursor-pointer"
          >
            <IoClose className="h-6 w-6 " />
          </div>
        </div>
        <div className="p-10 h-[435px] overflow-y-auto scrollbar-hide">
          <div className="flex flex-col items-start space-y-5 pb-10">
            <div className="flex items-center space-x-4">
              <h1 className="text-lg font-semibold">Price (hour)</h1>
              <h3 className="text-lg font-semibold text-azulOscuro/70">
                {minMaxBudget.min}€/h -{" "}
                {minMaxBudget.max === 20 ? "Maximum" : `${minMaxBudget.max}€/h`}
              </h3>
            </div>
            <MultiRangeSlider
              min={9}
              max={20}
              onChange={({ min, max }) => {
                setMinMaxBudget({ min, max });
                console.log(`min - ${min}, max = ${max}`);
              }}
            />
          </div>
          <div className="flex flex-col items-start space-y-5 mt-10">
            <h1 className="text-lg font-semibold">Professional Experience</h1>
            {checkedExperience.map((experience) => (
              <div
                key={experience.value}
                className="flex items-center space-x-4"
              >
                <input
                  onChange={() =>
                    toggleCheckbox(checkedExperience, experience.value)
                  }
                  checked={experience.checked}
                  type="checkbox"
                  className="h-6 w-6 border-2 cursor-pointer"
                />
                <p className="">{experience.value} años de experiencia</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-start space-y-5 mt-10">
            <h1 className="text-lg font-semibold">Skills Experience</h1>
            {selectedSkills.map((experience) => (
              <div
                key={experience.skill_id}
                className="flex items-center space-x-4"
              >
                <input
                  onChange={() =>
                    toggleCheckboxSkills(selectedSkills, experience.name)
                  }
                  checked={experience.checked}
                  type="checkbox"
                  className="h-6 w-6 border-2 cursor-pointer"
                />
                <p className="">{experience.name} </p>
              </div>
            ))}
          </div>
          <div className="flex items-start justify-between  mt-10 border-t border-border py-4">
            <div className="flex flex-col items-start space-y-5">
              <h1 className="text-lg font-semibold">Business profiles</h1>
              <p className="text-sm text-gray-500">
                Sólo perfiles que correspondan a una empresa o profesional
                autónomo validado.
              </p>
            </div>
            <Switch
              checked={businessProfile}
              onChange={(checked) => {
                setBusinessProfile(!businessProfile);
                // if (checked) {
                //   setWorkersFeatured("1");
                // } else {
                //   setWorkersFeatured("0");
                // }
              }}
            />
          </div>
        </div>
        <div className="flex flex-col items-start space-y-5 p-10 shadow-lg rounded-b-lg border-t-2 ">
          <div className="flex items-center justify-between w-full">
            <button className="underline text-sm font-medium">
              Remove filters
            </button>
            <button
              onClick={() => setFilterWorkers(false)}
              className="px-10 py-2 bg-azulBonico text-white rounded text-sm font-medium"
            >
              Show professionals
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFilterWorkersTime;
