import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../Config/api.config";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { ReactComponent as Search } from "../../../assets/img/search2.svg";
import { ReactComponent as Banner } from "../../../assets/img/myHiresEmployerBanner.svg";
import ModalEditEndEarlyDate from "../../../Components/Modals/ModalEditEndEarlyDate";
import ModalSendRating from "../../../Components/Modals/ModalSendRating";
import ModalWorkerCard, {
  ModalProps,
} from "../../../Components/Modals/ModalWorkerCard";
import DisplayHires from "../../../Components/HiredWorkers/DisplayHires";
import ReviewsScreen from "../../../Components/HiredWorkers/ReviewsScreen";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import autoAnimate from "@formkit/auto-animate";
import { useAtom } from "jotai";
import { loadingFeedback } from "../../../Atoms/jotaiAtoms";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import DisplayHiresNew from "src/Components/HiredWorkers/DisplayHiresNew";
import { HireTypes } from "src/types/types";
import GoBackButton from "src/Components/ui/GoBack";

const LIMIT = 10;
const PAGE_INITIAL = 1;
const ROWS_INITIAL = 1;

const HiredWorkers = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [hiredWorkers, setHiredWorkers] = useState({
    active: [],
    pending: [],
    finished: [],
    all: [],
  });
  const [query, setQuery] = useState("");
  const [reviews, setReviews] = useState<any>();
  const [showModal, setShowModal] = useState<ModalProps>({
    worker: false,
    rating: false,
    end: false,
    chat: false,
    decline: false,
    invite: false,
    report: false,
    video: false,
  });
  const [clickedWorker, setClickedWorker] = useState<any>();
  const [refreshData, setRefreshData] = useState(false);
  const [selectedSection, setSelectedSection] = useState("active");
  const [workerInfo, setWorkerInfo] = useState();
  const ref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useAtom(loadingFeedback);
  const [allContracts, setAllContracts] = useState<HireTypes[]>([]);
  const [limit, setLimit] = useState(LIMIT);
  const [page, setPage] = useState(PAGE_INITIAL);
  const [rows, setRows] = useState(ROWS_INITIAL);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParams, setSortParams] = useState("date_created");
  const [headerVisible, setHeaderVisible] = useState(true);

  const orderSenseOptions = [
    {
      value: "asc",
      label: `${t("employer.hires.sort.ascending")}`,
    },
    {
      value: "desc",
      label: `${t("employer.hires.sort.descending")}`,
    },
  ];
  const [orderSort, setOrderSort] = useState(orderSenseOptions[0]);

  const getWorkerInfo = (id: string) => {
    fetch(`${BASE_URL}workers?client_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setWorkerInfo((prev: any) => ({
            ...prev,
            allChosenTimes: data.result.workerProfileTime,
            languages: data.result.workerLanguage,
            education: data.result.workerEducation,
            experience: data.result.workerExperience,
            categories: data.result.workerCategoryList,
            score: data.result.score,
            data: data.result.workerProfile,
          }));
          setShowModal((prev) => ({ ...prev, worker: true }));
        }
      })
      .catch((err) => console.log(err));
  };
  const getHiredWorkers = (query: string) => {
    setLoading(true);
    fetch(
      `${BASE_URL}worker.employer.connection?client_id=${userID}${
        query ? `&find=${query}` : ""
      }&order=${
        sortParams === "date_end" ? "date_early_end" : sortParams
      }&order_sense=${orderSort.value.toUpperCase()}&page=${page}&limit=${LIMIT}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAllContracts(data.result);
        setRows(data.paginate.rows);
        setTotalPages(data.paginate.pages);
        setHiredWorkers((prev) => ({
          ...prev,
          all: data.result,
          finished: data.result.filter(
            (item: {
              date_early_end: string;
              server_date: string;
              date_end: string;
            }) =>
              item.date_early_end !== null || item.server_date > item.date_end
          ),
          active: data.result.filter(
            (item: {
              date_early_end: string;
              server_date: string;
              date_end: string;
            }) =>
              item.date_early_end === null && item.server_date < item.date_end
          ),
          // pending: data.result.filter(
          //   (item) =>
          //     item.date_early_end === null && item.date_start > item.server_date
          // ),
        }));
        setReviews({
          received: data.result.filter(
            (item: { score_from_worker: string | null }) =>
              item.score_from_worker !== null &&
              item.score_from_worker !== "0.00"
          ),
          given: data.result.filter(
            (item: { score_from_employer: string | null }) =>
              item.score_from_employer !== null &&
              item.score_from_employer !== "0.00"
          ),
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  const breakWorkerConnection = (
    connection: string,
    reason: string,
    date: string
  ) => {
    data.append("connection_id", connection);
    data.append("end_work_reason_id", reason);
    data.append("date_early_end", date);

    fetch(`${BASE_URL}worker.employer.connection`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getHiredWorkers("");
  }, []);

  useEffect(() => {
    if (refreshData) {
      getHiredWorkers("");
      setRefreshData(false);
    }
  }, [refreshData]);

  useEffect(() => {
    if (query && ref.current) {
      setQuery("");
      ref.current.value = "";
      setRefreshData(true);
    }
  }, [selectedSection]);

  useEffect(() => {
    ref.current && autoAnimate(ref.current);
  }, [ref]);
  const handleSearch = (e: string) => {
    setQuery(e);
  };

  useEffect(() => {
    getHiredWorkers(query);
  }, [query, page, sortParams, orderSort]);

  return (
    <>
      <HeaderBanner
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            {t("employer.hired_workeres.header_banner")}
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline my-8 mx-4"
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>

      <div className="shadow bg-white rounded-lg w-full transition dark:bg-background dark:border-border dark:border dark:text-whiteish">
        <div ref={ref} className="flex flex-col">
          <div className="flex pt-8 px-8 pb-3 border-b dark:border-border gap-10 text-xs lg:text-sm font-medium ">
            <p
              className={`${
                selectedSection === "active"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer  `}
              onClick={() => setSelectedSection("active")}
            >
              {t("employer.myhires.card.layer1.title")}
            </p>

            <p
              className={`${
                selectedSection === "finished"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer`}
              onClick={() => setSelectedSection("finished")}
            >
              {t("employer.myhires.card.layer3.title")}
            </p>
            <p
              className={`${
                selectedSection === "feedback"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer`}
              onClick={() => setSelectedSection("feedback")}
            >
              {t("employer.myhires.card.layer4.title")}
            </p>
            <p
              className={`${
                selectedSection === "allcontracts"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer`}
              onClick={() => setSelectedSection("allcontracts")}
            >
              {t("employer.hires.diplay_hires.all_contracts")}
            </p>
          </div>
          {selectedSection !== "feedback" &&
            selectedSection !== "allcontracts" && (
              <div
                className="relative w-[90%] lg:w-[95%] my-8 ml-6 "
                onKeyDown={(e) => {
                  if (e.key === "Enter") getHiredWorkers("");
                }}
              >
                <input
                  type="text"
                  ref={ref}
                  className="w-full border-2 border-blue-100 h-10 pl-4 outline-none text-sm rounded hover:border-blue-200 focus:border-blue-200 transition duration-300 dark:bg-input dark:border-border"
                  placeholder={`${t(
                    "employer.messages.leftbar.search.placeholder"
                  )}`}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                />
                <div
                  className="h-10 w-9 bg-azulBonico hover:bg-azulOscuro transition duration-300 absolute right-0 top-0 rounded-r cursor-pointer"
                  onClick={() => getHiredWorkers("")}
                >
                  <Search
                    className="h-4 cursor-pointer absolute top-[12px] left-[10px]"
                    fill={"white"}
                  />
                </div>
              </div>
            )}
          {selectedSection === "active" && (
            <DisplayHires
              section={"active"}
              hiredWorkers={hiredWorkers.active}
              setClickedWorker={setClickedWorker}
              setShowModal={setShowModal}
              getWorkerInfo={getWorkerInfo}
            />
          )}

          {selectedSection === "finished" && (
            <DisplayHires
              section={"finished"}
              hiredWorkers={hiredWorkers.finished}
              setClickedWorker={setClickedWorker}
              setShowModal={setShowModal}
              getWorkerInfo={getWorkerInfo}
            />
          )}
          {selectedSection === "feedback" && (
            <ReviewsScreen reviews={reviews} />
          )}
          {selectedSection === "allcontracts" && (
            <DisplayHiresNew
              allContracts={allContracts}
              getWorkerInfo={getWorkerInfo}
              setClickedWorker={setClickedWorker}
              setShowModal={setShowModal}
              handleSearch={handleSearch}
              getHiredWorkers={getHiredWorkers}
              limit={limit}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              rows={rows}
              setSortParams={setSortParams}
              setOrderSort={setOrderSort}
              orderSort={orderSort}
              orderSortOptions={orderSenseOptions}
            />
          )}
        </div>
      </div>
      {showModal.end && (
        <ModalEditEndEarlyDate
          showModal={showModal}
          setShowModal={setShowModal}
          hire={clickedWorker}
          breakWorkerConnection={breakWorkerConnection}
        />
      )}
      {showModal.rating && (
        <ModalSendRating
          setShowModal={setShowModal}
          showModal={showModal}
          hire={clickedWorker}
          setRefreshData={setRefreshData}
          setSelectedSection={setSelectedSection}
        />
      )}
      {showModal.worker && (
        <ModalWorkerCard
          checkIfThereIsConversation={() => console.log()}
          handleArchived={() => console.log()}
          handleShortlist={() => console.log}
          setDeclinedApplicant={undefined}
          showModal={showModal}
          setShowModal={setShowModal}
          worker={clickedWorker}
          workerInfo={workerInfo}
          setWorker={setWorkerInfo}
        />
      )}
    </>
  );
};

export default HiredWorkers;
