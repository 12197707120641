import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

import PasswordModal from "../ClientSettingsBoard/PasswordModal";

import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { BsFillChatDotsFill, BsQuestionCircleFill } from "react-icons/bs";
import Switch from "react-switch";
import { AiFillCheckCircle } from "react-icons/ai";
import { HiPencil } from "react-icons/hi";
import { GrMonitor } from "react-icons/gr";

interface PasswordSecurityProps {
  showAlert: boolean;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setDecorationBar: Dispatch<SetStateAction<string>>;
}

export default function PasswordSecurity({
  showAlert,
  setShowAlert,
  setDecorationBar,
}: PasswordSecurityProps) {
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [checkedFirst, setCheckedFirst] = useState(false);
  const [checkedSecond, setCheckedSecond] = useState(false);
  const [checkedThird, setCheckedThird] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setDecorationBar("password");
  }, []);

  return (
    <div className=" px-4 mt-4 lg:mt-0 py-2">
      <div className=" bg-white rounded-lg shadow pb-6 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className=" flex flex-col ">
          <p className="text-sm lg:text-xl font-semibold mb-8 border-b  border-border p-4 lg:p-8">
            {t("settings.password_security")}
          </p>
          <div className="bg-background rounded-lg  dark:bg-background flex flex-col px-8 pb-8">
            <div className="flex justify-between items-center mb-4 py-4">
              <p className="text-sm lg:text-lg font-bold">
                {t("settings.password_security.password")}
              </p>
              <div
                onClick={() => setShowModalPassword(true)}
                className="border border-border px-4 py-2 hover:bg-gray-100 cursor-pointer font-medium rounded text-sm"
              >
                {t(
                  "employer.leftbar_down.menu.my_profile.configuration.settings.titulo1_opcionesautenticacionyverificacion.contraseñayseguridad.changeyourpasswordbtn"
                )}
              </div>
              {/* <img
                src={edit}
                alt="edit"
                className="h-3 cursor-pointer"
                onClick={() => setShowModalPassword(true)}
              ></img> */}
            </div>
            {showModalPassword === true && (
              <PasswordModal
                showModal={showModalPassword}
                setShowModal={setShowModalPassword}
              />
            )}
            <div className="flex flex-col">
              <div className="flex items-center">
                <CheckCircleIcon className="h-5 mr-2" />
                {/* <img src={verify} alt="verify" className="h-5 pr-2"></img> */}
                <p className="text-sm">
                  {t("settings.password_security.password_sent")}
                </p>
              </div>
              <p className="text-sm text-gray-400 mt-1">
                {t("settings.password_security.password_strength")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="   mt-4">
        <div className="flex flex-col border-b border-blue-100 border-border py-8">
          <h1 className="text-xl font-semibold ">
            {t(
              "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.title"
            )}
          </h1>
          <p className="text-sm text-gray-600 mt-4">
            {t(
              "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.subtitle"
            )}
          </p>
        </div>
        <div className="bg-background dark:text-whiteish p-8 flex items-center    justify-between w-full">
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <h3 className="text-sm font-medium">
                {t(
                  "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner1.title"
                )}
              </h3>
              <BsQuestionCircleFill className="text-azulBonico h-4 w-4" />
            </div>
            <h3 className="text-gray-600 text-sm  mt-4">
              {t(
                "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner1.subtitle"
              )}
            </h3>
          </div>
          <Switch
            checked={checkedFirst}
            onChange={(val) => setCheckedFirst(val)}
          />
        </div>
        <div className="bg-background dark:text-whiteish p-8 flex items-start dark:border-border border-t shadow justify-between w-full">
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <h3 className="text-sm font-medium">
                {t(
                  "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner2.title"
                )}
              </h3>
              <BsQuestionCircleFill className="text-azulBonico h-4 w-4" />
            </div>
            <h3 className="text-gray-600 text-sm  mt-4">
              {t(
                "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner2.subtitle"
              )}
              ,
            </h3>
            <div className="bg-background border border-border  flex items-center justify-between w-full p-4 mt-4 rounded-lg">
              <div className="flex items-center gap-4">
                <BsFillChatDotsFill className="h-5 w-5" />
                <div>
                  <h3 className="font-medium text-sm">
                    {t(
                      "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner2.notification_smsdelivery"
                    )}
                  </h3>
                  <p className="text-sm text-gray-500">+34666554422</p>
                </div>
              </div>
              <div className="text-red-500 text-sm">
                {t(
                  "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner2.notification_smsdelivery.remove"
                )}
              </div>
            </div>
          </div>
          <Switch
            checked={checkedSecond}
            onChange={(val) => setCheckedSecond(val)}
          />
        </div>
        <div className="bg-background dark:text-whiteish p-8 flex items-center dark:border-border border-t shadow justify-between w-full">
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <h3 className="text-sm font-medium">Text message</h3>
              <BsQuestionCircleFill className="text-azulBonico h-4 w-4" />
            </div>
            <h3 className="text-gray-600 text-sm  mt-4">
              {t(
                "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner3.subtitle"
              )}
            </h3>
          </div>
          <Switch
            checked={checkedThird}
            onChange={(val) => setCheckedThird(val)}
          />
        </div>
        <div className="bg-background dark:text-whiteish p-8 flex items-center dark:border-border border-t rounded-b-lg shadow justify-between w-full">
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <h3 className="text-sm font-medium">
                {t(
                  "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner4.title"
                )}
              </h3>
              <BsQuestionCircleFill className="text-azulBonico h-4 w-4" />
            </div>
            <div className="flex items-start gap-2 mt-2">
              <AiFillCheckCircle className="text-azulBonico" />
              <div className="flex flex-col items-start">
                <h3 className="font-medium text-sm ">
                  {t(
                    "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner4.disabled_option.title"
                  )}
                </h3>
                <p className="text-sm text-gray-600">
                  {t(
                    "employer.leftbar_down.menu.my_profile.configuration.settings.titulo2_opcionesverificacionendospasos.banner4.disabled_option.subtitle"
                  )}
                </p>
              </div>
            </div>
            {/* <h3 className="text-gray-600 text-sm  mt-4">
              Receive a six digit code by text message to confirm it's you.
            </h3> */}
          </div>
          <div className="border-2 rounded-full p-1">
            <HiPencil className="text-azulOscuro h-6 w-6" />
          </div>
          {/* <Switch /> */}
        </div>
      </div>
      <div className="mt-4">
        <div className="flex flex-col border-b border-blue-100 py-8">
          <h1 className="text-xl font-semibold ">
            {t(
              "employer.leftbar_down.menu.my_profile.configuration.settings.titulo3_dispositivos.title"
            )}
          </h1>
          <p className="text-sm text-gray-600 mt-4">
            {t(
              "employer.leftbar_down.menu.my_profile.configuration.settings.titulo3_dispositivos.subtitle"
            )}
          </p>
        </div>
        <div className="bg-background p-8 flex items-center dark:border-border shadow justify-between w-full">
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <h3 className="text-sm font-medium">
                {t(
                  "employer.leftbar_down.menu.my_profile.configuration.settings.titulo3_dispositivos.banner.left_title"
                )}
              </h3>
            </div>
          </div>
          <h3 className="text-sm font-medium">
            {t(
              "employer.leftbar_down.menu.my_profile.configuration.settings.titulo3_dispositivos.banner.right_title"
            )}
          </h3>
        </div>
        <div className="bg-background dark:text-whiteish px-8 py-4 flex items-center dark:border-border shadow justify-between w-full border-y">
          <div className="flex flex-col ">
            <div className="flex items-center gap-2">
              <GrMonitor className="h-6 w-6 " />
              <h3 className="text-sm text-azulOscuro">Chrome on Windows</h3>
            </div>
            <h1 className="text-sm mt-4">{new Date().toDateString()}</h1>
            <h1 className="text-sm ">Valencia (Valencia), Spain</h1>
          </div>
          <div className="text-sm font-medium border border-border px-4 py-2 hover:bg-gray-100 cursor-pointer rounded">
            {t(
              "employer.leftbar_down.menu.my_profile.configuration.settings.titulo3_dispositivos.banner.log_out.btn"
            )}
          </div>
        </div>
        <div className="bg-background px-8 py-4 flex items-center dark:border-border shadow justify-between w-full border-y">
          <div className="flex flex-col ">
            <div className="flex items-center gap-2">
              <GrMonitor className="h-6 w-6 " />
              <h3 className="text-sm text-azulOscuro">Chrome on Windows</h3>
            </div>
            <h1 className="text-sm mt-4">{new Date().toDateString()}</h1>
            <h1 className="text-sm ">Valencia (Valencia), Spain</h1>
          </div>
          <div className="text-sm font-medium border border-border px-4 py-2 hover:bg-gray-100 cursor-pointer rounded">
            {t(
              "employer.leftbar_down.menu.my_profile.configuration.settings.titulo3_dispositivos.banner.log_out.btn"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
