export const hoursInitial = [
  { value: "00:00", label: "00:00", id: 1, show: true },
  { value: "00:30", label: "00:30", id: 2, show: true },
  { value: "01:00", label: "01:00", id: 3, show: true },
  { value: "01:30", label: "01:30", id: 4, show: true },
  { value: "02:00", label: "02:00", id: 5, show: true },
  { value: "02:30", label: "02:30", id: 6, show: true },
  { value: "03:00", label: "03:00", id: 7, show: true },
  { value: "03:30", label: "03:30", id: 8, show: true },
  { value: "04:00", label: "04:00", id: 9, show: true },
  { value: "04:30", label: "04:30", id: 10, show: true },
  { value: "05:00", label: "05:00", id: 11, show: true },
  { value: "05:30", label: "05:30", id: 12, show: true },
  { value: "06:00", label: "06:00", id: 13, show: true },
  { value: "06:30", label: "06:30", id: 14, show: true },
  { value: "07:00", label: "07:00", id: 15, show: true },
  { value: "07:30", label: "07:30", id: 16, show: true },
  { value: "08:00", label: "08:00", id: 17, show: true },
  { value: "08:30", label: "08:30", id: 18, show: true },
  { value: "09:00", label: "09:00", id: 19, show: true },
  { value: "09:30", label: "09:30", id: 20, show: true },
  { value: "10:00", label: "10:00", id: 21, show: true },
  { value: "10:30", label: "10:30", id: 22, show: true },
  { value: "11:00", label: "11:00", id: 23, show: true },
  { value: "11:30", label: "11:30", id: 24, show: true },
  { value: "12:00", label: "12:00", id: 25, show: true },
  { value: "12:30", label: "12:30", id: 26, show: true },
  { value: "13:00", label: "13:00", id: 27, show: true },
  { value: "13:30", label: "13:30", id: 28, show: true },
  { value: "14:00", label: "14:00", id: 29, show: true },
  { value: "14:30", label: "14:30", id: 30, show: true },
  { value: "15:00", label: "15:00", id: 31, show: true },
  { value: "15:30", label: "15:30", id: 32, show: true },
  { value: "16:00", label: "16:00", id: 33, show: true },
  { value: "16:30", label: "16:30", id: 34, show: true },
  { value: "17:00", label: "17:00", id: 35, show: true },
  { value: "17:30", label: "17:30", id: 36, show: true },
  { value: "18:00", label: "18:00", id: 37, show: true },
  { value: "18:30", label: "18:30", id: 38, show: true },
  { value: "19:00", label: "19:00", id: 39, show: true },
  { value: "19:30", label: "19:30", id: 40, show: true },
  { value: "20:00", label: "20:00", id: 41, show: true },
  { value: "20:30", label: "20:30", id: 42, show: true },
  { value: "21:00", label: "21:00", id: 43, show: true },
  { value: "21:30", label: "21:30", id: 44, show: true },
  { value: "22:00", label: "22:00", id: 45, show: true },
  { value: "22:30", label: "22:30", id: 46, show: true },
  { value: "23:00", label: "23:00", id: 47, show: true },
  { value: "23:30", label: "23:30", id: 48, show: true },
  { value: "23:59", label: "23:59", id: 49, show: true },
];
