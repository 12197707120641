import { useQuery } from "react-query";
import useLocalStorage from "./UseLocalStorage";
import { BASE_URL } from "src/Config/api.config";

export const useClientCredits = () => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const getClientCredits = async () => {
    const data = await fetch(
      `${BASE_URL}clients.wallet?client_id=${userID}&mode=balance`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: clientCreditsData, refetch: refetchClientCredits } = useQuery(
    ["client_credits"],
    getClientCredits
  );

  return { clientCreditsData, refetchClientCredits };
};

export const useClientCreditsInfo = () => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const getWalletInfo = async () => {
    const data = await fetch(
      `${BASE_URL}clients.wallet?client_id=${userID}&mode=detail`,
      {
        headers: {
          Authorization: "Bearer" + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const {
    data: walletData,
    refetch: refetchWalletInfo,
    isLoading: isWalletLoading,
  } = useQuery(["wallet"], getWalletInfo);

  return { walletData, refetchWalletInfo, isWalletLoading };
};
