import React, { useRef } from "react";

import "./Header.css";
import FlexihoursLogoPng from "../../assets/img/logopng.png";

import { BiChevronDown } from "react-icons/bi";
import { TbWorld } from "react-icons/tb";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderBurger from "./HeaderBurger";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useLanguages } from "../../Hooks/useLanguages";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { cn } from "src/lib/utils";
import Hamburger from "hamburger-react";
import { BASE_URL, NOTIFICATIONS } from "src/Config/api.config";
import { useQuery } from "react-query";
import { userProfileStore } from "src/Routing/useProfileStore";

function Header() {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");

  const [showChangeLanguage, setShowChangeLanguage] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { options } = useLanguages();
  const modalRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const checkNotifications = async () => {
    const data = await fetch(
      `${BASE_URL}${NOTIFICATIONS}?client_id=${userID}&limit=10`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };
  const { data: notifications } = useQuery({
    queryFn: checkNotifications,
    queryKey: ["notifications"],
    enabled: loggedInUserUid !== "",
  });

  useEffect(() => {
    if (notifications?.error_msg === "Token invalid or expired") {
      setLoggedInUserUid("");
      setUserId("");
    }
  }, [notifications]);
  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        showChangeLanguage &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setShowChangeLanguage(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showChangeLanguage]);

  return (
    <div className="flex items-center justify-center bg-transparent   w-full z-20">
      <div className="flex lg:hidden w-full lg:px-12 mx-auto justify-between h-[80px] items-center">
        <img
          src={FlexihoursLogoPng}
          className="h-11 cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        />
        <Hamburger color="black" toggled={isOpen} toggle={setOpen} size={28} />
      </div>
      <div className="hidden lg:block w-full ">
        <div className="flex items-center max-w-7xl mx-auto  justify-between  py-4">
          <div className="flex items-center gap-10">
            <a href="/" aria-label="home" className="brand">
              {/* {pathname === "/" ? (
              <FlexihoursLogo className="h-10" />
            ) : ( */}
              <img
                src={FlexihoursLogoPng}
                loading="lazy"
                className="h-11 cursor-pointer"
              />
              {/* )} */}
            </a>
            <nav className="nav-menu w-nav-menu">
              <div
                onClick={() => navigate("/aboutus")}
                className="  px-4 py-1 rounded-full cursor-pointer transition duration-200 ease-out"
              >
                <h1
                  className={cn(
                    " font-medium text-[15px]",
                    pathname !== "/aboutus" && "text-black",
                    pathname === "/aboutus" && "text-[#333]"
                  )}
                >
                  {t("home.Fotter.Right2.Seccion8.aboutusLink")}
                </h1>
              </div>
              <div
                onClick={() => navigate("/pricing")}
                className="  px-4 py-1 rounded-full cursor-pointer transition duration-200 ease-out"
              >
                <h1
                  className={cn(
                    " font-medium text-[15px]",
                    pathname !== "/pricing" && "text-black",
                    pathname === "/pricing" && "text-[#333]"
                  )}
                >
                  {t("home.Fotter.Right2.Seccion9.thirdTitle")}
                </h1>
              </div>
              <div
                onClick={() => navigate("/contact")}
                className="  px-4 py-1 rounded-full cursor-pointer transition duration-200 ease-out"
              >
                <h1
                  className={cn(
                    " font-medium text-[15px]",
                    pathname !== "/contact" && "text-black",
                    pathname === "/contact" && "text-[#333]"
                  )}
                >
                  {t("home.Fotter.Right2.Seccion9.forthTitle")}
                </h1>
              </div>
              {/* <div
                onClick={() => navigate("/coming-soon")}
                className="  px-4 py-1 rounded-full cursor-pointer transition bg-azulBonico duration-200 ease-out"
              >
                <h1 className={cn(" font-medium text-[15px] text-white")}>
                  Get Early Access
                </h1>
              </div> */}
            </nav>
          </div>

          <div className="nav-cta-wrapper">
            <div className="nav-link-language">
              <button
                onClick={() => setShowChangeLanguage(!showChangeLanguage)}
                className="flex items-center space-x-2 "
              >
                <TbWorld
                  color={pathname === "/" ? "black" : "gray"}
                  size={18}
                />

                <BiChevronDown
                  color={pathname === "/" ? "black" : "gray"}
                  size={20}
                />
              </button>
              {showChangeLanguage ? (
                <div
                  ref={modalRef}
                  className="absolute z-50 -bottom-52 -left-6 bg-white  w-40 px-4 py-4 rounded-lg shadow-xl "
                >
                  <h1 className="font-semibold uppercase text-[13px] pb-5 ">
                    {t("modal.add_language.language")}
                  </h1>
                  {options?.map((e) => (
                    <div
                      key={e.value}
                      className={`text-[14px] mb-1 ${
                        e.value === i18n.language
                          ? "bg-gray-100"
                          : " text-black"
                      } hover:bg-gray-200 transition-transform duration-150 ease-in-out w-full px-4 py-2 rounded cursor-pointer`}
                      onClick={() => {
                        setShowChangeLanguage(false);
                        i18n.changeLanguage(e.value);
                      }}
                    >
                      {e.label}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            {/* <div className="find-buttons">
              {loggedInUserUid ? (
                <a
                  onClick={() => {
                    if (profileType === "employer") {
                      navigate("/dashboard");
                    } else {
                      navigate("/dashboard-worker");
                    }
                  }}
                >
                  <button className="jobs-button transition">
                    <p className="text-[15px] font-medium">
                      {t("header.dashboard.title")}
                    </p>
                  </button>
                </a>
              ) : (
                <a onClick={() => navigate("/login")}>
                  <button className="jobs-button transition">
                    <p className="text-[15px] font-medium">
                      {t("home.Header.logInBtn")}
                    </p>
                  </button>
                </a>
              )}

              {!loggedInUserUid ?? (
                <a onClick={() => navigate("/register")}>
                  <button className="staff-button transition">
                    <p className="text-[15px] font-medium">
                      {t("home.Header.registerBtn")}
                    </p>
                  </button>
                </a>
              )}
            </div> */}
            <button
              onClick={() => navigate("/coming-soon")}
              className="relative inline-flex items-center px-6 py-2 rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 shadow-md text-white font-medium text-[15px] transition duration-300 ease-out hover:from-indigo-500 hover:to-blue-500 hover:shadow-lg focus:ring-4 focus:ring-blue-300 active:scale-95"
            >
              <span className="transition transform group-hover:scale-105">
                {t("coming-soon.early-access")}
              </span>
            </button>
          </div>
        </div>
      </div>
      {isOpen && <HeaderBurger />}
    </div>
  );
}

export default Header;
