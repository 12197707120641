import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import ModalAddEducation from "../../../Components/Modals/ModalAddEducation";
import ModalEditEducation from "../../../Components/Modals/ModalEditEducation";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";
import ModalDeleteEducation from "../../../Components/Modals/ModalDeleteEducation";

import { ReactComponent as Plus } from "../../../assets/img/plus.svg";
import edit from "../../../assets/img/edit.png";
import trash from "../../../assets/img/trash-can.png";
import educationImg from "../../../assets/img/education.png";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { EducationTypes } from "src/types/types";
import { useTranslation } from "react-i18next";

function AddEducation() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [allEducations, setAllEducations] = useLocalStorage(
    "EducationData",
    []
  );
  const [endingYear, setEndingYear] = useState("");
  const [startingYear, setStartingYear] = useState("");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [education, setEducation] = useLocalStorage("dataEducationEdit", []);
  const [checked, setChecked] = useState(false);

  const handleNoMoreLocal = () => {
    setEducation();
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  const removeEducation = () => {
    setAllEducations(
      allEducations?.filter(
        (items: { id: string }) => items.id !== education.id
      )
    );
  };
  const navigate = useNavigate();
  const onNextClick = () => {
    navigate("/create-profile/categories");
  };
  const getAllEducations = () => {
    fetch(`${BASE_URL}worker.education?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllEducations(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllEducations();
  }, []);

  const handleEdit = (education: EducationTypes) => {
    fetch(`${BASE_URL}worker.education?id=${education.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setEducation(data.result[0]))
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div className="h-screen w-full !bg-white">
        <RegistrationHeader />
        <div className=" pt-12 mx-auto  place-items-center px-10 lg:px-0 flex flex-col min-h-[75vh] ">
          <div className="text-xl lg:text-4xl font-bold lg:max-w-2xl">
            {t("createprofile.education.title")}
          </div>
          <p className="pt-8 max-w-2xl text-sm text-[#6e7081]">
            {t("createprofile.education.subtitle")}
          </p>
          {allEducations.length === 0 ? (
            <>
              <div className="flex flex-col items-start w-full lg:max-w-2xl">
                <div className="w-[90%] lg:w-[377px] h-[276px] border-2  rounded-xl justify-start items-start flex flex-col border-dashed  border-azulBonico mt-16">
                  <Plus
                    fill={"white"}
                    className="h-14 border rounded-full ml-5 p-5 bg-azulBonico justify-center mt-8 hover:bg-azulOscuro cursor-pointer"
                    onClick={() => setShowModal(true)}
                  />
                  <p className="mt-5 font-bold text-2xl tracking-wider ml-5 text-[#6e7081]">
                    {t("createprofile.experience.body.box.in")}
                  </p>
                </div>
                <label className=" mt-10 text-sm text-[#6e7081] cursor-pointer p-1  hover:bg-[#ededee]">
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    className="mr-4"
                  />{" "}
                  {t("createprofile.education.body.box.subtitle")}
                </label>
              </div>
            </>
          ) : (
            <>
              <Plus
                fill={"#6e7081"}
                className="h-12 border rounded-full p-4 justify-center mt-8 hover:bg-[#cfcfcf66] cursor-pointer "
                onClick={() => setShowModal(true)}
              />
              <div className="w-full flex items-center flex-wrap justify-center max-h-[620px] overflow-auto max-w-[1550px]">
                {allEducations?.map((education: EducationTypes) => {
                  return (
                    <div
                      className="flex max-w-[390px] w-[90%] lg:min-w-[390px] mx-8 mt-8 h-[278px] rounded-lg border justify-between border-blue-100 p-4 gap-4"
                      key={education.id}
                    >
                      <img
                        src={educationImg}
                        alt="education"
                        className="h-10 w-auto items-center flex-[0_0_15%] max-w-[15%] mt-1 object-contain"
                      />
                      <div className="flex flex-col pl-1 flex-[0_0_60%] max-w-[60%]">
                        <p className="font-bold text-xl mb-2">
                          {education.institution_name}
                        </p>
                        <p className="text-sm">
                          {education.degree && education.degree + "-"}{" "}
                          {education.area_of_study}
                        </p>
                        <p className="text-[#6e7081] text-sm">
                          {education.start_date?.substring(0, 4)} -{" "}
                          {education.end_date
                            ? education.end_date?.substring(0, 4)
                            : endingYear}
                        </p>
                        <p className="text-sm pt-2 overflow-hidden">
                          {education.description}
                        </p>
                      </div>
                      <div className="flex flex-col items-center gap-3 mt-2 flex-[0_0_15%] max-w-[15%]">
                        <img
                          src={edit}
                          alt="edit"
                          className="h-3 cursor-pointer"
                          onClick={() => {
                            handleEdit(education);
                            setTimeout(() => {
                              setShowEditEducationModal(true);
                            }, 150);
                          }}
                        />
                        <img
                          src={trash}
                          alt="trash"
                          className="h-5 cursor-pointer"
                          onClick={() => {
                            setEducation(education);
                            setShowDeleteModal(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {showEditEducationModal === true && (
          <ModalEditEducation
            refetch={getAllEducations}
            setShowEditEducationModal={setShowEditEducationModal}
            education={education}
            setEducation={setEducation}
            showEditEducationModal={showEditEducationModal}
          />
        )}
        {showModal === true && (
          <ModalAddEducation
            refetch={getAllEducations}
            setShowModal={setShowModal}
            showAddEducationModal={showModal}
            startingYear={startingYear}
            setStartingYear={setStartingYear}
            endingYear={endingYear}
            setEndingYear={setEndingYear}
          />
        )}
        {showDeleteModal === true && (
          <ModalDeleteEducation
            education={education}
            // handleRemove={removeEducation}
            setShowModal={setShowDeleteModal}
            title={t("worker.profile.modal_education.title")}
            handleNoMoreLocal={handleNoMoreLocal}
          />
        )}
        <ProgressRegistration
          disabled={
            checked === false && allEducations.length === 0 ? true : false
          }
          backButton={"create-profile/employment"}
          nextButtonText={t("createprofile.education.rightBtn")}
          onNextClick={onNextClick}
          progress="36"
        />
      </div>
    </div>
  );
}

export default AddEducation;
