import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useAtom } from "jotai";
import { selectedApplicantsSection } from "../../Atoms/jotaiAtoms";
import { useTranslation } from "react-i18next";
import { useEmployerStore } from "src/Routing/useProfileStore";
import { useQuery } from "react-query";

interface ProgressBarOfferProps {
  props: string;
  jobData: string;
}

const ProgressBarOffer = ({ props, jobData }: ProgressBarOfferProps) => {
  const [
    applicantsNumber,
    setApplicantsNumber,
    hiredNumber,
    setHiredNumber,
    offersNumber,
    setOffersNumber,
  ] = useEmployerStore((state) => [
    state.applicantsNumber,
    state.setApplicantsNumber,
    state.hiredNumber,
    state.setHiredNumber,
    state.offersNumber,
    state.setOffersNumber,
  ]);
  const [viewHover, setViewHover] = useState(false);
  const [inviteHover, setInviteHover] = useState(false);
  const [reviewHover, setReviewHover] = useState(false);
  const [offerHover, setOfferHover] = useState(false);
  const [hireHover, setHireHover] = useState(false);
  const [selectedSection, setSelectedSection] = useAtom(
    selectedApplicantsSection
  );
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const getApplicants = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=not archived`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: applicantsData } = useQuery(["applicants"], getApplicants);

  useEffect(() => {
    setApplicantsNumber(applicantsData?.length);
  }, [applicantsData]);

  const getOffers = async () => {
    const data = await fetch(
      `${BASE_URL}employer.hired.applicants?client_id=${userID}&job_id=${jobID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: offersData } = useQuery(["offers"], getOffers);

  useEffect(() => {
    setOffersNumber(offersData?.hiredApplicantsByStatus?.length ?? 0);
  }, [offersData]);

  const getHired = async () => {
    const data = await fetch(
      `${BASE_URL}worker.employer.connection?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: hiresData } = useQuery(["hires"], getHired);

  useEffect(() => {
    setHiredNumber(parseInt(hiresData?.numberOfActiveConnections));
  }, [hiresData]);

  return (
    <div>
      <div className="flex justify-between h-16 mt-24 lg:mt-16  text-sm bg-white text-center items-center shadow rounded cursor-pointer">
        {/* VIEW JOB */}
        <div
          // flex-[0_0_20%] max-w-[20%]
          className={`
          flex-[0_0_20%]
          max-w-[20%]
          transition
           ${
             props === "view"
               ? "bg-azulBonico text-white"
               : "bg-white text-black"
           }  h-full rounded-l  text-center font-medium `}
          onClick={() => {
            if (jobData !== "Closed") navigate("/applicants/view-job-post");
          }}
          onMouseEnter={() => setViewHover(true)}
          onMouseLeave={() => setViewHover(false)}
        >
          <div className="relative ">
            <p className="absolute left-[30%] lg:left-[45%] -translate-x-[50%] top-[16px] lg:top-[22px] text-[10px] lg:text-sm  font-semibold lg:font-bold">
              {t("employer.search_worker.view_job").toUpperCase()}
            </p>

            {/* </p> */}
          </div>
          <p
            className={`h-full  flex items-center justify-center transition ${
              viewHover === true && " border-y-2 border-l-2 border-azulBonico"
            } `}
          ></p>
        </div>

        {/* ////////////////////////////////////////////////////////////////// */}
        {/* INVITE  */}
        {/* triangulo superior invite */}
        <div
          className={`relative   transition ${
            jobData === "Closed" && "cursor-not-allowed"
          } `}
        >
          <div
            onMouseEnter={() => setInviteHover(true)}
            onMouseLeave={() => setInviteHover(false)}
            className="w-8  overflow-hidden inline-block absolute -top-[31.8px] right-[0px]"
            // className="w-8  overflow-hidden inline-block absolute -top-8 right-[0px]"
          >
            <div
              className={`h-16 ${
                props === "invite" && jobData !== "Closed"
                  ? "bg-azulBonico"
                  : "bg-white"
              } ${
                jobData === "Closed" && "!bg-[#EBEBE4]"
              }   -rotate-45 transform origin-top-left`}
            ></div>
          </div>
          {/* borde triangulo view-invite */}
          <div
            className={`w-[46px] overflow-hidden inline-block absolute -right-[13px] -top-[31.8px]`}
          >
            <div
              onMouseEnter={() => setInviteHover(true)}
              onMouseLeave={() => setInviteHover(false)}
              className={` h-16 ${
                props === "view" ? "bg-azulBonico" : "bg-white"
              }   border-azulBonico ${
                ((inviteHover === true && jobData !== "Closed") ||
                  viewHover === true) &&
                props !== "invite" &&
                "border-r-2 border-t-2"
              } ${
                jobData !== "Closed" && "border-r border-t"
              }  rotate-45 transform origin-top-left`}
            ></div>
          </div>
        </div>
        {/* triangulo inferior invite */}
        <div
          className={`relative  transition ${
            jobData === "Closed" && "cursor-not-allowed"
          }`}
        >
          <div
            onMouseEnter={() => setInviteHover(true)}
            onMouseLeave={() => setInviteHover(false)}
            className={`w-8 absolute overflow-hidden inline-block -top-[31.8px] right-[0px]`}
          >
            <div
              className={`h-16 transition ${
                props === "invite" && jobData !== "Closed"
                  ? "bg-azulBonico"
                  : "bg-white"
              } ${
                jobData === "Closed" && "!bg-[#EBEBE4]"
              } rotate-45 transform origin-bottom-left`}
            ></div>
          </div>
        </div>
        {/* bordes invite en hover */}
        <div
          className={`relative transition ${
            inviteHover === false && "hidden"
          } `}
        >
          <div
            className={`${
              jobData !== "Closed" && "border-t-2 border-azulBonico"
            } h-fit w-[32.5px]  -right-0 -top-[32px] absolute`}
          ></div>
        </div>
        <div className={`relative  ${inviteHover === false && "hidden"} `}>
          <div
            className={`${
              jobData !== "Closed" && "border-t-2 border-azulBonico"
            } h-fit w-[32.2px]  right-0 top-[30.5px] absolute`}
          ></div>
        </div>

        <div
          className={`flex-[0_0_20%] transition  flex items-center justify-center ${
            jobData === "Closed" && "!cursor-not-allowed"
          } max-w-[20%]  h-full  ${
            inviteHover === true &&
            props !== "invite" &&
            jobData !== "Closed" &&
            "border-y-2 border-azulBonico"
          } ${
            props === "invite" && jobData !== "Closed"
              ? "bg-azulBonico text-white"
              : "bg-white text-black"
          } ${jobData === "Closed" && "!bg-[#EBEBE4]"} `}
          onClick={() => {
            if (jobData !== "Closed") navigate("/applicants/invite");
          }}
          onMouseEnter={() => setInviteHover(true)}
          onMouseLeave={() => setInviteHover(false)}
        >
          <p className=" font-semibold -ml-5 lg:font-bold lg:-ml-10 text-[10px] lg:text-sm">
            {" "}
            {t("employer.search_worker.invite_worker").toUpperCase()}
          </p>
        </div>
        {/* <div className={`flex-[0_0_20%] max-w-[20%]  h-full`}></div> */}

        {/* ///////////////////////////////////////////////////////////////////////// */}
        {/* REVIEW */}
        <div className="relative">
          <div
            onMouseEnter={() => setReviewHover(true)}
            onMouseLeave={() => setReviewHover(false)}
            className="w-8  overflow-hidden inline-block absolute -top-[31.8px] right-[0px]"
          >
            <div
              className={`h-16 transition ${
                props === "review" ? "bg-azulBonico" : "bg-white"
              } -rotate-45 transform origin-top-left
              `}
            ></div>
          </div>
          {/* triangulo entre invite-review */}
          <div
            className={`w-[46px] overflow-hidden inline-block absolute -right-[13px] -top-[33.2px]`}
          >
            <div
              onMouseEnter={() => setInviteHover(true)}
              onMouseLeave={() => setInviteHover(false)}
              className={` h-16 transition ${
                props === "invite" && jobData !== "Closed"
                  ? "bg-azulBonico"
                  : "bg-white"
              } ${
                jobData === "Closed"
                  ? "!bg-[#EBEBE4] !cursor-not-allowed"
                  : "border-azulBonico"
              }  ${
                (reviewHover === true ||
                  (inviteHover === true && jobData !== "Closed")) &&
                props !== "review"
                  ? "border-r-2 border-t-2 border-azulBonico"
                  : "border-r border-t"
              }   rotate-45 transform origin-top-left`}
            ></div>
          </div>
        </div>
        <div className="relative">
          <div
            onMouseEnter={() => setReviewHover(true)}
            onMouseLeave={() => setReviewHover(false)}
            className="w-8 absolute overflow-hidden inline-block -top-8 right-[0px]"
          >
            <div
              className={`h-16 transition ${
                props === "review" ? "bg-azulBonico" : "bg-white"
              }  rotate-45 transform origin-bottom-left`}
            ></div>
          </div>
        </div>
        <div
          className={`relative transition ${
            reviewHover === false && "hidden"
          } `}
        >
          <div className="border-t-2 border-azulBonico h-fit w-[32.5px]  -right-0 -top-[32px] absolute"></div>
        </div>
        <div
          className={`relative transition ${
            reviewHover === false && "hidden"
          } `}
        >
          <div className="border-t-2 border-azulBonico h-fit w-[32.2px]  right-0 top-[30.5px] absolute"></div>
        </div>
        {/* <div
          className="relative "
          onMouseEnter={() => setReviewHover(true)}
          onMouseLeave={() => setReviewHover(false)}
          onClick={() => navigate("/applicants/review")}
        >
          <p
            className={`absolute   left-[30px] -top-[9px] whitespace-nowrap font-bold ${
              props === "review" && "text-white"
            }`}
          >
            {t("employer.search_worker.number_applicants").toUpperCase()}{" "}{applicantsNumber}
          </p>
        </div> */}
        <div
          className={`flex-[0_0_20%] h-full flex items-center justify-center  transition ${
            reviewHover === true &&
            props !== "review" &&
            "border-y-2 border-azulBonico"
          } ${
            props === "review"
              ? "bg-azulBonico text-white"
              : "bg-white text-black"
          } `}
          onClick={() => {
            if (jobData !== "Closed") navigate("/applicants/review");
          }}
          onMouseEnter={() => setReviewHover(true)}
          onMouseLeave={() => setReviewHover(false)}
        >
          {/* <p className=" mt-5 font-bold">REVIEW APPLICANTS ({numberOfApplicants})</p> */}
          <p
            className="text-[10px] lg:text-sm ml-2 lg:-ml-5 font-semibold lg:font-bold
          "
          >
            {t("employer.search_worker.number_applicants").toUpperCase()} (
            {applicantsNumber})
          </p>
        </div>
        <div
          className={`relative  ${
            jobData === "Closed" && "cursor-not-allowed"
          } `}
        >
          <div
            onMouseEnter={() => setReviewHover(true)}
            onMouseLeave={() => setReviewHover(false)}
            className="w-8 overflow-hidden inline-block absolute -top-8 right-[0px]"
          >
            <div
              className={` ${
                props === "offers" && jobData !== "Closed"
                  ? "bg-azulBonico"
                  : "bg-white"
              } h-16 ${
                jobData === "Closed" && "!bg-[#EBEBE4]"
              }  -rotate-45 transform origin-top-left`}
            ></div>
          </div>
          <div
            className={`w-[46px]  overflow-hidden inline-block absolute -right-[13.7px] -top-[32.35px]`}
          >
            <div
              onMouseEnter={() => setReviewHover(true)}
              onMouseLeave={() => setReviewHover(false)}
              className={` h-16 border-azulBonico ${
                props === "review" ? "bg-azulBonico" : "bg-white"
              } ${
                ((offerHover === true && jobData !== "Closed") ||
                  reviewHover === true) &&
                "border-r-2 border-t-2"
              }
                  ${jobData !== "Closed" && "border-r border-t"}
                 rotate-45 transform origin-top-left`}
            ></div>
          </div>
        </div>
        {/* ///////////////////////////////////////////////////////// */}
        {/* OFFERS */}
        <div
          className={`${jobData === "Closed" && "cursor-not-allowed"} relative`}
        >
          <div
            onMouseEnter={() => setOfferHover(true)}
            onMouseLeave={() => setOfferHover(false)}
            className="w-8 absolute overflow-hidden inline-block -top-8 right-[0px]"
          >
            <div
              className={` h-16 ${
                props === "offers" && jobData !== "Closed"
                  ? "bg-azulBonico"
                  : "bg-white"
              } ${
                jobData === "Closed" && "!bg-[#ebebe4]"
              }  rotate-45 transform origin-bottom-left`}
            ></div>
          </div>
        </div>
        <div className={`relative ${offerHover === false && "hidden"} `}>
          <div
            className={`${
              jobData !== "Closed" && "border-t-2 border-azulBonico"
            } h-fit w-[32.5px]  -right-0 -top-[32px] absolute`}
          ></div>
        </div>
        <div className={`relative ${offerHover === false && "hidden"} `}>
          <div
            className={`${
              jobData !== "Closed" && "border-t-2 border-azulBonico transition"
            } h-fit w-[32.2px]  right-0 top-[30.5px] absolute`}
          ></div>
        </div>
        {/* <div
          className={`relative ${
            jobData === "Closed" && "!cursor-not-allowed"
          }`}
          onMouseEnter={() => setOfferHover(true)}
          onMouseLeave={() => setOfferHover(false)}
          onClick={() => {
            if (jobData !== "Closed") navigate("/applicants/offers");
          }}
        >
          <p
            className={`absolute left-[75px] -top-[9px] whitespace-nowrap font-bold ${
              props === "offers" && "text-white"
            }`}
          >
            {t("employer.search_worker.offers").toUpperCase()}{" "}
            {offersNumber > 0 && `(${offersNumber})`}
          </p>
        </div> */}
        <div
          className={`flex-[0_0_20%]  h-full flex items-center justify-center transition ${
            jobData === "Closed" && "!cursor-not-allowed"
          } max-w-[20%]  h-full  ${
            offerHover === true &&
            props !== "offers" &&
            jobData !== "Closed" &&
            "border-y-2 border-azulBonico"
          } ${
            props === "offers" && jobData !== "Closed"
              ? "bg-azulBonico text-white"
              : "bg-white text-black"
          } ${jobData === "Closed" && "!bg-[#EBEBE4]"} `}
          onClick={() => {
            if (jobData !== "Closed") navigate("/applicants/offers");
          }}
          onMouseEnter={() => setOfferHover(true)}
          onMouseLeave={() => setOfferHover(false)}
        >
          <p className="font-semibold text-[10px] lg:text-sm lg:font-bold -ml-5 lg:-ml-10">
            {t("employer.search_worker.offers").toUpperCase()}
            {offersNumber > 0 && ` (${offersNumber})`}
          </p>
        </div>
        <div className="relative">
          <div
            onMouseEnter={() => setOfferHover(true)}
            onMouseLeave={() => setOfferHover(false)}
            className="w-8 overflow-hidden inline-block absolute -top-8 right-[0px]"
          >
            <div
              className={` ${
                props === "hire" ? "bg-azulBonico" : "bg-white"
              } h-16   -rotate-45 transform origin-top-left`}
            ></div>
          </div>
          <div
            className={`w-[46px]  overflow-hidden inline-block absolute -right-[13.7px] -top-[32.35px]`}
          >
            <div
              onMouseEnter={() => setOfferHover(true)}
              onMouseLeave={() => setOfferHover(false)}
              className={` h-16 border-azulBonico transition ${
                props === "offers" && jobData !== "Closed"
                  ? "bg-azulBonico"
                  : "bg-white"
              } ${
                jobData === "Closed"
                  ? "!bg-[#EBEBE4] !cursor-not-allowed"
                  : "border-azulBonico border-r border-t"
              } ${
                ((offerHover === true && jobData !== "Closed") ||
                  hireHover === true) &&
                props !== "offers" &&
                "border-r-2 border-t-2"
              }   rotate-45 transform origin-top-left`}
            ></div>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////// */}
        {/* HIRES */}
        <div
          className={`relative ${
            jobData === "Closed" && "cursor-not-allowed"
          } `}
        >
          <div
            onMouseEnter={() => setHireHover(true)}
            onMouseLeave={() => setHireHover(false)}
            className="w-8 absolute overflow-hidden inline-block -top-8 right-[0px]"
          >
            <div
              className={` h-16 transition ${
                props === "hire" ? "bg-azulBonico" : "bg-white"
              }  rotate-45 transform origin-bottom-left`}
            ></div>
          </div>
        </div>
        <div
          className={`relative transition ${hireHover === false && "hidden"} `}
        >
          <div className="border-t-2 border-azulBonico h-fit w-[36.5px]  -right-1 -top-[32px] absolute"></div>
        </div>
        <div
          className={`relative transition ${hireHover === false && "hidden"} `}
        >
          <div className="border-t-2 border-azulBonico h-fit w-[32.2px]  right-0 top-[30.5px] absolute"></div>
        </div>
        <div
          className={`h-full rounded-r flex-[0_0_20%] max-w-[20%] items-center transition  ${
            hireHover === true && " border-y-2 border-r-2 border-azulBonico "
          } ${
            props === "hire"
              ? "bg-azulBonico text-white"
              : "bg-white text-black"
          } `}
          onClick={() => {
            if (jobData !== "Closed") {
              navigate("/applicants/hired");
              setSelectedSection("offers");
            }
          }}
          onMouseEnter={() => setHireHover(true)}
          onMouseLeave={() => setHireHover(false)}
        >
          <div className="relative flex items-center justify-center  h-full ">
            {/* <p className="absolute left-[50%] -translate-x-[50%] top-[22px] font-bold">
              {t("employer.search_worker.hire").toUpperCase()}{" "}
              {hiredNumber > 0 && `(${hiredNumber})`}
            </p> */}
            <p
              className={`font-semibold lg:font-bold lg:-ml-6 text-[10px] lg:text-sm -ml-2`}
            >
              {t("employer.search_worker.hire").toUpperCase()}{" "}
              {hiredNumber > 0 && `(${hiredNumber})`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBarOffer;
