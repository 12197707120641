export const StylesModal: any = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    zIndex: 200,


  },
  content: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "100%",
    left: "50%",
    right: "50%",
    overflow: "auto",
    border: "none",
    background: "#fff",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    backgroundColor: "transparent",

  },
};
