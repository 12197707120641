import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
// import Modal from "react-modal";
import useLocalStorage from "src/Hooks/UseLocalStorage";
// import { StylesModal } from "src/Utils/stylesModal";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { ReactComponent as Dot } from "../../assets/img/dot.svg";

import { GrDocumentLocked } from "react-icons/gr";
import { HiOutlineExclamation } from "react-icons/hi";

interface ModalCloseAccountProps {
  showCloseAccountModal: boolean;
  setShowCloseAccountModal: Dispatch<SetStateAction<boolean>>;
}

const ModalCloseAccount = ({
  showCloseAccountModal,
  setShowCloseAccountModal,
}: ModalCloseAccountProps) => {
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const [closeFurtherConfirmation, setCloseFurtherConfirmation] =
    useState(false);
  const [closeFinalConfirmation, setCloseFinalConfirmation] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showCloseAccountModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showCloseAccountModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowCloseAccountModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-[80%] lg:w-[22%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="py-2 px-4 flex justify-between ">
            <div className="text-[15px] font-semibold ">
              {t("modal.close_account.title")} / {userInfo.first_name}{" "}
              {userInfo.last_name}
            </div>
            <div
              onClick={() => setShowCloseAccountModal(!showCloseAccountModal)}
              className="h-5 w-5 items-center justify-center flex cursor-pointer hover:bg-gray-200 rounded transition-all transform duration-150 ease-in-out"
            >
              <Cross className="h-4 w-4 text-gray-500" />
            </div>
          </div>
          <div className="py-5 flex flex-col items-center justify-center border-t border-b">
            <div className="py-2">
              <GrDocumentLocked size={20} color="#6E7781" />
            </div>
            <h1 className="font-medium">
              {userInfo.first_name} {userInfo.last_name}
            </h1>
            {closeFurtherConfirmation && !closeFinalConfirmation && (
              <div className="px-4 py-2 border-b">
                <div className="flex items-center px-4 py-4 bg-yellow-100 border-orange-200 border rounded">
                  <HiOutlineExclamation
                    size={20}
                    color="red"
                    className="h-5 w-5"
                  />
                  <p className="text-[13px] ml-4 text-gray-600">
                    {t("settings.modal.close_account.desc")}
                  </p>
                </div>
                <div className="relative my-4 ">
                  <Dot className="h-2 w-2 fill-gray-400 absolute left-[5px] z-10 top-2" />
                  <p className="text-[13px] text-gray-500 before:content-[''] before:bottom-0 before:left-2 before:block before:absolute before:top-0 before:w-[2px] before:bg-gray-300 pl-6">
                    {t("employer.modal_delete.warning2.part1")}{" "}
                    {userInfo.first_name} {userInfo.last_name}{" "}
                    {t("employer.modal_delete.warning2.part2")}
                  </p>
                </div>
              </div>
            )}
          </div>
          {closeFinalConfirmation && (
            <div className="px-4 py-2 mt-2">
              <div>
                <p className="text-[13px] font-medium cursor-default">
                  {t("modal.close_account.enter_password")}
                </p>
                <input
                  type="password"
                  className="border border-slate-200 w-full rounded mt-3 h-8 outline-none hover:border-azulBonico focus:border-azulBonico px-3 transition duration-150"
                ></input>
              </div>
            </div>
          )}

          <div className="flex flex-col items-center justify-center py-2 px-4">
            {!closeFurtherConfirmation && !closeFinalConfirmation && (
              <button
                onClick={() =>
                  setCloseFurtherConfirmation(!closeFurtherConfirmation)
                }
                className="bg-gray-100  w-full text-[13px] font-medium border rounded px-4 py-1  hover:bg-gray-200  transition-all transform duration-150 ease-in-out  border-red-200"
              >
                {t("settings.modal.close_account.close_button")}
              </button>
            )}

            {closeFurtherConfirmation && !closeFinalConfirmation && (
              <button
                className="bg-gray-100  w-full text-[13px] font-medium border rounded px-4 py-1  hover:bg-gray-200  transition-all transform duration-150 ease-in-out  border-red-200"
                onClick={() => {
                  setCloseFurtherConfirmation(false);
                  setCloseFinalConfirmation(!closeFinalConfirmation);
                }}
              >
                {t("settings.modal.close_account.close_button.further_info")}
              </button>
            )}
            {closeFinalConfirmation && (
              <>
                <button className="bg-gray-100  w-full text-[13px] font-medium border rounded px-4 py-1    transition-all transform duration-150 ease-in-out  border-red-200 ">
                  {t("settings.modal.close_account.close_button.final_info")}
                </button>
              </>
            )}
          </div>

          {/* <div className="text-sm px-8 py-6">
            <p>{t("modal.close_account.thanks")}</p>
            <p>{t("modal.close_account.enter_password")}</p>
            <input
              type="password"
              className="border border-slate-200 w-full rounded mt-3 h-8 outline-none hover:border-azulBonico focus:border-azulBonico px-3 transition duration-150"
            ></input>
            <div className="flex justify-around mt-5">
              <button
                className="px-8 py-1.5 text-azulBonico hover:bg-[#f7f4f4] border border-azulBonico bg-white rounded-full transition duration-300"
                disabled
              >
                {t("modal.close_account.title")}
              </button>
              <button
                className="px-14 py-1.5 text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                onClick={() => closeModal()}
              >
                {t("modal.close_account.cancel")}
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ModalCloseAccount;
