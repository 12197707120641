import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "../translations/en/translation.json";
import translationES from "../translations/es/translation.json";
import translationPT from "../translations/pt/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  pt: {
    translation: translationPT,
  },
};

export const init_i18n = () => {
  i18n

    .use(LanguageDetector)
    .use(initReactI18next)

    .init({
      resources,
      fallbackLng: "es",
    });
};
