import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface JobEditAdvancedPreferencesProps {
  hireDate?: string;
  setHireDate?: Dispatch<SetStateAction<string>>;
  expireDate: string;
  setExpireDate: Dispatch<SetStateAction<string>>;
  workersNeeded: string;
  setWorkersNeeded: Dispatch<SetStateAction<string>>;
}

const JobEditAdvancedPreferences = ({
  hireDate,
  setHireDate,
  expireDate,
  setExpireDate,
  setWorkersNeeded,
  workersNeeded,
}: JobEditAdvancedPreferencesProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`text-sm px-8 flex lg:flex-row flex-col pb-6 gap-4 lg:gap-48 transition `}
    >
      {/* <div className="flex flex-col gap-2">
        <p className="font-medium mb-1">
          {t("employer.job_post_edit.due_days")}
        </p>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="7"
            checked={hireDate === "7"}
            onChange={() => setHireDate("7")}
          />
          {t("employer.job_post_edit.oneweek")}
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="14"
            checked={hireDate === "14"}
            onChange={() => setHireDate("14")}
          />
          {t("employer.job_post_edit.twoweeks")}
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="30"
            checked={hireDate === "30"}
            onChange={() => setHireDate("30")}
          />
          {t("employer.job_post_edit.onemonth")}
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="90"
            checked={hireDate === "90"}
            // defaultChecked={jobInfo?.due_days === "90" && true}
            onChange={() => setHireDate("90")}
          />
          {t("employer.job_post_edit.three_months")}
        </label>
      </div> */}
      <div className="flex flex-col gap-2">
        <p className="font-medium mb-1">
          {t("employer.job_post_edit.expire_offer_date")}
        </p>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="1"
            checked={expireDate === "1"}
            onChange={() => setExpireDate("1")}
          />
          {t("employer.job_post_edit.one_day")}
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="3"
            checked={expireDate === "3"}
            onChange={() => setExpireDate("3")}
          />
          {t("employer.job_post_edit.three_days")}
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="7"
            checked={expireDate === "7"}
            onChange={() => setExpireDate("7")}
          />
          {t("employer.job_post_edit.one_week")}
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="14"
            checked={expireDate === "14"}
            onChange={() => setExpireDate("14")}
          />
          {t("employer.job_post_edit.two_weeks")}
        </label>
      </div>
      <div className="flex flex-col gap-2">
        <p className="font-medium mb-1">
          {t("employer.job_post_edit.number_professionals")}
        </p>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            value="1"
            checked={workersNeeded === "01"}
            onChange={() => setWorkersNeeded("01")}
          />
          {t("employer.job_post_edit.one_person")}
        </label>
        <label className="flex items-center gap-2">
          <input
            defaultValue={"2"}
            type="radio"
            value="More"
            checked={workersNeeded === "2"}
            onChange={() => setWorkersNeeded("2")}
          />
          {t("employer.job_post_edit.more_than_one_person")}
        </label>
        {workersNeeded === "2" && (
          <input
            type="number"
            className="w-full outline-none border-2 border-blue-100 hover:border-blue-200 rounded-lg h-7 px-2 focus:border-blue-200"
            defaultValue={workersNeeded}
            onChange={(e) => setWorkersNeeded(e.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default JobEditAdvancedPreferences;
