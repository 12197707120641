import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";

import { useAllCountries } from "src/Hooks/useCountries";

function CountryPicker({ job, setJob, checking }) {
  const [country, setCountry] = useState();
  const [countryName, setCountryName] = useState();
  const [isHovering, setIsHovering] = useState(false);
  const [countries, setCountries] = useState();
  const [availableCountries, setAvailableCountries] = useState([]);
  const ref = useRef();
  const { countries: countriesData, refetchCountries } = useAllCountries();

  const handleCountry = (country) => {
    setCountry(country);
    setJob((job) => ({
      ...job,
      country_code: countriesData?.find(({ name }) => name === country)
        .country_code,
    }));
  };
  const handleCountryName = (countryCode) => {
    const countryName = countriesData?.find(
      ({ country_code }) => country_code === countryCode
    );
    setCountryName(countryName?.name);
  };

  useEffect(() => {
    if (job?.country_code?.length > 0) {
      handleCountryName(job.country_code);
    }
  }, []);

  const colourStyles = {
    control: (styles) => ({
      border: "1px solid #A4211B",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  const colourStyles2 = {
    control: (styles) => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    if (countriesData)
      setAvailableCountries(
        countriesData?.map((row) => {
          return {
            value: row.name,
            label: row.name,
          };
        })
      );
  }, []);

  useEffect(() => {
    setCountries(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (countries?.length < 1) {
      refetchCountries();
    }
  }, [countries]);

  const handleMouseEnter = () => {
    setIsHovering(true);
    setCountryName();
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div>
      <div className="relative mt-2" ref={ref}>
        {countryName && (
          <Select
            styles={
              checking === true && !country ? colourStyles : colourStyles2
            }
            className="w-full cursor-pointer"
            options={availableCountries}
            defaultValue={{ label: countryName, value: countryName }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onChange={(e) => {
              handleCountry(e.value);
            }}
            placeholder={"Country"}
          />
        )}
        {!countryName && (
          <Select
            styles={
              checking === true && !country ? colourStyles : colourStyles2
            }
            className="w-full cursor-pointer"
            options={availableCountries}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onChange={(e) => handleCountry(e.value)}
            placeholder={"Country"}
          />
        )}
      </div>
    </div>
  );
}

export default CountryPicker;
