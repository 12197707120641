import React from "react";
import { Skeleton } from "../skeleton";

const CreditSkeleton = () => {
  return (
    <div className="flex items-center justify-between bg-white px-8 py-3 border-t border-b dark:bg-background">
      <Skeleton className="w-10 h-3" />
      <Skeleton className="w-8 h-3" />
      <Skeleton className="w-20 h-4" />
      <Skeleton className="w-14 h-4" />
    </div>
  );
};

export default CreditSkeleton;
