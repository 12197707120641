import React, { Dispatch, SetStateAction, lazy } from "react";
import { useEffect, useState, useRef } from "react";

import Modal from "react-modal";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { JobTypes } from "src/types/types";
import { Button } from "../ui/Button";
import { BiErrorCircle } from "react-icons/bi";
import { toast } from "react-hot-toast";
import { cn } from "src/lib/utils";

interface ModalAddCoverLetterProps {
  showModal: { cover: boolean };
  setShowModal: Dispatch<
    SetStateAction<{ job: boolean; report: boolean; cover: boolean }>
  >;
  // loading: boolean;
  // setLoading: Dispatch<SetStateAction<boolean>>;
  toApplyOrNotToApply: (job: JobTypes, coverLetter: string) => void;
  job: JobTypes;
}

export const ModalAddCoverLetter = ({
  showModal,
  setShowModal,
  toApplyOrNotToApply,
  job,
}: ModalAddCoverLetterProps) => {
  const [transition, setTransition] = useState(false);
  const [coverLetter, setCoverLetter] = useState("");
  const [error, setError] = useState<boolean | string>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.cover && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.cover]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, cover: false }));
    }, 150);
  };
  useEffect(() => {
    // setLoading(false);
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (coverLetter) {
      setError(false);
    }
  }, [coverLetter]);

  return (
    <Modal isOpen={showModal.cover} ariaHideApp={false} style={StylesModal}>
      {/* {loading === false ? ( */}
      <div
        className={`fixed ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[90%] lg:w-auto  shadow-xl translate-x-[-50%] left-[50%] rounded top-[50%] translate-y-[-50%] bg-white `}
        ref={ref}
      >
        <div className="text-lg font-bold px-8 py-4">
          {t("worker.modal_cover_letter.apply_job")}
        </div>
        <div className="text-sm px-8">
          {t("worker.modal_cover_letter.desc")}
        </div>
        <div className="px-8 mt-2">
          <textarea
            value={coverLetter}
            maxLength={1000}
            onChange={(e) => {
              setCoverLetter(e.target.value);
            }}
            className={`w-full border h-[150px] text-sm px-3 py-2 outline-none rounded ${
              error ? "border-[#A7211B]" : "border-slate-300"
            } `}
            placeholder={`${t("worker.modal_cover_letter.placeholder")}...`}
          />
          <p className="text-sm text-right text-slate-500">
            {t("worker.modal_cover_letter.max")}{" "}
            {coverLetter ? 1000 - coverLetter.length : 1000}{" "}
            {t("worker.modal_cover_letter.characters_more")}
          </p>
          {error !== false && (
            <div className="flex  items-center">
              {/* <img
                  src={exclamation}
                  alt="exclamation"
                  className="h-4  mr-4"
                /> */}
              <BiErrorCircle className="h-4 w-4 mr-2" />
              <p className=" text-sm text-[#A7211B]">{error}</p>
            </div>
          )}
        </div>
        <div className="flex w-full h-full items-end p-3 gap-2 px-8 mt-2 justify-center">
          <Button
            className="bg-none text-sm flex-1 h-8 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro  mt-1 "
            onClick={() => {
              closeModal();
            }}
          >
            {t("worker.modal_cover_letter.cancel")}
          </Button>
          <Button
            disabled={coverLetter.length < 10}
            className={cn(
              `border-none flex-1 rounded-[20px] text-sm h-8  bg-azulBonico hover:bg-azulOscuro text-white `,
              coverLetter.length < 10 &&
                "bg-gray-200 hover:bg-gray-300 text-[#333] cursor-not-allowed"
            )}
            onClick={(e) => {
              if (coverLetter === "") {
                toast.error(`${t("worker.modal_apply_job.error")}`);
                setError(`${t("worker.modal_apply_job.error")}`);
              }
              if (coverLetter !== "") {
                toApplyOrNotToApply(job, coverLetter);
              }
              setShowModal((prev) => ({ ...prev, cover: false }));
            }}
          >
            {t("worker.modal_cover_letter.apply")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
