import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OnlyStars from "src/Components/Rating/OnlyStars";

import { ReviewTypes } from "src/types/types";

interface ReviewCardProps {
  review: ReviewTypes;
}

const ReviewCard = ({ review }: ReviewCardProps) => {
  const { i18n, t } = useTranslation();

  const [formattedStartDate, setFormattedStartDate] = useState<any>();
  const [contractStartDate, setContractStartDate] = useState<any>("");
  const [contractEndDate, setContractEndDate] = useState<any>("");
  const [formattedEndDate, setFormattedEndDate] = useState<any>();

  useEffect(() => {
    setContractStartDate(review.date_start.split("-"));

    if (review.date_early_end !== null) {
      setContractEndDate(review.date_early_end.split("-"));
    } else {
      setContractEndDate(review.date_end.split("-"));
    }
  }, [review]);

  useEffect(() => {
    setFormattedStartDate(
      `${new Date(
        contractStartDate[0],
        Number(contractStartDate[1]?.replace("0", "") - 1),
        contractStartDate[2]
      )}`
    );
  }, [contractStartDate]);

  useEffect(() => {
    setFormattedEndDate(
      `${new Date(
        contractEndDate[0],
        Number(contractEndDate[1]?.replace("0", "") - 1),
        contractEndDate[2]
      )}`
    );
  }, [contractEndDate]);

  return (
    <div
      key={review.job_title}
      className="flex  items-center justify-between border-y px-8 py-4 dark:border-border"
      //   className="flex flex-col gap-1 border-b border-gray-200 dark:border-border px-8 py-3"
    >
      <div className="flex flex-col lg:flex-row items-start lg:items-center w-full justify-between">
        <div className="flex items-center lg:w-[40%]">
          <div>
            <h3 className="text-sm text-">
              {t("employer.hires.diplay_hires.title")}
            </h3>
            <h2 className="font-semibold text-sm ">{review.job_title}</h2>
            <p className="text-[13px] mt-3 text-slate-500">
              {formattedStartDate?.slice(4, 10)} -{" "}
              {formattedEndDate?.slice(4, 10)}
            </p>
          </div>
        </div>
        <div className="lg:w-[10%]">
          <p className="text-sm text-gray-500">
            {t("employer.hires.diplay_hires.company")}
          </p>
          <p className="text-sm">
            {review.employer_first_name}{" "}
            {review.employer_last_name?.slice(0, 1)}.
          </p>
        </div>
        <div className="lg:w-[50%] lg:ml-10 ml-auto">
          <p>
            <span className="font-medium text-sm">
              {review.budget?.substring(0, 2) ?? "0"},00€
            </span>{" "}
            /h
          </p>
          <p className="text-sm text-[#333] mb-2">
            {t("employer.hires.diplay_hires.completed")}{" "}
            {/* {formattedEndDate?.slice(4, 10)} */}
          </p>
          <div className="flex flex-col items-start">
            <OnlyStars
              showHalf={true}
              activeStar={review.score_from_employer}
            />
            <p className="mt-1 italic text-[#333]">{review.employer_review}</p>
          </div>
        </div>
      </div>
      {/* <div className="flex items-center gap-1 font-medium">
        {review.score_from_employer !== "0.00" &&
          review.score_from_employer && (
            <div className="mt-[3px]">
              <RatingStars
                activeStar={review.score_from_employer}
                evaluation={false}
                score={"dont"}
                size={14}
              />
            </div>
          )}
        <p>{review.job_title}</p>
        <p>-</p>

        <p>
          {review.employer_first_name +
            " " +
            review.employer_last_name?.slice(0, 1)}
          .
        </p>
      </div>
      <div className="flex items-center gap-1">
        <p className="text-sm text-[#333]">
          <span className="font-medium">{review.budget}€</span>
          /h
        </p>
        <p className="text-xs text-slate-400">-</p>
        <p className="text-xs text-slate-400">
          {review.date_start?.slice(8, 10)}{" "}
          {i18n.language === "en"
            ? HandleMonthName(review.date_start?.slice(5, 7))
            : "de" +
              " " +
              HandleMonthNameES(review.date_start?.slice(5, 7))}{" "}
          &apos;{review.date_start?.slice(2, 4)} -{" "}
          {i18n.language === "en" ? (
            <>
              {review.date_early_end ? (
                <>
                  {review.date_early_end?.slice(8, 10)}{" "}
                  {HandleMonthName(review.date_early_end?.slice(5, 7))} &apos;
                  {review.date_early_end?.slice(2, 4)}
                </>
              ) : (
                <>
                  {review.date_end?.slice(8, 10)}{" "}
                  {HandleMonthName(review.date_end?.slice(5, 7))} &apos;
                  {review.date_end?.slice(2, 4)}
                </>
              )}
            </>
          ) : (
            <>
              {review.date_early_end ? (
                <>
                  el {review.date_early_end?.slice(8, 10)} de{" "}
                  {HandleMonthNameES(review.date_early_end?.slice(5, 7))} &apos;
                  {review.date_early_end?.slice(2, 4)}
                </>
              ) : (
                <>
                  el {review.date_end?.slice(8, 10)} de{" "}
                  {HandleMonthNameES(review.date_end?.slice(5, 7))} &apos;
                  {review.date_end?.slice(2, 4)}
                </>
              )}
            </>
          )}
        </p>
      </div>
      <p className="mt-1 italic">{review.employer_review}</p> */}
    </div>
  );
};

export default ReviewCard;
