import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { userProfileStore } from "../useProfileStore";

const ProtectedRoute = () => {
  const profileStore = userProfileStore((s) => s.profileTypeToken);

  if (profileStore == "" || profileStore === null) {
    return <Navigate to={"/login"} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
