import React from "react";

import { ReactComponent as Referral } from "../../assets/img/referral.svg";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { formatJobTitle } from "src/Utils/formatters";
import { JobTypes } from "src/types/types";
import { RiLoader2Fill } from "react-icons/ri";

interface WorkerClosedJobsProps {
  counter: { closed: number };
  jobs: {
    closed: JobTypes[];
  };
  isLoading: boolean;
}

const WorkerClosedJobs = ({
  counter,
  jobs,
  isLoading,
}: WorkerClosedJobsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full bg-white mt-4 border border-border rounded-lg flex flex-col dark:bg-background dark:border-border">
        <div className="flex gap-5 p-6 items-center">
          <p className="text-lg lg:text-xl font-semibold flex items-center">
            {t("worker.myjobs.body.appliedjobs.layer3.closeoffer")} (
            <p>
              {isLoading ? (
                <span>
                  <RiLoader2Fill className="h-4 w-4 animate-spin" />
                </span>
              ) : (
                <>{counter?.closed}</>
              )}
            </p>
            )
          </p>
        </div>
        {jobs?.closed?.length > 0 ? (
          <>
            {jobs.closed?.map((job) => {
              return (
                <div
                  key={job.job_id}
                  className="border-t border-slate-200 dark:border-border"
                >
                  <div className="py-4 px-6 flex justify-between">
                    <p className="text-sm tracking-wider text-azulBonico font-semibold">
                      {formatJobTitle(job.title)}
                    </p>
                    <div className="flex-[0_0_40%] flex-col text-sm tracking-wider text-right">
                      <p className="text-xs lg:text-sm text-gray-500">
                        {/* {job.date_expire_job} */}
                        {new Date(job.date_expire_job).toLocaleDateString()}
                      </p>
                      {/* <p>Declined on</p> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="bg-white mt-4 p-6 border  justify-center border-slate-200 rounded-lg flex flex-col items-center gap-5">
            <Referral className="h-48" />
            <p className="font-semibold tracking-wide mt-9">
              {t("employer.closed_jobs.message")}
            </p>
            <p className="text-sm tracking-wide text-center">
              {parse(t("employer.closed_jobs.message.desc"))}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkerClosedJobs;
