import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import Select from "react-select";

import { useAtom } from "jotai";
import { categoryIdInvite, categoryInvite } from "src/Atoms/jotaiAtoms";
import { getJobCateogries } from "src/Hooks/useApplicationProcess";
import { InitialStateJobType } from "./JobPostEdit";

interface CategorySearchProps {
  title: string;
  jobInfo: InitialStateJobType;
  setJobInfo: Dispatch<SetStateAction<InitialStateJobType>>;
  back: boolean;
  categoryID: string;
  setCategoryID: Dispatch<SetStateAction<string>>;
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
}

export default function CategorySearch({
  title,
  jobInfo,
  setJobInfo,
  back,
  selectedCategory,
  setSelectedCategory,
  categoryID,
  setCategoryID,
}: CategorySearchProps) {
  const [categorySearch, setCategorySearch] = useState<
    { name: string; category_id: string }[]
  >([]);
  const [previousCategory, setPreviousCategory] = useState<{
    category_name: string;
    category_id: string;
  }>({ category_id: "", category_name: "" });
  const [auxiliar, setAuxiliar] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [category, setCategory] = useAtom(categoryInvite);
  const [categoryId, setCategoryId] = useAtom(categoryIdInvite);
  const { isCategoriesLoading, jobCategoryData } = getJobCateogries();

  useEffect(() => {
    setCategorySearch(jobCategoryData);
  }, [jobCategoryData]);

  const availableCategories = categorySearch?.map(function (row) {
    return { value: row.name, label: row.name, id: row.category_id };
  });
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      //   width: "250px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    if (jobInfo) {
      setPreviousCategory((prev) => ({
        ...prev,
        category_id: jobInfo?.category_id,
        category_name: jobInfo?.category_name,
      }));
    } else if (selectedCategory) {
      setPreviousCategory((prev) => ({
        ...prev,
        category_id: categoryID,
        category_name: selectedCategory,
      }));
    }
  }, []);
  useEffect(() => {
    if (back === false) {
      if (jobInfo) {
        setJobInfo((prev) => ({
          ...prev,
          category_name: previousCategory.category_name,
          category_id: previousCategory.category_id,
        }));
      } else if (selectedCategory) {
        setSelectedCategory(previousCategory.category_name);
        setCategoryID(previousCategory.category_id);
      }
    }
  }, [back]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (categorySearch && ref.current && !ref.current.contains(e.target)) {
        setCategorySearch([]);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [categorySearch]);

  return (
    <div className="p-8">
      <p className="mb-4 font-bold">{title}</p>

      {auxiliar === false ? (
        <Select
          placeholder={category}
          options={availableCategories}
          styles={colourStyles}
          value={{
            label: previousCategory?.category_name || category,
            value: previousCategory?.category_name || category,
          }}
          onChange={(e: any) => {
            setCategory(e.value);
            setCategoryId(e.id);
            if (jobInfo) {
              setJobInfo((prev) => ({
                ...prev,
                category_name: e.value,
                category_id: e.id,
              }));
            }
            if (selectedCategory) {
              setSelectedCategory(e.value);
              setCategoryID(e.id);
            }
            setAuxiliar(true);
            // handleCategory(e.target.innerText);
            // whatID(e);
          }}
          // className="jobpost"
        />
      ) : (
        <Select
          options={availableCategories}
          styles={colourStyles}
          // onChange={(e) => setSelectedCategory(e)}
          onChange={(e: any) => {
            if (jobInfo) {
              setJobInfo((prev) => ({
                ...prev,
                category_name: e.value,
                category_id: e.id,
              }));
            }
            if (selectedCategory) {
              setSelectedCategory(e.value);
              setCategoryID(e.id);
            }
            // handleCategory(e.target.innerText);
            // whatID(e);
          }}
          // className="jobpost"
        />
      )}

      {/* <div className="relative" ref={ref}>
        <input
          className="px-2 hover:border-azulBonico border w-full text-sm border-blue-100  rounded h-10 outline-none focus:border-azulBonico transition duration-300"
          placeholder="Search category..."
          value={selectedCategory}
          onChange={(e) => searchItems(e.target.value)}
        ></input>
        <Search
          className="h-5 absolute right-4 top-2.5 cursor-pointer"
          onClick={() => jobCategories()}
        />

        {categorySearch && (
          <div className="absolute top-10 jobpost overflow-auto text-sm border border-blue-100 rounded bg-white w-full max-h-40">
            <ul>
              {categorySearch?.length === 0 && (
                <div className="wrong-container">
                  <img className="exclamation" src={exclamation} alt="hey" /> No
                  results found... Please, try again!
                </div>
              )}
              {categorySearch?.map((category) => {
                return (
                  <li
                    className="hover:bg-blue-100 rounded p-1 cursor-pointer"
                    key={category.name}
                    onClick={(e) => {
                      if (jobInfo) {
                        setJobInfo((prev) => ({
                          ...prev,
                          category_name: category.name,
                          category_id: category.category_id,
                        }));
                      }
                      handleCategory(e.target.innerText);
                      whatID(e);
                    }}
                  >
                    {category.name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div> */}
      {/* )} */}
    </div>
  );
}
