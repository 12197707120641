import React, { Dispatch, SetStateAction } from "react";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

interface ModalBasicProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  title: string;
  content: string;
  handleContinue: () => void;
}

const ModalBasic = ({
  showModal,
  setShowModal,
  title,
  content,
  handleContinue,
}: ModalBasicProps) => {
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-[80%] lg:w-[22%] max-w-auto max-h-auto left-[50%] shadow-xl translate-x-[-50%] rounded-xl top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="text-lg font-bold px-8 py-4">{title}</div>
          <div className="text-sm px-8">{content}</div>
          <div className="flex w-full h-full items-end p-3 gap-2 px-8 mt-2 justify-center">
            <button
              className="bg-none text-sm font-semibold flex-1 h-8 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro cursor-pointer mt-1 transition duration-300"
              onClick={() => {
                closeModal();
              }}
            >
              {t("employer.modal.start_chat.cancel")}
            </button>
            <button
              className={`border-none font-semibold flex-1 rounded-[20px] text-sm  transition duration-300 h-8  bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer`}
              onClick={(e) => {
                handleContinue();
                closeModal();
              }}
            >
              {t("employer.modal.start_chat.continue")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalBasic;
