export const days = [
  {
    label: "Monday",
    value: "Mon",
  },
  {
    label: "Tuesday",
    value: "Tue",
  },
  {
    label: "Wednesday",
    value: "Wed",
  },
  {
    label: "Thursday",
    value: "Thu",
  },
  {
    label: "Friday",
    value: "Fri",
  },
  {
    label: "Saturday",
    value: "Sat",
  },
  {
    label: "Sunday",
    value: "Sun",
  },
];
