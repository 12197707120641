import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import Select from "react-select";

import JobCards from "../WorkerDashboard/JobCards/JobCards";
import JobCardsCompact from "../WorkerDashboard/JobCards/JobCardsCompact";

import SavedJobs from "./SavedJobs";

import Filters from "./Filters";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, JOBS, SAVED_JOBS_WORKER } from "../../Config/api.config";
import ModalSaveJobSearch from "../Modals/ModalSaveJobSearch";
import SearchSavedNavbar from "./SearchSavedNavbar";
import {
  allFiltersAppliedToJobSearch,
  categoryFilterOptions,
  cityFilterOptions,
  counterSaved,
  jobFeedbackOptions,
  jobOfferAtom,
  myCurrentPage,
  numberOffersPerPage,
  postcodeFilterOptions,
  showSavedJobsWorker,
  skillFilterOptions,
} from "../../Atoms/jotaiAtoms";
import { ReactComponent as Banner } from "../../assets/img/jobsSearchWorkerBanner.svg";
import { ReactComponent as Search } from "../../assets/img/search2.svg";
import { ReactComponent as Align } from "../../assets/img/align-justify.svg";
import { ReactComponent as AlignZwei } from "../../assets/img/align2.svg";
import { ReactComponent as Bep } from "../../assets/img/bep.svg";

import { ReactComponent as Results } from "../../assets/img/results.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/img/arrow-left.svg";

import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { XMarkIcon } from "@heroicons/react/24/solid";
import HeaderBanner from "../ui/HeaderBanner";
import { JobTypes, OffersTypes } from "src/types/types";
import GoBackButton from "../ui/GoBack";
import { useQuery } from "react-query";
import { useGetFeedbackJobs } from "src/Hooks/useFeedbackJobs";

const jobsPerPage = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
];

function JobSearch() {
  const [offers, setOffers] = useAtom(jobOfferAtom);
  const [desiredJob, setDesiredJob] = useLocalStorage("desiredJob", []);
  const [filteredList, setFilteredList] = useState(offers);
  const [savedJobsCounter, setSavedJobsCounter] = useState(0);
  const [updateSaved, setUpdateSaved] = useState(false);
  // const [savedJobs, setSavedJobs] = useState<any>();
  const [view, setView] = useState("expanded");
  const [updateApplied, setUpdateApplied] = useState(false);
  const [showSavedJobs, setShowSavedJobs] = useAtom(showSavedJobsWorker);
  const [clearSalaryInput, setClearSalaryInput] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState("");
  const [selectedSkills, setSelectedSkills] = useState<any>([]);
  const [selectedJobType, setSelectedJobType] = useState<any>([]);
  const [selectedCities, setSelectedCities] = useState<any>([]);
  const [selectedPostCodes, setSelectedPostCodes] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [open, setOpen] = useState(false);

  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [categoryFilter, setCategoryFilter] = useAtom(categoryFilterOptions);
  const [skillsFilter, setSkillsFilter] = useAtom(skillFilterOptions);
  const [cityFilter, setCityFilter] = useAtom(cityFilterOptions);
  const [postcodeFilter, setPostcodeFilter] = useAtom(postcodeFilterOptions);
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const [auxiliarDesiredJob, setAuxiliarDesiredJob] = useState();
  const [selectedSortFilter, setSelectedSortFilter] = useState("Relevance");
  const [hideAllFilters, setHideAllFilters] = useState(false);
  const [savedSearchesFilters, setSavedSearchesFilters] = useAtom(
    allFiltersAppliedToJobSearch
  );
  const [areThereFiltersChosen, setAreThereFiltersChosen] = useState(false);
  // const [savedJobs, setSavedJobs] = useState<any>();
  const ref = useRef<HTMLInputElement>(null);
  const [animationView, setAnimationView] = useState(false);
  const [sortedData, setSortedData] = useState<OffersTypes[]>([]);
  const [notInterestedOptions, setNotInterestedOptions] =
    useAtom(jobFeedbackOptions);
  const [savedJobsInfo, setSavedJobs] = useState<any>();
  // const [isLoadingSavedJobs, setIsLoadingSavedJobs] = useState(false);

  const [headerVisible, setHeaderVisible] = useState(true);

  const { t } = useTranslation();

  const { feedbackDataOptions } = useGetFeedbackJobs();

  useEffect(() => {
    setNotInterestedOptions(feedbackDataOptions);
  }, [feedbackDataOptions]);

  const sortFilter = [
    { value: "Relevance", label: t("worker.job_search.sort.relevance") },
    { value: "Newest", label: t("worker.job_search.sort.newest") },
  ];

  // const JOB_FILTERS = [
  //   {
  //     id: 1,
  //     label: t("worker.edit_job_type.modal.work_type.inperson"),
  //     checked: false,
  //     class: "type",
  //   },
  //   {
  //     id: 2,
  //     label: t("worker.edit_job_type.modal.work_type.remote"),
  //     checked: false,
  //     class: "type",
  //   },
  //   {
  //     id: 3,
  //     label: t("worker.edit_job_type.modal.work_type.hybrid"),
  //     checked: false,
  //     class: "type",
  //   },
  // ];

  useEffect(() => {
    if (open) {
      setAnimationView(true);
    } else {
      setAnimationView(false);
    }
  }, [open]);

  // const [jobTypeFilter, setJobTypeFilter] = useState(JOB_FILTERS);
  const navigate = useNavigate();
  const viewRef = useRef<HTMLDivElement>(null);

  const getNumberOfSavedJobs = async () => {
    const data = await fetch(
      `${BASE_URL}${SAVED_JOBS_WORKER}?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };
  const { data: savedJobsData, isLoading: isSavedJobsLoading } = useQuery(
    ["saved_jobs"],
    getNumberOfSavedJobs
  );

  useEffect(() => {
    if (!isSavedJobsLoading) {
      setSavedJobsCounter(savedJobsData?.numberOfSavedJobs);
    }
  }, [savedJobsData, isSavedJobsLoading]);

  useEffect(() => {
    setAuxiliarDesiredJob(desiredJob);
  }, [desiredJob]);

  const getSavedJobs = async () => {
    // setIsLoadingSavedJobs(true);
    const data = await fetch(
      `${BASE_URL}${JOBS}?client_id=${userID}&filter_field=saved`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: savedJobs,
    isLoading: isLoadingSavedJobs,
    refetch: refetchSavedJobs,
  } = useQuery({
    queryFn: getSavedJobs,
    queryKey: ["jobs_saved"],
  });

  useEffect(() => {
    if (!isLoadingSavedJobs) {
      setSavedJobs(savedJobs);
    }
  }, [savedJobs, isLoadingSavedJobs]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgray",

      display: "flex",
      width: "140px",
      padding: "0rem",
      borderRadius: "5px",
      height: "30px",
      fontSize: "14px",
    }),
  };
  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      width: "100px",
      padding: "0rem",
      borderRadius: "5px",
      height: "30px",
      fontSize: "14px",
    }),
  };

  //PAGINATION
  const [currentPage, setCurrentPage] = useAtom(myCurrentPage);
  const [offersPerPage, setOffersPerPage] = useAtom(numberOffersPerPage);
  const [totalPages, setTotalPages] = useState(1);
  const [allOffers, setAllOffers] = useState(0);
  const [pageNumbers, setPageNumbers] = useState<any>([]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (totalPages) {
      setPageNumbers([...Array(totalPages + 1).keys()]?.slice(1));
    }
  }, [totalPages]);

  const sortedJobCardsUpdated: any = useMemo(() => {
    if (offers?.length > 0)
      return selectedSortFilter === "Relevance"
        ? [...offers].sort(compareDate)
        : offers;
  }, [selectedSortFilter, offers]);

  const sortedJobCardsBudget: any = useMemo(() => {
    if (offers?.length > 0)
      return selectedSortFilter === "Newest"
        ? [...offers].sort(compareBudget)
        : offers;
  }, [selectedSortFilter, offers]);

  function compareDate(a: any, b: any) {
    return b.date_created - a.date_created;
  }
  function compareBudget(a: any, b: any) {
    return b.budget - a.budget;
  }

  useEffect(() => {
    if (selectedSortFilter === "Newest") {
      setSortedData(sortedJobCardsBudget);
    } else if (selectedSortFilter === "Relevance") {
      setSortedData(sortedJobCardsUpdated);
    } else {
      setSortedData(sortedJobCardsUpdated);
    }
  }, [selectedSortFilter]);

  const getSearchedOffersPagination = async () => {
    const data = await fetch(
      `${BASE_URL}${JOBS}?client_id=${userID}&find=${desiredJob}&page=${
        // page !== undefined ? page : currentPage
        currentPage
      }&limit=${offersPerPage}${
        selectedBudget ? `&budget=${selectedBudget}` : ""
      }${
        selectedPostCodes?.length > 0
          ? `&country_code=ES&city_id_list=${selectedCities?.map(
              (city: { id: string }) => city.id
            )}&post_code_id_list=${selectedPostCodes?.map(
              (code: { id: string }) => code.id
            )}`
          : selectedCities?.length > 0
          ? `&country_code=ES&city_id_list=${selectedCities?.map(
              (city: { id: string }) => city.id
            )}`
          : ""
      }${
        selectedJobType.length > 0
          ? `&job_type_list=${selectedJobType?.map(
              (type: { id: string }) => type.id
            )}`
          : ""
      }${
        selectedCategories?.length > 0
          ? `&category_id_list=${selectedCategories?.map(
              (cat: { category_id: string }) => cat.category_id
            )}`
          : ""
      }${
        selectedSkills?.length > 0
          ? `&skill_id_list=${selectedSkills?.map(
              (skill: { skill_id: string }) => skill.skill_id
            )}`
          : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data;
  };

  const {
    data: searchData,
    isLoading,
    refetch: refetchSearch,
  } = useQuery(
    [
      "searchJobs",
      desiredJob,
      selectedBudget,
      selectedCities,
      selectedPostCodes,
      selectedJobType,
      selectedCategories,
      selectedSkills,
      selectedSortFilter,
    ],
    getSearchedOffersPagination
  );

  useEffect(() => {
    if (searchData?.result.length > 0 && !isLoading) {
      setTotalPages(searchData?.paginate.pages);
      setAllOffers(searchData?.paginate.rows);
    } else {
      setAllOffers(0);
      setTotalPages(1);
    }
    setOffers(searchData?.result);
  }, [searchData]);

  useEffect(() => {
    if (savedSearchesFilters) {
      if (areThereFiltersChosen) {
        refetchSearch();
      }
    }
  }, [areThereFiltersChosen]);
  useEffect(() => {
    if (reset === true) {
      setCurrentPage(1);

      refetchSearch();
    }
  }, [offersPerPage]);
  function goToNextPage() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    refetchSearch();

    setCurrentPage((page) => page - 1);
  }
  function goToFirstPage() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setCurrentPage(1);
  }

  function goToLastPage() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setCurrentPage(totalPages);
  }

  function changePage(page: number) {
    setCurrentPage(page);
    refetchSearch();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    if (selectedCities?.length === 0) setSelectedPostCodes([]);
  }, [selectedCities]);

  const handleRemove = (props: any) => {
    if (props === "all") {
      setHideAllFilters(true);
      if (selectedCategories?.length > 0) {
        setSelectedCategories([]);
        setCategoryFilter((current: any) =>
          current?.map((obj: { checked: boolean }) => {
            return { ...obj, checked: false };
          })
        );
      }
      if (selectedSkills?.length > 0) {
        setSelectedSkills([]);
        setSkillsFilter((current: any) =>
          current?.map((obj: { checked: boolean }) => {
            return { ...obj, checked: false };
          })
        );
      }

      if (selectedCountry) setSelectedCountry([]);

      if (selectedCities?.length > 0) {
        setSelectedCities([]);
        setCityFilter((current: any) =>
          current?.map((obj: { checked: boolean }) => {
            return { ...obj, checked: false };
          })
        );
      }

      if (selectedPostCodes?.length > 0) {
        setSelectedPostCodes([]);
        setPostcodeFilter((current: any) =>
          current?.map((obj: { checked: boolean }) => {
            return { ...obj, checked: false };
          })
        );
      }

      if (selectedBudget) {
        setSelectedBudget("");
        setClearSalaryInput(true);
      }

      // setJobTypeFilter((current) =>
      //   current?.map((obj) => {
      //     return { ...obj, checked: false };
      //   })
      // );
      // setHideAllFilters(false);
    }
    if (props.class === "city") {
      setSelectedCities(
        selectedCities.filter((item: { id: string }) => item.id !== props.id)
      );
      setCityFilter((current: any) =>
        current?.map((obj: { id: string }) => {
          if (obj.id === props.id) {
            return { ...obj, checked: false };
          }
          return obj;
        })
      );
    }
    // if (props.class === "type") {
    //   setJobTypeFilter((current) =>
    //     current?.map((obj) => {
    //       if (obj.id === props.id) {
    //         return { ...obj, checked: !props.checked };
    //       }
    //       return obj;
    //     })
    //   );
    // }
    if (props.class === "postcode") {
      setSelectedPostCodes(
        selectedPostCodes.filter((item: { id: string }) => item.id !== props.id)
      );
      setPostcodeFilter((current: any) =>
        current?.map((obj: any) => {
          if (obj.id === props.id) {
            return { ...obj, checked: false };
          }
          return obj;
        })
      );
    }
    if (props.class === "category") {
      setSelectedCategories(
        selectedCategories.filter(
          (item: { category_id: string }) =>
            item.category_id !== props.category_id
        )
      );
      setCategoryFilter((current: any) =>
        current?.map((obj: any) => {
          if (obj.category_id === props.category_id) {
            return { ...obj, checked: false };
          }
          return obj;
        })
      );
    }
    if (props.class === "skill") {
      setSelectedSkills(
        selectedSkills.filter(
          (item: { skill_id: string }) => item.skill_id !== props.skill_id
        )
      );
      setSkillsFilter((current: any) =>
        current?.map((obj: any) => {
          if (obj.skill_id === props.skill_id) {
            return { ...obj, checked: false };
          }
          return obj;
        })
      );
    }
  };
  // const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const checkIfClickedOutsideViewPopup = (e: any) => {
      if (open && viewRef.current && !viewRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutsideViewPopup);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutsideViewPopup);
    };
  }, [open]);

  return (
    <>
      <HeaderBanner
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
        className="bg-[#2abeeb]"
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            {t("employer.job_search.header_banner")}
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      {showSavedJobs === false ? (
        <>
          <div
            className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline mt-8 mx-4 mb-4"
            onClick={() => navigate(-1)}
          >
            <GoBackButton />
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <Filters
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedSkills={selectedSkills}
              setSelectedSkills={setSelectedSkills}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              selectedPostcodes={selectedPostCodes}
              setSelectedPostCodes={setSelectedPostCodes}
              setSelectedBudget={setSelectedBudget}
              setSelectedJobType={setSelectedJobType}
              selectedJobType={selectedJobType}
              // jobTypeFilter={jobTypeFilter}
              // setJobTypeFilter={setJobTypeFilter}
              clearSalaryInput={clearSalaryInput}
              setClearSalaryInput={setClearSalaryInput}
              setAreThereFiltersChosen={setAreThereFiltersChosen}
              hideAllFilters={hideAllFilters}
              setHideAllFilters={setHideAllFilters}
            />
            <>
              {/* <div className="flex-[0_0_75%] max-w-[75%] p-2 mt-2 shadow"> */}
              <div className="rounded-lg  bg-white lg:w-[70%] ml-4 shadow dark:bg-background dark:border-border dark:border dark:text-whiteish">
                <SearchSavedNavbar
                  showSavedJobs={showSavedJobs}
                  setShowSavedJobs={setShowSavedJobs}
                  savedJobsCounter={savedJobsCounter?.toString()}
                />
                {/* {showLoader === true && savedSearchesFilters ? (
                  <div className="flex items-center justify-center m-auto z-20 w-full h-full bg-white dark:bg-background">
                    <Suspense fallback={null}>
                      <Loader className="left-[70%]" />
                    </Suspense>
                  </div>
                ) : ( */}
                <>
                  <div className="flex items-center relative">
                    <form
                      className="relative m-6 w-[76%]"
                      onSubmit={() => {
                        if (desiredJob?.length > 0) {
                          setCurrentPage(1);

                          refetchSearch();
                        }
                      }}
                    >
                      <input
                        ref={ref}
                        type="text"
                        className="w-full  border dark:bg-input dark:border-border dark:border h-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
                        placeholder={`${t(
                          "worker.job_search.search_jobs_placeholder"
                        )}`}
                        value={desiredJob}
                        // onChange={(e) => {
                        //   optimisedSearch(e.target.value);
                        // }}
                        onChange={(e) => setDesiredJob(e.target.value)}
                      />
                      <div
                        className="h-10 w-9 bg-azulBonico hover:bg-azulOscuro transition duration-300 absolute right-0 top-0 rounded-r cursor-pointer"
                        onClick={() => {
                          if (desiredJob?.length > 0) {
                            setCurrentPage(1);

                            refetchSearch();
                          }
                        }}
                      >
                        <input
                          type="text"
                          className="w-full  border h-10 pl-4 dark:bg-input outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
                          placeholder={`${t(
                            "worker.job_search.search_jobs_placeholder"
                          )}`}
                          value={desiredJob}
                          // onChange={(e) => optimisedSearch(e.target.value)}

                          onChange={(e) => setDesiredJob(e.target.value)}
                        />
                        <div
                          className="h-10 w-9 bg-azulBonico hover:bg-azulOscuro transition duration-300 absolute right-0 top-0 rounded-r cursor-pointer"
                          onClick={() => {
                            if (desiredJob?.length > 0) {
                              setCurrentPage(1);

                              refetchSearch();
                            }
                          }}
                        >
                          <Search
                            fill={"white"}
                            className="h-4 cursor-pointer absolute top-[12px] left-[10px]"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="relative group flex items-center">
                      <Button
                        className=" h-10 text-azulBonico group-hover:bg-azulBonico mr-6 border dark:bg-input border-azulBonico  tracking-wide bg-white text-xs group-hover:text-white flex items-center px-2 rounded"
                        onClick={() => setShowSaveSearchModal(true)}
                      >
                        {/* <Folder
                            className="h-[14px] mr-2 group-hover:fill-white fill-azulBonico transition"
                            // fill={"#2abeeb"}
                          /> */}
                        {t("worker.dashboard.save_search")}
                      </Button>
                      <div className="absolute top-0 left-0 z-10">
                        {showSaveSearchModal === true && (
                          <ModalSaveJobSearch
                            showSaveSearchModal={showSaveSearchModal}
                            setShowSaveSearchModal={setShowSaveSearchModal}
                            selectedCategories={selectedCategories}
                            selectedBudget={selectedBudget}
                            selectedSkills={selectedSkills}
                            selectedJobType={selectedJobType}
                            selectedCities={selectedCities}
                            selectedPostCodes={selectedPostCodes}
                            selectedCountry={selectedCountry}
                          />
                        )}
                      </div>
                    </div>
                    {/* {loading && (
                        <Suspense fallback={null}>
                          <Loader className="left-[70%]" />
                        </Suspense>
                      )} */}
                  </div>
                  <div className="flex px-6 flex-wrap max-h-48 text-xs overflow-auto mt-2 mb-2 jobpost">
                    {selectedBudget && (
                      <div
                        className="relative"
                        onClick={() => {
                          setClearSalaryInput(true);
                          setSelectedBudget("");
                        }}
                      >
                        <div className="px-2 !text-sm py-0.5 dark:border-border  mx-0.5 text-white bg-[#414959] my-0.5 flex items-center border rounded-xl cursor-pointer hover:bg-[#111217]">
                          {t("worker.job_search.min_salary")}: {selectedBudget}€
                          &nbsp;&nbsp;&nbsp; ×
                        </div>
                        {/* <Cross
                        fill={"white"}
                        className="h-[9px] absolute right-3 top-[11px] cursor-pointer"
                      /> */}
                      </div>
                    )}
                    {/* 
                    {jobTypeFilter?.map((type) => {
                      if (type.checked === true)
                        return (
                          <div
                            key={type.id}
                            className="relative"
                            onClick={() => handleRemove(type)}
                          >
                            <div className="px-2 text-sm py-0.5 mx-0.5  text-white bg-[#414959] my-0.5 flex items-center border rounded-xl cursor-pointer hover:bg-[#111217]">
                              {t("worker.job_search.model_work")}: {type.label}{" "}
                              &nbsp;&nbsp;&nbsp; ×
                            </div>
                          </div>
                        );
                    })} */}
                    {selectedCities?.map(
                      (city: { id: string; name: string }) => {
                        return (
                          <div
                            key={city.id}
                            className="relative"
                            onClick={() => handleRemove(city)}
                          >
                            <div className="px-2 text-sm py-0.5 mx-0.5 text-white dark:border-border transition bg-[#414959] my-0.5 flex items-center border rounded-xl cursor-pointer hover:bg-[#111217]">
                              {t("worker.job_search.city")}: {city.name}{" "}
                              &nbsp;&nbsp;&nbsp; ×
                            </div>
                            {/* <Cross
                            fill={"white"}
                            className="h-[9px] absolute right-3 top-[11px] cursor-pointer"
                          /> */}
                          </div>
                        );
                      }
                    )}
                    {selectedPostCodes?.map(
                      (postcode: { postcode_id: string; label: string }) => {
                        return (
                          <div
                            key={postcode.postcode_id}
                            className="relative"
                            onClick={() => handleRemove(postcode)}
                          >
                            <div className="px-2 text-sm py-0.5 mx-0.5 text-white dark:border-border transition bg-[#414959] my-0.5 flex items-center border rounded-xl cursor-pointer hover:bg-[#111217]">
                              {t("worker.job_search.locality")}:{" "}
                              {postcode.label} &nbsp;&nbsp;&nbsp; ×
                            </div>
                          </div>
                        );
                      }
                    )}
                    {selectedCategories?.map(
                      (category: { category_id: string; name: string }) => {
                        return (
                          <div
                            key={category.category_id}
                            className="relative"
                            onClick={() => handleRemove(category)}
                          >
                            <div className="px-2 text-sm py-0.5 mx-0.5 text-white dark:border-border transition bg-[#414959] my-0.5 flex items-center border rounded-xl cursor-pointer hover:bg-[#111217]">
                              {t("worker.job_search.category")}: {category.name}{" "}
                              &nbsp;&nbsp;&nbsp; ×
                            </div>
                          </div>
                        );
                      }
                    )}
                    {selectedSkills?.map(
                      (skill: { skill_id: string; name: string }) => {
                        return (
                          <div
                            key={skill.skill_id}
                            className="relative"
                            onClick={() => handleRemove(skill)}
                          >
                            <div className="px-2 text-sm py-0.5 mx-0.5 dark:border-border transition text-white bg-[#414959] my-0.5 flex items-center border rounded-xl cursor-pointer hover:bg-[#111217]">
                              {t("worker.job_search.skill")}: {skill.name}{" "}
                              &nbsp;&nbsp;&nbsp; ×
                            </div>
                          </div>
                        );
                      }
                    )}
                    {(selectedSkills?.length > 0 ||
                      // jobTypeFilter[0].checked === true ||
                      // jobTypeFilter[1].checked === true ||
                      // jobTypeFilter[2].checked === true ||
                      selectedCategories?.length > 0 ||
                      selectedBudget ||
                      selectedCities?.length > 0 ||
                      selectedPostCodes?.length > 0) && (
                      <div className="relative">
                        <div
                          className="px-2 text-xs py-1 mx-0.5 text-gray-600 bg-gray-200 my-0.5 flex items-center border rounded cursor-pointer "
                          onClick={() => handleRemove("all")}
                        >
                          {t("worker.job_search.clear_all_filters")}
                          <XMarkIcon className="h-3 w-3 ml-3  cursor-pointer text-gray-600" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="px-6 flex justify-between py-4 border-b border-blue-100">
                    <div className="flex items-center">
                      <Bep className="h-[14px] mr-2" fill={"#2abeeb"} />
                      <div>
                        <p className="text-sm">
                          <span className="font-medium">{allOffers}</span>
                          &nbsp;{t("worker.job_search.jobs_found")}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-6">
                      <div className="flex items-center gap-2">
                        <p className="font-medium text-sm">
                          {t("worker.job_search.sort")}:
                        </p>
                        <Select
                          options={sortFilter}
                          placeholder={`${t(
                            "worker.job_search.sort.relevance"
                          )}`}
                          onChange={(e) => setSelectedSortFilter(e!.value)}
                          // defaultValue={{
                          //   label:
                          //     sortFilter[0].label === "Relevance" ??
                          //     t("worker.job_search.sort.relevance"),
                          //   value: sortFilter[0].value,
                          // }}
                          styles={colourStyles}
                          className="text-sm dark:text-black cursor-pointer "
                        />
                      </div>
                      <div className="flex items-center" ref={viewRef}>
                        <p className="font-medium text-sm mr-2">
                          {t("worker.job_search.view")}:
                        </p>
                        {view === "expanded" ? (
                          <Align
                            className="h-[32px] overflow-visible hover:bg-[#f7f4f4] p-2  cursor-pointer rounded-full border border-slate-200"
                            fill={"#2abeeb"}
                            onClick={() => setOpen(!open)}
                          />
                        ) : (
                          <AlignZwei
                            className="h-[32px] overflow-visible hover:bg-[#f7f4f4] p-2 w-fit  cursor-pointer rounded-full border border-slate-200"
                            color={"#2abeeb"}
                            onClick={() => setOpen(!open)}
                          />
                        )}
                        <div className="relative z-30">
                          {open === true && (
                            <div
                              className={`${
                                animationView
                                  ? "translate-y-0 opacity-100"
                                  : "-translate-y-2 opacity-0"
                              } absolute w-[200px] top-6 right-2 transform bg-white transition-all duration-200 ease-in-out dark:bg-background dark:border-border dark:border  shadow-xl rounded cursor-pointer `}
                            >
                              <div
                                className="flex px-2 dark:hover:bg-neutral-400/30 items-center mt-3 hover:bg-[#f7f4f4]"
                                onClick={() => {
                                  setView("compact");
                                  setOpen(false);
                                }}
                              >
                                <AlignZwei
                                  className="h-[30px] p-2 w-fit overflow-visible mr-[9px]"
                                  fill={"#6e7081"}
                                />
                                <p className="text-sm">
                                  {t("worker.job_search.compact")}
                                </p>
                              </div>
                              <div
                                className="flex px-2 dark:hover:bg-neutral-400/30 items-center mb-3 hover:bg-[#f7f4f4]"
                                onClick={() => {
                                  setOpen(false);
                                  setView("expanded");
                                }}
                              >
                                <Align
                                  className="h-[32px] overflow-visible p-2 mr-2 "
                                  fill={"#6e7081"}
                                />
                                <p className="text-sm">
                                  {t("worker.job_search.expanded")}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {view === "expanded" && offers?.length > 0 ? (
                    <JobCards
                      // searchedJobs={searchedJobs}
                      offers={offers}
                      setOffers={setOffers}
                      updateSaved={updateSaved}
                      setUpdateSaved={setUpdateSaved}
                      savedJobsCounter={savedJobsCounter?.toString()}
                      setSavedJobsCounter={setSavedJobsCounter}
                      updateApplied={updateApplied}
                      setUpdateApplied={setUpdateApplied}
                      loading={false}
                      refetchSavedJobs={getSearchedOffersPagination}
                    />
                  ) : view === "compact" && offers?.length > 0 ? (
                    <JobCardsCompact
                      // searchedJobs={searchedJobs}
                      offers={offers}
                      setOffers={setOffers}
                      updateSaved={updateSaved}
                      setUpdateSaved={setUpdateSaved}
                      savedJobsCounter={savedJobsCounter}
                      setSavedJobsCounter={setSavedJobsCounter}
                      updateApplied={updateApplied}
                      setUpdateApplied={setUpdateApplied}
                    />
                  ) : (
                    <div className="my-12 flex flex-col justify-center m-auto">
                      <Results className="h-[150px] m-auto" />
                      <p className="text-xl font-medium tracking-wide mx-24 mt-6 text-center">
                        {t("worker.job_search.no_available_jobs")}
                      </p>
                    </div>
                  )}
                  <div className="border-t z-100 overflow-visible">
                    <div className="p-6 flex justify-between">
                      <div className="flex items-center gap-5">
                        <p className="text-sm font-medium">
                          {t("worker.job_search.jobs_per_page")}:
                        </p>
                        <Select
                          options={jobsPerPage}
                          styles={colourStyles2}
                          className="dark:text-black z-[200]"
                          defaultValue={{ label: 10, value: 10 }}
                          onChange={(e) => {
                            setOffersPerPage(e!.value);
                            setReset(true);
                          }}
                          isSearchable={false}
                        />
                      </div>
                      <div className="flex items-center gap-6">
                        <button
                          className={`text-sm font-medium cursor-pointer  ${
                            currentPage == 1
                              ? "text-slate-400 cursor-not-allowed"
                              : "text-azulBonico hover:text-azulOscuro"
                          } `}
                          onClick={() => goToFirstPage()}
                          disabled={currentPage == 1}
                        >
                          {t("worker.job_search.jobs_per_page.first")}
                        </button>
                        <button
                          className={`flex items-center gap-3 ${
                            currentPage == 1
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }  `}
                          onClick={() => goToPreviousPage()}
                          disabled={currentPage == 1}
                        >
                          <ArrowLeft
                            className="h-5 "
                            fill={`${
                              currentPage == 1 ? "rgb(148 163 184)" : "#2abeeb"
                            } `}
                          />
                          <p
                            className={`text-sm font-medium ${
                              currentPage == 1
                                ? "text-slate-400 cursor-not-allowed"
                                : "text-azulBonico hover:text-azulOscuro"
                            } `}
                          >
                            {t("worker.job_search.jobs_per_page.previous")}
                          </p>
                        </button>
                        <div className=" font-medium flex gap-3">
                          {currentPage < 4 ? (
                            <>
                              {pageNumbers?.slice(0, 4)?.map((page: number) => {
                                return (
                                  <>
                                    {page === currentPage ? (
                                      <div
                                        className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                        onClick={() => changePage(page)}
                                      >
                                        &nbsp;&nbsp;{page}&nbsp;&nbsp;
                                      </div>
                                    ) : (
                                      <div
                                        className="hover:text-azulOscuro hover:underline cursor-pointer"
                                        onClick={() => changePage(page)}
                                      >
                                        &nbsp;{page}&nbsp;
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {currentPage >= 4 &&
                              totalPages - 3 >= currentPage ? (
                                <>
                                  {pageNumbers
                                    ?.slice(currentPage - 2, currentPage + 1)
                                    ?.map((page: number) => {
                                      return (
                                        <>
                                          {page === currentPage ? (
                                            <div
                                              className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                              onClick={() => changePage(page)}
                                            >
                                              &nbsp;&nbsp;{page}
                                              &nbsp;&nbsp;
                                            </div>
                                          ) : (
                                            <div
                                              className="hover:text-azulOscuro hover:underline cursor-pointer"
                                              onClick={() => changePage(page)}
                                            >
                                              &nbsp;{page}&nbsp;
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                </>
                              ) : (
                                <>
                                  {totalPages - 3 < currentPage && (
                                    <>
                                      {pageNumbers
                                        ?.slice(totalPages - 4, totalPages)
                                        ?.map((page: number) => {
                                          return (
                                            <>
                                              {page === currentPage ? (
                                                <div
                                                  key={page}
                                                  className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                                  onClick={() =>
                                                    changePage(page)
                                                  }
                                                >
                                                  &nbsp;&nbsp;{page}
                                                  &nbsp;&nbsp;
                                                </div>
                                              ) : (
                                                <div
                                                  className="hover:text-azulOscuro hover:underline cursor-pointer"
                                                  onClick={() =>
                                                    changePage(page)
                                                  }
                                                >
                                                  &nbsp;{page}&nbsp;
                                                </div>
                                              )}
                                            </>
                                          );
                                        })}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <button
                          className={`flex items-center gap-3 ${
                            currentPage == totalPages
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }  `}
                          onClick={() => goToNextPage()}
                          disabled={currentPage == totalPages}
                        >
                          <p
                            className={`text-sm font-medium ${
                              currentPage == totalPages
                                ? "text-slate-400 cursor-not-allowed"
                                : "text-azulBonico hover:text-azulOscuro"
                            } `}
                          >
                            {t("worker.job_search.jobs_per_page.next")}
                          </p>
                          <ArrowRight
                            className="h-5 "
                            fill={`${
                              currentPage == totalPages
                                ? "rgb(148 163 184)"
                                : "#2abeeb"
                            } `}
                          />
                        </button>
                        <button
                          className={`text-sm font-medium cursor-pointer  ${
                            currentPage == totalPages
                              ? "text-slate-400 cursor-not-allowed"
                              : "text-azulBonico hover:text-azulOscuro"
                          } `}
                          onClick={() => goToLastPage()}
                          disabled={currentPage == totalPages}
                        >
                          {t("worker.job_search.jobs_per_page.last")}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
                {/* )} */}
              </div>
            </>
          </div>
        </>
      ) : (
        <>
          <div className="rounded-t bg-white  mt-6 shadow dark:bg-background dark:text-whiteish dark:border-border dark:border">
            <SearchSavedNavbar
              showSavedJobs={showSavedJobs}
              setShowSavedJobs={setShowSavedJobs}
              savedJobsCounter={savedJobsCounter?.toString()}
            />
          </div>
          <SavedJobs
            loadingSavedJobs={isLoadingSavedJobs}
            offers={savedJobs!}
            setOffers={setSavedJobs}
            updateSaved={updateSaved}
            setUpdateSaved={setUpdateSaved}
            savedJobsCounter={savedJobsCounter}
            setSavedJobsCounter={setSavedJobsCounter}
            setUpdateApplied={setUpdateApplied}
            updateApplied={updateApplied}
            refetchSavedJobs={getSavedJobs}
          />
        </>
      )}
    </>
  );
}

export default JobSearch;
