import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as LogoTwo } from "../../../../assets/img/logo_2.svg";

import "../../../../Components/HeaderUser/HeaderUser.css";

function RegistrationHeader() {
  const navigate = useNavigate();

  return (
    <div className="header-wrapper header-user-wrapper">
      <div className="flex items-center w-full lg:max-w-[1209px] h-[80px] pl-[50px]">
        <LogoTwo
          className="h-10 lg:h-14 cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
    </div>
  );
}

export default RegistrationHeader;
