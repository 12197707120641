import React from "react";

function YoutubeEmbed({ videoUrl }) {
  return (
    <div>
      <iframe
        src={`https://youtube.com/embed/${
          videoUrl?.split("v=")[1]?.split("&")[0]
        }`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="h-[400px] w-[700px]"
        title="Embedded youtube"
        loading="lazy"
      />
    </div>
  );
}

export default YoutubeEmbed;
