import { create } from "zustand";

export type ModalType = "success" | "failure";

interface ModalData {
  credits?: number;
}

interface ModalStore {
  isOpen: boolean;
  onOpen: (type: ModalType, data: ModalData) => void;
  onClose: () => void;
  type: ModalType | null;
  data: ModalData;
}

export const usePaymentStore = create<ModalStore>((set) => ({
  isOpen: false,
  onOpen: (type, data = {}) => set({ isOpen: true, type, data }),
  onClose: () => set({ isOpen: false }),
  type: null,
  data: {},
}));
