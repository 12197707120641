import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { cityNameInput } from "src/Atoms/jotaiAtoms";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";

export const useCities = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [cityName, setCityName] = useAtom(cityNameInput);
  const getAllCities = async () => {
    const data = await fetch(`${BASE_URL}cities?country_code=ES`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: cities, refetch: refetchCities } = useQuery(
    ["cities", cityName],
    getAllCities
  );

  return { cities, refetchCities };
};
