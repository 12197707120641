import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "../ui/Button";
import { useModalStore } from "src/Hooks/useModalStore";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";

import useUpdateCredits from "src/Hooks/useUpdateCredits";
import {
  BASE_URL,
  INVITE_WORKERS_EMPLOYER,
  POSTINGS,
} from "src/Config/api.config";
import FlexiCoin from "src/assets/img/flexicoin.png";

import { useEmployerStore } from "src/Routing/useProfileStore";
import Select from "react-select";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useQuery } from "react-query";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { toast } from "react-hot-toast";
import { cn } from "src/lib/utils";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { getCreditsTypes } from "src/Routing/useCreditsActions";

interface ModalInviteToJobWorkerProps {
  workerID: string;

  refetch: () => void;
}

const ModalInviteToJobWorker = ({
  workerID,
  // workerInvitedToJobs,
  refetch,
}: ModalInviteToJobWorkerProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showInviteOptionsMenu, setShowInviteOptionsMenu] = useModalStore(
    (state) => [state.showInviteOptionsMenu, state.setShowInviteOptionsMenu]
  );
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const params = useParams();
  const { inviteJob } = getCreditsTypes();
  const { updateCredits } = useUpdateCredits(inviteJob?.credits, inviteJob?.id);
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);

  const [selectedPosting, setSelectedPosting] = useState({
    value: "",
    label: "",
  });

  const [useJobType, setUseJobType] = useState("");
  const [nextInvitation, setNextInvitation] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [allOptions, setAllOptions] = useState([]);

  const handleClose = () => setShowInviteOptionsMenu(showInviteOptionsMenu);

  const navigate = useNavigate();

  const colourStyles = {
    control: () => ({
      "&:hover": {
        backgroundColor: "#f8f8f8",
      },
      display: "flex",
      width: "200px",
      marginRight: "14px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
      cursor: "pointer",
      zIndex: 9999,
      overflow: "visible",
    }),
  };
  const cancelInviteWorker = (worker_id: string) => {
    fetch(
      `${BASE_URL}${INVITE_WORKERS_EMPLOYER}?client_id=${userID}&job_id=${selectedPosting.value}&client_id_worker=${worker_id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: inviteData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const getPostings = async () => {
    const data = await fetch(
      `${BASE_URL}${POSTINGS}?client_id=${userID}&order=date_created`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: postings, isLoading } = useQuery(["postings"], getPostings);

  const postingsOptions = postings?.map(
    (post: { job_id: string; title: string }) => {
      return {
        value: post.job_id,
        label: post.title,
      };
    }
  );

  // INVITE WORKER
  const inviteData = new FormData();
  inviteData.append("_method", "POST");
  inviteData.append("client_id", userID);
  inviteData.append("job_id", selectedPosting.value);

  const handleInviteWorker = () => {
    inviteData.append("client_id_worker", workerID);
    fetch(`${BASE_URL}${INVITE_WORKERS_EMPLOYER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: inviteData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          updateCredits();
          setShowInviteOptionsMenu(showInviteOptionsMenu);
          if (data.result.workerInvited === "ok") {
            toast.success(`Invited to Job ${selectedPosting.label}`);
            refetch();
          }
        }
        if (data.status === "error") {
          toast.error(`${data.result.error_msg}`);
        }
      })
      .catch((err) => console.log(err));
  };

  const closeModal = () => setShowInviteOptionsMenu(showInviteOptionsMenu);

  return (
    <Modal isOpen={showInviteOptionsMenu} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <div className="flex items-center justify-between px-8 py-6">
          <h1 className="text-lg font-medium">
            You want to send an invitation
          </h1>
          <Button
            onClick={() => handleClose()}
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        {nextInvitation && useJobType === "0" && (
          <>
            <div className="flex flex-col items-start justify-between px-8 py-6">
              <p className="text-sm mb-4 border-b w-full pb-2 font-medium">
                Select existing post
              </p>
              <div className=" text-sm flex items-center group cursor-pointer  py-1 ">
                <div className="flex items-center w-full justify-between">
                  <Select
                    value={
                      selectedPosting.value !== ""
                        ? selectedPosting
                        : "Select Post"
                    }
                    placeholder="Select Job"
                    styles={colourStyles}
                    options={postingsOptions}
                    className="text-sm mb-2"
                    onChange={(e: any) => {
                      setSelectedPosting(e);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-3 ">
                {postingsOptions?.map(
                  (option: { value: string; label: string }) => (
                    <Button
                      onClick={() => setSelectedPosting(option)}
                      key={option.value}
                      className={cn(
                        "bg-gray-100 rounded px-2 py-1 text-sm cursor-pointer",
                        selectedPosting.value === option.value &&
                          "bg-gray-600 text-white"
                      )}
                    >
                      <p>{option.label}</p>
                    </Button>
                  )
                )}
              </div>
            </div>
            <div className=" flex items-center justify-end w-full gap-4 px-8 py-4">
              <Button
                onClick={() => handleInviteWorker()}
                disabled={selectedPosting.value === ""}
                className="bg-none text-[0.9rem] border relative group px-5 py-2 bg-azulBonico hover:bg-azulOscuro cursor-pointer text-white rounded-full mt-1"
              >
                Send Invitation
                <div className="absolute bottom-12 scale-0 right-2 bg-gray-100  rounded px-2 group-hover:scale-100 transition">
                  <div className="flex items-center gap-2">
                    <div className="text-sm text-[#333]">
                      {inviteJob?.credits}
                    </div>
                    <img src={FlexiCoin} className="h-4 w-4 " />
                  </div>
                </div>
              </Button>
            </div>
          </>
        )}

        {(!nextInvitation || useJobType === "") && (
          <>
            <h3 className="px-8 py-6 mb-4 ">
              Choose a existing job to invite the worker on or create a new one
            </h3>
            <div className="flex items-center justify-between px-8 pb-5 border-b">
              <div
                onClick={() => setUseJobType("0")}
                className={cn(
                  " text-sm flex items-center group cursor-pointer px-2 py-1 rounded bg-gray-100 hover:bg-gray-300",
                  useJobType === "0" && "bg-[#333] text-white hover:bg-#333]"
                )}
              >
                <p className="text-sm">Use an existing post</p>
              </div>
              <div
                onClick={() => setUseJobType("1")}
                className={cn(
                  "text-sm flex items-center group cursor-pointer px-2 py-1 rounded bg-gray-100 hover:bg-gray-300",
                  useJobType === "1" && "bg-[#333] text-white hover:bg-[#333]"
                )}
              >
                Create new job Post
              </div>
            </div>
            <div className=" flex items-center justify-end w-full gap-4 px-8 py-4">
              <Button
                disabled={useJobType === ""}
                onClick={() => {
                  if (useJobType === "1") {
                    navigate(`/job-post/invite/${workerID}`);
                  } else {
                    setNextInvitation(true);
                    //   handleInviteWorker();
                  }
                }}
                className="bg-none text-[0.9rem] disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-[#333] border group px-5 py-2 flex items-center bg-azulBonico hover:bg-azulOscuro cursor-pointer text-white rounded-full mt-1"
              >
                Next
                <div className="group-hover:translate-x-1 transition">
                  <ChevronRightIcon className="h-4 w-4" />
                </div>
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalInviteToJobWorker;
