import React from "react";
import { Skeleton } from "../skeleton";

const CardSkeleton = () => {
  return (
    <div className="flex flex-col w-full border rounded h-32 mb-4">
      <div className="flex flex-col gap-4 mx-8 mt-4 mb-2">
        <div className="flex items-center">
          <Skeleton className="w-10 h-10 rounded-full" />
          <Skeleton className="h-6 w-1/4 ml-4" />
        </div>
        <Skeleton className="h-4 w-1/3" />
        <Skeleton className="h-3 w-[60px]" />
      </div>
    </div>
  );
};

export default CardSkeleton;
