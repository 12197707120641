import React, { SetStateAction, Dispatch } from "react";
import { ActiveChats } from "src/types/types";
import { ReactComponent as Circle } from "../../../assets/img/circle.svg";
import { BASE_URL_IMG } from "src/Config/api.config";

interface ActiveChatCardProps {
  selectedWorkerChat: ActiveChats;
  chat: ActiveChats;
  refetch: () => void;
  setSelectedWorkerChat: Dispatch<SetStateAction<ActiveChats>>;
  today: string;
  msgDate: string;
  setShowSearchBar: Dispatch<SetStateAction<boolean>>;
}
const ActiveChatCard = ({
  selectedWorkerChat,
  chat,
  refetch,
  msgDate,
  today,
  setSelectedWorkerChat,
  setShowSearchBar,
}: ActiveChatCardProps) => {
  return (
    <div
      key={chat.chat_message_id}
      className={`hover:bg-blancoGris/30 dark:hover:bg-neutral-400/30 transition z-10 ${
        (chat.seen === "0" ||
          (selectedWorkerChat?.client_id === chat.client_id &&
            selectedWorkerChat?.job_id === chat.job_id)) &&
        "bg-azulBonico/30  dark:bg-neutral-400/30"
      } ${
        selectedWorkerChat?.client_id === chat.client_id &&
        selectedWorkerChat?.job_id === chat.job_id &&
        "border-l-4 border-slate-500 "
      } `}
      onClick={() => {
        refetch();
        // checkNewMessagesFromConversation(chat);
        setSelectedWorkerChat(chat);
        setShowSearchBar(false);
      }}
    >
      <div className="flex gap-2 py-4 px-2 border-b border-blue-100 dark:border dark:border-border justify-between  cursor-pointer  w-full h-full">
        <div className="flex gap-3">
          <div className="relative">
            {chat?.img_profile?.length < 5 ? (
              <p className="border font-medium rounded-full border-azulBonico text-azulBonico h-[44px] w-[44px] p-1 flex justify-center items-center">
                {chat.img_profile}
              </p>
            ) : (
              <img
                src={`${BASE_URL_IMG}${chat.img_profile}`}
                alt="employer image"
                className="w-[60px] h-[60px] rounded-full object-cover"
              />
            )}
            <Circle
              fill={`${
                chat.status === "OFFLINE" || chat.status === "INVISIBLE"
                  ? "#9c9d9d"
                  : "#14A800"
              }`}
              className="h-3.5 relative bottom-3 left-[34px] border-[2px] border-white rounded-full"
            />
          </div>
          <div className="flex flex-col justify-start items-start font-medium">
            <p className="text-sm">{chat.client_name}</p>
            <p className="text-sm ">
              {chat.job_title.length > 28
                ? chat.job_title?.slice(0, 28) + "..."
                : chat.job_title}
            </p>
            {chat.msg !== null ? (
              <>
                <p className="text-sm text-gray-500">
                  {chat.sender?.slice(0, 1) +
                    chat.sender?.slice(1).toLowerCase()}
                  :{" "}
                  {chat.msg.length > 26
                    ? chat.msg?.slice(0, 26) + "..."
                    : chat.msg}
                </p>
              </>
            ) : (
              <p className="text-sm text-grayLight">{chat.title}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <p className="text-sm text-gray-500">
            {today === msgDate ? (
              <>
                {chat.msg_time.split(":")[0]}:{chat.msg_time.split(":")[1]}
              </>
            ) : (
              <>
                {chat.msg_date?.slice(8)}/{chat.msg_date?.slice(5, 7)}
              </>
            )}
          </p>
          {chat.new_messages !== "0" && (
            <p className="text-white font-medium text-sm h-5 flex items-center justify-center bg-[#B91919] w-5 rounded-full border border-[#B91919]">
              {chat.new_messages}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveChatCard;
