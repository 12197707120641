import React, { useState } from "react";

import { ReactComponent as Question } from "../../assets/img/question-mark.svg";
import {
  HandleMonthName,
  HandleMonthNameES,
} from "../../Utils/HandleMonthName";
import { useTranslation } from "react-i18next";
import { formatJobTitle } from "src/Utils/formatters";
import { ArchivedCounters, OfferArchived } from "src/types/types";
import { RiLoader2Fill } from "react-icons/ri";

interface WorkerActiveJobsProps {
  counter: ArchivedCounters;
  jobs: OfferArchived;
  isLoading: boolean;
}
const WorkerArchivedJobs = ({
  counter,
  jobs,
  isLoading,
}: WorkerActiveJobsProps) => {
  const [showPopover, setShowPopover] = useState({
    archived: false,
    expired: false,
  });

  const { t, i18n } = useTranslation();
  const handleMouseEnter = (props: string) => {
    switch (props) {
      case "archived":
        setShowPopover((prev) => ({ ...prev, archived: true }));
        break;
      case "expired":
        setShowPopover((prev) => ({ ...prev, expired: true }));
        break;
    }
  };
  const handleMouseLeave = (props: string) => {
    switch (props) {
      case "archived":
        setShowPopover((prev) => ({ ...prev, archived: false }));
        break;
      case "expired":
        setShowPopover((prev) => ({ ...prev, expired: false }));
        break;
    }
  };

  return (
    <div>
      <div className="w-full bg-white mt-4 border border-border rounded-lg flex flex-col dark:bg-background dark:border-border">
        <div className="flex gap-5 p-6 items-center">
          <div className="text-lg lg:text-xl font-semibold flex items-center">
            {t("worker.myjobs.body.appliedjobs.layer2.declinedoffer")} (
            {isLoading ? (
              <span>
                <RiLoader2Fill className="h-4 w-4 animate-spin" />
              </span>
            ) : (
              <>{counter?.declined}</>
            )}
            )
          </div>
          <div className="relative">
            <Question
              className="h-4 w-fit cursor-pointer"
              fill={"#2abeeb"}
              onMouseEnter={() => handleMouseEnter("archived")}
              onMouseLeave={() => handleMouseLeave("archived")}
            />
            <div
              className={`inline-block absolute bottom-9 z-50 -left-4 w-64 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 shadow dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                showPopover.archived === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1 transition-opacity duration-300"
              }
            `}
            >
              <div className="py-2 px-3">
                <p>{t("worker.archived_jobs.section_displays")}</p>
              </div>
            </div>
            <div
              className={`bg-white z-50 border-r-2 border-b-2 w-4 h-4 rotate-45 absolute -top-[27px] left-0.5 
            ${
              showPopover.archived === false
                ? "opacity-0 transition-opacity duration-300"
                : " opacity-1  transition-opacity duration-300"
            } `}
            ></div>
          </div>
        </div>
        {jobs.declined?.map((job) => {
          return (
            <div
              key={job.title}
              className="border-t border-slate-200 dark:border-border"
            >
              <div className="py-4 px-6 flex justify-between ">
                <p className="text-sm tracking-wider text-azulBonico font-semibold">
                  {job.title !== null ? (
                    <>{formatJobTitle(job.title)}</>
                  ) : (
                    <>{t("job.not_found.title_null")}</>
                  )}
                </p>
                <div className="flex-[0_0_40%] flex-col text-sm tracking-wider  text-left">
                  {i18n.language === "en" ? (
                    <p>
                      Declined on{" "}
                      {HandleMonthName(job?.declined_date?.slice(5, 7))}{" "}
                      {job?.declined_date?.slice(8, 10)},{" "}
                      {job?.declined_date?.slice(0, 4)}
                    </p>
                  ) : (
                    <p>
                      Rechazado el {job?.declined_date?.slice(8, 10)} de{" "}
                      {HandleMonthNameES(job?.declined_date?.slice(5, 7))} del{" "}
                      {job?.declined_date?.slice(0, 4)}
                    </p>
                  )}
                  <p className="text-xs text-slate-700 mt-2">
                    {job.decline_reason}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bg-white mt-4 border border-slate-200 rounded-lg flex flex-col dark:border-border dark:bg-background">
        <div className="flex gap-5 p-6 items-center">
          <p className="text-lg lg:text-xl flex items-center font-semibold">
            {t("worker.myjobs.body.appliedjobs.layer2.expiredoffer")} (
            {isLoading ? (
              <span>
                <RiLoader2Fill className="h-4 w-4 animate-spin" />
              </span>
            ) : (
              <>{jobs?.expired?.length}</>
            )}
            )
          </p>
          <div className="relative">
            <Question
              className="h-4 w-fit cursor-pointer"
              fill={"#2abeeb"}
              onMouseEnter={() => handleMouseEnter("expired")}
              onMouseLeave={() => handleMouseLeave("expired")}
            />
            <div
              className={`inline-block absolute bottom-9 z-50 -left-4 w-64 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 shadow dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                showPopover.expired === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1 transition-opacity duration-300"
              }
            `}
            >
              <div className="py-2 px-3">
                <p>{t("worker.archived_jobs.offers")} </p>
              </div>
            </div>
            <div
              className={`bg-white z-50 border-r-2 border-b-2 w-4 h-4 rotate-45 absolute -top-[27px] left-0.5 
            ${
              showPopover.expired === false
                ? "opacity-0 transition-opacity duration-300"
                : " opacity-1  transition-opacity duration-300"
            } `}
            ></div>
          </div>
        </div>
        {jobs.expired?.map((job) => {
          return (
            <div key={job.title} className="border-t border-slate-200">
              <div className="py-4 px-6 flex justify-between">
                <p className="text-sm tracking-wider text-azulBonico font-semibold">
                  {formatJobTitle(job.title)}
                </p>
                <div className="flex-[0_0_40%] flex-col text-sm tracking-wider text-left">
                  {i18n.language === "en" ? (
                    <p>
                      {t("worker.worker_archived.expired_on")}{" "}
                      {HandleMonthName(job?.date_expire_offer?.slice(5, 7))}{" "}
                      {job?.date_expire_offer?.slice(8, 10)},{" "}
                      {job?.date_expire_offer?.slice(0, 4)}
                    </p>
                  ) : (
                    <p>
                      {t("worker.worker_archived.expired_on")}{" "}
                      {job?.date_expire_offer?.slice(8, 10)} de{" "}
                      {HandleMonthNameES(job?.date_expire_offer?.slice(5, 7))}{" "}
                      del {job?.date_expire_offer?.slice(0, 4)}
                    </p>
                  )}
                  <p className="text-xs text-slate-700 mt-2">
                    {job?.decline_reason}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="bg-white mt-8 p-6 border border-slate-200 rounded-lg flex items-center gap-5">
        <p className="text-xl font-semibold">Closed jobs (0)</p>
        <div className="relative">
          <Question
            className="h-4 w-fit cursor-pointer"
            fill={"#2abeeb"}
            // onMouseEnter={() => handleMouseEnter("offers")}
            // onMouseLeave={() => handleMouseLeave("offers")}
          />

        </div>
      </div> */}
    </div>
  );
};

export default WorkerArchivedJobs;
