import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
} from "react";
import { BASE_URL_IMG } from "src/Config/api.config";
import { ReactComponent as Company } from "src/assets/img/company.svg";
import { ReactComponent as Check } from "src/assets/img/check.svg";
import { ReactComponent as Edit } from "src/assets/img/edit.svg";
import { convertCreditsToEUR } from "src/Utils/conversionEur";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { showEditableCompany } from "src/Atoms/jotaiAtoms";
import { cn } from "src/lib/utils";
import { useAtom } from "jotai";
import { useEmployerStore } from "src/Routing/useProfileStore";
import { Skeleton } from "src/Components/ui/skeleton";
import Flexicoin from "src/assets/img/flexicoin-nobg.png";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import StepsRightContainer from "../HowItWorks/StepsRightContainer";
import { useWalletSore } from "src/Hooks/useWalletStore";

interface DashboardRightContainerProps {
  clientData: any;
  loadingCredits: boolean;
  setShowInstructionsCollapsed: Dispatch<SetStateAction<boolean>>;
  showInstructionsCollapsed: boolean;
}
const DashboardRightContainer = ({
  clientData,
  loadingCredits,
  showInstructionsCollapsed,
  setShowInstructionsCollapsed,
}: DashboardRightContainerProps) => {
  const [showEditCompany, setShowEditCompany] = useAtom(showEditableCompany);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);

  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);

  return (
    <div className="laptop:flex-[0_0_40%] mt-6 flex gap-12 flex-col items-right w-full pl-8">
      {clientData?.company_id && (
        <div className="flex flex-col bg-white border border-border rounded ">
          <div className="flex items-center justify-between px-4">
            <div className="flex items-center space-x-4">
              {!clientData.company_logo ? (
                <div className="rounded-full bg-white p-4">
                  <Company className="h-9 w-9" />
                </div>
              ) : (
                <img
                  loading="lazy"
                  src={`${BASE_URL_IMG}${clientData.company_logo}`}
                  className="h-16 w-16 object-cover rounded-full"
                  alt="profile"
                />
              )}
              <div className="flex flex-col items-start">
                <p className="text-xl font-medium">
                  {clientData?.company_name}
                </p>
                <div className="flex items-center gap-2 mt-2">
                  {clientData.company_validated === "1" ? (
                    <>
                      <Check className="h-4 w-fit fill-[rgb(156,163,175)]" />
                      <p className="text-xs text-gray-400">
                        {t("employer.dashboard.not_validated")}
                      </p>
                    </>
                  ) : (
                    <>
                      <Check className="h-4 w-fit fill-azulBonico" />
                      <p className="text-xs text-azulBonico">
                        {t("employer.dashboard.validated")}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div>
              <Edit
                className="h-4 ml-5 fill-gray-500 hover:fill-black cursor-pointer"
                onClick={() => {
                  setShowEditCompany(true);
                  navigate("/settings");
                }}
              />
            </div>
          </div>
        </div>
      )}
      {/* Right Container */}
      {clientData?.company_id !== null && (
        <div ref={modalRef} className=" items-center relative  hidden lg:flex ">
          <div
            className={cn(
              "flex items-center gap-2 bg-gray-100 dark:bg-input py-2 px-2 rounded border border-border"
            )}
          >
            <p className="text-sm flex items-center gap-1 ">
              {!loadingCredits ? (
                <>
                  <span className="font-bold dark:text-whiteish">
                    {creditsNumber}
                  </span>
                  <img src={Flexicoin} className="h-5 w-6 object-contain" />
                </>
              ) : (
                <Skeleton className="w-20 h-4" />
              )}
              <span>|</span>

              <p className=" font-semibold dark:text-whiteish text-gray-400 text-[12px]">
                {convertCreditsToEUR(creditsNumber)}
              </p>
            </p>
          </div>

          <div className=" relative z-10">
            <QuestionMarkCircleIcon className="h-4 w-4 ml-6 cursor-pointer peer hover:bg-black transition hover:text-white rounded-full " />
            <div className=" absolute  -top-12 -left-16 transition  hidden peer-hover:block w-28  bg-white shadow py-1 px-2 rounded">
              <p className="text-xs text-center ">
                {t("employer.dashboard.credits.credits_available")}*
              </p>
            </div>
          </div>
        </div>
      )}
      <Suspense fallback={null}>
        <StepsRightContainer
          showInstructionsCollapsed={showInstructionsCollapsed}
          setShowInstructionsCollapsed={setShowInstructionsCollapsed}
        />
      </Suspense>
    </div>
  );
};

export default DashboardRightContainer;
