import React from "react";

import WorkerCards from "./WorkerCards";
import { useTranslation } from "react-i18next";
import { ApplicantTypes } from "../../types/types";

interface AllApplicantsProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData: any;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  maxPages: any;
  currentPage: any;
  setCurrentPage: any;
  refetch: () => void;
}

const AllApplicants = ({
  applicantsData,
  setApplicantsData,
  setRefreshData,
  maxPages,
  currentPage,
  setCurrentPage,
  refetch,
}: AllApplicantsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {/* JOBSEEKERS CARDS */}
      <WorkerCards
        refetch={refetch}
        applicantsData={applicantsData}
        setApplicantsData={setApplicantsData}
        setRefreshData={setRefreshData}
      />

      <div
        className={`flex justify-center border-t border-blue-100 py-4 ${
          maxPages === currentPage && "hidden"
        } `}
      >
        <button
          className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100  bg-white rounded-full transition duration-150"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {t("employer.all_applicants.load_more")}
        </button>
      </div>
    </div>
  );
};

export default AllApplicants;
