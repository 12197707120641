import create from "zustand";

type StoreState = {
  showPreview: boolean;
  setShowPreview: (value: boolean) => void;
  toggleShowPreview: () => void;
};

const useShowComingSoon = create<StoreState>((set) => ({
  showPreview: false,
  setShowPreview: (value: boolean) => set({ showPreview: value }),
  toggleShowPreview: () =>
    set((state) => ({ showPreview: !state.showPreview })),
}));

export default useShowComingSoon;
