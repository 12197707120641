import React, { useEffect, useState } from "react";

import { JobOfferDetails } from "./JobOfferDetails";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { ModalAddCoverLetter } from "../Modals/ModalAddCoverLetter";
import { ModalInappropriateJob } from "../Modals/ModalInappropriateJob";
import { JobTypes } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import GoBackButton from "../ui/GoBack";
import { useQuery } from "react-query";

function OfferDetails() {
  const [job, setJob] = useState();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loading, setLoading] = useState({ skills: true, cover: true });
  const [letter, setLetter] = useState(false);
  const [jobInfo, setJobInfo] = useState();
  const [isApplied, setIsApplied] = useState();
  const [showModal, setShowModal] = useState<any>({
    cover: false,
    report: false,
  });
  const navigate = useNavigate();
  const params = useParams();

  const getOffer = async () => {
    const data = await fetch(
      `${BASE_URL}jobs?client_id=${userID}&job_id=${params.offerId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: offerInfoData, refetch } = useQuery(
    ["offer", params, letter],
    getOffer
  );

  useEffect(() => {
    setJob((prev: any) => ({
      ...prev,
      ...offerInfoData?.data[0],
      skill_list: offerInfoData?.skills,
    }));
    setJobInfo(offerInfoData);
  }, [offerInfoData]);

  const getMoreInfo = async () => {
    const data = await fetch(
      `${BASE_URL}worker.current.job.status?client_id=${userID}&job_id=${params.offerId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: moreInfoData } = useQuery(
    ["moreInfo", params.offerId],
    getMoreInfo
  );

  useEffect(() => {
    if (moreInfoData?.activeJobs.length > 0) {
      setJob((prev: any) => ({
        ...prev,
        ...moreInfoData?.activeJobs[0],
        active: true,
      }));
      setTimeout(() => {
        setLoading((prev) => ({ ...prev, cover: false }));
      }, 50);
    } else if (moreInfoData?.appliedJobs.length > 0) {
      setJob((prev: any) => ({
        ...prev,
        ...moreInfoData?.appliedJobs[0],
        applied: true,
      }));
    }
  }, [moreInfoData]);

  const saveData = new FormData();
  saveData.append("_method", "POST");
  saveData.append("client_id", userID);

  const saveJob = (id: string) => {
    saveData.append("job_id", id);
    fetch(`${BASE_URL}worker.saved.jobs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: saveData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.jobSaved === "ok") {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteData = new FormData();
  deleteData.append("client_id", userID);

  const deleteSavedJob = (id: string) => {
    fetch(`${BASE_URL}worker.saved.jobs?client_id=${userID}&job_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.deleteSavedJob === "ok") {
          refetch();
        }
      })

      .catch((err) => console.log(err));
  };

  const saveItOrNot = (job: JobTypes) => {
    if (job.saved === "true") deleteSavedJob(job.job_id);
    if (job.saved === "false") saveJob(job.job_id);
  };

  //apply

  const applyData = new FormData();
  applyData.append("_method", "POST");
  applyData.append("client_id", userID);

  const applyJob = (id: string, letter: string) => {
    applyData.append("job_id", id);
    applyData.append("cover_letter", letter);
    fetch(`${BASE_URL}worker.applied.jobs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: applyData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  const toApplyOrNotToApply = (job: JobTypes, letter: string) => {
    if (job.applied === "false") {
      applyJob(job.job_id, letter);
    } else if (job.applied === "true") {
      // cancelApplyJob(job.job_id);
      return;
    }
  };
  useEffect(() => {
    if (letter) {
      getOffer();
    }
  }, [letter]);

  return (
    <>
      <div className=" flex justify-between mt-8 items-center px-6">
        <div
          className="flex items-center mt-6 gap-2 fill-azulBonico cursor-pointer"
          onClick={() => navigate("/applied-jobs")}
          // onClick={() => navigate(-1)}
        >
          <GoBackButton />
        </div>
      </div>
      {/* <p className="text-2xl mx-6 mt-6 font-bold">
          {t("offer_details.job_details")}
        </p> */}
      {/* {loading.skills && loading.cover ? (
          <Suspense fallback={null}>
            <Loader />
          </Suspense>
        ) : ( */}
      <JobOfferDetails
        job={job!}
        setJob={setJob}
        saveItOrNot={saveItOrNot}
        // handleSaveJob={handleSaveJob}
        jobInfo={jobInfo!}
        setShowModal={setShowModal}
        isApplied={isApplied!}
        setIsApplied={setIsApplied}
        // applyJob={applyJob}
        // toApplyOrNotToApply={toApplyOrNotToApply}
      />
      {/* )} */}

      {showModal.report && (
        <ModalInappropriateJob
          showModal={showModal}
          setShowModal={setShowModal}
          job={job}
          setJob={setJob}
        />
      )}
      {showModal.cover && (
        <ModalAddCoverLetter
          showModal={showModal}
          setShowModal={setShowModal}
          job={job!}
          toApplyOrNotToApply={toApplyOrNotToApply}
        />
      )}
    </>
  );
}

export default OfferDetails;
