import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Suspense,
  lazy,
} from "react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

import useLocalStorage from "../../../Hooks/UseLocalStorage";

import JobCards from "src/Components/WorkerDashboard/JobCards/JobCards";
import DashboardRightContainer from "./DashboardRightContainer";
import SearchesDashboardWorker from "./SearchesDashboardWorker";

import { BASE_URL, INFO_WORKER } from "../../../Config/api.config";
import {
  ALL_FILTERS_INITIAL,
  allFiltersAppliedToJobSearch,
  counterApplied,
  counterSaved,
  jobFeedbackOptions,
  jobOfferAtom,
  loadingJob,
  savedJobsAtom,
  savedSearchesData,
  selectedTabHeaderWorker,
  whichModal,
} from "../../../Atoms/jotaiAtoms";

import ModalEditSavedJobsSearch from "../../../Components/Modals/ModalEditSavedJobsSearch";

import { ReactComponent as Search } from "../../../assets/img/search2.svg";
import { ReactComponent as Blooming } from "../../../assets/img/blooming.svg";
import { ReactComponent as Preferences } from "../../../assets/img/preferences.svg";
import { ReactComponent as Cross } from "../../../assets/img/cross.svg";
import { ReactComponent as ArrowRight } from "../../../assets/img/arrow-right.svg";
import { RiMoreLine } from "react-icons/ri";

import { VerticalLayout } from "../Layout/VerticalLayout";
import { useTranslation } from "react-i18next";
import {
  ParamsToSendProps,
  useCategories,
  useNumberOfAppliedJobs,
  useOffers,
  usePreviosulySearchedJob,
} from "../../../Utils/services/apiCalls";
import DashboardWorkerTabs from "./DashboardWorkerTabs";
import { useQuery } from "react-query";
import { useGetFeedbackJobs } from "src/Hooks/useFeedbackJobs";
import { JobTypes } from "src/types/types";
import ModalNewOffer from "src/Components/Modals/ModalNewOffer";
import { useModalStore } from "src/Hooks/useModalStore";
import { userProfileStore } from "src/Routing/useProfileStore";

const INITIAL_PARAMS = {
  budget: "",
  category_id_list: "",
  city_id_list: "",
  country_code: "",
  find: "",
  job_type_id_list: "",
  post_code_id_list: "",
  postcode_id_list: "",
  skill_id_list: "",
};

export default function DashboardWorker() {
  const [offerMatches, setOfferMatches] = useState<any>([]);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);

  const [previouslySearchedJobs, setPreviouslySearchedJobs] = useState([]);
  const [offers, setOffers] = useAtom(jobOfferAtom);
  const [savedJobs, setSavedJobs] = useAtom(savedJobsAtom);

  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [desiredJob, setDesiredJob] = useLocalStorage("desiredJob", "");
  const [addSearch, setAddSearch] = useState(false);
  const [searchedJobs, setSearchedJobs] = useLocalStorage("searchedJobs", []);

  const [goodMorningBanner, setGoodMorningBanner] = useState(true);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const [openModal, setOpenModal] = useAtom(whichModal);
  const [showLoader, setShowLoader] = useState(false);
  const [invitedJobs, setInvitedJobs] = useState<any>();
  const [savedJobsCounter, setSavedJobsCounter] = useAtom(counterSaved);
  const [appliedJobsCounter, setAppliedJobsCounter] = useAtom(counterApplied);
  const [selected, setSelected] = useState<string>("");
  const [updateSaved, setUpdateSaved] = useState(false);
  const [showEditSavedJobsSearch, setShowEditSavedJobsSearch] = useState(false);
  const [savedSearchedJobsData, setSavedSearchedJobsData] =
    useAtom(savedSearchesData);
  const [savedSearchesFilters, setSavedSearchesFilters] = useAtom(
    allFiltersAppliedToJobSearch
  );
  const [updateApplied, setUpdateApplied] = useState(false);
  const [openDropdownSearches, setOpenDropdownSearches] = useState(false);
  const [searchSelected, setSearchSelected] = useState("");
  const [updateCollapse, setUpdateCollapse] = useState(false);
  const [paramsToSend, setParamsToSend] =
    useState<ParamsToSendProps>(INITIAL_PARAMS);
  const [selectedTab, setSelectedTab] = useAtom(selectedTabHeaderWorker);
  const [appliedCounter, setAppliedCounter] = useState<
    | {
        active: number;
        applied: number;
        offered: number;
      }
    | any
  >({ active: 0, applied: 0, offered: 0 });

  const [showSavedSearchesPopover, setShowSavedSearchesPopover] =
    useState(false);
  const [notInterestedOptions, setNotInterestedOptions] =
    useAtom(jobFeedbackOptions);

  const [loadingCategories, setLoadingCategories] = useState(false);
  const openDropdownRef = useRef<HTMLDivElement>(null);
  const [showNewOffer, setShowNewOffer] = useModalStore((state) => [
    state.showNewOffer,
    state.setShowNewOffer,
  ]);

  const [workerCategories, setWorkerCategories] = useState([]);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const searchesRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedTab("dashboard");
    if (profileType !== "worker" && profileType) {
      if (profileType === "employer") {
        navigate("/dashboard");
      } else {
        navigate("/dashboard-worker");
      }
    }
  }, []);

  const getCategories = async () => {
    const response = await fetch(
      `${BASE_URL}worker.category?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const { data: workerCategoriesData, isLoading: loadingWorkerCategories } =
    useQuery({
      queryKey: ["categories"],
      queryFn: getCategories,
    });

  useEffect(() => {
    setWorkerCategories(workerCategoriesData?.result);
  }, [workerCategoriesData]);
  // const {
  //   categories: workerCategories,
  //   getCategories,
  //   setCategories: setWorkerCategories,
  //   loading: loadingWorkerCategories,
  // } = useCategories();

  // useEffect(() => {
  //   if (loadingWorkerCategories) {
  //     setLoadingCategories(true);
  //   } else {
  //     setTimeout(() => {
  //       setLoadingCategories(false);
  //     }, 500);
  //   }
  // }, [loadingWorkerCategories]);

  // GET OFFERS

  const searchOffers = async () => {
    const data = await fetch(`${BASE_URL}worker.matches?client_id=${userID}`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: offersData,
    refetch: getOffersMatches,
    isLoading: isLoadingOfferMatches,
  } = useQuery(["offers"], searchOffers);

  useEffect(() => {
    if (!isLoadingOfferMatches) {
      setOfferMatches(offersData);
    }
  }, [offersData]);

  const { feedbackDataOptions } = useGetFeedbackJobs();

  useEffect(() => {
    setNotInterestedOptions(feedbackDataOptions);
  }, [feedbackDataOptions]);

  const {
    error: errorAppliedJobs,
    loading: loadingAppliedJobs,
    getNumberOfAppliedJobs,
    appliedJobs,
    setAppliedJobs,
  } = useNumberOfAppliedJobs();

  useEffect(() => {
    setAppliedJobsCounter(appliedJobs?.length);
  }, [appliedJobs]);

  const getCurrentJobStatus = async () => {
    const data = await fetch(
      `${BASE_URL}worker.current.job.status?client_id=${userID}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: currentJobStatus, isLoading: loadingAppliedJobsNumber } =
    useQuery(["current_job"], getCurrentJobStatus);

  useEffect(() => {
    if (currentJobStatus?.offerJobs?.length === 1) {
      setShowNewOffer(showNewOffer);
    }
  }, [currentJobStatus]);

  useEffect(() => {
    setAppliedCounter({
      active: currentJobStatus?.numberOfActiveJobs,
      applied: currentJobStatus?.numberOfAppliedJobs,
      offered: currentJobStatus?.numberOfOfferJobs,
    });
  }, [currentJobStatus]);

  const {
    error: errorPreviouslySearchedJob,
    loading: loadingPreviouslySearchedJob,
    getPreviouslySearchedJobs,
    offers: offerPreviouslySearchedJob,
  } = usePreviosulySearchedJob({ paramsToSend });

  useEffect(() => {
    getPreviouslySearchedJobs({
      paramsToSend: {
        find: "",
        budget: "",
        category_id_list: "",
        city_id_list: "",
        country_code: "",
        job_type_id_list: "",
        post_code_id_list: "",
        postcode_id_list: "",
        skill_id_list: "",
      },
    });
  }, []);

  useEffect(() => {
    if (offerPreviouslySearchedJob?.length > 0) {
      setOffers(offerPreviouslySearchedJob);
    }
  }, [offerPreviouslySearchedJob]);
  useEffect(() => {
    if (offerPreviouslySearchedJob?.length > 0) {
      setPreviouslySearchedJobs(offerPreviouslySearchedJob);
    }
  }, [offerPreviouslySearchedJob]);

  useEffect(() => {
    if (!loadingPreviouslySearchedJob) {
      getPreviouslySearchedJobs({ paramsToSend });
    }
  }, [paramsToSend, loadingPreviouslySearchedJob]);

  const getSavedJobsSearch = async () => {
    const data = await fetch(
      `${BASE_URL}job.saved.search?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: savedJobSearchData, refetch: refetchSavedSearches } = useQuery(
    ["savedjobs_search"],
    getSavedJobsSearch
  );

  useEffect(() => {
    if (savedJobSearchData?.length === 0) {
      setSelected("bestMatches");
      setShowEditSavedJobsSearch(false);
    } else {
      setSelected("mySearches");
    }
    setSavedSearchedJobsData(savedJobSearchData);
  }, [savedJobSearchData]);

  useEffect(() => {
    setOpenModal("");
    setDesiredJob("");
    setSavedSearchesFilters(ALL_FILTERS_INITIAL);
  }, []);

  const handleJobSearch = (e: string) => {
    setDesiredJob(e);
  };

  useEffect(() => {
    if (addSearch === true) {
      if (searchedJobs[0] === null) {
        setSearchedJobs([desiredJob]);
      } else if (!searchedJobs.includes(desiredJob)) {
        setSearchedJobs((prev: any) => [...prev, desiredJob]);
      }
    }
  }, [addSearch]);

  const getInvitationsToJob = async () => {
    const data = await fetch(`${BASE_URL}worker.invites?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result.invites;
  };

  const { data: invitedJobsData, isLoading: isLoadingInvited } = useQuery(
    ["invited_jobs"],
    getInvitationsToJob,
    {
      enabled: selected === "invitations",
    }
  );

  useEffect(() => {
    setInvitedJobs(invitedJobsData);
  }, [invitedJobsData]);

  const getSavedJobsCounter = async () => {
    const data = await fetch(
      `${BASE_URL}worker.saved.jobs?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer` + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: savedJobsCounterdata, isLoading: isCounterLoadingJobs } =
    useQuery(["savedjobs_counter"], getSavedJobsCounter);

  useEffect(() => {
    if (!isCounterLoadingJobs) {
      setSavedJobsCounter(savedJobsCounterdata?.numberOfSavedJobs);
    }
  }, [savedJobsCounterdata]);

  const getSavedJobs = async () => {
    const data = await fetch(
      `${BASE_URL}jobs?client_id=${userID}&filter_field=saved`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      // .then((data) => setSavedJobs(data.result))
      .catch((err) => console.log(err));

    return data.result;
  };
  const {
    data: dataSavedJobs,
    isLoading: isLoadingSaved,
    refetch: refetchSavedJobs,
  } = useQuery(["saved_jobs", updateSaved], getSavedJobs);

  useEffect(() => {
    if (!isLoadingSaved) {
      setSavedJobs(dataSavedJobs);
    }
  }, [dataSavedJobs]);

  const handleSubmit = () => {
    addToSearchedJobs(desiredJob);
    setTimeout(() => {
      navigate("/jobs-search");
    }, 200);
  };

  useEffect(() => {
    if (savedSearchedJobsData?.length > 0 && !paramsToSend) {
      setParamsToSend(JSON.parse(savedSearchedJobsData[0].params_json));
      setSearchSelected(savedSearchedJobsData[0].search_name);
    }
  }, [savedSearchedJobsData]);

  useEffect(() => {
    if (selected === "savedJobs") {
      refetchSavedJobs();
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 150);
    }
    if (selected === "mySearches") {
      if (savedSearchedJobsData?.length > 0) {
        setParamsToSend(JSON.parse(savedSearchedJobsData[0].params_json));
        setSearchSelected(savedSearchedJobsData[0].search_name);
      }

      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 150);
    }

    if (selected === "bestMatches") {
      getOffersMatches();
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 150);
    }
  }, [selected]);

  const [currentMonth, setCurrentMonth] = useState<any>("");
  const [currentDate, setCurrentDate] = useState<any>("");
  const [currentDay, setCurrentDay] = useState<any>("");
  const today = new Date();
  useEffect(() => {
    setCurrentDate(today.getDate());
    const day = today.getDay();
    const month = today.getMonth() + 1;
    setCurrentDay(() => {
      switch (day) {
        case 1:
          return `${t("createprofile.schedule.card1.daytitle")}`;
        case 2:
          return `${t("createprofile.schedule.card2.daytitle")}`;
        case 3:
          return `${t("createprofile.schedule.card3.daytitle")}`;
        case 4:
          return `${t("createprofile.schedule.card4.daytitle")}`;
        case 5:
          return `${t("createprofile.schedule.card5.daytitle")}`;
        case 6:
          return `${t("createprofile.schedule.card6.daytitle")}`;
        case 0:
          return `${t("createprofile.schedule.card7.daytitle")}`;
      }
    });
    setCurrentMonth(() => {
      switch (month) {
        case 1:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month1.sortbyDropdown"
          )}`;
        case 2:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month2.sortbyDropdown"
          )}`;
        case 3:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month3.sortbyDropdown"
          )}`;
        case 4:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month4.sortbyDropdown"
          )}`;
        case 5:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month5.sortbyDropdown"
          )}`;
        case 6:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month6.sortbyDropdown"
          )}`;
        case 7:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month7.sortbyDropdown"
          )}`;
        case 8:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month8.sortbyDropdown"
          )}`;
        case 9:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month9.sortbyDropdown"
          )}`;
        case 10:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month10.sortbyDropdown"
          )}`;
        case 11:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month11.sortbyDropdown"
          )}`;
        case 12:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month12.sortbyDropdown"
          )}`;
      }
    });
  }, []);

  const addToSearchedJobs = (e: any) => {
    if (searchedJobs[0] === null) {
      setSearchedJobs([desiredJob]);
    } else if (!searchedJobs.includes(e)) {
      setSearchedJobs((prev: any) => [...prev, e]);
    }
  };

  useEffect(() => {
    const checkIfClickedOutsideSavedSearchPopover = (e: any) => {
      if (
        showSavedSearchesPopover &&
        searchesRef.current &&
        !searchesRef.current.contains(e.target)
      ) {
        setShowSavedSearchesPopover(false);
      }
    };
    document.addEventListener(
      "mousedown",
      checkIfClickedOutsideSavedSearchPopover
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideSavedSearchPopover
      );
    };
  }, [showSavedSearchesPopover]);

  useEffect(() => {
    const checkIfClickedOutsideSearchesDropdown = (e: any) => {
      if (
        openDropdownSearches &&
        openDropdownRef.current &&
        !openDropdownRef.current.contains(e.target)
      ) {
        setOpenDropdownSearches(false);
      }
    };
    document.addEventListener(
      "mousedown",
      checkIfClickedOutsideSearchesDropdown
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        checkIfClickedOutsideSearchesDropdown
      );
    };
  }, [openDropdownSearches]);

  const getWorkerInformation = async () => {
    const data = await fetch(`${BASE_URL}${INFO_WORKER}?client_id=${userID}`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: profileData,
    isLoading,
    refetch: refetchWorkerData,
  } = useQuery(["worker_info"], getWorkerInformation);

  return (
    <>
      <div className="flex-[0_0_100%] max-w-[0_0_100%] mobile:flex-[0_0_85%] mobile:max-w-[85%] tablet:flex-[0_0_90%] tablet:max-w-[90%] mx-auto laptop:hidden p-2 mt-24 laptop:mt-6 ">
        <div className="flex flex-col rounded-t-lg rounded-b-lg tracking-wider font-bold">
          <div
            className="flex justify-between items-center px-8 py-4 cursor-pointer hover:bg-[#f7f4f4]"
            onClick={() => navigate("/applied-jobs")}
          >
            <p>{t("worker.dashboard.applied_jobs")}</p>
            <ArrowRight className="h-4 fill-black" />
          </div>
          <div
            className="flex justify-between items-center px-8 py-4 border-t border-blue-100 cursor-pointer hover:bg-[#f7f4f4]"
            onClick={() => navigate("/profile")}
          >
            <p>{t("worker.dashboard.my_profile")}</p>
            <ArrowRight className="h-4 fill-black" />
          </div>
        </div>
      </div>
      <div className="block laptop:!flex relative ">
        <div className="flex-[0_0_100%] max-w-[0_0_100%] mobile:flex-[0_0_85%] mobile:max-w-[85%] tablet:flex-[0_0_90%] tablet:max-w-[90%] mx-auto laptop:!flex-[0_0_75%] laptop:!max-w-[75%] p-2 mt-4">
          {/* <div
              className={`rounded border bg-white border-blue-100 mb-6 ${
                hideApplyJobsBanner === true && "hidden"
              } `}
            >
              <div className="flex justify-between p-4">
                <Lottie options={defaultOptions} height={150} width={150} />
                <div className="flex flex-col mr-11">
                  <div className="relative">
                    <h1 className="text-xl font-bold tracking-wider">
                      Apply for jobs and find now what you are looking for.{" "}
                    </h1>
                    <VscClose
                      color="black"
                      size={19}
                      className="cursor-pointer absolute -top-3 -right-14"
                      onClick={() => {
                        setHideApplyJobsBanner(true);
                        setGoodMorningBanner(false);
                      }}
                    />
                  </div>
                  <p className="text-sm">
                    Set the skills you feel confident with, and we will match
                    them with suitable job positions.
                  </p>
                  <button
                    className="px-5 py-[2px] mt-12 text-white bg-azulBonico hover:bg-azulOscuro rounded-full w-fit transition duration-300"
                    onClick={() => navigate("/jobs-search")}
                  >
                    Start applying now
                  </button>
                </div>
              </div>
            </div> */}
          <div
            className={`hidden laptop:!block rounded-lg  bg-azulBonico  text-white mb-6 ${
              goodMorningBanner === false && "hidden"
            } `}
          >
            <div className="flex justify-around p-4 ">
              <div className="flex flex-col">
                <p className="text-[22px] font-[700]">
                  {currentDay},
                  {i18n.language === "en" ? (
                    <>{` ${currentMonth} ${currentDate}`}</>
                  ) : (
                    <>{` ${currentDate} de ${currentMonth}`}</>
                  )}
                </p>
                <h1 className="text-[36px] font-[700] tracking-wider">
                  {t("worker.find_work.dashboard.introduction.hello")},{" "}
                  {profileData?.data[0]?.first_name?.charAt(0).toUpperCase() +
                    profileData?.data[0]?.first_name?.slice(1)}
                  .
                </h1>
              </div>
              <Blooming className="h-24 w-fit" />
            </div>
          </div>
          <form
            className="relative hidden laptop:!block "
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            onClick={() => setOpenDropdownSearches(true)}
          >
            <input
              ref={ref}
              type="text"
              className="w-full mb-4  dark:bg-input dark:text-whiteish  h-10 pl-4 outline-none text-sm rounded shadow hover:border-azulBonico focus:border-azulBonico transition duration-300"
              placeholder={`${t("worker.dashboard.inputPlaceholder")}`}
              // onChange={(e) => optimisedSearch(e.target.value)}
              onChange={(e) => handleJobSearch(e.target.value)}
            />

            <div
              className="h-10 w-9 bg-azulBonico hover:bg-azulOscuro transition duration-300 absolute right-0 top-0 rounded-r cursor-pointer"
              onClick={() => {
                // addToSearchedJobs();
                if (desiredJob.length > 0) {
                  setAddSearch(true);
                  // setTimeout(() => {
                  //   navigate("/jobs-search");
                  // }, 100);
                }
              }}
            >
              <Search
                fill={"white"}
                className="h-4 cursor-pointer absolute top-[12px] left-[10px]"
              />
            </div>
            {desiredJob?.length > 0 && (
              <Cross
                className="h-4 fill-slate-600 absolute right-11 bottom-[28px] cursor-pointer"
                // className="h-4 cursor-pointer right-11 bottom-[19px] absolute"
                fill={"#E8E8E8"}
                onClick={() => {
                  if (ref.current !== null) {
                    ref.current.value = "";
                  }
                  setDesiredJob();
                }}
              />
            )}
          </form>
          {/* {openDropdownSearches && (
            <div ref={openDropdownRef}>
              <div className="absolute top-56 overflow-auto text-sm w-[70%] border z-50 dark:bg-background dark:border-border dark:border border-blue-100 rounded shadow bg-white max-h-40 jobpost">
                <div className="p-1">
                  {searchedJobs[0] !== null && searchedJobs.length > 0 && (
                    <div className="z-20">
                      <h1 className="rounded p-1 font-bold text-[#333]">
                        {t("worker.dashboard.recent_searches")}
                      </h1>
                      {searchedJobs?.map((search: string) => {
                        return (
                          <div
                            key={search}
                            className="flex items-center justify-between hover:bg-gray-100  rounded p-1 z-50"
                          >
                            <button
                              className=" cursor-pointer px-2  w-full text-left"
                              onClick={(e) => {
                                e.preventDefault();
                                setDesiredJob(search);
                                navigate("/jobs-search");
                              }}
                            >
                              {search}
                            </button>
                            <CgClose
                              onClick={() =>
                                setSearchedJobs(
                                  searchedJobs.filter(
                                    (job: any) => job !== search
                                  )
                                )
                              }
                              className="h-4 w-4 hover:text-slate-700 cursor-pointer"
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {savedSearchedJobsData?.length > 0 && (
                    <div className="z-20">
                      <h1 className="rounded p-1 font-bold text-[#333] ">
                        {t("worker.dashboard.saved_searches")}
                      </h1>

                      {savedSearchedJobsData?.map(
                        (search: {
                          params_json: string;
                          saved_search_id: string;
                          search_name: string;
                        }) => {
                          return (
                            <div
                              key={search.saved_search_id}
                              className="flex items-center justify-between hover:bg-gray-100  rounded p-1 z-50"
                            >
                              <button
                                className="cursor-pointer px-2  w-full text-left"
                                onClick={(e) => {
                                  e.preventDefault();

                                  navigate("/jobs-search");
                                  setDesiredJob(search.search_name);
                                  setSavedSearchesFilters(
                                    JSON.parse(search.params_json)
                                  );
                                }}
                              >
                                {search.search_name}
                              </button>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )} */}
          {searchedJobs?.length > 0 && (
            <p className="!flex mb-6 text-sm font-bold tracking-wide ml-2 max-w-[700px] truncate">
              {/* <p className="hidden laptop:!flex mb-6 text-sm font-bold tracking-wide ml-2 max-w-[700px] truncate"> */}
              {t("worker.dashboard.recent_searches")}:&nbsp;&nbsp;&nbsp;
              <ul className="text-azulOscuro font-normal flex">
                {searchedJobs
                  ?.slice(-8)
                  .reverse()
                  ?.map((job: any, idx: number) => {
                    return (
                      <li key={job?.job_id}>
                        {/* <span>&nbsp;</span> */}
                        <div
                          className="flex cursor-pointer hover:underline mr-1  hover:text-azulOscuro"
                          onClick={(e: any) => {
                            setDesiredJob(job);
                            navigate("/jobs-search");
                          }}
                        >
                          {job}
                          {idx !== searchedJobs.length - 1 && <span>,</span>}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </p>
          )}

          <div className=" flex flex-col bg-white shadow rounded dark:bg-background dark:border-border dark:text-whiteish dark:border">
            <DashboardWorkerTabs
              savedSearchedJobsData={savedSearchedJobsData}
              selected={selected}
              setSelected={setSelected}
              invitedJobs={invitedJobs}
              // savedJobs={savedJobs.numberOfSavedJobs}
            />
            <div className="px-6 py-5 text-sm font-[500] border-b border-blue-100">
              {selected === "bestMatches" && (
                <p>{t("worker.findwork.card.layer2.subtitle")}</p>
              )}
              {/* {selected === "appliedJobs" && (
                    <p>Here you can review all the jobs you have applied to.</p>
                  )} */}
              {selected === "mySearches" && (
                <div className="flex justify-between items-center">
                  <p>{t("worker.findwork.card.layer1.subtitle")}</p>
                  <div
                    className=" relative border rounded-full p-1 bg-white hover:bg-blancoGris transition duration-150 cursor-pointer"
                    ref={searchesRef}
                  >
                    <RiMoreLine
                      size={20}
                      color="black"
                      onClick={() =>
                        setShowSavedSearchesPopover(!showSavedSearchesPopover)
                      }
                    />
                    <div
                      className={`inline-block  py-2.5 w-max absolute top-[42px] lg:top-[45px] right-0 border-2 lg:-right-[48px] z-10 font-light text-sm rounded-lg  border-gray-200  dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 bg-white ${
                        showSavedSearchesPopover === false
                          ? "hidden"
                          : "visible"
                      }`}
                    >
                      <div
                        className="py-2 px-3 hover:bg-blancoGris text-sm w-fit dark:hover:bg-background"
                        onClick={() => setShowEditSavedJobsSearch(true)}
                      >
                        {t("worker.dashboard.saved_searches")}
                      </div>
                    </div>
                    <div
                      className={`bg-white border-l-2 dark:bg-input dark:border-gray-600 border-t-2 z-20 w-4 h-4 rotate-45 absolute -bottom-[26px] left-1.5 ${
                        showSavedSearchesPopover === false
                          ? "hidden"
                          : "visible"
                      } `}
                    ></div>
                  </div>
                </div>
              )}
              {showEditSavedJobsSearch === true && (
                <ModalEditSavedJobsSearch
                  showEditSavedJobsSearch={showEditSavedJobsSearch}
                  setShowEditSavedJobsSearch={setShowEditSavedJobsSearch}
                  savedSearchedJobsData={savedSearchedJobsData}
                  getSavedJobsSearch={refetchSavedSearches}
                  // setSavedSearchedJobsData={setSavedSearchedJobsData}
                />
              )}
              {selected === "invitations" && (
                <p>{t("worker.findwork.card.layer3.subtitle")}</p>
              )}
              {selected === "savedJobs" && (
                <p>{t("worker.findwork.card.layer4.subtitle")}</p>
              )}
            </div>
            {selected === "bestMatches" && (
              <>
                {offerMatches?.length > 0 ? (
                  <JobCards
                    updateSaved={updateSaved}
                    setUpdateSaved={setUpdateSaved}
                    updateApplied={updateApplied}
                    setUpdateApplied={setUpdateApplied}
                    offers={offerMatches}
                    loading={loadingPreviouslySearchedJob}
                    setOffers={setOfferMatches}
                    savedJobsCounter={savedJobsCounter?.toString()}
                    setSavedJobsCounter={setSavedJobsCounter}
                    refetchSavedJobs={refetchSavedSearches}
                  />
                ) : (
                  <div className="my-12 flex flex-col justify-center items-center m-auto">
                    <Preferences className="h-20 w-40 object-cover lg:h-32" />
                    <p className="text-sm lg:text-lg font-semibold tracking-wide mx-6 lg:mx-24 mt-6">
                      {t("worker.findwork.card.layer2.downcard")}
                    </p>
                  </div>
                )}
              </>
            )}
            {selected === "invitations" && (
              <>
                {invitedJobs?.length > 0 ? (
                  <JobCards
                    updateSaved={updateSaved}
                    setUpdateSaved={setUpdateSaved}
                    updateApplied={updateApplied}
                    setUpdateApplied={setUpdateApplied}
                    offers={invitedJobs}
                    setOffers={setInvitedJobs}
                    savedJobsCounter={savedJobsCounter?.toString()}
                    setSavedJobsCounter={setSavedJobsCounter}
                    loading={isLoadingInvited}
                    refetchSavedJobs={refetchSavedSearches}
                  />
                ) : (
                  <div className="my-12 flex flex-col justify-center items-center m-auto">
                    <Preferences className="h-20 w-40 object-cover lg:h-32" />
                    <p className="text-sm lg:text-lg font-semibold tracking-wide mx-6 lg:mx-24 mt-6">
                      {t("worker.findwork.card.layer3.downcard")}
                    </p>
                  </div>
                )}
              </>
            )}
            {selected === "mySearches" && (
              <SearchesDashboardWorker
                showLoader={showLoader}
                setUpdateApplied={setUpdateApplied}
                setSearchSelected={setSearchSelected}
                searchSelected={searchSelected}
                setParamsToSend={setParamsToSend}
                updateSaved={updateSaved}
                setUpdateSaved={setUpdateSaved}
                updateApplied={updateApplied}
                offers={previouslySearchedJobs}
                setOffers={setPreviouslySearchedJobs}
              />
            )}

            {selected === "savedJobs" && (
              <>
                {savedJobs?.length > 0 ? (
                  <JobCards
                    loading={isLoadingSaved}
                    // searchedJobs={""}
                    updateSaved={updateSaved}
                    setUpdateSaved={setUpdateSaved}
                    updateApplied={updateApplied}
                    setUpdateApplied={setUpdateApplied}
                    offers={savedJobs}
                    setOffers={setSavedJobs}
                    savedJobsCounter={savedJobsCounter?.toString()}
                    setSavedJobsCounter={setSavedJobsCounter}
                    refetchSavedJobs={refetchSavedJobs}
                  />
                ) : (
                  <div className="my-12 flex flex-col justify-center items-center m-auto">
                    <Preferences className="h-20 w-40 object-cover lg:h-32" />
                    <p className="text-sm lg:text-lg font-semibold tracking-wide mx-6 lg:mx-24 mt-6 text-center">
                      {t("worker.dashboard.saved_desc_empty")}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Suspense fallback={null}>
          <DashboardRightContainer
            workerCategories={workerCategories}
            refetchCategories={getCategories}
            loadingWorkerCategories={loadingCategories}
            setWorkerCategories={setWorkerCategories}
            setAppliedCounter={setAppliedCounter}
            appliedCounter={appliedCounter}
            loadingAppliedJobsNumber={loadingAppliedJobsNumber}
          />
        </Suspense>
      </div>
      <ModalNewOffer jobOffer={currentJobStatus?.offerJobs[0]} />
    </>
  );
}
