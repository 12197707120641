import React from "react";
import { Skeleton } from "../skeleton";

const DraftsCardSkeleton = () => {
  return (
    <div className="flex flex-col bg-white w-full shadow h-28 mb-4 p-4 rounded-lg px-8">
      <div className="flex flex-col md-4">
        <Skeleton className="h-4 w-[20%] mb-2" />
        <div className="flex items-center justify-between">
          <Skeleton className="h-4 w-[25%] mb-2" />
          <Skeleton className="h-6 w-6 rounded-full" />
        </div>
        <Skeleton className="h-4 w-[30%] mb-2" />
      </div>
    </div>
  );
};

export default DraftsCardSkeleton;
