import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoTwo } from "../../assets/img/logo_2.svg";

import { TbWorld } from "react-icons/tb";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import { useTranslation } from "react-i18next";
import { useLanguages } from "../../Hooks/useLanguages";
import { cn } from "src/lib/utils";

interface SmallFooterProps {
  marginRight?: boolean;
}

const SmallFooter = ({ marginRight }: SmallFooterProps) => {
  const navigate = useNavigate();
  const [showChangeLanguage, setShowChangeLanguage] = useState(false);
  const [animationModal, setAnimationModal] = useState(false);

  useEffect(() => {
    if (showChangeLanguage) {
      setAnimationModal(true);
    } else {
      setAnimationModal(false);
    }
  }, [showChangeLanguage]);

  const { options, languageChosen } = useLanguages();

  const { i18n, t } = useTranslation();

  return (
    <div
      className={cn(
        ` bg-white hidden lg:block  dark:bg-background border-t border-border   dark:text-whiteish fixed sm:block bottom-0 laptop:w-full w-screen left-0 laptop:left-auto `,
        window.location.pathname !== "/how-to-employer-guide" &&
          window.location.pathname !== "/how-to-worker-guide"
          ? "z-10"
          : "z-[200]",
        window.location.pathname === "/profile" && "z-0"
      )}
    >
      {/* <div className="border-t border-[#F1EFEF]  py-5 px-8 flex justify-between"> */}
      <div className="  py-5 px-8 flex justify-between">
        <div className="flex items-center space-x-10">
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            <LogoTwo className="h-8" />
          </div>
          <div className="relative">
            {showChangeLanguage ? (
              <div
                className={`absolute z-50 -top-52 left-7  bg-white dark:text-black  px-4 w-40 py-4 rounded-lg  shadow-lg ${
                  animationModal
                    ? " translate-y-0 opacity-100 "
                    : "-translate-y-2 opacity-0 "
                } transform transition-all duration-200 ease-in-out`}
              >
                <h1 className="font-bold text-sm pb-5 ">
                  {" "}
                  {t("modal.add_language.language")}
                </h1>
                <div className="space-y-1">
                  {options?.map((e) => (
                    <div
                      key={e.value}
                      className={`text-[14px] mb-1 ${
                        e.value === i18n.language?.slice(0, 2)
                          ? "bg-gray-100"
                          : "text-gray-500"
                      } hover:bg-gray-200 transition-transform duration-150 ease-in-out w-full px-4 py-2 rounded cursor-pointer`}
                      onClick={() => {
                        setShowChangeLanguage(false);
                        i18n.changeLanguage(e.value);
                      }}
                    >
                      {e.label}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <div className="border px-2 mx-10 py-2 rounded-lg hover:bg-[#fafafa] transition transform duration-150 ease-in-out cursor-pointer">
              <button
                onClick={() => setShowChangeLanguage(!showChangeLanguage)}
                className="flex items-center space-x-2  "
              >
                <TbWorld color="gray" size={18} />
                <h1 className="font-normal text-gray-500 text-sm">
                  {languageChosen}
                </h1>
                {!showChangeLanguage ? (
                  <BiChevronDown color="gray" size={18} />
                ) : (
                  <BiChevronUp color="gray" size={18} />
                )}
              </button>
            </div>
          </div>
        </div>

        <div
          className={cn(
            `relative flex items-center `,
            marginRight && "lg:mr-80"
          )}
        >
          <div className="flex items-center gap-7 text-sm  ">
            <p
              className="hover:underline cursor-pointer font-normal"
              onClick={() => navigate("/legal")}
            >
              {t(
                "register.join.footer.login.middlefooter.termsconditions.middlelink1"
              )}
            </p>
            <p
              className="hover:underline cursor-pointer font-normal
              "
              onClick={() => navigate("/legal")}
            >
              {t(
                "register.join.footer.login.middlefooter.termsconditions.middlelink2"
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallFooter;
