import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "src/assets/img/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "src/assets/img/arrow-left.svg";

interface PaginationProps {
  pagination: {
    totalPages: any;
    currentPage: number;
    limit: number;
    pageNumbers: any;
  };
  setPagination: any;
  getDiscoverWorkers: (args: string) => void;
}

const PaginationFindWorkers = ({
  pagination,
  getDiscoverWorkers,
  setPagination,
}: PaginationProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    getDiscoverWorkers("");
  }, [pagination.currentPage, pagination.limit]);

  useEffect(() => {
    if (pagination.totalPages) {
      setPagination((prev: any) => ({
        ...prev,

        pageNumbers: [...Array(pagination.totalPages + 1).keys()]?.slice(1),
        // pageNumbers: [...(pagination.totalPages + 1).keys()]?.slice(1),
      }));
    }
  }, [pagination.totalPages]);

  function goToNextPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
    }));
  }

  function goToPreviousPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.currentPage - 1,
    }));
  }

  function goToFirstPage() {
    setPagination((prev: any) => ({ ...prev, currentPage: 1 }));
  }

  function goToLastPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.totalPages,
    }));
  }

  function changePage(page: any) {
    setPagination((prev: any) => ({ ...prev, currentPage: page }));
  }

  return (
    <div className="flex items-center gap-6 ">
      <button
        className={`text-sm font-medium cursor-pointer  ${
          pagination.currentPage == 1
            ? "text-slate-400 cursor-not-allowed"
            : "text-azulBonico hover:text-azulOscuro"
        } `}
        onClick={() => goToFirstPage()}
        disabled={pagination.currentPage == 1}
      >
        {t("employer.alljobs.first.right.Text")}
      </button>
      <button
        className={`flex items-center gap-3 ${
          pagination.currentPage == 1 ? "cursor-not-allowed" : "cursor-pointer"
        }  `}
        onClick={() => goToPreviousPage()}
        disabled={pagination.currentPage == 1}
      >
        <ArrowLeft
          className="h-5 "
          fill={`${
            pagination.currentPage == 1 ? "rgb(148 163 184)" : "#2abeeb"
          } `}
        />
        {/* <p
          className={`text-sm font-medium ${
            pagination.currentPage == 1
              ? "text-slate-400 cursor-not-allowed"
              : "text-azulBonico hover:text-azulOscuro"
          } `}
        >
          {t("employer.alljobs.previous.right.Text")}
        </p> */}
      </button>
      <div className=" font-medium flex gap-3">
        {pagination.currentPage < 4 ? (
          <>
            {pagination.pageNumbers?.slice(0, 4)?.map((page: number) => {
              return (
                <>
                  {page === pagination.currentPage ? (
                    <div
                      className="text-azulBonico decoration-[2px]  underline underline-offset-[6px] cursor-pointer"
                      onClick={() => changePage(page)}
                    >
                      &nbsp;&nbsp;{page}&nbsp;&nbsp;
                    </div>
                  ) : (
                    <div
                      className="hover:text-azulOscuro dark:text-whiteish hover:underline cursor-pointer"
                      onClick={() => changePage(page)}
                    >
                      &nbsp;{page}&nbsp;
                    </div>
                  )}
                </>
              );
            })}
          </>
        ) : (
          <>
            {pagination.currentPage >= 4 &&
            pagination.totalPages - 3 >= pagination.currentPage ? (
              <>
                {pagination.pageNumbers
                  ?.slice(
                    pagination.currentPage - 2,
                    pagination.currentPage + 1
                  )
                  ?.map((page: number) => {
                    return (
                      <>
                        {page === pagination.currentPage ? (
                          <div
                            className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                            onClick={() => changePage(page)}
                          >
                            &nbsp;&nbsp;{page}&nbsp;&nbsp;
                          </div>
                        ) : (
                          <div
                            className="hover:text-azulOscuro  dark:text-whiteish hover:underline cursor-pointer"
                            onClick={() => changePage(page)}
                          >
                            &nbsp;{page}&nbsp;
                          </div>
                        )}
                      </>
                    );
                  })}
              </>
            ) : (
              <>
                {pagination.totalPages - 3 < pagination.currentPage && (
                  <>
                    {pagination.pageNumbers
                      ?.slice(pagination.totalPages - 4, pagination.totalPages)
                      ?.map((page: number) => {
                        return (
                          <>
                            {page === pagination.currentPage ? (
                              <div
                                className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                onClick={() => changePage(page)}
                              >
                                &nbsp;&nbsp;{page}
                                &nbsp;&nbsp;
                              </div>
                            ) : (
                              <div
                                className="hover:text-azulOscuro dark:text-whiteish hover:underline cursor-pointer"
                                onClick={() => changePage(page)}
                              >
                                &nbsp;{page}&nbsp;
                              </div>
                            )}
                          </>
                        );
                      })}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <button
        className={`flex items-center gap-3 ${
          pagination.currentPage == pagination.totalPages
            ? "cursor-not-allowed"
            : "cursor-pointer"
        }  `}
        onClick={() => goToNextPage()}
        disabled={pagination.currentPage == pagination.totalPages}
      >
        {/* <p
          className={`text-sm font-medium ${
            pagination.currentPage == pagination.totalPages
              ? "text-slate-400 cursor-not-allowed"
              : "text-azulBonico hover:text-azulOscuro"
          } `}
        >
          {t("employer.alljobs.next.right.Text")}
        </p> */}
        <ArrowRight
          className="h-5 "
          fill={`${
            pagination.currentPage == pagination.totalPages
              ? "rgb(148 163 184)"
              : "#2abeeb"
          } `}
        />
      </button>
      <button
        className={`text-sm font-medium cursor-pointer  ${
          pagination.currentPage == pagination.totalPages
            ? "text-slate-400 cursor-not-allowed"
            : "text-azulBonico hover:text-azulOscuro"
        } `}
        onClick={() => goToLastPage()}
        disabled={pagination.currentPage == pagination.totalPages}
      >
        {t("employer.alljobs.last.right.Text")}
      </button>
    </div>
  );
};

export default PaginationFindWorkers;
