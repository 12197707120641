import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import Select from "react-select";

import "./SelectedSkills.css";
import "./TitleJob.css";
import "./Scope.css";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, CITIES, POSTCODES } from "../../Config/api.config";

import { ReactComponent as Spain } from "../../assets/img/spain.svg";
import { useTranslation } from "react-i18next";

interface JobTypeSelectorProps {
  setShowSkills: any;
  setShowBudget: any;
  jobType: string;
  setJobType: Dispatch<SetStateAction<string>>;
  setShowScope: any;
  postcode: any;
  setPostcode: Dispatch<SetStateAction<string>>;
  selected: any;
  setSelected: Dispatch<SetStateAction<string>>;
  jobLocation: any;
  setJobLocation: Dispatch<SetStateAction<string>>;
  country: string;
  setCountry: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<boolean>>;
  setJobLocationID: Dispatch<SetStateAction<string>>;
  setPostcodeID: Dispatch<SetStateAction<string>>;
}

export default function JobTypeSelector({
  setShowSkills,
  setShowScope,
  setShowBudget,
  jobType,
  setJobType,
  jobLocation,
  setJobLocation,
  selected,
  setSelected,
  postcode,
  setPostcode,
  country,
  setCountry,
  setError,
  setJobLocationID,
  setPostcodeID,
}: JobTypeSelectorProps) {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCitiesList, setSelectedCitiesList] = useState([]);
  const [postcodesList, setPostcodesList] = useState([]);
  const [selectedPostcodesList, setSelectedPostcodesList] = useState([]);
  const [searchedLocations, setSearchedLocations] = useState([]);
  const [allLocations, setAllLocations] = useLocalStorage("allLocations", []);
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setJobType("1");
    setShow(true);
  }, []);

  useEffect(() => {
    setSelected("");
  }, [selected]);

  useEffect(() => {
    if (jobType === "1") {
      setSelected("presential");
      setShow(true);
    } else if (jobType === "2") {
      setSelected("remote");
      setShow(true);
    } else if (jobType === "3") {
      setSelected("hybrid");
      setShow(true);
    } else {
      setShow(true);
    }
  }, [jobType]);
  const handleSearch = (search: string) => {
    setSearchedLocations(
      allLocations.filter((location: { location: string }) =>
        location.location.toUpperCase().includes(`${search}`.toUpperCase())
      )
    );
  };

  const goToSkills = () => {
    setShowScope(false);
    setShowSkills(true);
  };
  const goToBudget = () => {
    setShowScope(false);
    setShowBudget(true);
  };
  const handleChange = (e: any) => {
    if (e.target.value === "presential") {
      setJobType("1");
      setJobLocation("");
      setPostcode("");
    } else if (e.target.value === "remote") {
      setJobType("2");
      setJobLocation("");
      setPostcode("");
    } else if (e.target.value === "hybrid") {
      setJobType("3");
      setPostcode("");
      setJobLocation("");
    }
  };

  useEffect(() => {
    if (jobType === "2" && !country) {
      setError(true);
    } else if (jobType === "1" && (!jobLocation || !postcode)) {
      setError(true);
    } else if (jobType === "3" && !jobLocation) {
      setError(true);
    } else {
      setError(false);
    }
  }, [jobType, postcode, jobLocation, country]);

  const getCitiesFromCountry = (props: string) => {
    if (props?.length >= 1) {
      fetch(`${BASE_URL}${CITIES}?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setCitiesList(data.result))
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    const append: any = citiesList?.map(function (row: {
      name: string;
      id: string;
    }) {
      return {
        label: row.name,
        value: row.name,
        id: row.id,
      };
    });
    setSelectedCitiesList(append);
  }, [citiesList]);

  const handleCityChange = (e: any) => {
    if (!e) {
      setCitiesList([]);
    } else if (e.length > 1) {
      getCitiesFromCountry(e);
    }
  };

  const getPostcodesFromCountry = (props: string) => {
    if (props?.length > 1) {
      fetch(`${BASE_URL}${POSTCODES}?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setPostcodesList(data.result))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const append: any = postcodesList?.map(function (row: {
      locality: string;
      state: string;
      postcode: string;
      id: string;
    }) {
      return {
        label: row.locality + "," + row.state + "," + row.postcode,
        value: row.locality + "," + row.state + "," + row.postcode,
        id: row.id,
      };
    });
    setSelectedPostcodesList(append);
  }, [postcodesList]);
  const handlePostcodeChange = (e: any) => {
    if (!e) {
      setPostcodesList([]);
    } else if (e.length > 1) {
      getPostcodesFromCountry(e);
    }
  };
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "34px",
      width: "140px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "34px",
      width: "245px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  const availableCountries = [
    {
      value: "Spain",
      label: (
        <div className="flex items-center gap-2 text-sm">
          <Spain className="h-5" /> {t("available_countries.spain")}
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* {show === true && ( */}
      <div onChange={(e) => handleChange(e)}>
        <div className="flex items-start cursor-pointer">
          {/* {selected ? ( */}
          <input
            type="radio"
            value="presential"
            name="jobType"
            className="mt-2"
            defaultChecked={true}
            // defaultChecked={`${selected}` == "presential" ? true : false}
          />
          {/* // ) : (
            //   <input
            //     type="radio"
            //     value="presential"
            //     name="jobType"
            //     className="mt-2"
            //   />
            // )} */}
          <div className="flex flex-col">
            <h5 className="ml-4">
              {t("welcome-client.rightcard.jobtype.check1")}
            </h5>
            {jobType === "1" && (
              <p className="text-sm my-3 text-slate-400 ml-4">
                {t("welcome-client.rightcard.jobtype.check1.subtitle1")}
              </p>
            )}
          </div>
        </div>
        {/* {jobType === "1" && ( */}
        <>
          <p className="text-sm ml-7 mt-2">
            {t("welcome-client.rightcard.jobtype.check1.subtitle2")}
          </p>
          <div className="flex items-end gap-1 justify-start mb-6 ml-7">
            <div className="flex flex-col !w-full">
              <div className="flex flex-col lg:flex-row mt-4 w-full items-start">
                {/* <div className="w-fit rounded mr-2">
                      <Select
                        styles={colourStyles}
                        className="w-fit overflow-visible dark:text-black"
                        isDisabled={true}
                        isSearchable={false}
                        value={{
                          value: "Spain",
                          label: (
                            <div className="flex items-center gap-2 text-sm">
                              <Spain className="h-5" />{" "}
                              {t(
                                "welcome-client.rightcard.jobtype.check1.subtitle3.paisempresa.value.dropdown.paisempresaSearchFieldPlaceholder"
                              )}
                            </div>
                          ),
                        }}
                        options={availableCountries}
                        onChange={(e: any) => {
                          setCountry(e.value);
                          setJobLocation("");
                          setPostcode("");
                          setSelectedCitiesList([]);
                          setSelectedPostcodesList([]);
                        }}
                      />
                    </div> */}
                <div className="flex flex-col  w-full mt-4">
                  <div className="relative">
                    <Select
                      // isDisabled={true}
                      onInputChange={(e) => getCitiesFromCountry(e)}
                      options={selectedCitiesList}
                      className="text-sm dark:text-black"
                      styles={colourStyles2}
                      placeholder={`${t(
                        "welcome-client.rightcard.jobtype.check1.subtitle3.ciudadempresa.value.dropdown.ciudadpaisempresaSearchFieldPlaceholder"
                      )}...`}
                      value={
                        jobLocation && {
                          label: jobLocation.label || jobLocation.name,
                          value: jobLocation.label || jobLocation.name,
                        }
                      }
                      onChange={(e: any) => {
                        setJobLocationID(e.id);
                        setJobLocation(e);
                      }}
                      noOptionsMessage={() =>
                        `${t(
                          "worker.search_jobs_filter.search_postcodes_search_type"
                        )}`
                      }
                    />
                    {/* {jobLocation ? (
                          <input
                            className="px-2 hover:border-azulBonico border !w-full text-sm border-blue-100  rounded !h-[34px] outline-none focus:border-azulBonico transition duration-300"
                            value={jobLocation.name}
                            onChange={(e) => setJobLocation()}
                          ></input>
                        ) : (
                          <input
                            className="px-2 hover:border-azulBonico border !w-full text-sm border-blue-100  rounded !h-[34px] outline-none focus:border-azulBonico transition duration-300"
                            placeholder="Search city..."
                            onChange={(e) => handleCityChange(e.target.value)}
                          ></input>
                        )}
                        {citiesList && (
                          <div className="absolute top-8 jobpost overflow-auto text-sm border border-blue-100 rounded z-10 bg-white w-full max-h-40">
                            <ul>
                              {citiesList?.length === 0 && (
                                <div className="wrong-container">
                                  <img
                                    className="exclamation"
                                    src={exclamation}
                                    alt="hey"
                                  />{" "}
                                  No results found... Please, try again!
                                </div>
                              )}
                              {citiesList?.map((city) => {
                                return (
                                  <li
                                    className="hover:bg-blue-100 rounded pl-4 p-1 cursor-pointer"
                                    key={city.id}
                                    onClick={(e) => {
                                      setJobLocation(city);
                                      setCitiesList();
                                    }}
                                  >
                                    {city.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )} */}
                  </div>
                  <div className="relative mt-2">
                    <Select
                      // isDisabled={true}
                      onInputChange={(e) => getPostcodesFromCountry(e)}
                      options={selectedPostcodesList}
                      className="text-sm dark:text-black"
                      styles={colourStyles2}
                      placeholder={`${t(
                        "welcome-client.rightcard.jobtype.check1.subtitle3.cpempresa.value.dropdown.cpempresaSearchFieldPlaceholder"
                      )}...`}
                      defaultValue={
                        postcode && {
                          value: postcode,
                          label: postcode,
                        }
                      }
                      onChange={(e: any) => {
                        setPostcodeID(e.id);
                        if (postcode?.locality) {
                          setPostcode(e);
                        } else {
                          setPostcode(e.value);
                          // setJobInfo((prev) => ({
                          //   ...prev,
                          //   postcode_id: e.id,
                          // }));
                        }
                      }}
                      noOptionsMessage={() =>
                        `${t(
                          "worker.search_jobs_filter.search_postcodes_search_type"
                        )}`
                      }
                    />{" "}
                    {/* {postcode ? (
                          <input
                            className="px-2 hover:border-azulBonico border !w-full text-sm border-blue-100  rounded !h-[34px] outline-none focus:border-azulBonico transition duration-300"
                            value={`${
                              postcode?.locality
                                ? `${postcode?.locality}, ${postcode?.state} ${postcode?.postcode}`
                                : `${postcode}`
                            }`}
                            onChange={(e) => setPostcode()}
                          ></input>
                        ) : (
                          <input
                            className="px-2 hover:border-azulBonico border !w-full text-sm border-blue-100  rounded !h-[34px] outline-none focus:border-azulBonico transition duration-300"
                            placeholder="Search postcode..."
                            onChange={(e) =>
                              handlePostcodeChange(e.target.value)
                            }
                          ></input>
                        )}
                        {postcodesList && (
                          <div className="absolute top-8 jobpost overflow-auto text-sm border border-blue-100 rounded z-10 bg-white w-full max-h-40">
                            <ul>
                              {postcodesList?.length === 0 && (
                                <div className="wrong-container">
                                  <img
                                    className="exclamation"
                                    src={exclamation}
                                    alt="hey"
                                  />{" "}
                                  No results found... Please, try again!
                                </div>
                              )}
                              {postcodesList?.map((code) => {
                                return (
                                  <li
                                    className="hover:bg-blue-100 rounded pl-4 p-1 cursor-pointer text-sm"
                                    key={code.id}
                                    onClick={(e) => {
                                      setPostcodesList();
                                      if (postcode?.locality) {
                                        setPostcode(code);
                                      } else {
                                        setPostcode(
                                          `${code.locality}, ${code.state} ${code.postcode}`
                                        );
                                        setJobInfo((prev) => ({
                                          ...prev,
                                          postcode_id: code.id,
                                        }));
                                      }
                                    }}
                                  >
                                    {code.locality}, {code.state}{" "}
                                    {code.postcode}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* )} */}
        {/* <label className="flex items-start my-4 cursor-pointer">
            {selected ? (
              <input
                type="radio"
                value="hybrid"
                name="jobType"
                defaultChecked={`${selected}` == "hybrid" ? true : false}
                className="mt-2"
              />
            ) : (
              <input
                type="radio"
                value="hybrid"
                name="jobType"
                className="mt-2"
              />
            )}
            <div className="flex flex-col ml-4">
              <h5>{t("welcome-client.rightcard.jobtype.check2")}</h5>

              {jobType === "3" && (
                <>
                  <p className="text-sm my-3 text-slate-400">
                    {t("welcome-client.rightcard.jobtype.check2.subtitle1")}
                  </p>
                </>
              )}
            </div>
          </label> */}
        {/* {jobType === "3" && (
            <>
              <p className="text-sm ml-7 mt-2">
                {t("welcome-client.rightcard.jobtype.check2.subtitle2")}
              </p>
              <div className="flex items-end gap-1 justify-start mb-6 ml-7">
                <div className="flex flex-col !w-full">
                  <div className="flex flex-col lg:flex-row mt-4 w-full items-start">
                    <div className="flex flex-col  w-full mt-4">
                      <div className="relative">
                        <Select
                          onInputChange={(e) => getCitiesFromCountry(e)}
                          options={selectedCitiesList}
                          className="text-sm dark:text-black"
                          styles={colourStyles2}
                          placeholder={`${t(
                            "welcome-client.rightcard.jobtype.check2.subtitle3.ciudadempresa.value.dropdown.ciudadpaisempresaSearchFieldPlaceholder"
                          )}...`}
                          defaultValue={
                            jobLocation && {
                              label: jobLocation.label,
                              value: jobLocation.label,
                            }
                          }
                          onChange={(e: any) => {
                            setJobLocationID(e.id);
                            setJobLocation(e);
                          }}
                          noOptionsMessage={() =>
                            `${t(
                              "worker.search_jobs_filter.search_postcodes_search_type"
                            )}`
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )} */}
        {/* <label className="flex items-start mb-4 cursor-pointer">
            {selected ? (
              <input
                type="radio"
                value="remote"
                name="jobType"
                defaultChecked={`${selected}` == "remote" ? true : false}
                className="mt-2"
              />
            ) : (
              <input
                type="radio"
                value="remote"
                name="jobType"
                className="mt-2"
              />
            )}
            <div className="flex flex-col ml-4">
              <h5>{t("welcome-client.rightcard.jobtype.check3")}</h5>
              {jobType === "2" && (
                <>
                  <p className="text-sm text-slate-400 mt-2">
                    {t("welcome-client.rightcard.jobtype.check3.subtitle1")}
                    <br></br>{" "}
                    {t("welcome-client.rightcard.jobtype.check3.subtitle2")}
                  </p>
                </>
              )}
            </div>
          </label> */}
        {/* {jobType === "2" && (
            <>
              <div className="w-fit rounded mr-2 mt-4 ml-7">
                <Select
                  isDisabled={true}
                  styles={colourStyles}
                  className="w-fit overflow-visible"
                  isSearchable={false}
                  value={{
                    value: "Spain",
                    label: (
                      <div className="flex items-center gap-2 text-sm">
                        <Spain className="h-5" />{" "}
                        {t(
                          "welcome-client.rightcard.jobtype.check3.subtitle3.paisempresa.value.dropdown.paisempresaSearchFieldPlaceholder"
                        )}
                      </div>
                    ),
                  }}
                  options={availableCountries}
                  onChange={(e: any) => {
                    setCountry(e.value);
                    setJobLocation("");
                    setPostcode("");
                  }}
                />
              </div>
            </>
          )} */}

        <div className="flex flex-col"></div>
      </div>
      {/* )} */}
    </div>
  );
}
