import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import { showFilterWorkersTime } from "src/Atoms/jotaiAtoms";
import { StylesModal } from "src/Utils/stylesModal";

const ModalFilterWorkersSchedule = () => {
  const [filterWorkers, setFilterWorkers] = useAtom(showFilterWorkersTime);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  return (
    <Modal style={StylesModal} isOpen={filterWorkers}>
      <div
        className={`fixed z-50 ${
          transition === false ? "opacity-40 scale-0" : "opacity-100 scale-100"
        } transition duration-150 w-[90%] lg:w-[700px] h-[650px] max-w-auto max-h-auto left-[50%] shadow-xl translate-x-[-50%] rounded-xl top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="flex items-center justify-between p-10">
          <h1 className="text-2xl font-semibold">Filters</h1>
          <div
            onClick={() => setFilterWorkers(false)}
            className="h-8 w-8 rounded-full bg-[#f8f8f8] flex items-center justify-center cursor-pointer"
          >
            <IoClose className="h-6 w-6 " />
          </div>
        </div>
        <div
          className="p-10 h-[435px] overflow-y-auto scrollbar-hide
        "
        >
          <div className="">
            <h1 className="text-lg font-semibold">Initial hours</h1>
            <div className="bg-[#f8f8f8] flex items-center justify-between gap-2 py-1 rounded px-1 mt-4">
              <div className="hover:bg-white w-1/2 flex items-center justify-center hover:shadow-lg transition py-4 rounded cursor-pointer">
                <p className="font-medium">Flexible Hours</p>
              </div>
              <div className="hover:bg-white w-1/2 flex items-center justify-center hover:shadow-lg transition py-4 rounded cursor-pointer">
                <p className="font-medium">Exact Hours</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFilterWorkersSchedule;
