import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  lazy,
  useState,
} from "react";
import Lottie from "lottie-react";
import { v4 as uniqueID } from "uuid";

import useLocalStorage from "src/Hooks/UseLocalStorage";
import animationData from "src/Utils/lotties/heartSavedJob.json";
import { BASE_URL } from "src/Config/api.config";
import { ModalJobCard } from "../../Modals/ModalJobCard";

import { ReactComponent as Heart } from "src/assets/img/heart.svg";
import { ReactComponent as ThumbDown } from "src/assets/img/thumb-down.svg";

import { JobTypes, OffersTypes } from "src/types/types";
import { formatJobTitle } from "src/Utils/formatters";
import { useTranslation } from "react-i18next";

interface JobCardsCompactProps {
  offers: OffersTypes[];
  setOffers: Dispatch<SetStateAction<OffersTypes[]>>;
  updateSaved: boolean;
  setUpdateSaved: Dispatch<SetStateAction<boolean>>;
  savedJobsCounter: number;
  setSavedJobsCounter: Dispatch<SetStateAction<number>>;
  setUpdateApplied: any;
  updateApplied: boolean;
}

function JobCardsCompact({
  // filteredList,
  offers,
  setOffers,
  updateSaved,
  setUpdateSaved,
  savedJobsCounter,
  setSavedJobsCounter,
}: JobCardsCompactProps) {
  const { t, i18n } = useTranslation();
  const [showModalJobCard, setShowModalJobCard] = useState(false);
  const [clickedJob, setClickedJob] = useState<JobTypes>();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const unique_id = uniqueID();
  const handleSaveJob = (job: JobTypes) => {
    setOffers((current: any) =>
      current?.map((obj: any) => {
        if (obj.job_id === job.job_id) {
          if (job?.saved === "true" || job?.saved === true) {
            setSavedJobsCounter(savedJobsCounter - 1);
            return { ...obj, saved: false };
          } else if (job?.saved === "false" || job?.saved === false) {
            setSavedJobsCounter(savedJobsCounter + 1);
            return { ...obj, saved: true };
          }
        }
        return obj;
      })
    );
  };

  const saveData = new FormData();
  saveData.append("_method", "POST");
  saveData.append("client_id", userID);

  const saveJob = (id: string) => {
    saveData.append("job_id", id);
    fetch(`${BASE_URL}worker.saved.jobs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: saveData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const deleteData = new FormData();
  deleteData.append("client_id", userID);

  const deleteSavedJob = (id: string) => {
    fetch(`${BASE_URL}worker.saved.jobs?client_id=${userID}&job_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const saveItOrNot = (job: JobTypes) => {
    if (job.saved === "true") {
      deleteSavedJob(job.job_id);
    }
    if (job.saved === "false") {
      saveJob(job.job_id);
    }
    if (updateSaved) {
      setUpdateSaved(!updateSaved);
    }
  };

  let posted: any;
  return (
    <Suspense fallback={null}>
      <div className="flex flex-col">
        {offers.length ? (
          offers?.map((job) => {
            if (job?.updated) {
              const separatedPosted = job?.updated?.split(" ");
              posted = separatedPosted[0] + `${" "}` + separatedPosted[1];
            }
            return (
              <>
                <div
                  className=" border-b border-blue-100 dark:border-border dark:hover:bg-neutral-400/30  p-6 cursor-pointer hover:bg-[#f7f4f4] group transition"
                  key={job.job_id}
                >
                  <div className=" flex justify-between">
                    <p
                      className="font-[700] tracking-wide group-hover:text-azulOscuro hover:underlin"
                      onClick={() => {
                        setShowModalJobCard(true);
                        setClickedJob(job);
                      }}
                    >
                      {formatJobTitle(job.title)}
                    </p>
                    <div className="flex gap-4">
                      <ThumbDown
                        className={`rounded-full hover:bg-white dark:bg-whiteish w-fit h-8 p-2 overflow-visible border-[1.5px] border-[#b2b2b2] hover:border-red-500 cursor-pointer `}
                      />
                      {/* <img
                                src={thumbDown}
                                alt="down"
                                className="rounded-full hover:bg-white w-fit h-8 p-2 overflow-visible border-[1.5px] border-[#b2b2b2] hover:border-red-500 cursor-pointer"
                              /> */}
                      <div
                        onClick={() => {
                          saveItOrNot(job);
                          handleSaveJob(job);
                        }}
                        className={` ${
                          job.saved === "true"
                            ? "relative border-2 border-[#FC66AA] rounded-full w-[33px] h-[33px]"
                            : "null"
                        }`}
                      >
                        {job.saved === "true" ? (
                          <div className="absolute -top-[28px] -left-[31px]">
                            <Lottie
                              animationData={animationData}
                              height={90}
                              width={90}
                              className="w-[90px] h-[90px]"
                              loop
                            />
                          </div>
                        ) : (
                          <Heart className="h-[33px] hover:fill-[#FC66AA] cursor-pointer hover:bg-white w-fit text-white z-100 rounded-full fill-[#b2b2b2]" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {i18n.language === "en" ? (
                      <p className="text-xs text-rositaOscuro">
                        Posted {posted} ago
                      </p>
                    ) : (
                      <p className="text-xs text-rositaOscuro">
                        Publicado hace {posted}
                      </p>
                    )}
                  </div>
                  <div className=" text-sm flex items-center mt-2 font-medium w-full  justify-between">
                    <span className="font-medium text-rositaOscuro mr-2 tracking-wide">
                      {" "}
                      {job.category_name}
                    </span>
                    <span className="font-medium"> {job.budget}€ / h </span>
                  </div>
                  {/* <div className="flex items-center border-y py-3 mt-3 ">
                    <div className="flex flex-col items-start text-black dark:text-whiteish text-sm  w-full">
                      <p className="text-rositaOscuro font-medium">
                        {t("worker.job_card.category")}
                      </p>{" "}
                      <span className="font-medium tracking-wide">
                        {" "}
                        {job.category_name}
                      </span>
                    </div>
                    <div className=" text-sm w-full text-black dark:text-whiteish">
                      <p className="text-rositaOscuro font-medium">
                        {t("worker.job_card.applicants")}
                      </p>
                      <span className="font-medium">{job.proposals}</span>{" "}
                    </div>
                    <div className=" text-sm  w-full font-medium">
                      <p className="text-rositaOscuro">{` ${t(
                        "welcome-client.leftcard.timeline.budget"
                      )}`}</p>
                      <span className="font-medium"> {job.budget}€ / h </span>
                    </div>

                    <div className="w-full font-medium text-sm">
                      <p className="text-rositaOscuro">
                        {t("welcome-client.leftcard.timeline.jobtype")}
                      </p>

                      <p>
                        {job.type_name}{" "}
                        {(job.type_name === "Hybrid" ||
                          job.type_name === "In-person") && (
                          <>
                            {" "}
                            <span>at</span>{" "}
                            <span className="font-medium">{job.city_name}</span>{" "}
                          </>
                        )}
                      </p>
                    </div>
                  </div> */}
                </div>
              </>
            );
          })
        ) : (
          <p>{t("worker.worker_dashboard.no_results")}... :</p>
        )}
      </div>
      {showModalJobCard === true && (
        <ModalJobCard
          showModal={{ cover: false, job: false, report: false }}
          // showModal={showmo}
          // showModalJobCard={showModalJobCard}
          // setShowModalJobCard={setShowModalJobCard}
          job={clickedJob!}
          setJob={setClickedJob}
          saveItOrNot={saveItOrNot}
          handleSaveJob={handleSaveJob}
        />
      )}
    </Suspense>
  );
}

export default JobCardsCompact;
