import { create } from "zustand"
const themeKey = "theme"

interface ThemeProps {
    themeMode?: string;
    setThemeMode: (theme: string) => void
}

export const useThemeStore = create<ThemeProps>((set) => ({
    themeMode: localStorage.getItem(themeKey) ?? "",
    setThemeMode: async (themeMode) => {
        try {
            await localStorage.setItem(themeKey, themeMode)
        } catch {

        }
        set({ themeMode })
    }
    // setThemeMode: (themeMode) => set({ themeMode })
}))