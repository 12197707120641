import React from "react";
import { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { TbWorld } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { cn } from "src/lib/utils";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useLanguages } from "src/Hooks/useLanguages";

const HeaderBurger = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [showChangeLanguage, setShowChangeLanguage] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { options } = useLanguages();

  return (
    <div className="lg:hidden w-full h-screen bg-white px-6">
      <div>
        <div className="flex flex-col items-center w-full">
          {/* <nav> */}
          <div
            onClick={() => navigate("/aboutus")}
            className="text-[#333] px-4 py-4 cursor-pointer border-b border-border w-full"
          >
            <h1 className={cn(" font-medium text-lg ", pathname === "/" && "")}>
              {t("home.Fotter.Right2.Seccion8.aboutusLink")}
            </h1>
          </div>
          <div
            onClick={() => navigate("/pricing")}
            className="text-[#333] px-4 py-4 cursor-pointer border-b border-border w-full"
          >
            <h1 className={cn(" font-medium ", pathname === "/" && "")}>
              {t("home.Fotter.Right2.Seccion9.thirdTitle")}
            </h1>
          </div>
          <div
            onClick={() => navigate("/contact")}
            className="text-[#333] px-4 py-4 cursor-pointer border-b border-border w-full
            "
          >
            <h1 className={cn(" font-medium ", pathname === "/" && "")}>
              {t("home.Fotter.Right2.Seccion9.forthTitle")}
            </h1>
          </div>
          {/* </nav> */}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center my-4">
        {loggedInUserUid ? (
          <a onClick={() => navigate("/dashboard")}>
            <button className="bg-azulBonico text-white px-10 py-2 rounded lg:mr-[0.3rem] transition">
              <p className=" font-medium">{t("header.dashboard.title")}</p>
            </button>
          </a>
        ) : (
          <a onClick={() => navigate("/login")}>
            <button className="bg-azulBonico transition text-white px-10 py-2 rounded">
              <p className=" font-medium">{t("home.Header.logInBtn")}</p>
            </button>
          </a>
        )}

        {!loggedInUserUid ?? (
          <a onClick={() => navigate("/register")}>
            <button className="bg-[#fb5ba5] transition">
              <p className=" font-medium">{t("home.Header.registerBtn")}</p>
            </button>
          </a>
        )}
      </div>

      <div className="  w-full   relative ">
        <button
          onClick={() => setShowChangeLanguage(!showChangeLanguage)}
          className="flex items-center space-x-2 w-[120px] mx-auto border px-2 py-1 rounded-lg hover:bg-[#fafafa] transition transform duration-150 ease-in-out cursor-pointer"
        >
          <TbWorld color="gray" size={18} />
          <h1 className="font-semibold text-sm">Español</h1>
          <BiChevronDown color="gray" size={20} />
        </button>
        {showChangeLanguage ? (
          <div className="absolute z-50 bottom-20 bg-white w-[93%] mx-6  px-4 pr-20 py-4 rounded-lg shadow-2xl ">
            <h1 className="font-bold text-sm pb-5 ">
              {" "}
              {t("modal.add_language.language")}
            </h1>
            <div className="flex flex-col items-start pl-4">
              {options.map((e) => (
                <div
                  key={e.value}
                  className={`text-[14px] mb-1 ${
                    e.value === i18n.language ? "bg-gray-100" : " text-black"
                  } hover:bg-gray-200 transition-transform duration-150 ease-in-out w-full px-4 py-2 rounded cursor-pointer`}
                  onClick={() => {
                    setShowChangeLanguage(false);
                    i18n.changeLanguage(e.value);
                  }}
                >
                  {e.label}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HeaderBurger;
