import { t } from "i18next";

export const allLevels = [
    {
        value: "Basic",
        label: `${t("createprofile.language.table.rightBtn.textPlaceholder.text1")}`,
    },
    {
        value: "Conversational",
        label: `${t("createprofile.language.table.rightBtn.textPlaceholder.text2")}`,
    },
    {
        value: "Fluent",
        label: `${t("createprofile.language.table.rightBtn.textPlaceholder.text3")}`,
    },
    {
        value: "Native or Bilingual",
        label: `${t("createprofile.language.table.rightBtn.textPlaceholder.text4")}`,
    },
];