import React from "react";
import { useTranslation } from "react-i18next";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";
import { formatJobTitle } from "src/Utils/formatters";
import { JobTypes } from "src/types/types";
import { Button } from "../ui/Button";
import { GoArrowUpRight } from "react-icons/go";

interface ClosedJobsSearchCardProps {
  job: JobTypes;
  getClosedJobData: (id: string) => void;
}
const ClosedJobsSearchCard = ({
  job,
  getClosedJobData,
}: ClosedJobsSearchCardProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      key={job.job_id}
      className=" border-b border-border py-6 px-8 hover:bg-[#f7f4f4] transition"
    >
      <div className="flex justify-between items-end mb-2 w-full">
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between w-full">
            <p
              className="font-semibold text-lg max-w-[350px] hover:underline hover:text-azulOscuro cursor-pointer transition"
              onClick={() => getClosedJobData(job.job_id)}
            >
              {formatJobTitle(job.title)}
            </p>
            <p className="text-sm text-neutral-500 font-medium ">
              {formatJobTitle(job.close_reason)}
            </p>
          </div>
          <div className="flex flex-col text-gray-400 text-sm mt-3">
            <p className="whitespace-nowrap">
              {i18n.language === "en" ? (
                <div className="flex flex-col items-start">
                  <span className="italic">
                    {t("employer.all_closed_jobs.closed_date.closed_on")}{" "}
                    {HandleMonthName(job.date_closed?.slice(5, 7))}{" "}
                    {job.date_closed?.slice(8)}th
                  </span>
                  {/* because{" "} */}
                </div>
              ) : (
                <div className="flex flex-col items-start">
                  <span className="italic">
                    {t("employer.all_closed_jobs.closed_date.closed_on")}{" "}
                    {job.date_closed?.slice(8)} de{" "}
                    {HandleMonthNameES(job.date_closed?.slice(5, 7))}{" "}
                  </span>
                </div>
              )}
            </p>
          </div>

          {/* {job.expired === "false" && (
              <>
                {job.updated.includes("day") ? (
                  <p>
                    {"Updated " +
                      job.updated.split(" ")[0] +
                      " days ago"}
                  </p>
                ) : (
                  <p>{"Updated " + job.updated + "ago"}</p>
                )} */}
        </div>
      </div>
      <Button
        className="whitespace-nowrap tracking-wide ml-auto  px-6 py-2 frounded-full flex items-center h-fit text-sm text-white bg-azulBonico  hover:bg-azulOscuro"
        onClick={() => getClosedJobData(job.job_id)}
      >
        {t("employer.all_drafts.view_job")}
        <GoArrowUpRight className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default ClosedJobsSearchCard;
