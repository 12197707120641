import React from "react";
import { useTranslation } from "react-i18next";

interface InviteWorkersNavBarProps {
  selectedSection: string;
  setSelectedSection: any;
}

const InviteWorkersNavBar = ({
  selectedSection,
  setSelectedSection,
}: InviteWorkersNavBarProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex pt-8 px-8 pb-3 border-b gap-10 text-sm font-medium border-blue-100">
        <p
          className={`cursor-pointer ${
            selectedSection === "search"
              ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
              : "hover:text-azulOscuro"
          } `}
          onClick={() => setSelectedSection("search")}
        >
          &nbsp;&nbsp;{t("employer.invite_workers.search")}&nbsp;&nbsp;
        </p>
        <p
          className={`cursor-pointer ${
            selectedSection === "invited"
              ? "underline decoration-[3px] underline-offset-[15px] text-[#2DBEEB]"
              : "hover:text-azulOscuro"
          } `}
          onClick={() => setSelectedSection("invited")}
        >
          &nbsp;&nbsp;{t("employer.invite_workers.invited")}&nbsp;&nbsp;
        </p>
      </div>
    </div>
  );
};

export default InviteWorkersNavBar;
