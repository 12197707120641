import React, { Dispatch, SetStateAction, useState } from "react";
import Select from "react-select";

import { ReactComponent as Search } from "../../assets/img/search.svg";
import blueCross from "../../assets/img/blue-cross.png";
import filters from "../../assets/img/filters.png";
import { useTranslation } from "react-i18next";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface ApplicantFiltersProps {
  getApplicants: () => void;
  setSearchedApplicant: Dispatch<SetStateAction<string>>;
  sortFilter: any;
  setSortOrder: any;
  setCurrentPage: any;
}

const ApplicantsFilters = ({
  getApplicants,
  setSearchedApplicant,
  sortFilter,
  setSortOrder,
  setCurrentPage,
}: ApplicantFiltersProps) => {
  const [filterList, setFilterList] = useState<any>([]);
  const [showFilters, setShowFilters] = useState(false);

  const { t } = useTranslation();

  const handleFilters = (e: any, props: any) => {
    e.preventDefault();
    setFilterList([...filterList, props]);
  };

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        transition: "0.3s",
        borderColor: "#2abeeb",
      },
      transition: "0.3s",
      display: "flex",
      width: "200px",
      padding: "0rem",
      cursor: "pointer",
      borderRadius: "5px",
      height: "37px",
      fontSize: "14px",
    }),
  };
  return (
    <div>
      <div className="flex items-start dark:text-whiteish">
        <div className="relative px-8 pt-8 pb-4 w-2/4">
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter") getApplicants();
            }}
          >
            <input
              className="px-2  hover:border-azulBonico border text-sm dark:bg-input dark:border-border dark:border  border-blue-100 w-full rounded h-10 outline-none focus:border-azulBonico transition duration-300"
              type="text"
              placeholder={`${t("employer.invite_workers.search")} ...`}
              onChange={(e) => setSearchedApplicant(e.target.value)}
            ></input>
            <Search
              // fill={"white"}
              // type="submit"
              className="h-5 absolute right-[3rem] top-[42px] cursor-pointer dark:fill-whiteish"
              onClick={() => getApplicants()}
            />
          </div>
          <div className={` flex flex-wrap`}>
            {" "}
            {filterList?.map((filter: any) => {
              return (
                <>
                  <div className="relative flex items-center  py-0.5 mx-1  px-1 mt-2 border rounded-xl bg-blancoGrisClaro dark:border-border dark:bg-background">
                    <p
                      className={` text-[13px]  w-fit active:bg-azulBonico  flex items-center  cursor-pointer text-[#343a47]`}
                    >
                      {filter}
                    </p>
                    <XMarkIcon className="h-4  mr-0.5 items-center ml-1 hover:text-[#bdbdbd] transition cursor-pointer" />
                  </div>
                </>
              );
            })}
          </div>
          {filterList.length > 0 && (
            <div
              className="flex items-center pt-4 pl-4 cursor-pointer  "
              onClick={() => setFilterList([])}
            >
              <img src={blueCross} alt="cross" className="h-2" />
              <p className="text-sm hover:text-azulOscuro hover:underline text-azulBonico ml-2 font-bold">
                {t("employer.review_candidates.filters.clear")}
              </p>
            </div>
          )}
        </div>
        <div
          className="px-8 py-8 relative cursor-pointer "
          onClick={() => setShowFilters(!showFilters)}
        >
          <button className="px-10 py-2 font-bold text-azulBonico dark:bg-background text-end  hover:bg-[#f7f4f4] border border-azulBonico  bg-white  rounded-full transition duration-300">
            <div className="pl-3 text-sm">
              {t("employer.applicant_filters.title")}
            </div>
          </button>
          <img
            src={filters}
            alt="fitler"
            className="h-5 top-[42px] left-[3.6rem] w-5 absolute"
          />
        </div>
        <div className="flex items-center py-8 gap-4 ml-2">
          <p className="text-sm font-bold">
            {t("employer.applicant_filters.sort")}:
          </p>
          <Select
            options={sortFilter}
            onChange={(e: any) => {
              setSortOrder(e.param);
              setCurrentPage(1);
            }}
            isSearchable={false}
            defaultValue={{
              label: sortFilter[0].label,
              value: sortFilter[0].value,
            }}
            styles={colourStyles}
            className="text-sm dark:text-black"
          />
        </div>
      </div>
      {showFilters === true && (
        <div className="border-b border-blue-100 flex py-4 relative justify-around pl-6 dark:text-whiteish bg-white dark:bg-background dark:border-border text-sm pb-12">
          <div className="flex flex-col gap-2">
            <p className="font-bold mb-3">
              {t("employer.applicant_filters.hourly_rate")}
            </p>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              // onClick={() => handleFilters("All")}
            >
              <input
                type="radio"
                value="visibilityAll"
                className="mr-3"
              ></input>
              <p>{t("employer.applicant_filters.any_hourly_rate")}</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => {
                handleFilters(e, "Public");
              }}
            >
              <input
                type="radio"
                value="visibilityPublic"
                className="mr-3"
              ></input>
              <p>{t("employer.applicant_filters.maximum")} 10€ </p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Private")}
            >
              <input
                type="radio"
                value="visibilityPrivate"
                className="mr-3"
              ></input>
              <p>10€ - 20€</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Private")}
            >
              <input
                type="radio"
                value="visibilityPrivate"
                className="mr-3"
              ></input>
              <p>20€ - 30€</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Private")}
            >
              <input
                type="radio"
                value="visibilityPrivate"
                className="mr-3"
              ></input>
              <p>{t("employer.applicant_filters.minimum")} 30€</p>
            </label>
            <button
              className="absolute bottom-4 left-4 px-4 py-1 text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
              onClick={() => setShowFilters(!showFilters)}
            >
              {t("employer.applicant_filters.close_filters")}
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold mb-3">
              {t("employer.applicant_filters.last_activity")}
            </p>
            <label className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer ">
              <input type="radio" value="statusAll" className="mr-3"></input>
              <p>{t("employer.applicant_filters.any_time")}</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Drafts")}
            >
              <input type="radio" value="statusDraft" className="mr-3"></input>
              <p>{t("employer.applicant_filters.twoweeks")}</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Open")}
            >
              <input type="radio" value="statusOpen" className="mr-3"></input>
              <p>{t("employer.applicant_filters.amonth")}</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Closed")}
            >
              <input type="radio" value="statusClosed" className="mr-3"></input>
              <p>{t("employer.applicant_filters.twomonths")}</p>
            </label>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold mb-3">
              {t("employer.applicant_filters.type")}
            </p>
            <label className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer">
              <input type="radio" value="typeAll" className="mr-3"></input>
              <p>{t("employer.applicant_filters.type.all")}</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Fixed-Price")}
            >
              <input type="radio" value="typeFixed" className="mr-3"></input>
              <p>{t("employer.applicant_filters.type.fixed_price")}</p>
            </label>
            <label
              className="flex hover:bg-blue-100 dark:hover:bg-slate-400/30 hover:rounded p-1 cursor-pointer"
              onClick={(e) => handleFilters(e, "Hourly")}
            >
              <input type="radio" value="typeHourly" className="mr-3"></input>
              <p>{t("employer.applicant_filters.type.hourly")}</p>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicantsFilters;
