import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as Warning } from "../../assets/img/warning.svg";

interface RequestErrorModalProps {
  warning: boolean;
}

const RequestErrorModal = ({ warning }: RequestErrorModalProps) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (warning !== false) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3200);
    }
  }, [warning]);

  return (
    <div
      className={`flex justify-center transition-opacity duration-700 ${
        show !== false ? "opacity-100 " : "opacity-0 "
      }`}
    >
      <div className="bg-red-700 text-white font-bold text-sm rounded-lg py-3 tracking-wide px-6 mt-6 fixed items-start shadow-lg">
        <div className="flex justify-between gap-[78px] items-center">
          <div className="flex gap-4 items-center">
            <Warning className="h-4 fill-white" />
            <p>
              Error: <span className="italic">'{warning}'</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestErrorModal;
