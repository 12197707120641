import { create } from "zustand";

const publicKey = "isPublic";

interface IsPublicProps {
  isPublic: string | null;
  setIsPublic: (isPublic: string) => void;
}

export const useIsPublicStore = create<IsPublicProps>((set) => ({
  isPublic: localStorage.getItem(publicKey),
  setIsPublic: async (isPublic) => {
    try {
      await localStorage.setItem(publicKey, isPublic);
    } catch (error) {}
    set({ isPublic });
  },
}));
