import React, { Dispatch, SetStateAction } from "react";
import { BASE_URL_IMG } from "src/Config/api.config";
import { ApplicantTypes } from "src/types/types";
import { ReactComponent as ThumbDown } from "src/assets/img/thumb-down.svg";
import { ReactComponent as Circle } from "src/assets/img/circle.svg";
import RatingStars from "../Rating/RatingStars";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";
import { useTranslation } from "react-i18next";
import HandleDayEnding from "src/Utils/HandleDayEnding";
import { applicationView } from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import CustomSkillCarousel from "../WorkerDashboard/CustomSkillCarousel";
import { ModalProps } from "./WorkerCards";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";
import { HeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartFilled } from "@heroicons/react/24/solid";

interface ApplicantCardProps {
  applicant: ApplicantTypes;
  handleSeenApplicant: (applicant: ApplicantTypes) => void;
  getWorkerInfo: (clientId: string) => void;
  setClickedWorker: Dispatch<SetStateAction<any>>;
  setShowModal: Dispatch<SetStateAction<ModalProps>>;
  endingEarlyEnd: string;
  selectedSection: string;
  checkIfThereIsConversation: (applicant: ApplicantTypes) => void;
  handleShortlist: (applicant: ApplicantTypes) => void;
  setShowBannerArchived: Dispatch<SetStateAction<boolean>>;
  setShowArchivePopover: Dispatch<SetStateAction<number>>;
  setDeclinedApplicant: Dispatch<SetStateAction<ApplicantTypes>>;
  daysMap: any;
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  handleArchived: (applicant: ApplicantTypes) => void;
  showArchivePopover: number;
  setActiveStar: Dispatch<SetStateAction<number>>;
  i: number;
}

const ApplicantCard: React.FC<ApplicantCardProps> = ({
  applicant,
  handleSeenApplicant,
  getWorkerInfo,
  setClickedWorker,
  endingEarlyEnd,
  selectedSection,
  setShowModal,
  checkIfThereIsConversation,
  handleShortlist,
  setShowArchivePopover,
  setShowBannerArchived,
  setDeclinedApplicant,
  daysMap,
  handleArchived,
  setRefreshData,
  showArchivePopover,
  setActiveStar,
  i,
}) => {
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const postcode = applicant?.post_code_name?.split(",");
  const endingEnd = HandleDayEnding(applicant?.date_end?.slice(9));
  const endingStart = HandleDayEnding(applicant?.date_start?.slice(9));
  const [applicationWindow, setApplicationWindow] = useAtom(applicationView);
  const [offeredApplicant, setOfferedApplicant] = useLocalStorage(
    "dataOfferedApplicant",
    ""
  );

  return (
    <>
      <div
        className={`dark:text-whiteish ${
          applicant?.seen_by_employer === "0"
            ? "bg-blue-50/50"
            : "hover:bg-[#fafafa] dark:hover:bg-neutral-400/30 transition transform ease-out duration-150"
          // : "hover:bg-[#f7f4f4]"
        }  cursor-pointer`}
        onClick={() => {
          setClickedWorker(applicant);
          handleSeenApplicant(applicant);
          getWorkerInfo(
            applicant.client_id
              ? applicant.client_id
              : applicant.client_id_worker
          );
        }}
      >
        <div className="flex  border-t border-blue-200 ">
          <div className="relative px-8 py-4">
            <img
              loading="lazy"
              src={`${BASE_URL_IMG}${applicant?.profile_image}`}
              alt="img"
              className="h-24 w-24 object-cover rounded-full"
            />
            <Circle
              fill={`${
                applicant?.online_status === "1" ? "#14A800" : "#94b8a3"
              }`}
              className="h-5 absolute top-[95px] right-[35px] border-[4px] border-white rounded-full"
            />
          </div>
          <div className="flex flex-col flex-1 pt-4">
            {applicant.inapropiate_worker === "false" ||
            !applicant.inapropiate_worker ? (
              <div className="flex justify-between">
                <div className="flex flex-col w-full">
                  <p className="text-azulBonico font-semibold">
                    {applicant.first_name.charAt(0).toUpperCase() +
                      applicant.first_name.slice(1)}{" "}
                    {applicant.last_name}
                  </p>

                  <p className="text-[16px] font-semibold">{applicant.title}</p>
                  <p className="text-[13px] text-slate-400 font-normal">
                    {postcode &&
                      postcode[0]?.slice(0, 1) +
                        postcode[0]
                          ?.slice(1, postcode[0].length)
                          .toLowerCase() +
                        "," +
                        postcode[1]}
                    , {applicant.city_name}
                  </p>

                  {applicationWindow === "hired" ||
                  window.location.pathname === "/applicants/hired" ? (
                    <div className="mt-2">
                      {!applicant.date_early_end &&
                        applicant.date_start > applicant.server_date && (
                          <p className="text-sm font-semibold">
                            {i18n.language === "en" ? (
                              <>
                                {t("worker.diplay_jobs.employer.start_working")}{" "}
                                {HandleMonthName(
                                  applicant.date_start?.slice(5, 7)
                                )}{" "}
                                {applicant.date_start?.slice(8)}
                                <span className="text-xs">
                                  {endingStart}
                                </span>{" "}
                                {t("worker.display_job.worker.until")}{" "}
                                {HandleMonthName(
                                  applicant.date_end?.slice(5, 7)
                                )}{" "}
                                {applicant.date_end?.slice(8)}
                                <span className="text-xs">{endingEnd}</span>
                              </>
                            ) : (
                              <>
                                {t("worker.diplay_jobs.employer.start_working")}{" "}
                                {applicant.date_start?.slice(8)} de{" "}
                                {HandleMonthNameES(
                                  applicant.date_start?.slice(5, 7)
                                )}{" "}
                                {t("worker.display_job.worker.until")} el{" "}
                                {applicant.date_end?.slice(8)} de{" "}
                                {HandleMonthNameES(
                                  applicant.date_end?.slice(5, 7)
                                )}
                              </>
                            )}
                          </p>
                        )}
                      {!applicant.date_early_end &&
                        applicant.date_start <= applicant.server_date &&
                        applicant.server_date <= applicant.date_end && (
                          <p className="text-sm font-semibold">
                            {i18n.language === "en" ? (
                              <>
                                {t(
                                  "worker.diplay_jobs.employer.started_working"
                                )}{" "}
                                {HandleMonthName(
                                  applicant.date_start?.slice(5, 7)
                                )}{" "}
                                {applicant.date_start?.slice(8)}
                                <span className="text-xs">
                                  {endingStart}
                                </span>{" "}
                                {t("worker.diplay_jobs.employer.until")}{" "}
                                {HandleMonthName(
                                  applicant.date_end?.slice(5, 7)
                                )}{" "}
                                {applicant.date_end?.slice(8)}
                                <span className="text-xs">{endingEnd}</span>
                              </>
                            ) : (
                              <>
                                {t(
                                  "worker.diplay_jobs.employer.started_working"
                                )}{" "}
                                {applicant.date_start?.slice(8)} de{" "}
                                {HandleMonthNameES(
                                  applicant.date_start?.slice(5, 7)
                                )}{" "}
                                {t("worker.diplay_jobs.employer.until")} el{" "}
                                {applicant.date_end?.slice(8)} de{" "}
                                {HandleMonthNameES(
                                  applicant.date_end?.slice(5, 7)
                                )}
                              </>
                            )}
                          </p>
                        )}
                      {!applicant.date_early_end &&
                        applicant.server_date > applicant.date_end && (
                          <p className="text-sm font-semibold">
                            {i18n.language === "en" ? (
                              <>
                                {t("worker.diplay_jobs.employer.worked_from")}{" "}
                                {HandleMonthName(
                                  applicant.date_start?.slice(5, 7)
                                )}{" "}
                                {applicant.date_start?.slice(8)}
                                <span className="text-xs">
                                  {endingStart}
                                </span>{" "}
                                {t("worker.display_job.worker.until")}{" "}
                                {HandleMonthName(
                                  applicant.date_end?.slice(5, 7)
                                )}{" "}
                                {applicant.date_end?.slice(8)}
                                <span className="text-xs">{endingEnd}</span>
                              </>
                            ) : (
                              <>
                                {t("worker.diplay_jobs.employer.worked_from")}{" "}
                                {applicant.date_start?.slice(8)} de{" "}
                                {HandleMonthNameES(
                                  applicant.date_start?.slice(5, 7)
                                )}{" "}
                                {t("worker.display_job.worker.until")} el{" "}
                                {applicant.date_end?.slice(8)} de{" "}
                                {HandleMonthNameES(
                                  applicant.date_end?.slice(5, 7)
                                )}
                              </>
                            )}
                          </p>
                        )}
                      {applicant.date_early_end && (
                        <p className="text-sm font-semibold">
                          {i18n.language === "en" ? (
                            <>
                              {t("worker.diplay_jobs.employer.worked_from")}{" "}
                              {HandleMonthName(
                                applicant.date_start?.slice(5, 7)
                              )}{" "}
                              {applicant.date_start?.slice(8)}
                              <span className="text-xs">
                                {endingStart}
                              </span>{" "}
                              {t("worker.display_job.worker.until")}{" "}
                              {HandleMonthName(
                                applicant.date_early_end?.slice(5, 7)
                              )}{" "}
                              {applicant.date_early_end?.slice(8)}
                              <span className="text-xs">{endingEarlyEnd}</span>
                            </>
                          ) : (
                            <>
                              {t("worker.diplay_jobs.employer.worked_from")}{" "}
                              {applicant.date_start?.slice(8)} de{" "}
                              {HandleMonthNameES(
                                applicant.date_start?.slice(5, 7)
                              )}{" "}
                              {t("worker.display_job.worker.until")} el{" "}
                              {applicant.date_early_end?.slice(8)} de{" "}
                              {HandleMonthNameES(
                                applicant.date_early_end?.slice(5, 7)
                              )}
                            </>
                          )}
                        </p>
                      )}
                      {applicant.date_early_end && (
                        <p className="text-sm text-slate-400 font-semibold">
                          {t(
                            "employer.hired_workers.display_hires.job_finished"
                          )}{" "}
                          <span className="font-semibold">
                            {applicant.early_end_for.toLowerCase()}
                          </span>{" "}
                          {applicant?.end_work_reason_name && (
                            <>
                              <span>
                                {t(
                                  "employer.hired_workers.display_hires.because_of"
                                )}
                              </span>{" "}
                              <span className="font-semibold italic">
                                {applicant.early_end_for.includes("Employer")
                                  ? applicant.end_work_reason_name.toLowerCase()
                                  : applicant.quit_work_reason_name.toLowerCase()}
                              </span>
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="text-sm flex gap-12 mt-3 w-full">
                      {applicant.budget === "0" ? (
                        <p>{t("employer.invite_workers.not_specified")}</p>
                      ) : (
                        <p>
                          <span className="font-semibold">
                            {applicant.budget}€
                          </span>{" "}
                          / {t("employer.job_details.hour")}
                        </p>
                      )}

                      <p>
                        {" "}
                        {t("employer.invite_workers.preferred_job_type")}:{" "}
                        <span className="font-semibold">
                          {applicant.job_type_name}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {selectedSection === "declined" && (
                  <>
                    {applicant?.declined === "true" && (
                      <div className="pr-8 font-semibold text-sm h-fit w-fit flex flex-nowrap">
                        <div className="flex flex-col items-start gap-2 mr-2">
                          <p className="whitespace-nowrap">
                            {t("employer.worker_card.declined")}{" "}
                            <span className=" text-xs font-semibold text-slate-400 ">
                              {i18n.language === "en" ? (
                                <>
                                  on{" "}
                                  {HandleMonthName(
                                    applicant?.declined_reason_date?.slice(3, 5)
                                  )}{" "}
                                  {applicant?.declined_reason_date?.slice(0, 2)}
                                </>
                              ) : (
                                <>
                                  el{" "}
                                  {applicant?.declined_reason_date?.slice(0, 2)}{" "}
                                  de{" "}
                                  {HandleMonthNameES(
                                    applicant?.declined_reason_date?.slice(3, 5)
                                  )}
                                </>
                              )}
                            </span>
                          </p>
                          <p className="text-xs text-slate-400 italic whitespace-nowrap">
                            {applicant?.declined_reason_name}
                          </p>
                        </div>
                      </div>
                    )}
                    {applicant?.offer_declined_by_worker === "true" && (
                      <div className="pr-8  text-sm h-fit w-fit flex flex-nowrap">
                        <div className="flex flex-col items-start gap-2 mr-2">
                          <p className="font-semibold whitespace-nowrap">
                            {t("employer.worker_card.declined")}{" "}
                            <span className=" text-xs font-semibold text-slate-400 ">
                              {i18n.language === "en" ? (
                                <>
                                  on{" "}
                                  {HandleMonthName(
                                    applicant?.offer_declined_reason_date?.slice(
                                      3,
                                      5
                                    )
                                  )}{" "}
                                  {applicant?.offer_declined_reason_date?.slice(
                                    0,
                                    2
                                  )}
                                </>
                              ) : (
                                <>
                                  el{" "}
                                  {applicant?.offer_declined_reason_date?.slice(
                                    0,
                                    2
                                  )}{" "}
                                  de{" "}
                                  {HandleMonthNameES(
                                    applicant?.offer_declined_reason_date?.slice(
                                      3,
                                      5
                                    )
                                  )}
                                </>
                              )}
                            </span>
                          </p>
                          <p className="text-xs font-semibold italic text-slate-400 whitespace-nowrap">
                            {applicant?.offer_declined_reason_name}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {selectedSection === "archived" &&
                  (window.location.pathname === "/applicants/review" ||
                    applicationWindow === "review") &&
                  jobData?.data[0]?.job_status !== "Closed" &&
                  jobData?.data[0]?.expired !== "true" && (
                    <div className="pr-8 font-semibold text-sm h-fit w-fit flex flex-nowrap">
                      <div className="flex items-center gap-4 mr-2">
                        <button
                          className="px-[36px] py-1 text-azulBonico hover:bg-[#f7f4f4] border border-azulBonico bg-white rounded-full transition duration-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            setClickedWorker(applicant);
                            setShowModal((prev) => ({
                              ...prev,
                              decline: true,
                            }));
                            setDeclinedApplicant(applicant);
                          }}
                        >
                          {t("employer.invite_workers.decline")}
                        </button>
                        <button
                          className="px-8 py-[5px] text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleArchived(applicant);
                          }}
                        >
                          {t("employer.invite_workers.unarchive")}
                        </button>
                      </div>
                    </div>
                  )}
                {(applicationWindow === "hired" ||
                  window.location.pathname === "/applicants/hired") && (
                  <div className="mr-8">
                    {!applicant.date_early_end &&
                      applicant.date_start > applicant.server_date && (
                        <div className="text-sm mr-8 text-right">
                          <p className="font-semibold text-slate-500  whitespace-nowrap">
                            {t("employer.invite_workers.no_working")}
                          </p>
                        </div>
                      )}
                    {!applicant.date_early_end &&
                      applicant.date_start <= applicant.server_date &&
                      applicant.server_date <= applicant.date_end && (
                        <div className="text-sm flex flex-col gap-2 w-fit h-fit items-end whitespace-nowrap">
                          <p className="text-green-500 font-semibold">
                            {t("employer.invite_workers.currently_working")}
                          </p>

                          <button
                            className="button-rate !w-full"
                            onClick={(e) => {
                              e.stopPropagation();
                              setClickedWorker(applicant);
                              setShowModal((prev) => ({
                                ...prev,
                                end: true,
                              }));
                            }}
                          >
                            {t("employer.invite_workers.end_contract")}
                          </button>
                        </div>
                      )}
                    {applicant.server_date < applicant.date_early_end! && (
                      <div className="text-sm flex flex-col gap-2 text-left">
                        <p className="text-green-500 font-semibold whitespace-nowrap">
                          {t("employer.invite_workers.currently_working")}
                        </p>
                      </div>
                    )}

                    {(applicant.server_date > applicant.date_end ||
                      applicant.server_date >= applicant.date_early_end!) && (
                      <div className="text-sm flex flex-col gap-2 mr-8 text-center">
                        <p className="font-semibold text-slate-500  whitespace-nowrap">
                          {t("employer.invite_workers.finished_working")}
                        </p>
                        {applicant.score_from_employer === "0.00" ||
                        applicant.score_from_employer === null ? (
                          <button
                            className="button-rate w-full whitespace-nowrap"
                            onClick={(e) => {
                              e.stopPropagation();
                              setClickedWorker(applicant);
                              setShowModal((prev) => ({
                                ...prev,
                                rating: true,
                              }));
                            }}
                          >
                            {t("employer.invite_workers.give_feedback")}
                          </button>
                        ) : (
                          <div className="flex gap-2 font-semibold text-sm">
                            <RatingStars
                              score={applicant.score_from_employer}
                              setActiveStar={setActiveStar}
                              activeStar={applicant.score_from_employer}
                              showHalf={true}
                              showModal={false}
                              evaluation={false}
                            />
                            <p className="text-sm text-slate-500 mt-[1px]">
                              ({applicant.score_from_employer})
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {(applicationWindow === "offers" ||
                  window.location.pathname === "/applicants/offers") &&
                  jobData?.data?.[0]?.job_status !== "Closed" &&
                  jobData?.data?.[0]?.expired !== "true" && (
                    <div className="pr-8 font-semibold text-sm h-fit flex flex-col items-end">
                      <button
                        className="px-4 py-1 w-40 text-azulBonico hover:text-white hover:bg-azulBonico border border-azulBonico bg-white rounded-full transition duration-300 "
                        onClick={(e) => {
                          setClickedWorker(applicant);
                          e.stopPropagation();
                          checkIfThereIsConversation(applicant);
                          handleSeenApplicant(applicant);
                        }}
                      >
                        <p className="text-[12px]">
                          {t("employer.invite_workers.messages")}
                        </p>
                      </button>
                      <p className="text-slate-400 mt-2 ml-2 text-[10px]">
                        {t("employer.invite_workers.offer_expires")}{" "}
                        {applicant.expire_offer_days}{" "}
                        {t("employer.invite_workers.days")}
                      </p>
                    </div>
                  )}
                {(selectedSection === "all" ||
                  selectedSection === "shortlisted") &&
                  (applicationWindow === "review" ||
                    window.location.pathname === "/applicants/review") &&
                  jobData?.data?.[0]?.job_status !== "Closed" &&
                  jobData?.data?.[0]?.expired !== "true" && (
                    <div className="pr-8 font-semibold text-sm h-fit w-fit flex flex-nowrap">
                      <div className="flex items-center gap-3 mr-2">
                        <div className="relative">
                          <div
                            className={` ${
                              showArchivePopover === i &&
                              " transition scale-x-100"
                            } ${
                              showArchivePopover !== i &&
                              "scale-x-0 -translate-x-5"
                            }  duration-500  absolute left-4 w-fit
                      `}
                          >
                            <div
                              className={`absolute right-10 -bottom-11 font-light text-xs rounded-lg border dark:text-whiteish dark:bg-background border-gray-200 shadow dark:border-background bg-white`}
                            >
                              <div className="flex flex-col px-6 py-2 items-start whitespace-nowrap">
                                <p className="text-[13px] font-normal">
                                  {t("employer.worker_cards.archive")}
                                  <br />{" "}
                                  <span className="text-gray-400">
                                    {t("employer.invite_workers.applicant_not")}
                                  </span>
                                </p>
                                <p className=" text-gray-400 text-xs  font-normal">
                                  {t("employer.invite_workers.notified")}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`bg-white  border-r-2 border-t-2 dark:border-background dark:bg-background border-gray-200 z-20 w-4 h-4 top-2 rotate-45 absolute right-[31px] `}
                            ></div>
                          </div>
                          <ThumbDown
                            className={`rounded-full ${
                              applicant.archived === "true"
                                ? "border-red-500"
                                : "hover:border-red-500"
                            } hover:bg-white dark:bg-whiteish  w-fit h-7 p-1.5  overflow-visible border-2 cursor-pointer `}
                            onClick={(e: any) => {
                              setShowBannerArchived(true);

                              e.stopPropagation();
                              setClickedWorker({
                                first_name: applicant.first_name,
                                client_id: applicant.client_id,
                                archived: "true",
                              });
                              setTimeout(() => {
                                // if (showBannerArchived) {
                                setTimeout(() => {
                                  handleArchived(applicant);
                                  setRefreshData(true);
                                }, 50);
                                // }
                              }, 1000);
                              setTimeout(() => {
                                setShowBannerArchived(false);
                              }, 2000);
                            }}
                            onMouseEnter={() => setShowArchivePopover(i)}
                            onMouseLeave={() => setShowArchivePopover(-1)}
                          />
                        </div>

                        {applicant.saved === "true" && (
                          <HeartFilled
                            color="red"
                            className={`rounded-full ${
                              applicant.archived === "false"
                                ? "border-green-500"
                                : "hover:border-green-500"
                            } hover:bg-white dark:bg-whiteish    w-fit h-7 p-0.5  overflow-visible border-2 cursor-pointer`}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (applicant.saved === "true") {
                                handleShortlist(applicant);
                              } else {
                                setClickedWorker({
                                  first_name: applicant.first_name,
                                  client_id: applicant.client_id,
                                  saved: "true",
                                });
                                setTimeout(() => {
                                  setShowBannerArchived(true);
                                  handleShortlist(applicant);
                                  setRefreshData(true);
                                }, 50);
                              }
                            }}
                          />
                        )}
                        {applicant.saved !== "true" && (
                          <HeartIcon
                            color="gray"
                            className={`rounded-full ${
                              applicant.archived === "false"
                                ? "border-green-500"
                                : "hover:border-green-500"
                            } hover:bg-white dark:bg-whiteish text-red-500  w-fit h-7 p-0.5 overflow-visible border-2 cursor-pointer`}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (applicant.saved === "true") {
                                handleShortlist(applicant);
                              } else {
                                setClickedWorker({
                                  first_name: applicant.first_name,
                                  client_id: applicant.client_id,
                                  saved: "true",
                                });
                                setTimeout(() => {
                                  setShowBannerArchived(true);
                                  handleShortlist(applicant);
                                  setRefreshData(true);
                                }, 50);
                              }
                            }}
                          />
                        )}
                      </div>
                      {/* <button
                        className="px-4 w-40 py-1 text-azulBonico bg-white hover:shadow  bg-transparent rounded-full transition duration-300"
                        onClick={(e) => {
                          setClickedWorker(applicant);
                          e.stopPropagation();
                          checkIfThereIsConversation(applicant);
                          handleSeenApplicant(applicant);
                        }}
                      >
                        {t("employer.invite_workers.messages")}
                      </button> */}
                      <a
                        className="px-[52px] ml-4 py-[5px] whitespace-nowrap text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOfferedApplicant(applicant);
                          navigate("/send-offer");
                        }}
                      >
                        {t("employer.invite_workers.sendOffer")}
                      </a>
                    </div>
                  )}

                {(applicationWindow === "invite" ||
                  window.location.pathname === "/applicants/invite") && (
                  <>
                    {applicant.invited === "true" ? (
                      <div className="whitespace-nowrap pr-8 flex flex-col">
                        <p className="text-slate-400 text-sm font-semibold">
                          {t("worker.worker_card.invited_to_job")}{" "}
                          {i18n.language === "en" ? (
                            <>
                              {HandleMonthName(
                                applicant.date_invited?.slice(5, 7)
                              )}{" "}
                              {applicant.date_invited?.slice(8, 10)}
                              <span className="text-xs">th</span>.{" "}
                            </>
                          ) : (
                            <>
                              {applicant.date_invited?.slice(8, 10)} de{" "}
                              {HandleMonthNameES(
                                applicant.date_invited?.slice(5, 7)
                              )}
                              .{" "}
                            </>
                          )}
                        </p>
                        <span
                          className="font-normal hover:underline text-right  cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setClickedWorker(applicant);
                            setShowModal((prev) => ({
                              ...prev,
                              invite: true,
                            }));
                          }}
                        >
                          {t("employer.invite_wokers.undo")}
                        </span>
                      </div>
                    ) : (
                      <div className="pr-8">
                        <button
                          className="px-6 h-fit text-sm font-semibold  whitespace-nowrap py-1 bg-azulBonico text-white  border border-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            setClickedWorker(applicant);

                            setShowModal((prev) => ({
                              ...prev,
                              invite: true,
                            }));
                          }}
                        >
                          {t("employer.invite_workers.invite_to_job")}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-5">
                <p className="text-azulBonico font-semibold">
                  {applicant.first_name} {applicant.last_name}
                </p>
                <div>
                  <p className="text-xs text-slate-500 font-medium">
                    {t("employer.worker_card.flagged_as_inappropiate")}:
                    <span className="italic">{applicant.inapropiate_desc}</span>
                  </p>
                  {applicant.inapropiate_note && (
                    <p className="text-xs text-slate-500 italic mt-1">
                      "{applicant.inapropiate_note}"
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {(applicant.inapropiate_worker === "false" ||
          !applicant.inapropiate_worker) && (
          <>
            <div className="pl-[163px] mb-4 w-full">
              {(applicant.cover_letter || applicant.overview) && (
                <p className="text-sm  pr-12 mb-3 cover_letter text-[#777777] ">
                  {applicant.cover_letter &&
                  applicant.cover_letter !== "undefined" ? (
                    <span className="font-semibold text-black dark:text-whiteish">
                      {t("employer.invite_workers.cover_letter")} -{" "}
                    </span>
                  ) : (
                    <span className="font-semibold text-black dark:text-whiteish">
                      {t("employer.invite_workers.description")} -{" "}
                    </span>
                  )}
                  {applicant.cover_letter &&
                  applicant.cover_letter !== "undefined"
                    ? applicant.cover_letter
                    : applicant.overview}
                </p>
              )}

              {applicant?.skills?.length > 0 &&
                window.location.pathname !== "/applicants/hired" && (
                  <CustomSkillCarousel>
                    {applicant?.skills?.split(",")?.map((skill) => {
                      return (
                        <p
                          key={skill}
                          className="bg-blancoGrisClaro px-12 text-xs py-0.5 flex whitespace-nowrap max-w-[700px] hover:bg-[#bdbdbd] my-1 border text-center mx-1 items-center align-center font-medium justify-center rounded-xl cursor-pointer text-[#343a47] z-0"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {skill}
                        </p>
                      );
                    })}
                  </CustomSkillCarousel>
                )}
            </div>

            <div className="pl-[163px] pb-4 flex text-xs mt-2 gap-2 cursor-pointer w-fit">
              {daysMap
                .sort((a: { id: string }, b: { id: string }) =>
                  a.id.localeCompare(b.id)
                )
                ?.map((day: { dayname: string; hours: string }) => (
                  <div
                    key={day.dayname}
                    className="flex flex-col text-center border-2 border-blue-200 dark:bg-background  rounded w-[45px]"
                  >
                    <p className="border-b border-blue-200 px-2 text-slate-500 dark:text-whiteish font-semibold">
                      {day.dayname}
                    </p>
                    <p className=" px-2 text-slate-500 dark:text-whiteish font-semibold">
                      {day.hours}h
                    </p>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ApplicantCard;
