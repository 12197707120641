import { Companies } from "src/types/types";

export const companies: Companies[] = [
  {
    active: "1",
    cif: "54201386T",
    city_id: "1379",
    company_logo: "img.flexihours/img_companies/company_logo_392.png",
    country_code: "ES",
    date_created: "2023-04-25 15:24:44",
    description:
      "Construcciones Manolo es una empresa familiar fundada en 2001, y que tras muchos años de esfuerzo, hemos conseguido establecernos como líderes en el sector de la demolición y construcción de viviendas y adosados.",
    id: "17",
    name: "Construcciones Manolo",
    phone: "688253552",
    post_code_id: null,
    prefix_phone: "34",
    validated: "1",
  },
  {
    active: "1",
    cif: "A28837680",
    city_id: "1040",
    company_logo: "img.flexihours/img_companies/company_logo_391.png",
    country_code: "ES",
    date_created: "2023-04-25 13:58:30",
    description:
      "Tienda en el Centro de Valencia de Artículos de Fiesta y Globos. \r\nNos encontramos en la Calle Pizarro, nr. 22, Bajo, Derecha. \r\nEstamos en el centro de la ciudad de Valencia, a escasos metros de la estación de trenes de Valencia Norte.",
    id: "16",
    name: "Confetti Party Boutique",
    phone: "961945034",
    post_code_id: null,
    prefix_phone: "34",
    validated: "0",
  },
  {
    active: "1",
    cif: "B05383716",
    city_id: "1801",
    company_logo: null,
    country_code: "ES",
    date_created: "2023-05-05 11:54:10",
    description:
      "tkp`thkpfhkfhklfghklfgmlfkop`gyhkpgyhklñgyhklñghlñghklghklghklghklghlñklñghklgkhlgkh",
    id: "18",
    name: "SOCIEDAD DE LAS HADAS DE LOS DIENTES SL",
    phone: "659105929",
    post_code_id: null,
    prefix_phone: "34",
    validated: "0",
  },
];
