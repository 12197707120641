import React from "react";
import { Skeleton } from "../skeleton";

const TimeSkeleton = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center gap-4 mb-5">
        <Skeleton className="h-4 w-[8%] rounded-full" />
        <Skeleton className="h-4 w-[13%] rounded-full" />
      </div>
    </div>
  );
};

export default TimeSkeleton;
