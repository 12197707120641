import React, { Dispatch, SetStateAction, useState } from "react";
import WorkerCards from "./WorkerCards";

import { ReactComponent as Dismissed } from "../../assets/img/dismissed.svg";
import { ReactComponent as ArrowUp } from "../../assets/img/arrow-up-solid.svg";
import { useTranslation } from "react-i18next";
import { ApplicantTypes, OffersTypes } from "../../types/types";

interface DeclinedApplicantsProps {
  declinedApplicants: ApplicantTypes[];
  declinedOffers: ApplicantTypes[];
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

const DeclinedApplicants = ({
  declinedApplicants,
  declinedOffers,
  refetch,
  setRefreshData,
}: DeclinedApplicantsProps) => {
  const [showDeclined, setShowDeclined] = useState(true);
  const [showDeclinedOffers, setShowDeclinedOffers] = useState(true);
  const { t } = useTranslation();
  return (
    <div>
      {declinedApplicants?.length === 0 && declinedOffers?.length === 0 && (
        <div className="flex flex-col w-full items-center justify-center my-12 dark:text-whiteish">
          <Dismissed className="h-32 mb-6" />
          <p className="text-lg font-bold tracking-wide">
            {t("employer.applicants_declined.title")}
          </p>
          <p className="text-sm mt-4">
            {t("employer.applicants_declined.desc")}
          </p>
        </div>
      )}
      {declinedApplicants?.length > 0 && (
        <>
          <div className="px-8 pt-6 py-3 border-t border-azulBonico">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setShowDeclined(!showDeclined)}
            >
              <ArrowUp
                className={`h-4 fill-azulOscuro ${
                  showDeclined
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown"
                }`}
              />
              <p className="font-bold text-sm text-azulOscuro hover:underline">
                {declinedApplicants?.length} Declined
              </p>
            </div>
          </div>
          {showDeclined && (
            <>
              <WorkerCards
                refetch={refetch}
                applicantsData={declinedApplicants}
                setRefreshData={setRefreshData}
              />
            </>
          )}
        </>
      )}
      {declinedOffers?.length > 0 && (
        <>
          <div className="px-8 pt-6 py-3 border-t border-azulBonico">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setShowDeclinedOffers(!showDeclinedOffers)}
            >
              <ArrowUp
                className={`h-4 fill-azulOscuro ${
                  showDeclinedOffers
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown"
                }`}
              />
              <p className="font-bold text-sm text-azulOscuro hover:underline">
                {declinedOffers?.length} Declined offers by worker
              </p>
            </div>
          </div>
          {showDeclinedOffers && (
            <>
              <WorkerCards
                refetch={refetch}
                applicantsData={declinedOffers}
                setRefreshData={setRefreshData}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DeclinedApplicants;
