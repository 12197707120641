import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";
import { useQuery } from "react-query";

export const useGetNotifications = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const checkNotifications = async () => {
    const data = await fetch(
      `${BASE_URL}notifications?client_id=${userID}&limit=10`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const {
    data: notificationData,
    isLoading: isNotificationsLoading,
    refetch,
  } = useQuery(["notifications"], checkNotifications);

  return { notificationData, isNotificationsLoading, refetch };
};
