import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

interface MonthPickerProps {
  setMonth: Dispatch<SetStateAction<string>>;
  month: string;
  checking: boolean;
  job?: string;
}

function MonthPicker({ setMonth, month, checking, job }: MonthPickerProps) {
  const [monthe, setMonthe] = useState("");
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const options = [
    {
      value: "January",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month1.sortbyDropdown"
      ),
      id: "01",
    },
    {
      value: "February",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month2.sortbyDropdown"
      ),
      id: "02",
    },
    {
      value: "March",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month3.sortbyDropdown"
      ),
      id: "03",
    },
    {
      value: "April",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month4.sortbyDropdown"
      ),
      id: "04",
    },
    {
      value: "May",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month5.sortbyDropdown"
      ),
      id: "05",
    },
    {
      value: "June",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month6.sortbyDropdown"
      ),
      id: "06",
    },
    {
      value: "July",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month7.sortbyDropdown"
      ),
      id: "07",
    },
    {
      value: "August",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month8.sortbyDropdown"
      ),
      id: "08",
    },
    {
      value: "September",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month9.sortbyDropdown"
      ),
      id: "09",
    },
    {
      value: "October",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month10.sortbyDropdown"
      ),
      id: "10",
    },
    {
      value: "November",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month11.sortbyDropdown"
      ),
      id: "11",
    },
    {
      value: "December",
      label: t(
        "createprofile.submit.edityourworkexperience.subtitle4.card.month12.sortbyDropdown"
      ),
      id: "12",
    },
  ];
  useEffect(() => {
    handleMonthWord();
  }, []);
  const handleMonthWord = () => {
    switch (job?.substring(5, 7)) {
      case "01":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month1.sortbyDropdown"
          )}`
        );
      case "02":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month2.sortbyDropdown"
          )}`
        );
      case "03":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month3.sortbyDropdown"
          )}`
        );
      case "04":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month4.sortbyDropdown"
          )}`
        );
      case "05":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month5.sortbyDropdown"
          )}`
        );
      case "06":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month6.sortbyDropdown"
          )}`
        );
      case "07":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month7.sortbyDropdown"
          )}`
        );
      case "08":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month8.sortbyDropdown"
          )}`
        );
      case "09":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month9.sortbyDropdown"
          )}`
        );
      case "10":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month10.sortbyDropdown"
          )}`
        );
      case "11":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month11.sortbyDropdown"
          )}`
        );
      case "12":
        return setMonthe(
          `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month12.sortbyDropdown"
          )}`
        );
    }
  };
  const handleMonth = (e: any) => {
    setMonthe(e.value);
    switch (e.value) {
      case "January":
        return setMonth("01");
      case "February":
        return setMonth("02");
      case "March":
        return setMonth("03");
      case "April":
        return setMonth("04");
      case "May":
        return setMonth("05");
      case "June":
        return setMonth("06");
      case "July":
        return setMonth("07");
      case "August":
        return setMonth("08");
      case "September":
        return setMonth("09");
      case "October":
        return setMonth("10");
      case "November":
        return setMonth("11");
      case "December":
        return setMonth("12");
    }
  };

  const colourStyles = {
    control: () => ({
      border: "1px solid #A4211B",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div>
      <div className="relative" ref={ref}>
        {monthe && (
          <Select
            styles={checking === true && !month ? colourStyles : colourStyles2}
            className="w-full cursor-pointer"
            options={options}
            defaultValue={{ label: monthe, value: monthe }}
            onFocus={handleMouseEnter}
            onBlur={handleMouseLeave}
            onChange={(e) => handleMonth(e)}
            placeholder={`${t(
              "createprofile.employment.placeholder.subtitle5_3.card.sortbyDropdown"
            )}`}
          />
        )}
        {!monthe && (
          <Select
            styles={checking === true && !month ? colourStyles : colourStyles2}
            className="w-full cursor-pointer"
            options={options}
            onFocus={handleMouseEnter}
            onBlur={handleMouseLeave}
            onChange={(e) => handleMonth(e)}
            placeholder={`${t(
              "createprofile.employment.placeholder.subtitle5_3.card.sortbyDropdown"
            )}`}
          />
        )}
      </div>
    </div>
  );
}

export default MonthPicker;
