import React, { useState, useEffect } from "react";

import ModalProfileCompleteness from "../Modals/ModalProfileCompleteness";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../Config/api.config";
import ProgressBarRegister from "../ProgressBarRegistration/ProgressBarRegister";
import { useTranslation } from "react-i18next";
import { UserInfoTypes } from "src/types/types";
import { useQuery } from "react-query";

interface ProfileCompletenessProps {
  userInfo: UserInfoTypes;
}

function ProfileCompleteness({ userInfo }: ProfileCompletenessProps) {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [profileCompletenessData, setProfileCompletenessData] = useState(0);
  const [showCompletenessModal, setShowCompletenessModal] = useState(false);
  const { t } = useTranslation();

  const getProfileCompleteness = async () => {
    const data = await fetch(`${BASE_URL}worker.profile?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerInfo } = useQuery(["workerInfo"], getProfileCompleteness);

  useEffect(() => {
    setProfileCompletenessData(workerInfo?.profileProgress);
  }, [workerInfo]);

  return (
    <div>
      <div className="bg-blue-100 flex flex-col py-4 px-6 dark:bg-background dark:border-y dark:border-border shadow rounded">
        <p
          className="text-sm font-bold hover:text-azulOscuro cursor-pointer hover:underline"
          onClick={() => setShowCompletenessModal(true)}
        >
          {t("worker.find_work.dashboard.profile_completeness")}:
        </p>
        <div className="relative">
          <div className="flex items-center mr-8">
            {profileCompletenessData !== 0 && (
              <ProgressBarRegister
                height={8}
                bgcolor="#2abeed"
                progress={profileCompletenessData}
                margintop="1rem"
              />
            )}
          </div>
          <p className="absolute -top-[20px] -right-1  text-sm mt-[30px]">
            {profileCompletenessData}%
          </p>
        </div>
      </div>
      {showCompletenessModal === true && (
        <ModalProfileCompleteness
          showCompletenessModal={showCompletenessModal}
          setShowCompletenessModal={setShowCompletenessModal}
          userInfo={userInfo}
          percentage={profileCompletenessData}
        />
      )}
    </div>
  );
}

export default ProfileCompleteness;
