import React, { useRef, useEffect, useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { StylesModal } from "../../Utils/stylesModal";
import { Button } from "../ui/Button";

interface ModalInviteJobProps {
  setShowModal: any;
  showModal: { invite: boolean };
  worker: {
    invited: string;
    title: string;
    first_name: string;
    client_id_worker: string;
  };
  handleInviteWorker: (args: string) => void;
  cancelInviteWorker: (args: string) => void;
}

export default function ModalInviteJob({
  setShowModal,
  showModal,
  worker,
  handleInviteWorker,
  cancelInviteWorker,
}: ModalInviteJobProps) {
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.invite && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.invite]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, invite: false }));
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showModal.invite} ariaHideApp={false} style={StylesModal}>
      {" "}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100 "
        } 
          //  transition duration-150 w-[90%] lg:w-[30%] left-[50%] ease-out rounded translate-x-[-50%] translate-y-[-90%] top-[50%] bg-white border-0.5`}
        ref={ref}
      >
        <div className="text-lg font-bold border-b  px-8 py-3">
          {worker.invited !== "false" ? (
            <>{t("employer.modal_invite_job.cancel_invitation")}</>
          ) : (
            <>{t("employer.modal_invite_job.invite_to_job")}</>
          )}
        </div>
        <div className=" px-8 py-3 pb-1 font-normal">
          {worker.invited === "false" ? (
            <p className="text-slate-400">
              {t("employer.modal_invite_job.invite")}{" "}
              <span className="text-black italic">{worker.title}</span>{" "}
              {t("employer.modal_invite_job.to")}{" "}
              <span>{worker.first_name}</span>
            </p>
          ) : (
            <p className="text-slate-400">
              {t("employer.modal_invite_job.cancel_invite")}{" "}
              <span className="text-black italic">{worker.title}</span>{" "}
              {t("employer.modal_invite_job.to")}{" "}
              <span>{worker.first_name}</span>
            </p>
          )}
        </div>

        <div className="flex w-full h-full items-end p-4 gap-7 pr-4 justify-end ">
          <Button
            className="bg-none text-sm   h-7  text-azulBonico  hover:underline  mt-1 "
            onClick={() => {
              closeModal();
            }}
          >
            {t("employer.messages.leftbar.header.back")}
          </Button>
          <Button
            className="bg-azulBonico border-none  hover:bg-azulOscuro   text-white w-[145px] rounded-[20px] text-sm px-[1rem] py-0  h-7"
            onClick={() => {
              if (worker.invited === "true") {
                cancelInviteWorker(worker.client_id_worker);
              } else {
                handleInviteWorker(worker.client_id_worker);
              }
              setTimeout(() => {
                setShowModal((prev: any) => ({
                  ...prev,
                  invite: false,
                  worker: false,
                }));
              }, 100);
            }}
          >
            {worker.invited === "true"
              ? t("employer.modal_invite_job.cancel_invitation")
              : t("employer.modal_invite_job.send_invitation")}{" "}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
