import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import ModalWorkerCard from "../Modals/ModalWorkerCard";
import { BASE_URL, BASE_URL_IMG } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import ModalStartChat from "../Modals/ModalStartChat";
import ModalDeclineApplicant from "../Modals/ModalDeclineApplicant";
import {
  messages4JobId,
  numberOfAllApplicants,
  numberOfArchivedApplicants,
  numberOfShortlistedApplicants,
  numberOfDeclinedApplicants,
  selectedApplicantsSection,
  applicationView,
  loadingCandidates,
} from "../../Atoms/jotaiAtoms";

import { ReactComponent as Cross } from "../../assets/img/cross-white.svg";
import ModalSendRating from "../Modals/ModalSendRating";
import ModalInviteJob from "../Modals/ModalInviteJob";
import ModalEditEndEarlyDate from "../Modals/ModalEditEndEarlyDate";
import ModalVideoIntroduction from "../Modals/ModalVideoIntroduction";
import { ModalInappropriateWorker } from "../Modals/ModalInappropriateWorker";
import CustomSkillCarousel from "../WorkerDashboard/CustomSkillCarousel";
import { useTranslation } from "react-i18next";
import { ApplicantTypes, HourDaysTypes } from "../../types/types";
import { WorkerCardSkeleton } from "../ui/skeleton/WorkerCardSkeleton";
import HandleDayEnding from "src/Utils/HandleDayEnding";
import { CheckIcon } from "@heroicons/react/24/solid";
import { cn } from "src/lib/utils";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import ApplicantCard from "./ApplicantCard";
import { getCreditsTypes } from "src/Routing/useCreditsActions";

interface WorkerCardProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData?: any;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
}

export interface ModalProps {
  invite: boolean;
  chat: boolean;
  rating: boolean;
  decline: boolean;
  worker: boolean;
  end: boolean;
  video: boolean;
  report: boolean;
}

const WorkerCards = ({
  applicantsData,
  setApplicantsData,
  setRefreshData,
  refetch,
}: WorkerCardProps) => {
  const { inviteJob } = getCreditsTypes();
  const { creditsDataInfo, updateCredits } = useUpdateCredits(
    inviteJob?.credits.toString(),
    inviteJob?.id
  );
  const [clickedWorker, setClickedWorker] = useState<any>({
    saved: "false",
    archived: "false",
  });
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [counterShortlisted, setCounterShortlisted] = useAtom(
    numberOfShortlistedApplicants
  );
  const [counterArchived, setCounterArchived] = useAtom(
    numberOfArchivedApplicants
  );
  const [counterAllApplicants, setCounterAllApplicants] = useAtom(
    numberOfAllApplicants
  );
  // const [showSchedulePopover, setShowSchedulePopover] = useState(false);
  // const [showTransition, setShowTransition] = useState(false);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [showArchivePopover, setShowArchivePopover] = useState(-1);
  const [selectedSection, setSelectedSection] = useAtom(
    selectedApplicantsSection
  );
  const [declineReason, setDeclineReason] = useState("");
  const [declineExplanation, setDeclineExplanation] = useState("");
  const [declinedApplicant, setDeclinedApplicant] = useState<any>();
  const [offeredApplicant, setOfferedApplicant] = useLocalStorage(
    "dataOfferedApplicant",
    ""
  );
  const [applicationWindow, setApplicationWindow] = useAtom(applicationView);
  const [loading, setLoading] = useAtom(loadingCandidates);

  const [counterDeclined, setCounterDeclined] = useAtom(
    numberOfDeclinedApplicants
  );
  const [activeStar, setActiveStar] = useState(-1);
  const [workerInfo, setWorkerInfo] = useState<any>();
  // const [showRateModal, setShowRateModal] = useState(false);
  // const [workerTimes, setWorkerTimes] = useState();
  const [messagesJobId, setMessagesJobId] = useAtom(messages4JobId);
  const [showBannerArchived, setShowBannerArchived] = useState(false);
  const [showModal, setShowModal] = useState<ModalProps>({
    invite: false,
    chat: false,
    rating: false,
    decline: false,
    worker: false,
    end: false,
    video: false,
    report: false,
  });
  const data = new FormData();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  data.append("_method", "PUT");
  data.append("job_id", jobID);
  data.append("seen", "1");
  const handleCheckApplicantAsSeen = (id: string) => {
    data.append("worker_id", id);
    fetch(`${BASE_URL}employer.applicants`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));
  };
  const addData = new FormData();
  addData.append("_method", "POST");
  addData.append("client_id", userID);
  addData.append("job_id", jobID);

  const handleAddShortlisted = (props: string) => {
    addData.append("worker_id", props);
    fetch(`${BASE_URL}employer.saved.applicants`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: addData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };
  const handleRemoveShortlisted = (props: string) => {
    fetch(
      `${BASE_URL}employer.saved.applicants?client_id=${userID}&job_id=${jobID}&worker_id=${props}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const getWorkerInfo = (id: string) => {
    fetch(`${BASE_URL}workers?client_id=${id}&client_id_employer=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setWorkerInfo((prev: any) => ({
            ...prev,
            allChosenTimes: data.result.workerProfileTime,
            languages: data.result.workerLanguage,
            education: data.result.workerEducation,
            experience: data.result.workerExperience,
            categories: data.result.workerCategoryList,
            data: data.result.workerProfile,
            score: data.result.score,
            report: data.result.inapropiateFlag,
          }));
          setShowModal((prev) => ({ ...prev, worker: true }));
        }
      })
      .catch((err) => console.log(err));
  };

  const declineData = new FormData();
  declineData.append("_method", "POST");
  declineData.append("client_id", userID);
  declineData.append("job_id", jobID);

  const handleDeclineApplicant = () => {
    if (selectedSection === "all") {
      setRefreshData(true);
      setCounterDeclined(counterDeclined + 1);
      setCounterAllApplicants(counterAllApplicants - 1);
      // if (applicant.saved === "true") {
      //   setCounterShortlisted(counterShortlisted - 1);
      // }
    } else if (selectedSection === "shortlisted") {
      setRefreshData(true);
      setCounterShortlisted(counterShortlisted - 1);
      setCounterDeclined(counterDeclined + 1);
      setCounterAllApplicants(counterAllApplicants - 1);
    } else if (selectedSection === "archived") {
      setCounterArchived(counterArchived - 1);
      setCounterDeclined(counterDeclined + 1);
    }
    declineData.append("worker_id", declinedApplicant.client_id);
    declineData.append("decline_reason_id", declineReason);
    declineData.append("note", declineExplanation);
    fetch(`${BASE_URL}employer.declined.applicants`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: declineData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") setRefreshData(true);
      })
      .catch((err) => console.log(err));
  };

  const archivedData = new FormData();
  archivedData.append("_method", "POST");
  archivedData.append("client_id", userID);
  archivedData.append("job_id", jobID);
  const handleArchived = (applicant: ApplicantTypes) => {
    if (applicant.archived === "false") {
      if (selectedSection === "all" || selectedSection === "shortlisted") {
        setRefreshData(true);

        setApplicantsData(
          applicantsData.filter(
            (item) => item.client_id !== applicant.client_id
          )
        );
      }
      setTimeout(() => {
        setCounterArchived(counterArchived + 1);
        setCounterAllApplicants(counterAllApplicants - 1);
        if (applicant.saved === "true") {
          setCounterShortlisted(counterShortlisted - 1);
        }
      }, 100);
      archivedData.append("worker_id", applicant.client_id);
      // setShowBannerArchived(true);

      fetch(`${BASE_URL}employer.archived.applicants`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: archivedData,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err))
        .finally(() => {
          setTimeout(() => {
            setShowBannerArchived(false);
          }, 3000);
        });
    } else {
      setTimeout(() => {
        setCounterArchived(counterArchived - 1);
        setCounterAllApplicants(counterAllApplicants + 1);
        if (applicant.saved === "true") {
          setCounterShortlisted(counterShortlisted + 1);
        }
      }, 100);
      fetch(
        `${BASE_URL}employer.archived.applicants?client_id=${userID}&job_id=${jobID}&worker_id=${applicant.client_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${loggedInUserUid}`,
          },
        }
      )
        .then((res) => res.json())
        .catch((err) => console.log(err));
    }
  };

  const handleSeenApplicant = (applicant: ApplicantTypes) => {
    if (applicant.seen_by_employer === "0") {
      handleCheckApplicantAsSeen(applicant.client_id);
      setApplicantsData((current: any) =>
        current?.map((obj: any) => {
          if (obj.client_id === applicant.client_id) {
            return { ...obj, seen_by_employer: "1" };
          }
          return obj;
        })
      );
    }
  };

  const handleShortlist = (applicant: ApplicantTypes) => {
    if (applicant.saved === "true") {
      handleRemoveShortlisted(applicant.client_id);
      setApplicantsData((current: any) =>
        current?.map((obj: any) => {
          if (obj.client_id === applicant.client_id) {
            return { ...obj, saved: "false" };
          }
          return obj;
        })
      );
      if (selectedSection === "shortlisted") {
        setApplicantsData(
          applicantsData.filter(
            (item) => item.client_id !== applicant.client_id
          )
        );
      }
      setTimeout(() => {
        setCounterShortlisted(counterShortlisted - 1);
      }, 100);
    } else {
      handleAddShortlisted(applicant.client_id);
      setApplicantsData((current: any) =>
        current?.map((obj: any) => {
          if (obj.client_id === applicant.client_id) {
            return { ...obj, saved: "true" };
          }
          return obj;
        })
      );
      setTimeout(() => {
        setCounterShortlisted(counterShortlisted + 1);
      }, 100);
    }
  };
  const checkIfThereIsConversation = (applicant: ApplicantTypes) => {
    fetch(
      `${BASE_URL}chat?client_id=${userID}&client_id_chat=${applicant.client_id}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setMessagesJobId(jobID);
        if (data.result.chat.length === 0) {
          setShowModal((prev) => ({ ...prev, chat: true }));
        } else {
          navigate("/messages-em");
        }
      })
      .catch((err) => console.log(err));
  };
  const endData = new FormData();
  endData.append("_method", "PUT");
  endData.append("client_id", userID);
  const breakWorkerConnection = (
    connection: string,
    reason: string,
    date: string
  ) => {
    endData.append("connection_id", connection);
    endData.append("end_work_reason_id", reason);
    endData.append("date_early_end", date);
    fetch(`${BASE_URL}worker.employer.connection`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: endData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (
      window.location.pathname === "/applicants/offers" ||
      applicationWindow === "offers"
    ) {
      setSelectedSection("offers");
    } else if (
      window.location.pathname === "/applicants/hired" ||
      applicationWindow === "hired"
    ) {
      setSelectedSection("hired");
    }
  }, [window, applicationWindow]);

  const inviteData = new FormData();
  inviteData.append("_method", "POST");
  inviteData.append("client_id", userID);
  inviteData.append("job_id", jobID);
  const handleInviteWorker = (worker_id: string) => {
    inviteData.append("client_id_worker", worker_id);
    fetch(`${BASE_URL}employer.invite.workers`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: inviteData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          updateCredits();
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  const cancelInviteWorker = (worker_id: string) => {
    fetch(
      `${BASE_URL}employer.invite.workers?client_id=${userID}&job_id=${jobID}&client_id_worker=${worker_id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: inviteData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  let daysMap: any = [];
  const orderTimes = (hours: HourDaysTypes[]) => {
    for (const day of hours) {
      switch (day.dayname) {
        case "MON":
          day.id = "1";
          break;
        case "TUE":
          day.id = "2";
          break;
        case "WED":
          day.id = "3";
          break;
        case "THU":
          day.id = "4";
          break;
        case "FRI":
          day.id = "5";
          break;
        case "SAT":
          day.id = "6";
          break;
        case "SUN":
          day.id = "7";
          break;
      }
      daysMap.push(day);
    }
  };

  useEffect(() => {
    console.log("jobData", jobData);
  }, [jobData]);

  return (
    <div>
      {!loading && (
        <div className="flex flex-col">
          <div
            className={`fixed top-8 left-[50%] -translate-x-[50%] transition-opacity duration-700 ${
              clickedWorker && showBannerArchived
                ? "opacity-100  z-40"
                : "opacity-0 -z-10"
            }`}
          >
            <div
              className={`${
                clickedWorker?.archived === "true" && "bg-[#9b211b]"
              } ${
                clickedWorker?.saved === "true" && "bg-green-500"
              }  text-white font-semibold text-xs rounded-lg py-3.5 tracking-wide px-6 mt-6  flex  items-start shadow-lg`}
            >
              {/* <div className="bg-white">
                <CheckIcon
                  className={cn(
                    `h-6 w-6 `,
                    clickedWorker.saved === "true" && "text-green-500"
                  )}
                />
              </div> */}
              <div className="flex justify-between gap-[78px] items-center">
                <div className="flex gap-4 items-center">
                  <div className="bg-white rounded-full p-1">
                    {clickedWorker?.saved === "true" && (
                      <CheckIcon
                        className={cn(
                          `h-5 w-5 `,
                          clickedWorker?.saved === "true" && "text-green-500"
                        )}
                      />
                    )}
                    {clickedWorker.archived === "true" && (
                      <ArchiveBoxIcon
                        className={cn(
                          `h-5 w-5 `,
                          clickedWorker?.archived === "true" && "text-[#9b211b]"
                        )}
                      />
                    )}
                  </div>
                  <p>
                    {clickedWorker?.first_name}{" "}
                    {t("employer.review_candidates.clicked_worker")}{" "}
                    {clickedWorker?.archived === "true" &&
                      t("employer.invite_workers.archived").toLowerCase()}
                    {clickedWorker?.saved === "true" &&
                      t("employer.invite_workers.shortlisted").toLowerCase()}
                    .{" "}
                  </p>
                </div>
                <Cross
                  className="h-3 fill-white w-fit cursor-pointer"
                  onClick={() => setShowBannerArchived(false)}
                />
              </div>
            </div>
          </div>
          {applicantsData?.map((applicant, i) => {
            let totalScore = 0;
            totalScore = Math.ceil(4.9);

            let endingEarlyEnd = "";
            if (applicant?.date_early_end) {
              endingEarlyEnd = HandleDayEnding(
                applicant?.date_early_end?.slice(9)
              );
            }
            let starsScore;
            if (applicant?.score != "0") starsScore = applicant?.score;
            daysMap = [];
            if (applicant?.hours_day) {
              orderTimes(applicant?.hours_day);
            }
            if (loading)
              return <WorkerCardSkeleton key={applicant.client_id_worker} />;
            return (
              <ApplicantCard
                key={applicant?.client_id_worker}
                applicant={applicant}
                getWorkerInfo={getWorkerInfo}
                handleSeenApplicant={handleSeenApplicant}
                setClickedWorker={setClickedWorker}
                setShowModal={setShowModal}
                endingEarlyEnd={endingEarlyEnd}
                selectedSection={selectedSection}
                setShowArchivePopover={setShowArchivePopover}
                setShowBannerArchived={setShowBannerArchived}
                handleShortlist={handleShortlist}
                checkIfThereIsConversation={checkIfThereIsConversation}
                setDeclinedApplicant={setDeclinedApplicant}
                daysMap={daysMap}
                handleArchived={handleArchived}
                setRefreshData={setRefreshData}
                showArchivePopover={showArchivePopover}
                setActiveStar={setActiveStar}
                i={i}
              />
            );
          })}
        </div>
      )}
      <div>
        {showModal.invite && (
          <ModalInviteJob
            showModal={showModal}
            setShowModal={setShowModal}
            worker={clickedWorker}
            handleInviteWorker={handleInviteWorker}
            cancelInviteWorker={cancelInviteWorker}
          />
        )}
      </div>

      {showModal.chat && (
        <ModalStartChat
          showModal={showModal}
          setShowModal={setShowModal}
          clickedWorker={clickedWorker}
          job={jobData.data?.[0]}
        />
      )}
      {showModal.rating && (
        <ModalSendRating
          setShowModal={setShowModal}
          showModal={showModal}
          hire={clickedWorker}
          setRefreshData={setRefreshData}
        />
      )}

      {showModal.decline && (
        <ModalDeclineApplicant
          showModal={showModal}
          setShowModal={setShowModal}
          setDeclineExplanation={setDeclineExplanation}
          setDeclineReason={setDeclineReason}
          declineReason={declineReason}
          handleDeclineApplicant={handleDeclineApplicant}
        />
      )}
      {showModal.worker && (
        <ModalWorkerCard
          showModal={showModal}
          setShowModal={setShowModal}
          worker={clickedWorker}
          setWorker={setClickedWorker}
          workerInfo={workerInfo}
          handleShortlist={handleShortlist}
          handleArchived={handleArchived}
          setDeclinedApplicant={setDeclinedApplicant}
          checkIfThereIsConversation={checkIfThereIsConversation}
        />
      )}
      {showModal.end && (
        <ModalEditEndEarlyDate
          showModal={showModal}
          setShowModal={setShowModal}
          hire={clickedWorker}
          breakWorkerConnection={breakWorkerConnection}
        />
      )}
      {showModal.video && (
        <ModalVideoIntroduction
          videoUrl={clickedWorker.profile_video}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {showModal.report && (
        <ModalInappropriateWorker
          showModal={showModal}
          setShowModal={setShowModal}
          worker={clickedWorker}
          setWorkerInfo={setWorkerInfo}
          setApplicantsData={setApplicantsData}
          applicantsData={applicantsData}
        />
      )}
    </div>
  );
};

export default WorkerCards;
