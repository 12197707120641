import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { JobOfferDetails } from "../ViewJobOffer/JobOfferDetails";
import {
  BASE_URL,
  BASE_URL_IMG,
  CONNECTION_REVIEWS,
  JOBS,
} from "../../Config/api.config";

import { ReactComponent as NewWindow } from "../../assets/img/new-window.svg";

const Loader = lazy(() => import("src/Components/Loader/Loader"));
import { useTranslation } from "react-i18next";
import { JobInfoTypes, JobTypes, ReviewTypes } from "../../types/types";
import HandleDayEnding from "src/Utils/HandleDayEnding";
import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useQuery } from "react-query";
import ReviewCard from "../Reviews/ReviewCard";

interface ModalJobCardProps {
  showModal: { report: boolean; job: boolean; cover: boolean };
  setShowModal?: any;
  job: JobTypes;
  setJob: any;
  saveItOrNot: (job: JobTypes) => void;
  handleSaveJob: (job: JobTypes, e: any) => void;

  setIsApplied?: any;
  isApplied?: any;
  setAlert?: any;
}

export const ModalJobCard = ({
  showModal,
  setShowModal,
  job,
  setJob,
  saveItOrNot,
  handleSaveJob,
  setIsApplied,
  isApplied,
  setAlert,
}: ModalJobCardProps) => {
  const navigate = useNavigate();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [jobInfo, setJobInfo] = useState<JobInfoTypes>({
    data: [],
    similarJobs: [],
    skills: [],
  });
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const showReviewsFirst = "employer";
  const { t, i18n } = useTranslation();
  const [clientId, setClientId] = useState();
  const getSimilarJobs = async () => {
    const data = await fetch(
      `${BASE_URL}${JOBS}?client_id=${userID}&job_id=${job.job_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: similarJobData } = useQuery(
    ["similarJobs", job.job_id],
    getSimilarJobs
  );
  useEffect(() => {
    setJobInfo(similarJobData);
    setClientId(similarJobData?.data[0].client_id);
  }, [similarJobData]);

  // useEffect(() => {
  //   getSimilarJobs();
  // }, []);

  // const getReviews = (id: string) => {
  //   fetch(`${BASE_URL}connections.reviews?client_id=${id}`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${loggedInUserUid}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data.status === "ok") {
  //         setReviews(data.result.reviews);
  //         setTimeout(() => {
  //           setLoading(false);
  //         }, 100);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const getReviews = async (id: string) => {
  //   const data = await fetch(`${BASE_URL}connections.reviews?client_id=${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${loggedInUserUid}`,
  //     },
  //   }).then((res) => res.json());

  //   return data.result.reviews;
  // };

  const { data: reviews, isLoading: isReviewsLoading } = useQuery({
    queryKey: ["connections", clientId],
    queryFn: async () => {
      const data = await fetch(
        `${BASE_URL}${CONNECTION_REVIEWS}?client_id=${clientId}`,
        {
          headers: {
            Authorization: "Bearer " + loggedInUserUid,
          },
        }
      ).then((res) => res.json());

      return data.result.reviews;
    },
  });

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.report) {
        if (showModal.job && !showModal.report) closeModal();
      } else if (showModal.cover) {
        if (showModal.job && !showModal.cover) closeModal();
      } else {
        if (showModal.job && ref.current && !ref.current.contains(e.target)) {
          closeModal();
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.job, showModal.report, showModal.cover]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, job: false }));
      setAlert(false);
      setJob();
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);

  const stylesModal: any = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.35)",
      zIndex: 200,
    },
    content: {
      position: "absolute",
      top: "-20px",
      left: "40px",
      right: "40px",
      bottom: "0px",

      overflow: "auto",
      border: "none",
      background: "#fff",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal isOpen={showModal.job} style={stylesModal} ariaHideApp={false}>
      <div
        className={`fixed z-[160] pb-10 ${
          transition === false ? "scale-x-0 translate-x-full" : "scale-x-100"
        } transition duration-200 w-[90%] lg:w-[1000px] right-0 h-full rounded  overflow-x-hidden jobpost  bg-blancoGris dark:bg-background
         `}
        ref={ref}
      >
        <div className="text-sm font-bold shadow bg-white  dark:bg-background  px-8 py-5 flex justify-between ">
          <ChevronRightIcon
            onClick={() => closeModal()}
            className="h-5 w-5 text-[#2abeeb] cursor-pointer"
          />

          <a
            className="flex items-center gap-3 cursor-pointer text-azulBonico hover:text-azulOscuro hover:underline"
            href={`job-offer/${job.job_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <NewWindow className="h-4" fill={"#2abeeb"} />
            <p className="">{t("worker.modal_jobcard.open_job_window")}</p>
          </a>
        </div>
        {/* {!loading ? ( */}
        <div>
          <JobOfferDetails
            job={job}
            setJob={setJob}
            setShowModal={setShowModal}
            saveItOrNot={saveItOrNot}
            // handleSaveJob={handleSaveJob}
            setIsApplied={setIsApplied}
            isApplied={isApplied}
            jobInfo={jobInfo!}
          />

          {!isReviewsLoading && reviews?.length > 0 && (
            <div className="px-6 pt-3">
              <div className="text-sm shadow bg-white dark:bg-background flex flex-col rounded ">
                <div className="border-b border-blue-100 dark:border-border">
                  <p className="font-bold text-xl tracking-wide my-6 mx-6">
                    {t("worker.modal_jobcard.employment_history")}
                  </p>
                </div>
                <div>
                  {reviews
                    ?.slice(0)
                    ?.reverse()
                    ?.slice(0, 10)
                    ?.map((review: ReviewTypes) => {
                      if (review.date_start < date) {
                        const endingEnd = HandleDayEnding(
                          review.date_end?.slice(9)
                        );
                        const endingStart = HandleDayEnding(
                          review.date_start?.slice(9)
                        );
                        let endingEarlyEnd;
                        if (review.date_early_end) {
                          endingEarlyEnd = HandleDayEnding(
                            review.date_early_end?.slice(9)
                          );
                        }
                        return (
                          <ReviewCard
                            key={review.id}
                            review={review}
                            endingEnd={endingEnd}
                            endingStart={endingStart}
                            showReviewsFirst={showReviewsFirst}
                            date={date}
                            endingEarlyEnd={endingEarlyEnd!}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          )}
          {jobInfo?.similarJobs?.length > 0 && (
            <div className="px-6 pt-3 ">
              <div className="text-sm    bg-white flex flex-col rounded shadow ">
                <div className=" border-b border-blue-100">
                  <p className="font-bold text-lg lg:text-2xl tracking-wide my-6 mx-6">
                    {t("worker.modal_jobcard.similar_jobs")}
                  </p>
                </div>
                <div>
                  {jobInfo?.similarJobs?.map((offer) => {
                    return (
                      <div
                        key={offer.job_id}
                        className="flex gap-2 border-b justify-between border-blue-100 py-3 px-5"
                      >
                        {/* <a
                            className="text-azulBonico font-bold hover:text-azulOscuro hover:underline cursor-pointer"
                            href={`http://localhost:3000/job-offer/${offer.job_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {offer.title}.
                          </a> */}
                        <div className="flex flex-col items-start">
                          <button
                            onClick={() =>
                              navigate(`/job-offer/${offer.job_id}`)
                            }
                            className="text-azulBonico font-semibold hover:text-azulOscuro hover:underline cursor-pointer"
                          >
                            {offer.title}.
                          </button>
                          <div className="hidden lg:block">
                            {offer.type_name === "In-person" && (
                              <>
                                <p>
                                  <span className="font-medium">
                                    {offer.type_name}
                                  </span>{" "}
                                  {t("worker.modal_jobcard.at")}{" "}
                                  {offer.city_name}, {offer.location}.
                                </p>
                              </>
                            )}
                            {offer.type_name === "Hybrid" && (
                              <>
                                <p>
                                  <span className="font-medium">
                                    {offer.type_name}
                                  </span>{" "}
                                  {t("worker.modal_jobcard.at")}{" "}
                                  {offer.city_name}.
                                </p>
                              </>
                            )}
                            {offer.type_name === "Online" && (
                              <>
                                <p>
                                  <span className="font-medium">
                                    {offer.type_name}
                                  </span>
                                  .
                                </p>
                              </>
                            )}
                          </div>
                        </div>

                        <p>
                          <span className="font-medium">{offer.budget}€ </span>
                          /h
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mb-8"></div>
      </div>
    </Modal>
  );
};
