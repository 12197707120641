import React, { useEffect, useState } from "react";
import { Button } from "../ui/Button";

import { CgClose } from "react-icons/cg";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";

const Cookies = () => {
  const [cookiesStatus, setCookiesStatus] = useLocalStorage("cookies", false);
  const [closeCookies, setCloseCookies] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    setCookiesStatus(true);
    setCloseCookies(!closeCookies);
  };

  if (!cookiesStatus && !closeCookies)
    return (
      <div className="fixed left-1/2 -translate-x-[50%] bottom-3 lg:bottom-14 z-[300] w-[90%] lg:w-[40%]   bg-white shadow-xl rounded-2xl p-6">
        {/* <div
          onClick={() => setCloseCookies(!closeCookies)}
          className="absolute right-10 top-10 cursor-pointer"
        >
          <CgClose className="h-7 w-7 hover:text-slate-700" />
        </div> */}
        <div className="flex lg:flex-row flex-col items-center justify-between gap-10">
          <h1 className="font-normal  text-lg lg:text-xl">
            {t("cookies.title")}.{" "}
            <span>
              {i18n.language === "en" ? (
                <>
                  Consult our{" "}
                  <span className="font-medium">Privacy policy</span> to obtain
                  detailed information
                </>
              ) : (
                <>
                  Consulte nuestra{" "}
                  <span className="font-medium">Política de privacidad</span>{" "}
                  para obtener información detallada.
                </>
              )}
            </span>
          </h1>
          <Button
            onClick={() => handleClick()}
            className="px-4 py-2 bg-[#1D4ED8] text-lg rounded-lg text-white"
          >
            {i18n.language === "en" ? <>Understood.</> : <>Entendido.</>}
          </Button>
        </div>

        {/* <p className="text-xs lg:text-sm text-gray-500">
          {t("cookies.description")}{" "}
          <span className="text-blue-500 underline">
            {t("cookies.description.policy")}
          </span>
        </p> */}
        {/* <div className="  w-full pt-2 lg:pt-10">
          <div className="flex items-center ml-auto justify-end">
            <Button className="bg-white text-black px-2 py-1 lg:px-6 lg:py-3 text-xs lg:text-sm">
              {t("cookies.description.policy.leftbtn")}
            </Button>
            <Button
              onClick={() => handleClick()}
              // onClick={() => setCookiesStatus("accepted")}
              className="bg-black text-white px-2 py-1 lg:px-6 lg:py-3 text-xs lg:text-sm "
            >
              {t("cookies.description.policy.rightbtn")}
            </Button>
          </div>
        </div> */}
      </div>
    );
  else {
    return null;
  }
};

export default Cookies;
