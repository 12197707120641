import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";

import { ReactComponent as Cross } from "../../../assets/img/cross.svg";

import "../../../Components/ClientDashboard/ScrollBar.css";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";

function AddCategories() {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [selectedCategoryWorker, setSelectedCategoryWorker] = useLocalStorage(
    "selectedCategoryWorker",
    []
  );
  const [allCategories, setAllCategories] = useState([]);

  //React select library settings
  const getAllCategories = () => {
    fetch(`${BASE_URL}job.categories?category_id`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllCategories(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const availableCategories = allCategories?.map(function (row: {
    name: string;
    category_id: string;
  }) {
    return {
      value: row.name,
      label: row.name,
      category_id: row.category_id,
      name: row.name,
    };
  });

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  //Post settings
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append(
    "category_list",
    selectedCategoryWorker?.map(
      (category: { category_id: string }) => category.category_id
    )
  );
  const workerCategories = () => {
    fetch(`${BASE_URL}worker.category`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  //Get worker categories
  const getWorkerCategories = () => {
    fetch(`${BASE_URL}worker.category?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setSelectedCategoryWorker(data.result))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getWorkerCategories();
  }, []);

  //To show and remove those buttons
  const addCategory = (category: { category_id: string }) => {
    let counter = 0;
    for (let i = 0; i <= selectedCategoryWorker?.length; i++) {
      if (category.category_id === selectedCategoryWorker[i]?.category_id) {
        counter++;
      }
    }
    if (counter === 0) {
      return setSelectedCategoryWorker((current: any) => [
        ...current,
        category,
      ]);
    }
  };

  const removeHandler = (cat: { name: string }) => {
    // let x = e.target.getAttribute("removecategory");
    setSelectedCategoryWorker(
      selectedCategoryWorker.filter(
        (items: { name: string }) => items.name !== cat.name
      )
    );
  };

  //Navigation
  const navigate = useNavigate();
  const onNextClick = () => {
    workerCategories();
    navigate("/create-profile/skills");
  };

  return (
    <div className="h-screen w-full !bg-white ">
      <RegistrationHeader />
      <div className=" pt-12 mx-auto  place-items-center px-10 lg:px-0 flex flex-col min-h-[75vh]">
        <div className="text-xl lg:text-4xl font-bold">
          {" "}
          {t("createprofile.categories.title")}
        </div>
        <p className="pt-8 text-sm text-[#6e7081]">
          {t("createprofile.categories.subtitle")}
        </p>{" "}
        <div className="mr-4 mt-16 w-[300px]">
          {selectedCategoryWorker?.length > 0 && (
            <p className="text-sm font-bold mb-3">
              {/* Selected services */}
              {t("createprofile.categories.subTitle2")}
            </p>
          )}
          <div className="flex flex-wrap max-h-24 text-sm overflow-auto">
            {selectedCategoryWorker?.map(
              (category: { category_id: string; name: string }) => {
                return (
                  <div
                    key={category.category_id}
                    className="flex items-center gap-2 cursor-pointer px-2 py-0.5 mx-0.5 text-white bg-azulBonico my-0.5 border rounded-xl hover:bg-[#292e38]"
                    // removecategory={category.name}
                    onClick={() => removeHandler(category)}
                  >
                    <div className="text-xs">{category.name}</div>
                    <Cross
                      // removecategory={category.name}
                      className="h-[13px] cursor-pointer w-fit fill-white"
                    />
                  </div>
                );
              }
            )}
          </div>
          <Select
            options={availableCategories}
            styles={colourStyles}
            placeholder={t(
              "createprofile.submit.edityourCategories.placeholder"
            )}
            onChange={(e: any) => addCategory(e)}
            className="mt-12 text-sm"
          />
        </div>
      </div>
      <ProgressRegistration
        disabled={selectedCategoryWorker.length === 0 ? true : false}
        backButton={"create-profile/education"}
        nextButtonText={t("createprofile.categories.rightBtn")}
        onNextClick={onNextClick}
        progress="48"
      />
    </div>
  );
}

export default AddCategories;
