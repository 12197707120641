import React from "react";

import { Skeleton } from "../ui/skeleton";

export const FindWorkerCardSkeleton = () => {
  return (
    <div className="py-6 px-4 w-full bg-white dark:bg-background rounded shadow-lg mb-4">
      <div className="flex items-start pr-10 relative gap-10 ">
        <div>
          <Skeleton className="h-28 w-28 rounded-full" />
        </div>
        <div className="flex flex-col items-start flex-grow">
          <Skeleton className="h-5 w-[30%] ml-4 " />
          <div className="flex  flex-grow items-center w-full">
            <Skeleton className="h-3 w-[10%] ml-4 mr-10 mt-4" />
          </div>
          <div className="flex items-center w-full">
            <Skeleton className="h-4 w-1/6 ml-4 my-4" />
            <Skeleton className="h-4 w-1/6 ml-4 my-4" />
          </div>
          <div className="flex items-center">
            <Skeleton className="h-8 w-10  ml-4" />
            <Skeleton className="h-8 w-10  ml-4" />
            <Skeleton className="h-8 w-10  ml-4" />
          </div>
        </div>
        <div className="absolute top-2 right-2 flex items-center space-x-4">
          <Skeleton className="h-[26px] w-[36px] rounded" />
        </div>
      </div>
    </div>
  );
};
