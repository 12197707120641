import React, { useEffect, useState } from "react";
import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../Config/api.config";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { useAllCountries } from "src/Hooks/useCountries";

function AddTitle() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [countries, setCountries] = useState([]);
  // const [countries, setCountries] = useLocalStorage("AllCountries", []);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const { t } = useTranslation();
  const { countries: countriesData, refetchCountries } = useAllCountries();

  const handleTitle = (e: any) => {
    setUserInfo((info: any) => ({ ...info, title: e }));
  };
  const navigate = useNavigate();
  const onNextClick = () => {
    saveTitle();
    navigate("/create-profile/employment");
  };

  useEffect(() => {
    setCountries(countriesData);
  }, [countries]);

  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append("title", userInfo.title);

  const saveTitle = () => {
    fetch(`${BASE_URL}worker.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (countries?.length < 1) {
      refetchCountries();
    }
  }, [countries]);

  return (
    <div className=" w-full !bg-white  h-full">
      <RegistrationHeader />
      <div className="lg:max-w-xl pt-12 mx-auto  px-10 lg:px-0 min-h-[75vh]">
        <div className="text-xl lg:text-4xl font-bold">
          {t("createprofile.title.title")}
        </div>
        <p className="pt-8 text-sm text-[#6e7081]">
          {t("createprofile.title.subtitle")}
        </p>{" "}
        <form>
          <input
            value={userInfo?.title}
            type="text"
            className="w-full mt-6 border h-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
            placeholder={`${t(
              "createprofile.employment.placeholder.subtitle1.card.placeholder"
            )}`}
            onChange={(e) => handleTitle(e.target.value)}
          ></input>
        </form>
      </div>
      <ProgressRegistration
        onNextClick={onNextClick}
        disabled={!userInfo.title ? true : false}
        nextButtonText={`${t("createprofile.title.rightBtn")}`}
        progress="12"
      />
    </div>
  );
}

export default AddTitle;
