import React, { Dispatch, SetStateAction } from "react";

import { ReactComponent as Mailbox } from "../../assets/img/mailbox.svg";
import WorkerCards from "./WorkerCards";
import { ApplicantTypes } from "src/types/types";
import { useTranslation } from "react-i18next";

interface MessagedApplicantsProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData: Dispatch<SetStateAction<ApplicantTypes[]>>;
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  maxPages: number;
  currentPage: number;
  setCurrentPage: (number: number) => void;
  refetch: () => void;
}

const MessagedApplicants = ({
  applicantsData,
  setApplicantsData,
  setRefreshData,
  maxPages,
  currentPage,
  setCurrentPage,
  refetch,
}: MessagedApplicantsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      {applicantsData?.length > 0 ? (
        <>
          <WorkerCards
            refetch={refetch}
            applicantsData={applicantsData}
            setApplicantsData={setApplicantsData}
            setRefreshData={setRefreshData}
          />
          <div
            className={`flex justify-center border-t border-blue-100 py-4 ${
              maxPages === currentPage && "hidden"
            }`}
          >
            <button
              className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-150"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              {t("employer.messaged_applicants.load_more")}
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-col w-full items-center justify-center my-12 dark:text-whiteish">
          <Mailbox className="h-32 mb-6" />
          <p className="text-lg font-bold tracking-wide">
            {t("employer.messaged_applicants.no_messages")}
          </p>
          <p className="text-sm mt-4  ">
            {t("employer.messaged_applicants.desc")}
          </p>
        </div>
      )}
    </div>
  );
};

export default MessagedApplicants;
