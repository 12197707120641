import React, { useState } from "react";
import { Button } from "../ui/Button";
import { ReactComponent as PaypalLogo } from "src/assets/img/paypal-logo.svg";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/Form";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronDownIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import { toast } from "react-hot-toast";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { BsFillCreditCardFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const formSchema = z.object({
  cardNumber: z.string().min(16).max(16),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  expirationMonth: z.string().min(1).max(2),
  expirationYear: z.string().min(1).max(2),
  securityCode: z.string().min(1).max(3),
  countryCode: z.string().min(1),
  addressLine1: z.string().min(1),
  addressLine2: z.string().min(1).optional(),
  city: z.string().min(1),
  postalCode: z.string().min(1).optional(),
});

const BillingPayments = () => {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleShowPayment = () =>
    setShowAddPaymentMethod(!showAddPaymentMethod);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cardNumber: "",
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
      expirationMonth: "",
      expirationYear: "",
      securityCode: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postalCode: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setLoading(true);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" px-4 py-2">
      <div className="bg-white rounded-lg shadow pb-6 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-8">
            <h1 className="text-2xl font-semibold mb-4">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments"
              )}
            </h1>
          </div>
          {!showAddPaymentMethod && (
            <div className="border p-4 m-8">
              <div className="flex flex-col lg:flex-row items-center justify-between">
                <h1 className="text-lg font-semibold">
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.left.title"
                  )}
                </h1>
                <Button
                  onClick={handleShowPayment}
                  className="border-2 border-azulBonico text-sm lg:text-[15px px-1 mt-2 lg:mt-0 lg:px-6 py-2 text-azulOscuro hover:bg-blue-50"
                >
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.rightBtn"
                  )}
                </Button>
              </div>

              <div className="flex flex-col mt-14">
                <p className="text-sm font-medium">
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.subTitle1"
                  )}
                </p>
                <p className="text-sm mt-4">
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.subTitle2"
                  )}
                </p>
              </div>
            </div>
          )}

          {showAddPaymentMethod && (
            <div className="border p-4 m-8">
              <div className="flex items-center justify-between">
                <h1 className="text-sm lg:text-lg font-semibold">
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.rightBtn"
                  )}
                </h1>
                <Button
                  onClick={handleShowPayment}
                  className="border-2 border-azulBonico px-6 py-2 text-azulOscuro hover:bg-blue-50"
                >
                  Cancel
                </Button>
              </div>
              <div className="flex flex-col items-start mt-14">
                <div className="flex items-center gap-4 border-b w-full py-4 border-border">
                  <input
                    onChange={(checked) => {
                      setShowCardDetails(!showCardDetails);
                    }}
                    checked={showCardDetails}
                    type="checkbox"
                  />
                  <p className="text-sm">
                    Payment Card{" "}
                    <span className="text-xs text-gray-500 ml-3">
                      Visa, Mastercard, American Express
                    </span>
                  </p>
                </div>

                {showCardDetails && (
                  <Form {...form}>
                    <form
                      className="w-full flex flex-col gap-6"
                      onSubmit={form.handleSubmit(onSubmit)}
                    >
                      <FormField
                        control={form.control}
                        name="cardNumber"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Card Number</FormLabel>
                            <FormControl>
                              <div className="w-full relative outline-none border rounded h-10 text-sm">
                                <input
                                  disabled={loading}
                                  type="number"
                                  {...field}
                                  className="w-full outline-none border rounded h-10 px-14 text-sm"
                                  placeholder="1234 5678 9123 456"
                                />

                                <div className="absolute left-[10px] top-[6px] opacity-20">
                                  <BsFillCreditCardFill className="h-7 w-7" />
                                </div>
                                <div className="absolute right-[10px] top-[6px] opacity-20 flex items-center">
                                  <ShieldCheckIcon className="h-7 w-7" />
                                  <p className="text-sm font-medium">
                                    Securely stored
                                  </p>
                                </div>
                              </div>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <div className="flex items-center justify-between">
                        <FormField
                          control={form.control}
                          name="firstName"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>First name</FormLabel>
                              <FormControl>
                                <input
                                  disabled={loading}
                                  {...field}
                                  className="w-full outline-none border rounded h-10 px-4 text-sm"
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="lastName"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Last name</FormLabel>
                              <FormControl>
                                <input
                                  disabled={loading}
                                  {...field}
                                  className="w-full outline-none border rounded h-10 px-4 text-sm "
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <FormField
                          control={form.control}
                          name="expirationMonth"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Expiration month</FormLabel>
                              <FormControl>
                                <input
                                  disabled={loading}
                                  {...field}
                                  className="w-full outline-none border rounded h-10 px-4 text-sm "
                                  placeholder="MM"
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="expirationYear"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Expiration Year</FormLabel>
                              <FormControl>
                                <input
                                  disabled={loading}
                                  {...field}
                                  className="w-full outline-none border rounded h-10 px-4 text-sm"
                                  placeholder="YY"
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                      <FormField
                        control={form.control}
                        name="securityCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Security Code</FormLabel>
                            <FormControl>
                              <input
                                disabled={loading}
                                {...field}
                                className="w-full outline-none border rounded h-10 px-4 text-sm"
                                placeholder="3 digits"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <div className="mb-4">
                        <h1 className="text-lg font-semibold">
                          Billing Address
                        </h1>
                      </div>
                      <FormField
                        control={form.control}
                        name="countryCode"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Security Code</FormLabel>
                            <FormControl>
                              <div className="flex justify-between items-center border border-border w-1/2 px-4 h-10 rounded outline-none">
                                <p className="text-sm">Spain</p>
                                <ChevronDownIcon className="h-5 w-5" />
                              </div>
                              {/* <input
                                {...field}
                                className="w-full outline-none border rounded h-10 px-4 text-sm"
                                placeholder="3 digits"
                              /> */}
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="addressLine1"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Address line 1</FormLabel>
                            <FormControl>
                              <input
                                disabled={loading}
                                {...field}
                                className="w-full outline-none border rounded h-10 px-4 text-sm"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="addressLine2"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Address line 2{" "}
                              <span className="text-gray-500">(optional)</span>
                            </FormLabel>
                            <FormControl>
                              <input
                                disabled={loading}
                                {...field}
                                className="w-full outline-none border rounded h-10 px-4 text-sm"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="city"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>City</FormLabel>
                            <FormControl>
                              <input
                                disabled={loading}
                                {...field}
                                className="w-full outline-none border rounded h-10 px-4 text-sm"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="postalCode"
                        render={({ field }) => (
                          <FormItem className="flex flex-col">
                            <FormLabel>
                              Postal code{" "}
                              <span className="text-gray-500">(optional)</span>
                            </FormLabel>
                            <FormControl>
                              <input
                                disabled={loading}
                                {...field}
                                className=" outline-none border rounded h-10 px-4 text-sm w-1/2"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <Button
                        disabled={loading}
                        type="submit"
                        className="bg-azulBonico text-white justify-end  ml-auto px-6 py-2"
                      >
                        Save
                      </Button>
                    </form>
                  </Form>
                )}
                {/* {!showCardDetails && ( */}
                <div className="flex items-center gap-4  w-full py-4 border-t mt-4 ">
                  <input type="checkbox" />

                  <PaypalLogo className="h-10  w-28" />
                  <p className="text-sm">
                    {/* Payment Card{" "} */}
                    {/* <span className="text-xs text-gray-500 ml-3">
                      Visa, Mastercard, American Express
                    </span> */}
                  </p>
                </div>
                {/* // )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingPayments;
