import React from "react";

import { useTranslation } from "react-i18next";
import { ReactComponent as Invite } from "../../assets/img/messages.svg";
import { ReactComponent as Search } from "../../assets/img/search.svg";

import WorkerCards from "./WorkerCards";
import { ApplicantTypes } from "../../types/types";
import { WorkerCardSkeleton } from "../ui/skeleton/WorkerCardSkeleton";

interface SearchWorkersProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData: any;
  setRefreshData: any;
  isLoading: boolean;
  refetch: () => void;
}

const SearchWorkers = ({
  applicantsData,
  setApplicantsData,
  setRefreshData,
  isLoading,
  refetch,
}: SearchWorkersProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex ">
        <div className="relative px-8 pt-8 pb-4 w-2/3">
          <input
            className="px-2 hover:border-azulBonico border text-sm border-blue-100 w-full rounded h-10 outline-none focus:border-azulBonico transition duration-300 dark:bg-input dark:border-border"
            placeholder={`${t("employer.invite_workers.searchPlaceholder")}...`}
          />
          <Search className="h-5 absolute right-[3rem] top-[42px] cursor-pointer " />
        </div>
      </div>
      {isLoading && (
        <>
          <WorkerCardSkeleton />
          <WorkerCardSkeleton />
        </>
      )}

      {/* JOBSEEKERS CARDS */}
      {!isLoading && applicantsData?.length > 0 && (
        <div>
          <WorkerCards
            refetch={refetch}
            applicantsData={applicantsData}
            setApplicantsData={setApplicantsData}
            setRefreshData={setRefreshData}
          />
        </div>
      )}
      {!isLoading && applicantsData?.length === 0 && (
        <div className="flex flex-col w-full items-center justify-center my-12">
          <Invite className="h-32 mb-6" />
          <p className="text-lg font-bold tracking-wide">
            {t("employer.invite_workers.no_workers_found")}
          </p>
          <p className="text-sm mt-4  ">
            <span
              className="text-azulBonico  font-medium"
              // onClick={() => setSelectedSection("search")}
            >
              {t("employer.invite_workers.invite")}
            </span>{" "}
            {t("employer.invite_workers.suitable_candidates")}
          </p>
        </div>
      )}
    </div>
  );
};

export default SearchWorkers;
