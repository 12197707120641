import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";

const GoBackButton = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center gap-2 hover:underline group">
      <div className="rounded-full p-1 bg-gray-200 group-hover:-translate-x-3 transition">
        <ChevronLeftIcon className="h-3 text-azulOscuro" />
      </div>
      <p className="text-sm font-medium text-azulOscuro">
        {t("employer.notifications.linkback")}
      </p>
    </div>
  );
};

export default GoBackButton;
