import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";

import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";

import { ReactComponent as Plus } from "src/assets/img/plus.svg";
import ScheduleTimes from "./ScheduleTimes";
import { hoursInitial } from "../../Utils/content/Hours";
import { toast } from "react-hot-toast";
import LoaderSmall from "../Worker/Registration/LoaderSmall";
import { useTranslation } from "react-i18next";
import { userProfileStore } from "../../Routing/useProfileStore";
import { HourDaysTypes } from "../../types/types";

interface ScheduleDaysProps {
  handleToggle: (i: number, day: string, dayTimes: HourDaysTypes[]) => void;
  day: string;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  dayTimes: HourDaysTypes[];
  getTimesPerDay: (day: string, i: number) => void;
  i: number;
  setSelectedDays: React.Dispatch<React.SetStateAction<number[]>>;
  selectedDays: number[];
  refetchTimes: () => void;
  shortenedDay: string;
  draftId?: string;
}

const ScheduleDays = ({
  handleToggle,
  day,
  setUpdate,
  getTimesPerDay,
  dayTimes,
  i,
  setSelectedDays,
  selectedDays,
  shortenedDay,
  refetchTimes,
  draftId,
}: ScheduleDaysProps) => {
  const { t } = useTranslation();

  const [hours, setHours] = useState(hoursInitial);
  const [startingHour, setStartingHour] = useState<{
    value: string;
    label: string;
    id: string;
  }>({
    value: "",
    label: t("welcome-client.rightcard.dayweek.StartinghourBtn"),
    id: "",
  });
  const [endingHour, setEndingHour] = useState<{
    value: string;
    label: string;
    id: string;
  }>({
    value: "",
    label: t("welcome-client.rightcard.dayweek.EndhourBtn"),
    id: "",
  });
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [endingHours, setEndingHours] = useState<any>();
  const [startingHours, setStartingHours] = useState<any>();
  const profileType: any = userProfileStore((s) => s.profileTypeToken);

  const [goHandle, setGoHandle] = useState(false);
  const [dayName, setDayName] = useState("");
  const [error, setError] = useState("");
  const [showAddTime, setShowAddTime] = useState(false);
  const [nextDay, setNextDay] = useState("");
  const endingRef = useRef(null);
  const startingRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [chooseDifferentHour, setChooseDifferentHour] = useState(false);

  const formData = new FormData();
  formData.append("_method", "POST");

  const sendTimeNextDay = () => {
    if (profileType === "worker") {
      formData.append("client_id", userID);
    } else {
      formData.append("job_id", draftId!);
    }

    formData.append("hour_from", "00:00");
    formData.append("dayname", nextDay);
    formData.append("hour_to", endingHour?.value);

    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setNextDay("");
        }
      })
      .catch((err) => console.error(err));
  };

  const data = new FormData();
  data.append("_method", "POST");
  const sendTime = () => {
    setLoading(true);
    if (profileType === "worker") {
      data.append("client_id", userID);
    } else {
      data.append("job_id", draftId!);
    }
    data.append("hour_from", startingHour?.value);
    data.append("dayname", dayName);
    data.append("hour_to", nextDay === "" ? endingHour?.value : "23:59");
    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          if (
            endingHour?.id < startingHour?.id &&
            endingHour?.value !== "00:00"
          ) {
            sendTimeNextDay();
          }

          toast.success(`${t("toast.hours_added")}`);
          // setSelectedHours([
          //   ...selectedHours,
          //   {
          //     id_min: startingHour.id,
          //     id_max: endingHour.id,
          //     starting_hour: startingHour.label,
          //     ending_hour: endingHour.label,
          //     id: unique_id,
          //   },
          // ]);
          eraseEndingAndStartingHour();
          // setUpdateTimes(true);
          setUpdate(true);
          setError("");
          getTimesPerDay(shortenedDay, i);
          setShowAddTime(false);
          setSelectedDays([...selectedDays, i]);
          // return toast({
          //   title: "Hours Added",
          //   description: `Hours from ${startingHour.value} to ${startingHour.value} has been added.`,
          // });
        } else {
          // return toast({
          //   title: `${data.result.error_msg}`,
          //   variant: "destructive",
          // });
          toast.error(data.result.error_msg);
          // setError(data.result.error_msg);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setChooseDifferentHour(false);
    }, 5100);
  }, [chooseDifferentHour]);

  const colourStyles = {
    control: () => ({
      border: !chooseDifferentHour ? "1px solid lightgrey" : "1px solid red ",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      width: "150px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
      fontSize: "14px",
    }),
  };

  const handleDelete = (id: string) => {
    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile?${
        profileType === "worker" ? "worker" : "job"
      }_time_profile_id=${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setUpdate(true);
          // return toast({
          //   title: "Hours Removed",
          //   variant: "destructive",
          // });
          toast.success(`${t("toast.hours_removed")}`);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (dayTimes?.length > 0) {
      const append = dayTimes.every((element: any) => {
        if (element.show === false) {
          return true;
        }
      });
      if (append) {
        setSelectedDays(selectedDays.filter((item) => item !== i));
      }
    }
  }, [dayTimes]);

  useEffect(() => {
    if (day) {
      switch (day) {
        case "Monday":
          return setDayName("Mon");
        case "Tuesday":
          return setDayName("Tue");
        case "Wednesday":
          return setDayName("Wed");
        case "Thursday":
          return setDayName("Thu");
        case "Friday":
          return setDayName("Fri");
        case "Saturday":
          return setDayName("Sat");
        case "Sunday":
          return setDayName("Sun");
      }
    }
  }, [day]);

  const handleStartingHourLimit = () => {
    const append = hours?.map(function (row: any) {
      if (row.id < endingHour?.id) {
        return {
          label: row.label,
          value: row.value,
          id: row.id,
        };
      } else {
        return {
          label: "",
          value: "",
          id: "",
        };
      }
    });
    setStartingHours(append.filter((item) => item.id !== ""));
  };
  const handleEndingHourLimit = () => {
    const append = hours?.map(function (row: any) {
      if (row.id > startingHour?.id) {
        return {
          label: row.label,
          value: row.value,
          id: row.id,
        };
      } else {
        return {
          label: "",
          value: "",
          id: "",
        };
      }
    });
    setEndingHours(append.filter((item) => item.id !== ""));
  };

  useEffect(() => {
    handleStartingHourLimit();
  }, [endingHour]);
  useEffect(() => {
    handleEndingHourLimit();
  }, [startingHour]);

  const eraseEndingAndStartingHour = () => {
    setStartingHour({
      value: "Starting Hour",
      label: t("welcome-client.rightcard.dayweek.StartinghourBtn"),
      id: "",
    });
    setEndingHour({
      value: "Ending Hour",
      label: t("welcome-client.rightcard.dayweek.EndhourBtn"),
      id: "",
    });
  };

  useEffect(() => {
    if (goHandle) {
      if (startingHour.value !== endingHour.value) {
        sendTime();
      } else {
        // toast({
        //   title: "Choose a different hour",
        //   description: "",
        // });
        // toast.error("Choose a different hour");
        setChooseDifferentHour(true);
        // return toast({
        //   title: "Choose a different hour",
        //   description: "You choose the same hour",
        //   variant: "destructive",
        // });
      }
      setGoHandle(false);
    }
  }, [goHandle]);

  useEffect(() => {
    if (endingHour?.id < startingHour?.id) {
      if (dayName === "Mon") setNextDay("Tue");
      if (dayName === "Tue") setNextDay("Wed");
      if (dayName === "Wed") setNextDay("Thu");
      if (dayName === "Thu") setNextDay("Fri");
      if (dayName === "Fri") setNextDay("Sat");
      if (dayName === "Sat") setNextDay("Sun");
    } else {
      setNextDay("");
    }
  }, [endingHour, startingHour]);

  return (
    <div className="">
      {showAddTime ? (
        <>
          {loading && (
            <div className="flex items-center mx-auto my-3 w-full justify-center h-10">
              <LoaderSmall />
            </div>
          )}

          {!loading && (
            <div className="flex flex-col items-end">
              <div
                className="flex items-center mx-auto gap-8 my-3 ml-1"
                onClick={(e) => e.stopPropagation()}
              >
                <Select
                  ref={startingRef}
                  options={hours}
                  // options={startingHours?.length > 0 ? startingHours : hours}
                  styles={colourStyles}
                  className="!text-sm cursor-pointer dark:text-black "
                  hideSelectedOptions={true}
                  placeholder={t(
                    "welcome-client.rightcard.dayweek.StartinghourBtn"
                  )}
                  menuPortalTarget={document.querySelector("body")}
                  value={
                    startingHour &&
                    endingHour && {
                      value: startingHour?.value,
                      label: startingHour?.label,
                    }
                  }
                  onChange={(e: any) => {
                    setStartingHour(e);
                  }}
                />
                <p className="text-sm text-slate-400">
                  {t(
                    "welcome-client.rightcard.dayweek.monday.textBtwPlaceholders"
                  )}
                </p>
                <Select
                  ref={endingRef}
                  options={hours}
                  // options={endingHours?.length > 0 ? endingHours : hours}
                  hideSelectedOptions={true}
                  styles={colourStyles}
                  menuPortalTarget={document.querySelector("body")}
                  value={
                    startingHour &&
                    endingHour && {
                      value: endingHour?.value,
                      label: endingHour?.label,
                    }
                  }
                  className="text-sm cursor-pointer dark:text-black"
                  placeholder={t("welcome-client.rightcard.dayweek.EndhourBtn")}
                  onChange={(e: any) => {
                    setEndingHour(e);
                  }}
                />
              </div>
              <div className="flex w-[30px] mx-auto  mr-10">
                <button
                  className={` ${
                    startingHour?.id && endingHour?.id
                      ? "border-azulBonico fill-azulBonico cursor-pointer text-azulBonico hover:bg-azulBonico transform transition-all duration-150 ease-out hover:text-white"
                      : "border-slate-300 fill-slate-300 text-slate-300 cursor-not-allowed"
                  } border-2 rounded px-2.5 py-[8px] text-xs font-bold `}
                  disabled={!startingHour || !endingHour ? true : false}
                  onClick={() => {
                    if (
                      dayTimes[0]?.hour_from === "00:00:00" &&
                      dayTimes[0]?.hour_to === "23:59:00"
                    ) {
                      handleDelete(dayTimes[0].worker_time_profile_id);
                      setTimeout(() => {
                        setGoHandle(true);
                      }, 200);
                    } else {
                      // setMinimumId(startingHour.id);
                      // setMaximumId(endingHour.id);
                      setGoHandle(true);
                    }
                  }}
                >
                  {t("welcome-client.rightcard.dayweek.addBtn")}
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex w-full justify-around   border-t border-b py-2 my-2">
          <div
            className="text-sm flex  items-center font-medium cursor-pointer gap-2 text-azulOscuro hover:bg-[#e2e2e2] transform transition-all duration-150 ease-in-out px-4 py-1 rounded "
            onClick={() => {
              setShowAddTime(true);
            }}
          >
            <p>{t("welcome-client.rightcard.dayweek.monday.addtimeBtn")}</p>
            <Plus className="h-3 fill-azulOscuro" />
          </div>
          <label className="text-sm flex items-center font-medium cursor-pointer gap-2 text-azulOscuro  ">
            <input
              type="checkbox"
              checked={
                dayTimes[0]?.hour_from === "00:00:00" &&
                dayTimes[0]?.hour_to === "23:59:00" &&
                true
              }
              className="cursor-pointer"
              onChange={() => {
                handleToggle(i, day, dayTimes);
              }}
            />
            {t("welcome-client.rightcard.dayweek.monday.alldaycheck")}
          </label>
        </div>
      )}

      {dayTimes
        ?.sort((a, b) => a.hour_from.localeCompare(b.hour_from))
        ?.map((hour) => (
          <ScheduleTimes
            key={hour.worker_time_profile_id}
            hour={hour}
            handleDelete={handleDelete}
            day={dayName}
            setUpdate={setUpdate}
            profileType={profileType}
            draftId={draftId!}
            refetchTimes={refetchTimes}
          />
        ))}
    </div>
  );
};

export default ScheduleDays;
