import React from "react";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Diamond } from "src/assets/img/diamond.svg";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const userSubscribed = false;
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-blue-100/60 dark:bg-input rounded-xl text-azulBonico justify-center mx-auto w-28 text-center  py-3 px-1 mt-10">
        <p className="uppercase font-medium text-[#2563eb]">
          {t("worker.subscriptions.pricing.header.title.title")}
        </p>
      </div>
      <h1 className="text-center text-xl lg:text-3xl font-bold mt-10 mb-5 text-[#333] dark:text-whiteish ">
        {/* {t("subscriptions.title")} */}
        {t("worker.subscriptions.pricing.title")}
      </h1>
      <p className="text-center text-base lg:text-lg text-[#333] dark:text-whiteish">
        {t("worker.subscriptions.pricing.subtitle")}
      </p>
      <div className="flex lg:flex-row flex-col items-center justify-center gap-20 mt-10">
        <div className="border-2  rounded-3xl p-5 w-96 h-full my-4">
          <div className="flex items-center gap-4">
            <div className="bg-blue-100 p-1 rounded-xl">
              <Diamond className="h-20 w-20" />
            </div>
            <div>
              <p className="text-xl dark:text-whiteish">
                {t(
                  "worker.subscriptions.pricing.body.leftcard.freeplan.maintitle"
                )}
              </p>
              <p className="text-4xl font-bold dark:text-whiteish">
                $0
                <span className="font-normal text-lg">
                  {t(
                    "worker.subscriptions.pricing.body.leftcard.freeplan.mainsubtitle"
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-2">
            <h3 className="font-semibold text-xl text-[#333] dark:text-whiteish">
              {t("worker.subscriptions.pricing.body.leftcard.freeplan.title1")}
            </h3>
            <ul className=" mt-3 space-y-2">
              <li className="flex items-center gap-4">
                <CheckCircleIcon className="h-6 w-6 text-[#333] dark:text-whiteish" />
                <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                  {t(
                    "worker.subscriptions.pricing.body.leftcard.freeplan.title1.subtitle1"
                  )}
                </p>
              </li>
              <li className="flex items-center gap-4">
                <CheckCircleIcon className="h-6 w-6 text-[#333] dark:text-whiteish" />
                <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                  {t(
                    "worker.subscriptions.pricing.body.leftcard.freeplan.title1.subtitle2"
                  )}
                </p>
              </li>
              <li className="flex items-center gap-4 ">
                <CheckCircleIcon className="h-6 w-6 text-gray-400" />
                <p className="font-normal text-[16px] text-gray-400 line-through">
                  {t(
                    "worker.subscriptions.pricing.body.leftcard.freeplan.title1.subtitle3"
                  )}
                </p>
              </li>
            </ul>
          </div>
          <div className="mt-4">
            <h1 className="text-xl font-semibold text-[#333] dark:text-whiteish">
              {t("worker.subscriptions.pricing.body.leftcard.freeplan.title2")}
            </h1>
          </div>
          <div className="bg-blue-50 dark:bg-input rounded">
            <div className="flex items-center gap-4">
              <CheckCircleIcon className="h-10 w-8 text-[#333] dark:text-whiteish" />
              <div className="flex items-center gap-4">
                <h1 className="font-semibold text-xl dark:text-whiteish">
                  $12
                </h1>
                <span className="text-[16px] text-[#333] dark:text-whiteish">
                  {t(
                    "worker.subscriptions.pricing.body.leftcard.freeplan.title2.subtitle1"
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2"></div>
          {userSubscribed ? (
            <div className="bg-[#2563eb] bg-gradient-to-r from-[#2563eb]  to-azulBonico relative flex items-center justify-center py-3 px-3 rounded-full mt-4">
              <h1 className=" font-semibold text-center text-white  uppercase text-[16px] ">
                {t(
                  "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle2.getstartedBtn"
                )}
              </h1>
              <div className="px-3 py-1 bg-white  rounded-full absolute right-4">
                <ArrowUpRightIcon className="h-5 w-5 text-azulOscuro" />
              </div>
            </div>
          ) : (
            <div className="bg-gray-400 bg-gradient-to-r from-gray-400 to-gray-200 relative flex items-center justify-center py-3 px-3 rounded-full mt-4">
              <h1 className="font-semibold text-center text-white uppercase text-[16px]">
                {t(
                  "worker.subscriptions.pricing.body.leftcard.freeplan.title2.subtitle2.currentplanBtn"
                )}
              </h1>
            </div>
          )}
        </div>
        <div className="relative border-2 border-[#2563eb] rounded-3xl pt-5 w-96 h-full my-4 pb-5">
          <div className="absolute -top-1 flex items-center justify-center w-96 rounded-full ">
            <div className="border-4 w-40 rounded-full border-[#2563eb] " />
          </div>
          <div className="flex items-center gap-4  px-4">
            <div className="bg-blue-100 p-1 rounded-xl">
              <Diamond className="h-20 w-20" />
            </div>
            <div>
              <p className="text-xl dark:text-whiteish">
                {t(
                  "worker.subscriptions.pricing.body.rightcard.proplan.maintitle"
                )}
              </p>
              <p className="text-4xl font-bold dark:text-whiteish">
                $12
                <span className="font-normal text-lg dark:text-whiteish">
                  {" "}
                  {t(
                    "worker.subscriptions.pricing.body.rightcard.proplan.mainsubtitle"
                  )}{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-2 px-4">
            <h3 className="font-semibold text-xl text-[#333] dark:text-whiteish">
              {t("worker.subscriptions.pricing.body.rightcard.proplan.title1")}
            </h3>
            <ul className=" mt-3 space-y-2">
              <li className="flex items-center gap-4">
                <CheckCircleIcon className="h-6 w-6 text-[#333] dark:text-whiteish" />
                <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                  {t(
                    "worker.subscriptions.pricing.body.rightcard.proplan.title1.subtitle1"
                  )}
                </p>
              </li>
              <li className="flex items-center gap-4">
                <CheckCircleIcon className="h-6 w-6 text-[#333] dark:text-whiteish" />
                <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                  {t(
                    "worker.subscriptions.pricing.body.rightcard.proplan.title1.subtitle2"
                  )}
                </p>
              </li>
              <li className="flex items-center gap-4">
                <CheckCircleIcon className="h-6 w-6 text-[#333] dark:text-whiteish" />
                <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                  {t(
                    "worker.subscriptions.pricing.body.rightcard.proplan.title1.subtitle3"
                  )}
                </p>
              </li>
            </ul>
          </div>
          <div className="mt-4 px-4">
            <h1 className="text-xl font-semibold text-[#333] dark:text-whiteish">
              {t("worker.subscriptions.pricing.body.rightcard.proplan.title2")}
            </h1>
          </div>
          <div className="flex flex-col px-4">
            <div className="flex items-center gap-4 bg-blue-50 dark:bg-input rounded mt-1">
              <CheckCircleIcon className="h-10 w-10 text-[#333] dark:text-whiteish " />
              <div className="flex items-center gap-4">
                <h1 className="font-semibold text-xl dark:text-whiteish ">
                  $12
                </h1>
                <span className="text-[16px] text-[#333] dark:text-whiteish ">
                  {t(
                    "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle1"
                  )}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4 bg-blue-50 mt-1 rounded dark:bg-input">
              <CheckCircleIcon className="h-10 w-10 text-[#333] dark:text-whiteish" />
              <div className="flex items-center gap-4  ">
                <h1 className="font-semibold text-xl dark:text-whiteish ">
                  $12
                </h1>
                <span className="text-[16px] text-[#333] dark:text-whiteish ">
                  {t(
                    "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle2"
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2"></div>
          {!userSubscribed ? (
            <div className="bg-[#2563eb] mx-4 bg-gradient-to-r from-[#2563eb]  to-azulBonico relative flex items-center justify-center py-3 px-3 rounded-full mt-4">
              <h1 className=" font-semibold text-center text-white  uppercase text-[16px] ">
                {t(
                  "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle2.getstartedBtn"
                )}
              </h1>
              <div className="px-3 py-1 bg-white  rounded-full absolute right-4">
                <ArrowUpRightIcon className="h-5 w-5 text-azulOscuro" />
              </div>
            </div>
          ) : (
            <div className="bg-gray-400 mx-4 bg-gradient-to-r from-gray-400 to-gray-200 relative flex items-center justify-center py-3 px-3 rounded-full mt-4">
              <h1 className="font-semibold text-center text-white uppercase text-[16px]">
                {t(
                  "worker.subscriptions.pricing.body.leftcard.freeplan.title2.subtitle2.currentplanBtn"
                )}
              </h1>
              <></>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
