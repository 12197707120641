import React, { useState, useRef, Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import { ImLocation } from "react-icons/im";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { selectedApplicantsSection } from "../../Atoms/jotaiAtoms";
import {
  HandleMonthName,
  HandleMonthNameES,
} from "../../Utils/HandleMonthName";

import { RiMoreLine } from "react-icons/ri";
import { ReactComponent as Play } from "../../assets/img/play.svg";
import { ReactComponent as ThumbDown } from "../../assets/img/thumb-down.svg";
import { ReactComponent as Flag } from "../../assets/img/flag.svg";
import { ReactComponent as Cross } from "../../assets/img/cross-white.svg";
import AvailableDays from "../Time/AvailableDays";
import RatingStars from "../Rating/RatingStars";
import { options } from "../../lib/months";
import {
  ApplicantTypes,
  EducationTypes,
  WorkerInfoTypes,
} from "../../types/types";
import { ModalProps } from "../Modals/ModalWorkerCard";
import { useTranslation } from "react-i18next";
import { BASE_URL_IMG } from "../../Config/api.config";

import { AiOutlineDownload } from "react-icons/ai";

interface ApplicantDetailsProps {
  worker: ApplicantTypes;
  setWorker: any;
  setShowModal: Dispatch<SetStateAction<ModalProps>>;
  handleShortlist: (worker: any) => void;
  handleArchived: (worker: any) => void;
  closeModal: any;
  setDeclinedApplicant: any;
  checkIfThereIsConversation: (worker: any) => void;
  workerInfo: WorkerInfoTypes;
  // downloadPdf: () => void;
}

const ApplicantDetails = ({
  worker,
  setWorker,
  setShowModal,
  handleShortlist,
  handleArchived,
  closeModal,
  setDeclinedApplicant,
  checkIfThereIsConversation,
  workerInfo,
}: ApplicantDetailsProps) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeStar, setActiveStar] = useState(-1);
  const [offeredApplicant, setOfferedApplicant] = useLocalStorage(
    "dataOfferedApplicant",
    ""
  );
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [allowChange, setAllowChange] = useState("");
  const [selectedSection, setSelectedSection] = useAtom(
    selectedApplicantsSection
  );
  // const [allChosenTimes, setAllChosenTimes] = useState();
  const dropRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const postcode = worker?.post_code_name?.split(",");
  const { i18n } = useTranslation();

  const handleShortlistedStatusApplicant = (props: string) => {
    handleShortlist(worker);
    setAllowChange(props);
  };

  useEffect(() => {
    if (allowChange === "true") {
      setWorker((prev: any) => ({ ...prev, saved: "true" }));
      setAllowChange("false");
    } else if (allowChange === "false") {
      setWorker((prev: any) => ({ ...prev, saved: "false" }));
      setAllowChange("false");
    }
  }, [allowChange]);

  //no se cierra
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showDropdown &&
        dropRef.current &&
        !dropRef.current.contains(e.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDropdown]);

  return (
    <>
      <div id="applicants-details">
        <div className="text-xl mt-6 mx-6 rounded h-fit drop-shadow bg-white  flex flex-col lg:flex-row justify-between">
          <div className="flex flex-col w-full ">
            <div className="flex lg:flex-row flex-col px-8 py-6 w-full justify-around border-b border-blue-100">
              <div className="flex flex-[0_0_40%] w-full justify-between">
                <img
                  src={`${BASE_URL_IMG}${worker?.profile_image}`}
                  alt="profile"
                  className="lg:h-20  lg:w-20 h-14 w-14 object-cover rounded-full"
                />
                <div className="flex flex-col px-6">
                  <p className="flex items-center lg:items-end text-sm lg:text-2xl tracking-wider font-bold whitespace-nowrap">
                    <span className="text-lg lg:text-3xl ">
                      {worker.first_name?.slice(0, 1)}
                    </span>
                    {worker.first_name?.slice(1)}
                    &nbsp;
                    <span className="text-lg lg:text-3xl ">
                      {worker.last_name?.slice(0, 1)}
                    </span>
                    {worker.last_name?.slice(1)}
                  </p>
                  <div className="flex items-start mt-4 ">
                    <ImLocation className="h-3 text-gray-400 mt-[2px] mr-1" />
                    {/* <InPerson className="h-3" /> */}
                    <p className="text-sm">
                      {" "}
                      {postcode?.[0]?.slice(0, 1) +
                        postcode?.[0]
                          ?.slice(1, postcode?.[0].length)
                          .toLowerCase() +
                        "," +
                        postcode?.[1]}
                      , {worker.city_name}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-end flex-[0_0_60%]">
                {!jobData.job_status &&
                  window.location.pathname !== "/my-hires" && (
                    <div className="flex items-center gap-3">
                      {selectedSection === "declined" && (
                        <>
                          {worker?.declined === "true" && (
                            <div className="pr-8 font-bold text-sm h-fit w-fit flex flex-nowrap">
                              <div className="flex flex-col items-start gap-2 mr-2">
                                <p className="whitespace-nowrap">
                                  {t("employer.invite_workers.declined")}{" "}
                                  <span className=" text-xs font-bold text-slate-400 ">
                                    {i18n.language === "en" ? (
                                      <>
                                        {HandleMonthName(
                                          worker?.declined_reason_date?.slice(
                                            3,
                                            5
                                          )
                                        )}{" "}
                                        {worker?.declined_reason_date?.slice(
                                          0,
                                          2
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {worker?.declined_reason_date?.slice(
                                          0,
                                          2
                                        )}{" "}
                                        de{" "}
                                        {HandleMonthNameES(
                                          worker?.declined_reason_date?.slice(
                                            3,
                                            5
                                          )
                                        )}
                                      </>
                                    )}
                                  </span>
                                </p>
                                <p className="text-xs text-slate-400 italic whitespace-nowrap">
                                  {worker?.declined_reason_name}
                                </p>
                              </div>
                            </div>
                          )}
                          {worker?.offer_declined_by_worker === "true" && (
                            <div className="pr-8  text-sm h-fit w-fit flex flex-nowrap">
                              <div className="flex flex-col items-start gap-2 mr-2">
                                <p className="font-bold whitespace-nowrap">
                                  {t("employer.invite_workers.declined")}{" "}
                                  <span className=" text-xs font-bold text-slate-400 ">
                                    {i18n.language === "en" ? (
                                      <>
                                        {HandleMonthName(
                                          worker?.offer_declined_reason_date?.slice(
                                            3,
                                            5
                                          )
                                        )}{" "}
                                        {worker?.offer_declined_reason_date?.slice(
                                          0,
                                          2
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {worker?.offer_declined_reason_date?.slice(
                                          0,
                                          2
                                        )}{" "}
                                        de{" "}
                                        {HandleMonthNameES(
                                          worker?.offer_declined_reason_date?.slice(
                                            3,
                                            5
                                          )
                                        )}
                                      </>
                                    )}
                                  </span>
                                </p>
                                <p className="text-xs font-bold italic text-slate-400 whitespace-nowrap">
                                  {worker?.offer_declined_reason_name}
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {worker?.archived === "true" &&
                        selectedSection === "archived" && (
                          <div className="pr-8 font-bold text-sm h-fit w-fit flex flex-nowrap">
                            <div className="flex items-center gap-4 mr-2">
                              <button
                                className="px-[36px] py-1 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-300"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowModal((prev) => ({
                                    ...prev,
                                    decline: true,
                                  }));
                                  setDeclinedApplicant(worker);
                                }}
                              >
                                {t("employer.invite_workers.decline")}
                              </button>
                              <button
                                className="px-8 py-[5px] text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleArchived(worker);
                                }}
                              >
                                {t("employer.invite_workers.unarchive")}
                              </button>
                            </div>
                          </div>
                        )}

                      {window.location.pathname === "/applicants/offers" && (
                        <div className="pr-8 font-bold text-sm h-fit w-fit flex flex-col">
                          <button
                            className="px-8 py-1 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-300"
                            onClick={(e) => {
                              checkIfThereIsConversation(worker);
                            }}
                          >
                            {t("employer.invite_workers.messages")}
                          </button>
                          <p className="mx-auto mt-2 text-xs">
                            {t("employer.invite_workers.offer_expires")}{" "}
                            {worker?.expire_offer_days}{" "}
                            {t("employer.invite_workers.days")}
                          </p>
                        </div>
                      )}
                      {(selectedSection === "all" ||
                        selectedSection === "shortlisted") &&
                        window.location.pathname === "/applicants/review" &&
                        jobData?.data[0]?.job_status !== "Closed" &&
                        jobData?.data[0]?.expired !== "true" && (
                          <div className="flex flex-col gap-3">
                            {workerInfo.report.length === 0 && (
                              <div className="flex gap-3">
                                <div className="relative" ref={dropRef}>
                                  <RiMoreLine
                                    size={30}
                                    color="black"
                                    className="border p-1 border-blue-100 rounded-full cursor-pointer hover:bg-[#f7f4f4] transition duration-300"
                                    onClick={() =>
                                      setShowDropdown(!showDropdown)
                                    }
                                  />
                                  {showDropdown === true && (
                                    <ul
                                      className={`absolute top-12 -left-4 z-40 py-[10px] bg-white text-start text-sm shadow-[0px_0px_5px_1px_rgba(0,0,0,0.2)] rounded w-fit whitespace-nowrap `}
                                    >
                                      <div
                                        className="flex items-center gap-2 px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                                        onClick={() => {
                                          handleArchived(worker);
                                          closeModal();
                                        }}
                                      >
                                        <ThumbDown className="w-fit h-3 overflow-visible" />
                                        <li>
                                          {t("employer.invite_workers.archive")}
                                        </li>
                                      </div>
                                      <div
                                        className="flex items-center gap-2 px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                                        onClick={() => {
                                          setDeclinedApplicant(worker);
                                          setShowModal((prev) => ({
                                            ...prev,
                                            decline: true,
                                          }));
                                        }}
                                      >
                                        <Cross
                                          className={`bg-black p-[2px] rounded-full w-fit h-3 overflow-visible`}
                                          fill={"white"}
                                        />
                                        <li>
                                          {t(
                                            "employer.invite_workers.decline_candidate"
                                          )}
                                        </li>
                                      </div>
                                      {workerInfo.report.length === 0 && (
                                        <div
                                          className="flex items-center gap-2 px-2 py-1 cursor-pointer hover:bg-[#f7f4f4]"
                                          onClick={() =>
                                            setShowModal((prev) => ({
                                              ...prev,
                                              report: true,
                                            }))
                                          }
                                        >
                                          <Flag className="h-3" />
                                          <li>
                                            {t("employer.received_offer.flag")}
                                          </li>
                                          {/* <li>Thanks for flagging !</li> */}
                                        </div>
                                      )}
                                    </ul>
                                  )}
                                  <div
                                    className={`bg-white z-50 border-l-2 border-t-2 w-4 h-4 rotate-45 absolute -bottom-[23px] left-2 ${
                                      showDropdown === false
                                        ? "hidden"
                                        : "visible"
                                    }`}
                                  ></div>
                                </div>
                                {worker.saved === "false" ? (
                                  <button
                                    className="px-5 py-1.5 text-sm text-azulBonico hover:bg-[#f7f4f4] border h-fit border-blue-100  bg-white rounded-full transition duration-300"
                                    onClick={() => {
                                      handleShortlistedStatusApplicant("true");
                                    }}
                                  >
                                    {t("employer.invite_workers.shortlist")}
                                  </button>
                                ) : (
                                  <button
                                    className="px-5 py-1.5 text-sm text-azulBonico hover:bg-[#f7f4f4] border h-fit border-blue-100  bg-white rounded-full transition duration-300"
                                    onClick={() =>
                                      handleShortlistedStatusApplicant("false")
                                    }
                                  >
                                    {t("employer.invite_workers.shortlisted")}
                                  </button>
                                )}
                                <button
                                  className="px-8 flex  py-1.5 text-sm text-white bg-azulBonico hover:bg-azulOscuro h-fit rounded-full transition duration-300"
                                  onClick={() => {
                                    setOfferedApplicant(worker);
                                    navigate("/send-offer");
                                  }}
                                >
                                  {t("employer.invite_workers.sendOffer")}
                                </button>
                              </div>
                            )}

                            {workerInfo.report.length > 0 && (
                              <p className="text-xs text-slate-500 font-medium">
                                {t(
                                  "employer.invite_workers.flagged_inappropiate"
                                )}
                                :<br></br>{" "}
                                <span className="italic">
                                  {
                                    workerInfo.report[0]
                                      .inapropiate_worker_profile_reason_name
                                  }
                                </span>
                              </p>
                            )}
                          </div>
                        )}
                      {window.location.pathname === "/applicants/hired" && (
                        <>
                          {!worker.date_early_end &&
                            worker.date_start > worker.server_date && (
                              <div className="text-sm mr-8 text-right">
                                <p className="font-bold text-slate-500">
                                  {t("employer.invite_workers.no_working")}
                                </p>
                              </div>
                            )}
                          {!worker.date_early_end &&
                            worker.date_start <= worker.server_date &&
                            worker.server_date <= worker.date_end && (
                              <div className="text-sm mr-8 text-right flex flex-col gap-2 items-end w-fit h-fit">
                                <p className="text-green-500 font-bold">
                                  {t(
                                    "employer.invite_workers.currently_working"
                                  )}
                                </p>
                                {/* <p
                                  className="text-slate-500 cursor-pointer hover:underline w-fit h-fit text-xs  mb-[2px]"
                                  onClick={() => {
                                    // setClickedWorker(worker);
                                    setShowModal((prev) => ({
                                      ...prev,
                                      end: true,
                                    }));
                                  }}
                                >
                                  End contract??
                                </p> */}
                                <button
                                  className="button-rate !w-full"
                                  onClick={() => {
                                    // setClickedWorker(worker);
                                    setShowModal((prev) => ({
                                      ...prev,
                                      end: true,
                                    }));
                                  }}
                                >
                                  {t("employer.invite_workers.end_contract")}
                                </button>
                              </div>
                            )}
                          {!worker.date_early_end &&
                            worker.server_date <= worker.date_early_end! && (
                              <div className="text-sm flex flex-col gap-2  text-left">
                                <p className="text-green-500 font-bold">
                                  {t(
                                    "employer.invite_workers.currently_working"
                                  )}
                                </p>
                              </div>
                            )}
                          {(worker.server_date > worker.date_end ||
                            worker.server_date >= worker.date_early_end!) && (
                            <div className="text-sm flex flex-col gap-2 mr-8 text-center">
                              <p className="font-bold text-slate-500">
                                {t("employer.invite_workers.finished_working")}
                              </p>
                              {worker.score_from_employer === "0.00" ||
                              worker.score_from_employer === null ? (
                                <button
                                  className="button-rate w-full"
                                  onClick={() => {
                                    // setClickedWorker(worker);
                                    setShowModal((prev) => ({
                                      ...prev,
                                      rating: true,
                                    }));
                                  }}
                                >
                                  {t("employer.invite_workers.give_feedback")}
                                </button>
                              ) : (
                                <div className="flex gap-2 font-bold text-sm">
                                  <RatingStars
                                    score={worker.score_from_employer}
                                    setActiveStar={setActiveStar}
                                    showHalf={true}
                                    evaluation={false}
                                    activeStar={worker.score_from_employer}
                                    showModal={false}
                                    // reviews={true}
                                  />
                                  <p className="text-sm text-slate-500 mt-[1px]">
                                    ({worker.score_from_employer})
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {window.location.pathname === "/applicants/invite" && (
                        <div className="flex flex-col gap-3 items-end justify-end">
                          {worker.invited === "true" ? (
                            <div className="whitespace-nowrap pr-8 flex flex-col ">
                              <p className="text-slate-400 text-sm font-bold">
                                {t("employer.invite_workers.invited_to_job")}{" "}
                                {i18n.language === "en" ? (
                                  <>
                                    {HandleMonthName(
                                      worker.date_invited?.slice(5, 7)
                                    )}{" "}
                                    {worker.date_invited?.slice(8, 10)}
                                    <span className="text-xs">th</span>.{" "}
                                  </>
                                ) : (
                                  <>
                                    {worker.date_invited?.slice(8, 10)} de{" "}
                                    {HandleMonthNameES(
                                      worker.date_invited?.slice(5, 7)
                                    )}
                                    .{" "}
                                  </>
                                )}
                                <span
                                  className="font-normal hover:underline cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // setClickedWorker(worker);
                                    // setWorker((prev) => ({
                                    //   ...prev,
                                    //   invited: "false",
                                    // }));
                                    setShowModal((prev) => ({
                                      ...prev,
                                      invite: true,
                                    }));
                                  }}
                                >
                                  {t("employer.invite_wokers.undo")}
                                </span>
                              </p>
                            </div>
                          ) : (
                            <>
                              {workerInfo?.report.length === 0 && (
                                <div className="pr-4">
                                  <button
                                    className="px-6 h-fit text-sm  whitespace-nowrap py-1 hover:bg-azulOscuro text-white bg-azulBonico   border border-blue-100  rounded-full transition duration-300"
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      setShowModal((prev) => ({
                                        ...prev,
                                        invite: true,
                                      }));
                                    }}
                                  >
                                    {t("employer.invite_workers.invite_to_job")}
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                          {workerInfo.report.length > 0 ? (
                            <p className="text-xs text-slate-500 font-medium">
                              {t(
                                "employer.invite_workers.flagged_inappropiate"
                              )}
                              :<br></br>{" "}
                              <span className="italic">
                                {
                                  workerInfo.report[0]
                                    .inapropiate_worker_profile_reason_name
                                }
                              </span>
                            </p>
                          ) : (
                            <div
                              className="flex items-center gap-3 cursor-pointer ml-auto justify-end"
                              onClick={() =>
                                setShowModal((prev) => ({
                                  ...prev,
                                  report: true,
                                }))
                              }
                            >
                              <Flag className="h-3 fill-azulBonico" />
                              <p className="text-sm text-azulBonico font-semibold">
                                {t("employer.invite_workers.flag_inappropiate")}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                <div
                  className="bg-white rounded-full p-2 ml-2 shadow cursor-pointer hover:bg-gray-100 transition"
                  // className="flex items-center  ml-2 py-2 px-2 rounded-full cursor-pointer "
                  // onClick={downloadPdf}
                >
                  {/* <p className="text-sm">Download</p> */}
                  <AiOutlineDownload className="h-4" />
                  {/* Download <InformationCircleIcon className="h-4 ml-2" /> */}
                </div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col w-full">
              <div className="flex flex-col text-sm px-8 py-6 lg:flex-[0_0_30%] w-full border-r border-blue-100 ">
                <div className="flex flex-col mb-8">
                  <div className="flex flex-col  ">
                    <p className="font-bold text-base tracking-wide">
                      {t("employer.invite_workers.about_worker")}
                    </p>
                    <p className="mt-1 text-xs text-slate-400">
                      {t("employer.invite_workers.member_since")}{" "}
                      {i18n.language === "en"
                        ? HandleMonthName(
                            workerInfo?.score?.date_joined?.slice(5, 7)
                          )
                        : HandleMonthNameES(
                            workerInfo?.score?.date_joined?.slice(5, 7)
                          )}{" "}
                      {workerInfo?.score?.date_joined?.slice(0, 4)}
                    </p>
                    <div className="mt-3">
                      <RatingStars
                        activeStar={workerInfo?.score?.avgScore}
                        score={workerInfo?.score?.avgScore}
                        setActiveStar={setActiveStar}
                        evaluation={false}
                        showHalf={true}
                        reviews={true}
                        info={workerInfo?.score}
                        showModal={false}
                      />
                    </div>
                    {workerInfo?.score &&
                      workerInfo?.score?.avgScore !== "0.0" && (
                        <ul className="flex flex-col gap-2 font-medium">
                          <li className="text-sm flex items-center">
                            <p className="w-6">
                              {/* <span className="font-bold "> */}
                              {Math.round(workerInfo?.score?.avgComplianceDate)}
                              %
                            </p>
                            <span className="text-xs text-gray-500 ml-2">
                              {t("employer.invite_workers.job_success")}
                            </span>
                          </li>
                          <li className="text-sm flex items-center">
                            <div className="flex items-center w-6">
                              <span className="">
                                {workerInfo?.score?.totalConnections}
                              </span>{" "}
                            </div>
                            <span className="text-xs text-gray-500 ml-2">
                              {t("employer.invite_workers.job_done")}{" "}
                            </span>
                          </li>
                          <li className="text-sm flex items-center">
                            <span className="flex items-center w-6">
                              {workerInfo?.score?.totalConnectionsTerminated}
                            </span>{" "}
                            <span className="text-xs text-gray-500 ml-2">
                              {t("employer.invite_workers.left_job")}{" "}
                            </span>
                          </li>
                        </ul>
                      )}
                  </div>
                  {!workerInfo?.score && (
                    <p className="text-xs mt-1 text-gray-500 ">No feedback</p>
                  )}
                  {worker.profile_video && (
                    <>
                      <div className="flex items-center mb-4 mt-5 justify-between">
                        <p className="font-bold text-base tracking-wide">
                          Meet {worker?.first_name}
                        </p>
                      </div>

                      <div
                        className="relative cursor-pointer"
                        onClick={() =>
                          setShowModal((prev: any) => ({
                            ...prev,
                            video: true,
                          }))
                        }
                      >
                        <img
                          src={`https://img.youtube.com/vi/${
                            worker?.profile_video?.split("v=")[1].split("&")[0]
                          }/mqdefault.jpg`}
                          alt="thumbnail"
                        />
                        <Play
                          className="h-10 absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4"
                          fillOpacity={"0.9"}
                          fill={"white"}
                        />
                      </div>
                    </>
                  )}
                </div>{" "}
                <div className="flex flex-col mb-4">
                  <p className="font-bold text-base  tracking-wide mb-2">
                    {t("worker.profile.categories")}
                  </p>
                  <ul>
                    {workerInfo.categories &&
                      workerInfo.categories?.map((cat: { name: string }) => {
                        return (
                          <li
                            key={cat.name}
                            className="mb-1 text-[#7b7f80b5] text-[13px] font-medium "
                          >
                            {cat.name}{" "}
                          </li>
                        );
                      })}
                  </ul>
                  {!workerInfo.categories && (
                    <p className="text-xs text-gray-500">No categories</p>
                  )}
                </div>
                <div className="flex flex-col mb-4">
                  <p className="font-bold text-base  tracking-wide mb-4">
                    {t("worker.profile.languages")}
                  </p>
                  {workerInfo.languages?.map(
                    (lang: {
                      language_name: string;
                      languale_level_name: string;
                    }) => {
                      return (
                        <div
                          key={lang.language_name}
                          className="mb-4 flex flex-col items-start border-t pt-2"
                        >
                          <h3>{lang.language_name}</h3>
                          <p className=" text-[#7b7f80b5]">
                            {lang.languale_level_name}
                          </p>
                        </div>
                      );
                    }
                  )}
                  <p></p>
                </div>
                <div className="flex flex-col">
                  {workerInfo.education.length > 0 && (
                    <p className="font-bold text-base tracking-wide mb-4">
                      {t("worker.profile.education")}
                    </p>
                  )}
                  {workerInfo.education?.map((education: EducationTypes) => {
                    return (
                      <>
                        <div
                          key={education.start_date}
                          className="flex items-start justify-between mb-4"
                        >
                          <div className="flex flex-col">
                            <p className="font-bold">
                              {education.institution_name}
                            </p>
                            <p className="text-sm">
                              {education.degree && `${education.degree},`}
                              &nbsp; {`${education.area_of_study} `}{" "}
                            </p>
                            <p className="text-sm">
                              {education.start_date.substring(0, 4)}{" "}
                              {education.end_date &&
                                `- ${education.end_date.substring(0, 4)}`}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col flex-[0_0_70%] w-full">
                <div className="border-b border-blue-100  px-8 py-6">
                  <p className="font-extrabold tracking-wider text-xl">
                    {worker.title ? worker.title : workerInfo.data?.[0].title}
                  </p>{" "}
                  {worker.cover_letter &&
                  worker.cover_letter !== "undefined" ? (
                    <p className="text-sm tracking-wide my-8 max-w-[558px] overflow-hidden">
                      {worker.cover_letter}
                    </p>
                  ) : (
                    <p className="text-sm tracking-wide my-8 max-w-[558px] overflow-hidden">
                      {workerInfo.data?.[0].overview}
                    </p>
                  )}
                </div>
                <div className="px-8 py-6">
                  <p className="font-bold text-lg">
                    {t("worker.profile.skills")}
                  </p>
                  <div className="flex flex-wrap mt-4 gap-2">
                    {worker?.skills?.split(",")?.map((skill: string) => {
                      return (
                        <p
                          key={skill}
                          className="px-2 w-fit text-xs py-0.5 mx-0.5 bg-blancoGrisClaro my-0.5 flex items-center border rounded-xl text-[#343a47] cursor-pointer "
                        >
                          {skill}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="px-8 border-t border-blue-100 pb-4">
                  <div className="flex mt-5 mb-2 items-center gap-3">
                    <p className="font-bold text-lg">
                      {t("worker.profile.available_hours")}
                    </p>
                  </div>
                  <AvailableDays allChosenTimes={workerInfo.allChosenTimes} />
                </div>
                <div className="px-8 border-t border-blue-100 pb-4">
                  {workerInfo.experience?.length > 0 && (
                    <div className="flex flex-col w-full mt-5">
                      <p className="font-bold text-lg tracking-wide mb-4">
                        {t("worker.profile.work_experience")}
                      </p>
                      <div className="flex flex-col">
                        {workerInfo.experience?.map((experience) => {
                          return (
                            <div key={experience.job_title} className="py-6 ">
                              <div className="flex justify-between items-center">
                                <p className="text-base font-bold">
                                  {experience.job_title} |{" "}
                                  {experience.company_name}
                                </p>
                              </div>
                              <div className="flex flex-col">
                                <p className="text-sm text-[#7b7f80b5]">
                                  {options?.map((month) => {
                                    return month.id ===
                                      experience.start_date.substring(5, 7)
                                      ? month.label
                                      : "";
                                  })}
                                  &nbsp;
                                  {experience.start_date.substring(0, 4)} -
                                  &nbsp;
                                  {(!experience.end_date ||
                                    experience.end_date === "0000-00-00") &&
                                    "Present"}
                                  {experience.end_date &&
                                    options?.map((month) => {
                                      return month.id ===
                                        experience?.end_date.substring(5, 7)
                                        ? `${
                                            month.label
                                          } ${experience.end_date.substring(
                                            0,
                                            4
                                          )}`
                                        : "";
                                    })}
                                </p>
                                <p className="text-sm">
                                  {experience.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicantDetails;
