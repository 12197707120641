import React, { useState, useRef, useEffect, Fragment } from "react";
import Select from "react-select";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import exclamation from "../../assets/img/exclamation.png";
import { HireTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { cn } from "src/lib/utils";
import { userProfileStore } from "src/Routing/useProfileStore";
interface ModalEditEndEarlyDateProps {
  hire?: HireTypes;
  showModal: { end: boolean };
  setShowModal: any;
  employer?: any;
  breakWorkerConnection: (args: any, args2: any, args3: any) => void;
}

const ModalEditEndEarlyDate = ({
  showModal,
  setShowModal,
  hire,
  breakWorkerConnection,
  employer,
}: ModalEditEndEarlyDateProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [transition, setTransition] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [reasons, setReasons] = useState();
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const [endData, setEndData] = useState({
    date: hire ? hire.server_date : employer.server_date,
    reason: "",
  });
  const [warning, setWarning] = useState(false);

  const { t, i18n } = useTranslation();
  console.log("employer", employer);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.end && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.end]);
  const getEndConnectionReasons = () => {
    fetch(
      `${BASE_URL}${profileType === "employer" ? "end" : "quit"}.work.reasons`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) =>
        setReasons(
          data.result?.map(function (row: any) {
            return {
              value: row.name,
              label: row.name,
              id: row.id,
            };
          })
        )
      )
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEndConnectionReasons();
  }, []);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, end: false }));
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);

  const colourStyles = {
    control: () => ({
      border: `${
        warning && !endData.reason ? "2px solid #9b211b" : "2px solid #E2E8F0"
      } `,
      display: "flex",
      fontWeight: "300",
      color: "black",
      letterSpacing: "0.05em",
      //   backgroundColor: "#F9F7F7",
      padding: "0rem",
      paddingLeft: "0.5rem",
      width: "full",
      // maxWidth: "400px",
      borderRadius: "5px",
      height: "40px",
    }),
  };

  function addDay(date: Date) {
    date?.setDate(date?.getDate() + 1);
    return date;
  }

  // const endDateInput = addDay(new Date(hire?.date_end?.slice(0, 10)));

  return (
    <Modal isOpen={showModal.end} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] py-4 lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <div className="flex justify-between border-b py-2 mb-6 px-8">
          <h1 className="font-bold text-xl tracking-wide  ">
            {t("modal.send_rating.title")}
          </h1>
          <Button
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
            onClick={() => closeModal()}
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <div className="text-[15px] text-gray-500 pt-4 px-8">
          <>
            <span className="font-semibold text-black">
              {t("modal.send_rating.not_hire")}{" "}
              {employer?.employer_full_name ?? hire?.first_name}?
            </span>{" "}
            {t("modal.send_rating.desc.not_hire")}
          </>
          {/* )} */}
        </div>
        <div className="px-8 py-4 flex flex-col gap-4">
          <p className="text-sm flex flex-col items-start text-gray-500 font-medium">
            <span className="text-black font-normal">
              {t("modal.send_rating.job_title")}
            </span>{" "}
            {employer?.title ?? hire?.title}
          </p>
          <p className="text-sm flex flex-col items-start text-gray-500 font-medium">
            <span className="text-black font-normal">
              {i18n.language === "en" ? (
                <>{hire ? "Employee name:" : "Employer name:"}</>
              ) : (
                <>{hire ? "Nombre del Trabajador" : "Nombre del Empleador"}</>
              )}
            </span>{" "}
            {hire ? hire.first_name : employer.employer_name}{" "}
            {hire && hire.last_name}
          </p>
          <div className="flex items-center justify-between gap-4">
            <p className="text-sm ">{t("modal.send_rating.end_date")}</p>
            <input
              type="date"
              className="border border-border  text-sm cursor-pointer outline-none rounded px-4 py-1 text-black"
              defaultValue={date}
              min={hire ? hire.date_end : employer.date_end}
              max={hire ? hire.date_end : employer.date_end}
              onChange={(e) =>
                setEndData((prev) => ({
                  ...prev,
                  date: e.target.value,
                }))
              }
            />
          </div>
          <div className="w-full mt-4">
            <Select
              options={reasons}
              styles={colourStyles}
              placeholder={`${t("modal.send_rating.select_reason")}`}
              className="text-sm cursor-pointer"
              onChange={(e: any) => {
                setEndData((prev) => ({ ...prev, reason: e.id }));
                setWarning(false);
              }}
            />
            {warning && !endData.reason && (
              <div className="text-[#9b211b] flex items-center text-sm mt-2">
                <img className="exclamation" src={exclamation} alt="hey" />
                <p>{t("modal.send_rating.select_reason")}</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full h-full items-end p-3 gap-2 px-8 mt-2 justify-center">
          <button
            className={`border-none font-semibold flex-1 rounded-[20px] text-sm  transition duration-300 h-8  bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer`}
            onClick={(e) => {
              // handleContinue();
              if (hire) {
                if (!hire.connection_id || !endData.reason || !endData.date) {
                  setWarning(true);
                } else {
                  breakWorkerConnection(
                    hire.connection_id,
                    endData.reason,
                    endData.date
                  );
                  closeModal();
                  setShowModal((prev: any) => ({
                    ...prev,
                    worker: false,
                  }));
                }
              } else {
                if (
                  !employer.connection_id ||
                  !endData.reason ||
                  !endData.date
                ) {
                  setWarning(true);
                } else {
                  breakWorkerConnection(
                    employer.connection_id,
                    endData.reason,
                    endData.date
                  );
                  closeModal();
                }
              }
            }}
          >
            {t("modal.send_rating.accept")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditEndEarlyDate;
