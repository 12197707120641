import React, { useEffect } from "react";
import Select from "react-select";

import { ReactComponent as ArrowRight } from "src/assets/img/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "src/assets/img/arrow-left.svg";
import { useTranslation } from "react-i18next";

const jobsPerPage = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
];

interface PaginationProps {
  pagination: {
    totalPages: any;
    currentPage: number;
    title: string;
    limit: number;
    pageNumbers: any;
  };
  setPagination: any;
  getJobPostsFiltered: (args: string) => void;
}

const Pagination = ({
  pagination,
  setPagination,
  getJobPostsFiltered,
}: PaginationProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    getJobPostsFiltered("");
  }, [pagination.currentPage, pagination.title, pagination.limit]);

  useEffect(() => {
    if (pagination.totalPages) {
      setPagination((prev: any) => ({
        ...prev,

        pageNumbers: [...Array(pagination.totalPages + 1).keys()]?.slice(1),
        // pageNumbers: [...(pagination.totalPages + 1).keys()]?.slice(1),
      }));
    }
  }, [pagination.totalPages]);

  function goToNextPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.currentPage + 1,
    }));
  }

  function goToPreviousPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.currentPage - 1,
    }));
  }

  function goToFirstPage() {
    setPagination((prev: any) => ({ ...prev, currentPage: 1 }));
  }

  function goToLastPage() {
    setPagination((prev: any) => ({
      ...prev,
      currentPage: pagination.totalPages,
    }));
  }

  function changePage(page: any) {
    setPagination((prev: any) => ({ ...prev, currentPage: page }));
  }

  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "lightblue",
      },
      display: "flex",
      width: "100px",
      padding: "0rem",
      borderRadius: "5px",
      height: "30px",
      fontSize: "14px",
    }),
  };

  return (
    <div className="border-t z-100 overflow-visible mt-2 dark:text-whiteish ">
      <div className="p-6 flex flex-col lg:flex-row justify-between">
        <div className="flex items-center gap-5">
          <p className="text-sm font-medium">
            {t("employer.alljobs.jobsperpage.left.Text")}:
          </p>
          <Select
            options={jobsPerPage}
            styles={colourStyles2}
            className="text-sm z-[200] dark:text-black"
            defaultValue={{ label: pagination.limit, value: pagination.limit }}
            onChange={(e: any) => {
              setPagination((prev: any) => ({ ...prev, limit: e.value }));
            }}
            isSearchable={false}
          />
        </div>
        <div className="flex items-center mt-4 lg:mt-0 gap-6">
          <button
            className={`hidden lg:block text-sm font-medium cursor-pointer  ${
              pagination.currentPage == 1
                ? "text-slate-400 cursor-not-allowed"
                : "text-azulBonico hover:text-azulOscuro"
            } `}
            onClick={() => goToFirstPage()}
            disabled={pagination.currentPage == 1}
          >
            {t("employer.alljobs.first.right.Text")}
          </button>
          <button
            className={`flex items-center gap-3 ${
              pagination.currentPage == 1
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }  `}
            onClick={() => goToPreviousPage()}
            disabled={pagination.currentPage == 1}
          >
            <ArrowLeft
              className="h-5 "
              fill={`${
                pagination.currentPage == 1 ? "rgb(148 163 184)" : "#2abeeb"
              } `}
            />
            <p
              className={`text-sm font-medium ${
                pagination.currentPage == 1
                  ? "text-slate-400 cursor-not-allowed"
                  : "text-azulBonico hover:text-azulOscuro"
              } `}
            >
              {t("employer.alljobs.previous.right.Text")}
            </p>
          </button>
          <div className=" font-medium flex gap-3">
            {pagination.currentPage < 4 ? (
              <>
                {pagination.pageNumbers?.slice(0, 4)?.map((page: number) => {
                  return (
                    <>
                      {page === pagination.currentPage ? (
                        <div
                          className="text-azulBonico decoration-[2px]  underline underline-offset-[6px] cursor-pointer"
                          onClick={() => changePage(page)}
                        >
                          &nbsp;&nbsp;{page}&nbsp;&nbsp;
                        </div>
                      ) : (
                        <div
                          className="hover:text-azulOscuro hover:underline cursor-pointer"
                          onClick={() => changePage(page)}
                        >
                          &nbsp;{page}&nbsp;
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <>
                {pagination.currentPage >= 4 &&
                pagination.totalPages - 3 >= pagination.currentPage ? (
                  <>
                    {pagination.pageNumbers
                      ?.slice(
                        pagination.currentPage - 2,
                        pagination.currentPage + 1
                      )
                      ?.map((page: number) => {
                        return (
                          <>
                            {page === pagination.currentPage ? (
                              <div
                                className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                onClick={() => changePage(page)}
                              >
                                &nbsp;&nbsp;{page}&nbsp;&nbsp;
                              </div>
                            ) : (
                              <div
                                className="hover:text-azulOscuro hover:underline cursor-pointer"
                                onClick={() => changePage(page)}
                              >
                                &nbsp;{page}&nbsp;
                              </div>
                            )}
                          </>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {pagination.totalPages - 3 < pagination.currentPage && (
                      <>
                        {pagination.pageNumbers
                          ?.slice(
                            pagination.totalPages - 4,
                            pagination.totalPages
                          )
                          ?.map((page: number) => {
                            return (
                              <>
                                {page === pagination.currentPage ? (
                                  <div
                                    className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                    onClick={() => changePage(page)}
                                  >
                                    &nbsp;&nbsp;{page}
                                    &nbsp;&nbsp;
                                  </div>
                                ) : (
                                  <div
                                    className="hover:text-azulOscuro hover:underline cursor-pointer"
                                    onClick={() => changePage(page)}
                                  >
                                    &nbsp;{page}&nbsp;
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <button
            className={`flex items-center gap-3 ${
              pagination.currentPage == pagination.totalPages
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }  `}
            onClick={() => goToNextPage()}
            disabled={pagination.currentPage == pagination.totalPages}
          >
            <p
              className={`text-sm font-medium ${
                pagination.currentPage == pagination.totalPages
                  ? "text-slate-400 cursor-not-allowed"
                  : "text-azulBonico hover:text-azulOscuro"
              } `}
            >
              {t("employer.alljobs.next.right.Text")}
            </p>
            <ArrowRight
              className="h-5 "
              fill={`${
                pagination.currentPage == pagination.totalPages
                  ? "rgb(148 163 184)"
                  : "#2abeeb"
              } `}
            />
          </button>
          <button
            className={`hidden lg:block text-sm font-medium cursor-pointer  ${
              pagination.currentPage == pagination.totalPages
                ? "text-slate-400 cursor-not-allowed"
                : "text-azulBonico hover:text-azulOscuro"
            } `}
            onClick={() => goToLastPage()}
            disabled={pagination.currentPage == pagination.totalPages}
          >
            {t("employer.alljobs.last.right.Text")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
