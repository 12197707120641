import React, { Dispatch, SetStateAction } from "react";

import JobCards from "../WorkerDashboard/JobCards/JobCards";

import { ReactComponent as Preferences } from "../../assets/img/preferences.svg";
import { useTranslation } from "react-i18next";
import { OffersTypes } from "src/types/types";

interface SavedJobsProps {
  offers: OffersTypes[];
  setOffers: Dispatch<SetStateAction<OffersTypes[]>>;
  updateSaved: boolean;
  setUpdateSaved: any;
  savedJobsCounter: number;
  setSavedJobsCounter: any;
  setUpdateApplied: any;
  updateApplied: boolean;
  updatedApplied?: boolean;
  refetchSavedJobs: () => void;
  loadingSavedJobs: boolean;
}

function SavedJobs({
  offers,
  setOffers,
  updateSaved,
  setUpdateSaved,
  savedJobsCounter,
  setSavedJobsCounter,
  setUpdateApplied,
  updatedApplied,
  refetchSavedJobs,
  loadingSavedJobs,
}: SavedJobsProps) {
  const { t } = useTranslation();

  if (offers?.length === 0)
    return (
      <div className="py-12 flex flex-col justify-center m-auto bg-white shadow rounded-b">
        <Preferences className="h-[150px] m-auto" />
        <p className="text-xl font-semibold tracking-wide mx-24 mt-6 text-center">
          {t("worker.job_search.saved_jobs_empty")}
        </p>
      </div>
    );
  return (
    <div className="rounded-b  w-full bg-white shadow dark:bg-background dark:text-whiteish">
      <JobCards
        loading={loadingSavedJobs}
        refetchSavedJobs={refetchSavedJobs}
        offers={offers}
        setOffers={setOffers}
        updateSaved={updateSaved}
        setUpdateSaved={setUpdateSaved}
        savedJobsCounter={savedJobsCounter?.toString()}
        setSavedJobsCounter={setSavedJobsCounter}
        setUpdateApplied={setUpdateApplied}
        updateApplied={updatedApplied!}
      />
    </div>
  );
}

export default SavedJobs;
