/* eslint-disable @typescript-eslint/ban-ts-comment */
export const testResponse = [
  {
    job_id: "100",
    job_time: [
      {
        date: "2023-12-04",
        hour_from: "15:00:00",
        hour_to: "18:30:00",
      },
      {
        date: "2023-12-05",
        hour_from: "15:00:00",
        hour_to: "18:30:00",
      },
      { date: "2023-12-06", hour_from: "15:00:00", hour_to: "18:30:00" },
      {
        date: "2023-12-07",
        hour_from: "15:00:00",
        hour_to: "18:30:00",
      },
      {
        date: "2023-12-08",
        hour_from: "15:00:00",
        hour_to: "18:30:00",
      },
    ],
  },
  {
    job_id: "99",
    job_time: [
      {
        date: "2023-12-04",
        hour_from: "09:00:00",
        hour_to: "13:30:00",
      },
      {
        date: "2023-12-08",
        hour_from: "09:00:00",
        hour_to: "13:30:00",
      },
    ],
  },
];

function useCalendar() {
  const availabilityData = {};

  for (const job of testResponse) {
    const jobId = job.job_id;
    for (const jobTime of job.job_time) {
      const date = jobTime.date;
      const hourFrom = jobTime.hour_from.slice(0, -3);
      const hourTo = jobTime.hour_to.slice(0, -3);

      //@ts-ignore
      if (!availabilityData[date]) {
        //@ts-ignore
        availabilityData[date] = {};
      }
      const existingTimeRange = `${hourFrom}-${hourTo}`;
      //@ts-ignore
      if (availabilityData[date][existingTimeRange]) continue;

      //@ts-ignore
      availabilityData[date][existingTimeRange] = {
        availability: true,
        jobId,
      };
    }
  }
  for (const date in availabilityData) {
    for (let hour = 0; hour < 24; hour++) {
      const hourFrom = `${hour < 10 ? "0" : ""}${hour}:00`;
      const hourTo = `${hour < 8 ? "0" : ""}${hour + 1}:00`;

      const existingTimeRange = `${hourFrom}-${hourTo}`;
      //@ts-ignore
      if (availabilityData[date][existingTimeRange]) continue;

      //@ts-ignore
      availabilityData[date][existingTimeRange] = {
        availability: false,
      };
    }
  }

  const converRangesIntoHourly = (dayHours: any) => {
    const transformedAvailabilityData = dayHours.map((availableItem: any) => {
      const [hourFrom, hourTo] = Object.keys(availableItem)[0].split("-");
      //@ts-ignore
      const availability = Object.values(availableItem)[0]!.availability;

      if (availability) {
        let currentHour = parseInt(hourFrom);

        const hourlyRanges = [];
        while (currentHour < parseInt(hourTo)) {
          const hourRange = {
            hourFrom: `${currentHour.toString().padStart(2, "0")}:00`,
            hourTo: `${(currentHour + 1).toString().padStart(2, "0")}:00`,
            availability,
          };

          hourlyRanges.push(hourRange);
          currentHour += 1;
        }
        availableItem[hourFrom + "-" + hourTo] = {
          availability: true,
          hourlyRanges,
        };
      }

      return availableItem;
    });

    return transformedAvailabilityData;
  };

  const convertObjectIntoArrayOfObjects = (inputData: any) => {
    const outputData = Object.entries(inputData).map(([key, value]) => ({
      [key]: value,
    }));

    return outputData;
  };
  const updateFormatting = (objects: any) => {
    const convertedObjects = [];
    for (const obj of objects) {
      const timeRange = Object.keys(obj)[0];
      const availability = obj[timeRange].availability;

      if (timeRange) {
        const timeRangeParts = timeRange.split("-");
        const hourFrom = timeRangeParts[0];
        const hourTo = timeRangeParts[1];

        obj.hourFrom = hourFrom;
        obj.hourTo = hourTo;
      }
      obj.availability = availability;
      if (!obj[timeRange].hourlyRanges) {
        delete obj[timeRange];
      }

      if (obj[timeRange]?.hourlyRanges) {
        for (const hourlyRange of obj[timeRange]!.hourlyRanges) {
          const newHourlyRange = {
            hourFrom: hourlyRange.hourFrom,
            hourTo: hourlyRange.hourTo,
            availability: hourlyRange.availability,
          };

          convertedObjects.push(newHourlyRange);
        }
      }
    }

    return [objects, convertedObjects];
  };

  function itHasHourlyRanges(objects: any) {
    const objectsNotDuplicated = [];

    for (const obj of objects) {
      const hourFrom = obj.hourFrom;
      const timeRange = Object.keys(obj)[0];
      if (obj[timeRange].hourlyRanges) {
      } else {
        objectsNotDuplicated.push(obj);
      }
    }
    return objectsNotDuplicated;
  }

  function sortByTime(objects: any) {
    const sorted = objects.sort(
      (a: { hourFrom: string }, b: { hourFrom: string }) => {
        const timeA = a.hourFrom.split(":")[0];
        const timeB = b.hourFrom.split(":")[0];

        return parseInt(timeA) - parseInt(timeB);
      }
    );

    return sorted;
  }

  function removeDuplicates(objects: any) {
    const filteredData = [];
    const uniqueData = [];
    const seenHourFromsWithAvailability: any = {};
    const sorted = objects.sort(
      (a: { hourFrom: string }, b: { hourFrom: string }) => {
        const timeA = a.hourFrom.split(":")[0];
        const timeB = b.hourFrom.split(":")[0];

        return parseInt(timeA) - parseInt(timeB);
      }
    );

    for (let i = 1; i < sorted.length; i++) {
      const current = sorted[i].hourTo;
      const prev = sorted[i - 1].hourTo;
      if (prev === current) {
        filteredData.push(sorted[i]);
      }
    }

    const sortedData = sorted.sort(
      (a: { availability: boolean }, b: { availability: boolean }) => {
        if (a.availability === true && b.availability === false) {
          return -1;
        } else if (a.availability === false && b.availability === true) {
          return 1;
        } else {
          return 0;
        }
      }
    );

    for (const obj of sortedData) {
      const hourFrom = obj.hourFrom;
      if (!seenHourFromsWithAvailability[hourFrom]) {
        uniqueData.push(obj);
        seenHourFromsWithAvailability[hourFrom] = true;
      }
    }

    const sortedUniqueData = sortByTime(uniqueData);

    return sortedUniqueData;
  }

  return {
    convertObjectIntoArrayOfObjects,
    converRangesIntoHourly,
    updateFormatting,
    itHasHourlyRanges,
    removeDuplicates,
    availabilityData,
  };
}

export default useCalendar;
