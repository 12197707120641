import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";

import exclamation from "../../../assets/img/exclamation.png";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const currencyCode = [{ value: "EUR", label: "EUR" }];
const salaryWay = [
  { value: "Neto", label: "Neto", logic: "1" },
  { value: "Bruto", label: "Bruto", logic: "0" },
];

function AddBudget() {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [validation, setValidation] = useState(false);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const [salaryCurrencyCode, setSalaryCurrencyCode] = useState("AUD");
  const [netPay, setNetPay] = useState("0");

  const navigate = useNavigate();

  useEffect(() => {
    setUserInfo((prev: any) => ({ ...prev, budget: 0 }));
  }, []);
  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("currency_code", "EUR");
  data.append("netpay", netPay);
  const saveBudget = () => {
    data.append("budget", userInfo.budget);
    fetch(`${BASE_URL}worker.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const onNextClick = () => {
    saveBudget();
    navigate("/create-profile/schedule");
  };

  function isNumber(str: any) {
    if (str?.trim() === "") {
      return false;
    }
    return !isNaN(str);
  }

  const handleBudget = (e: any) => {
    setUserInfo((info: any) => ({ ...info, budget: e.target.value }));
    if (!isNumber(e.target.value)) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      width: "90px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
    }),
  };

  return (
    <>
      <div className=" h-screen w-full !bg-white ">
        <RegistrationHeader />
        <div className="max-w-2xl pt-12 mx-auto min-h-[75vh] px-10 lg:px-0">
          <div className="text-xl lg:text-4xl font-bold">
            {" "}
            {t("createprofile.budget.title")}
          </div>
          <p className="pt-8 text-sm text-[#6e7081]">
            {t("createprofile.budget.subtitle")}
          </p>{" "}
          <div className="flex lg:flex-row flex-col justify-between mt-12">
            <div className="flex flex-col text-sm">
              <p className="font-bold tracking-wide">
                {t("createprofile.budget.subTitle2")}
              </p>
              <p>{t("createprofile.budget.subTitle2.text")}</p>
            </div>
            <div className="flex gap-5">
              {userInfo && (
                <div className="relative">
                  <input
                    value={userInfo?.budget && userInfo?.budget}
                    className="border h-10 text-right pr-4 text-sm outline-none rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 "
                    maxLength={8}
                    placeholder="0"
                    onChange={(e) => handleBudget(e)}
                  />
                  {validation === true && (
                    <div className="wrong-container flex items-center gap-2 text-sm mt-2">
                      <AiOutlineExclamationCircle size={17} color="red" />

                      {t("modal_edit.enter_valid")}
                    </div>
                  )}
                </div>
              )}
              <div className="hidden lg:inline-flex">
                <Select
                  options={currencyCode}
                  styles={colourStyles}
                  isDisabled={true}
                  defaultValue={{
                    label: "EUR",
                    value: "EUR",
                  }}
                  onChange={(e) => setSalaryCurrencyCode(e!.value)}
                  className="text-sm"
                />
                <Select
                  options={salaryWay}
                  styles={colourStyles}
                  // isDisabled={true}
                  defaultValue={{
                    label: "Bruto",
                    value: "Bruto",
                  }}
                  onChange={(e: any) => setNetPay(e.logic)}
                  className="text-sm"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={null}>
          <ProgressRegistration
            onNextClick={onNextClick}
            backButton={"create-profile/bio"}
            nextButtonText={t("createprofile.budget.rightBtn")}
            // whereTo={""}
            progress="92"
          />
        </Suspense>
      </div>
    </>
  );
}

export default AddBudget;
