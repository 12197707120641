import React from "react";

import { Skeleton } from "../skeleton";

export const WorkerCardSkeleton = () => {
  return (
    <div className="py-8 px-2 w-full">
      <div className="flex items-start pr-10 relative">
        <Skeleton className="h-24 w-24 rounded-full ml-6 mr-4" />
        <div className="flex flex-col items-start flex-grow">
          <Skeleton className="h-5 w-[20%] ml-4 " />
          <div className="flex  flex-grow items-center w-full">
            <Skeleton className="h-3 w-[10%] ml-4 mr-10 mt-4" />
            <Skeleton className="h-3 w-[20%] ml-4 mt-4 " />
          </div>
          <Skeleton className="h-4 w-1/6 ml-4 my-4" />
          <div className="flex items-center w-full ml-4 gap-4">
            <Skeleton className="h-5 w-[30%] mb-4" />
            <Skeleton className="h-5 w-[30%] mb-4" />
            <Skeleton className="h-5 w-[30%] mb-4" />
          </div>
          <div className="flex items-center">
            <Skeleton className="h-8 w-10  ml-4" />
            <Skeleton className="h-8 w-10  ml-4" />
            <Skeleton className="h-8 w-10  ml-4" />
          </div>
        </div>
        <div className="absolute top-2 right-10 flex items-center space-x-4">
          <Skeleton className="h-[26px] w-[26px] rounded-full" />
          <Skeleton className="h-[26px] w-[26px] rounded-full" />
          <Skeleton className="h-[26px] w-28 rounded-full" />
          <Skeleton className="h-[26px] w-40 rounded-full" />
        </div>
      </div>
    </div>
  );
};
