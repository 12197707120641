import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import Badge from "../../assets/img/badge.png";
import { BASE_URL, GIFT_ID } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useQuery } from "react-query";
import Flexicoin from "src/assets/img/flexicoin-nobg.png";
import Welcome from "src/assets/img/welcome.png";
import { isCompany } from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { BiCheck, BiLoader } from "react-icons/bi";
import { cn } from "src/lib/utils";
import Confetti from "react-confetti";
import useIncreaseCredits from "src/Hooks/uesIncreaseCredits";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

interface ModalCongratulationsGiftProps {
  showCongratulationsGift: boolean;
  setShowCongratulationsGift: Dispatch<SetStateAction<boolean>>;
}
const ModalCongratulationsGift: React.FC<ModalCongratulationsGiftProps> = ({
  setShowCongratulationsGift,
  showCongratulationsGift,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser");
  const [showWelcome, setShowWelcome] = useState(false);
  const [giftCredits, setGiftCredits] = useState("");
  const [loadingClaimGift, setLoadingClaimGift] = useState(false);
  const [showSuccessfullyClaimed, setShowSuccessfullyClaimed] = useState(false);
  const [isUserCompany, setIsUserCompany] = useAtom(isCompany);
  const { increaseCredits } = useIncreaseCredits(Number(giftCredits), "12");
  const { t } = useTranslation();

  const getActionTypes = async () => {
    const data = await fetch(`${BASE_URL}actions`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const { data: actionsData, refetch } = useQuery(["actions"], getActionTypes);

  const [transition, setTransition] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  useEffect(() => {
    setGiftCredits(
      actionsData?.actions.filter(
        (param: { id: string; name: string; value: string }) =>
          param.name === "Welcome Gift"
      )[0].credits
    );
  }, [actionsData]);

  useEffect(() => {
    if (showSuccessfullyClaimed) {
      setLoadingClaimGift(false);
    }
  }, [showSuccessfullyClaimed]);

  useEffect(() => {
    if (showSuccessfullyClaimed) {
      setTimeout(() => {
        setShowCongratulationsGift(false);
        window.location.reload();
      }, 3000);
    }
  }, [showSuccessfullyClaimed]);

  return (
    <Modal
      style={StylesModal}
      isOpen={showCongratulationsGift}
      ariaHideApp={false}
    >
      <div
        className={`fixed z-50 ${
          transition === false ? "opacity-40 scale-0" : "opacity-100 scale-100"
        } transition duration-150 w-[90%] lg:w-[700px] max-w-auto max-h-auto left-[50%] shadow-xl translate-x-[-50%] rounded-xl top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        {!showWelcome ? (
          <div>
            <div className="bg-azulBonico rounded-t-xl relative">
              <img
                src={Welcome}
                className="w-40 h-40 object-cover rounded-xl mr-auto ml-10"
              />
              <img
                src={Flexicoin}
                className="h-24 object-cover -bottom-10 absolute right-10 
              "
              />
              <div
                onClick={() => setShowCongratulationsGift(false)}
                className="h-8 w-8 rounded-full bg-gray-100 absolute top-4 right-4 flex items-center justify-center cursor-pointer"
              >
                <IoClose className="h-5 w-5 text-gray-500" />
              </div>
            </div>
            <div className="h-40 relative py-4">
              <div className="px-10">
                <h1 className="text-xl font-medium">
                  {t("employer.modal.congratulations.gift.title")}
                </h1>
                <p className="text-sm text-gray-500 font-medium py-2">
                  {t("employer.modal.congratulations.gift.desc")}
                </p>
              </div>
            </div>
            <div className="border-t border-border py-2">
              <div className="w-full flex items-center justify-end px-6">
                <button
                  onClick={() => setShowWelcome(true)}
                  className="text-white px-4 py-2 rounded bg-azulBonico ml-auto text-sm font-medium "
                >
                  {t("employer.modal.congratulations.gift.button")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {showSuccessfullyClaimed && (
              <Confetti
                numberOfPieces={300}
                gravity={0.3}
                initialVelocityY={150}
                initialVelocityX={10}
              />
            )}
            <div className="items-center flex flex-col">
              <img src={Badge} className="w-96 object-cover" />
            </div>
            <div className="flex flex-col items-center p-4 space-y-6">
              <h1 className="text-3xl font-bold">
                {t("employer.modal.congratulations.gift.received_title")}
              </h1>
              <p className="text-gray-400 font-medium">
                {t("employer.modal.congratulations.gift.received_desc_pt1")}{" "}
                {giftCredits}{" "}
                {t("employer.modal.congratulations.gift.received_desc_pt2")}
              </p>
              <button
                onClick={() => {
                  setLoadingClaimGift(true);
                  increaseCredits();
                  setTimeout(() => {
                    setLoadingClaimGift(false);
                    setShowSuccessfullyClaimed(true);
                  }, 2000);
                }}
                className={cn(
                  "bg-azulBonico text-white px-4 rounded py-2 w-40 flex items-center justify-center ",
                  showSuccessfullyClaimed && "bg-green-500 "
                )}
              >
                {loadingClaimGift ? (
                  <BiLoader className="h-5 w-5 animate-spin" />
                ) : (
                  <>
                    {showSuccessfullyClaimed ? (
                      <BiCheck className="h-5 w-5 " />
                    ) : (
                      <p className="text-sm  text-center font-medium ">
                        {t(
                          "employer.modal.congratulations.gift.received_button"
                        )}
                      </p>
                    )}
                  </>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModalCongratulationsGift;
