import React, { useState, lazy, Suspense } from "react";
import { useAtom } from "jotai";
import { v4 as uniqueID } from "uuid";

import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { ModalJobCard } from "../../Modals/ModalJobCard";
import { BASE_URL } from "../../../Config/api.config";
import { counterApplied, loadingJob } from "../../../Atoms/jotaiAtoms";
import { ModalInappropriateJob } from "../../Modals/ModalInappropriateJob";
import { ModalAddCoverLetter } from "../../Modals/ModalAddCoverLetter";

import { JobTypes, OffersTypes } from "../../../types/types";
const JobCardDiff = lazy(() => import("./JobCardDiff"));
const JobCardSkeleton = lazy(
  () => import("src/Components/ui/skeleton/JobCardSkeleton")
);

interface JobCardsProps {
  offers: OffersTypes[];
  setOffers: any;
  updateSaved: boolean;
  setUpdateSaved: any;
  savedJobsCounter: string;
  setSavedJobsCounter: any;
  setUpdateApplied: any;
  updateApplied: boolean;
  loading: boolean;
  refetchSavedJobs: () => void;
}

function JobCards({
  offers,
  setOffers,
  updateSaved,
  setUpdateSaved,
  savedJobsCounter,
  setSavedJobsCounter,
  setUpdateApplied,
  updateApplied,
  loading,
  refetchSavedJobs,
}: JobCardsProps) {
  const [clickedJob, setClickedJob] = useState<any>();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [appliedJobsCounter, setAppliedJobsCounter] = useAtom(counterApplied);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [showNotInterested, setShowNotInterested] = useState(false);
  const [isApplied, setIsApplied] = useState<string | boolean>();
  const [alert, setAlert] = useState("");
  const unique_id = uniqueID();
  const [showModal, setShowModal] = useState({
    job: false,
    report: false,
    cover: false,
  });

  //Save
  const handleSaveJob = (job: JobTypes, e: any) => {
    e.stopPropagation();
    setOffers((current: JobTypes[]) =>
      current?.map((obj: JobTypes) => {
        if (obj.job_id === job.job_id) {
          if (job?.saved === "true" || job?.saved === true) {
            setSavedJobsCounter(Number(savedJobsCounter) - 1);
            return { ...obj, saved: false };
          } else if (job?.saved === "false" || job?.saved === false) {
            setSavedJobsCounter(Number(savedJobsCounter) + 1);
            return { ...obj, saved: true };
          }
        }
        return obj;
      })
    );
  };
  const saveData = new FormData();
  saveData.append("_method", "POST");
  saveData.append("client_id", userID);

  const saveJob = (id: string) => {
    saveData.append("job_id", id);
    fetch(`${BASE_URL}worker.saved.jobs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: saveData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.jobSaved === "ok") {
          refetchSavedJobs();
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteSavedJob = (id: string) => {
    fetch(`${BASE_URL}worker.saved.jobs?client_id=${userID}&job_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.deleteSavedJob === "ok") {
          refetchSavedJobs();
        }
      })
      .catch((err) => console.log(err));
  };

  const saveItOrNot = (job: JobTypes) => {
    setUpdateSaved(!updateSaved);
    if (job.saved === "true" || job.saved === true) {
      deleteSavedJob(job.job_id);
    }
    if (job.saved === "false" || job.saved === false) {
      saveJob(job.job_id);
    }
  };

  //Apply

  const handleApplyJob = (job: JobTypes) => {
    setOffers((current: JobTypes[]) =>
      current?.map((obj) => {
        if (obj.job_id === job.job_id) {
          if (job?.applied === "true" || job?.applied === "true") {
            setAppliedJobsCounter(appliedJobsCounter - 1);
            return { ...obj, applied: false };
          } else if (job?.applied === "false" || job?.applied === "false") {
            setAppliedJobsCounter(appliedJobsCounter + 1);

            return { ...obj, applied: true };
          }
        }
        return obj;
      })
    );
  };
  const applyData = new FormData();
  applyData.append("_method", "POST");
  applyData.append("client_id", userID);

  const applyJob = (id: string, coverLetter: string) => {
    applyData.append("job_id", id);
    applyData.append("cover_letter", coverLetter);
    fetch(`${BASE_URL}worker.applied.jobs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: applyData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getNumberOfAppliedJobs();
          setClickedJob((prev: any) => ({ ...prev, applied: true }));
          handleApplyJob(clickedJob);
          setIsApplied(true);
          setUpdateApplied(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setAlert("ERROR: " + data.result.error_msg);
        }
      })
      .catch((err) => console.log(err));
  };

  const cancelApplyJob = (id: string) => {
    fetch(`${BASE_URL}worker.applied.jobs?client_id=${userID}&job_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
  };

  const getNumberOfAppliedJobs = () => {
    fetch(`${BASE_URL}worker.applied.jobs?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAppliedJobsCounter(parseInt(data.result.numberOfAppliedJobs));
      })
      .catch((err) => console.log(err));
  };

  const toApplyOrNotToApply = (job: JobTypes, coverLetter: string) => {
    setUpdateApplied(!updateApplied);
    if (job.applied === "false") {
      applyJob(job.job_id, coverLetter);
    } else if (job.applied === "true") {
      cancelApplyJob(job.job_id);
    }
  };

  return (
    <Suspense fallback={null}>
      {offers?.length > 0 &&
        offers?.map((job: JobTypes) => {
          if (!loading) {
            return (
              <JobCardDiff
                key={job.job_id}
                clickedJob={clickedJob}
                handleSaveJob={handleSaveJob}
                job={job}
                saveItOrNot={saveItOrNot}
                setClickedJob={setClickedJob}
                setOffers={setOffers}
                setShowModal={setShowModal}
                setShowNotInterested={setShowNotInterested}
                showNotInterested={showNotInterested}
                unique_id={unique_id}
                loading={loading}
              />
            );
          } else {
            return <JobCardSkeleton key={job.job_id} />;
          }
        })}
      {showModal.job && (
        <ModalJobCard
          showModal={showModal}
          setShowModal={setShowModal}
          job={clickedJob}
          setJob={setClickedJob}
          saveItOrNot={saveItOrNot}
          handleSaveJob={handleSaveJob}
          setIsApplied={setIsApplied}
          isApplied={isApplied}
          setAlert={setAlert}
        />
      )}
      {showModal.report && (
        <ModalInappropriateJob
          setShowModal={setShowModal}
          showModal={showModal}
          job={clickedJob}
          setJob={setClickedJob}
          offers={offers}
          setOffers={setOffers}
        />
      )}
      {showModal.cover && (
        <ModalAddCoverLetter
          showModal={showModal}
          setShowModal={setShowModal}
          toApplyOrNotToApply={toApplyOrNotToApply}
          job={clickedJob}
        />
      )}
    </Suspense>
  );
}

export default JobCards;
