import React, { useRef, useState } from "react";
import { Button } from "../ui/Button";
import { useModalStore } from "src/Hooks/useModalStore";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { selectedWorkerId, workerProfile } from "src/Atoms/jotaiAtoms";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { BASE_URL, EMPLOYER_DISCOVER_PROFILE } from "src/Config/api.config";
import FlexiCoin from "src/assets/img/flexicoin.png";
import { useEmployerStore } from "src/Routing/useProfileStore";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { cn } from "src/lib/utils";
import { useTranslation } from "react-i18next";
import { getCreditsTypes } from "src/Routing/useCreditsActions";

const ModalInviteWorker = () => {
  const [showModalInvite, setShowModalInvite] = useModalStore((state) => [
    state.showModalInvite,
    state.setShowModalInvite,
  ]);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const { t } = useTranslation();

  const modalRef = useRef<HTMLDivElement>(null);
  const [sendWithNote, setSendWithNote] = useState(false);
  const [workerId, setWorkerId] = useAtom(selectedWorkerId);
  const params = useParams();
  const { inviteJob } = getCreditsTypes();
  const { updateCredits } = useUpdateCredits(inviteJob?.credits, inviteJob?.id);
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);

  const handleClose = () => setShowModalInvite(showModalInvite);

  const navigate = useNavigate();
  const [workerProf, setWorkerProf] = useAtom(workerProfile);

  const discoverProfileData = new FormData();

  discoverProfileData.append("_method", "POST");
  discoverProfileData.append("client_id_employer", userID);
  discoverProfileData.append("client_id_worker", workerId);

  const discoverProfile = async () => {
    const data = await fetch(`${BASE_URL}${EMPLOYER_DISCOVER_PROFILE}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: discoverProfileData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="fixed top-0 right-0 inset-0 w-full h-full bg-black/50 text-black z-50"
      ref={modalRef}
    >
      <div className="fixed  z-[50] lg:w-[500px] w-[90%] left-[50%] transition shadow-xl translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-[15px] lg:text-lg font-medium">
            {t(
              "employer.findworkers.body.rightcard.viewprofile.dot_modal.title"
            )}
          </h1>
          <Button
            onClick={() => handleClose()}
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-6">
          {t(
            "employer.findworkers.body.rightcard.viewprofile.dot_modal.subtitle_profile"
          )}
        </h3>

        {!sendWithNote ? (
          <div className=" flex items-center justify-between w-full gap-4 px-8 py-2">
            <div className="flex items-center gap-4 justify-end ml-auto">
              {window.location.pathname !== `/find-worker/${workerId}` && (
                <Button
                  disabled={Number(creditsNumber) < Number(inviteJob.credits)}
                  onClick={() => {
                    if (Number(creditsNumber) > Number(inviteJob.credits)) {
                      if (workerProf.data.profile_discovered === "0") {
                        updateCredits();
                        discoverProfile();
                      }
                      navigate(`/find-worker/${workerId}`);
                      setShowModalInvite(showModalInvite);
                    }
                  }}
                  className={cn(
                    "bg-none text-[0.9rem]  h-10 border rounded-full px-6 py-1 group disabled:bg-gray-200 disabled:text-[#333] disabled:cursor-not-allowed",
                    workerProf.data.profile_discovered === "1" &&
                      "bg-azulBonico text-white"
                  )}
                >
                  {t(
                    "employer.findworkers.body.rightcard.viewprofile.dot_modal.rightBtn"
                  )}
                  {workerProf.data.profile_discovered === "0" && (
                    <div className="absolute bottom-16 scale-0 bg-gray-100 right-16 rounded px-2 group-hover:scale-100 transition">
                      <div className="flex items-center gap-2">
                        <div>{Number(inviteJob.credits)}</div>
                        <img src={FlexiCoin} className="h-4 w-4 " />
                      </div>
                    </div>
                  )}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="border-t border-border flex items-center justify-end w-full gap-4 px-8 py-2">
            <Button
              onClick={() => setSendWithNote(false)}
              className="bg-none text-[0.9rem] w-24 h-10 border text-azulBonico rounded mt-1"
            >
              {t("employer.modal.invite_worker.cancel")}
            </Button>
            <Button className="bg-none text-[0.9rem] w-24 h-10 border bg-azulBonico text-white rounded   mt-1 ">
              {t("employer.modal.invite_worker.send")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalInviteWorker;
