import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";

import { ReactComponent as Addition } from "../../../assets/img/plus.svg";
import exclamation from "../../../assets/img/exclamation.png";
import trash from "../../../assets/img/trash-can.png";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { LanguageType } from "src/types/types";
import useWindowDimensions from "src/Hooks/useWindowDimensions";
import { allLevels } from "src/data/levels";

const INITIAL_LANGUAGE = {
  language: "",
  level: "",
};
function AddIdiomas() {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);
  const [language, setLanguage] = useState<{ language: string; level: string }>(
    INITIAL_LANGUAGE
  );
  const [englishLevel, setEnglishLevel] = useState({
    language: "English",
    level: "",
  });
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [allLanguages, setAllLanguages] = useState<any>([]);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [allLanguagesAvailable, setAllLanguagesAvailable] = useState<any>([]);
  const [allLevelsAvailable, setAllLevelsAvailable] = useState<any>([]);
  const [auxiliarList, setAuxiliarList] = useState<any>([]);
  const [checkingEnglish, setCheckingEnglish] = useState(false);
  const [indexes, setIndexes] = useState([]);
  const [auxiliarIndex, setAuxiliarIndex] = useState(-1);
  const [auxiliarCounter, setAuxiliarCounter] = useState(-1);
  const [knownLanguages, setKnownLanguages] = useState<any>([]);
  const [added, isAdded] = useState(false);
  const [receivedLanguages, setReceivedLanguages] = useState<any>();

  const windowDimensions = useWindowDimensions();

  console.log(windowDimensions);

  const proficiency = [
    {
      value: "Basic",
      label: t("createprofile.language.table.rightBtn.textPlaceholder.text1"),
    },
    {
      value: "Conversational",
      label: t("createprofile.language.table.rightBtn.textPlaceholder.text2"),
    },
    {
      value: "Fluent",
      label: t("createprofile.language.table.rightBtn.textPlaceholder.text3"),
    },
    {
      value: "Native or Bilingual",
      label: t("createprofile.language.table.rightBtn.textPlaceholder.text4"),
    },
  ];

  const navigate = useNavigate();
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  const getAllLanguages = () => {
    fetch(`${BASE_URL}languages?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllLanguagesAvailable(data.result))
      .catch((err) => console.log(err));
  };

  const getAllLevels = () => {
    fetch(`${BASE_URL}language.level`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllLevelsAvailable(data.result))
      .catch((err) => console.log(err));
  };

  const getWorkerLanguages = () => {
    fetch(`${BASE_URL}worker.language?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setReceivedLanguages(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getWorkerLanguages();
    getAllLanguages();
    getAllLevels();
  }, []);

  const availableLanguages = allLanguagesAvailable?.map(function (row: {
    name: string;
  }) {
    return { value: row.name, label: row.name };
  });

  const handleAddDiv = () => {
    setCounter(counter + 1);
    setAuxiliarIndex(auxiliarIndex + 1);
    setAuxiliarCounter(auxiliarCounter + 1);
    if (language?.language && language?.level) {
      setAllLanguages((current: any) => [...current, language]);
      setAuxiliarList((current: any) => [...current, language]);
      setLanguage(INITIAL_LANGUAGE);
    } else if (englishLevel?.level) {
      setAllLanguages((current: any) => [...current, englishLevel]);
      setAuxiliarList((current: any) => [...current, englishLevel]);
    }
  };
  const handleDeleteReceivedOnes = (idx: number) => {
    setReceivedLanguages(
      receivedLanguages.filter((_: any, i: any) => i !== idx)
    );
  };

  const removeNode = (idx: number) => {
    if (counter - 1 === idx) {
      setLanguage(INITIAL_LANGUAGE);
    }
    if (allLanguages) {
      setAllLanguages(allLanguages.filter((_: any, i: any) => i !== idx));
    }
    document.getElementById(`id-${idx}`)!.remove();
  };

  const onNextClick = () => {
    isAdded(false);
    handleAlphaCode(language);
    for (let i = 0; i < allLanguages.length; i++) {
      handleAlphaCode(allLanguages[i]);
    }
    isAdded(true);
    setTimeout(() => {
      navigate("/create-profile/bio");
    }, 300);
  };
  useEffect(() => {
    if (knownLanguages.length !== 0) {
      if (receivedLanguages?.length > 1) {
        if (englishLevel.level) {
          data.append(
            "language_code_list",
            [
              knownLanguages?.map(
                (language: LanguageType) => language.language_code
              ),
              receivedLanguages
                ?.slice(0, receivedLanguages.length - 1)
                ?.map((language: LanguageType) => language.language_code),
              "EN",
            ].toString()
          );
          data.append(
            "language_level_id_list",
            [
              knownLanguages?.map(
                (language: { level_id: string }) => language.level_id
              ),
              receivedLanguages
                ?.slice(0, receivedLanguages.length - 1)
                ?.map((language: LanguageType) => language.language_level_id),
              level(englishLevel),
            ].toString()
          );
        } else {
          data.append(
            "language_code_list",
            [
              knownLanguages?.map(
                (language: LanguageType) => language.language_code
              ),
              receivedLanguages?.map(
                (language: LanguageType) => language.language_code
              ),
            ].toString()
          );
          data.append(
            "language_level_id_list",
            [
              knownLanguages?.map(
                (language: { level_id: string }) => language.level_id
              ),
              receivedLanguages?.map(
                (language: { language_level_id: string }) =>
                  language.language_level_id
              ),
            ].toString()
          );
        }
      } else if (receivedLanguages?.length === 1) {
        data.append(
          "language_code_list",
          [
            knownLanguages?.map(
              (language: LanguageType) => language.language_code
            ),
            receivedLanguages[0].language_code,
          ].toString()
        );
        if (englishLevel.level) {
          data.append(
            "language_level_id_list",
            [
              knownLanguages?.map(
                (language: { level_id: string }) => language.level_id
              ),
              level(englishLevel),
            ].toString()
          );
        } else {
          data.append(
            "language_level_id_list",
            [
              knownLanguages?.map(
                (language: { level_id: string }) => language.level_id
              ),
              receivedLanguages[0].language_level_id,
            ].toString()
          );
        }
      } else {
        if (receivedLanguages?.length === 0) {
          data.append(
            "language_code_list",
            [
              knownLanguages?.map(
                (language: LanguageType) => language.language_code
              ),
            ].toString()
          );
          data.append(
            "language_level_id_list",
            [
              knownLanguages?.map(
                (language: { level_id: string }) => language.level_id
              ),
            ].toString()
          );
        } else {
          data.append(
            "language_code_list",
            [
              knownLanguages?.map(
                (language: LanguageType) => language.language_code
              ),
              "EN",
            ].toString()
          );
          data.append(
            "language_level_id_list",
            [
              knownLanguages?.map(
                (language: { level_id: string }) => language.level_id
              ),
              level(englishLevel),
            ].toString()
          );
        }
      }
    } else if (receivedLanguages?.length > 1) {
      if (englishLevel.level) {
        data.append(
          "language_code_list",
          [
            receivedLanguages?.map(
              (language: LanguageType) => language.language_code
            ),
          ].toString()
        );
        data.append(
          "language_level_id_list",
          [
            receivedLanguages
              ?.slice(0, receivedLanguages.length - 1)
              ?.map(
                (language: { language_level_id: string }) =>
                  language.language_level_id
              ),
            level(englishLevel),
          ].toString()
        );
      } else {
        data.append(
          "language_code_list",
          [
            receivedLanguages?.map(
              (language: {
                language_code: string;
                language_level_id: string;
              }) => language.language_code
            ),
          ].toString()
        );
        data.append(
          "language_level_id_list",
          [
            receivedLanguages?.map(
              (language: LanguageType) => language.language_level_id
            ),
          ].toString()
        );
      }
    } else {
      data.append("language_code_list", "EN");
      if (englishLevel?.level) {
        data.append("language_level_id_list", level(englishLevel)!.toString());
      } else if (receivedLanguages) {
        data.append(
          "language_level_id_list",
          receivedLanguages[0]?.language_level_id
        );
      }
    }
    if (englishLevel.level || receivedLanguages) {
      handleLanguagesPost();
    }
  }, [added]);

  const level = (language: { level: string }) => {
    switch (language?.level) {
      case "Native or Bilingual":
        return 4;
      case "Fluent":
        return 3;
      case "Conversational":
        return 2;
      case "Basic":
        return 1;
    }
  };
  const handleAlphaCode = (language: { language: string; level: string }) => {
    console.log(language);
    const code = allLanguagesAvailable?.find(
      ({ name }: { name: string }) => name === language?.language
    );
    const level = (language: { level: string }) => {
      switch (language?.level) {
        case "Native or Bilingual":
          return 4;
        case "Fluent":
          return 3;
        case "Conversational":
          return 2;
        case "Basic":
          return 1;
      }
    };

    if (code !== undefined) {
      return setKnownLanguages((languages: any) => [
        ...languages,
        {
          language_code: code.alpha2_code,
          level_id: level(language),
        },
      ]);
    }
  };

  const handleLanguagesPost = () => {
    fetch(`${BASE_URL}worker.language`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      transition: "300ms",
      display: "flex",
      width: windowDimensions.width < 500 ? "100%" : "180px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  return (
    <div>
      <div className=" h-screen w-full !bg-white ">
        <RegistrationHeader />
        <div className="lg:max-w-3xl pt-12 px-10 lg:px-0 mx-auto min-h-[75vh] flex flex-col ">
          <div className="text-4xl font-bold">
            {" "}
            {t("createprofile.languages.title")}
          </div>
          <p className="pt-8 text-sm text-[#6e7081]">
            {t("createprofile.languages.subtitle")}
          </p>{" "}
          <div className="flex flex-col w-full text-sm mt-8 border-b border-blue-100">
            <div className="flex py-2 bg-[#2abeeb3d] font-bold px-4">
              <p onClick={() => console.log("allLanguages are:", allLanguages)}>
                {t("createprofile.language.table.leftBtn.headertable")}
              </p>
              <p className="ml-[45%]">
                {t("createprofile.language.table.rightBtn.headertable")}
              </p>
            </div>
            <div className="flex items-center justify-between p-4">
              <p className="flex-[0_0_47%] max-w-[47%] ml-2">
                {t("register.create_profile.worker.english")}
              </p>
              <div className="flex flex-col w-full">
                {receivedLanguages?.length > 0 ? (
                  receivedLanguages?.map(
                    (language: {
                      language_name: string;
                      languale_level_name: string;
                    }) => {
                      return (
                        <>
                          {language.language_name === "English" && (
                            <Select
                              styles={colourStyles}
                              options={proficiency}
                              placeholder={language.languale_level_name}
                              isSearchable={false}
                              onChange={(e) => {
                                setEnglishLevel((level) => ({
                                  ...level,
                                  level: e!.value,
                                }));
                                setCheckingEnglish(true);
                              }}
                              className="flex-[0_0_40%] max-w-[40%] w-max"
                            />
                          )}
                        </>
                      );
                    }
                  )
                ) : (
                  <>
                    <Select
                      options={allLevels}
                      styles={colourStyles}
                      placeholder={t(
                        "createprofile.language.body.box.secondrow.placeholderLeft"
                      )}
                      className=" w-full"
                      onChange={(e) => {
                        setEnglishLevel((level) => ({
                          ...level,
                          level: e!.value,
                        }));
                        setCheckingEnglish(true);
                      }}
                    />
                  </>
                )}
                {checkingEnglish === true && !englishLevel.level && (
                  <div className=" flex align-center text-[#9b211b] text-xs mt-4">
                    <img className="exclamation" src={exclamation} alt="hey" />{" "}
                    {t("register.create_profile.worker.english.proficiency")}
                  </div>
                )}
              </div>
              <p className="text-white flex-[0_0_20%] max-w-[20%]">.</p>
            </div>
          </div>
          {receivedLanguages?.length > 1 &&
            receivedLanguages?.map(
              (
                language: {
                  language_name: string;
                  languale_level_name: string;
                },
                idx: number
              ) => {
                return (
                  <>
                    {language.language_name !== "English" && (
                      <div
                        className="flex items-center text-sm gap-8 justify-between py-4 border-t border-blue-100"
                        key={idx}
                      >
                        <input
                          type="text"
                          value={language.language_name}
                          className=" bg-white h-10  rounded pl-6"
                          disabled={true}
                        />
                        <input
                          type="text"
                          value={language.languale_level_name}
                          className=" h-10 bg-white rounded "
                          disabled={true}
                        />
                        <img
                          src={trash}
                          alt="trash"
                          className="h-7 border cursor-pointer p-1 overflow-visible rounded-full mr-4"
                          onClick={() => handleDeleteReceivedOnes(idx)}
                        />
                      </div>
                    )}
                  </>
                );
              }
            )}
          {Array.from(Array(counter))?.map((item, idx) => (
            <div
              className="flex items-center justify-between p-4 text-sm border-b border-blue-100"
              key={idx}
              id={`id-${idx}`}
            >
              <Select
                options={availableLanguages}
                placeholder={t(
                  "createprofile.language.body.box.secondrow.placeholderLeft"
                )}
                styles={colourStyles}
                isDisabled={idx < auxiliarIndex ? true : false}
                onChange={(e: any) => {
                  setLanguage((language) => ({
                    ...language,
                    language: e.value,
                  }));
                }}
              />

              <Select
                options={proficiency}
                styles={colourStyles}
                placeholder={t(
                  "createprofile.language.body.box.secondrow.placeholderRight"
                )}
                isDisabled={idx < auxiliarIndex ? true : false}
                onChange={(e: any) => {
                  setLanguage((language) => ({
                    ...language,
                    level: e.value,
                  }));
                }}
              />
              <img
                src={trash}
                alt="trash"
                className="h-7 border cursor-pointer p-1 overflow-visible rounded-full"
                onClick={() => removeNode(idx)}
              />
            </div>
          ))}
          {(language?.language?.length > 0 && language?.level?.length > 0) ||
          allLanguages.length === 0 ? (
            <div
              className="flex items-center gap-4 mt-4 w-fit text-sm cursor-pointer"
              onClick={handleAddDiv}
            >
              <Addition fill={"#2abeeb"} className="h-4" />
              <p className="text-azulBonico hover:text-azulOscuro hover:underline">
                {t("createprofile.language.body.box.subtitle")}
              </p>
            </div>
          ) : null}
        </div>
        <ProgressRegistration
          onNextClick={onNextClick}
          disabled={
            checkingEnglish === true || receivedLanguages?.length > 0
              ? false
              : true
          }
          backButton={"create-profile/skills"}
          nextButtonText={t("createprofile.language.rightBtn")}
          // whereTo={"create-profile/bio"}
          progress="72"
        />
      </div>
    </div>
  );
}

export default AddIdiomas;
