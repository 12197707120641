import React from "react";
interface ProgressBarRegisterProps {
  bgcolor: string;
  progress: number | string;
  height: number;
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
  margintop: string;
}

const ProgressBarRegister = ({
  bgcolor,
  progress,
  height,
  color1,
  color2,
  color3,
  color4,
  margintop,
}: ProgressBarRegisterProps) => {
  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "#cfcfcfb6",
    borderRadius: 40,
    marginTop: margintop,
  };

  const progresstext = {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.7rem",
  };
  const headline = {
    color: color1,
  };
  const skills = {
    color: color2,
  };
  const scope = {
    color: color3,
  };
  const budget = {
    color: color4,
  };

  return (
    <div style={Parentdiv} className="text-[#cfcfcfb6]">
      <div
        style={{
          height: "100%",
          width: `${progress}%`,
          backgroundColor: bgcolor,
          borderRadius: 40,
          textAlign: "right",
        }}
      >
        <span></span>
      </div>
      <div style={progresstext}>
        <p style={headline}></p>
        <p style={skills}></p>
        <p style={scope}></p>
        <p style={budget}></p>
      </div>
    </div>
  );
};

export default ProgressBarRegister;
