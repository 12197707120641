import { t } from "i18next";
import React from "react";

const SubscriptionPlan = () => {
  return (
    <div className="px-4 py-2">
      <div className="bg-white rounded-lg shadow pb-6 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-8">
            <h1 className="text-2xl font-semibold mb-4">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan"
              )}
            </h1>
          </div>
          <div className="border-b px-8 py-4">
            <h1 className="font-medium text-sm lg:text-lg">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_1_Title1"
              )}
            </h1>
            <p className="text-sm text-gray-500">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_1_SubTitle1"
              )}
              {/* Manage or change your Flexihours plan. View our{" "}
              <span className="underline text-blue-500">terms of service</span>{" "}
              and{" "}
              <span className="underline text-blue-500">privacy policy</span> */}
            </p>
          </div>

          <div className="border-t px-8 py-4">
            <h1 className="font-medium text-sm lg:text-lg">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_2_Title2"
              )}
            </h1>
            <p className="text-sm text-gray-500">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_2_SubTitle2"
              )}
            </p>
          </div>
          <div className="border-t px-8 py-4">
            <h1 className="font-medium text-sm lg:text-lg">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_3_Title3"
              )}
            </h1>
            <p className="text-sm text-gray-500">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_3_SubTitle3"
              )}
            </p>
          </div>
          <div className="border-t px-8 py-4">
            <div className="flex flex-col lg:flex-row items-start justify-between">
              <div className="flex flex-col items-start">
                <h1 className="font-medium text-sm lg:text-lg">
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_4_Title4"
                  )}
                </h1>
                <p className="text-sm text-gray-500">
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_4_SubTitle4"
                  )}
                </p>
              </div>
              <h1 className="text-blue-600 text-sm lg:text-lg mt-2 lg:mt-0">
                {t(
                  "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.item_4_SubTitle4.link"
                )}
              </h1>
            </div>
          </div>
          <div className="border-t flex   px-8 py-4 justify-end">
            <div className="ml-auto flex flex-col lg:flex-row items-center gap-2">
              <div className="border px-3 py-2 text-sm font-medium border-azulOscuro rounded text-azulOscuro">
                {t(
                  "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.leftBtn"
                )}
              </div>
              <div className="border px-3 py-2 text-sm font-medium text-white bg-azulOscuro rounded ml-auto lg:ml-0">
                {t(
                  "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.plan.body.rightBtn"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
