import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, PROFILE_WORKER } from "../../Config/api.config";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { useQuery } from "react-query";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

interface ModalEditBioProps {
  setShowEditBioModal: React.Dispatch<React.SetStateAction<boolean>>;
  showEditBioModal: boolean;
  refetchData: () => void;
}

function ModalEditBio({
  setShowEditBioModal,
  showEditBioModal,
  refetchData,
}: ModalEditBioProps) {
  const { t, i18n } = useTranslation();
  const [bioWorker, setBioWorker] = useState("");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("overview", bioWorker);

  const updateBio = () => {
    fetch(`${BASE_URL}${PROFILE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowEditBioModal(false);
          refetchData();
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      })
      .catch((err) => console.log(err));
  };

  const getBio = async () => {
    const data = await fetch(
      `${BASE_URL}${PROFILE_WORKER}?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerInfo, isLoading } = useQuery(["workerInfo"], getBio);

  useEffect(() => {
    if (!isLoading) {
      setBioWorker(workerInfo?.workerProfile?.overview);
    }
  }, [workerInfo]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showEditBioModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditBioModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowEditBioModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal
      isOpen={showEditBioModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.35)",
          zIndex: 100,
        },
        content: {
          position: "absolute",
          top: "-20px",
          left: "40px",
          right: "40px",
          bottom: "0px",
          border: "none",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          backgroundColor: "transparent",
        },
      }}
      ariaHideApp={false}
    >
      {" "}
      {/* {loading === false ? ( */}
      <div
        className={`fixed  z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[90%] lg:w-[700px] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.editBIO_overview.title")}
        </div>
        <div className="p-8">
          <p className="text-sm">
            {t("createprofile.submit.editBIO_overview.subtitle")}
          </p>
          <form className="relative">
            <textarea
              value={bioWorker}
              className="w-full mt-6 border h-48 p-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
              placeholder={`${t(
                "worker.profile.modal_edit_description.placeholder"
              )}`}
              onChange={(e) => setBioWorker(e.target.value)}
            ></textarea>
            {100 - bioWorker?.length > 0 && (
              <p className="text-xs absolute right-0 -bottom-5">
                {i18n.language === "en" ? (
                  <>At least {100 - bioWorker?.length} characters</>
                ) : (
                  <>Faltan {100 - bioWorker?.length} caracteres</>
                )}
              </p>
            )}
          </form>
        </div>

        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro cursor-pointer mt-1"
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.editBIO_overview.leftBtn")}
          </Button>
          <Button
            className={` border-none   w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]  h-10 ${
              bioWorker?.length < 100
                ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
            } `}
            disabled={bioWorker?.length < 100}
            onClick={(e) => {
              updateBio();
              setLoading(true);
            }}
          >
            {t("createprofile.submit.editBIO_overview.rightBtn")}
          </Button>
        </div>
      </div>
      {/* // ) : (
      //   <Suspense fallback={null}>
      //     <Loader />
      //   </Suspense>
      // )} */}
    </Modal>
  );
}

export default ModalEditBio;
