import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";

interface PaginationContractsProps {
  limit: number;
  rows: number;
  goToPreviousPage: () => void;
  changePage: (arg: number) => void;
  pageNumbers: any;
  page: number;
  totalPages: number;
  goToNextPage: () => void;
}

const PaginationContracts = ({
  limit,
  rows,
  changePage,
  goToPreviousPage,
  pageNumbers,
  page,
  totalPages,
  goToNextPage,
}: PaginationContractsProps) => {
  const { t } = useTranslation();
  return (
    <div className="border-y px-8 py-8">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <p className="text-xs lg:text-sm text-gray-500 mb-4 lg:mb-0">
          1 - {limit} of {rows} {t("employer.hires.diplay_hires.all_contracts")}
        </p>
        <div className="flex items-center gap-6">
          <button
            className={`flex items-center gap-3 ${
              page === 1 ? "cursor-not-allowed" : "cursor-pointer"
            }  `}
            onClick={() => goToPreviousPage()}
            disabled={page === 1}
          >
            <ChevronLeftIcon
              className="h-5 "
              fill={`${page === 1 ? "rgb(148 163 184)" : "#2abeeb"} `}
            />
            <p
              className={`text-sm font-medium ${
                page === 1
                  ? "text-slate-400 cursor-not-allowed"
                  : "text-azulBonico hover:text-azulOscuro"
              } `}
            >
              {t("employer.alljobs.previous.right.Text")}
            </p>
          </button>
          <div className="font-medium flex gap-3">
            {page < 4 ? (
              <>
                {pageNumbers?.slice(0, 4)?.map((actpage: number) => {
                  return (
                    <>
                      {actpage === page ? (
                        <div
                          className="text-azulBonico decoration-[2px]  underline underline-offset-[6px] cursor-pointer"
                          onClick={() => changePage(actpage)}
                        >
                          &nbsp;&nbsp;{actpage}&nbsp;&nbsp;
                        </div>
                      ) : (
                        <div
                          className="hover:text-azulOscuro hover:underline cursor-pointer"
                          onClick={() => changePage(actpage)}
                        >
                          &nbsp;{actpage}&nbsp;
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <>
                {page >= 4 && totalPages - 3 >= page ? (
                  <>
                    {pageNumbers
                      ?.slice(page - 2, page + 1)
                      ?.map((actpage: number) => {
                        return (
                          <>
                            {actpage === page ? (
                              <div
                                className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                onClick={() => changePage(actpage)}
                              >
                                &nbsp;&nbsp;{actpage}&nbsp;&nbsp;
                              </div>
                            ) : (
                              <div
                                className="hover:text-azulOscuro hover:underline cursor-pointer"
                                onClick={() => changePage(actpage)}
                              >
                                &nbsp;{actpage}&nbsp;
                              </div>
                            )}
                          </>
                        );
                      })}
                  </>
                ) : (
                  <>
                    {totalPages - 3 < page && (
                      <>
                        {pageNumbers
                          ?.slice(totalPages - 4, totalPages)
                          ?.map((actpage: number) => {
                            return (
                              <>
                                {actpage === page ? (
                                  <div
                                    className="text-azulBonico decoration-4  underline underline-offset-[6px] cursor-pointer"
                                    onClick={() => changePage(actpage)}
                                  >
                                    &nbsp;&nbsp;{actpage}
                                    &nbsp;&nbsp;
                                  </div>
                                ) : (
                                  <div
                                    className="hover:text-azulOscuro hover:underline cursor-pointer"
                                    onClick={() => changePage(actpage)}
                                  >
                                    &nbsp;{actpage}&nbsp;
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <button
            disabled={page === totalPages}
            onClick={() => goToNextPage()}
            className={`flex items-center gap-3 ${
              page === totalPages ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <p
              className={`text-sm font-medium ${
                page === totalPages
                  ? "text-slate-400 cursor-not-allowed"
                  : " text-azulBonico hover:text-azulOscuro"
              }`}
            >
              {t("employer.alljobs.next.right.Text")}
            </p>
            <ChevronRightIcon
              className="h-5"
              fill={`${page == totalPages ? "rgb(148 163 184)" : "#2abeeb"} `}
            />
          </button>
          {/* <button
        className={`text-sm font-medium cursor-pointer  ${
          page == totalPages
            ? "text-slate-400 cursor-not-allowed"
            : "text-azulBonico hover:text-azulOscuro"
        } `}
        onClick={() => goToLastPage()}
        disabled={page == totalPages}
      >
        {t("employer.alljobs.last.right.Text")}
      </button> */}
        </div>
      </div>
    </div>
  );
};

export default PaginationContracts;
