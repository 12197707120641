import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import exclamation from "../../assets/img/exclamation.png";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";
import { StylesModal } from "src/Utils/stylesModal";
import { Button } from "../ui/Button";
import { AiOutlineCheck } from "react-icons/ai";
import { EyeIcon, EyeSlashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { userProfileStore } from "src/Routing/useProfileStore";

interface PasswordModalProps {
  showModal: boolean;
  setShowModal: any;
}

export default function PasswordModal({
  showModal,
  setShowModal,
}: PasswordModalProps) {
  const [userEmail, setUserEmail] = useLocalStorage("userEmail", "");
  const [showPassword, setShowPassword] = useState(false);
  // const [newPassword, setNewPassword] = useState();
  // const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [arePasswordEqual, setArePasswordEqual] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const profileType = userProfileStore((s) => s.profileTypeToken);

  // const [profileType, setProfileType] = useLocalStorage("profileType", "");
  const [transition, setTransition] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [formValues, setFormValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const newPasswordSchema = Yup.object()
    .shape({
      newPassword: Yup.string()
        .min(8, `${t("worker.settings.modal_password.new_password.too_short")}`)
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9!@#\$%\^&\*])/,
          `${t("worker.settings.modal_password.new_password.at_least")}`
        )
        .max(50, `${t("worker.settings.modal_password.new_password.too_long")}`)
        .required(
          `${t("worker.settings.modal_password.new_password.new_password")}`
        ),
      confirmNewPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        `${t("worker.settings.modal_password.new_password.password_match")}`
      ),
    })
    .required();

  const handleUpdatePassword = (values: {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    if (values.newPassword === values.confirmNewPassword) {
      updatePassword(values);
      setArePasswordEqual(true);
    } else {
      setArePasswordEqual(false);
    }
  };

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  const updatePassword = (values: {
    oldPassword: string;
    newPassword: string;
  }) => {
    data.append("current_password", values.oldPassword);
    data.append("new_password", values.newPassword);
    fetch(
      `${BASE_URL}${
        profileType === "worker" ? "worker.password" : "employer.password"
      }`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          if (data.result.clientUpdate === "no updated") {
            closeModal();
            toast.error(`${data.result.clientUpdate.toUpperCase()}`);
          } else if (data.result.clientUpdate === "ok") {
            toast.success(`${data.result.clientUpdate}`);
            closeModal();
          }
        } else {
          setIncorrectPassword(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showModal} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed z-10 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="flex w-full items-center border-b justify-between px-8">
          <div className="text-xl font-bold   py-6">
            {t("modal.change_password.title")}
          </div>
          <div
            onClick={() => closeModal()}
            className="hover:bg-gray-200 p-1 rounded-full"
          >
            <XMarkIcon className="h-4 w-4" />
          </div>
        </div>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={newPasswordSchema}
          onSubmit={(values, { setSubmitting }) => {
            setFormValues({
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
              confirmNewPassword: values.confirmNewPassword,
            });
            setSubmitting(false);
            // registerUser();
            // executeLoginDetails();
            // setTimeout(() => {
            //   goToName();
            // }, 400);
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange }) => (
            <form>
              <div className="p-4 px-8">
                <p className="mb-2 text-sm font-medium">
                  {t("modal.change_password.old_password")}
                </p>
                <input
                  type="password"
                  name="oldPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.oldPassword}
                  className="border h-10 text-sm pl-4 outline-none rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 w-full"
                ></input>
                <div
                  onClick={() => navigate("/forgot-password")}
                  className="mt-1"
                >
                  <h3 className="text-azulOscuro text-sm hover:underline underline-offset-2 cursor-pointer">
                    Forgot password?
                  </h3>
                </div>
              </div>
              {incorrectPassword && (
                <div className="text-[#9b211b] flex items-center text-sm px-6 -mt-2">
                  <img className="exclamation" src={exclamation} alt="hey" />{" "}
                  {t("modal.change_password.incorrect")}
                </div>
              )}
              {/* <p className="text-azulBonico">
                    {" "}
                    {t("modal.change_password.show_password")}
                  </p> */}
              {/* <button
                    className="absolute w-5 bottom-15 right-[10rem]"
                    type="button"
                  >
                    <img src={key} alt="key" />
                  </button>{" "} */}
              <div className="p-4 px-8 relative">
                <div className="flex justify-between">
                  <p className="mb-2 text-sm font-medium">
                    {t("modal.change_password.new_password")}
                  </p>
                  <div
                    className="flex mr-1 text-sm cursor-pointer absolute top-14 right-10"
                    onClick={togglePassword}
                  >
                    {!showPassword ? (
                      <EyeIcon className="h-4 w-4 text-black" />
                    ) : (
                      <EyeSlashIcon className="h-4 w-4 text-black" />
                    )}
                  </div>
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  className="border h-10 text-sm pl-4 outline-none rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 w-full"
                ></input>
                {errors.newPassword && touched.newPassword ? (
                  <div className="wrong-container flex items-center space-x-2 mt-2">
                    <img
                      className="exclamation h-5 w-5"
                      src={exclamation}
                      alt="hey"
                    />
                    <p className="text-sm">{errors.newPassword}</p>
                  </div>
                ) : null}
              </div>
              <div className="p-4 px-8">
                <p className="mb-2 text-sm font-medium">
                  {t("modal.change_password.confirm")}
                </p>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmNewPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmNewPassword}
                  className="border h-10 text-sm pl-4 outline-none rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 w-full"
                ></input>
                {errors.confirmNewPassword && touched.confirmNewPassword ? (
                  <div className="wrong-container flex items-center space-x-2 mt-2">
                    <img
                      className="exclamation h-5 w-5"
                      src={exclamation}
                      alt="hey"
                    />
                    <p className="text-sm">{errors.confirmNewPassword}</p>
                  </div>
                ) : null}
              </div>
              {values.newPassword !== "" &&
                values.newPassword === values.confirmNewPassword && (
                  <div className="px-8 flex items-center text-xs text-green-500 gap-1 mb-3">
                    <AiOutlineCheck />
                    <p>
                      {t("employer.settings.client_settings.same_password")}
                    </p>
                  </div>
                )}
              <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end border-t border-blue-100">
                <Button
                  type="button"
                  className={` rounded-[20px] text-sm px-4 py-[0]  h-10 ${
                    !values.oldPassword ||
                    errors.confirmNewPassword ||
                    !values.confirmNewPassword
                      ? "cursor-not-allowed bg-blancoGris text-grey"
                      : "bg-azulBonico border-none hover:bg-azulOscuro   text-white"
                  }`}
                  onClick={() => {
                    handleUpdatePassword(values);
                  }}
                >
                  {t("employer.settings.client_settings.change_password")}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
