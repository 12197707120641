import React, { useEffect, useRef, useState } from "react";
import { BASE_URL, CLOSE_JOB_EMPLOYER } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";
import { JobTypes } from "src/types/types";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { formatJobTitle } from "src/Utils/formatters";
import { ReactComponent as Cross } from "src/assets/img/cross.svg";
import { Button } from "../ui/Button";
import { QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import parse from "html-react-parser";
import LoadingSpinner from "src/Pages/General/Register/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { cn } from "src/lib/utils";
import { useTranslation } from "react-i18next";
import { userProfileStore } from "src/Routing/useProfileStore";

interface ModalDeleteCloseJobProps {
  job: JobTypes;
}

const ModalDeleteCloseJob = ({ job }: ModalDeleteCloseJobProps) => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [showDeleteModal, setShowDeleteModal] = useModalStore((state) => [
    state.showDeleteJob,
    state.setShowDeleteJob,
  ]);
  const [successDeleted, setSuccessDeleted] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const [startTransition, setStartTransition] = useState(false);
  const { t } = useTranslation();

  const handleDeleteJob = () => {
    setLoading(true);
    fetch(
      `${BASE_URL}${CLOSE_JOB_EMPLOYER}?client_id=${userID}&job_id=${job?.job_id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result.deleteJobs === 1) {
          setTimeout(() => {
            setLoading(false);
            setSuccessDeleted(true);
          }, 1000);

          setTimeout(() => {
            setShowDeleteModal(showDeleteModal);
          }, 2000);
          setTimeout(() => {
            if (profileType === "employer") {
              navigate("/dashboard");
            } else {
              navigate("/dashboard-worker");
            }
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showDeleteModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDeleteModal]);

  const closeModal = () => {
    setStartTransition(false);
    setTimeout(() => {
      setShowDeleteModal(showDeleteModal);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setStartTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showDeleteModal} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed z-50 ${
          startTransition === false
            ? "opacity-40 scale-0"
            : "opacity-100 scale-100"
        } transition w-[90%]  lg:w-[700px] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="px-8 py-6 flex items-center justify-between">
          <p className="text-xl font-bold ">
            {t("employer.job_details.remove_posting")}
          </p>
          <div
            onClick={() => setShowDeleteModal(showDeleteModal)}
            className="p-2 hover:bg-gray-200 rounded-full transition cursor-pointer"
          >
            <Cross className="h-4 w-4" />
          </div>
        </div>

        <div className="px-8 mt-6">
          <div className="flex items-end justify-between bg-gray-50 rounded p-2">
            <div className="w-[80%]">
              <p className="text-sm text-neutral-600 font-medium">
                {" "}
                {formatJobTitle(job.title)}
              </p>
              <div className="mt-4">
                <p className="text-xs text-neutral-500 ">{job.details}</p>
                <p className="text-xs text-neutral-300 font-medium">
                  {job.location}
                </p>
              </div>
              <div>{/* {job.skill_list.split(",")} */}</div>
            </div>
            <div className="flex flex-col items-end w-[20%]">
              <p className="text-azulBonico text-xs">{job.category_name}</p>
              <p className="text-xs">
                <span className="font-semibold">€{job.budget}</span> /{" "}
                {t("employer.job_details.hour")}
              </p>
            </div>
          </div>
        </div>
        <div className="px-8 py-6 ">
          <p>{t("employer.job_post_edit.once_delete")}</p>
          <p className="text-sm ">
            {parse(t("employer.job_post_edit.delete_post"))}
          </p>
          {/* <div className="flex items-center mt-2">
            <p className="text-xs text-neutral-400 ">
              {Number(cloneJob?.credits)}{" "}
              {t("employer.applicants.view_jobpost.modal.clone.credits")}
            </p>
            <QuestionMarkCircleIcon className="h-4 w-4 ml-1 text-neutral-400" />
          </div> */}
        </div>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1"
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourworkexperience.leftBtn")}
          </Button>
          <Button
            className={cn(
              `border-none flex items-center transition  justify-center text-white w-[110px] bg-azulBonico hover:bg-azulOscuro rounded-[20px] text-[0.9rem] px-[1rem]   h-10 `,
              successDeleted && !loading && "bg-green-500 scale-105"
            )}
            onClick={() => {
              handleDeleteJob();
              // closeModal();
            }}
          >
            {loading && !successDeleted && <LoadingSpinner />}
            {!loading && !successDeleted && (
              <>{t("modal.end_early_date.accept")}</>
            )}
            {successDeleted && !loading && (
              <>
                <TrashIcon className={`h-6 w-6 `} color="white" />
              </>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteCloseJob;
