import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  lazy,
} from "react";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, EXPERIENCE_WORKER } from "../../Config/api.config";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

import exclamation from "../../assets/img/exclamation.png";
import { EducationTypes, JobTypes } from "../../types/types";
import { useTranslation } from "react-i18next";

interface ModalDeleteProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  title: string;
  error?: boolean;
  titleJob: string;
  handleRemove: () => void;
  job: EducationTypes;
  showModal: boolean;
}

export default function ModalDelete({
  setShowModal,
  title,
  titleJob,
  handleRemove,
  showModal,
  job,
}: // error,
ModalDeleteProps) {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [animationModal, setAnimationModal] = useState<boolean>(showModal);
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleRemoveExperience = () => {
    fetch(`${BASE_URL}${EXPERIENCE_WORKER}?experience_id=${job.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const handleDeletion = () => {
    if (title === "Job Post" || title === "Job Draft") {
      handleRemove();
    } else {
      handleRemoveExperience();
    }
  };

  useEffect(() => {
    if (showModal) {
      setAnimationModal(true);
    } else {
      setAnimationModal(false);
    }
  }, [showModal]);
  return (
    <div>
      <div
        className={`${
          animationModal
            ? "scale-20 opacity-100"
            : "scale-50 -translate-y-2 opacity-0"
        } fixed top-0 left-0 w-full  h-full bg-black/50 !text-black z-30`}
      >
        {/* {loading === false ? ( */}
        <div
          className="transition-all transform duration-150 ease-in-out fixed z-50 w-[600px] left-[50%] cursor-default translate-x-[-50%] rounded top-[45%] translate-y-[-50%] bg-white border-0.5"
          ref={ref}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
            {t("modal.delete.title")} {title}
          </div>
          <div className="p-8 pb-32 font-normal">
            {t("modal.delete.desc.are_you_sure")}{" "}
            <span className="text-azulBonico">
              {job?.title ? job?.title : titleJob ? titleJob : job?.job_title}
            </span>
            ? {t("modal.delete.desc.delete_permanent")}.
          </div>
          {/* {error !== false && !error && (
              <div className="text-[#9b211b] flex items-center text-sm px-8 -mt-2">
                <img className="exclamation" src={exclamation} alt="hey" />
                {error}
              </div>
            )} */}
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
            <button
              className="bg-none text-[0.9rem] font-semibold w-24 h-10  text-azulBonico rounded-3xl hover:underline cursor-pointer mt-1 transition duration-300"
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t("modal.delete.button.cancel")}
            </button>
            <button
              className="bg-azulBonico border-none font-semibold hover:bg-azulOscuro text-white w-[135px] rounded-[20px] text-[0.9rem] px-[0.6rem] py-[0] cursor-pointer transition duration-300 h-10"
              onClick={(e) => {
                e.stopPropagation();
                handleDeletion();
                // setLoading(true);
              }}
            >
              {t("modal.delete.button.delete")}
            </button>
          </div>
        </div>
        {/* // ) : (
        //   <Loader />
        // )} */}
      </div>
    </div>
  );
}
