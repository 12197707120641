import { useQuery } from "react-query";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";

export const useGetCities = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const getAllCities = async () => {
    const data = await fetch(`${BASE_URL}cities?country_code=ES`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    }).then((res) => res.json());
    return data.result;
  };

  const { data: citiesData } = useQuery(["cities"], getAllCities);

  return { citiesData };
};
