import React from "react";

export default function Navbar({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <nav className="h-20 py-4 cursor-pointer">
        <ul className=" max-w-full h-full flex justify-end">{children}</ul>
      </nav>
    </div>
  );
}
