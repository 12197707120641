import { XMarkIcon } from "@heroicons/react/24/solid";
import { useAtom } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import {
  budgetEnd,
  budgetStart,
  categoriesArrayId,
  citiesArrayId,
  cityNameInput,
  jobTypeArrayId,
  saveTypeId,
} from "src/Atoms/jotaiAtoms";
import {
  HoursSelectionType,
  INITIAL_TIME_HOUR,
  SelectedPostcodeFilter,
} from "src/Pages/Employer/FindWorkers/FilterWorkers";
import { Cities, Postcode } from "src/types/types";
import Select from "react-select";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";
import { Button } from "src/Components/ui/Button";
import { hoursInitial } from "src/Utils/content/Hours";
import uuid from "react-uuid";
import { type Categories } from "src/types/types";
import { debounce } from "src/Utils/debounce";
import { useCategories } from "src/Hooks/useCategories";
import { useCities } from "src/Hooks/useCities";

export interface TimeSelection {
  day: string;
  hour_from: string;
  hour_to: string;
}

const FilterWork = () => {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [citiesToShow, setCitiesToShow] = useState<Cities[]>([]);
  const [selectedHoursDay, setSelectedHoursDay] = useState<any>([]);
  const [selectedPostcodes, setSelectedPostCodes] = useState<
    SelectedPostcodeFilter[]
  >([]);
  const [hourTimeSelection, setHourTimeSelection] =
    useState<TimeSelection>(INITIAL_TIME_HOUR);
  const [categories, setCategories] = useState<Categories[]>([]);

  const [categoriesIds, setCategoriesIds] = useAtom(categoriesArrayId);
  const [citiesId, setCitiesId] = useAtom(citiesArrayId);
  const [savedType, setSavedType] = useAtom(saveTypeId);
  const [jobType, setJobType] = useAtom(jobTypeArrayId);
  const [budgetValueFrom, setBudgetValueFrom] = useAtom(budgetStart);
  const [budgetValueTo, setBudgetValueTo] = useAtom(budgetEnd);
  const [categoriesToShow, setCategoriesToShow] = useState<Categories[]>([]);

  const [allPostcodes, setAllPostcodes] = useState<Postcode[]>([]);

  const [cityName, setCityName] = useAtom(cityNameInput);
  const [cities, setCities] = useState<Cities[]>([]);
  const { categories: categoriesData } = useCategories();

  const { cities: citiesData, refetchCities } = useCities();

  const days = [
    {
      label: `${t(
        "worker.findwork.body.left.time availability.placeholder1.day.left.up.sortbyDropdown.day1"
      )}`,
      value: "Mon",
    },
    {
      label: `${t(
        "worker.findwork.body.left.time availability.placeholder1.day.left.up.sortbyDropdown.day2"
      )}`,
      value: "Tue",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day3"
      )}`,
      value: "Wed",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day4"
      )}`,
      value: "Thu",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day5"
      )}`,
      value: "Fri",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day6"
      )}`,
      value: "Sat",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day7"
      )}`,
      value: "Sun",
    },
  ];

  const onClearFilters = () => {
    setCitiesId([]);
    setCitiesToShow([]);
    setSelectedPostCodes([]);
    setSelectedHoursDay([]);
    setCategoriesIds([]);
    setCategoriesToShow([]);
    setJobType([]);
    setSavedType("");
    setBudgetValueFrom("");
    setBudgetValueTo("");
  };

  useEffect(() => {
    setCities(citiesData);
  }, [citiesData]);

  const optionsCities = cities?.map((city) => {
    return { value: city.id, label: city.name };
  });

  const handleCityName = (e: string) => {
    setCityName(e);
  };
  const optimisedSearch = useCallback(debounce(handleCityName), []);
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
      width: "160px",
    }),
  };

  const handleChangeJobCity = (cityId: string) => {
    if (!citiesId.includes(cityId)) {
      setCitiesId((prev) => [...prev, cityId]);
    } else {
      setCitiesId(citiesId.filter((id) => id !== cityId));
    }
  };

  const handleFilterJobCity = (cityId: string) => {
    const selectedCity: any = cities.find((city) => city.id === cityId);
    setCitiesToShow((prev) => [...prev, selectedCity]);
  };

  const handleRemoveJobCity = (cityId: string) => {
    setCitiesId(citiesId.filter((city) => city !== cityId));
    setCitiesToShow(citiesToShow.filter((city) => city.id !== cityId));
  };

  const getAllPostcodes = () => {
    fetch(`${BASE_URL}postcodes?country_code=es`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllPostcodes(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllPostcodes();
  }, []);

  const handlePostcodesFilter = (postcode: any, action: { action: string }) => {
    if (action.action === "select-option") {
      setSelectedPostCodes([
        ...selectedPostcodes,
        postcode[postcode.length - 1],
      ]);
    } else if (action.action === "clear") {
      setSelectedPostCodes([]);
    }
  };

  const handleRemovePostcode = (postcodeId: string) => {
    setSelectedPostCodes(
      selectedPostcodes.filter((postcode) => postcode.id !== postcodeId)
    );
  };

  const optionsPostcodes = allPostcodes?.map((postcode) => {
    return {
      label:
        postcode.locality + ", " + postcode.state + ", " + postcode.postcode,
      value:
        postcode.locality + ", " + postcode.state + ", " + postcode.postcode,
      state: postcode.state,
      class: "postcode",
      postcode: postcode.postcode,
      id: postcode.id,
      checked: false,
    };
  });

  const emptySelectHourSelection = () => {
    setHourTimeSelection({ day: "", hour_from: "", hour_to: "" });
  };

  const handleAddTime = () => {
    setSelectedHoursDay((prev: any) => [
      ...prev,
      {
        day: hourTimeSelection.day,
        hour_from: hourTimeSelection.hour_from,
        hour_to: hourTimeSelection.hour_to,
        id: uuid(),
      },
    ]);

    setHourTimeSelection(INITIAL_TIME_HOUR);
    emptySelectHourSelection();
  };

  const removeTimeSelection = (timeId: string) => {
    setSelectedHoursDay(
      selectedHoursDay.filter(
        (hourDay: { id: string }) => hourDay.id !== timeId
      )
    );
  };

  useEffect(() => {
    setCategories(categoriesData);
  }, [categoriesData]);
  // const getAllCategories = async () => {
  //   const data = await fetch(`${BASE_URL}job.categories?category_id`, {
  //     headers: {
  //       Authorization: "Bearer " + loggedInUserUid,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setCategories(data.result))
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   getAllCategories();
  // }, []);

  const optionsCategories = categories?.map((category) => {
    return { value: category.category_id, label: category.name };
  });

  const handleChangeCategory = (categoryId: string) => {
    if (!categoriesIds.includes(categoryId)) {
      setCategoriesIds((prev) => [...prev, categoryId]);
    } else {
      setCategoriesIds(categoriesIds.filter((id) => id !== categoryId));
    }
  };

  const handleFilterCategory = (categoryId: string) => {
    const selectedCategory: any = categories.find(
      (category) => category.category_id === categoryId
    );
    if (!categoriesToShow.includes(selectedCategory)) {
      setCategoriesToShow((prev) => [...prev, selectedCategory]);
    } else {
      setCategoriesToShow(
        categoriesToShow.filter(
          (category) => category.category_id !== categoryId
        )
      );
    }
  };

  const handleRemoveCategory = (categoryId: string) => {
    setCategoriesIds(
      categoriesIds.filter((category) => category !== categoryId)
    );

    setCategoriesToShow(
      categoriesToShow.filter((category) => category.category_id !== categoryId)
    );
  };

  const handleChangeJobType = (e: string) => {
    if (!jobType.includes(e)) {
      setJobType((prev: string[]) => [...prev, e]);
    } else {
      setJobType(jobType.filter((type) => type !== e));
    }
  };

  return (
    <div className="flex bg-white rounded shadow mt-4 flex-col px-8 py-8 justify-between dark:border-border dark:bg-background dark:text-whiteish border-b border-border">
      <div className="flex flex-col items-start gap-10">
        <div className="flex items-center justify-between w-full">
          <p>{t("worker.findwork.body.left.filters.title")}</p>
          <div
            onClick={() => onClearFilters()}
            className="flex items-center gap-2 bg-gray-100 dark:bg-input dark:text-whiteish  rounded px-2 py-1 cursor-pointer hover:bg-gray-200 transition"
          >
            <div className="text-sm">
              {t("employer.review_candidates.filters.clear")}
            </div>
            <XMarkIcon className="h-4 w-4" />
          </div>
        </div>
        <div className="flex flex-col items-start gap-4">
          <p className="text-sm mb-2 font-medium">
            {t("worker.search_jobs_filter.select_cities")}
          </p>

          <Select
            onInputChange={(e) => optimisedSearch(e)}
            styles={colourStyles}
            placeholder={`${t("worker.search_jobs_filter.select_cities")}`}
            className=" dark:text-black text-sm  "
            controlShouldRenderValue={false}
            options={optionsCities}
            onChange={(e) => {
              handleChangeJobCity(e!.value);
              handleFilterJobCity(e!.value);
            }}
          />
          {citiesToShow.length > 0 && (
            <div className="">
              <div className="flex flex-wrap items-center gap-4">
                {citiesToShow?.map((city) => (
                  <div
                    className="flex flex-wrap bg-gray-100 items-center gap-4 cursor-pointer px-2 py-1 rounded"
                    key={city.id}
                    onClick={() => handleRemoveJobCity(city.id)}
                  >
                    <p className="text-sm">{city?.name}</p>
                    <XMarkIcon className="h-4 w-4" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-start gap-4">
          <p className="text-sm  font-medium">
            {t("worker.search_jobs_filter.search_postcodes")}
          </p>
          <Select
            options={optionsPostcodes}
            placeholder={`${t(
              "worker.search_jobs_filter.search_postcodes"
            )}...`}
            // isMulti
            // closeMenuOnSelect={false}
            // hideSelectedOptions={true}
            controlShouldRenderValue={false}
            isOptionSelected={(option: { checked: boolean }) =>
              option.checked === true
            }
            onChange={(e, action) => handlePostcodesFilter(e, action)}
            className="text-sm mb-3 dark:text-black w-[200px]"
            noOptionsMessage={() =>
              t("worker.search_jobs_filter.search_postcodes_search_type")
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
          <div className="flex flex-wrap items-center gap-3">
            {selectedPostcodes.length > 0 &&
              selectedPostcodes?.map(
                (postcode: {
                  checked: boolean;
                  class: string;
                  id: string;
                  label: string;
                  postcode: string;
                  state: string;
                  value: string;
                }) => (
                  <div
                    onClick={() => handleRemovePostcode(postcode.id)}
                    key={postcode.id}
                    className="flex flex-wrap bg-gray-100 items-center gap-4 cursor-pointer px-2 py-1 rounded"
                  >
                    <p className="text-sm">{postcode?.label}</p>
                    <XMarkIcon className="h-4 w-4" />
                  </div>
                )
              )}
          </div>
        </div>
        <div className="flex flex-col items-start ">
          <p className="text-sm font-medium">
            {t("createprofile.submit.edityourAvailableHours.title")}
          </p>
          <div className="flex flex-col items-start">
            <div className="flex items-center gap-4 mt-4">
              <Select
                options={days}
                styles={colourStyles}
                value={
                  hourTimeSelection.day !== ""
                    ? {
                        label: hourTimeSelection.day,
                        value: hourTimeSelection.day,
                      }
                    : `${t(
                        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up"
                      )}`
                }
                placeholder={`${t(
                  "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up"
                )}`}
                className=" dark:text-black text-sm "
                onChange={(e: any) => {
                  setHourTimeSelection((prev) => ({
                    ...prev,
                    day: e.value,
                  }));
                }}
              />

              <Button
                onClick={() => handleAddTime()}
                className="border cursor-pointer hover:bg-blue-50 text-sm w-[160px] py-2 rounded disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-[#333]"
                disabled={
                  hourTimeSelection.day === "" ||
                  hourTimeSelection.hour_from === "" ||
                  hourTimeSelection.hour_to === ""
                }
              >
                {t("welcome-client.rightcard.dayweek.monday.addtimeBtn")}
              </Button>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <Select
                value={
                  hourTimeSelection.hour_from !== ""
                    ? {
                        label: hourTimeSelection.hour_from,
                        value: hourTimeSelection.hour_from,
                      }
                    : "Hour From"
                }
                options={hoursInitial}
                styles={colourStyles}
                placeholder="Select From"
                className=" dark:text-black text-sm "
                onChange={(e: any) => {
                  setHourTimeSelection((prev) => ({
                    ...prev,
                    hour_from: e.value,
                  }));
                }}
              />
              <Select
                value={
                  hourTimeSelection.hour_to !== ""
                    ? {
                        label: hourTimeSelection.hour_to,
                        value: hourTimeSelection.hour_to,
                      }
                    : "Hour To"
                }
                options={hoursInitial}
                styles={colourStyles}
                placeholder="Select To"
                className=" dark:text-black text-sm "
                onChange={(e: any) => {
                  setHourTimeSelection((prev) => ({
                    ...prev,
                    hour_to: e.value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="flex flex-col items-start mt-4">
            {selectedHoursDay.length > 0 &&
              selectedHoursDay?.map((dayHour: HoursSelectionType) => (
                <div
                  className="flex items-center gap-6 w-full justify-between"
                  key={dayHour.id}
                >
                  <div className="flex items-center gap-4 ">
                    <p className="font-medium">{dayHour.day}</p>
                    <div className="flex bg-gray-200 rounded-full px-2 items-center gap-3">
                      <p className="text-sm text-[#333]">{dayHour.hour_from}</p>
                      <p className="text-sm text-[#333]">{dayHour.hour_to}</p>
                    </div>
                  </div>
                  <div
                    onClick={() => removeTimeSelection(dayHour.id)}
                    className="p-1 rounded-full hover:bg-gray-100 cursor-pointer"
                  >
                    <XMarkIcon className="h-4 w-4 " />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col items-start gap-4">
          <p className="text-sm font-medium">
            {" "}
            {t("worker.profile.categories")}
          </p>
          <Select
            options={optionsCategories}
            placeholder={t(
              "worker.findwork.body.left.categorias.placeholder.selectcategories.sortbyDropdown"
            )}
            className="text-sm w-[200px] dark:border-border dark:bg-input dark:text-background"
            onChange={(e) => {
              handleChangeCategory(e!.value);
              handleFilterCategory(e!.value);
            }}
          />
          <div className="flex flex-col items-start">
            {categories?.slice(0, 5)?.map((category) => (
              <div
                onClick={() => {
                  handleChangeCategory(category.category_id);
                  handleFilterCategory(category.category_id);
                }}
                className="flex items-center gap-2 mb-1"
                key={category.category_id}
              >
                <input
                  checked={categoriesIds.includes(category.category_id)}
                  type="checkbox"
                />
                <p className="text-sm">{category.name}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap items-center gap-3">
            {categoriesToShow.length > 0 &&
              categoriesToShow?.map((category) => (
                <div
                  onClick={() => handleRemoveCategory(category.category_id)}
                  key={category.category_id}
                  className="flex flex-wrap bg-gray-100 dark:bg-input dark:border-border items-center gap-4 cursor-pointer px-2 py-1 rounded"
                >
                  <p className="text-sm">{category.name}</p>
                  <XMarkIcon className="h-4 w-4 cursor-pointer" />
                </div>
              ))}
          </div>
          <div className="flex flex-col items-start gap-4">
            <p className="text-sm font-medium">
              {" "}
              {t("worker.search_jobs_filter.job_type")}
            </p>
            <div className="flex flex-col items-start">
              <div className="flex items-center gap-2 text-[14px]">
                <input
                  onChange={() => {
                    handleChangeJobType("2");
                  }}
                  checked={jobType.includes("2")}
                  type="checkbox"
                />
                <p>{t("worker.edit_job_type.modal.work_type.remote")}</p>
              </div>
              <div className="flex items-center gap-2 text-[14px]">
                <input
                  onChange={() => {
                    handleChangeJobType("3");
                  }}
                  checked={jobType.includes("3")}
                  type="checkbox"
                />
                <p>{t("worker.edit_job_type.modal.work_type.hybrid")}</p>
              </div>
              <div className="flex items-center gap-2 text-[14px]">
                <input
                  onChange={() => {
                    handleChangeJobType("1");
                  }}
                  type="checkbox"
                  checked={jobType.includes("1")}
                />
                <p>{t("worker.edit_job_type.modal.work_type.inperson")}</p>
              </div>
              <div className="flex items-center gap-2 text-[14px]">
                <input
                  checked={jobType.includes("4")}
                  onChange={() => {
                    handleChangeJobType("4");
                  }}
                  type="checkbox"
                />
                <p>{t("worker.edit_job_type.modal.work_type.nomatter")}</p>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col items-start gap-4">
            <p className="text-sm font-medium">Profile Saved</p>

            <div className="flex flex-col items-start">
              <div className="flex items-center gap-2 text-[14px]">
                <input
                  onChange={() => setSavedType("1")}
                  type="checkbox"
                  checked={savedType === "1"}
                />
                <p>Saved</p>
              </div>
              <div className="flex items-center gap-2 text-[14px]">
                <input
                  onChange={() => setSavedType("0")}
                  type="checkbox"
                  title="Not Saved"
                  checked={savedType === "0"}
                />
                <p>Not Saved</p>
              </div>
            </div>
          </div> */}
          <div className="flex flex-col items-start gap-4">
            <h1 className="text-sm font-medium">
              {t("worker.profile.budget")}
            </h1>
            <div className="flex flex-col items-start gap-2 text-[14px]">
              <p className="text-sm">
                {t("employer.find_workers.filters.from")}
              </p>
              <input
                onChange={(e) => setBudgetValueFrom(e.target.value)}
                value={budgetValueFrom}
                type="number"
                className="w-full border dark:border-border dark:bg-input outline-none rounded px-2 py-1"
              />
            </div>
            <div className="flex flex-col items-start gap-2 text-[14px]">
              <p className="text-sm">{t("employer.find_workers.filters.to")}</p>
              <input
                onChange={(e) => setBudgetValueTo(e.target.value)}
                value={budgetValueTo}
                type="number"
                className="w-full border dark:border-border dark:bg-input  outline-none rounded px-2 py-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWork;
