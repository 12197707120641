import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Router from "./Routing/Router";
import "./App.css";
import { useState } from "react";

import { init_i18n } from "./Utils/i18n";
import { Toaster } from "react-hot-toast";
import Cookies from "./Components/Modals/Cookies";
import PublicPassword from "./Pages/General/Home/PublicPassword";
import "react-multi-carousel/lib/styles.css";
import { useIsPublicStore } from "./Hooks/usePublicStore";

const AppContainer = () => {
  return <Router />;
};

init_i18n();

function App() {
  const queryClient = new QueryClient();
  const [showModal, setShowModal] = useState(true);
  const isPublic = useIsPublicStore((s) => s.isPublic);

  if (isPublic === "false") {
    return <PublicPassword showModal={showModal} />;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Cookies />
      <Toaster position="top-center" />

      <AppContainer />
    </QueryClientProvider>
  );
}

export default App;
