import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as LogoTwo } from "../../assets/img/logo_2.svg";
import { ReactComponent as Home } from "../../assets/img/house.svg";
import { ReactComponent as Hires } from "../../assets/img/sitemap.svg";
import { ReactComponent as Envelope } from "../../assets/img/envelope.svg";
import { ReactComponent as Bell } from "../../assets/img/bell.svg";
import { ReactComponent as Settings } from "../../assets/img/settings.svg";
import { ReactComponent as Circle } from "../../assets/img/circle.svg";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useAtom } from "jotai";
import {
  allNotifications,
  isEmployerOnline,
  messages4JobId,
  notificationsState,
  process1WithParamNew,
  selectedTabHeaderEmployer,
  showOpenMenuEmployer,
  widthScreen,
} from "../../Atoms/jotaiAtoms";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  BASE_URL_IMG,
  CHAT,
  LOGOUT,
  NOTIFICATIONS,
  SETTINGS_USER,
} from "../../Config/api.config";
import { FaCaretDown } from "react-icons/fa";
import { BiHelpCircle, BiLogOut, BiWallet } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import {
  ChevronRightIcon,
  CurrencyDollarIcon,
  MoonIcon,
  PlusIcon,
  SunIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import {
  useEmployerStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import { cn } from "src/lib/utils";
import { useThemeStore } from "src/Routing/useThemeStore";
import Select from "react-select";
import FlexihoursClock from "src/assets/img/flexihours.png";

import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import Flexicoin from "src/assets/img/flexicoin-nobg.png";
import { useCreditsActions } from "src/Routing/useCreditsActions";
import { useGetNotifications } from "src/Hooks/useGetNotifications";
import { useGetCredits } from "src/Hooks/useGetCredits";
import { MdHistory } from "react-icons/md";
import { useWalletSore } from "src/Hooks/useWalletStore";
import { Skeleton } from "../ui/skeleton";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { useClientData } from "src/Hooks/useClientData";

const themeOptions = [
  {
    value: "light",
    label: (
      <div className="flex items-center gap-4">
        <SunIcon className="h-4 w-4" />
        Light
      </div>
    ),
  },
  {
    value: "dark",
    label: (
      <div className="flex items-center gap-4">
        <MoonIcon className="h-4 w-4" />
        Dark
      </div>
    ),
  },
];

const VerticalHeaderUser = () => {
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const [selectedTab, setSelectedTab] = useAtom(selectedTabHeaderEmployer);
  const [jobTitle, setJobTitle] = useLocalStorage("jobTitle", "");
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [clientSkills, setClientSkills] = useLocalStorage("clientSkills", "");
  const [notifications, setNotifications] = useAtom(allNotifications);
  const [unreadMessages, setUnreadMessages] = useState("");
  // const [dataProcess1, setDataProcess1] = useAtom(dataMessagesProcessOne);
  const [useParamsForStartConversation, setUseParamsForStartConversation] =
    useAtom(process1WithParamNew);
  const [messagesJobId, setMessagesJobId] = useAtom(messages4JobId);
  const [showNotificationsPopover, setShowNotificationsPopover] =
    useState(false);
  const [decorationBar, setDecorationBar] = useLocalStorage(
    "barWorker",
    "info"
  );
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loggedStatus, setLoggedStatus] = useAtom(isEmployerOnline);
  const [isOpen, setOpen] = useState(false);
  const [notificationsData, setNotificationsData] = useAtom(notificationsState);
  const [jobType, setJobType] = useLocalStorage("jobType", "");
  const [budget, setBudget] = useLocalStorage("budget", "");
  const [width, setWidth] = useAtom(widthScreen);
  const [showOptions, setShowOptions] = useAtom(showOpenMenuEmployer);
  const setProfileTypesLocal = userProfileStore((s) => s.setProfile);

  const navigate = useNavigate();
  const settingsRef = useRef<HTMLDivElement>(null);
  const [showAccountConfig, setShowAccountConfig] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useThemeStore((state) => [
    state.themeMode,
    state.setThemeMode,
  ]);
  const { isNotificationsLoading, notificationData, refetch } =
    useGetNotifications();

  const actionTypes: any = useCreditsActions((state) => state.actionTypes);
  const [postJobCredits, setPostJobCredits] = useState<
    { id: string; name: string; credits: string }[]
  >([]);
  const [postjobCredit, setPostJobCredit] = useState<
    { id: string; name: string; credits: string }[]
  >([]);
  const [loadingActionCredits, setLoadingActionCredits] = useState(false);
  const modal = useWalletSore();

  const [showVerticalEntire, setShowVerticalEntire] =
    useLocalStorage("vertical_user");
  const [transitionAnimation, setTransitionAnimation] = useLocalStorage(
    "transition_vertical",
    false
  );
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const { employerData, refetchEmployer } = useClientData();

  useEffect(() => {
    setUserInfo(employerData);
  }, [employerData]);

  const { t } = useTranslation();

  useOnClickOutside(ref, () => {
    if (showAccountConfig) {
      setShowAccountConfig(!showAccountConfig);
    }
  });

  const { creditsData, loadingCredits } = useGetCredits();

  const resizeVerticalHeader = () => {
    setShowVerticalEntire(!showVerticalEntire);
    setTimeout(() => {
      if (showVerticalEntire) {
        setTransitionAnimation(false);
      } else {
        setTransitionAnimation(true);
      }
    }, 50);
  };

  useEffect(() => {
    setCreditsNumber(creditsData);
  }, [creditsData]);

  //LOCAL STORAGE
  useEffect(() => {
    setLoadingActionCredits(true);
    const jsonActionTypes = JSON.parse(actionTypes) as any;
    setPostJobCredits(jsonActionTypes);
    setTimeout(() => {
      setLoadingActionCredits(false);
    }, 400);
  }, [actionTypes]);

  useEffect(() => {
    if (postJobCredits !== null && postJobCredits?.length > 0) {
      const postJobAction: any = postJobCredits?.find(
        (action) => action.id === "1"
      );
      setPostJobCredit(postJobAction?.credits);
    }
  }, [postJobCredits]);

  useEffect(() => {
    if (window.location.pathname !== "/welcome-client") {
      setJobTitle();
      setSelectedCategory();
      setClientSkills();
      setJobType();
      setBudget();
    }
  }, []);

  //NOTIFICATIONS
  const INTERVAL_NOTIFICATIONS = 10000;

  useEffect(() => {
    setNotifications(notificationData?.notifications);
    setNotificationsData(notificationData?.paginate?.[0]);
    setUnreadMessages(notificationData?.messages);
  }, [notificationData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, INTERVAL_NOTIFICATIONS);
    return () => clearInterval(interval);
  }, []);

  //SET ONLINE STATUS FROM MESSAGES TAB OFF
  const data = new FormData();
  const setOnlineStatusOff = () => {
    data.append("_method", "PUT");
    data.append("client_id", userID);
    data.append("status", "LOGOUT");
    fetch(`${BASE_URL}${CHAT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // checkNotifications();
    if (window.location.pathname !== "/messages-em" && loggedStatus === true) {
      setLoggedStatus(false);
      setOnlineStatusOff();
      // setDataProcess1();
      setUseParamsForStartConversation("");
      setMessagesJobId("");
    }
  }, []);

  const readNotification = new FormData();
  readNotification.append("_method", "PUT");
  readNotification.append("client_id", userID);

  const checkNotificationAsRead = (id: string) => {
    if (id) {
      readNotification.append("notification_id_list", id);
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    } else {
      fetch(`${BASE_URL}${NOTIFICATIONS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: readNotification,
      })
        .then((res) => res.json())
        .catch((err) => console.log(err));
    }
  };

  //To get the inner width of the window, and close the nav bar mobile if it happens to be open

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerWidth > 989) setOpen(false);
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const logoutData = new FormData();
  logoutData.append("id", userID);

  const logoutUser = async () => {
    const data = await fetch(`${BASE_URL}${LOGOUT}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: logoutData,
    })
      .then((res) => res.json())
      .then(() => handleLogout())
      .catch((err) => console.log(err));
  };
  const handleLogout = () => {
    setLoggedInUserUid("");
    setUserId("");
    setProfileTypesLocal(null);
    navigate("/login");
    // localStorage.clear();
    // setTimeout(() => {
    //   setWidth("w-full");
    //   navigate("/login");
    // }, 100);
  };

  const themeData = new FormData();
  themeData.append("_method", "PUT");
  themeData.append("client_id", userID);

  const updateTheme = (theme: string) => {
    themeData.append("dark_mode", theme === "dark" ? "1" : "0");
    fetch(`${BASE_URL}${SETTINGS_USER}`, {
      method: "POST",
      body: themeData,
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));
  };

  return (
    <div
      ref={ref}
      className="h-screen  bg-white dark:bg-background border-r border-border px-2  pt-[20px] shadow transition cursor-pointer"
    >
      <div
        className={cn(
          "flex flex-col justify-between h-full w-64  pb-12 transition-width duration-200 ease-in-out",
          showVerticalEntire && "w-14 "
        )}
      >
        <div className="flex flex-col text-base font-semibold tracking-wide px-2 relative">
          {!transitionAnimation ? (
            <LogoTwo
              className="h-10 cursor-pointer"
              onClick={() => {
                if (window.location.pathname !== "/dashboard") {
                  setSelectedTab("dashboard");
                  setShowOptions((prev) => ({
                    ...prev,
                    jobs: true,
                    profile: false,
                  }));
                  if (profileType === "employer") {
                    navigate("/dashboard");
                  } else {
                    navigate("/dashboard-worker");
                  }
                } else {
                  if (!loggedInUserUid) {
                    navigate("/");
                  }
                }
              }}
            />
          ) : (
            <div
              onClick={() => {
                if (window.location.pathname !== "/dashboard") {
                  setSelectedTab("dashboard");
                  setShowOptions((prev) => ({
                    ...prev,
                    jobs: true,
                    profile: false,
                  }));
                  if (profileType === "employer") {
                    navigate("/dashboard");
                  } else {
                    navigate("/dashboard-worker");
                  }
                } else {
                  if (!loggedInUserUid) {
                    navigate("/");
                  }
                }
              }}
              className={cn("h-10 justify-center items-center flex")}
            >
              <img src={FlexihoursClock} className="h-6 w-6 cursor-pointer" />
            </div>
          )}
          <div
            onClick={() => resizeVerticalHeader()}
            className="h-7 w-7 rounded hover:bg-gray-200 items-center flex justify-center absolute right-2 top-16 transition"
          >
            {!transitionAnimation ? (
              <BsChevronDoubleLeft className="h-4 w-4 text-gray-500" />
            ) : (
              <BsChevronDoubleRight className="h-4 w-4 text-gray-500" />
            )}
          </div>
          {/* <LogoComponent color={theme === "dark" ?? "#E1E7EF"} /> */}
          <div className="flex flex-col mt-14  text-slate-600 dark:text-whiteish">
            <div
              className={cn(
                `flex items-center gap-5 justify-between h-10 py-2 px-2 transition cursor-pointer mb-[1px] dark:text-whiteish ${
                  window.location.pathname === "/dashboard" ||
                  window.location.pathname === "/all-jobs" ||
                  window.location.pathname === "/welcome-client"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30 "
                    : "text-slate-500 hover:bg-slate-200 dark:hover:bg-neutral-400/30 rounded "
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                if (!transitionAnimation) {
                  setSelectedTab("dashboard");
                  if (profileType === "employer") {
                    navigate("/dashboard");
                  } else {
                    navigate("/dashboard-worker");
                  }
                } else {
                  if (profileType === "employer") {
                    navigate("/dashboard");
                  } else {
                    navigate("/dashboard-worker");
                  }
                }
              }}
            >
              <div className="flex items-center gap-5 ">
                <Home
                  className={`h-4 w-4  dark:fill-whiteish ${
                    selectedTab === "dashboard" ||
                    selectedTab === "all-jobs" ||
                    selectedTab === "welcome-client"
                      ? "fill-azulOscuro"
                      : "fill-slate-500"
                  } `}
                />
                {!transitionAnimation && (
                  <p className={cn("text-[14px] ")}>
                    {t("employer.leftbar.menu.item1.subitem1.title")}
                  </p>
                )}
              </div>
            </div>

            <div
              className={cn(
                `flex items-center gap-5 transition cursor-pointer h-10 py-2 px-2 mb-[1px] dark:text-whiteish ${
                  window.location.pathname === "/my-hires"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200  rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                if (!transitionAnimation) {
                  setShowOptions((prev) => ({
                    ...prev,
                    jobs: false,
                    profile: false,
                  }));
                  setSelectedTab("hires");
                  navigate("/my-hires");
                } else {
                  setSelectedTab("hires");
                  navigate("/my-hires");
                }
              }}
            >
              <Hires
                className={`h-4 w-4 dark:fill-whiteish ${
                  selectedTab === "hires" ? "fill-azulOscuro" : "fill-slate-500"
                } `}
              />

              {!transitionAnimation && (
                <p className="text-[14px]">
                  {t("employer.leftbar.menu.item2.title")}
                </p>
              )}
            </div>
            <div
              className={cn(
                `flex items-center gap-5 transition cursor-pointer h-10 py-2 px-2 mb-[1px] dark:text-whiteish ${
                  window.location.pathname === "/messages-em"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200  rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                setShowOptions((prev) => ({
                  ...prev,
                  jobs: false,
                  profile: false,
                }));
                setSelectedTab("messages");
                navigate("/messages-em");
              }}
            >
              <Envelope
                className={`h-4 w-4  dark:fill-whiteish ${
                  selectedTab === "messages"
                    ? "fill-azulOscuro"
                    : "fill-slate-500"
                }`}
              />
              {!transitionAnimation && (
                <p className="text-[14px]">
                  {t("employer.leftbar.menu.item3.title")}
                </p>
              )}
              {!isNotificationsLoading && unreadMessages !== "0" && (
                <p className="flex mt-[1px] justify-center text-sm text-white font-bold bg-[#b91919] w-[22px] h-fit rounded-full border border-[#b91919]">
                  {unreadMessages}
                </p>
              )}
            </div>

            <div
              className={cn(
                `flex items-center gap-5 transition  cursor-pointer h-10 py-2 px-2 mb-[1px] dark:text-whiteish ${
                  window.location.pathname === "/notifications"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200  rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                setShowOptions((prev) => ({
                  ...prev,
                  jobs: false,
                  profile: false,
                }));
                setSelectedTab("notifications");
                checkNotificationAsRead("");
                navigate("/notifications");
              }}
            >
              <Bell
                className={`  h-4 w-4 dark:fill-whiteish  ${
                  notificationsData?.unread_notifications !== "0" &&
                  "animate-bellAanimation"
                }  ${
                  selectedTab === "notifications"
                    ? "fill-azulOscuro"
                    : "fill-slate-500"
                }`}
              />
              {!transitionAnimation && (
                <div className="relative">
                  {/* {!transitionAnimation && ( */}
                  <p className="text-[14px]">
                    {t("employer.leftbar.menu.item5.title")}
                  </p>
                  {/* )} */}
                  {notificationsData?.unread_notifications !== "0" && (
                    <Circle
                      className="h-2 absolute top-1 -left-6"
                      fill={"#b91919"}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              className={cn(
                `flex items-center gap-5 transition cursor-pointer h-10 py-2 px-2 mb-[1px] dark:text-whiteish ${
                  window.location.pathname === "/credits"
                    ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                    : "text-slate-500 hover:bg-slate-200  rounded dark:hover:bg-neutral-400/30"
                }`,
                transitionAnimation && "justify-center"
              )}
              onClick={() => {
                setShowOptions((prev) => ({
                  ...prev,
                  jobs: false,
                  profile: false,
                }));

                setSelectedTab("credits");
                navigate("/credits");
              }}
            >
              <CurrencyDollarIcon
                className={`h-4 w-4 dark:fill-whiteish ${
                  selectedTab === "credits"
                    ? "fill-azulOscuro"
                    : "fill-slate-500"
                }`}
              />

              {!transitionAnimation && (
                <div>
                  <p className="text-[14px]">
                    {t("employer.leftbar.menu.item7.Credits.title")}
                  </p>
                </div>
              )}
            </div>
            <div
              onClick={() => {
                navigate("/find-workers");
                setSelectedTab("find-workers");
              }}
              className={cn(
                "flex items-center gap-5 transition cursor-pointer h-10 py-2 px-2 mb-[1px] dark:text-whiteish text-slate-500",
                window.location.pathname === "/find-workers"
                  ? "bg-blue-100 text-azulOscuro rounded dark:bg-neutral-400/30"
                  : "text-slate-500 hover:bg-slate-200  rounded dark:hover:bg-neutral-400/30",
                transitionAnimation && "justify-center"
              )}
            >
              <UserIcon className="h-4" />
              {!transitionAnimation && (
                <div>
                  <p className="text-[14px]">
                    {t("employer.leftbar.menu.item8.Findworkers.title")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="pb-4 relative mx-2" ref={settingsRef}>
            {Number(creditsNumber) > Number(postjobCredit) &&
              !loadingActionCredits && (
                <>
                  {!transitionAnimation ? (
                    <Button
                      onClick={() => navigate("/welcome-client")}
                      className="w-full px-6 py-2 group tracking-wide h-10 text-white rounded bg-rositaOscuro hover:bg-[#d33d74]"
                    >
                      <p className="">
                        {t("employer.leftbar.postJobBtn.title")}
                      </p>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => navigate("/welcome-client")}
                      className="bg-rositaOscuro h-10 w-full flex items-center justify-center hover:bg-[#d33d74] rounded"
                    >
                      <PlusIcon className="text-white h-7 w-7" />
                    </Button>
                  )}
                </>
              )}

            {Number(creditsNumber) < Number(postjobCredit) &&
              !loadingActionCredits && (
                <>
                  {!transitionAnimation ? (
                    <Button className="px-6 py-2 bg-gray-200 cursor-not-allowed hover:bg-gray-300 text-gray-600 w-full rounded">
                      <p className="">
                        {t("employer.leftbar.postJobBtn.title")}
                      </p>
                    </Button>
                  ) : (
                    <Button className="bg-gray-200 text-[#333] cursor-not-allowed h-10 w-full flex items-center justify-center rounded">
                      <PlusIcon className="text-[#333] h-7 w-7" />
                    </Button>
                  )}
                </>
              )}

            {loadingActionCredits && (
              <>
                <Skeleton className="h-10 w-full rounded animate-pulse" />
              </>
            )}

            {/* COonfig Modal */}
            {showAccountConfig && (
              <div className="mx-2">
                <div className="absolute bottom-1 left-0 mx-2 w-60 bg-white shadow dark:bg-input dark:text-whiteish shadow-gray-400 rounded py-2 px-2">
                  <div className="flex flex-col">
                    <div
                      onClick={() => navigate("/entrepise-profile")}
                      className="w-full flex flex-col items-center justify-center pb-6 pt-2"
                    >
                      {userInfo?.profile_image && (
                        <img
                          src={`${BASE_URL_IMG}${
                            userInfo.company_logo ?? userInfo?.profile_image
                          }`}
                          alt="profile"
                          className="h-[105px]  w-[105px] object-cover rounded-full cursor-default"
                        />
                      )}
                      {!userInfo?.profile_image && (
                        <p className="border-2 font-bold rounded-full dark:border-border dark:text-neutral-400/30 border-black text-black h-[55px] w-[55px] p-1 flex mr-2 justify-center items-center">
                          {userInfo?.first_name?.slice(0, 1)}
                          {userInfo?.last_name?.slice(0, 1)}
                        </p>
                      )}
                      <p className="text-base font-bold py-2 cursor-default">
                        {userInfo?.first_name} {userInfo?.last_name}
                      </p>
                    </div>
                    {userInfo?.company_id !== null && (
                      <div
                        onClick={() => navigate("/credits")}
                        className="px-2 py-2 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-neutral-400/30 flex items-center gap-3 transition duration-200 ease-out hover:rounded-lg"
                      >
                        <MdHistory />
                        <p className="text-sm">
                          {t("employer.wallet_info.title")}
                        </p>
                        <div className="group-hover:translate-x-1 transition justify-end absolute right-4">
                          <ChevronRightIcon className="h-4 w-4" />
                        </div>
                      </div>
                    )}

                    <div className="flex flex-col space-y-2">
                      {userInfo?.company_id !== null && (
                        <div
                          onClick={() => {
                            if (modal.isOpen) {
                              modal.onClose();
                            } else {
                              modal.onOpen();
                            }
                          }}
                          className={cn(
                            "px-2 py-2 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-neutral-400/30 flex items-center gap-3 transition duration-200 ease-out hover:rounded-lg",
                            modal.isOpen && "bg-[#f7f7f4]"
                          )}
                        >
                          <BiWallet className="h-4 fill-black dark:fill-whiteish" />

                          <p className="text-sm ">
                            {t("settings.wallet.title")}
                          </p>
                        </div>
                      )}
                      <div
                        onClick={() => navigate("/contact")}
                        className="px-2 py-2 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-background flex items-center gap-3 transition-transform duration-200 ease-out hover:rounded-lg"
                      >
                        <BiHelpCircle className="h-4 fill-black dark:fill-whiteish" />
                        <p className="text-sm">
                          {t("employer.leftbar.usermenu.link1")}
                        </p>
                      </div>
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-background flex items-center gap-3 transition-transform duration-200 ease-out hover:rounded-lg"
                        onClick={() => {
                          setDecorationBar("info");
                          navigate("/settings");
                        }}
                      >
                        <Settings className="h-4 fill-black dark:fill-whiteish" />
                        <p className="text-sm ">
                          {t("employer.leftbar.usermenu.link2")}
                        </p>
                      </div>
                      {/* <div className="flex items-center justify-between">
                        <div className="flex items-center px-2 py-1 gap-3">
                          {theme === "light" ? (
                            <SunIcon className="h-4 fill-black dark:fill-whiteish" />
                          ) : (
                            <MoonIcon className="h-4 fill-black dark:fill-whiteish" />
                          )}
                          <p className="text-sm ">{t("theme.title")}</p>
                        </div>
                        <div className="grow ml-10 ">
                          <Select
                            className="text-xs  dark:text-black"
                            isSearchable={false}
                            options={themeOptions}
                            controlShouldRenderValue={true}
                            hideSelectedOptions={true}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            value={{
                              value: theme ?? "",
                              label:
                                theme !== "light"
                                  ? themeOptions[1].label
                                  : themeOptions[0].label,
                            }}
                            onChange={(e) => {
                              setTheme(e!.value);
                              updateTheme(e!.value);
                            }}
                          />
                        </div>
                      </div> */}
                    </div>

                    <div className="border-t my-2" />

                    <div
                      className="px-2 py-1 cursor-pointer hover:bg-[#f7f7f4] dark:hover:bg-neutral-400/30 flex items-center gap-3 transition duration-200 ease-out hover:rounded-lg"
                      onClick={() => {
                        setOnlineStatusOff();
                        logoutUser();
                        // window.localStorage.clear();
                      }}
                    >
                      <BiLogOut className="h-4  dark:fill-whiteish" />
                      <p className="text-[15px]">
                        {t("employer.leftbar.usermenu.link3")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Lower Vertical Header */}
          <div
            className={cn(
              `flex items-center justify-between rounded-lg py-2  text-black dark:text-whiteish dark:bg-neutral-400/30 transition  mt-2 mx-2 px-2 hover:bg-neutral-100 bg-transparent cursor-pointer ${
                showAccountConfig ? "bg-slate-50" : ""
              }`,
              transitionAnimation && "mx-0 px-0"
            )}
            onClick={() => {
              setShowAccountConfig(!showAccountConfig);
              setShowOptions((prev) => ({
                ...prev,
                profile: !showOptions.profile,
              }));
            }}
          >
            <div className="flex items-center justify-center ">
              {!transitionAnimation && userInfo?.profile_image && (
                <img
                  src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
                  alt="profile"
                  className="h-10  w-10 object-cover rounded-full cursor-default"
                />
              )}
              {!transitionAnimation && !userInfo?.profile_image && (
                <p className="border-2 font-bold rounded-full border-black dark:text-neutral-400  dark:border-neutral-400 text-black h-[44px] w-[44px] p-1 flex mr-2 justify-center items-center">
                  {userInfo?.first_name?.slice(0, 1)}
                  {userInfo?.last_name?.slice(0, 1)}
                </p>
              )}
              <div className="flex flex-col items-start ml-2">
                {!transitionAnimation && (
                  <p className="text-base font-bold py-2 cursor-default ">
                    {userInfo?.first_name?.split(" ")[0]}{" "}
                    {userInfo?.last_name?.slice(0, 1)}.
                  </p>
                )}
                {userInfo?.company_id !== null && (
                  <div
                    className={cn(
                      "flex items-center gap-2 cursor-pointer hover:bg-gray-100 dark:bg-transparent relative",
                      transitionAnimation && "hover:bg-transparent"
                    )}
                  >
                    <p className="text-sm flex items-center gap-1 ">
                      <span
                        className={cn(
                          "font-bold  dark:text-whiteish ",
                          showVerticalEntire && "text-xs"
                        )}
                      >
                        {creditsNumber}
                      </span>
                    </p>
                    <div
                      className={cn(
                        "h-5 w-5 bg-transparent  flex items-center justify-center ml-1 rounded-full ",
                        transitionAnimation && "justify-center absolute left-7"
                      )}
                    >
                      <img
                        src={Flexicoin}
                        className={cn(
                          "h-5 w-5 object-contain ",
                          transitionAnimation && "h-4 w-4"
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!transitionAnimation && (
              <div>
                <FaCaretDown
                  className={`h-4 fill-black dark:fill-whiteish transform transition-all duration-150 ease-in-out ${
                    showAccountConfig ? "rotate-180 " : "rotate-0"
                  }`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalHeaderUser;
