import React, { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { useRef } from "react";

import { BASE_URL, REPORT_CHAT_MESSAGES } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";

import exclamation from "../../assets/img/exclamation.png";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";

interface ModalReportMessageProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedMessage: { chat_message_id: string };
  setRefreshConversation: Dispatch<SetStateAction<boolean>>;
}

const ModalReportMessage = ({
  showModal,
  setShowModal,
  selectedMessage,
  setRefreshConversation,
}: ModalReportMessageProps) => {
  const { t } = useTranslation();
  const [reportOptions, setReportOptions] = useState<{ name: string }[]>([]);
  const [selectedOption, setSelectedOption] = useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });
  const [error, setError] = useState(false);
  const [transition, setTransition] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [reportReason, setReportReason] = useState();
  const ref = useRef<HTMLDivElement>(null);
  const getReportOptions = () => {
    fetch(`${BASE_URL}${REPORT_CHAT_MESSAGES}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setReportOptions(data.result))
      .catch((err) => console.log(err));
  };

  const reclamationData = new FormData();
  reclamationData.append("_method", "PUT");
  reclamationData.append("client_id", userID);
  reclamationData.append("chat_message_id", selectedMessage.chat_message_id);

  const sendReclamation = () => {
    reclamationData.append("chat_message_report_id", selectedOption.id);
    if (reportReason) reclamationData.append("note", reportReason);

    fetch(`${BASE_URL}${REPORT_CHAT_MESSAGES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: reclamationData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          closeModal();
          setRefreshConversation(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getReportOptions();
  }, []);

  const handleInputChange = (option: any) => {
    setSelectedOption(option);
    setError(false);
  };

  const handleReclamation = () => {
    if (!selectedOption) {
      setError(true);
    } else {
      sendReclamation();
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-[90%] lg:w-[28%] left-[50%] shadow-xl translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
            {t("worker.modal_report_message")}
          </div>
          <form className="mt-10">
            {reportOptions &&
              reportOptions?.map((option) => {
                return (
                  <>
                    <div className="mx-6 text-sm">
                      <label
                        className={`flex cursor-pointer hover:bg-blancoGrisClaro items-center gap-4 text-[15px] p-1.5 mt-2 ${
                          selectedOption?.name === option.name &&
                          "bg-blancoGrisClaro"
                        }`}
                      >
                        <input
                          type="radio"
                          value={option.name}
                          checked={selectedOption?.name === option.name}
                          onChange={() => handleInputChange(option)}
                        />
                        {option.name}
                      </label>
                    </div>
                  </>
                );
              })}
            {error === true && (
              <div className="wrong-container">
                <img
                  className="exclamation ml-3.5"
                  src={exclamation}
                  alt="hey"
                />{" "}
                {t("worker.modal_report_message.field_required")}
              </div>
            )}
            <p className="text-sm font-bold mx-6 mt-10">
              {t("worker.modal_report_message.tell_us_more")}
            </p>
            <div className="mx-6 mt-4">
              <textarea
                placeholder={`${t(
                  "worker.modal_report_message.additional_details"
                )}`}
                className="w-full rounded h-[150px] border outline-none text-sm p-3"
                onChange={(e: any) => setReportReason(e.target.value)}
              ></textarea>
            </div>
          </form>
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
            <Button
              className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro cursor-pointer mt-1 "
              onClick={() => closeModal()}
            >
              {t("worker.modal_report_message.cancel")}
            </Button>
            <Button
              className={`border-none  w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]  h-10 bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer `}
              onClick={() => handleReclamation()}
            >
              {t("worker.modal_report_message.save")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReportMessage;
