import React from "react";
import { useTranslation } from "react-i18next";
import { DraftTypes } from "src/types/types";
import { Button } from "../ui/Button";

interface DraftsSearchCardProps {
  job: DraftTypes;
  getDraftData: (id: string) => void;
}

const DraftsSearchCard = ({ job, getDraftData }: DraftsSearchCardProps) => {
  const { t } = useTranslation();
  return (
    <div
      key={job.job_id}
      className=" border-b dark:border-border dark:border py-4 px-8 transition hover:bg-[#f7f4f4] dark:hover:bg-background hover:text-azulBonico cursor-pointer"
    >
      <div className="flex items-end justify-between mb-2">
        <div className=" flex-[0_0_33%] max-w-[33%]">
          <div className="flex flex-col">
            <p className="font-bold text-base max-w-[350px] hover:underline hover:text-azulOscuro">
              {job.title.charAt(0).toUpperCase() + job.title?.slice(1)}
            </p>
            <div className="flex flex-col text-slate-500 text-sm">
              <p className="text-slate-500 text-sm mt-3">
                {job.date_updated.includes("day") ? (
                  <p>
                    {t("employer.all_drafts.updated") +
                      " " +
                      job.date_updated.split(" ")[0] +
                      t("employer.all_drafts.days_ago")}
                  </p>
                ) : (
                  <p>
                    {t("employer.all_drafts.updated") +
                      " " +
                      job.updated?.slice(0, 15) +
                      t("employer.all_drafts.ago")}
                  </p>
                )}
              </p>
            </div>
          </div>
        </div>
        <Button
          className="px-4 whitespace-nowrap tracking-wide h-fit py-1 text-sm text-white bg-azulBonico rounded hover:bg-azulOscuro "
          onClick={() => getDraftData(job.job_id)}
        >
          {t("employer.all_drafts.edit_draft")}
        </Button>
      </div>
    </div>
  );
};

export default DraftsSearchCard;
