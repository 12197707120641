import React, { useState } from "react";
import { useEffect } from "react";

import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";

import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";
import { ReactComponent as ArrowUp } from "../../../assets/img/arrow-up-solid.svg";
import ScheduleDays from "../../../Components/Time/ScheduleDays";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../../Config/api.config";

import { useNavigate } from "react-router-dom";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { HourDaysTypes } from "src/types/types";
import { toast } from "react-hot-toast";
import { ReactComponent as Trash } from "../../..//assets/img/trash-can.svg";
import { userProfileStore } from "src/Routing/useProfileStore";

const AddScheduleGood = () => {
  const { t } = useTranslation();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [showOpen, setShowOpen] = useState<any>([]);
  const [allChosenTimes, setAllChosenTimes] = useState([]);
  const [update, setUpdate] = useState(false);
  const [updateTimes, setUpdateTimes] = useState(false);
  const [dayTimes, setDayTimes] = useState([]);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [shortenedDay, setShortenedDay] = useState("");
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const [iClicked, setIClicked] = useState<any>();
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const navigate = useNavigate();

  const getTimes = () => {
    fetch(`${BASE_URL}worker.time.profile?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllChosenTimes(data.result);
      })
      .catch((err) => console.log(err));
  };
  const data = new FormData();
  data.append("_method", "POST");
  data.append("hour_from", "00:00");
  data.append("hour_to", "23:59");

  const sendTime = (day: string) => {
    data.append("client_id", userID);

    data.append("dayname", day.toUpperCase());
    fetch(`${BASE_URL}worker.time.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setUpdate(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteAllTimes = (day: string) => {
    fetch(
      `${BASE_URL}worker.time.profile?client_id=${userID}&dayname=${day
        ?.slice(0, 3)
        .toUpperCase()}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setUpdate(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const getTimesPerDay = (day: string, i: number) => {
    fetch(
      `${BASE_URL}worker.time.profile?client_id=${userID}&dayname=${day
        .toString()
        .toUpperCase()}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setDayTimes(data.result);
          setShowOpen(i);

          if (data.result.length === 0) {
            handleRemoveCheck(day);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTimes();
  }, []);

  const handleRemoveCheck = (day: string) => {
    if (day.toUpperCase().includes("MON")) {
      setSelectedDays(selectedDays.filter((item: number) => item !== 0));
    } else if (day.toUpperCase().includes("TUE")) {
      setSelectedDays(selectedDays.filter((item: number) => item !== 1));
    } else if (day.toUpperCase().includes("WED")) {
      setSelectedDays(selectedDays.filter((item: number) => item !== 2));
    } else if (day.toUpperCase().includes("THU")) {
      setSelectedDays(selectedDays.filter((item: number) => item !== 3));
    } else if (day.toUpperCase().includes("FRI")) {
      setSelectedDays(selectedDays.filter((item: number) => item !== 4));
    } else if (day.toUpperCase().includes("SAT")) {
      setSelectedDays(selectedDays.filter((item: number) => item !== 5));
    } else if (day.toUpperCase().includes("SUN")) {
      setSelectedDays(selectedDays.filter((item: number) => item !== 6));
    }
  };

  useEffect(() => {
    if (allChosenTimes) {
      const days = allChosenTimes?.map(
        (day: { dayname: string }) => day.dayname
      );
      let append: any = [];
      if (days.includes("MON") && !selectedDays.includes(0)) {
        append = [...append, 0];
      }
      if (days.includes("TUE") && !selectedDays.includes(1)) {
        append = [...append, 1];
      }
      if (days.includes("WED") && !selectedDays.includes(2)) {
        append = [...append, 2];
      }
      if (days.includes("THU") && !selectedDays.includes(3)) {
        append = [...append, 3];
      }
      if (days.includes("FRI") && !selectedDays.includes(4)) {
        append = [...append, 4];
      }
      if (days.includes("SAT") && !selectedDays.includes(5)) {
        append = [...append, 5];
      }
      if (days.includes("SUN") && !selectedDays.includes(6)) {
        append = [...append, 6];
      }
      setSelectedDays([...selectedDays, append.flat()].flat());
    }
  }, [allChosenTimes]);

  useEffect(() => {
    if (update) {
      getTimes();
      getTimesPerDay(shortenedDay, iClicked);
      setUpdate(false);
    }
  }, [update]);

  const handleToggle = (i: number, day: string, dayTimes: HourDaysTypes[]) => {
    if (
      dayTimes[0]?.hour_from === "00:00:00" &&
      dayTimes[0]?.hour_to === "23:59:00"
    ) {
      setSelectedDays(selectedDays.filter((item: number) => item !== i));
      handleDeleteAllTimes(day);
    } else {
      handleDeleteAllTimes(day);
      setTimeout(() => {
        sendTime(day?.slice(0, 3));
      }, 100);
      setShowOpen(null);
    }
  };

  const onNextClick = () => {
    navigate("/create-profile/details");
  };

  const handleDelete = () => {
    fetch(`${BASE_URL}worker.time.profile?client_id=${userID} `, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          toast.success(`${t("modal.time_selection.resetted_all_hours")}`);
          getTimes();
          // setShowOpen();
        }
      });
  };

  return (
    <div className=" h-screen w-full bg-white ">
      <RegistrationHeader />
      <div className="lg:max-w-3xl mx-auto pt-16 px-10 lg:px-0 flex flex-col min-h-[75vh]">
        {profileType === "worker" ? (
          <div className="text-xl lg:text-4xl font-bold">
            {t("createprofile.schedule.title")}
            {/* Choose what days and hours you are available to work. */}
          </div>
        ) : (
          <div className="text-4xl font-bold">
            {t("register.worker.add_schedule.select_time")}
          </div>
        )}
        {profileType === "worker" ? (
          <p className="pb-8 pt-4 text-sm text-[#6e7081]">
            {t("createprofile.schedule.subtitle")}
          </p>
        ) : (
          <p className="pb-8 pt-4 text-sm text-[#6e7081]">
            {t("register.worker.add_schedule.select_time.various_ranges")}
          </p>
        )}
        <div className="w-full justify-end">
          <header className=" mt-1  flex items-center justify-end w-full mr-10 group cursor-pointer ">
            <button
              onClick={() => handleDelete()}
              className={`${
                allChosenTimes.length > 0
                  ? "cursor-pointer border-gray-200 text-gray-600 hover:text-white hover:bg-red-700"
                  : "cursor-not-allowed text-gray-600/40"
              } shadow px-3 py-[2px] rounded hover:bg-gray-200 transition text-xs flex items-center font-medium group mb-2`}
            >
              {/* <h1
            className=" text-xs  font-semibold  transition"
          > */}
              <Trash
                className={`h-[12px]  mr-2 fill-gray-600 ${
                  allChosenTimes.length > 0 ? "group-hover:fill-white" : ""
                }  transition`}
              />
              {t("welcome-client.rightcard.resetallBtn")}
              {/* </h1> */}
            </button>
          </header>
        </div>
        <div className="2xl:max-h-[730px] max-h-[430px] overflow-y-auto jobpost pr-2">
          {days?.map((day, i, arr) => {
            return (
              <div
                key={day}
                className={`flex flex-col ${
                  arr[i] === day &&
                  showOpen !== i &&
                  allChosenTimes?.length > 0 &&
                  !allChosenTimes
                    ?.map((time: HourDaysTypes) =>
                      time.dayname.includes(day?.slice(0, 3).toUpperCase())
                    )
                    .includes(true) &&
                  !selectedDays.includes(i) &&
                  "hover:bg-[#fdfbfb] hover:text-black text-slate-300 hover:border-slate-300 transition duration-15  "
                } ${
                  (allChosenTimes
                    ?.map((time: HourDaysTypes) =>
                      time.dayname.includes(day?.slice(0, 3).toUpperCase())
                    )
                    .includes(true) ||
                    selectedDays.includes(i)) &&
                  "border-2 border-azulBonico text-black"
                } ${
                  (showOpen === i || selectedDays.includes(i)) &&
                  "text-black border-2 border-azulBonico"
                } border rounded-lg pr-5 pl-3 py-2 mb-3 `}
              >
                <div
                  className={`flex justify-between items-center cursor-pointer`}
                  onClick={() => {
                    setIClicked(i);

                    if (showOpen === i) {
                      setShowOpen("");
                      setShortenedDay("");
                    } else {
                      getTimesPerDay(day?.slice(0, 3), i);
                      setShortenedDay(day?.slice(0, 3));
                    }
                  }}
                >
                  <div className="flex items-center">
                    <div
                      // onClick={(e) => e.stopPropagation()}
                      className="flex items-center"
                    >
                      {/* <div
                        className="flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleToggle(i, day);
                        }}
                      >
                        <ToggleSwitch
                          isOn={selectedDays?.includes(i)}
                          onColor="#2abeeb"
                        />
                      </div> */}
                      <p className={`font-[600] tracking-wider mr-4 pl-3`}>
                        {day === "Monday" &&
                          t("createprofile.schedule.card1.daytitle")}
                        {day === "Tuesday" &&
                          t("createprofile.schedule.card2.daytitle")}
                        {day === "Wednesday" &&
                          t("createprofile.schedule.card3.daytitle")}
                        {day === "Thursday" &&
                          t("createprofile.schedule.card4.daytitle")}
                        {day === "Friday" &&
                          t("createprofile.schedule.card5.daytitle")}
                        {day === "Saturday" &&
                          t("createprofile.schedule.card6.daytitle")}
                        {day === "Sunday" &&
                          t("createprofile.schedule.card7.daytitle")}
                      </p>
                    </div>
                    {allChosenTimes?.length > 0 && (
                      <>
                        {allChosenTimes
                          .sort((a: HourDaysTypes, b: HourDaysTypes) =>
                            a.hour_from.localeCompare(b.hour_from)
                          )
                          ?.map((times: HourDaysTypes) => {
                            if (
                              times.dayname === day?.slice(0, 3).toUpperCase()
                            ) {
                              return (
                                <div
                                  key={times.dayname}
                                  className="flex text-sm text-black gap-1 ml-1.5"
                                >
                                  <p>{times.hour_from?.slice(0, 5)}</p>
                                  <p>-</p>
                                  <p>{times.hour_to?.slice(0, 5)}</p>
                                  <p className="ml-0.5">•</p>
                                </div>
                              );
                            }
                          })}
                      </>
                    )}
                  </div>
                  {!allChosenTimes
                    ?.map((time: HourDaysTypes) =>
                      time.dayname.includes(day?.slice(0, 3).toUpperCase())
                    )
                    .includes(true) ? (
                    <>
                      {showOpen === i ? (
                        <ArrowUp className="h-3 fill-black"></ArrowUp>
                      ) : (
                        <ArrowDown className={`h-3 fill-slate-300`} />
                      )}
                    </>
                  ) : (
                    <>
                      {showOpen === i ? (
                        <ArrowUp className="h-3 fill-black"></ArrowUp>
                      ) : (
                        <ArrowDown className={`h-3 fill-black`} />
                      )}
                    </>
                  )}
                </div>
                {showOpen === i && (
                  <>
                    <ScheduleDays
                      draftId=""
                      refetchTimes={getTimes}
                      handleToggle={handleToggle}
                      day={day}
                      setUpdate={setUpdate}
                      getTimesPerDay={getTimesPerDay}
                      dayTimes={dayTimes}
                      // setDayTimes={setDayTimes}
                      i={i}
                      setSelectedDays={setSelectedDays}
                      selectedDays={selectedDays}
                      shortenedDay={shortenedDay}
                      // profileType={"worker"}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <ProgressRegistration
        onNextClick={onNextClick}
        disabled={allChosenTimes?.length === 0 ? true : false}
        backButton={"create-profile/budget"}
        nextButtonText={t("createprofile.schedule.rightBtn")}
        progress="94"
      />
    </div>
  );
};

export default AddScheduleGood;
