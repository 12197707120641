import React, { useRef, useEffect, useState } from "react";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, EDUCATION_WORKER } from "../../Config/api.config";
import { Button } from "../ui/Button";
import { useTranslation } from "react-i18next";

interface ModalDeleteEducationProps {
  setShowModal: any;
  showDeleteEducationModal?: boolean;
  title: string;
  handleNoMoreLocal: () => void;
  education: {
    id: string;
    institution_name: string;
    degree: string;
    area_of_study: string;
    start_date: string;
    end_date: string;
  };
  refetchData?: () => void;
}

export default function ModalDeleteEducation({
  setShowModal,
  showDeleteEducationModal,
  title,
  handleNoMoreLocal,
  education,
  refetchData,
}: ModalDeleteEducationProps) {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleRemoveEducation = () => {
    fetch(`${BASE_URL}${EDUCATION_WORKER}?education_id=${education.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          // refetchData();
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showDeleteEducationModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDeleteEducationModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  return (
    <div>
      {" "}
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-y-0" : "scale-y-100"
          } transition duration-150 w-[90%] lg:w-[30%] left-[50%] translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
            {title}
          </div>
          <div className="p-8 pb-32 font-normal">
            {t("worker.profile.modal_education.desc1")}{" "}
            <span className="text-azulBonico">
              {education?.institution_name}
            </span>
            ? {t("worker.profile.modal_education.desc2")}.
          </div>
          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
            <Button
              className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro cursor-pointer mt-1"
              onClick={() => {
                handleNoMoreLocal();
                closeModal();
              }}
            >
              {t("modal.delete.button.cancel")}
            </Button>
            <Button
              className="bg-azulBonico border-none hover:bg-azulOscuro   text-white w-[135px] rounded-[20px] text-[0.9rem] px-[0.6rem] py-[0] cursor-pointer h-10"
              onClick={() => {
                handleRemoveEducation();
                setLoading(true);
              }}
            >
              {t("modal.delete.button.delete")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
