import React, { useState } from "react";

import ModalEditAvailability from "../Modals/ModalEditAvailability";
import useLocalStorage from "../../Hooks/UseLocalStorage";

import { ReactComponent as Question } from "../../assets/img/question-mark.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  JobTypes,
  OfferApplied,
  OfferCounters,
  OffersTypes,
} from "../../types/types";
import { formatJobTitle } from "src/Utils/formatters";

import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";
import HandleDayEnding from "src/Utils/HandleDayEnding";
import LoadingSpinner from "src/Pages/General/Register/LoadingSpinner";
import { RiLoader2Fill } from "react-icons/ri";

interface WorkerActiveJobsProps {
  counters: OfferCounters;
  jobs: OfferApplied;
  isLoading: boolean;
}

const WorkerActiveJobs = ({
  counters,
  jobs,
  isLoading,
}: WorkerActiveJobsProps) => {
  const [showOffersPopover, setShowOffersPopover] = useState(false);
  const [showInvitationsPopover, setShowInvitationsPopover] = useState(false);
  const [showActivePopover, setShowActivePopover] = useState(false);
  const [showSubmittedPopover, setShowSubmittedPopover] = useState(false);
  const [showModalAvailability, setShowModalAvailability] = useState(false);
  const [dataForFetch, setDataForFetch] = useLocalStorage(
    "dataForFetchingOffer"
  );
  const [availability, setAvailability] = useLocalStorage(
    "Availability",
    "Available now"
  );
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleMouseEnter = (props: string) => {
    if (props === "offers") {
      setShowOffersPopover(true);
    } else if (props === "invitations") {
      setShowInvitationsPopover(true);
    } else if (props === "active") {
      setShowActivePopover(true);
    } else if (props === "submitted") {
      setShowSubmittedPopover(true);
    }
  };

  const handleMouseLeave = (props: string) => {
    if (props === "offers") {
      setShowOffersPopover(false);
    } else if (props === "invitations") {
      setShowInvitationsPopover(false);
    } else if (props === "active") {
      setShowActivePopover(false);
    } else if (props === "submitted") {
      setShowSubmittedPopover(false);
    }
  };
  return (
    <div>
      <div className="w-full bg-white mt-4 border border-border rounded-lg flex flex-col dark:bg-background dark:border-border">
        <div className="flex  gap-5 p-6 items-center">
          <div className="text-lg lg:text-xl flex items-center font-semibold">
            {t("worker.myjobs.body.appliedjobs.layer1.offers")} (
            <p>
              {isLoading ? (
                <span>
                  <RiLoader2Fill className="h-4 w-4 animate-spin" />
                </span>
              ) : (
                <>{counters?.offered}</>
              )}
            </p>
            )
          </div>
          <div className="relative">
            <Question
              className="h-4 w-fit cursor-pointer"
              fill={"#2abeeb"}
              onMouseEnter={() => handleMouseEnter("offers")}
              onMouseLeave={() => handleMouseLeave("offers")}
            />
            <div
              className={`inline-block absolute -top-[75px] z-10 -left-4 w-64 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 shadow dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                showOffersPopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1 transition-opacity duration-300"
              }
                `}
            >
              <div className="py-2 px-3">
                <p>{t("worker.applied_jobs.offers_sent")}</p>
              </div>
            </div>
            <div
              className={`bg-white z-50 border-r-2 border-b-2 w-4 h-4 rotate-45 absolute -top-[25px] left-0.5 ${
                showOffersPopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              } `}
            ></div>
          </div>
        </div>
        {jobs.offered?.map((job: OffersTypes) => {
          return (
            <div
              key={job.job_id}
              className="border-t border-slate-200 dark:border-border dark:bg-background"
            >
              <div className="py-4 px-6 flex justify-between">
                <div className="flex-[0_0_60%] flex-col">
                  <p className="text-sm tracking-wider ">
                    {t("worker.applied_jobs.initiated")}{" "}
                    <span className="italic">
                      {i18n.language === "en" ? (
                        <>
                          on {HandleMonthName(job.date_received?.slice(5, 7))}{" "}
                          {job.date_received?.slice(8, 10)}
                          {HandleDayEnding(job.date_received?.slice(8, 10))}
                        </>
                      ) : (
                        <>
                          el {job.date_received?.slice(8, 10)} de{" "}
                          {HandleMonthNameES(job.date_received?.slice(5, 7))}
                        </>
                      )}
                    </span>
                  </p>
                  <p className="text-xs mt-2 text-slate-500">
                    {job.received_ago.includes("day")
                      ? job.received_ago.split(" ")[0] +
                        " " +
                        job.received_ago.split(" ")[1]
                      : job.received_ago}{" "}
                    {/* ago */}
                  </p>
                </div>
                <div className="flex-[0_0_40%]">
                  <div
                    className="text-sm text-azulBonico font-bold hover:underline hover:text-azulOscuro cursor-pointer"
                    onClick={() => {
                      setDataForFetch({
                        client_id: job.client_id,
                        job_id: job.job_id,
                      });
                      setTimeout(() => {
                        navigate("/view-offer");
                      }, 100);
                    }}
                  >
                    {formatJobTitle(job.title)}
                  </div>
                  {/* <a
                    href={`/job-offer/${job.job_id}`}
                  <p className="text-sm">$ {job.budget}</p>
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-azulBonico font-bold hover:underline hover:text-azulOscuro cursor-pointer"
                  >
                    {job.title}
                  </a> */}
                </div>
                {/* <div className="flex flex-col text-right">
                  <p className="text-sm">{job.city_name}</p>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>

      {/* <div className="bg-white mt-8 p-6 border border-slate-200 rounded-lg flex justify-between">
        <div className="flex gap-5 items-center">
          <p className="text-xl font-bold">Invitations to interview (0)</p>
          <div className="relative">
            <Question
              className="h-4 w-fit cursor-pointer"
              fill={"#2abeeb"}
              onMouseEnter={() => handleMouseEnter("invitations")}
              onMouseLeave={() => handleMouseLeave("invitations")}
            />
            <div
              class={`inline-block absolute -top-[120px] -left-3 z-10 w-64 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 shadow  dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                showInvitationsPopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              }
              }  `}
            >
              <div class="py-2 px-3">
                <p>
                  Employers may contact you first about a job posting. If you
                  don't want these inquiries, you can set your profile to
                  currently not available.
                </p>
              </div>
            </div>
            <div
              className={`bg-white z-50 border-r-2 border-b-2 w-4 h-4 rotate-45 absolute -top-[29px] left-0.5 ${
                showInvitationsPopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              } `}
            ></div>
          </div>
        </div>

        <div className="flex items-center gap-5">
          <p
            className={`mt-1 cursor-pointer font-bold hover:bg-[#f7f4f4] border rounded-xl text-sm px-3 border-slate-200 w-fit ${
              availability === "Available now"
                ? "text-[#14A800]"
                : availability === "Listening offers..."
                ? "text-[#FF7D01]"
                : "text-[#94b8a3]"
            }`}
            onClick={() => setShowModalAvailability(true)}
          >
            {availability}
          </p>
          <img
            src={edit}
            alt="edit"
            className="h-3 cursor-pointer"
            onClick={() => setShowModalAvailability(true)}
          />
        </div>
      </div> */}

      <div className="bg-white mt-8 dark:bg-background dark:border-border   border border-slate-200 rounded-lg flex flex-col">
        <div className="flex  gap-5 p-6 items-center">
          <div className="text-lg lg:text-xl font-semibold flex items-center">
            {t("worker.myjobs.body.appliedjobs.layer1.messaged")} (
            <p>
              {isLoading ? (
                <span>
                  <RiLoader2Fill className="h-4 w-4 animate-spin" />
                </span>
              ) : (
                <>{counters?.active}</>
              )}
            </p>
            )
          </div>
          <div className="relative">
            <Question
              className="h-4 w-fit cursor-pointer"
              fill={"#2abeeb"}
              onMouseEnter={() => handleMouseEnter("active")}
              onMouseLeave={() => handleMouseLeave("active")}
            />
            <div
              className={`inline-block absolute -top-[76px] -left-3 z-10 w-64 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 shadow  dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                showActivePopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              }
              }  `}
            >
              <div className="py-2 px-3">
                <p>{t("worker.applied_jobs.started_conversation")}</p>
              </div>
            </div>
            <div
              className={`bg-white z-50 border-r-2 border-b-2 w-4 h-4 rotate-45 absolute -top-[25px] left-0.5 ${
                showActivePopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              } `}
            ></div>
          </div>{" "}
        </div>{" "}
        {jobs.active?.map((job) => {
          return (
            <div
              key={job.job_id}
              className="border-t border-slate-200 dark:border-border"
            >
              <div className="py-4 px-6 flex ">
                <div className="flex-[0_0_60%] flex-col">
                  <p className="text-sm tracking-wider ">
                    {t("worker.applied_jobs.initiated")}{" "}
                    <span className="italic">
                      {i18n.language === "en" ? (
                        <>
                          on {HandleMonthName(job.date_received?.slice(5, 7))}{" "}
                          {job.date_received?.slice(8, 10)}
                          {HandleDayEnding(job.date_received?.slice(8, 10))}
                        </>
                      ) : (
                        <>
                          el {job.date_received?.slice(8, 10)} de{" "}
                          {HandleMonthNameES(job.date_received?.slice(5, 7))}
                        </>
                      )}
                    </span>
                  </p>
                  <p className="text-xs mt-2 text-slate-500">
                    {job.received_ago.includes("day")
                      ? job.received_ago.split(" ")[0] +
                        " " +
                        job.received_ago.split(" ")[1]
                      : job.received_ago}{" "}
                    ago
                  </p>
                </div>
                <div className="flex-[0_0_40%]">
                  <a
                    href={`/job-offer/${job.job_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-azulBonico font-bold hover:underline hover:text-azulOscuro cursor-pointer transition"
                  >
                    {formatJobTitle(job.title)}
                  </a>
                  {/* <div
                    className="text-sm text-azulBonico font-bold hover:underline hover:text-azulOscuro cursor-pointer"
                    onClick={() => setOffer(job)}
                  >
                    {job.title}
                  </div> */}
                </div>
                {/* <div className="flex flex-col text-right">
                  <p className="text-sm">$ {job.budget}</p>
                  <p className="text-sm">{job.city_name}</p>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-white mt-8 dark:border-border dark:bg-background border border-slate-200 rounded-lg flex flex-col">
        <div className="flex  gap-5 p-6 items-center">
          <div className="text-lg lg:text-xl font-semibold flex items-center">
            {t("worker.myjobs.body.appliedjobs.layer1.appliedjobs")} (
            <p>
              {isLoading ? (
                <span>
                  <RiLoader2Fill className="h-4 w-4 animate-spin" />
                </span>
              ) : (
                <>{counters?.applied}</>
              )}
            </p>
            )
          </div>
          <div className="relative">
            <Question
              className="h-4 w-fit cursor-pointer"
              fill={"#2abeeb"}
              onMouseEnter={() => handleMouseEnter("submitted")}
              onMouseLeave={() => handleMouseLeave("submitted")}
            />
            <div
              className={`inline-block absolute -top-[94px] -left-3 z-10 w-64 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 shadow  dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                showSubmittedPopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              }
              }  `}
            >
              <div className="py-2 px-3">
                <p>{t("worker.applied_jobs.applied_reply")}</p>
              </div>
            </div>
            <div
              className={`bg-white z-50 border-r-2 border-b-2 w-4 h-4 rotate-45 absolute -top-[24px] left-0.5 ${
                showSubmittedPopover === false
                  ? "opacity-0 transition-opacity duration-300"
                  : " opacity-1  transition-opacity duration-300"
              } `}
            ></div>
          </div>{" "}
        </div>
        {jobs.applied?.map((job: OffersTypes) => {
          return (
            <div
              key={job.job_id}
              className="border-t border-slate-200 dark:border-border"
            >
              <div className="py-4 px-6 flex">
                <div className="flex-[0_0_60%] flex-col">
                  <p className="text-sm tracking-wider ">
                    {t("worker.applied_jobs.initiated")}{" "}
                    <span className="italic">
                      {i18n.language === "en" ? (
                        <>
                          on {HandleMonthName(job.date_received?.slice(5, 7))}{" "}
                          {job.date_received?.slice(8, 10)}
                          {HandleDayEnding(job.date_received?.slice(8, 10))}
                        </>
                      ) : (
                        <>
                          el {job.date_received?.slice(8, 10)} de{" "}
                          {HandleMonthNameES(job.date_received?.slice(5, 7))}
                        </>
                      )}
                    </span>
                  </p>
                  <p className="text-xs mt-2 text-slate-500">
                    {job.received_ago.includes("day")
                      ? job.received_ago.split(" ")[0] +
                        " " +
                        job.received_ago.split(" ")[1]
                      : job.received_ago}{" "}
                    ago
                  </p>
                </div>
                <div className="flex-[0_0_40%]">
                  {/* <div
                    className="text-sm text-azulBonico font-bold hover:underline hover:text-azulOscuro cursor-pointer"
                    onClick={() => setOffer(job)}
                  >
                    {job.title}
                  </div> */}
                  <a
                    href={`/job-offer/${job.job_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-azulBonico font-bold hover:underline hover:text-azulOscuro cursor-pointer"
                  >
                    {formatJobTitle(job.title)}
                  </a>
                </div>
                {/* <div className="flex flex-col text-right">
                  <p className="text-sm">$ {job.budget}</p>
                  <p className="text-sm">{job.city_name}</p>
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
      {showModalAvailability === true && (
        <ModalEditAvailability
          setShowModalAvailability={setShowModalAvailability}
          setAvailability={setAvailability}
          showModalAvailability={showModalAvailability}
          availability={availability}
        />
      )}
    </div>
  );
};

export default WorkerActiveJobs;
