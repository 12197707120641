import React, { Suspense, lazy, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import useLocalStorage from "../../../Hooks/UseLocalStorage";

const JobPosts = lazy(() => import("src/Components/ClientDashboard/JobPosts"));
const JobDrafts = lazy(
  () => import("src/Components/ClientDashboard/JobDrafts")
);
const ClosedJobs = lazy(
  () => import("src/Components/ClientDashboard/ClosedJobs")
);

import {
  jobStatus,
  selectedTabHeaderEmployer,
  showCongratulationsGift,
} from "../../../Atoms/jotaiAtoms";

import { ReactComponent as Blooming } from "../../../assets/img/blooming.svg";
import { useTranslation } from "react-i18next";
import type { EmployerTypes, JobTypes } from "src/types/types";
import {
  useEmployerStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import { cn } from "src/lib/utils";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import { useThemeStore } from "src/Routing/useThemeStore";

import DraftsCardSkeleton from "src/Components/ui/skeleton/DraftsCardSkeleton";
import DashboardRightContainer from "src/Components/Dashboard/DashboardRightContainer";
import { useDashboard } from "src/Hooks/useDashboard";
import { useGetCredits } from "src/Hooks/useGetCredits";
import { useClientData } from "src/Hooks/useClientData";
import ModalCongratulationsGift from "src/Components/Modals/ModalCongratulationsGift";

export default function Dashboard() {
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);

  const [selectedTab, setSelectedTab] = useAtom(selectedTabHeaderEmployer);
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [originalData, setOriginalData] = useLocalStorage("originalData", "");

  const [showInstructionsCollapsed, setShowInstructionsCollapsed] =
    useState(false);
  const [draftId, setDraftId] = useLocalStorage("draft_id", "");
  const [categoryID, setCategoryID] = useLocalStorage("jobCategoryID", "");
  const [paramsJob, setParamsJob] = useAtom(jobStatus);
  const [clientData, setClientData] = useState<EmployerTypes>();
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [jobLocation, setJobLocation] = useLocalStorage("jobLocation", "");
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const [currentMonth, setCurrentMonth] = useState<any>("");
  const [currentDate, setCurrentDate] = useState<any>("");
  const [currentDay, setCurrentDay] = useState<any>("");
  const today = new Date();
  const theme = useThemeStore((state) => state.themeMode);
  const containerRef = useRef<HTMLDivElement>(null);
  const { employerData, refetchEmployer } = useClientData();

  const [congratulationsGift, setCongratulationsGift] = useAtom(
    showCongratulationsGift
  );

  useEffect(() => {
    refetchEmployer();
  }, []);

  useEffect(() => {
    if (
      employerData?.gift_received === "0" &&
      employerData?.company_id !== null
    ) {
      setCongratulationsGift(true);
    }
  }, [employerData]);

  const [headerVisible, setHeaderVisible] = useState(false);

  //REQUEST JOB POSTS, DRAFTS, CLOSED
  const {
    isLoading,
    jobPosts,
    jobDrafts,
    isDraftsLoading,
    jobClosed,
    isClosedJobsLoading,
    refetchJobDrafts,
  } = useDashboard();

  //GET CREDITS
  const { creditsData, loadingCredits } = useGetCredits();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setCreditsNumber(creditsData);
  }, [creditsData]);

  useEffect(() => {
    setSelectedTab("dashboard");
    setJobID();
    setDraftId();
    setJobData();
    setParamsJob("");
    setCategoryID();
    setJobLocation();
    setOriginalData();
    setJobInfo();
    if (profileType !== "employer" && profileType) {
      navigate("/dashboard");
    }
  }, []);
  useEffect(() => {
    setCurrentDate(today.getDate());
    const day = today.getDay();
    const month = today.getMonth() + 1;
    setCurrentDay(() => {
      switch (day) {
        case 1:
          return `${t("createprofile.schedule.card1.daytitle")}`;
        case 2:
          return `${t("createprofile.schedule.card2.daytitle")}`;
        case 3:
          return `${t("createprofile.schedule.card3.daytitle")}`;
        case 4:
          return `${t("createprofile.schedule.card4.daytitle")}`;
        case 5:
          return `${t("createprofile.schedule.card5.daytitle")}`;
        case 6:
          return `${t("createprofile.schedule.card6.daytitle")}`;
        case 0:
          return `${t("createprofile.schedule.card7.daytitle")}`;
      }
    });
    setCurrentMonth(() => {
      switch (month) {
        case 1:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month1.sortbyDropdown"
          )}`;
        case 2:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month2.sortbyDropdown"
          )}`;
        case 3:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month3.sortbyDropdown"
          )}`;
        case 4:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month4.sortbyDropdown"
          )}`;
        case 5:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month5.sortbyDropdown"
          )}`;
        case 6:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month6.sortbyDropdown"
          )}`;
        case 7:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month7.sortbyDropdown"
          )}`;
        case 8:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month8.sortbyDropdown"
          )}`;
        case 9:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month9.sortbyDropdown"
          )}`;
        case 10:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month10.sortbyDropdown"
          )}`;
        case 11:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month11.sortbyDropdown"
          )}`;
        case 12:
          return `${t(
            "createprofile.submit.edityourworkexperience.subtitle4.card.month12.sortbyDropdown"
          )}`;
      }
    });
  }, []);

  useEffect(() => {
    setClientData(employerData);
  }, [employerData]);

  return (
    // <VerticalLayout>
    <div
      ref={containerRef}
      className={cn(
        ` px-8 laptop:px-0 pb-20 mt-24 laptop:mt-0 `,
        theme === "dark" ? "dark" : ""
      )}
    >
      <div className="block bg-transparent px-10">
        <div className="laptop:flex justify-between">
          {/* Left Container */}
          <div className="laptop:flex-[0_0_70%] dark:text-whiteish py-10">
            <HeaderBanner
              headerVisible={headerVisible}
              setHeaderVisible={setHeaderVisible}
              className=" py-8"
            >
              <div className="flex items-center w-full justify-between laptop:px-8 ">
                <div className="flex flex-col">
                  <p className="text-[22px] font-[700]">
                    {currentDay},
                    {i18n.language === "en" ? (
                      <>{` ${currentMonth} ${currentDate}`}</>
                    ) : (
                      <>{` ${currentDate} de ${currentMonth}`}</>
                    )}
                  </p>
                  <h1 className="text-[36px] font-[700] tracking-wider">
                    {t("worker.find_work.dashboard.introduction.hello")},{" "}
                    {clientData?.first_name}.
                  </h1>
                </div>
                <Blooming className="h-24 w-fit" />
              </div>
            </HeaderBanner>

            <Suspense fallback={null}>
              <JobPosts isLoading={isLoading} jobPosts={jobPosts ?? []} />
            </Suspense>

            <Suspense fallback={<DraftsCardSkeleton />}>
              <JobDrafts
                jobDrafts={jobDrafts ?? []}
                isLoading={isDraftsLoading}
                refetchJobDrafts={refetchJobDrafts}
              />
            </Suspense>
            <Suspense fallback={null}>
              <ClosedJobs
                jobClosed={jobClosed ?? []}
                isLoading={isClosedJobsLoading}
              />
            </Suspense>
          </div>
          <DashboardRightContainer
            clientData={clientData}
            loadingCredits={loadingCredits}
            showInstructionsCollapsed={showInstructionsCollapsed}
            setShowInstructionsCollapsed={setShowInstructionsCollapsed}
          />
        </div>

        <div className="px-8 border rounded lg:border-t text-sm mt-4 lg:mt-0  border-blue-100 bg-white py-5 w-full relative laptop:hidden  !z-[150] ">
          <button
            className={cn(
              `py-1.5  text-white bg-rositaOscuro font-bold hover:bg-[#D33D74] w-full rounded  transition duration-300`,
              Number(creditsNumber) > 60 &&
                "disabled:cursor-not-allowed bg-gray-200 text-neutral-500"
            )}
            onClick={() => navigate("/welcome-client")}
          >
            {t("employer.allJobs.post_a_jobs")}
          </button>
        </div>
      </div>
      {congratulationsGift && (
        <ModalCongratulationsGift
          showCongratulationsGift={congratulationsGift}
          setShowCongratulationsGift={setCongratulationsGift}
        />
      )}
    </div>
    // </VerticalLayout>
  );
}
