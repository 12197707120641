import { useQuery } from "react-query";
import useLocalStorage from "./UseLocalStorage";
import { BASE_URL } from "src/Config/api.config";

export const useApplicationProcessHires = () => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const getHired = async () => {
    const data = await fetch(
      `${BASE_URL}worker.employer.connection?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: hiresData,
    isLoading: isHiresLoading,
    refetch: refetchHires,
  } = useQuery(["hires"], getHired);

  return { hiresData, isHiresLoading, refetchHires };
};

export const useApplicationProcessJob = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");

  const getJobData = async () => {
    const data = await fetch(`${BASE_URL}postings?job_id=${jobID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: jobsData, isLoading } = useQuery(["job"], getJobData);

  return { jobsData };
};

export const useApplicationProcessOffers = () => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");

  const getOffered = async () => {
    const data = await fetch(
      `${BASE_URL}employer.hired.applicants?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const {
    data: offersData,
    isLoading,
    refetch: refetchOffers,
  } = useQuery(["offers"], getOffered);

  return { offersData, isLoading, refetchOffers };
};

export const getJobCateogries = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const jobCategories = async () => {
    const data = await fetch(`${BASE_URL}job.categories?category_id`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: jobCategoryData, isLoading: isCategoriesLoading } = useQuery(
    ["job_categories"],
    jobCategories
  );

  return { jobCategoryData, isCategoriesLoading };
};

export const getJobInfo = (jobID: string) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const editJob = async () => {
    const data = await fetch(`${BASE_URL}postings?job_id=${jobID}`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const {
    data: jobInfoData,
    isLoading: isJobLoading,
    refetch: refetchEditJob,
  } = useQuery(["posting", jobID], editJob);

  return { jobInfoData, isJobLoading, refetchEditJob };
};

export const getJobTime = (jobID: string) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const getTimes = async () => {
    const data = await fetch(`${BASE_URL}job.time.profile?job_id=${jobID}`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    }).then((res) => res.json());
    return data.result;
  };

  const { data: jobTimeData } = useQuery(["job_time"], getTimes);

  return { jobTimeData };
};
