import React, { Dispatch, HTMLAttributes, SetStateAction } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { cn } from "src/lib/utils";

interface HeaderBannerProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  headerVisible?: boolean;
  setHeaderVisible: Dispatch<SetStateAction<boolean>>;
}

const HeaderBanner = ({ children, className, ...props }: HeaderBannerProps) => {
  const { headerVisible, setHeaderVisible } = props;

  if (!headerVisible) return null;
  return (
    <div
      className={cn(
        `hidden laptop:!block group relative rounded-lg tracking-wider mt-6 bg-rositaOscuro text-white dark:text-whiteish dark:bg-darkPink mb-6`,
        className
      )}
    >
      <div
        onClick={() => setHeaderVisible(!headerVisible)}
        className="bg-gray-200 cursor-pointer absolute top-4 right-4 h-6 w-6 rounded-full items-center flex justify-center opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out"
      >
        <AiOutlineClose className="h-4 w-4 text-gray-600" />
      </div>
      <div className="flex justify-around p-4 h-full ">{children}</div>
    </div>
  );
};

export default HeaderBanner;
