import React, { Dispatch, SetStateAction, useState } from "react";

import WorkerCards from "./WorkerCards";

import { ReactComponent as Dismissed } from "../../assets/img/dismissed.svg";
import { ReactComponent as ArrowUp } from "../../assets/img/arrow-up-solid.svg";
import { useTranslation } from "react-i18next";
import { ApplicantTypes } from "src/types/types";

interface ArchivedApplicantsProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData: Dispatch<SetStateAction<ApplicantTypes[]>>;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
}

const ArchivedApplicants = ({
  applicantsData,
  setApplicantsData,
  setRefreshData,
  refetch,
}: ArchivedApplicantsProps) => {
  const [showArchived, setShowArchived] = useState(true);

  const { t } = useTranslation();

  return (
    <div>
      {applicantsData?.length > 0 && (
        <>
          <div className="px-8 pt-6 py-3">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setShowArchived(!showArchived)}
            >
              <ArrowUp
                className={`h-4 fill-azulOscuro ${
                  showArchived
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown "
                }`}
              />

              <p className="font-bold text-sm text-azulOscuro hover:underline">
                {applicantsData?.length}{" "}
                {t("employer.review_candidates.archived")}
              </p>
            </div>
          </div>
          {showArchived && (
            <>
              <WorkerCards
                refetch={refetch}
                applicantsData={applicantsData}
                setApplicantsData={setApplicantsData}
                setRefreshData={setRefreshData}
              />
              {/* <div
                className={`flex justify-center border-t border-blue-100 py-4 ${
                  maxPages === currentPage && "hidden"
                }`}
              >
                <button className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-150">
                  LoadMore
                </button>
              </div> */}
            </>
          )}
        </>
      )}
      {applicantsData?.length === 0 && (
        <div className="flex flex-col w-full items-center justify-center my-12 dark:text-whiteish">
          <Dismissed className="h-32 mb-6" />
          <p className="text-lg font-bold tracking-wide">
            {t("employer.review_candidates.no_archived")}
          </p>
          <p className="text-sm mt-4">
            {t("employer.review_candidates.no_archived_desc")}
          </p>
        </div>
      )}
      {/* {declinedApplicants?.length > 0 && (
        <>
          <div className="px-8 pt-6 py-3 border-t border-azulBonico">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setShowDeclined(!showDeclined)}
            >
              <ArrowUp
                className={`h-4 fill-azulOscuro ${
                  showDeclined
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown "
                }`}
              />

              <p className="font-bold text-sm text-azulOscuro hover:underline">
                {declinedApplicants?.length} Declined
              </p>
            </div>
          </div>
          {showDeclined && (
            <>
              <WorkerCards
                applicantsData={declinedApplicants}
                // setApplicantsData={setApplicantsData}
                setRefreshData={setRefreshData}
              />

            </>
          )}
        </>
      )}
      {declinedOffers?.length > 0 && (
        <>
          <div className="px-8 pt-6 py-3 border-t border-azulBonico">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setShowDeclinedOffers(!showDeclinedOffers)}
            >
              <ArrowUp
                className={`h-4 fill-azulOscuro ${
                  showDeclinedOffers
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown "
                }`}
              />
              <p className="font-bold text-sm text-azulOscuro hover:underline">
                {declinedOffers?.length} Declined Offers by Worker
              </p>
            </div>
          </div>
          {showDeclinedOffers && (
            <>
              <WorkerCards
                applicantsData={declinedOffers}
                setRefreshData={setRefreshData}
              />
            </>
          )}
        </>
      )} */}
    </div>
  );
  // return (
  //   <div>
  //     {applicantsData?.length > 0 && (
  //       <>
  //         <div className="px-8 pt-6 py-3">
  //           <div
  //             className="flex items-center gap-2 cursor-pointer"
  //             onClick={() => setShowArchived(!showArchived)}
  //           >
  //             <ArrowUp
  //               className={`h-4 fill-azulOscuro ${
  //                 showArchived
  //                   ? "animate-toArrowUp rotate-180"
  //                   : "animate-toArrowDown "
  //               }`}
  //             />

  //             <p className="font-bold text-sm text-azulOscuro hover:underline">
  //               {applicantsData?.length} Archived
  //             </p>
  //           </div>
  //         </div>
  //         {showArchived && (
  //           <>
  //             <WorkerCards
  //               applicantsData={applicantsData}
  //               setApplicantsData={setApplicantsData}
  //               setRefreshData={setRefreshData}
  //             />
  //             {/* <div
  //               className={`flex justify-center border-t border-blue-100 py-4 ${
  //                 maxPages === currentPage && "hidden"
  //               }`}
  //             >
  //               <button className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-150">
  //                 LoadMore
  //               </button>
  //             </div> */}
  //           </>
  //         )}
  //       </>
  //     )}
  //     {applicantsData?.length === 0 &&
  //       declinedApplicants?.length === 0 &&
  //       declinedOffers?.length === 0 && (
  //         <div className="flex flex-col w-full items-center justify-center my-12">
  //           <Dismissed className="h-32 mb-6" />
  //           <p className="text-lg font-bold tracking-wide">
  //             Find all your archived applicants
  //           </p>
  //           <p className="text-sm mt-4">
  //             Applicants won't be notified when you archive their offers
  //           </p>
  //         </div>
  //       )}
  //     {declinedApplicants?.length > 0 && (
  //       <>
  //         <div className="px-8 pt-6 py-3 border-t border-azulBonico">
  //           <div
  //             className="flex items-center gap-2 cursor-pointer"
  //             onClick={() => setShowDeclined(!showDeclined)}
  //           >
  //             <ArrowUp
  //               className={`h-4 fill-azulOscuro ${
  //                 showDeclined
  //                   ? "animate-toArrowUp rotate-180"
  //                   : "animate-toArrowDown "
  //               }`}
  //             />

  //             <p className="font-bold text-sm text-azulOscuro hover:underline">
  //               {declinedApplicants?.length} Declined
  //             </p>
  //           </div>
  //         </div>
  //         {showDeclined && (
  //           <>
  //             <WorkerCards
  //               applicantsData={declinedApplicants}
  //               // setApplicantsData={setApplicantsData}
  //               setRefreshData={setRefreshData}
  //             />
  //             {/* <div
  //               className={`flex justify-center border-t border-blue-100 py-4 ${
  //                 maxPages === currentPage && "hidden"
  //               }`}
  //             >
  //               <button className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-150">
  //                 LoadMore
  //               </button>
  //             </div> */}
  //           </>
  //         )}
  //       </>
  //     )}
  //     {declinedOffers?.length > 0 && (
  //       <>
  //         <div className="px-8 pt-6 py-3 border-t border-azulBonico">
  //           <div
  //             className="flex items-center gap-2 cursor-pointer"
  //             onClick={() => setShowDeclinedOffers(!showDeclinedOffers)}
  //           >
  //             <ArrowUp
  //               className={`h-4 fill-azulOscuro ${
  //                 showDeclinedOffers
  //                   ? "animate-toArrowUp rotate-180"
  //                   : "animate-toArrowDown "
  //               }`}
  //             />
  //             <p className="font-bold text-sm text-azulOscuro hover:underline">
  //               {declinedOffers?.length} Declined Offers by Worker
  //             </p>
  //           </div>
  //         </div>
  //         {showDeclinedOffers && (
  //           <>
  //             <WorkerCards
  //               applicantsData={declinedOffers}
  //               setRefreshData={setRefreshData}
  //             />
  //           </>
  //         )}
  //       </>
  //     )}
  //   </div>
  // );
};

export default ArchivedApplicants;
