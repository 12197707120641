import { useQuery } from "react-query";
import useLocalStorage from "./UseLocalStorage";
import { DraftTypes, JobTypes } from "src/types/types";
import {
  BASE_URL,
  CLOSE_JOB_EMPLOYER,
  DRAFTS,
  POSTINGS,
} from "src/Config/api.config";

export const useDashboard = () => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const getJobPosts = async () => {
    const data = await fetch(
      `${BASE_URL}${POSTINGS}?client_id=${userID}&order=date_created`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: jobPosts, isLoading } = useQuery<JobTypes[]>(
    ["job_posts"],
    getJobPosts
  );

  const getJobDrafts = async () => {
    const data = await fetch(
      `${BASE_URL}${DRAFTS}?client_id=${userID}&order=updated`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());
    return data.result;
  };

  const {
    data: jobDrafts,
    isLoading: isDraftsLoading,
    refetch: refetchJobDrafts,
  } = useQuery<DraftTypes[]>(["job_drafts"], getJobDrafts);

  const getClosedJobs = async () => {
    const data = await fetch(
      `${BASE_URL}${CLOSE_JOB_EMPLOYER}?client_id=${userID}&order=date_updated&limit=10`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: jobClosed, isLoading: isClosedJobsLoading } = useQuery<
    JobTypes[]
  >(["job_closed"], getClosedJobs);

  return {
    jobPosts,
    isLoading,
    jobDrafts,
    isDraftsLoading,
    jobClosed,
    isClosedJobsLoading,
    refetchJobDrafts,
  };
};
